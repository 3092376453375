import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css'; // Your global CSS file
import App from './App';
import { ThemeProvider } from './context/ThemeContext'; // Import the ThemeProvider

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThemeProvider> {/* Wrap your app inside the ThemeProvider */}
      <App />
    </ThemeProvider>
  </React.StrictMode>
);
