const currentquestions = [
  {
    question:
      "The masses of the three wires of copper are in the ratio 1 : 3 : 5. And their length are in the ratio 5 : 3 : 1. The ratio of their electrical resistance is :",
    answerOptions: [
      { answer: "1 : 3 : 5", isCorrect: false },
      { answer: "5 : 3 : 1", isCorrect: false },
      { answer: "1 : 15 : 125", isCorrect: false },
      { answer: "125 : 15 : 1", isCorrect: true },
    ],
  },
  {
    question:
      "You have three resistors connected in series: R1 = 10 Ω, R2 = 20 Ω, and R3 = 30 Ω. A 12-volt battery is connected across the combination. Calculate the total resistance in the circuit and the current flowing through it :",
    answerOptions: [
      { answer: "60 Ω and 0.2 amperes", isCorrect: true },
      { answer: "40 Ω and 0.1 amperes", isCorrect: false },
      { answer: "50 Ω and 0.2 amperes", isCorrect: false },
      { answer: "60 Ω and 0.1 amperes", isCorrect: false },
    ],
  },
  {
    question:
      "You have three resistors connected in parallel: R1 = 10 Ω, R2 = 20 Ω, and R3 = 30 Ω. A 12-volt battery is connected across the combination. Calculate the total resistance :",
    answerOptions: [
      { answer: "approximately 5.40", isCorrect: false },
      { answer: "approximately 5.45", isCorrect: true },
      { answer: "approximately 5.50", isCorrect: false },
      { answer: "approximately 5.55", isCorrect: false },
    ],
  },
  {
    question:
      " In an electrical circuit, three resistors are connected in series: R1 = 10 Ω, R2 = 20 Ω, and R3 = 30 Ω. A voltage source of 24 volts is applied across the combination. Calculate the total resistance through it :",
    answerOptions: [
      { answer: "60 Ω", isCorrect: true },
      { answer: "50 Ω", isCorrect: false },
      { answer: "55 Ω", isCorrect: false },
      { answer: "65 Ω", isCorrect: false },
    ],
  },
  {
    question:
      " In another electrical circuit, three resistors are connected in parallel: R1 = 10 Ω, R2 = 20 Ω, and R3 = 30 Ω. A voltage source of 24 volts is applied across the combination. Calculate the total resistance in the circuit and the total current flowing through it. Calculate the total resistance in the parallel combination using the formula for resistors in parallel :",
    answerOptions: [
      { answer: "5.45 Ω and 44 amperes", isCorrect: true },
      { answer: "5.40 Ω and 44 amperes", isCorrect: false },
      { answer: "5.50 Ω and 40 amperes", isCorrect: false },
      { answer: "5.45 Ω and 45 amperes", isCorrect: false },
    ],
  },
  {
    question:
      "n idential cells, each of emf E and internal resistance r, are joined in series to form a closed circuit. Find the potential difference across any one cell :",
    answerOptions: [
      { answer: "1", isCorrect: false },
      { answer: "2", isCorrect: false },
      { answer: "0", isCorrect: true },
      { answer: "-1", isCorrect: false },
    ],
  },
  {
    question:
      "Find teh approximate total distance traveled by an electron in the time interval in which its displacement is one meter along the wire :",
    answerOptions: [
      { answer: "10^8 m", isCorrect: false },
      { answer: "10^7 m", isCorrect: false },
      { answer: "10^10 m", isCorrect: false },
      { answer: "10^8 m", isCorrect: true },
    ],
  },
  {
    question:
      "How much time will be taken by an electron to move a distance l = 1 km in a copper wire of cross section A = 1 mm^2 if it carries a current I = 4.5 A :",
    answerOptions: [
      { answer: "3x10^6 A", isCorrect: true },
      { answer: "3x10^5 A", isCorrect: false },
      { answer: "3x10^4 A", isCorrect: false },
      { answer: "3x10^3 A", isCorrect: false },
    ],
  },
  {
    question:
      "A 5 mueF capacitor having initial charge of 20 mueC id charged through a wire of resistance 5 Ω. Find the heat dissipated in the wire between 25 mue s and 50 mue s after the connections are made :",
    answerOptions: [
      { answer: "4.7x10^-6 J", isCorrect: true },
      { answer: "4.8x10^-6 J", isCorrect: false },
      { answer: "4.9x10^-6 J", isCorrect: false },
      { answer: "4.6x10^-6 J", isCorrect: false },
    ],
  },
  {
    question:
      "Find the total linear momentum of the electrons in a conductor of length l = 1000 m carrying a current i = 70 A.  :",
    answerOptions: [
      { answer: "0.4x10^-6 Ns", isCorrect: true },
      { answer: "0.4x10^-5 Ns", isCorrect: false },
      { answer: "0.4x10^-4 Ns", isCorrect: false },
      { answer: "0.4x10^-6 Ns", isCorrect: false },
    ],
  },
  {
    question:
      "A copper coil has a resistance of 20.0 Ω at 0 degree C and a resistance of 26.4 Ω at 80 degee C. Find the temperature coefficient of resistance of coppernm :",
    answerOptions: [
      { answer: "4x10^-3 degree C^-1", isCorrect: true },
      { answer: "4x10^-3 degree C", isCorrect: false },
      { answer: "4x10^-1 degree C^-1", isCorrect: false },
      { answer: "4x10^-3 degree C^1", isCorrect: false },
    ],
  },
  {
    question:
      "What is the value of the shunt that passes 10% of the main current through a galvanometer of 99 Ω :",
    answerOptions: [
      { answer: "12 Ω", isCorrect: false },
      { answer: "10 Ω", isCorrect: false },
      { answer: "11 Ω", isCorrect: true },
      { answer: "14 Ω", isCorrect: false },
    ],
  },
  {
    question:
      "Two bulbs are rated 30 W-200 V and 60 W-200 V. They are connected with a 400 V power supply. Find which bulb will get fused if they are connected in series and paralled : ",
    answerOptions: [
      { answer: "One will fused", isCorrect: false },
      { answer: "Both will fused", isCorrect: true },
      { answer: "None will fused", isCorrect: false },
      { answer: "Can not be said", isCorrect: false },
    ],
  },
  {
    question:
      " Two electrical bulbs, rated for the same voltage, have powers of 200 W and 100 W, respectively. If their resistance are r1 and r2, respectively, then :",
    answerOptions: [
      { answer: "r1 = 2r2", isCorrect: false },
      { answer: "r2 = 2r1", isCorrect: true },
      { answer: "r2 = 4r1", isCorrect: false },
      { answer: "r1 = 4r2", isCorrect: false },
    ],
  },
  {
    question:
      "The power rating of an electric moter that draws a current of 3.75 A, when operated at 200 V, is nearly :",
    answerOptions: [
      { answer: "54 W", isCorrect: false },
      { answer: "1 hp", isCorrect: true },
      { answer: "500 W", isCorrect: false },
      { answer: "750 hp", isCorrect: false },
    ],
  },
  {
    question:
      "How many calories of heat will be approx. developed in a 210 W electric bulb in 5 min  :",
    answerOptions: [
      { answer: "15,000", isCorrect: true },
      { answer: "1050", isCorrect: false },
      { answer: "63,000", isCorrect: false },
      { answer: "80,000", isCorrect: false },
    ],
  },
  {
    question:
      "100 cells each of emf 5 V and internal resistance 1 ohm are to be arranged to produce maximum current in a 25 Ω resistance. Each row contains equal number of cells. Find the number of rows :",
    answerOptions: [
      { answer: "m = 1", isCorrect: false },
      { answer: "m = 2", isCorrect: true },
      { answer: "m = 3", isCorrect: false },
      { answer: "m = 4", isCorrect: false },
    ],
  },
  {
    question:
      "n identical light bulbs, each designed to draw p power from a certain voltage supply, are joined in series across that supply. The total power which they will draw is :",
    answerOptions: [
      { answer: "nP", isCorrect: false },
      { answer: "P", isCorrect: false },
      { answer: "P/n", isCorrect: true },
      { answer: "P/n^2", isCorrect: false },
    ],
  },
  {
    question:
      "n identical cells, each of emf f and internal resistance r, are joined in series to form a closed circult. The potential difference across any one cell is  :",
    answerOptions: [
      { answer: "0", isCorrect: true },
      { answer: "f", isCorrect: false },
      { answer: "f/n", isCorrect: false },
      { answer: "(n-1)e/n", isCorrect: false },
    ],
  },
  {
    question:
      "A constant voltage is applied between the two ends of a metallic wire. If both the length and the radius of the wire are doubled, the rate of heat developded in the wire will :",
    answerOptions: [
      { answer: "be halved", isCorrect: false },
      { answer: "be doubled", isCorrect: true },
      { answer: "remain the same", isCorrect: false },
      { answer: "be quadrupled", isCorrect: false },
    ],
  },
  {
    question:
      "A circuit has a resistance of 10 Ω, and a current of 2 amperes is flowing through it. Calculate the voltage across the circuit :",
    answerOptions: [
      { answer: "20 V", isCorrect: true },
      { answer: "30 V", isCorrect: false },
      { answer: "10 V", isCorrect: false },
      { answer: "40 V", isCorrect: false },
    ],
  },
  {
    question:
      "  In a circuit, the resistance is 8 Ω, and a voltage of 24 volts is applied across it. Calculate the current flowing through the circuit :",
    answerOptions: [
      { answer: "1 amperes", isCorrect: false },
      { answer: "3 amperes", isCorrect: true },
      { answer: "5 amperes", isCorrect: false },
      { answer: "7 amperes", isCorrect: false },
    ],
  },
  {
    question:
      "If a resistor has a voltage drop of 6 volts across it and a current of 3 amperes passing through it, what is the resistance of the resistor :",
    answerOptions: [
      { answer: "5 Ω", isCorrect: false },
      { answer: "3 Ω", isCorrect: false },
      { answer: "2 Ω", isCorrect: true },
      { answer: "1 Ω", isCorrect: false },
    ],
  },
  {
    question:
      " An ammeter has a resistance of 0.1 Ω. If it reads 2.5 amperes in a circuit, what is the actual current flowing through the circuit :",
    answerOptions: [
      { answer: "0.25 V", isCorrect: true },
      { answer: "0.50 V", isCorrect: false },
      { answer: "0.75 V", isCorrect: false },
      { answer: "Nothing can be said", isCorrect: false },
    ],
  },
  {
    question:
      " A circuit has a current of 5 milliamperes (5 mA) flowing through it, and the voltage across the circuit is 15 volts. Calculate the resistance of the circuit :",
    answerOptions: [
      { answer: "3,000 Ω", isCorrect: true },
      { answer: "2,000 Ω", isCorrect: false },
      { answer: "5,000 Ω", isCorrect: false },
      { answer: "Data insufficient", isCorrect: false },
    ],
  },
  {
    question:
      "If you have a circuit with a total resistance of 20 Ω and you connect an ammeter with a resistance of 0.5 Ω in series with a resistor of 15 Ω, what current will the ammeter read :",
    answerOptions: [
      { answer: "0.0641 amperes", isCorrect: false },
      { answer: "0.0640 amperes", isCorrect: false },
      { answer: "0.0643 amperes", isCorrect: false },
      { answer: "0.0645 amperes", isCorrect: true },
    ],
  },
  {
    question:
      " A voltmeter with a resistance of 10,000 Ω is connected across a 100-volt battery. What will be the voltage reading on the voltmeter :",
    answerOptions: [
      { answer: "300 volts", isCorrect: false },
      { answer: "200 volts", isCorrect: false },
      { answer: "100 volts", isCorrect: true },
      { answer: "400 volts", isCorrect: false },
    ],
  },
  {
    question:
      " In a meter bridge experiment, the wire length is 100 cm, and a 12 ohm resistor is used as the standard resistor. If the bridge is balanced with the jockey at 75 cm from one end, what is the resistance of the unknown resistor connected to the other gap  :",
    answerOptions: [
      { answer: "34 Ω", isCorrect: false },
      { answer: "36 Ω", isCorrect: true },
      { answer: "38 Ω", isCorrect: false },
      { answer: "can not be said", isCorrect: false },
    ],
  },
  {
    question:
      " In a meter bridge experiment, the length of the wire is 100 cm, and a 15 ohm resistor is used as the standard resistor. If the bridge is balanced with the jockey at 40 cm from one end, what is the unknown resistance connected to the other gap  :",
    answerOptions: [
      { answer: "20 Ω", isCorrect: false },
      { answer: "10 Ω", isCorrect: true },
      { answer: "15 Ω", isCorrect: false },
      { answer: "can not be said", isCorrect: false },
    ],
  },
  {
    question:
      " If you have a circuit with a voltage source of 24 volts and you connect a voltmeter with a resistance of 5000 Ω in parallel with a resistor of 1000 Ω, what voltage will the voltmeter read :",
    answerOptions: [
      { answer: "24 V", isCorrect: true },
      { answer: "22 V", isCorrect: false },
      { answer: "26 V", isCorrect: false },
      { answer: "28 V", isCorrect: false },
    ],
  },
  {
    question:
      "In a series circuit with a 12V battery and two resistors of 3 Ω and 6 Ω, what is the total current flowing through the circuit?",
    answerOptions: [
      { answer: "1 A", isCorrect: true },
      { answer: "2 A", isCorrect: false },
      { answer: "3 A", isCorrect: false },
      { answer: "4 A", isCorrect: false },
    ],
  },
  {
    question:
      "What is the equivalent resistance of two resistors, 4 Ω and 8 Ω, connected in parallel?",
    answerOptions: [
      { answer: "2.67 Ω", isCorrect: true },
      { answer: "3 Ω", isCorrect: false },
      { answer: "6 Ω", isCorrect: false },
      { answer: "12 Ω", isCorrect: false },
    ],
  },
  {
    question:
      "If a circuit has a resistance of 10 Ω and a current of 3 A, what is the voltage across the resistor?",
    answerOptions: [
      { answer: "30 V", isCorrect: true },
      { answer: "20 V", isCorrect: false },
      { answer: "15 V", isCorrect: false },
      { answer: "10 V", isCorrect: false },
    ],
  },
  {
    question:
      "In a parallel circuit with a 24V source and two resistors, 5 Ω and 10 Ω, what is the current through the 10 Ω resistor?",
    answerOptions: [
      { answer: "2.4 A", isCorrect: true },
      { answer: "1.6 A", isCorrect: false },
      { answer: "0.4 A", isCorrect: false },
      { answer: "0.6 A", isCorrect: false },
    ],
  },
  {
    question:
      "What is the power dissipation in a 20 Ω resistor with a current of 2 A flowing through it?",
    answerOptions: [
      { answer: "80 W", isCorrect: true },
      { answer: "40 W", isCorrect: false },
      { answer: "20 W", isCorrect: false },
      { answer: "10 W", isCorrect: false },
    ],
  },
  {
    question:
      "What is the total resistance of a series circuit with resistors of 2 Ω, 3 Ω, and 5 Ω?",
    answerOptions: [
      { answer: "10 Ω", isCorrect: true },
      { answer: "8 Ω", isCorrect: false },
      { answer: "7 Ω", isCorrect: false },
      { answer: "6 Ω", isCorrect: false },
    ],
  },
  {
    question:
      "In a circuit with a 9V battery and a 3 Ω resistor, what is the current flowing through the resistor?",
    answerOptions: [
      { answer: "3 A", isCorrect: true },
      { answer: "2 A", isCorrect: false },
      { answer: "1.5 A", isCorrect: false },
      { answer: "0.5 A", isCorrect: false },
    ],
  },
  {
    question:
      "What is the total resistance of two resistors, 6 Ω and 12 Ω, connected in series?",
    answerOptions: [
      { answer: "18 Ω", isCorrect: true },
      { answer: "8 Ω", isCorrect: false },
      { answer: "12 Ω", isCorrect: false },
      { answer: "4 Ω", isCorrect: false },
    ],
  },
  {
    question:
      "If a circuit contains a 15V battery and three resistors of 5 Ω each in parallel, what is the total resistance of the circuit?",
    answerOptions: [
      { answer: "1.67 Ω", isCorrect: true },
      { answer: "5 Ω", isCorrect: false },
      { answer: "15 Ω", isCorrect: false },
      { answer: "2.5 Ω", isCorrect: false },
    ],
  },
  {
    question:
      "What is the voltage drop across a 10 Ω resistor carrying a current of 4 A?",
    answerOptions: [
      { answer: "40 V", isCorrect: true },
      { answer: "20 V", isCorrect: false },
      { answer: "30 V", isCorrect: false },
      { answer: "10 V", isCorrect: false },
    ],
  },
  {
    question:
      "In a circuit with a 24V source and resistors of 8 Ω and 12 Ω in parallel, what is the total current supplied by the source?",
    answerOptions: [
      { answer: "3.2 A", isCorrect: true },
      { answer: "2 A", isCorrect: false },
      { answer: "1.5 A", isCorrect: false },
      { answer: "4 A", isCorrect: false },
    ],
  },
  {
    question:
      "What is the equivalent resistance of three resistors, 2 Ω, 3 Ω, and 6 Ω, connected in parallel?",
    answerOptions: [
      { answer: "1.5 Ω", isCorrect: true },
      { answer: "2 Ω", isCorrect: false },
      { answer: "3 Ω", isCorrect: false },
      { answer: "1 Ω", isCorrect: false },
    ],
  },
  {
    question:
      "If a 5 Ω resistor is connected to a 12V battery, what is the power dissipated by the resistor?",
    answerOptions: [
      { answer: "12 W", isCorrect: true },
      { answer: "24 W", isCorrect: false },
      { answer: "5 W", isCorrect: false },
      { answer: "10 W", isCorrect: false },
    ],
  },
  {
    question:
      "In a circuit with resistors of 10 Ω and 20 Ω connected in series, what is the current if the voltage across the series combination is 30V?",
    answerOptions: [
      { answer: "1 A", isCorrect: true },
      { answer: "2 A", isCorrect: false },
      { answer: "3 A", isCorrect: false },
      { answer: "0.5 A", isCorrect: false },
    ],
  },
  {
    question:
      "What is the voltage across each resistor if two resistors of 4 Ω and 6 Ω are connected in series with a 20V source?",
    answerOptions: [
      { answer: "8 V and 12 V", isCorrect: true },
      { answer: "10 V and 10 V", isCorrect: false },
      { answer: "5 V and 15 V", isCorrect: false },
      { answer: "12 V and 8 V", isCorrect: false },
    ],
  },
  {
    question:
      "What is the total resistance of four resistors, each of 10 Ω, connected in parallel?",
    answerOptions: [
      { answer: "2.5 Ω", isCorrect: true },
      { answer: "5 Ω", isCorrect: false },
      { answer: "10 Ω", isCorrect: false },
      { answer: "20 Ω", isCorrect: false },
    ],
  },
  {
    question:
      "If a 12V battery is connected to a resistor of 6 Ω, what is the power consumed by the resistor?",
    answerOptions: [
      { answer: "24 W", isCorrect: true },
      { answer: "12 W", isCorrect: false },
      { answer: "6 W", isCorrect: false },
      { answer: "48 W", isCorrect: false },
    ],
  },
  {
    question:
      "In a circuit with a 5V battery and a 15 Ω resistor, what is the current flowing through the resistor?",
    answerOptions: [
      { answer: "0.33 A", isCorrect: true },
      { answer: "1 A", isCorrect: false },
      { answer: "0.5 A", isCorrect: false },
      { answer: "2 A", isCorrect: false },
    ],
  },
  {
    question:
      "What is the equivalent resistance of a 10 Ω resistor and a 5 Ω resistor connected in series?",
    answerOptions: [
      { answer: "15 Ω", isCorrect: true },
      { answer: "5 Ω", isCorrect: false },
      { answer: "10 Ω", isCorrect: false },
      { answer: "20 Ω", isCorrect: false },
    ],
  },
  {
    question:
      "What is the voltage drop across a 12 Ω resistor if the current through it is 2 A?",
    answerOptions: [
      { answer: "24 V", isCorrect: true },
      { answer: "12 V", isCorrect: false },
      { answer: "6 V", isCorrect: false },
      { answer: "48 V", isCorrect: false },
    ],
  },
  {
    question:
      "If a circuit has a total resistance of 8 Ω and a current of 2 A, what is the voltage supplied by the source?",
    answerOptions: [
      { answer: "16 V", isCorrect: true },
      { answer: "8 V", isCorrect: false },
      { answer: "4 V", isCorrect: false },
      { answer: "32 V", isCorrect: false },
    ],
  },
  {
    question:
      "What is the current flowing through a 12 Ω resistor connected to a 24V battery?",
    answerOptions: [
      { answer: "2 A", isCorrect: true },
      { answer: "1 A", isCorrect: false },
      { answer: "3 A", isCorrect: false },
      { answer: "4 A", isCorrect: false },
    ],
  },
  {
    question:
      "What is the equivalent resistance of three resistors, each 5 Ω, connected in parallel?",
    answerOptions: [
      { answer: "1.67 Ω", isCorrect: true },
      { answer: "2 Ω", isCorrect: false },
      { answer: "5 Ω", isCorrect: false },
      { answer: "15 Ω", isCorrect: false },
    ],
  },
  {
    question:
      "If a circuit with a 24V battery has two resistors, 10 Ω and 15 Ω, connected in series, what is the total resistance?",
    answerOptions: [
      { answer: "25 Ω", isCorrect: true },
      { answer: "15 Ω", isCorrect: false },
      { answer: "10 Ω", isCorrect: false },
      { answer: "35 Ω", isCorrect: false },
    ],
  },
  {
    question:
      "What is the power consumed by a 10 Ω resistor connected to a 5V battery?",
    answerOptions: [
      { answer: "2.5 W", isCorrect: true },
      { answer: "5 W", isCorrect: false },
      { answer: "10 W", isCorrect: false },
      { answer: "1 W", isCorrect: false },
    ],
  },
  {
    question:
      "In a series circuit with a 6V battery and resistors of 4 Ω and 8 Ω, what is the voltage drop across the 4 Ω resistor?",
    answerOptions: [
      { answer: "2 V", isCorrect: true },
      { answer: "3 V", isCorrect: false },
      { answer: "4 V", isCorrect: false },
      { answer: "6 V", isCorrect: false },
    ],
  },
  {
    question:
      "What is the current through a 20 Ω resistor if the voltage across it is 40V?",
    answerOptions: [
      { answer: "2 A", isCorrect: true },
      { answer: "4 A", isCorrect: false },
      { answer: "1 A", isCorrect: false },
      { answer: "5 A", isCorrect: false },
    ],
  },
  {
    question:
      "What is the equivalent resistance of two resistors, 10 Ω and 20 Ω, connected in parallel?",
    answerOptions: [
      { answer: "6.67 Ω", isCorrect: true },
      { answer: "30 Ω", isCorrect: false },
      { answer: "5 Ω", isCorrect: false },
      { answer: "15 Ω", isCorrect: false },
    ],
  },
  {
    question:
      "If a 9V battery is connected to a resistor of 3 Ω, what is the current through the resistor?",
    answerOptions: [
      { answer: "3 A", isCorrect: true },
      { answer: "6 A", isCorrect: false },
      { answer: "1 A", isCorrect: false },
      { answer: "9 A", isCorrect: false },
    ],
  },
  {
    question:
      "In a parallel circuit with a 15V battery and resistors of 5 Ω and 10 Ω, what is the total current supplied by the battery?",
    answerOptions: [
      { answer: "3 A", isCorrect: true },
      { answer: "2 A", isCorrect: false },
      { answer: "1.5 A", isCorrect: false },
      { answer: "1 A", isCorrect: false },
    ],
  },
  {
    question:
      "What is the voltage drop across a 6 Ω resistor if it dissipates 12 W of power?",
    answerOptions: [
      { answer: "12 V", isCorrect: true },
      { answer: "6 V", isCorrect: false },
      { answer: "24 V", isCorrect: false },
      { answer: "18 V", isCorrect: false },
    ],
  },
  {
    question:
      "What is the total resistance of a series circuit with resistors of 7 Ω, 14 Ω, and 21 Ω?",
    answerOptions: [
      { answer: "42 Ω", isCorrect: true },
      { answer: "28 Ω", isCorrect: false },
      { answer: "21 Ω", isCorrect: false },
      { answer: "14 Ω", isCorrect: false },
    ],
  },
  {
    question:
      "What is the equivalent resistance of a 12 Ω resistor and a 24 Ω resistor connected in parallel?",
    answerOptions: [
      { answer: "8 Ω", isCorrect: true },
      { answer: "6 Ω", isCorrect: false },
      { answer: "10 Ω", isCorrect: false },
      { answer: "36 Ω", isCorrect: false },
    ],
  },
  {
    question:
      "If a resistor of 15 Ω is connected to a 30V battery, what is the power consumed by the resistor?",
    answerOptions: [
      { answer: "60 W", isCorrect: true },
      { answer: "45 W", isCorrect: false },
      { answer: "30 W", isCorrect: false },
      { answer: "15 W", isCorrect: false },
    ],
  },
  {
    question:
      "In a series circuit with a 10V battery and two resistors, 5 Ω and 15 Ω, what is the current through the circuit?",
    answerOptions: [
      { answer: "0.5 A", isCorrect: true },
      { answer: "1 A", isCorrect: false },
      { answer: "2 A", isCorrect: false },
      { answer: "0.25 A", isCorrect: false },
    ],
  },
  {
    question:
      "What is the current flowing through a 8 Ω resistor in a parallel circuit with a 16V battery?",
    answerOptions: [
      { answer: "2 A", isCorrect: true },
      { answer: "1 A", isCorrect: false },
      { answer: "4 A", isCorrect: false },
      { answer: "8 A", isCorrect: false },
    ],
  },
  {
    question:
      "What is the equivalent resistance of two resistors, 3 Ω and 6 Ω, connected in series?",
    answerOptions: [
      { answer: "9 Ω", isCorrect: true },
      { answer: "6 Ω", isCorrect: false },
      { answer: "3 Ω", isCorrect: false },
      { answer: "12 Ω", isCorrect: false },
    ],
  },
  {
    question:
      "If the total resistance of a circuit is 10 Ω and the voltage across the circuit is 50V, what is the current?",
    answerOptions: [
      { answer: "5 A", isCorrect: true },
      { answer: "10 A", isCorrect: false },
      { answer: "2 A", isCorrect: false },
      { answer: "50 A", isCorrect: false },
    ],
  },
  {
    question:
      "What is the power consumed by a 4 Ω resistor with a voltage of 16V across it?",
    answerOptions: [
      { answer: "64 W", isCorrect: true },
      { answer: "32 W", isCorrect: false },
      { answer: "16 W", isCorrect: false },
      { answer: "8 W", isCorrect: false },
    ],
  },
  {
    question:
      "What is the equivalent resistance of three resistors, 5 Ω, 10 Ω, and 15 Ω, connected in parallel?",
    answerOptions: [
      { answer: "3.33 Ω", isCorrect: true },
      { answer: "5 Ω", isCorrect: false },
      { answer: "10 Ω", isCorrect: false },
      { answer: "30 Ω", isCorrect: false },
    ],
  },
  {
    question:
      "If a 6 Ω resistor is connected to a 12V battery, what is the voltage drop across the resistor?",
    answerOptions: [
      { answer: "12 V", isCorrect: true },
      { answer: "6 V", isCorrect: false },
      { answer: "3 V", isCorrect: false },
      { answer: "24 V", isCorrect: false },
    ],
  },
  {
    question:
      "What is the current flowing through a 20 Ω resistor connected in series with a 10 Ω resistor and a 30V battery?",
    answerOptions: [
      { answer: "1 A", isCorrect: true },
      { answer: "2 A", isCorrect: false },
      { answer: "0.5 A", isCorrect: false },
      { answer: "3 A", isCorrect: false },
    ],
  },
  {
    question:
      "What is the voltage across a 25 Ω resistor if it carries a current of 0.5 A?",
    answerOptions: [
      { answer: "12.5 V", isCorrect: true },
      { answer: "10 V", isCorrect: false },
      { answer: "25 V", isCorrect: false },
      { answer: "5 V", isCorrect: false },
    ],
  },
  {
    question:
      "In a parallel circuit with resistors of 4 Ω and 12 Ω, what is the total current supplied by a 24V battery?",
    answerOptions: [
      { answer: "8 A", isCorrect: true },
      { answer: "6 A", isCorrect: false },
      { answer: "4 A", isCorrect: false },
      { answer: "12 A", isCorrect: false },
    ],
  },
  {
    question:
      "You have a lamp with a resistance of 240 Ω connected to a 120V outlet. What is the current flowing through the lamp?",
    answerOptions: [
      { answer: "0.5 A", isCorrect: true },
      { answer: "1 A", isCorrect: false },
      { answer: "2 A", isCorrect: false },
      { answer: "4 A", isCorrect: false },
    ],
  },
  {
    question:
      "A car battery provides 12V of power to a radio with a resistance of 6 Ω. What is the power consumed by the radio?",
    answerOptions: [
      { answer: "24 W", isCorrect: true },
      { answer: "12 W", isCorrect: false },
      { answer: "36 W", isCorrect: false },
      { answer: "6 W", isCorrect: false },
    ],
  },
  {
    question:
      "You are using a 5V power supply to charge a device with an internal resistance of 10 Ω. What is the current flowing through the device?",
    answerOptions: [
      { answer: "0.5 A", isCorrect: true },
      { answer: "1 A", isCorrect: false },
      { answer: "2 A", isCorrect: false },
      { answer: "5 A", isCorrect: false },
    ],
  },
  {
    question:
      "If you connect a toaster with a resistance of 8 Ω to a 240V outlet, what will be the current drawn by the toaster?",
    answerOptions: [
      { answer: "30 A", isCorrect: true },
      { answer: "15 A", isCorrect: false },
      { answer: "10 A", isCorrect: false },
      { answer: "20 A", isCorrect: false },
    ],
  },
  {
    question:
      "You have a string of holiday lights that uses 0.4 A of current when connected to a 120V outlet. What is the total resistance of the lights?",
    answerOptions: [
      { answer: "300 Ω", isCorrect: true },
      { answer: "60 Ω", isCorrect: false },
      { answer: "150 Ω", isCorrect: false },
      { answer: "120 Ω", isCorrect: false },
    ],
  },
  {
    question:
      "A refrigerator has a resistance of 15 Ω and is plugged into a 230V outlet. What is the power consumed by the refrigerator?",
    answerOptions: [
      { answer: "3.4 kW", isCorrect: true },
      { answer: "1.5 kW", isCorrect: false },
      { answer: "2.3 kW", isCorrect: false },
      { answer: "4.6 kW", isCorrect: false },
    ],
  },
  {
    question:
      "A power drill with a resistance of 50 Ω is used with a 120V outlet. How much power does the drill consume?",
    answerOptions: [
      { answer: "288 W", isCorrect: true },
      { answer: "144 W", isCorrect: false },
      { answer: "60 W", isCorrect: false },
      { answer: "120 W", isCorrect: false },
    ],
  },
  {
    question:
      "You need to replace a fuse in your home circuit. If the circuit has a resistance of 12 Ω and operates on a 240V supply, what is the maximum current the fuse should handle?",
    answerOptions: [
      { answer: "20 A", isCorrect: true },
      { answer: "10 A", isCorrect: false },
      { answer: "15 A", isCorrect: false },
      { answer: "5 A", isCorrect: false },
    ],
  },
  {
    question:
      "You are designing a new circuit and need to connect a 9V battery to a resistor to achieve a current of 3 A. What should be the resistance of the resistor?",
    answerOptions: [
      { answer: "3 Ω", isCorrect: true },
      { answer: "6 Ω", isCorrect: false },
      { answer: "9 Ω", isCorrect: false },
      { answer: "12 Ω", isCorrect: false },
    ],
  },
  {
    question:
      "If a 12V battery is connected to a series circuit with resistors of 2 Ω and 3 Ω, what is the total voltage drop across the 2 Ω resistor?",
    answerOptions: [
      { answer: "4.8 V", isCorrect: true },
      { answer: "6 V", isCorrect: false },
      { answer: "2.4 V", isCorrect: false },
      { answer: "8 V", isCorrect: false },
    ],
  },
  {
    question:
      "A heater has a resistance of 25 Ω and is connected to a 110V power source. How much current does the heater draw?",
    answerOptions: [
      { answer: "4.4 A", isCorrect: true },
      { answer: "2.2 A", isCorrect: false },
      { answer: "5 A", isCorrect: false },
      { answer: "8.8 A", isCorrect: false },
    ],
  },
];

export default currentquestions;
