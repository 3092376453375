import React, { useState, useEffect } from "react"; 
import { collection, getDocs } from "firebase/firestore";
import { fireDB, auth } from "../config/firebase";
import { onAuthStateChanged } from "firebase/auth";
import { Timestamp } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import Like from "../components/Like";
import Comment from "../components/Comment";
import Share from "../components/Share";
import Retweet from "../components/Retweet"; // Import Retweet component
import "../pagesStyle/trending.css";

const formatTimestamp = (timestamp) => {
  if (timestamp instanceof Timestamp) {
    const date = timestamp.toDate();
    return date.toLocaleString();
  }
  return "Invalid Date";
};

function EngagementTrends() {
  const [questions, setQuestions] = useState([]);
  const [filteredQuestions, setFilteredQuestions] = useState([]);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userNames, setUserNames] = useState({});
  const [questionPerformances, setQuestionPerformances] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const userSnapshot = await getDocs(collection(fireDB, "users"));
        const userData = {};
        userSnapshot.forEach((doc) => {
          const user = doc.data();
          userData[doc.id] = user.name; // Mapping userId to user name
        });
        setUserNames(userData);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchUsers();
  }, []);

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const querySnapshot = await getDocs(collection(fireDB, "quizQuestions"));
        const questionsList = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          likes: doc.data().likes || 0,
          comments: doc.data().comments || [],
          shares: doc.data().shares || 0,
          retweetedBy: doc.data().retweetedBy || [], // Ensure retweetedBy is part of data
          userId: doc.data().userId || "Unknown User",
        }));

        // Sort questions based on timestamp
        questionsList.sort((a, b) => b.timestamp?.toMillis() - a.timestamp?.toMillis() || 0);

        setQuestions(questionsList);
        setFilteredQuestions(questionsList); // Initially show all questions
      } catch (error) {
        console.error("Error fetching questions:", error);
      }
    };

    fetchQuestions();
  }, []);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setIsAuthenticated(!!user);
    });

    return () => unsubscribe();
  }, []);

  const calculateEngagementScore = (question) => {
    return (
      (question.likes || 0) * 1.5 +
      (question.comments.length || 0) * 1.2 +
      (question.shares || 0) * 1.3 +
      (question.retweetedBy.length || 0) * 1.1 // Include retweets in engagement score
    ) || 0; // Ensure it defaults to 0
  };

  useEffect(() => {
    const fetchPerformanceData = async () => {
      const performanceData = {};
      try {
        const querySnapshot = await getDocs(collection(fireDB, "quizPerformances"));
        querySnapshot.forEach((doc) => {
          performanceData[doc.id] = doc.data();
        });
      } catch (error) {
        console.error("Error fetching performance data:", error);
      }
      setQuestionPerformances(performanceData);
    };

    fetchPerformanceData();
  }, []);

  return (
    <div className="questions-list card">
      {filteredQuestions.map((question) => (
        <div key={question.id} className="question-card">
          <h3>{question.question || "Question not available"}</h3>
          <div className="posted-by">
            <p>
              Posted by{" "}
              <span
                onClick={() => navigate(`/profile/${question.userId}`)}
                style={{ cursor: "pointer", color: "#007bff" }}
              >
                {userNames[question.userId] || "Unknown User"}
              </span>
            </p>
            <p className="timestamp">{formatTimestamp(question.createdAt)}</p>
          </div>

          {question.options && question.options.length > 0 && (
            <div className="options-buttons">
              {question.options.map((option, index) => (
                <button key={index} className="option-button">
                  {option}
                </button>
              ))}
            </div>
          )}

          {questionPerformances[question.id] && (
            <div className="performance-metrics">
              <p>Total Answers: {questionPerformances[question.id].totalAnswers || 0}</p>
              <p>Correct Answers: {questionPerformances[question.id].totalCorrect || 0}</p>
              <p>Incorrect Answers: {questionPerformances[question.id].totalIncorrect || 0}</p>
            </div>
          )}

          <div className="engagement-metrics">
            <Like
              questionId={question.id}
              userId={auth.currentUser?.uid}
              questions={questions}
              setQuestions={setQuestions}
            />
             <Retweet
              questionId={question.id}
              userId={auth.currentUser?.uid}
              questions={questions}
              setQuestions={setQuestions}
            />
            <Share
              question={question}
              questions={questions}
              setQuestions={setQuestions}
            />
            <Comment
              question={question}
              userId={auth.currentUser?.uid}
              isAuthenticated={isAuthenticated}
              updateQuestions={setQuestions}
            />
           
            <p>
              Engagement Score: {calculateEngagementScore(question).toFixed(2)}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
}

export default EngagementTrends;
