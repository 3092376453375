import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  doc,
  getDoc,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import { fireDB, auth } from "../config/firebase"; // Adjust the import path as necessary
import '../componentsStyle/userprofile.css';


const UserProfile = () => {
  const { userId } = useParams();
  const [user, setUser] = useState(null);
  const [users, setUsers] = useState(null);
  const [userData, setUserData] = useState(null);
  const [followers, setFollowers] = useState([]);
  const [following, setFollowing] = useState([]);
  const [postedQuestions, setPostedQuestions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [rank, setRank] = useState(null);
  const [engagementScore, setEngagementScore] = useState(null);
  const [isFollowersOpen, setIsFollowersOpen] = useState(false);
  const [isFollowingOpen, setIsFollowingOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userRef = doc(fireDB, "users", userId);
        const userSnap = await getDoc(userRef);

        if (userSnap.exists()) {
          const currentUser = userSnap.data();
          setUserData(currentUser);

          // Fetch posted questions
          await fetchPostedQuestions(currentUser);

          // Calculate engagement score and rank
          await calculateUserRank(currentUser);

          // Fetch followers and following users
          await fetchFollowersAndFollowing(currentUser);
        } else {
          setError("No such user found");
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
        setError("Failed to fetch user data");
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, [userId]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      setLoading(true);
      if (currentUser) {
        setUser(currentUser);
      } else {
        navigate("/login");
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, [navigate]);

  const fetchPostedQuestions = async (currentUser) => {
    try {
      const questionsQuery = query(
        collection(fireDB, "quizQuestions"),
        where("userId", "==", currentUser.uid)
      );
      const questionSnapshot = await getDocs(questionsQuery);
      const questionsList = questionSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      console.log("Fetched Posted Questions:", questionsList); // Log the questions fetched
      setPostedQuestions(questionsList);

      const score = calculateEngagementScore(questionsList);
      console.log("Calculated Engagement Score:", score); // Log the score
      setEngagementScore(score);
    } catch (error) {
      console.error("Error fetching posted questions:", error);
    }
  };

  useEffect(() => {
    const currentUser = auth.currentUser; // Get the current user
    setUser(currentUser);

    const fetchPostedQuestions = async () => {
      if (currentUser) {
        const userRef = doc(fireDB, 'users', currentUser.uid);
        const userDoc = await getDoc(userRef);
        const postedQuestionsIds = userDoc.data().postedQuestions || [];

        // Fetch questions based on IDs
        const questions = await Promise.all(
          postedQuestionsIds.map(async (id) => {
            const questionRef = doc(fireDB, 'quizQuestions', id);
            const questionDoc = await getDoc(questionRef);
            return { id: questionDoc.id, ...questionDoc.data() };
          })
        );

        setPostedQuestions(questions);
      }
    };

    fetchPostedQuestions();
  }, []);


  const calculateEngagementScore = (questions) => {
    console.log("Calculating engagement score for questions:", questions);
    return questions.reduce((totalScore, question) => {
      const likes = question.likes || 0;
      const commentsCount = question.comments?.length || 0;
      const shares = question.shares || 0;
      const retweetedCount = question.retweetedBy?.length || 0;

      console.log(
        `Question ID: ${question.id}, Likes: ${likes}, Comments: ${commentsCount}, Shares: ${shares}, Retweets: ${retweetedCount}`
      );

      return (
        totalScore +
        likes * 1.5 +
        commentsCount * 1.2 +
        shares * 1.3 +
        retweetedCount * 1.1
      );
    }, 0);
  };

  const fetchUsersAndScores = async () => {
    try {
      const usersQuery = query(collection(fireDB, "users"));
      const userSnapshot = await getDocs(usersQuery);
      const usersList = userSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      const questionsQuery = query(collection(fireDB, "quizQuestions"));
      const questionSnapshot = await getDocs(questionsQuery);
      const questionsList = questionSnapshot.docs.map((doc) => doc.data());

      const usersWithScores = usersList.map((user) => {
        const userQuestions = questionsList.filter((q) => q.userId === user.id);
        const engagementScore = calculateEngagementScore(userQuestions);
        return {
          ...user,
          engagementScore,
        };
      });

      const sortedUsers = usersWithScores.sort(
        (a, b) => b.engagementScore - a.engagementScore
      );

      setUsers(sortedUsers);
    } catch (error) {
      console.error("Error fetching users or performance data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUsersAndScores();
  }, []);

  const calculateUserRank = async (currentUser) => {
    const usersQuery = query(collection(fireDB, "users"));
    const userSnapshot = await getDocs(usersQuery);
    const usersList = userSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    const questionsSnapshot = await getDocs(
      collection(fireDB, "quizQuestions")
    );
    const questionsList = questionsSnapshot.docs.map((doc) => doc.data());

    const usersWithScores = usersList.map((user) => {
      const userQuestions = questionsList.filter((q) => q.userId === user.id);
      const engagementScore = calculateEngagementScore(userQuestions);
      return { ...user, engagementScore };
    });

    const sortedUsers = usersWithScores.sort(
      (a, b) => b.engagementScore - a.engagementScore
    );
    const userRank = sortedUsers.findIndex((user) => user.id === userId) + 1;

    const currentUserScore =
      usersWithScores.find((user) => user.id === userId)?.engagementScore || 0;
    setEngagementScore(currentUserScore);

    setRank(userRank);
  };

  const fetchFollowersAndFollowing = async (currentUser) => {
    const followersIds = currentUser.followers || [];
    const followersPromises = followersIds.map(async (id) => {
      const followerRef = doc(fireDB, "users", id);
      const followerSnap = await getDoc(followerRef);
      return { id, ...followerSnap.data() };
    });
    const followersList = await Promise.all(followersPromises);
    setFollowers(followersList);

    const followingIds = currentUser.following || [];
    const followingPromises = followingIds.map(async (id) => {
      const followingRef = doc(fireDB, "users", id);
      const followingSnap = await getDoc(followingRef);
      return { id, ...followingSnap.data() };
    });
    const followingList = await Promise.all(followingPromises);
    setFollowing(followingList);
  };

  if (loading) return <p>Loading user data...</p>;
  if (error) return <p>{error}</p>;

  return (
    <>
      {userData ? (
        <div className="profile-container">
          <div className="rank-section">
            <h2 className="rank">Rank: {rank}</h2>
            <h2 className="rank">
              {engagementScore !== null
                ? `Performance Score: ${engagementScore}`
                : "Calculating score..."}
            </h2>
          </div>
          <div>
            {" "}
           
          </div>
          <div className="user-info">
            <h1 className="user-name">{userData.name}</h1>
            <p className="user-description">
              {userData.description || "No description provided"}
            </p>
            <p className="user-role">
              Role: {userData.role || "No role assigned"}
            </p>
            <p className="user-institution">
              Institution: {userData.institution || "No institution specified"}
            </p>
            <p className="user-country">Country: {userData.country}</p>
          </div>

          {/* Followers Dropdown */}
          <div className="followers-section">
            <h3 onClick={() => setIsFollowersOpen(!isFollowersOpen)}>
              Followers ({followers.length})
            </h3>
            {isFollowersOpen && (
              <ul className="followers-list">
                {followers.length > 0 ? (
                  followers.map((follower) => (
                    <li key={follower.id} className="follower-item">
                      <p>{follower.name}</p>
                    </li>
                  ))
                ) : (
                  <p>No followers yet</p>
                )}
              </ul>
            )}
          </div>

          {/* Following Dropdown */}
          <div className="following-section">
            <h3 onClick={() => setIsFollowingOpen(!isFollowingOpen)}>
              Following ({following.length})
            </h3>
            {isFollowingOpen && (
              <ul className="following-list">
                {following.length > 0 ? (
                  following.map((user) => (
                    <li key={user.id} className="following-item">
                      <p>{user.name}</p>
                    </li>
                  ))
                ) : (
                  <p>Not following anyone yet</p>
                )}
              </ul>
            )}
          </div>

   
        
        </div>
      ) : (
        <p>User not found.</p>
      )}
    </>
  );
};

export default UserProfile;
