import React, { useState } from "react";
import emailjs from "emailjs-com";
import '../pagesStyle/ContactUs.css';

const ContactUs = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });

    const [submitted, setSubmitted] = useState(false);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Send the form data using EmailJS
        emailjs.send(
            'xnetous2023@gmail.com',   // Replace with your EmailJS service ID
            'your_template_id',   // Replace with your EmailJS template ID
            formData,
            'txwort@gamil.com'        // Replace with your EmailJS user ID
        ).then(
            (result) => {
                console.log(result.text);
                setSubmitted(true);
            },
            (error) => {
                console.log(error.text);
            }
        );
    };

    return (
        <div className="contact-us">
            <div className="contact-container">
                <h1 className="contact-title">Contact Us</h1>

                {submitted ? (
                    <p className="contact-success">Thank you for contacting us! We will get back to you shortly.</p>
                ) : (
                    <form className="contact-form" onSubmit={handleSubmit}>
                        <div className="form-group">
                            <label htmlFor="name">Name</label>
                            <input
                                type="text"
                                id="name"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                required
                                placeholder="Your full name"
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="email">Email</label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                required
                                placeholder="Your email address"
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="message">Message</label>
                            <textarea
                                id="message"
                                name="message"
                                value={formData.message}
                                onChange={handleChange}
                                required
                                placeholder="Your message"
                            />
                        </div>

                        <button type="submit" className="submit-btn">Send Message</button>
                    </form>
                )}
            </div>
        </div>
    );
};

export default ContactUs;
