const reactionmechanismquestions = [
    {
        question:" The breaking and forming of covalent bonds :",
        answerOptions: [
            {answer: 'inorganic reaction', isCorrect: false},
            {answer: 'physical reaction', isCorrect: false},
            {answer: 'chemical reaction', isCorrect: false},
            {answer: 'organic reaction', isCorrect: true},
        ],
    },  {
        question:"Which of these are organic reaction :",
        answerOptions: [
            {answer: 'substitution reaction', isCorrect: false},
            {answer: 'addition reaction', isCorrect: false},
            {answer: 'elimination reaction', isCorrect: false},
            {answer: 'all of these', isCorrect: true},
        ],
    }, {
        question:"Nucleophile are species that have :",
        answerOptions: [
            {answer: 'lone pair of e^-', isCorrect: true},
            {answer: 'gain of e^-', isCorrect: false},
            {answer: 'neutral', isCorrect: false},
            {answer: 'can not be said ', isCorrect: false},
        ],
    },  {
        question:" The reaction rate of formation of alkyl radical by a Cl degree is  :",
        answerOptions: [
            {answer: 'primary > secondary > tertiary', isCorrect: false},
            {answer: 'seconday > primary > tertiary', isCorrect: false},
            {answer: 'tertiary > secondary > primary', isCorrect: true},
            {answer: 'none of these', isCorrect: false},
        ],
    },  {
        question:" Examples of chlorinating reagents :",
        answerOptions: [
            {answer: 'Cl2', isCorrect: false},
            {answer: 'SO2Cl2', isCorrect: false},
            {answer: '(CH)3COCl', isCorrect: false},
            {answer: 'all of these', isCorrect: true},
        ],
    },  {
        question:"Isobutane in presence of Br2/hv and 127°C will be formed  :",
        answerOptions: [
            {answer: 'tertiary butyl bromide', isCorrect: true},
            {answer: 'secondary butyl bromide', isCorrect: false},
            {answer: 'primary butyl bromide', isCorrect: false},
            {answer: 'only butyl bromide', isCorrect: false},
        ],
    }, {
        question:"In friedel crafts reaction, commonly used electrophile is :",
        answerOptions: [
            {answer: 'R+', isCorrect: false},
            {answer: 'RCO+', isCorrect: false},
            {answer: 'HC+ = NH', isCorrect: false},
            {answer: 'All of these', isCorrect: true},
        ],
    },  {
        question:"Which catylist used in Nitration :",
        answerOptions: [
            {answer: 'Δ conc.', isCorrect: false},
            {answer: 'H2SO4', isCorrect: false},
            {answer: 'Δ conc. and H2SO4', isCorrect: true},
            {answer: 'can not be said', isCorrect: false},
        ],
    },  {
        question:"1st order reaction is called :",
        answerOptions: [
            {answer: 'unimolecular', isCorrect: true},
            {answer: 'bimolecular', isCorrect: false},
            {answer: 'trimolecular', isCorrect: false},
            {answer: 'none of these', isCorrect: false},
        ],
    }, {
        question:" A nucleophile can attack the :",
        answerOptions: [
            {answer: 'H-atom', isCorrect: false},
            {answer: 'C-atom', isCorrect: true},
            {answer: 'both of these', isCorrect: false},
            {answer: 'can not be said', isCorrect: false},
        ],
    },  {
        question:" The order of reactivity of alkyl halides towards SN1 reaction is :",
        answerOptions: [
            {answer: '1° > 2° > 3° > CH3X > vinyl', isCorrect: false},
            {answer: '2° > 3° > 1° > CH3X > vinyl', isCorrect: false},
            {answer: '3° > 2° > 1° > CH3X > vinyl', isCorrect: true},
            {answer: '3° > 1° > 2° > CH3X > vinyl', isCorrect: false},
        ],
    }, {
        question:"In aqueous formic acid, a primary alkyl halide may also undergo  :",
        answerOptions: [
            {answer: 'SN3', isCorrect: false},
            {answer: 'SN2', isCorrect: false},
            {answer: 'SN1', isCorrect: true},
            {answer: 'can not be said', isCorrect: false},
        ],
    }, {
        question:"SN2 is a :",
        answerOptions: [
            {answer: 'unimolecular and 1st order', isCorrect: false},
            {answer: 'bimolecular and 1st order', isCorrect: false},
            {answer: 'bimolecular and 2nd order', isCorrect: true},
            {answer: 'unimolecular and 3rd order', isCorrect: false},
        ],
    }, {
        question:"Electrophilic Addition id given by :",
        answerOptions: [
            {answer: 'alkane', isCorrect: false},
            {answer: 'alkene', isCorrect: true},
            {answer: 'alkyne', isCorrect: false},
            {answer: 'all of these', isCorrect: false},
        ],
    },  {
        question:"Racemic product is formed by :",
        answerOptions: [
            {answer: 'cis alkene and anti addition', isCorrect: true},
            {answer: 'trans alkene and anti addition', isCorrect: false},
            {answer: 'cis alkene and syn addition', isCorrect: false},
            {answer: 'trans alkene and syn addition', isCorrect: false},
        ],
    },  {
        question:"RCH=CH2+ HBr --Peroxide---> : ",
        answerOptions: [
            {answer: 'RCHCH3Br', isCorrect: false},
            {answer: 'RCH3CBr', isCorrect: false},
            {answer: 'RCH2CH2Br ', isCorrect: true},
            {answer: 'No idea', isCorrect: true},
        ],
    },  {
        question:"E1 is :",
        answerOptions: [
            {answer: 'unimolecular and 0 order', isCorrect: false},
            {answer: 'unimolecular and 1st order', isCorrect: true},
            {answer: 'unimolecular and 2nd order', isCorrect: false},
            {answer: 'unimolecular and 3rd order', isCorrect: false},
        ],
    }, {
        question:"The strongest acid amongst the following compounds is : (AIEEE  2011)",
        answerOptions: [
            {answer: ' HCOOH', isCorrect: true},
            {answer: ' CH3CH2CH(Cl)CO2H', isCorrect: false},
            {answer: 'CH3CH2CH2CO2H', isCorrect: false},
            {answer: 'CH3COOH', isCorrect: false},
        ],
    },  {
        question:"The reaction (CH3)3C - Br ---H2O----> (CH3)3C - OH  : (AIEEE 2003)",
        answerOptions: [
            {answer: 'addition reaction', isCorrect: false},
            {answer: 'substitution raction', isCorrect: true},
            {answer: 'free radical reaction', isCorrect: false},
            {answer: 'elimination reaction', isCorrect: false},
        ],
    },  {
        question:"Which of the following has the most acidic hydrogen :",
        answerOptions: [
            {answer: ' 3–hexanone', isCorrect: false},
            {answer: ' 2, 4–hexanedione', isCorrect: true},
            {answer: ' 2, 5– hexanedione', isCorrect: false},
            {answer: ' 2, 3– hexanedione', isCorrect: false},
        ],
    }, 
]

export default reactionmechanismquestions;