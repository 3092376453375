import React, { useState, useEffect } from "react";
import {
  collection,
  getDocs,
  doc,
  increment,
  setDoc,
  getDoc,
  updateDoc,
} from "firebase/firestore";
import { toast } from "react-toastify";
import { fireDB, auth } from "../config/firebase";
import { onAuthStateChanged } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { getAuth } from "firebase/auth";
import Filters from "../components/Filters";
import "../componentsStyle/trending.css";
import { Timestamp } from "firebase/firestore";
import Like from "../components/Like";
import Retweet from "../components/Retweet";
import Comment from "../components/Comment";
import Share from "../components/Share";
import useBookmark from "../components/Bookmark";
import "../pagesStyle/Home.css";
import UserRecommendations from "../components/UserRecommendations";
import Vote from "./Vote";

const formatTimestamp = (timestamp) => {
  if (timestamp instanceof Timestamp) {
    const date = timestamp.toDate();
    return date.toLocaleDateString();
  }
  return "Invalid Date";
};

function Home() {
  const [user, setUser] = useState(null);

  const [questions, setQuestions] = useState([]);
  const [filteredQuestions, setFilteredQuestions] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [shareDropdownOpen, setShareDropdownOpen] = useState(null);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [followers, setFollowers] = useState([]); // State for followers
  const [following, setFollowing] = useState([]); // Following state
  const [userNames, setUserNames] = useState({});
  const [userId, setUserId] = useState(null);
  const [questionPerformances, setQuestionPerformances] = useState({});
  const [loading, setLoading] = useState(true);
  const [voteQuestions, setVoteQuestions] = useState([]);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  // In your component file

  useEffect(() => {
    const auth = getAuth();
    const user = auth.currentUser;
    setUserId(user ? user.uid : null);
  }, []);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const userSnapshot = await getDocs(collection(fireDB, "users"));
        const userData = {};
        userSnapshot.forEach((doc) => {
          const user = doc.data();
          console.log("Fetched user data:", doc.id, user.name); // Debugging user fetching
          userData[doc.id] = user.name; // Mapping userId to user name
        });
        setUserNames(userData);
        console.log("Final userNames object:", userData); // Check final object
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchUsers();
  }, []);

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const querySnapshot = await getDocs(
          collection(fireDB, "quizQuestions")
        );
        const questionsList = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          selectedAnswer: null,
          feedback: "",
          correctAnswerShown: false,
          likes: doc.data().likes || 0,
          likedByUser: false,
          comments: doc.data().comments || [],
          shares: doc.data().shares || 0,
          retweetedBy: doc.data().retweetedBy || [],
          userId: doc.data().userId || "Unknown User",
          createdAt: doc.data().createdAt || Timestamp.fromDate(new Date()),
        }));

        // Extract unique user IDs
        const userIds = [...new Set(questionsList.map((q) => q.userId))];

        // Sort questions by 'createdAt' in descending order (newest first)
        const sortedQuestions = questionsList.sort((a, b) => {
          return b.createdAt.toMillis() - a.createdAt.toMillis();
        });

        setQuestions(sortedQuestions);

        // Extract unique categories
        const categoriesSet = new Set(
          sortedQuestions.map((q) => q.category).filter(Boolean)
        );
        setCategories([...categoriesSet]);
      } catch (error) {
        console.error("Error fetching questions:", error);
      }
    };

    fetchQuestions();
  }, []);

  // Define fetchVoteQuestions outside useEffect to make it reusable
  const fetchVoteQuestions = async () => {
    setLoading(true);
    try {
      const querySnapshot = await getDocs(collection(fireDB, "voteQuestions"));
      const voteQuestionsList = querySnapshot.docs
        .map((doc) => ({
          id: doc.id,
          ...doc.data(),
          likes: doc.data().likes || 0,
          likedByUser: false,
        }))
        .filter((question) => question.options?.includes("Yes") && question.options?.includes("No")) // Filter for vote questions
        .map((question) => ({
          ...question,
          yesCount: question.Yes || 0,
          noCount: question.No || 0,
        }));

      setVoteQuestions(voteQuestionsList);
    } catch (error) {
      console.error("Error fetching vote questions:", error);
    } finally {
      setLoading(false);
    }
  };

  // Call fetchVoteQuestions when the component mounts
  useEffect(() => {
    fetchVoteQuestions();
  }, []);

  const handleVoteUpdate = () => {
    fetchVoteQuestions(); // Re-fetch questions after a vote to show latest counts
  };

 

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      setLoading(true);
      if (currentUser) {
        setUser(currentUser);
        try {
          const userDoc = doc(fireDB, "users", currentUser.uid);
          const docSnap = await getDoc(userDoc);

        } catch (error) {
          console.error("Error fetching data:", error);
          toast.error("Failed to load data. Please try again.");
        } finally {
          setLoading(false);
        }
      } else {
        navigate("/login");
      }
    });

    return () => unsubscribe();
  }, [navigate]);
  

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userRef = doc(fireDB, "users", userId);
        const userSnap = await getDoc(userRef);
        const currentUser = userSnap.data();

        // Fetch followers
        const followersIds = currentUser.followers || [];
        const followersPromises = followersIds.map(async (id) => {
          const followerRef = doc(fireDB, "users", id);
          const followerSnap = await getDoc(followerRef);
          return { id, ...followerSnap.data() }; // Include id with follower data
        });
        const followersList = await Promise.all(followersPromises);
        setFollowers(followersList);

        // Fetch following users
        const followingIds = currentUser.following || [];
        const followingPromises = followingIds.map(async (id) => {
          const followingRef = doc(fireDB, "users", id);
          const followingSnap = await getDoc(followingRef);
          return { id, ...followingSnap.data() }; // Include id with following data
        });
        const followingList = await Promise.all(followingPromises);
        setFollowing(followingList);
      } catch (error) {
        setError("Failed to fetch user data");
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, [userId]);

  useEffect(() => {
    // Sort questions by a combined score
    const sortedQuestions = [...questions].sort((a, b) => {
      // Define a scoring system
      // Sort in descending order
    });

    // Apply category and search filters
    let updatedQuestions = sortedQuestions;

    // Filter by category
    // Filter by category
    if (selectedCategory) {
      updatedQuestions = updatedQuestions.filter(
        (question) => question.category === selectedCategory
      );
    } else {
      // Optionally: Show all questions or handle missing categories
      updatedQuestions = sortedQuestions.filter((q) => q.category || "General");
    }

    // Filter by search query
    if (searchQuery) {
      updatedQuestions = updatedQuestions.filter((question) =>
        question.question.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    setFilteredQuestions(updatedQuestions);
  }, [questions, selectedCategory, searchQuery]);

  // Define the updateQuestions function
  const updateQuestions = (questionId, updatedComments, field) => {
    const updatedQuestions = questions.map((q) => {
      if (q.id === questionId) {
        return { ...q, [field]: updatedComments };
      }
      return q;
    });
    setQuestions(updatedQuestions); // Update the state of questions
  };

  const toggleShareDropdown = (questionId) => {
    setShareDropdownOpen((prev) => (prev === questionId ? null : questionId));
  };

  const handleAnswer = async (selectedOption, questionId) => {
    setLoading(true);

    try {
      if (!questionId || !userId) {
        console.error("Question ID or User ID is missing.");
        setLoading(false);
        return;
      }

      // Fetch question data from Firestore
      const questionRef = doc(fireDB, "quizQuestions", questionId);
      const questionSnap = await getDoc(questionRef);

      if (!questionSnap.exists()) {
        console.error("Question not found.");
        setLoading(false);
        return;
      }

      const questionData = questionSnap.data();
      const isCorrect = selectedOption === questionData.correctAnswer;

      setQuestions((prevQuestions) =>
        prevQuestions.map((question) =>
          question.id === questionId
            ? {
                ...question,
                selectedAnswer: selectedOption,
                feedback:
                  selectedOption === question.correctAnswer
                    ? "Correct!"
                    : "Incorrect",
                correctAnswerShown: selectedOption !== question.correctAnswer,
              }
            : question
        )
      );

      // Update question data in Firestore
      await updateDoc(questionRef, {
        hasAnswered: true,
        feedback: isCorrect ? "Correct!" : "Incorrect",
        correctAnswerShown: !isCorrect,
      });

      // Update user stats
      const userRef = doc(fireDB, "users", userId);
      await updateDoc(userRef, {
        totalAnswers: increment(1),
        totalCorrect: isCorrect ? increment(1) : increment(0),
        totalIncorrect: !isCorrect ? increment(1) : increment(0),
      });

      // Update or set performance data in Firestore
      const performanceRef = doc(fireDB, "quizPerformances", questionId);
      const performanceSnap = await getDoc(performanceRef);

      if (!performanceSnap.exists()) {
        console.log("Performance document does not exist. Creating a new one.");
        // Create a new performance document
        await setDoc(performanceRef, {
          totalAnswers: 0,
          totalCorrect: 0,
          totalIncorrect: 0,
        });
      }

      // Initialize performance if it doesn't exist
      let currentPerformance = performanceSnap.exists()
        ? performanceSnap.data()
        : { totalAnswers: 0, totalCorrect: 0, totalIncorrect: 0 };

      const updatedPerformance = {
        totalAnswers: currentPerformance.totalAnswers + 1,
        totalCorrect: isCorrect
          ? currentPerformance.totalCorrect + 1
          : currentPerformance.totalCorrect,
        totalIncorrect: !isCorrect
          ? currentPerformance.totalIncorrect + 1
          : currentPerformance.totalIncorrect,
      };

      // Save updated performance data to Firestore using setDoc with merge: true
      await setDoc(performanceRef, updatedPerformance, { merge: true });
      console.log("Performance document updated:", updatedPerformance);

      // Update local state for performances
      setQuestionPerformances((prevPerformances) => ({
        ...prevPerformances,
        [questionId]: updatedPerformance,
      }));

      setLoading(false);
    } catch (error) {
      console.error("Error updating Firestore:", error);
      setLoading(false);
    }
  };

  return (
    <div
      className="questions-list card"
      style={{
        maxWidth: "600px",
        margin: "0 auto",
        padding: "1rem",
        background: "#f9f9f9",
      }}
    >
      <div style={{ marginBottom: "1rem" }}>
        <input
          type="text"
          placeholder="Search questions..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          style={{
            marginTop: "80px",
            width: "100%",
            padding: "0.5rem",
            borderRadius: "4px",
            border: "1px solid #ddd",
          }}
        />
        <Filters
          categories={categories}
          selectedCategory={selectedCategory}
          onCategoryChange={(category) => setSelectedCategory(category)} // Pass this function
        />
      </div>

      <div className="questions-list">
      {loading ? (
        <p>Loading...</p>
      ) : (
        voteQuestions.map((question) => (
          <div key={question.id} className="question-card">
            
            <h3>{question.question}</h3>
            
            
            {/* Include the Vote component with props */}
            <Vote
              questionId={question.id}
              yesCount={question.yesCount || 0}
              noCount={question.noCount || 0}
              userId={question.userId} // This is now properly set
              onVoteUpdate={handleVoteUpdate}
              
            />
          </div>
        ))
      )}
    </div>

      {filteredQuestions.map((question) => (
        <div
          key={question.id}
          className="question-card"
          style={{
            marginBottom: "1rem",
            padding: "1rem",
            background: "#fff",
            borderRadius: "8px",
            boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
          }}
        >
          <div
            className="question-card"
            style={{
              border: "1px solid #ddd",
              borderRadius: "8px",
              padding: "12px",
              marginBottom: "1rem",
              backgroundColor: "#fff",
            }}
          >
            <h3
              style={{
                color: "#333",
                fontWeight: "bold",
                fontSize: "1.25rem",
                marginBottom: "6px",
              }}
            >
              {question.question}
            </h3>
            <div
              key={question.id}
              style={{
                display: "flex",
                justifyContent: "space-between", // Space between the user info and date
                alignItems: "center", // Center items vertically
                color: "#444",
                fontSize: "0.9rem",
                lineHeight: "1.4",
                marginBottom: "1rem", // Space between questions
              }}
            >
              <p style={{ margin: 0 }}>
                Posted by{" "}
                <span
                  onClick={() => {
                    console.log("Navigating to user profile:", question.userId); // Debugging to check userId
                    navigate(`/profile/${question.userId}`); // Navigate to UserProfile with userId
                  }}
                  style={{
                    cursor: "pointer",
                    color: "#007bff",
                    fontWeight: "600",
                    textDecoration: "underline",
                    transition: "color 0.2s ease", // Smooth color transition
                  }}
                  onMouseEnter={(e) => {
                    e.target.style.color = "#0056b3"; // Darker blue on hover
                  }}
                  onMouseLeave={(e) => {
                    e.target.style.color = "#007bff"; // Reset to original color
                  }}
                >
                  {userNames[question.userId]
                    ? userNames[question.userId]
                    : "Unknown User"}
                </span>
              </p>

              <span style={{ color: "#999", fontSize: "0.85rem" }}>
                <p className="timestamp">
                  {formatTimestamp(question.createdAt)}
                </p>
              </span>
            </div>
          </div>

          <div className="post-quiz-container">
            {question && question.options && question.options.length >= 4 && (
              <>
                <div className="options-container top-options">
                  <button
                    className="option-button left"
                    onClick={() =>
                      handleAnswer(question.options[0], question.id)
                    }
                  >
                    {question.options[0]}
                  </button>
                  <button
                    className="option-button right"
                    onClick={() =>
                      handleAnswer(question.options[3], question.id)
                    }
                  >
                    {question.options[3]}
                  </button>
                </div>
                <div className="options-container bottom-options">
                  <button
                    className="option-button left"
                    onClick={() =>
                      handleAnswer(question.options[1], question.id)
                    }
                  >
                    {question.options[1]}
                  </button>
                  <button
                    className="option-button right"
                    onClick={() =>
                      handleAnswer(question.options[2], question.id)
                    }
                  >
                    {question.options[2]}
                  </button>
                </div>
              </>
            )}
          </div>

          {question.feedback && (
            <p
              style={{
                color: question.feedback === "Correct!" ? "green" : "red",
              }}
            >
              {question.feedback}
            </p>
          )}
          {question.correctAnswerShown && (
            <p>
              <strong>Correct Answer:</strong> {question.correctAnswer}
            </p>
          )}

          <div>
            {loading ? (
              <p>Loading...</p>
            ) : questionPerformances && questionPerformances[question.id] ? (
              questionPerformances[question.id].totalAnswers !== 0 ? (
                <div>
                  <h2>Quiz Statistics</h2>
                  <p>
                    Total Answers:{" "}
                    {Number(questionPerformances[question.id].totalAnswers)}
                    <br />
                    Correct Answers:{" "}
                    {Number(questionPerformances[question.id].totalCorrect)}
                    <br />
                    Incorrect Answers:{" "}
                    {Number(questionPerformances[question.id].totalIncorrect)}
                  </p>
                  <progress
                    className="progress-bar"
                    value={Number(
                      questionPerformances[question.id].totalCorrect
                    )}
                    max={Number(questionPerformances[question.id].totalAnswers)}
                  />
                  <p>
                    Accuracy:{" "}
                    {(
                      (Number(questionPerformances[question.id].totalCorrect) /
                        Number(
                          questionPerformances[question.id].totalAnswers
                        )) *
                      100
                    ).toFixed(2)}
                    %
                  </p>
                </div>
              ) : (
                <p>No answers yet.</p>
              )
            ) : (
              <p>No performance data available.</p>
            )}
          </div>

          <div className="interaction-container">
            <div className="interaction-item">
              <Like
                questionId={question.id}
                userId={userId}
                questions={questions}
                setQuestions={setQuestions}
              />
            </div>

            <div className="interaction-item">
              <Retweet
                questionId={question.id}
                userId={userId}
                questions={questions}
                setQuestions={setQuestions}
              />
            
              {/* Use retweetCount instead of retweet */}
            </div>

            <div className="interaction-item">
              <Share
                question={question}
                toggleShareDropdown={toggleShareDropdown}
                shareDropdownOpen={shareDropdownOpen}
                questions={questions}
                setQuestions={setQuestions}
              />
            </div>

            <div className="interaction-item">
              <Comment
                key={question.id}
                question={question}
                isAuthenticated={true}
                updateQuestions={updateQuestions}
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default Home;
