const statisticsquestions = [
  {
    question: "Find the mean of the data set: 5, 10, 15, 20, 25.",
    answerOptions: [
      { answer: "15", isCorrect: true },
      { answer: "20", isCorrect: false },
      { answer: "10", isCorrect: false },
      { answer: "25", isCorrect: false },
    ],
  },
  {
    question: "The median of the data set: 7, 12, 9, 15, 8 is:",
    answerOptions: [
      { answer: "9", isCorrect: true },
      { answer: "8", isCorrect: false },
      { answer: "12", isCorrect: false },
      { answer: "15", isCorrect: false },
    ],
  },
  {
    question: "What is the mode of the data set: 6, 8, 7, 6, 9, 6, 10?",
    answerOptions: [
      { answer: "6", isCorrect: true },
      { answer: "7", isCorrect: false },
      { answer: "8", isCorrect: false },
      { answer: "9", isCorrect: false },
    ],
  },
  {
    question:
      "Find the standard deviation of the data: 2, 4, 4, 4, 5, 5, 7, 9.",
    answerOptions: [
      { answer: "2", isCorrect: true },
      { answer: "1", isCorrect: false },
      { answer: "3", isCorrect: false },
      { answer: "0", isCorrect: false },
    ],
  },
  {
    question:
      "If two events ( A ) and ( B ) are independent, and ( P(A) = 0.5 ) and ( P(B) = 0.6 ), what is ( P(A cap B) )?",
    answerOptions: [
      { answer: "0.3", isCorrect: true },
      { answer: "0.9", isCorrect: false },
      { answer: "0.1", isCorrect: false },
      { answer: "0.5", isCorrect: false },
    ],
  },
  {
    question:
      "In how many ways can 5 cards be selected from a deck of 52 cards?",
    answerOptions: [
      { answer: "2598960", isCorrect: true },
      { answer: "123456", isCorrect: false },
      { answer: "1489950", isCorrect: false },
      { answer: "649740", isCorrect: false },
    ],
  },
  {
    question:
      "A die is rolled. What is the probability of getting an odd number?",
    answerOptions: [
      { answer: "0.5", isCorrect: true },
      { answer: "0.25", isCorrect: false },
      { answer: "1", isCorrect: false },
      { answer: "0.75", isCorrect: false },
    ],
  },
  {
    question:
      "A bag contains 4 red balls and 6 blue balls. If a ball is drawn at random, what is the probability of getting a red ball?",
    answerOptions: [
      { answer: "0.4", isCorrect: true },
      { answer: "0.6", isCorrect: false },
      { answer: "0.1", isCorrect: false },
      { answer: "0.5", isCorrect: false },
    ],
  },
  {
    question: "Find the variance of the data: 2, 4, 4, 6, 8, 8, 10.",
    answerOptions: [
      { answer: "6", isCorrect: true },
      { answer: "4", isCorrect: false },
      { answer: "8", isCorrect: false },
      { answer: "5", isCorrect: false },
    ],
  },
  {
    question:
      "The probability of an event ( A ) happening is 0.4 and the probability of event ( B ) happening is 0.3. If ( A ) and ( B ) are mutually exclusive, find ( P(A cup B) ).",
    answerOptions: [
      { answer: "0.7", isCorrect: true },
      { answer: "0.1", isCorrect: false },
      { answer: "0.5", isCorrect: false },
      { answer: "1", isCorrect: false },
    ],
  },
  {
    question:
      "Find the probability that a card drawn from a well-shuffled deck of 52 cards is a queen or a king.",
    answerOptions: [
      { answer: "2/13", isCorrect: true },
      { answer: "1/13", isCorrect: false },
      { answer: "4/13", isCorrect: false },
      { answer: "1/52", isCorrect: false },
    ],
  },
  {
    question:
      "If the mean of a data set is 50 and the sum of all observations is 200, how many observations are in the data set?",
    answerOptions: [
      { answer: "4", isCorrect: true },
      { answer: "5", isCorrect: false },
      { answer: "8", isCorrect: false },
      { answer: "2", isCorrect: false },
    ],
  },
  {
    question:
      "A coin is tossed twice. What is the probability of getting at least one head?",
    answerOptions: [
      { answer: "3/4", isCorrect: true },
      { answer: "1/4", isCorrect: false },
      { answer: "1/2", isCorrect: false },
      { answer: "1", isCorrect: false },
    ],
  },
  {
    question:
      "A bag contains 3 white balls and 2 black balls. Two balls are drawn randomly. What is the probability that one is white and the other is black?",
    answerOptions: [
      { answer: "3/5", isCorrect: true },
      { answer: "1/5", isCorrect: false },
      { answer: "2/5", isCorrect: false },
      { answer: "4/5", isCorrect: false },
    ],
  },
  {
    question:
      "If the correlation coefficient between two variables ( x ) and ( y ) is 0, then:",
    answerOptions: [
      {
        answer: "There is no linear relationship between ( x ) and ( y )",
        isCorrect: true,
      },
      { answer: "There is a perfect linear relationship", isCorrect: false },
      { answer: "They are independent", isCorrect: false },
      { answer: "There is a strong relationship", isCorrect: false },
    ],
  },
  {
    question:
      "What is the probability of selecting a prime number from the numbers 1 to 10?",
    answerOptions: [
      { answer: "2/5", isCorrect: true },
      { answer: "3/5", isCorrect: false },
      { answer: "1/2", isCorrect: false },
      { answer: "1/5", isCorrect: false },
    ],
  },
  {
    question: "Find the median of the data set: 2, 3, 5, 7, 11, 13, 17.",
    answerOptions: [
      { answer: "7", isCorrect: true },
      { answer: "11", isCorrect: false },
      { answer: "9", isCorrect: false },
      { answer: "5", isCorrect: false },
    ],
  },
  {
    question:
      "What is the probability of drawing a red card from a deck of 52 cards?",
    answerOptions: [
      { answer: "1/2", isCorrect: true },
      { answer: "1/4", isCorrect: false },
      { answer: "1/13", isCorrect: false },
      { answer: "1/26", isCorrect: false },
    ],
  },
  {
    question:
      "A die is rolled twice. What is the probability of getting a 6 on both rolls?",
    answerOptions: [
      { answer: "1/36", isCorrect: true },
      { answer: "1/6", isCorrect: false },
      { answer: "1/12", isCorrect: false },
      { answer: "1/18", isCorrect: false },
    ],
  },
  {
    question:
      "If two events ( A ) and ( B ) are such that ( P(A) = 0.6 ), ( P(B) = 0.7 ), and ( P(A cap B) = 0.4 ), find ( P(A cup B) ).",
    answerOptions: [
      { answer: "0.9", isCorrect: true },
      { answer: "1", isCorrect: false },
      { answer: "0.8", isCorrect: false },
      { answer: "0.7", isCorrect: false },
    ],
  },
  {
    question:
      "A bag contains 5 red, 4 blue, and 3 green balls. If a ball is drawn at random, what is the probability that it is blue?",
    answerOptions: [
      { answer: "4/12", isCorrect: false },
      { answer: "1/3", isCorrect: true },
      { answer: "1/4", isCorrect: false },
      { answer: "5/12", isCorrect: false },
    ],
  },
  {
    question:
      "A card is drawn from a standard deck of 52 cards. What is the probability that it is a face card (Jack, Queen, or King)?",
    answerOptions: [
      { answer: "3/13", isCorrect: true },
      { answer: "1/13", isCorrect: false },
      { answer: "1/4", isCorrect: false },
      { answer: "9/52", isCorrect: false },
    ],
  },
  {
    question:
      "What is the probability of getting an even number when rolling a fair die?",
    answerOptions: [
      { answer: "1/2", isCorrect: true },
      { answer: "1/3", isCorrect: false },
      { answer: "2/3", isCorrect: false },
      { answer: "1/6", isCorrect: false },
    ],
  },
  {
    question:
      "Two dice are rolled. What is the probability that the sum of the numbers is 7?",
    answerOptions: [
      { answer: "1/6", isCorrect: true },
      { answer: "1/12", isCorrect: false },
      { answer: "5/36", isCorrect: false },
      { answer: "1/36", isCorrect: false },
    ],
  },
  {
    question:
      "A coin is tossed 3 times. What is the probability of getting exactly 2 heads?",
    answerOptions: [
      { answer: "3/8", isCorrect: true },
      { answer: "1/8", isCorrect: false },
      { answer: "1/4", isCorrect: false },
      { answer: "1/2", isCorrect: false },
    ],
  },
  {
    question:
      "If two events A and B are independent, and ( P(A) = 0.3 ) and ( P(B) = 0.4 ), what is the probability of both events happening?",
    answerOptions: [
      { answer: "0.12", isCorrect: true },
      { answer: "0.7", isCorrect: false },
      { answer: "0.1", isCorrect: false },
      { answer: "0.75", isCorrect: false },
    ],
  },
  {
    question:
      "In how many ways can a committee of 3 people be chosen from a group of 7 people?",
    answerOptions: [
      { answer: "35", isCorrect: true },
      { answer: "21", isCorrect: false },
      { answer: "42", isCorrect: false },
      { answer: "49", isCorrect: false },
    ],
  },
  {
    question:
      "A die is rolled twice. What is the probability that the first roll is a 4 and the second roll is a 6?",
    answerOptions: [
      { answer: "1/36", isCorrect: true },
      { answer: "1/12", isCorrect: false },
      { answer: "1/6", isCorrect: false },
      { answer: "1/18", isCorrect: false },
    ],
  },
  {
    question:
      "A box contains 5 red, 6 green, and 7 blue balls. Two balls are drawn at random. What is the probability that both are red?",
    answerOptions: [
      { answer: "2/51", isCorrect: true },
      { answer: "5/51", isCorrect: false },
      { answer: "1/6", isCorrect: false },
      { answer: "1/15", isCorrect: false },
    ],
  },
  {
    question:
      "A card is drawn at random from a deck of 52 cards. What is the probability that it is either a heart or a king?",
    answerOptions: [
      { answer: "4/13", isCorrect: true },
      { answer: "1/13", isCorrect: false },
      { answer: "1/52", isCorrect: false },
      { answer: "1/26", isCorrect: false },
    ],
  },
  {
    question:
      "A bag contains 4 red balls and 5 black balls. If two balls are drawn at random, what is the probability that both balls are black?",
    answerOptions: [
      { answer: "5/18", isCorrect: true },
      { answer: "10/18", isCorrect: false },
      { answer: "4/9", isCorrect: false },
      { answer: "1/2", isCorrect: false },
    ],
  },
  {
    question:
      "Two events A and B are such that ( P(A) = 0.7 ), ( P(B) = 0.4 ), and ( P(A cap B) = 0.2 ). Find ( P(A cup B) ).",
    answerOptions: [
      { answer: "0.9", isCorrect: true },
      { answer: "0.7", isCorrect: false },
      { answer: "1.1", isCorrect: false },
      { answer: "0.3", isCorrect: false },
    ],
  },
  {
    question:
      "A bag contains 6 red balls and 4 white balls. Two balls are drawn without replacement. What is the probability that the first ball is red and the second is white?",
    answerOptions: [
      { answer: "4/15", isCorrect: true },
      { answer: "2/5", isCorrect: false },
      { answer: "3/5", isCorrect: false },
      { answer: "7/15", isCorrect: false },
    ],
  },
  {
    question:
      "What is the probability of getting at least one head when two coins are tossed?",
    answerOptions: [
      { answer: "3/4", isCorrect: true },
      { answer: "1/4", isCorrect: false },
      { answer: "1/2", isCorrect: false },
      { answer: "1", isCorrect: false },
    ],
  },
  {
    question:
      "A box contains 3 red, 4 white, and 5 blue balls. Two balls are drawn at random. What is the probability that both are white?",
    answerOptions: [
      { answer: "2/33", isCorrect: true },
      { answer: "4/33", isCorrect: false },
      { answer: "5/33", isCorrect: false },
      { answer: "1/33", isCorrect: false },
    ],
  },
  {
    question:
      "A number is selected at random from the numbers 1 to 100. What is the probability that the number is divisible by 5?",
    answerOptions: [
      { answer: "1/5", isCorrect: true },
      { answer: "1/10", isCorrect: false },
      { answer: "1/25", isCorrect: false },
      { answer: "1/50", isCorrect: false },
    ],
  },
  {
    question:
      "Two dice are rolled. What is the probability of getting a sum of 8?",
    answerOptions: [
      { answer: "5/36", isCorrect: true },
      { answer: "1/6", isCorrect: false },
      { answer: "1/36", isCorrect: false },
      { answer: "7/36", isCorrect: false },
    ],
  },
  {
    question:
      "A coin is tossed 4 times. What is the probability of getting exactly 3 heads?",
    answerOptions: [
      { answer: "1/4", isCorrect: true },
      { answer: "1/8", isCorrect: false },
      { answer: "1/2", isCorrect: false },
      { answer: "3/8", isCorrect: false },
    ],
  },
  {
    question:
      "A bag contains 10 red and 5 blue balls. If 3 balls are drawn at random, what is the probability that all are red?",
    answerOptions: [
      { answer: "12/91", isCorrect: true },
      { answer: "10/91", isCorrect: false },
      { answer: "7/91", isCorrect: false },
      { answer: "5/91", isCorrect: false },
    ],
  },
  {
    question:
      "Two cards are drawn from a deck of 52 cards. What is the probability that both cards are aces?",
    answerOptions: [
      { answer: "1/221", isCorrect: true },
      { answer: "1/52", isCorrect: false },
      { answer: "1/13", isCorrect: false },
      { answer: "1/169", isCorrect: false },
    ],
  },
  {
    question:
      "A bag contains 6 red, 3 blue, and 4 green balls. If a ball is drawn at random, what is the probability that it is neither red nor green?",
    answerOptions: [
      { answer: "3/13", isCorrect: true },
      { answer: "4/13", isCorrect: false },
      { answer: "6/13", isCorrect: false },
      { answer: "9/13", isCorrect: false },
    ],
  },
  {
    question:
      "A coin is tossed twice. What is the probability of getting exactly one head?",
    answerOptions: [
      { answer: "1/2", isCorrect: true },
      { answer: "1/4", isCorrect: false },
      { answer: "3/4", isCorrect: false },
      { answer: "1/8", isCorrect: false },
    ],
  },
  {
    question:
      "Two cards are drawn from a deck of 52 cards. What is the probability that both are black?",
    answerOptions: [
      { answer: "25/102", isCorrect: true },
      { answer: "26/102", isCorrect: false },
      { answer: "12/51", isCorrect: false },
      { answer: "1/2", isCorrect: false },
    ],
  },
  {
    question:
      "A die is rolled. What is the probability of getting a number less than 4?",
    answerOptions: [
      { answer: "1/2", isCorrect: true },
      { answer: "1/3", isCorrect: false },
      { answer: "2/3", isCorrect: false },
      { answer: "1/6", isCorrect: false },
    ],
  },
  {
    question:
      "What is the probability of drawing an ace from a deck of 52 cards?",
    answerOptions: [
      { answer: "1/13", isCorrect: true },
      { answer: "1/52", isCorrect: false },
      { answer: "4/13", isCorrect: false },
      { answer: "1/4", isCorrect: false },
    ],
  },
  {
    question:
      "A number is selected at random from 1 to 20. What is the probability that the number is divisible by 4?",
    answerOptions: [
      { answer: "1/5", isCorrect: true },
      { answer: "1/4", isCorrect: false },
      { answer: "1/2", isCorrect: false },
      { answer: "1/10", isCorrect: false },
    ],
  },
  {
    question:
      "A coin is tossed 5 times. What is the probability of getting exactly 3 heads?",
    answerOptions: [
      { answer: "5/16", isCorrect: true },
      { answer: "1/16", isCorrect: false },
      { answer: "1/2", isCorrect: false },
      { answer: "3/8", isCorrect: false },
    ],
  },
  {
    question:
      "In a class of 30 students, 18 study mathematics and 12 study physics. If a student is chosen at random, what is the probability that the student studies mathematics or physics, given that 10 students study both subjects?",
    answerOptions: [
      { answer: "2/3", isCorrect: true },
      { answer: "1/2", isCorrect: false },
      { answer: "3/5", isCorrect: false },
      { answer: "5/6", isCorrect: false },
    ],
  },
  {
    question:
      "A fair die is rolled twice. What is the probability of getting a sum of 9?",
    answerOptions: [
      { answer: "1/9", isCorrect: true },
      { answer: "1/6", isCorrect: false },
      { answer: "1/8", isCorrect: false },
      { answer: "1/12", isCorrect: false },
    ],
  },
  {
    question:
      "In how many ways can a committee of 4 members be formed from a group of 10 people?",
    answerOptions: [
      { answer: "210", isCorrect: true },
      { answer: "120", isCorrect: false },
      { answer: "180", isCorrect: false },
      { answer: "100", isCorrect: false },
    ],
  },
  {
    question:
      "A jar contains 10 red marbles and 5 blue marbles. If two marbles are drawn at random without replacement, what is the probability that both are red?",
    answerOptions: [
      { answer: "3/7", isCorrect: true },
      { answer: "2/7", isCorrect: false },
      { answer: "5/14", isCorrect: false },
      { answer: "1/7", isCorrect: false },
    ],
  },
  {
    question:
      "A bag contains 5 white, 4 black, and 3 red balls. If two balls are drawn at random, what is the probability that one is white and the other is black?",
    answerOptions: [
      { answer: "10/33", isCorrect: true },
      { answer: "5/33", isCorrect: false },
      { answer: "4/33", isCorrect: false },
      { answer: "8/33", isCorrect: false },
    ],
  },
  {
    question:
      "In how many ways can a group of 3 boys and 2 girls be selected from 5 boys and 4 girls?",
    answerOptions: [
      { answer: "60", isCorrect: true },
      { answer: "20", isCorrect: false },
      { answer: "40", isCorrect: false },
      { answer: "30", isCorrect: false },
    ],
  },
  {
    question:
      "A coin is tossed 4 times. What is the probability of getting at least one head?",
    answerOptions: [
      { answer: "15/16", isCorrect: true },
      { answer: "1/2", isCorrect: false },
      { answer: "7/8", isCorrect: false },
      { answer: "13/16", isCorrect: false },
    ],
  },
  {
    question:
      "A box contains 8 balls numbered from 1 to 8. What is the probability of selecting a ball with an even number?",
    answerOptions: [
      { answer: "1/2", isCorrect: true },
      { answer: "1/4", isCorrect: false },
      { answer: "1/8", isCorrect: false },
      { answer: "3/8", isCorrect: false },
    ],
  },
  {
    question:
      "A die is rolled twice. What is the probability of getting a 6 on at least one of the rolls?",
    answerOptions: [
      { answer: "11/36", isCorrect: true },
      { answer: "1/6", isCorrect: false },
      { answer: "1/12", isCorrect: false },
      { answer: "25/36", isCorrect: false },
    ],
  },
  {
    question:
      "If two dice are rolled, what is the probability that the sum is less than 5?",
    answerOptions: [
      { answer: "1/9", isCorrect: true },
      { answer: "1/6", isCorrect: false },
      { answer: "1/3", isCorrect: false },
      { answer: "1/2", isCorrect: false },
    ],
  },
  {
    question:
      "A student guesses on all 5 questions of a true/false test. What is the probability that the student gets exactly 3 correct answers?",
    answerOptions: [
      { answer: "5/16", isCorrect: true },
      { answer: "1/16", isCorrect: false },
      { answer: "1/2", isCorrect: false },
      { answer: "3/8", isCorrect: false },
    ],
  },
  {
    question:
      "A fair coin is tossed 4 times. What is the probability of getting exactly 2 heads?",
    answerOptions: [
      { answer: "3/8", isCorrect: true },
      { answer: "1/4", isCorrect: false },
      { answer: "1/2", isCorrect: false },
      { answer: "1/8", isCorrect: false },
    ],
  },
  {
    question:
      "A die is rolled. What is the probability of getting an odd number?",
    answerOptions: [
      { answer: "1/2", isCorrect: true },
      { answer: "1/3", isCorrect: false },
      { answer: "1/6", isCorrect: false },
      { answer: "2/3", isCorrect: false },
    ],
  },
  {
    question:
      "If two fair dice are thrown, what is the probability of getting a sum of 8?",
    answerOptions: [
      { answer: "5/36", isCorrect: true },
      { answer: "1/6", isCorrect: false },
      { answer: "7/36", isCorrect: false },
      { answer: "1/12", isCorrect: false },
    ],
  },
  {
    question:
      "In a group of 3 men and 2 women, 2 persons are selected at random. What is the probability that both are men?",
    answerOptions: [
      { answer: "3/10", isCorrect: true },
      { answer: "1/2", isCorrect: false },
      { answer: "3/5", isCorrect: false },
      { answer: "1/5", isCorrect: false },
    ],
  },
  {
    question:
      "A bag contains 5 red, 4 green, and 3 blue balls. If two balls are drawn at random, what is the probability that both balls are red?",
    answerOptions: [
      { answer: "2/33", isCorrect: true },
      { answer: "5/33", isCorrect: false },
      { answer: "1/11", isCorrect: false },
      { answer: "1/3", isCorrect: false },
    ],
  },
  {
    question:
      "If two cards are drawn at random from a deck of 52 cards, what is the probability that both are kings?",
    answerOptions: [
      { answer: "1/221", isCorrect: true },
      { answer: "1/169", isCorrect: false },
      { answer: "1/169", isCorrect: false },
      { answer: "1/221", isCorrect: false },
    ],
  },
  {
    question:
      "What is the probability of drawing a queen or a heart from a deck of 52 cards?",
    answerOptions: [
      { answer: "4/13", isCorrect: true },
      { answer: "7/52", isCorrect: false },
      { answer: "1/4", isCorrect: false },
      { answer: "3/13", isCorrect: false },
    ],
  },
  {
    question:
      "A coin is tossed 3 times. What is the probability of getting exactly 2 heads?",
    answerOptions: [
      { answer: "3/8", isCorrect: true },
      { answer: "1/2", isCorrect: false },
      { answer: "1/4", isCorrect: false },
      { answer: "5/8", isCorrect: false },
    ],
  },
  {
    question:
      "The mean of 5 numbers is 20. If one number is excluded, the mean becomes 16. What is the excluded number?",
    answerOptions: [
      { answer: "36", isCorrect: true },
      { answer: "30", isCorrect: false },
      { answer: "25", isCorrect: false },
      { answer: "20", isCorrect: false },
    ],
  },
  {
    question:
      "If the probability of event A occurring is 0.4, and the probability of event B occurring is 0.6, and events A and B are independent, what is the probability of both events occurring?",
    answerOptions: [
      { answer: "0.24", isCorrect: true },
      { answer: "0.60", isCorrect: false },
      { answer: "0.36", isCorrect: false },
      { answer: "0.12", isCorrect: false },
    ],
  },
  {
    question:
      "What is the probability that a randomly chosen person was born in a leap year?",
    answerOptions: [
      { answer: "1/4", isCorrect: true },
      { answer: "1/2", isCorrect: false },
      { answer: "1/8", isCorrect: false },
      { answer: "1/3", isCorrect: false },
    ],
  },
  {
    question:
      "A die is rolled twice. What is the probability of getting a sum of 9?",
    answerOptions: [
      { answer: "1/9", isCorrect: true },
      { answer: "1/6", isCorrect: false },
      { answer: "1/12", isCorrect: false },
      { answer: "5/36", isCorrect: false },
    ],
  },
  {
    question:
      "If the probability of a defective item in a production line is 0.1, what is the probability of getting exactly 1 defective item in a sample of 3?",
    answerOptions: [
      { answer: "0.243", isCorrect: true },
      { answer: "0.3", isCorrect: false },
      { answer: "0.27", isCorrect: false },
      { answer: "0.09", isCorrect: false },
    ],
  },
  {
    question:
      "The mean and variance of a data set are 10 and 4, respectively. If 2 is added to each observation, what will be the new mean?",
    answerOptions: [
      { answer: "12", isCorrect: true },
      { answer: "10", isCorrect: false },
      { answer: "14", isCorrect: false },
      { answer: "8", isCorrect: false },
    ],
  },
  {
    question: "What is the variance of the first 10 natural numbers?",
    answerOptions: [
      { answer: "8.25", isCorrect: true },
      { answer: "9.5", isCorrect: false },
      { answer: "7", isCorrect: false },
      { answer: "6.5", isCorrect: false },
    ],
  },
  {
    question:
      "If a fair coin is tossed 4 times, what is the probability of getting at least one head?",
    answerOptions: [
      { answer: "15/16", isCorrect: true },
      { answer: "3/4", isCorrect: false },
      { answer: "1/2", isCorrect: false },
      { answer: "1/4", isCorrect: false },
    ],
  },
  {
    question:
      "A bag contains 6 white and 4 black balls. Two balls are drawn at random. What is the probability that they are of different colors?",
    answerOptions: [
      { answer: "12/45", isCorrect: false },
      { answer: "24/45", isCorrect: true },
      { answer: "1/2", isCorrect: false },
      { answer: "1/3", isCorrect: false },
    ],
  },
  {
    question:
      "The probability that a randomly chosen person speaks both English and French is 0.3. The probability that a person speaks English is 0.6, and French is 0.5. What is the probability that a person speaks at least one of the two languages?",
    answerOptions: [
      { answer: "0.8", isCorrect: true },
      { answer: "0.7", isCorrect: false },
      { answer: "0.6", isCorrect: false },
      { answer: "0.9", isCorrect: false },
    ],
  },
  {
    question:
      "The probability of getting exactly two heads in three tosses of a fair coin is:",
    answerOptions: [
      { answer: "3/8", isCorrect: true },
      { answer: "1/2", isCorrect: false },
      { answer: "5/8", isCorrect: false },
      { answer: "1/4", isCorrect: false },
    ],
  },
  {
    question:
      "If the correlation coefficient between two variables is 0.8, what is the coefficient of determination?",
    answerOptions: [
      { answer: "0.64", isCorrect: true },
      { answer: "0.8", isCorrect: false },
      { answer: "0.6", isCorrect: false },
      { answer: "0.4", isCorrect: false },
    ],
  },
  {
    question:
      "If P(A) = 0.4 and P(B) = 0.5, and P(A ∩ B) = 0.2, what is P(A ∪ B)?",
    answerOptions: [
      { answer: "0.7", isCorrect: true },
      { answer: "0.9", isCorrect: false },
      { answer: "0.6", isCorrect: false },
      { answer: "0.5", isCorrect: false },
    ],
  },
  {
    question:
      "What is the probability of selecting a king or a queen from a standard deck of cards?",
    answerOptions: [
      { answer: "2/13", isCorrect: true },
      { answer: "1/13", isCorrect: false },
      { answer: "4/13", isCorrect: false },
      { answer: "3/13", isCorrect: false },
    ],
  },
  {
    question:
      "A factory produces 500 light bulbs, and 20 of them are defective. If a customer buys 3 bulbs, what is the probability that none of them are defective?",
    answerOptions: [
      { answer: "0.882", isCorrect: true },
      { answer: "0.920", isCorrect: false },
      { answer: "0.950", isCorrect: false },
      { answer: "0.860", isCorrect: false },
    ],
  },
  {
    question:
      "In a survey, 60% of people prefer product A, and the rest prefer product B. If 5 people are selected randomly, what is the probability that exactly 3 prefer product A?",
    answerOptions: [
      { answer: "0.3456", isCorrect: true },
      { answer: "0.4567", isCorrect: false },
      { answer: "0.264", isCorrect: false },
      { answer: "0.513", isCorrect: false },
    ],
  },
  {
    question:
      "A company has 8 managers and 4 engineers. If a committee of 3 people is to be selected randomly, what is the probability that exactly 2 engineers are selected?",
    answerOptions: [
      { answer: "14/55", isCorrect: true },
      { answer: "12/55", isCorrect: false },
      { answer: "16/55", isCorrect: false },
      { answer: "18/55", isCorrect: false },
    ],
  },
  {
    question:
      "A retailer offers a promotion where a customer has a 10% chance of winning a prize. If 6 customers visit the store, what is the probability that exactly 2 win a prize?",
    answerOptions: [
      { answer: "0.0985", isCorrect: true },
      { answer: "0.2314", isCorrect: false },
      { answer: "0.1456", isCorrect: false },
      { answer: "0.0873", isCorrect: false },
    ],
  },
  {
    question:
      "In a medical test, 95% of the time the test accurately detects a disease, and 90% of the time it correctly identifies the absence of the disease. What is the probability that the test will give a false positive?",
    answerOptions: [
      { answer: "0.10", isCorrect: true },
      { answer: "0.05", isCorrect: false },
      { answer: "0.15", isCorrect: false },
      { answer: "0.20", isCorrect: false },
    ],
  },
  {
    question:
      "The probability that it rains on any given day is 0.3. If it rains, the probability that a person carries an umbrella is 0.8. What is the probability that it rains and the person carries an umbrella?",
    answerOptions: [
      { answer: "0.24", isCorrect: true },
      { answer: "0.12", isCorrect: false },
      { answer: "0.18", isCorrect: false },
      { answer: "0.28", isCorrect: false },
    ],
  },
  {
    question:
      "In a game, the probability that a player hits the target is 0.7. If the player takes 5 shots, what is the probability that they hit the target at least once?",
    answerOptions: [
      { answer: "0.9975", isCorrect: true },
      { answer: "0.875", isCorrect: false },
      { answer: "0.650", isCorrect: false },
      { answer: "0.735", isCorrect: false },
    ],
  },
  {
    question:
      "A train is late 25% of the time. If 3 trains arrive one after another, what is the probability that exactly one train is late?",
    answerOptions: [
      { answer: "0.4219", isCorrect: true },
      { answer: "0.1875", isCorrect: false },
      { answer: "0.3438", isCorrect: false },
      { answer: "0.625", isCorrect: false },
    ],
  },
  {
    question:
      "In a batch of 100 batteries, 5 are defective. If 3 batteries are selected at random, what is the probability that at least one of them is defective?",
    answerOptions: [
      { answer: "0.1411", isCorrect: true },
      { answer: "0.0985", isCorrect: false },
      { answer: "0.0863", isCorrect: false },
      { answer: "0.0658", isCorrect: false },
    ],
  },
  {
    question:
      "A factory has two machines. Machine A produces 60% of the items, and Machine B produces 40%. The probability that Machine A produces a defective item is 0.03, and the probability that Machine B produces a defective item is 0.05. If an item is chosen at random and is found to be defective, what is the probability that it was produced by Machine A?",
    answerOptions: [
      { answer: "0.5625", isCorrect: true },
      { answer: "0.4325", isCorrect: false },
      { answer: "0.3125", isCorrect: false },
      { answer: "0.475", isCorrect: false },
    ],
  },
];

export default statisticsquestions;
