const enviromenatalchequestions = [
    {
        question:"Ozone hole is maximum over  :",
        answerOptions: [
            {answer: 'Africa', isCorrect: false},
            {answer: 'Europe', isCorrect: false},
            {answer: 'Antractica', isCorrect: true},
            {answer: 'India', isCorrect: false},
        ],
    },  {
        question:"The greenhouse effect is caused by  :",
        answerOptions: [
            {answer: 'CO', isCorrect: false},
            {answer: 'NO', isCorrect: false},
            {answer: 'NO2', isCorrect: false},
            {answer: 'CO2', isCorrect: true},
        ],
    }, {
        question:"Surface water contains :",
        answerOptions: [
            {answer: 'only salt', isCorrect: false},
            {answer: 'organic matter', isCorrect: false},
            {answer: 'salt and organic matter', isCorrect: true},
            {answer: 'suspended matter ', isCorrect: false},
        ],
    },  {
        question:"Polluted atmosphere and hydrosphere affects  :",
        answerOptions: [
            {answer: 'atmosphere', isCorrect: false},
            {answer: 'expsphere', isCorrect: false},
            {answer: 'biosphere', isCorrect: true},
            {answer: 'none of these', isCorrect: false},
        ],
    },  {
        question:"Which air pollutant is not released by automobiles :",
        answerOptions: [
            {answer: 'fly ash', isCorrect: true},
            {answer: 'CO', isCorrect: false},
            {answer: 'SO2', isCorrect: false},
            {answer: 'hydrocarbon', isCorrect: false},
        ],
    },  {
        question:"Which of the following is not an air pollutan  :",
        answerOptions: [
            {answer: 'N2O', isCorrect: false},
            {answer: 'N2', isCorrect: true},
            {answer: 'CO', isCorrect: false},
            {answer: 'NO', isCorrect: false},
        ],
    }, {
        question:"Which of the following is not a greenhouse gas :",
        answerOptions: [
            {answer: 'water vapour', isCorrect: false},
            {answer: 'CO2', isCorrect: false},
            {answer: 'O2', isCorrect: true},
            {answer: 'CH4', isCorrect: false},
        ],
    },  {
        question:"Which of the following is a primary pollutant :",
        answerOptions: [
            {answer: 'PAN', isCorrect: false},
            {answer: 'aldehydes', isCorrect: false},
            {answer: 'CO', isCorrect: true},
            {answer: 'H2SO4', isCorrect: false},
        ],
    },  {
        question:"Major sources of NOx pollutants are :",
        answerOptions: [
            {answer: 'natural gas', isCorrect: false},
            {answer: 'gasonline', isCorrect: false},
            {answer: 'combusion of oil and coal', isCorrect: false},
            {answer: 'all of these', isCorrect: true},
        ],
    }, {
        question:"The upper most region of the atmosphere is :",
        answerOptions: [
            {answer: 'troposphere', isCorrect: false},
            {answer: 'exosphere', isCorrect: true},
            {answer: 'statosphere', isCorrect: false},
            {answer: 'ionosphere', isCorrect: false},
        ],
    },  {
        question:"The poisonous gas present in the exhaust fumes of car is  :",
        answerOptions: [
            {answer: 'CO2', isCorrect: false},
            {answer: 'CO', isCorrect: true},
            {answer: 'CH4', isCorrect: false},
            {answer: 'C2H2', isCorrect: false},
        ],
    }, {
        question:"The major components of the atmosphere are  :",
        answerOptions: [
            {answer: 'CO2 and N2', isCorrect: false},
            {answer: 'O2 and SO4', isCorrect: false},
            {answer: 'CO and CO2', isCorrect: false},
            {answer: 'N2 and O2', isCorrect: true},
        ],
    },  {
        question:"The medium which interacts with the long lived pollutant is :",
        answerOptions: [
            {answer: 'source', isCorrect: false},
            {answer: 'receptor', isCorrect: false},
            {answer: 'sink', isCorrect: false},
            {answer: 'all', isCorrect: true},
        ],
    }, {
        question:"Cyclon collector is used for minimizing  :",
        answerOptions: [
            {answer: 'water population', isCorrect: false},
            {answer: 'sound population', isCorrect: false},
            {answer: 'soil population', isCorrect: false},
            {answer: 'air population', isCorrect: true},
        ],
    }, {
        question:"Household fuel (LPG) mainly contains  :",
        answerOptions: [
            {answer: 'CH4', isCorrect: false},
            {answer: 'C2H2', isCorrect: false},
            {answer: 'C2H4', isCorrect: false},
            {answer: 'C2H10', isCorrect: true},
        ],
    },
]

export default enviromenatalchequestions;