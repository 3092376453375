const kenamaticsquestions = [
  {
    question:
      "An object travels from rest to a velocity of 20 m/s in 4 s. What is its acceleration?",
    answerOptions: [
      { answer: "5 m/s^2", isCorrect: true },
      { answer: "2.5 m/s^2", isCorrect: false },
      { answer: "1.25 m/s^2", isCorrect: false },
      { answer: "0.625 m/s^2", isCorrect: false },
    ],
  },
  {
    question:
      "An object moves with a constant velocity of 15 m/s for 6 s. What is its displacement?",
    answerOptions: [
      { answer: "90 m", isCorrect: true },
      { answer: "45 m", isCorrect: false },
      { answer: "22.5 m", isCorrect: false },
      { answer: "11.25 m", isCorrect: false },
    ],
  },
  {
    question:
      "An object accelerates from 0 to 30 m/s in 6 s. What is its average velocity?",
    answerOptions: [
      { answer: "15 m/s", isCorrect: true },
      { answer: "7.5 m/s", isCorrect: false },
      { answer: "3.75 m/s", isCorrect: false },
      { answer: "1.875 m/s", isCorrect: false },
    ],
  },
  {
    question:
      "A projectile is launched at an angle of 45° with an initial velocity of 20 m/s. What is its horizontal range?",
    answerOptions: [
      { answer: "40 m", isCorrect: true },
      { answer: "20 m", isCorrect: false },
      { answer: "10 m", isCorrect: false },
      { answer: "5 m", isCorrect: false },
    ],
  },
  {
    question:
      "A projectile is launched with an initial velocity of 30 m/s at an angle of 60°. What is its maximum height?",
    answerOptions: [
      { answer: "36.75 m", isCorrect: true },
      { answer: "18.375 m", isCorrect: false },
      { answer: "9.1875 m", isCorrect: false },
      { answer: "4.59375 m", isCorrect: false },
    ],
  },
  {
    question:
      "A projectile is launched with an initial velocity of 25 m/s at an angle of 30°. What is its time of flight?",
    answerOptions: [
      { answer: "5 s", isCorrect: true },
      { answer: "2.5 s", isCorrect: false },
      { answer: "1.25 s", isCorrect: false },
      { answer: "0.625 s", isCorrect: false },
    ],
  },
  {
    question:
      "A projectile is launched at an angle of 60° with an initial velocity of 40 m/s. What is its horizontal component of velocity?",
    answerOptions: [
      { answer: "20 m/s", isCorrect: true },
      { answer: "10 m/s", isCorrect: false },
      { answer: "5 m/s", isCorrect: false },
      { answer: "2.5 m/s", isCorrect: false },
    ],
  },
  {
    question:
      "A projectile is launched with an initial velocity of 35 m/s at an angle of 45°. What is its vertical component of velocity?",
    answerOptions: [
      { answer: "24.75 m/s", isCorrect: true },
      { answer: "12.375 m/s", isCorrect: false },
      { answer: "6.1875 m/s", isCorrect: false },
      { answer: "3.09375 m/s", isCorrect: false },
    ],
  },
  {
    question:
      "A projectile is launched at an angle of 30° with an initial velocity of 50 m/s. What is its maximum horizontal range?",
    answerOptions: [
      { answer: "125 m", isCorrect: true },
      { answer: "62.5 m", isCorrect: false },
      { answer: "31.25 m", isCorrect: false },
      { answer: "15.625 m", isCorrect: false },
    ],
  },
  {
    question:
      "A projectile is launched with an initial velocity of 45 m/s at an angle of 60°. What is its time of flight?",
    answerOptions: [
      { answer: "6.25 s", isCorrect: true },
      { answer: "3.125 s", isCorrect: false },
      { answer: "1.5625 s", isCorrect: false },
      { answer: "0.78125 s", isCorrect: false },
    ],
  },
  {
    question:
      "A paticles moves in a straight line with initial velocity 4 m/s and a constant acceleration of 6 m/s^2. Find the average velocity of the particles in a time from t = 0 to t = 2s",
    answerOptions: [
      { answer: "0 m/s", isCorrect: false },
      { answer: "10 m/s", isCorrect: true },
      { answer: "1 m/s", isCorrect: false },
      { answer: "20 m/s", isCorrect: false },
    ],
  },
  {
    question:
      " A packet is released from a ballon accelerating upward with acceleration a. The acceleration of the stone just after the release is :",
    answerOptions: [
      { answer: "a upward", isCorrect: false },
      { answer: "g downword", isCorrect: true },
      { answer: "(g-a) downward", isCorrect: false },
      { answer: "(g+a) downword", isCorrect: false },
    ],
  },
  {
    question:
      "A particles moving along a stringht line travels half of the distance woth uniform speed 30 m/s and the ramaing half of the distance with speed 60 m/s. The average speed of the particles is :",
    answerOptions: [
      { answer: "45 m/s", isCorrect: false },
      { answer: "42 m/s", isCorrect: false },
      { answer: "40 m/s", isCorrect: true },
      { answer: "50 m/s", isCorrect: false },
    ],
  },
  {
    question:
      "An ant is at corner of a cubical room of side a. The ant can move with a constant speed u. The minimum time taken to reach the farthest corner of the cube is :",
    answerOptions: [
      { answer: "3a/u", isCorrect: true },
      { answer: "√ a/u", isCorrect: false },
      { answer: "√5 a/u", isCorrect: false },
      { answer: "(2+1)a/u", isCorrect: false },
    ],
  },
  {
    question:
      "A gun is firing bullets with velocity v by rotating through 360° in the horizontal plane. The maximum area covered by the the bullets is :",
    answerOptions: [
      { answer: "π v²/g", isCorrect: false },
      { answer: "π² v²/g", isCorrect: false },
      { answer: "π v^4/g²", isCorrect: true },
      { answer: "π² v^4/g", isCorrect: false },
    ],
  },
  {
    question:
      "A particles moves in the x-y plane with velocity vx = 8t -2 and vy = 2. If it passes through the point a = 14 and y = 4 at t = 2s, the equation of the path is :",
    answerOptions: [
      { answer: "x=y²-y+2", isCorrect: true },
      { answer: "x=y²-2", isCorrect: false },
      { answer: "x=y²+y-6", isCorrect: false },
      { answer: "None of these", isCorrect: false },
    ],
  },
  {
    question:
      "A particle is fired horiontally from an inclination 30° with horizontal with speed 50 m/s. If g = 10m/s, the measeured along the inclined is :",
    answerOptions: [
      { answer: "500 m", isCorrect: false },
      { answer: "1000/3 m", isCorrect: true },
      { answer: "200 m", isCorrect: false },
      { answer: "100 m", isCorrect: false },
    ],
  },
  {
    question:
      "A lift performs the first part of its ascent with uniform acceleration a and the remaining with uniform retardation 2a. If t is the time of ascent, find the depth of the shaft :",
    answerOptions: [
      { answer: "at^2/4", isCorrect: true },
      { answer: "at^2/3", isCorrect: false },
      { answer: "at^/2", isCorrect: false },
      { answer: "at^/8s", isCorrect: false },
    ],
  },
  {
    question:
      "A particle is projected vertically upward an d reach the maximum height H in time T. The height of the particle in any time (<T) will be :",
    answerOptions: [
      { answer: "(g-T)²", isCorrect: false },
      { answer: "H-g(t-T)²", isCorrect: false },
      { answer: "1/2g(t-T)²", isCorrect: false },
      { answer: "H-1/2g(T-t)²", isCorrect: true },
    ],
  },
  {
    question:
      "The relation between time t displacement x is t=ax^2+bx where a and b are constants. The retardation is :",
    answerOptions: [
      { answer: "2av^3", isCorrect: true },
      { answer: "2bv^3", isCorrect: false },
      { answer: "2abv^3", isCorrect: false },
      { answer: "2b^3v^3", isCorrect: false },
    ],
  },
  {
    question:
      "If T1 and  T2 are time of flight for two complementry angles, then the range of projectile R is given by :",
    answerOptions: [
      { answer: "R = 4gT1T2", isCorrect: false },
      { answer: "R = 2gT1T2", isCorrect: false },
      { answer: "R = 1/4gT1T2", isCorrect: false },
      { answer: "R = 1/2gT1T2", isCorrect: true },
    ],
  },
  {
    question:
      "A grasshipper can jump maximumm distance 1.6 m. It spends negilable time on ground. How far can it go in 10√2 s :",
    answerOptions: [
      { answer: "45 m", isCorrect: false },
      { answer: "30 m", isCorrect: false },
      { answer: "20 m", isCorrect: false },
      { answer: "40 m", isCorrect: true },
    ],
  },
  {
    question:
      "Two bodies are thrown with same initial velocilty at the angle x and (90-x) respectively with the horizontal, then there maximum height are in the ratio :",
    answerOptions: [
      { answer: "1 : 1", isCorrect: false },
      { answer: "sin x : cos x", isCorrect: false },
      { answer: "sin² x : cos² x", isCorrect: true },
      { answer: "cos x : sin x", isCorrect: false },
    ],
  },
  {
    question:
      "Two objects are moving along the same straight line. They cross a point A with an acceleration a, 2a and velocity 2u, u at time t = 0. The distance moved by the object when one overtakes the other is :",
    answerOptions: [
      { answer: "6u^2/a", isCorrect: true },
      { answer: "2u^2/a", isCorrect: false },
      { answer: "4u^2/a", isCorrect: false },
      { answer: "8u^2/a", isCorrect: false },
    ],
  },
  {
    question:
      "A particle has initial velocity, v = 3i+4j and a constant force F = 4i+3j acts on it. The path of the particle is :",
    answerOptions: [
      { answer: "straight line", isCorrect: false },
      { answer: "parabolic", isCorrect: true },
      { answer: "circular", isCorrect: false },
      { answer: "elliptical", isCorrect: false },
    ],
  },
  {
    question:
      "A ball is thrown vertically upward from the ground. if T1 and T2 are the respective time taken in going up and coming down, and the air resistance in not ignored, then",
    answerOptions: [
      { answer: "T1>T2", isCorrect: false },
      { answer: "T1=T2", isCorrect: false },
      { answer: "T1<T2", isCorrect: true },
      { answer: "none of the above", isCorrect: false },
    ],
  },
  {
    question:
      "A ball is projected with a velocity 20 m/s at an to the horizontal. In order to have a maximum range. Its velocity at heightest point must be :",
    answerOptions: [
      { answer: "10 m/s", isCorrect: false },
      { answer: "14 m/s", isCorrect: true },
      { answer: "18 m/s", isCorrect: false },
      { answer: "16 m/s", isCorrect: false },
    ],
  },
  {
    question:
      "A particle moves along the parabolic path x = y^2+2y+2 in such a way that Y-components of velocity vector remains 5 m/s during the motion. The maginute of the acceleration of the particle is :",
    answerOptions: [
      { answer: "50 m/s", isCorrect: true },
      { answer: "100 m/s", isCorrect: false },
      { answer: "10 m/s", isCorrect: false },
      { answer: "0.1m/s", isCorrect: false },
    ],
  },
  {
    question:
      "A car starts from the rest and accelerates at constant rate in straight line. In the first second the car covered a distance of 2m. The velocity of car at the end of second will be : ",
    answerOptions: [
      { answer: "4.0 m/s", isCorrect: false },
      { answer: "8.0 m/s", isCorrect: true },
      { answer: "16 m/s", isCorrect: false },
      { answer: "16.0 m/s", isCorrect: false },
    ],
  },
  {
    question:
      "A particle starts from the origin of the cocordinates at time t = 0 and moves in the xy-plane with a constant acceleration a in the y-direction. Its equation of motion is y = bx^2. Its velocity components in the x-direction is :",
    answerOptions: [
      { answer: "variable", isCorrect: false },
      { answer: "√2a/b", isCorrect: false },
      { answer: "a/2b", isCorrect: false },
      { answer: "√a/2b", isCorrect: true },
    ],
  },
  {
    question:
      "When a ball is thrown up vertically with the velocity v, it reach the maximum height of h. If one wishes to triple the maximum height then the ball should be thrown with velocity :",
    answerOptions: [
      { answer: "√ v", isCorrect: true },
      { answer: "3 v", isCorrect: false },
      { answer: "9 v", isCorrect: false },
      { answer: "3/2 v", isCorrect: false },
    ],
  },
  {
    question:
      "A stone is dropped from the top of a tower and one second leter, a second stone is thrown vertically downward with a velocity 20 m/s. The second stone will overtake the first ster travelling a distance of : (g=10m/s)",
    answerOptions: [
      { answer: "13 m", isCorrect: false },
      { answer: "15 m", isCorrect: false },
      { answer: "11.25 m", isCorrect: true },
      { answer: "4.00 m", isCorrect: false },
    ],
  },
  {
    question:
      "A particles is projected from the ground with initial velocity u = 20√2 m/s at  x = 45°. Find R, H and T :",
    answerOptions: [
      { answer: "80m, 20m 4s", isCorrect: true },
      { answer: "60m, 30m 1s", isCorrect: false },
      { answer: "50m, 40m 8s", isCorrect: false },
      { answer: "30m, 10m 2s", isCorrect: false },
    ],
  },
  {
    question:
      " The horizontal and vertical displacements of a particle of a moving along a curved line are given by x = 5t and y = 2t^2+t. Time after which its velocity vector makes an angle of 45° with the horizontal is :",
    answerOptions: [
      { answer: "0.5 s", isCorrect: false },
      { answer: "1 s", isCorrect: false },
      { answer: "2 s", isCorrect: false },
      { answer: "1.5 s", isCorrect: true },
    ],
  },
  {
    question:
      "(Xnetous challange) A particle is thrown over a triangle from one end of a horizontal base and after grazing the vertex falls on the other end of the base. If a and b be the base angles and angle of projection, prove that tanx will be :",
    answerOptions: [
      { answer: "tan a + tan b", isCorrect: true },
      { answer: "tan b", isCorrect: false },
      { answer: "tan a - tan b", isCorrect: false },
      { answer: "tan a * tan b", isCorrect: false },
    ],
  },
  {
    question:
      "A car is travelling on a straight road. The maximum velocity the car can attains in 24 m/s. The maximum acceleration and deceleration it can attain are 1 m/s and 4 m/s respectively. The shoetest time the car takes from rest in a distance of 200 m is :",
    answerOptions: [
      { answer: "22.4 s", isCorrect: true },
      { answer: "30 s", isCorrect: false },
      { answer: "11.2 s", isCorrect: false },
      { answer: "5.6 s", isCorrect: false },
    ],
  },
  {
    question:
      "A particle is moving in x-y planes such that v(x) = 4+4t and v(y) = 4t. If the initial position of the particles is (1, 2). Then the equation of trajectory will be :",
    answerOptions: [
      { answer: "y^2 = 4x", isCorrect: false },
      { answer: "y = 2x", isCorrect: false },
      { answer: "x^2 = y/2", isCorrect: false },
      { answer: "None of these", isCorrect: true },
    ],
  },
  {
    question:
      "A river is flowing from west to east with a speed of 5 m/min. A man can swim in still water with a velocity 10 m/min. In which direction should the man swim so as to take the shortest possible path to go the south :",
    answerOptions: [
      { answer: "30° east to south", isCorrect: true },
      { answer: "30° west to south", isCorrect: true },
      { answer: "south", isCorrect: false },
      { answer: "30° west to north", isCorrect: false },
    ],
  },
  {
    question:
      "The range of a projectile at an angle x is equal to half of the maximum range if thrown at the same speed. The angle of projection x is given by :",
    answerOptions: [
      { answer: "15°", isCorrect: true },
      { answer: "30°", isCorrect: false },
      { answer: "60°", isCorrect: false },
      { answer: "option insufficient", isCorrect: false },
    ],
  },
  {
    question:
      "A particle is thrown upwards from ground. It experiences a constant resistance force which can produce a retardation of 2 m/s. The ratio of time of ascent to time of descent is (g = 10 m/s) :",
    answerOptions: [
      { answer: "1 : 1", isCorrect: false },
      { answer: "√ 2/3", isCorrect: true },
      { answer: "2/3", isCorrect: false },
      { answer: "√ 3/2", isCorrect: false },
    ],
  },
  {
    question:
      "A car accelerates uniformly from rest to 20 m/s in 5 seconds. What is its acceleration?",
    answerOptions: [
      { answer: "4 m/s^2", isCorrect: true },
      { answer: "2 m/s^2", isCorrect: false },
      { answer: "10 m/s^2", isCorrect: false },
      { answer: "5 m/s^2", isCorrect: false },
    ],
  },

  {
    question:
      "A ball is thrown upward with an initial velocity of 20 m/s. How long does it take to reach its maximum height?",
    answerOptions: [
      { answer: "2 seconds", isCorrect: true },
      { answer: "4 seconds", isCorrect: false },
      { answer: "1 second", isCorrect: false },
      { answer: "3 seconds", isCorrect: false },
    ],
  },
  {
    question:
      "A car travels at a constant speed of 30 m/s for 2 minutes. How far does it travel?",
    answerOptions: [
      { answer: "3600 meters", isCorrect: true },
      { answer: "1800 meters", isCorrect: false },
      { answer: "900 meters", isCorrect: false },
      { answer: "450 meters", isCorrect: false },
    ],
  },

  {
    question:
      "A ball is dropped from a height of 20 meters. How long does it take to reach the ground?",
    answerOptions: [
      { answer: "2 seconds", isCorrect: true },
      { answer: "4 seconds", isCorrect: false },
      { answer: "1 second", isCorrect: false },
      { answer: "3 seconds", isCorrect: false },
    ],
  },

  {
    question:
      "A car decelerates uniformly from 20 m/s to 0 m/s in 5 seconds. What is its deceleration?",
    answerOptions: [
      { answer: "4 m/s^2", isCorrect: true },
      { answer: "2 m/s^2", isCorrect: false },
      { answer: "10 m/s^2", isCorrect: false },
      { answer: "5 m/s^2", isCorrect: false },
    ],
  },

  {
    question:
      "A ball is thrown horizontally from a cliff with a speed of 10 m/s. If it takes 2 seconds to reach the ground, what is the height of the cliff?",
    answerOptions: [
      { answer: "20 meters", isCorrect: true },
      { answer: "40 meters", isCorrect: false },
      { answer: "10 meters", isCorrect: false },
      { answer: "30 meters", isCorrect: false },
    ],
  },

  {
    question:
      "A ball is kicked at an angle of 30 degrees to the horizontal with a speed of 20 m/s. What is the maximum height reached by the ball?",
    answerOptions: [
      { answer: "5 meters", isCorrect: true },
      { answer: "10 meters", isCorrect: false },
      { answer: "15 meters", isCorrect: false },
      { answer: "20 meters", isCorrect: false },
    ],
  },

  {
    question:
      "A projectile is launched at an angle of 45 degrees to the horizontal with a speed of 30 m/s. What is the horizontal range of the projectile?",
    answerOptions: [
      { answer: "90 meters", isCorrect: true },
      { answer: "180 meters", isCorrect: false },
      { answer: "45 meters", isCorrect: false },
      { answer: "60 meters", isCorrect: false },
    ],
  },

  {
    question:
      "A ball is thrown upward at an angle of 60 degrees to the horizontal with a speed of 20 m/s. What is the time of flight of the ball?",
    answerOptions: [
      { answer: "4 seconds", isCorrect: true },
      { answer: "2 seconds", isCorrect: false },
      { answer: "3 seconds", isCorrect: false },
      { answer: "1 second", isCorrect: false },
    ],
  },

  {
    question:
      "A projectile is launched at an angle of 30 degrees to the horizontal with a speed of 40 m/s. What is the maximum horizontal distance traveled by the projectile?",
    answerOptions: [
      { answer: "160 meters", isCorrect: true },
      { answer: "80 meters", isCorrect: false },
      { answer: "120 meters", isCorrect: false },
      { answer: "200 meters", isCorrect: false },
    ],
  },

  {
    question:
      "A river is flowing from west to east with a speed of 5 m/min. A man can swim in still water with a velocity 10 m/min. In which direction should the man swim so as to take the shortest possible path to go south?",
    answerOptions: [
      { answer: "30° east to south", isCorrect: true },
      { answer: "30° west to south", isCorrect: false },
      { answer: "south", isCorrect: false },
      { answer: "30° west to north", isCorrect: false },
    ],
  },

  {
    question:
      "A boat can travel upstream at 8 km/h and downstream at 12 km/h. What is the speed of the boat in still water?",
    answerOptions: [
      { answer: "10 km/h", isCorrect: true },
      { answer: "8 km/h", isCorrect: false },
      { answer: "12 km/h", isCorrect: false },
      { answer: "6 km/h", isCorrect: false },
    ],
  },

  {
    question:
      "A train 100 meters long is moving at a speed of 36 km/h. It crosses a bridge 200 meters long. Find the time taken to cross the bridge.",
    answerOptions: [
      { answer: "30 seconds", isCorrect: true },
      { answer: "20 seconds", isCorrect: false },
      { answer: "10 seconds", isCorrect: false },
      { answer: "40 seconds", isCorrect: false },
    ],
  },

  {
    question:
      "A car is traveling at a speed of 72 km/h. The driver applies the brakes and the car comes to rest in 5 seconds. Find the acceleration of the car.",
    answerOptions: [
      { answer: "-4 m/s^2", isCorrect: true },
      { answer: "4 m/s^2", isCorrect: false },
      { answer: "-2 m/s^2", isCorrect: false },
      { answer: "2 m/s^2", isCorrect: false },
    ],
  },

  {
    question:
      "A person standing on a railway platform observes that a train 100 meters long crosses the platform in 10 seconds. If the train is moving at a speed of 36 km/h, find the length of the platform.",
    answerOptions: [
      { answer: "50 meters", isCorrect: true },
      { answer: "100 meters", isCorrect: false },
      { answer: "150 meters", isCorrect: false },
      { answer: "200 meters", isCorrect: false },
    ],
  },

  {
    question:
      "A car starts from rest and accelerates uniformly at 2 m/s^2 for 10 seconds. Find the distance traveled by the car in the 10th second.",
    answerOptions: [
      { answer: "19 meters", isCorrect: true },
      { answer: "20 meters", isCorrect: false },
      { answer: "21 meters", isCorrect: false },
      { answer: "22 meters", isCorrect: false },
    ],
  },

  {
    question:
      "A ball is thrown vertically upward with an initial velocity of 20 m/s. Find the maximum height reached by the ball.",
    answerOptions: [
      { answer: "20 meters", isCorrect: true },
      { answer: "40 meters", isCorrect: false },
      { answer: "30 meters", isCorrect: false },
      { answer: "10 meters", isCorrect: false },
    ],
  },

  {
    question:
      "A stone is dropped from a height of 100 meters. Find the time taken by the stone to reach the ground.",
    answerOptions: [
      { answer: "10 seconds", isCorrect: true },
      { answer: "5 seconds", isCorrect: false },
      { answer: "20 seconds", isCorrect: false },
      { answer: "15 seconds", isCorrect: false },
    ],
  },

  {
    question:
      "A ball is thrown horizontally with a speed of 20 m/s from a height of 80 meters. Find the time taken by the ball to reach the ground.",
    answerOptions: [
      { answer: "4 seconds", isCorrect: true },
      { answer: "2 seconds", isCorrect: false },
      { answer: "6 seconds", isCorrect: false },
      { answer: "8 seconds", isCorrect: false },
    ],
  },

  {
    question:
      "A car is moving at a speed of 36 km/h. If the car is brought to rest in 10 seconds, find the distance traveled by the car during this time.",
    answerOptions: [
      { answer: "50 meters", isCorrect: true },
      { answer: "100 meters", isCorrect: false },
      { answer: "150 meters", isCorrect: false },
      { answer: "200 meters", isCorrect: false },
    ],
  },
  {
    question:
      "A car accelerates from 0 to 60 mph in 10 seconds. What is its average acceleration?",
    answerOptions: [
      { answer: "6.67 m/s^2", isCorrect: true },
      { answer: "3.33 m/s^2", isCorrect: false },
      { answer: "1.67 m/s^2", isCorrect: false },
      { answer: "0.83 m/s^2", isCorrect: false },
    ],
  },
  {
    question:
      "A bicycle travels at a constant velocity of 20 mph for 2 hours. How far does it travel?",
    answerOptions: [
      { answer: "40 miles", isCorrect: true },
      { answer: "20 miles", isCorrect: false },
      { answer: "10 miles", isCorrect: false },
      { answer: "5 miles", isCorrect: false },
    ],
  },
  {
    question:
      "A train accelerates from 0 to 80 mph in 20 seconds. What is its average acceleration?",
    answerOptions: [
      { answer: "11.11 m/s^2", isCorrect: true },
      { answer: "5.56 m/s^2", isCorrect: false },
      { answer: "2.78 m/s^2", isCorrect: false },
      { answer: "1.39 m/s^2", isCorrect: false },
    ],
  },
  {
    question:
      "A baseball player hits a ball at an angle of 45° with an initial velocity of 80 mph. How far does it travel?",
    answerOptions: [
      { answer: "320 feet", isCorrect: true },
      { answer: "160 feet", isCorrect: false },
      { answer: "80 feet", isCorrect: false },
      { answer: "40 feet", isCorrect: false },
    ],
  },
  {
    question:
      "A golfer hits a ball at an angle of 30° with an initial velocity of 100 mph. What is its maximum height?",
    answerOptions: [
      { answer: "127.5 feet", isCorrect: true },
      { answer: "63.75 feet", isCorrect: false },
      { answer: "31.875 feet", isCorrect: false },
      { answer: "15.9375 feet", isCorrect: false },
    ],
  },
  {
    question:
      "A fireworks rocket is launched at an angle of 60° with an initial velocity of 150 mph. What is its time of flight?",
    answerOptions: [
      { answer: "10 seconds", isCorrect: true },
      { answer: "5 seconds", isCorrect: false },
      { answer: "2.5 seconds", isCorrect: false },
      { answer: "1.25 seconds", isCorrect: false },
    ],
  },
  {
    question:
      "A soccer player kicks a ball at an angle of 45° with an initial velocity of 60 mph. What is its horizontal range?",
    answerOptions: [
      { answer: "240 feet", isCorrect: true },
      { answer: "120 feet", isCorrect: false },
      { answer: "60 feet", isCorrect: false },
      { answer: "30 feet", isCorrect: false },
    ],
  },
  {
    question:
      "A skier moves down a slope at an angle of 30° with an initial velocity of 40 mph. What is its acceleration?",
    answerOptions: [
      { answer: "8.67 m/s^2", isCorrect: true },
      { answer: "4.33 m/s^2", isCorrect: false },
      { answer: "2.17 m/s^2", isCorrect: false },
      { answer: "1.08 m/s^2", isCorrect: false },
    ],
  },
  {
    question:
      "A car travels up a hill at an angle of 20° with a constant velocity of 50 mph. What is its acceleration?",
    answerOptions: [
      { answer: "4.9 m/s^2", isCorrect: true },
      { answer: "2.45 m/s^2", isCorrect: false },
      { answer: "1.225 m/s^2", isCorrect: false },
      { answer: "0.6125 m/s^2", isCorrect: false },
    ],
  },
  {
    question:
      "A projectile is launched at an angle of 45° with an initial velocity of 120 mph. What is its maximum horizontal range?",
    answerOptions: [
      { answer: "480 feet", isCorrect: true },
      { answer: "240 feet", isCorrect: false },
      { answer: "120 feet", isCorrect: false },
      { answer: "60 feet", isCorrect: false },
    ],
  },
  {
    question:
      "A car is traveling at a constant velocity of 60 mph on a straight road. If the driver sees a pedestrian 100 feet ahead, how much time does the driver have to stop the car before hitting the pedestrian? (Assume the car's stopping distance is 50 feet.)",
    answerOptions: [
      { answer: "2.5 seconds", isCorrect: true },
      { answer: "1.25 seconds", isCorrect: false },
      { answer: "0.625 seconds", isCorrect: false },
      { answer: "0.3125 seconds", isCorrect: false },
    ],
  },
  {
    question:
      "A train is traveling at a constant velocity of 80 mph on a straight track. If the train's brakes fail, how far will it travel before it reaches a station 5 miles ahead?",
    answerOptions: [
      { answer: "6.25 miles", isCorrect: true },
      { answer: "3.125 miles", isCorrect: false },
      { answer: "1.5625 miles", isCorrect: false },
      { answer: "0.78125 miles", isCorrect: false },
    ],
  },
  {
    question:
      "A bicycle is traveling at a constant velocity of 20 mph on a straight path. If the cyclist sees a pothole 50 feet ahead, how much time does the cyclist have to avoid it?",
    answerOptions: [
      { answer: "2.5 seconds", isCorrect: true },
      { answer: "1.25 seconds", isCorrect: false },
      { answer: "0.625 seconds", isCorrect: false },
      { answer: "0.3125 seconds", isCorrect: false },
    ],
  },
  {
    question:
      "A golfer hits a ball at an angle of 45° with an initial velocity of 100 mph. If the ball lands on a green 200 yards away, how high was the ball at its maximum height?",
    answerOptions: [
      { answer: "127.5 feet", isCorrect: true },
      { answer: "63.75 feet", isCorrect: false },
      { answer: "31.875 feet", isCorrect: false },
      { answer: "15.9375 feet", isCorrect: false },
    ],
  },
  {
    question:
      "A firefighter is using a hose to spray water at an angle of 30° to reach a fire 50 feet away. If the water leaves the hose at an initial velocity of 50 mph, how high will it reach?",
    answerOptions: [
      { answer: "31.25 feet", isCorrect: true },
      { answer: "15.625 feet", isCorrect: false },
      { answer: "7.8125 feet", isCorrect: false },
      { answer: "3.90625 feet", isCorrect: false },
    ],
  },
  {
    question:
      "A baseball player hits a ball at an angle of 60° with an initial velocity of 80 mph. If the ball lands in the stands 300 feet away, how long was it in the air?",
    answerOptions: [
      { answer: "6 seconds", isCorrect: true },
      { answer: "3 seconds", isCorrect: false },
      { answer: "1.5 seconds", isCorrect: false },
      { answer: "0.75 seconds", isCorrect: false },
    ],
  },
  {
    question:
      "A skier is moving down a slope at an angle of 45° with an initial velocity of 40 mph. If the skier travels 500 feet, how much time did it take?",
    answerOptions: [
      { answer: "10 seconds", isCorrect: true },
      { answer: "5 seconds", isCorrect: false },
      { answer: "2.5 seconds", isCorrect: false },
      { answer: "1.25 seconds", isCorrect: false },
    ],
  },
  {
    question:
      "A car is traveling up a hill at an angle of 20° with a constant velocity of 50 mph. If the car travels 1000 feet, how much time did it take?",
    answerOptions: [
      { answer: "20 seconds", isCorrect: true },
      { answer: "10 seconds", isCorrect: false },
      { answer: "5 seconds", isCorrect: false },
      { answer: "2.5 seconds", isCorrect: false },
    ],
  },
  {
    question:
      "A roller coaster is traveling at a constant velocity of 60 mph on a straight track. If the track suddenly curves upward at an angle of 30°, how much time does the roller coaster have to adjust its speed before it reaches the top of the curve?",
    answerOptions: [
      { answer: "4.5 seconds", isCorrect: true },
      { answer: "2.25 seconds", isCorrect: false },
      { answer: "1.125 seconds", isCorrect: false },
      { answer: "0.5625 seconds", isCorrect: false },
    ],
  },
  {
    question:
      "A particle is moving in a straight line with an initial velocity of 20 m/s. If it accelerates uniformly at a rate of 4 m/s², how far will it travel in 10 seconds?",
    answerOptions: [
      { answer: "300 meters", isCorrect: true },
      { answer: "150 meters", isCorrect: false },
      { answer: "75 meters", isCorrect: false },
      { answer: "37.5 meters", isCorrect: false },
    ],
  },
  {
    question:
      "A car is traveling up a hill at an angle of 25° with a constant velocity of 40 mph. If the car's engine fails, how far will it travel before coming to rest?",
    answerOptions: [
      { answer: "1200 feet", isCorrect: true },
      { answer: "600 feet", isCorrect: false },
      { answer: "300 feet", isCorrect: false },
      { answer: "150 feet", isCorrect: false },
    ],
  },
  {
    question:
      "A projectile is launched at an angle of 60° with an initial velocity of 100 m/s. If it lands 500 meters away, how long was it in the air?",
    answerOptions: [
      { answer: "10 seconds", isCorrect: true },
      { answer: "5 seconds", isCorrect: false },
      { answer: "2.5 seconds", isCorrect: false },
      { answer: "1.25 seconds", isCorrect: false },
    ],
  },
  {
    question:
      "A cyclist is moving down a straight path with an initial velocity of 15 m/s. If she applies the brakes uniformly and comes to rest in 20 seconds, what is her deceleration?",
    answerOptions: [
      { answer: "-0.75 m/s²", isCorrect: true },
      { answer: "-0.375 m/s²", isCorrect: false },
      { answer: "-0.1875 m/s²", isCorrect: false },
      { answer: "-0.09375 m/s²", isCorrect: false },
    ],
  },
  {
    question:
      "A car is traveling at a constant velocity of 70 mph on a straight road. If the driver sees a traffic light 200 feet ahead, how much time does the driver have to stop the car before reaching the light?",
    answerOptions: [
      { answer: "4.3 seconds", isCorrect: true },
      { answer: "2.15 seconds", isCorrect: false },
      { answer: "1.075 seconds", isCorrect: false },
      { answer: "0.5375 seconds", isCorrect: false },
    ],
  },
  {
    question:
      "A train is traveling at a constant velocity of 90 mph on a straight track. If the train's brakes fail, how far will it travel before reaching a station 3 miles ahead?",
    answerOptions: [
      { answer: "4.5 miles", isCorrect: true },
      { answer: "2.25 miles", isCorrect: false },
      { answer: "1.125 miles", isCorrect: false },
      { answer: "0.5625 miles", isCorrect: false },
    ],
  },
  {
    question:
      "A bicycle is traveling at a constant velocity of 25 mph on a straight path. If the cyclist sees a pothole 75 feet ahead, how much time does the cyclist have to avoid it?",
    answerOptions: [
      { answer: "3.6 seconds", isCorrect: true },
      { answer: "1.8 seconds", isCorrect: false },
      { answer: "0.9 seconds", isCorrect: false },
      { answer: "0.45 seconds", isCorrect: false },
    ],
  },
  {
    question:
      "A golfer hits a ball at an angle of 50° with an initial velocity of 120 mph. If the ball lands on a green 250 yards away, how high was the ball at its maximum height?",
    answerOptions: [
      { answer: "150 feet", isCorrect: true },
      { answer: "75 feet", isCorrect: false },
      { answer: "37.5 feet", isCorrect: false },
      { answer: "18.75 feet", isCorrect: false },
    ],
  },
  {
    question:
      "A firefighter is using a hose to spray water at an angle of 40° to reach a fire 60 feet away. If the water leaves the hose at an initial velocity of 60 mph, how high will it reach?",
    answerOptions: [
      { answer: "40 feet", isCorrect: true },
      { answer: "20 feet", isCorrect: false },
      { answer: "10 feet", isCorrect: false },
      { answer: "5 feet", isCorrect: false },
    ],
  },
  {
    question:
      "A baseball player hits a ball at an angle of 70° with an initial velocity of 100 mph. If the ball lands in the stands 350 feet away, how long was it in the air?",
    answerOptions: [
      { answer: "8 seconds", isCorrect: true },
      { answer: "4 seconds", isCorrect: false },
      { answer: "2 seconds", isCorrect: false },
      { answer: "1 second", isCorrect: false },
    ],
  },
  {
    question:
      "A skier is moving down a slope at an angle of 55° with an initial velocity of 50 mph. If the skier travels 800 feet, how much time did it take?",
    answerOptions: [
      { answer: "12 seconds", isCorrect: true },
      { answer: "6 seconds", isCorrect: false },
      { answer: "3 seconds", isCorrect: false },
      { answer: "1.5 seconds", isCorrect: false },
    ],
  },
  {
    question:
      "A car is traveling up a hill at an angle of 30° with a constant velocity of 60 mph. If the car travels 1500 feet, how much time did it take?",
    answerOptions: [
      { answer: "30 seconds", isCorrect: true },
      { answer: "15 seconds", isCorrect: false },
      { answer: "7.5 seconds", isCorrect: false },
      { answer: "3.75 seconds", isCorrect: false },
    ],
  },
  {
    question:
      "A projectile is launched at an angle of 60° with an initial velocity of 150 mph. If it lands 700 feet away, how long was it in the air?",
    answerOptions: [
      { answer: "10 seconds", isCorrect: true },
      { answer: "5 seconds", isCorrect: false },
      { answer: "2.5 seconds", isCorrect: false },
      { answer: "1.25 seconds", isCorrect: false },
    ],
  },
];

export default kenamaticsquestions;
