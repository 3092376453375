const groupelevenquestions = [
    {
        question:"Copper, Ag and Au are known as coinage metals because they were used to make coins :",
        answerOptions: [
            {answer: 'yes', isCorrect: true},
            {answer: 'no', isCorrect: false},
            {answer: 'may be', isCorrect: false},
            {answer: 'data insufficient', isCorrect: false},
        ],
    }, {
        question:"All coinage metals have similar lattice structure :",
        answerOptions: [
            {answer: 'fcc', isCorrect: false},
            {answer: 'ccp', isCorrect: true},
            {answer: 'bcc', isCorrect: false},
            {answer: 'none of these', isCorrect: false},
        ],
    },  {
        question:"mp and bp of coinage metals are relatively  :",
        answerOptions: [
            {answer: 'high', isCorrect: true},
            {answer: 'low', isCorrect: false},
            {answer: 'medium', isCorrect: false},
            {answer: 'data insufficient', isCorrect: false},
        ],
    },  {
        question:"Which of the following has highest melting point :",
        answerOptions: [
            {answer: 'Cu', isCorrect: true},
            {answer: 'Ag', isCorrect: false},
            {answer: 'Au', isCorrect: false},
            {answer: 'none of these', isCorrect: false},
        ],
    },  {
        question:"Which of the following has highest melting point :",
        answerOptions: [
            {answer: 'Cu', isCorrect: false},
            {answer: 'Ag', isCorrect: false},
            {answer: 'Au', isCorrect: true},
            {answer: 'all of these', isCorrect: false},
        ],
    },  {
        question:"Higher sublimation and ionization energies are responsible for :",
        answerOptions: [
            {answer: 'thermal', isCorrect: false},
            {answer: 'metastate', isCorrect: false},
            {answer: 'nobal nature', isCorrect: true},
            {answer: 'all', isCorrect: false},
           
        ],
    }, {
        question:"The order found for first ionization energy is :",
        answerOptions: [
            {answer: 'Cu > Ag < Au', isCorrect: true},
            {answer: 'Ag > Au > Cu', isCorrect: false},
            {answer: 'Ag > Cu > Au', isCorrect: false},
            {answer: 'Au > Ag > Cu', isCorrect: false},
        ],
    },  {
        question:"Which elements having most malleable metal :",
        answerOptions: [
            {answer: 'Cu', isCorrect: false},
            {answer: 'Au', isCorrect: false},
            {answer: 'Ag', isCorrect: true},
            {answer: 'all of the above', isCorrect: false},
        ],
    },  {
        question:"Which elements having highest electrical and thermal conductivities :",
        answerOptions: [
            {answer: 'Cu', isCorrect: false},
            {answer: 'Ag', isCorrect: false},
            {answer: 'Au', isCorrect: true},
            {answer: 'none of these', isCorrect: false},
        ],
    },  {
        question:"Which of the following is used in photography  :",
        answerOptions: [
            {answer: 'CuBr2', isCorrect: false},
            {answer: 'CuBr', isCorrect: false},
            {answer: 'AgBr', isCorrect: true},
            {answer: 'CuCl3', isCorrect: false},
        ],
    },  {
        question:"Which of the following is not expected to exist :",
        answerOptions: [
            {answer: 'CuF', isCorrect: true},
            {answer: 'CuCl', isCorrect: false},
            {answer: 'CuBr', isCorrect: false},
            {answer: 'CuI', isCorrect: false},
        ],
    },  {
        question:"An alloy which does not contain Cu is :",
        answerOptions: [
            {answer: 'brass', isCorrect: false},
            {answer: 'bronze', isCorrect: false},
            {answer: 'solder', isCorrect: true},
            {answer: 'none of these', isCorrect: false},
        ],
    },  {
        question:"Which of the following is expected to be coloured :",
        answerOptions: [
            {answer: 'CuCl', isCorrect: false},
            {answer: 'Ag2SO4', isCorrect: false},
            {answer: 'CuF2', isCorrect: true},
            {answer: 'MgF2', isCorrect: false},
        ],
    },  {
        question:"Which of the following found in native state :",
        answerOptions: [
            {answer: 'Cu', isCorrect: false},
            {answer: 'Ag', isCorrect: false},
            {answer: 'S', isCorrect: false},
            {answer: 'Au', isCorrect: true},
        ],
    },  {
        question:" When excess KCN is added to CuSO4 colour of the solution is :",
        answerOptions: [
            {answer: 'green', isCorrect: false},
            {answer: 'blue', isCorrect: false},
            {answer: 'red', isCorrect: false},
            {answer: 'colorless', isCorrect: true},
        ],
    },  {
        question:"When excess NH4OH is added to CuSO4 solution, the colour of the solution becomes :",
        answerOptions: [
            {answer: 'red', isCorrect: false},
            {answer: 'deep blue', isCorrect: true},
            {answer: 'light blue', isCorrect: false},
            {answer: 'none of these', isCorrect: false},
        ],
    },  {
        question:"When a mixture of Cu2S and Cu2O are heated the product is  :",
        answerOptions: [
            {answer: 'Cu2S', isCorrect: false},
            {answer: 'Cu', isCorrect: true},
            {answer: 'CuSO4', isCorrect: false},
            {answer: 'anythings may be', isCorrect: false},
        ],
    },  {
        question:"Which of the following does not disproportionate in water :",
        answerOptions: [
            {answer: 'Cu2+', isCorrect: true},
            {answer: 'Au+1', isCorrect: false},
            {answer: 'Cu+', isCorrect: false},
            {answer: 'Cl2', isCorrect: false},
        ],
    },  {
        question:"When Cu2S and FeS mixture is heated in air the product is  :",
        answerOptions: [
            {answer: 'Cu2S', isCorrect: true},
            {answer: 'CuO, FeO', isCorrect: false},
            {answer: 'Cu2OFeS', isCorrect: false},
            {answer: 'none of these', isCorrect: false},
        ],
    },  {
        question:"Bonding in Cu is :",
        answerOptions: [
            {answer: 'covalent', isCorrect: false},
            {answer: 'electrovalent', isCorrect: false},
            {answer: 'ioonic and covalent', isCorrect: true},
            {answer: 'all are these', isorrect: false},
        ],
    },
]

export default groupelevenquestions;