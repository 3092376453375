import { useState } from "react";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import LinkGeneration from "./utils/LinkGeneration";
import { ToastContainer } from "react-toastify";

function App() {

  return (
    <div>
      <ToastContainer />
   
 
      <LinkGeneration />
    </div>
  );
}

export default App;
