import React from 'react';

function Filters({ categories, selectedCategory, onCategoryChange }) {
  return (
    <div className="filters-container" style={{ marginBottom: '1rem' }}>
      <select
        value={selectedCategory}
        onChange={(e) => onCategoryChange(e.target.value)}
        style={{
          padding: '0.5rem',
          borderRadius: '0.25rem',
          border: '1px solid #ddd',
          fontSize: '1rem',
        }}
      >
        <option value="">All Categories</option>
        {categories.map((category) => (
          <option key={category} value={category}>
            {category}
          </option>
        ))}
      </select>
    </div>
  );
}

export default Filters;
