const wavequestions = [
  {
    question:
      "The potential energy of a particle executing SHM varies with frequency f. The frequency of oscillation of the particles will be :",
    answerOptions: [
      { answer: "f/2", isCorrect: true },
      { answer: "f/√2", isCorrect: false },
      { answer: "f", isCorrect: false },
      { answer: "2f", isCorrect: false },
    ],
  },
  {
    question:
      "Which of the following is not change, when wave goes from one medium to another :",
    answerOptions: [
      { answer: "amplitude", isCorrect: false },
      { answer: "velocity", isCorrect: false },
      { answer: "frequency", isCorrect: true },
      { answer: "data insufficient", isCorrect: false },
    ],
  },
  {
    question: "Lounness of sound is directly proportional to :",
    answerOptions: [
      { answer: "amplitude", isCorrect: false },
      { answer: "amplitude²", isCorrect: true },
      { answer: "intensity", isCorrect: false },
      { answer: "intensity²", isCorrect: false },
    ],
  },
  {
    question:
      "Two sounds having levels of 50 dB and 60 dB, then intensity difference of these sound will be :",
    answerOptions: [
      { answer: "1", isCorrect: false },
      { answer: "10", isCorrect: true },
      { answer: "100", isCorrect: false },
      { answer: "0.1", isCorrect: false },
    ],
  },
  {
    question:
      "A particle executes S.H.M with frequency 2.5 Hz and amplitude 2 m. The speed of the particle 0.3 s after crossing, the equilibrium position is :",
    answerOptions: [
      { answer: "zero", isCorrect: true },
      { answer: "2π m/s", isCorrect: false },
      { answer: "4π m/s", isCorrect: false },
      { answer: "π m/s", isCorrect: false },
    ],
  },
  {
    question: "Bats can hear sounds with compare to humans :",
    answerOptions: [
      { answer: "low frequency", isCorrect: false },
      { answer: "high frequency", isCorrect: false },
      { answer: "low pitch", isCorrect: false },
      { answer: "high pitch", isCorrect: true },
    ],
  },
  {
    question:
      "The frequencies of three identical sound waves are (v-1), (v), and (v+1). They posture while performing beats. The frequency of beats per second will be :(Xnetous chellange, AIEEE-2009, updated by Xnetous)",
    answerOptions: [
      { answer: "3", isCorrect: false },
      { answer: "2", isCorrect: false },
      { answer: "1", isCorrect: false },
      { answer: "4", isCorrect: true },
    ],
  },
  {
    question: "Frequency is proportional to :",
    answerOptions: [
      { answer: "amplitude", isCorrect: false },
      { answer: "wavelength", isCorrect: false },
      { answer: "no. of waves", isCorrect: true },
      { answer: "velocity", isCorrect: false },
    ],
  },
  {
    question:
      "A dics of radius R is provided at its rim. The period for small oscillation about an axis perpendicular to the plane of dics is :",
    answerOptions: [
      { answer: "2π √R/g", isCorrect: false },
      { answer: "2π √2R/g", isCorrect: false },
      { answer: "2π √2R/3g", isCorrect: false },
      { answer: "2π √2R/2g", isCorrect: true },
    ],
  },
  {
    question:
      "When the temprature increases, the frequency of a tuning fork : (AIEEE - 2002)",
    answerOptions: [
      { answer: "increases", isCorrect: false },
      { answer: "decreases", isCorrect: true },
      { answer: "remain same", isCorrect: false },
      { answer: "depends on materials", isCorrect: false },
    ],
  },
  {
    question:
      "If the length of a simple pendulam is equal to the radius of earth, its time period will be :",
    answerOptions: [
      { answer: "2π √R/g", isCorrect: false },
      { answer: "2π √R/2g", isCorrect: true },
      { answer: "2π √2R/g", isCorrect: false },
      { answer: "infinte", isCorrect: false },
    ],
  },
  {
    question:
      "A travelling wave has a particle displacement amplitude of A and a frequency of v. The particle velocity amplitude for the waves is : (Xnetous chellange, IIT-JEE 1983, updated by Xnetous )",
    answerOptions: [
      { answer: "2πv", isCorrect: false },
      { answer: "A(2πv)", isCorrect: true },
      { answer: "Aπv", isCorrect: false },
      { answer: "data insufficient", isCorrect: false },
    ],
  },
  {
    question:
      "A mass M is suspended from a spring of negligible mass. The spring is pulled a little then released, so that the mass executes S.H.M of time periods T. If the mass is increased by m, the time periods becomes 5T/3. The ratio pf m/M is :",
    answerOptions: [
      { answer: "5/3", isCorrect: false },
      { answer: "3/5", isCorrect: false },
      { answer: "16/9", isCorrect: true },
      { answer: "25/9", isCorrect: false },
    ],
  },
  {
    question:
      "A simple harmonic oscillator has an acceleration of 1.25 m/s² at 5 cm from equilibrium. Its period of ocillation is :",
    answerOptions: [
      { answer: "4π/5 s", isCorrect: false },
      { answer: "5π/2 s", isCorrect: false },
      { answer: "2π/5 s", isCorrect: true },
      { answer: "2π/ 25 s", isCorrect: false },
    ],
  },
  {
    question:
      "With a speed that is one-fifth of the speed of sound, an observer moves toward a stationary source of sound. What is the perceived frequency's percentage increase :",
    answerOptions: [
      { answer: "0.5 %", isCorrect: false },
      { answer: "0", isCorrect: false },
      { answer: "20 %", isCorrect: false },
      { answer: "5 %", isCorrect: true },
    ],
  },
  {
    question:
      "A second pendulam is suspended from the ceiling of a trolly moving horizontlly with an acceleration of 4 m/s². Its periods of oscillation is :",
    answerOptions: [
      { answer: "1.90 s", isCorrect: true },
      { answer: "1.70 s", isCorrect: false },
      { answer: "2.30 s", isCorrect: false },
      { answer: "1.40 s", isCorrect: false },
    ],
  },
  {
    question:
      "A 10 watt source of frequency 1000 c/s sends out wave in air. Calculate the intensity at a distance 10 m away from source :",
    answerOptions: [
      { answer: "1/20π watt/m²", isCorrect: false },
      { answer: "1/40π watt/m²", isCorrect: true },
      { answer: "1/60π watt/m²", isCorrect: false },
      { answer: "1/80π watt/m²", isCorrect: false },
    ],
  },
  {
    question:
      "The equation for a wave on a string with linear mass density is y = 0.02(m) sin [2π(t/0.04(s) - x/0.50(m))],  The tension in the string is : (AIEEE-2010, updated by Xnetous)",
    answerOptions: [
      { answer: "40 N", isCorrect: false },
      { answer: "12.5 N", isCorrect: false },
      { answer: "0.5 N", isCorrect: false },
      { answer: "6.25 N", isCorrect: true },
    ],
  },
  {
    question:
      "A particles of mass m executes S.H.M with amplitude a and frequency v. The average kenatic energy during its motion from the position of equilibrium to the end is : (AIEEE 2007)",
    answerOptions: [
      { answer: "2π² ma² v²", isCorrect: false },
      { answer: "π² ma² v²", isCorrect: true },
      { answer: "1/4 ma² v²", isCorrect: false },
      { answer: "4 π² ma² v²", isCorrect: false },
    ],
  },
  {
    question:
      "At a set distance from a siren that generates sound with a fixed amplitude, a guy is positioned on the ground. On a clear night as opposed to a day, the sound is stronger in the man's ears : (IIT-JEE 1980, updated by Xnetous)",
    answerOptions: [
      { answer: "false", isCorrect: true },
      { answer: "may be", isCorrect: false },
      { answer: "true", isCorrect: false },
      { answer: "can not be said", isCorrect: false },
    ],
  },
  {
    question:
      "A particles of frequency v released with S.H.M, Then kinetic energy oscillator with this frequency f is: (IIT 1987, updated by Xnetous)",
    answerOptions: [
      { answer: "f/2", isCorrect: false },
      { answer: "f", isCorrect: false },
      { answer: "2f", isCorrect: true },
      { answer: "4f", isCorrect: false },
    ],
  },
  {
    question:
      "A stationary wave is superposed with a wave described by the equation y = a cos (kx - ωt) such that the point x = 0 is a node. The other wave's equation is : (IIT-JEE 1988, updated by Xnetous)",
    answerOptions: [
      { answer: "y = a sin (kx + ωt)", isCorrect: false },
      { answer: "y = - a cos (kx - ωt)", isCorrect: false },
      { answer: "y = - a cos (kx + ωt)", isCorrect: true },
      { answer: "y = - a sin (kx - ωt)", isCorrect: false },
    ],
  },
  {
    question:
      "A particle executes sipmple harmonic motion between x = -A and x = +A. The time taken for it to go from 0 to A/2 is T1 and to go from A/2 to A is T2. Then : (IIT-JEE 2001)",
    answerOptions: [
      { answer: "T1 < T2", isCorrect: true },
      { answer: "T1 > T2", isCorrect: false },
      { answer: "T1 = T2", isCorrect: false },
      { answer: "T1 = 2T2", isCorrect: false },
    ],
  },
  {
    question:
      "If a spring has time period T, and is cut into n equal parts, then the time period of each part will be : (AIEEE 2002)",
    answerOptions: [
      { answer: "T √(n)", isCorrect: false },
      { answer: "T/√(n)", isCorrect: true },
      { answer: "nT", isCorrect: false },
      { answer: "T", isCorrect: false },
    ],
  },
  {
    question:
      "The length of simple pendulam is decresed by 21%. The percentage change inits time period is : (AIEEE 2003)",
    answerOptions: [
      { answer: "11% decrese", isCorrect: true },
      { answer: "11% increse", isCorrect: false },
      { answer: "21% decrese", isCorrect: false },
      { answer: "21% increse", isCorrect: false },
    ],
  },
  {
    question:
      "The sound level is reduced by a sound absorber by 20 dB. The level of intensity falls by a factor of : (AIEEE-2007, updated by Xnetous)",
    answerOptions: [
      { answer: "10", isCorrect: false },
      { answer: "100", isCorrect: true },
      { answer: "1000", isCorrect: false },
      { answer: "10000", isCorrect: false },
    ],
  },
  {
    question:
      "The displacement of a particle varies according to the relation y = 4 (cosπt + sinπt). The amplitude of particle is :",
    answerOptions: [
      { answer: "8 units", isCorrect: false },
      { answer: "2 units", isCorrect: false },
      { answer: "4 units", isCorrect: false },
      { answer: "4 √(2)", isCorrect: true },
    ],
  },
  {
    question:
      " The only difference between tubes A and B is that tube A has both ends open whereas tube B has one end closed. The tube A to tube B fundament frequency ratio is : (AIEEE-2002, updated by Xnetous)",
    answerOptions: [
      { answer: "1 : 2", isCorrect: false },
      { answer: "1 : 4", isCorrect: false },
      { answer: "2 : 1", isCorrect: true },
      { answer: "4 : 1", isCorrect: false },
    ],
  },
  {
    question:
      "If a particle is free to move on x-axis, is being acted upon by a force given by :",
    answerOptions: [
      { answer: "F = kx^n", isCorrect: false },
      { answer: "F = - kx^n", isCorrect: true },
      { answer: "F = k/2 x^n", isCorrect: false },
      { answer: "F = - k/2 x^n", isCorrect: false },
    ],
  },
  {
    question:
      "A child swinging on a swing in string in sitting position, stand up, then the time periods of the swing will : (AIEEE 2002)",
    answerOptions: [
      { answer: "increase", isCorrect: false },
      { answer: "decrese", isCorrect: true },
      { answer: "remain same", isCorrect: false },
      { answer: "data insufficient", isCorrect: false },
    ],
  },
  {
    question:
      "A mass-spring system oscillates with a frequency of 2 Hz. What is the period of oscillation?",
    answerOptions: [
      { answer: "0.5 seconds", isCorrect: true },
      { answer: "2 seconds", isCorrect: false },
      { answer: "1 second", isCorrect: false },
      { answer: "0.25 seconds", isCorrect: false },
    ],
  },
  {
    question:
      "A pendulum has a length of 1 meter. What is its period of oscillation, assuming no damping and small angle approximation?",
    answerOptions: [
      { answer: "2.0 seconds", isCorrect: true },
      { answer: "1.4 seconds", isCorrect: false },
      { answer: "3.0 seconds", isCorrect: false },
      { answer: "4.0 seconds", isCorrect: false },
    ],
  },
  {
    question:
      "A wave traveling along a string has a frequency of 50 Hz and a wavelength of 2 meters. What is the speed of the wave?",
    answerOptions: [
      { answer: "100 m/s", isCorrect: true },
      { answer: "25 m/s", isCorrect: false },
      { answer: "50 m/s", isCorrect: false },
      { answer: "200 m/s", isCorrect: false },
    ],
  },
  {
    question:
      "A particle in simple harmonic motion has a maximum velocity of 10 m/s and an amplitude of 2 meters. What is the angular frequency of the motion?",
    answerOptions: [
      { answer: "5 rad/s", isCorrect: true },
      { answer: "10 rad/s", isCorrect: false },
      { answer: "2.5 rad/s", isCorrect: false },
      { answer: "1 rad/s", isCorrect: false },
    ],
  },
  {
    question:
      "A wave pulse is traveling on a string. If the frequency of the pulse is doubled, what happens to the wavelength, assuming the speed of the wave remains constant?",
    answerOptions: [
      { answer: "Wavelength is halved", isCorrect: true },
      { answer: "Wavelength doubles", isCorrect: false },
      { answer: "Wavelength remains the same", isCorrect: false },
      { answer: "Wavelength is quadrupled", isCorrect: false },
    ],
  },
  {
    question:
      "In a simple harmonic oscillator, if the mass is doubled and the spring constant is halved, what happens to the frequency of oscillation?",
    answerOptions: [
      { answer: "Frequency decreases by a factor of √2", isCorrect: true },
      { answer: "Frequency increases by a factor of 2", isCorrect: false },
      { answer: "Frequency decreases by a factor of 2", isCorrect: false },
      { answer: "Frequency remains the same", isCorrect: false },
    ],
  },
  {
    question:
      "A sound wave travels through air with a speed of 343 m/s and has a frequency of 256 Hz. What is the wavelength of the sound wave?",
    answerOptions: [
      { answer: "1.34 meters", isCorrect: true },
      { answer: "2.0 meters", isCorrect: false },
      { answer: "0.67 meters", isCorrect: false },
      { answer: "3.0 meters", isCorrect: false },
    ],
  },
  {
    question:
      "If the amplitude of a simple harmonic oscillator is doubled, what happens to its maximum velocity?",
    answerOptions: [
      {
        answer: "Maximum velocity increases by a factor of √2",
        isCorrect: true,
      },
      { answer: "Maximum velocity doubles", isCorrect: false },
      { answer: "Maximum velocity remains the same", isCorrect: false },
      {
        answer: "Maximum velocity increases by a factor of 4",
        isCorrect: false,
      },
    ],
  },
  {
    question:
      "A tuning fork produces a sound wave with a wavelength of 0.4 meters. If the speed of sound in air is 340 m/s, what is the frequency of the sound wave?",
    answerOptions: [
      { answer: "850 Hz", isCorrect: true },
      { answer: "340 Hz", isCorrect: false },
      { answer: "680 Hz", isCorrect: false },
      { answer: "1,360 Hz", isCorrect: false },
    ],
  },
  {
    question:
      "A mass on a spring oscillates with a period of 0.5 seconds. What is the frequency of oscillation?",
    answerOptions: [
      { answer: "2 Hz", isCorrect: true },
      { answer: "0.5 Hz", isCorrect: false },
      { answer: "4 Hz", isCorrect: false },
      { answer: "1 Hz", isCorrect: false },
    ],
  },
  {
    question:
      "A wave with a frequency of 10 Hz travels at a speed of 20 m/s. What is the wavelength of the wave?",
    answerOptions: [
      { answer: "2 meters", isCorrect: true },
      { answer: "1 meter", isCorrect: false },
      { answer: "0.5 meters", isCorrect: false },
      { answer: "5 meters", isCorrect: false },
    ],
  },
  {
    question:
      "A pendulum in a clock swings back and forth with a period of 1 second. If the length of the pendulum is increased to 4 times its original length, what is the new period of the pendulum?",
    answerOptions: [
      { answer: "2 seconds", isCorrect: true },
      { answer: "1 second", isCorrect: false },
      { answer: "4 seconds", isCorrect: false },
      { answer: "√4 seconds", isCorrect: false },
    ],
  },
  {
    question:
      "A wave traveling on a string has an amplitude of 5 cm and a frequency of 2 Hz. If the speed of the wave is 10 m/s, what is the maximum displacement of the string?",
    answerOptions: [
      { answer: "5 cm", isCorrect: true },
      { answer: "10 cm", isCorrect: false },
      { answer: "2.5 cm", isCorrect: false },
      { answer: "1 cm", isCorrect: false },
    ],
  },
  {
    question:
      "A car is moving at a speed of 30 m/s and produces a sound wave with a frequency of 500 Hz. If the car approaches a stationary observer, what will be the observed frequency assuming the speed of sound is 340 m/s?",
    answerOptions: [
      { answer: "535 Hz", isCorrect: true },
      { answer: "500 Hz", isCorrect: false },
      { answer: "465 Hz", isCorrect: false },
      { answer: "570 Hz", isCorrect: false },
    ],
  },
  {
    question:
      "A block on a spring oscillates with a frequency of 4 Hz. If the spring constant is 160 N/m and the mass of the block is 1 kg, what is the angular frequency of oscillation?",
    answerOptions: [
      { answer: "25.13 rad/s", isCorrect: true },
      { answer: "15.7 rad/s", isCorrect: false },
      { answer: "10 rad/s", isCorrect: false },
      { answer: "20 rad/s", isCorrect: false },
    ],
  },
  {
    question:
      "In a simple harmonic oscillator, the mass is 2 kg and the spring constant is 50 N/m. What is the frequency of oscillation?",
    answerOptions: [
      { answer: "1.58 Hz", isCorrect: true },
      { answer: "2.5 Hz", isCorrect: false },
      { answer: "4 Hz", isCorrect: false },
      { answer: "3.14 Hz", isCorrect: false },
    ],
  },
  {
    question:
      "A wave has a frequency of 60 Hz and a wavelength of 3 meters. What is the speed of the wave?",
    answerOptions: [
      { answer: "180 m/s", isCorrect: true },
      { answer: "120 m/s", isCorrect: false },
      { answer: "90 m/s", isCorrect: false },
      { answer: "60 m/s", isCorrect: false },
    ],
  },
  {
    question:
      "A simple pendulum has a period of 2 seconds. What is the length of the pendulum, assuming small angle approximation and g = 9.8 m/s²?",
    answerOptions: [
      { answer: "1 meter", isCorrect: true },
      { answer: "2 meters", isCorrect: false },
      { answer: "0.5 meters", isCorrect: false },
      { answer: "4 meters", isCorrect: false },
    ],
  },
  {
    question:
      "An object in simple harmonic motion has a maximum displacement of 0.4 meters and a maximum velocity of 2 m/s. What is the angular frequency of the motion?",
    answerOptions: [
      { answer: "5 rad/s", isCorrect: true },
      { answer: "2.5 rad/s", isCorrect: false },
      { answer: "10 rad/s", isCorrect: false },
      { answer: "1 rad/s", isCorrect: false },
    ],
  },
  {
    question:
      "A tuning fork vibrates at 440 Hz and produces a sound wave in air. If the speed of sound in air is 340 m/s, what is the wavelength of the sound wave?",
    answerOptions: [
      { answer: "0.772 meters", isCorrect: true },
      { answer: "0.77 meters", isCorrect: false },
      { answer: "1 meter", isCorrect: false },
      { answer: "0.5 meters", isCorrect: false },
    ],
  },
  {
    question:
      "A wave on a string has a frequency of 8 Hz and a speed of 16 m/s. What is the wavelength of the wave?",
    answerOptions: [
      { answer: "2 meters", isCorrect: true },
      { answer: "4 meters", isCorrect: false },
      { answer: "1 meter", isCorrect: false },
      { answer: "8 meters", isCorrect: false },
    ],
  },
  {
    question:
      "If a pendulum with a length of 2 meters has a period of 2.8 seconds, what is the acceleration due to gravity (g)?",
    answerOptions: [
      { answer: "9.8 m/s²", isCorrect: true },
      { answer: "10 m/s²", isCorrect: false },
      { answer: "8.5 m/s²", isCorrect: false },
      { answer: "11 m/s²", isCorrect: false },
    ],
  },
  {
    question:
      "A mass of 0.5 kg is attached to a spring with a spring constant of 200 N/m. What is the period of oscillation for this mass-spring system?",
    answerOptions: [
      { answer: "1.4 seconds", isCorrect: true },
      { answer: "2.0 seconds", isCorrect: false },
      { answer: "1.0 seconds", isCorrect: false },
      { answer: "0.5 seconds", isCorrect: false },
    ],
  },
  {
    question:
      "A wave traveling in a medium has a speed of 12 m/s and a wavelength of 0.3 meters. What is the frequency of the wave?",
    answerOptions: [
      { answer: "40 Hz", isCorrect: true },
      { answer: "30 Hz", isCorrect: false },
      { answer: "25 Hz", isCorrect: false },
      { answer: "20 Hz", isCorrect: false },
    ],
  },
  {
    question:
      "If a mass on a spring oscillates with a frequency of 4 Hz and the mass is 0.5 kg, what is the spring constant of the spring?",
    answerOptions: [
      { answer: "40 N/m", isCorrect: true },
      { answer: "20 N/m", isCorrect: false },
      { answer: "10 N/m", isCorrect: false },
      { answer: "30 N/m", isCorrect: false },
    ],
  },
  {
    question:
      "A guitar string vibrates with a fundamental frequency of 110 Hz. If the speed of the wave on the string is 220 m/s, what is the length of the string?",
    answerOptions: [
      { answer: "1 meter", isCorrect: true },
      { answer: "0.5 meters", isCorrect: false },
      { answer: "2 meters", isCorrect: false },
      { answer: "0.25 meters", isCorrect: false },
    ],
  },
  {
    question:
      "A mass-spring system has a spring constant of 100 N/m and an amplitude of 0.5 meters. What is the maximum kinetic energy of the mass during oscillation?",
    answerOptions: [
      { answer: "12.5 J", isCorrect: true },
      { answer: "25 J", isCorrect: false },
      { answer: "6.25 J", isCorrect: false },
      { answer: "50 J", isCorrect: false },
    ],
  },
  {
    question:
      "A mass-spring system oscillates with an amplitude of 0.3 meters and a spring constant of 50 N/m. If the mass is 0.5 kg, what is the maximum speed of the mass?",
    answerOptions: [
      { answer: "1.5 m/s", isCorrect: true },
      { answer: "0.75 m/s", isCorrect: false },
      { answer: "0.5 m/s", isCorrect: false },
      { answer: "3.0 m/s", isCorrect: false },
    ],
  },
  {
    question:
      "A particle in SHM has a frequency of 10 Hz and an amplitude of 0.2 meters. What is the total mechanical energy of the system?",
    answerOptions: [
      { answer: "1.6 J", isCorrect: true },
      { answer: "0.8 J", isCorrect: false },
      { answer: "0.4 J", isCorrect: false },
      { answer: "3.2 J", isCorrect: false },
    ],
  },
  {
    question:
      "A wave on a string has a tension of 100 N and a mass per unit length of 0.02 kg/m. If the string length is 2 meters and the frequency is 50 Hz, what is the wavelength?",
    answerOptions: [
      { answer: "1 meter", isCorrect: true },
      { answer: "0.5 meters", isCorrect: false },
      { answer: "2 meters", isCorrect: false },
      { answer: "4 meters", isCorrect: false },
    ],
  },
  {
    question:
      "If a pendulum has a period of 3 seconds, what is its length? (Assume g = 9.8 m/s²)",
    answerOptions: [
      { answer: "2.3 meters", isCorrect: true },
      { answer: "1.5 meters", isCorrect: false },
      { answer: "1.0 meters", isCorrect: false },
      { answer: "0.6 meters", isCorrect: false },
    ],
  },
  {
    question:
      "A mass-spring system has a period of 2 seconds. If the mass is increased to double its original value, what is the new period of oscillation?",
    answerOptions: [
      { answer: "2.83 seconds", isCorrect: true },
      { answer: "4 seconds", isCorrect: false },
      { answer: "1 second", isCorrect: false },
      { answer: "3 seconds", isCorrect: false },
    ],
  },
  {
    question:
      "A string vibrating in its third harmonic has a wavelength of 0.5 meters. What is the length of the string?",
    answerOptions: [
      { answer: "0.75 meters", isCorrect: true },
      { answer: "1 meter", isCorrect: false },
      { answer: "1.5 meters", isCorrect: false },
      { answer: "0.25 meters", isCorrect: false },
    ],
  },
  {
    question:
      "A wave traveling through water has a frequency of 4 Hz and a speed of 12 m/s. What is the phase difference between two points 3 meters apart?",
    answerOptions: [
      { answer: "2π radians", isCorrect: true },
      { answer: "π radians", isCorrect: false },
      { answer: "π/2 radians", isCorrect: false },
      { answer: "4π radians", isCorrect: false },
    ],
  },
  {
    question:
      "A block of mass 0.2 kg is attached to a spring with a spring constant of 25 N/m. What is the frequency of oscillation?",
    answerOptions: [
      { answer: "1.77 Hz", isCorrect: true },
      { answer: "2.5 Hz", isCorrect: false },
      { answer: "1 Hz", isCorrect: false },
      { answer: "3 Hz", isCorrect: false },
    ],
  },
  {
    question:
      "In SHM, if the displacement of a particle is given by x(t) = 0.1 cos(2πt + π/4), what is the phase constant of the oscillation?",
    answerOptions: [
      { answer: "π/4 radians", isCorrect: true },
      { answer: "π/2 radians", isCorrect: false },
      { answer: "π radians", isCorrect: false },
      { answer: "0 radians", isCorrect: false },
    ],
  },
  {
    question:
      "A mass on a spring oscillates with an angular frequency of 10 rad/s. If the mass is 0.8 kg, what is the spring constant?",
    answerOptions: [
      { answer: "64 N/m", isCorrect: true },
      { answer: "80 N/m", isCorrect: false },
      { answer: "40 N/m", isCorrect: false },
      { answer: "32 N/m", isCorrect: false },
    ],
  },
  {
    question:
      "A wave with a wavelength of 0.5 meters and a frequency of 20 Hz travels with a speed of 10 m/s. What is the phase velocity of the wave?",
    answerOptions: [
      { answer: "10 m/s", isCorrect: true },
      { answer: "20 m/s", isCorrect: false },
      { answer: "25 m/s", isCorrect: false },
      { answer: "5 m/s", isCorrect: false },
    ],
  },
  {
    question:
      "If a particle in SHM has a total energy of 8 J and an amplitude of 0.5 meters, what is the spring constant?",
    answerOptions: [
      { answer: "64 N/m", isCorrect: true },
      { answer: "32 N/m", isCorrect: false },
      { answer: "16 N/m", isCorrect: false },
      { answer: "128 N/m", isCorrect: false },
    ],
  },
  {
    question:
      "A sound wave in air has a wavelength of 0.2 meters and travels at a speed of 343 m/s. What is its frequency?",
    answerOptions: [
      { answer: "1715 Hz", isCorrect: true },
      { answer: "686 Hz", isCorrect: false },
      { answer: "343 Hz", isCorrect: false },
      { answer: "2000 Hz", isCorrect: false },
    ],
  },
  {
    question:
      "A mass on a spring has a maximum kinetic energy of 12 J and an amplitude of 0.4 meters. What is the spring constant?",
    answerOptions: [
      { answer: "180 N/m", isCorrect: true },
      { answer: "150 N/m", isCorrect: false },
      { answer: "200 N/m", isCorrect: false },
      { answer: "100 N/m", isCorrect: false },
    ],
  },
  {
    question:
      "A pendulum of length 0.5 meters has a period of 1.4 seconds. What is the acceleration due to gravity?",
    answerOptions: [
      { answer: "9.8 m/s²", isCorrect: true },
      { answer: "10 m/s²", isCorrect: false },
      { answer: "8.0 m/s²", isCorrect: false },
      { answer: "9.2 m/s²", isCorrect: false },
    ],
  },
  {
    question:
      "A wave pulse traveling along a string has a speed of 15 m/s and a wavelength of 0.3 meters. What is the frequency of the pulse?",
    answerOptions: [
      { answer: "50 Hz", isCorrect: true },
      { answer: "45 Hz", isCorrect: false },
      { answer: "40 Hz", isCorrect: false },
      { answer: "60 Hz", isCorrect: false },
    ],
  },
  {
    question:
      "A mass-spring system has a period of 1.5 seconds. If the spring constant is 120 N/m, what is the mass of the system?",
    answerOptions: [
      { answer: "6.0 kg", isCorrect: true },
      { answer: "4.0 kg", isCorrect: false },
      { answer: "8.0 kg", isCorrect: false },
      { answer: "3.0 kg", isCorrect: false },
    ],
  },
  {
    question:
      "A wave has a frequency of 100 Hz and a phase velocity of 300 m/s. What is the wavelength of the wave?",
    answerOptions: [
      { answer: "3 meters", isCorrect: true },
      { answer: "2 meters", isCorrect: false },
      { answer: "1 meter", isCorrect: false },
      { answer: "4 meters", isCorrect: false },
    ],
  },
  {
    question:
      "A particle in SHM has a maximum displacement of 0.6 meters and a maximum speed of 2.4 m/s. What is the frequency of the motion?",
    answerOptions: [
      { answer: "1 Hz", isCorrect: true },
      { answer: "2 Hz", isCorrect: false },
      { answer: "0.5 Hz", isCorrect: false },
      { answer: "4 Hz", isCorrect: false },
    ],
  },
  {
    question:
      "A sound wave traveling in air has a frequency of 256 Hz. If the speed of sound is 340 m/s, what is the wavelength of the sound wave?",
    answerOptions: [
      { answer: "1.33 meters", isCorrect: true },
      { answer: "1.25 meters", isCorrect: false },
      { answer: "2.0 meters", isCorrect: false },
      { answer: "0.85 meters", isCorrect: false },
    ],
  },
  {
    question:
      "A string fixed at both ends has a length of 1 meter. If the fundamental frequency of vibration is 50 Hz, what is the speed of waves on the string?",
    answerOptions: [
      { answer: "100 m/s", isCorrect: true },
      { answer: "50 m/s", isCorrect: false },
      { answer: "200 m/s", isCorrect: false },
      { answer: "75 m/s", isCorrect: false },
    ],
  },
  {
    question:
      "A mass-spring system oscillates with a frequency of 5 Hz and has a mass of 0.4 kg. What is the spring constant?",
    answerOptions: [
      { answer: "100 N/m", isCorrect: true },
      { answer: "80 N/m", isCorrect: false },
      { answer: "60 N/m", isCorrect: false },
      { answer: "40 N/m", isCorrect: false },
    ],
  },
  {
    question:
      "The phase difference between two points on a wave is π/2 radians. What is the path difference between these two points?",
    answerOptions: [
      { answer: "λ/4", isCorrect: true },
      { answer: "λ/2", isCorrect: false },
      { answer: "λ/8", isCorrect: false },
      { answer: "λ/3", isCorrect: false },
    ],
  },
  {
    question:
      "A simple pendulum has a length of 1.5 meters. What is its period of oscillation?",
    answerOptions: [
      { answer: "1.84 seconds", isCorrect: true },
      { answer: "2.0 seconds", isCorrect: false },
      { answer: "1.5 seconds", isCorrect: false },
      { answer: "1.0 seconds", isCorrect: false },
    ],
  },
  {
    question:
      "A wave on a string has a wavelength of 0.4 meters and a frequency of 12.5 Hz. What is the speed of the wave?",
    answerOptions: [
      { answer: "5 m/s", isCorrect: true },
      { answer: "4.8 m/s", isCorrect: false },
      { answer: "6 m/s", isCorrect: false },
      { answer: "3.5 m/s", isCorrect: false },
    ],
  },
  {
    question:
      "A particle in SHM has a maximum speed of 6 m/s and an amplitude of 0.8 meters. What is the angular frequency of the motion?",
    answerOptions: [
      { answer: "7.5 rad/s", isCorrect: true },
      { answer: "6 rad/s", isCorrect: false },
      { answer: "8 rad/s", isCorrect: false },
      { answer: "5 rad/s", isCorrect: false },
    ],
  },
  {
    question:
      "A car's suspension system is designed to absorb shocks and vibrations. If the car's suspension behaves like a mass-spring system with a spring constant of 20,000 N/m and a mass of 500 kg, what is the natural frequency of the system?",
    answerOptions: [
      { answer: "0.9 Hz", isCorrect: true },
      { answer: "1.8 Hz", isCorrect: false },
      { answer: "2.5 Hz", isCorrect: false },
      { answer: "0.5 Hz", isCorrect: false },
    ],
  },
  {
    question:
      "A violin string is tightened to produce a specific pitch. If the string has a length of 0.7 meters and a frequency of 440 Hz, what is the speed of the wave on the string?",
    answerOptions: [
      { answer: "308 m/s", isCorrect: true },
      { answer: "220 m/s", isCorrect: false },
      { answer: "350 m/s", isCorrect: false },
      { answer: "440 m/s", isCorrect: false },
    ],
  },
  {
    question:
      "A child on a swing moves back and forth with a period of 2 seconds. If the length of the swing's ropes is 1.5 meters, what is the acceleration due to gravity in this location?",
    answerOptions: [
      { answer: "9.8 m/s²", isCorrect: true },
      { answer: "10 m/s²", isCorrect: false },
      { answer: "8.5 m/s²", isCorrect: false },
      { answer: "9.2 m/s²", isCorrect: false },
    ],
  },
  {
    question:
      "A professional speaker's sound system generates sound waves with a frequency of 1,000 Hz. If the speed of sound in air is 343 m/s, what is the wavelength of the sound waves?",
    answerOptions: [
      { answer: "0.343 meters", isCorrect: true },
      { answer: "1.0 meters", isCorrect: false },
      { answer: "0.5 meters", isCorrect: false },
      { answer: "0.25 meters", isCorrect: false },
    ],
  },
  {
    question:
      "An amusement park ride features a rotating circular platform with a radius of 5 meters. If the platform completes one full revolution every 10 seconds, what is the centripetal acceleration experienced by a rider?",
    answerOptions: [
      { answer: "3.9 m/s²", isCorrect: true },
      { answer: "6.3 m/s²", isCorrect: false },
      { answer: "4.5 m/s²", isCorrect: false },
      { answer: "2.5 m/s²", isCorrect: false },
    ],
  },
  {
    question:
      "A washing machine vibrates during its spin cycle with an amplitude of 0.02 meters and a frequency of 1 Hz. What is the maximum speed of the vibrations?",
    answerOptions: [
      { answer: "0.12 m/s", isCorrect: true },
      { answer: "0.04 m/s", isCorrect: false },
      { answer: "0.1 m/s", isCorrect: false },
      { answer: "0.2 m/s", isCorrect: false },
    ],
  },
  {
    question:
      "A tuning fork produces a sound wave with a frequency of 512 Hz. If the speed of sound in air is 343 m/s, how far apart are two consecutive nodes in the sound wave?",
    answerOptions: [
      { answer: "0.343 meters", isCorrect: true },
      { answer: "0.5 meters", isCorrect: false },
      { answer: "1 meter", isCorrect: false },
      { answer: "0.25 meters", isCorrect: false },
    ],
  },
  {
    question:
      "An elevator cable is modeled as a mass-spring system. If the cable has a spring constant of 10,000 N/m and the mass of the elevator is 2,000 kg, what is the natural frequency of the cable's oscillation?",
    answerOptions: [
      { answer: "1.12 Hz", isCorrect: true },
      { answer: "2.0 Hz", isCorrect: false },
      { answer: "0.5 Hz", isCorrect: false },
      { answer: "0.8 Hz", isCorrect: false },
    ],
  },
  {
    question:
      "A clock pendulum has a length of 0.75 meters. If the clock ticks every second, what is the period of oscillation for the pendulum?",
    answerOptions: [
      { answer: "1.73 seconds", isCorrect: true },
      { answer: "1.0 seconds", isCorrect: false },
      { answer: "0.75 seconds", isCorrect: false },
      { answer: "2.0 seconds", isCorrect: false },
    ],
  },
  {
    question:
      "A loudspeaker emits a sound wave with a frequency of 500 Hz. If the speed of sound is 340 m/s, what is the frequency of the sound wave perceived by a person moving towards the speaker at 20 m/s?",
    answerOptions: [
      { answer: "510 Hz", isCorrect: true },
      { answer: "490 Hz", isCorrect: false },
      { answer: "530 Hz", isCorrect: false },
      { answer: "500 Hz", isCorrect: false },
    ],
  },
  {
    question:
      "A diving board oscillates with a frequency of 2 Hz and an amplitude of 0.1 meters. What is the maximum acceleration experienced by a diver jumping from the board?",
    answerOptions: [
      { answer: "1.58 m/s²", isCorrect: true },
      { answer: "0.8 m/s²", isCorrect: false },
      { answer: "2.5 m/s²", isCorrect: false },
      { answer: "1.0 m/s²", isCorrect: false },
    ],
  },
];

export default wavequestions;
