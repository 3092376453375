const groupefourteenquestions = [
    {
        question:"How many isotopes carbon have :",
        answerOptions: [
            {answer: 'one', isCorrect: false},
            {answer: 'two', isCorrect: false},
            {answer: 'three', isCorrect: true},
            {answer: 'foue', isCorrect: false},
        ],
    }, {
        question:"The property of self-linking is known as :",
        answerOptions: [
            {answer: 'irradiation', isCorrect: false},
            {answer: 'radioisotope', isCorrect: false},
            {answer: 'catenation', isCorrect: true},
            {answer: 'bond energy', isCorrect: false},
        ],
    },  {
        question:"Catenation depends mainly on  :",
        answerOptions: [
            {answer: 'bond length', isCorrect: false},
            {answer: 'bond energy', isCorrect: false},
            {answer: 'temperature', isCorrect: true},
            {answer: 'isotopes', isCorrect: false},
        ],
    },  {
        question:"The C - C bond energy is very high. Therefore, C has :",
        answerOptions: [
            {answer: 'maximum catenation property', isCorrect: true},
            {answer: 'minimum catenation property', isCorrect: false},
            {answer: 'remain unchanged', isCorrect: false},
            {answer: 'none of these', isCorrect: false},
        ],
    },  {
        question:"Sulphur also shows catenation because S - S bond energy is  :",
        answerOptions: [
            {answer: 'relatively low', isCorrect: false},
            {answer: 'relatively high', isCorrect: true},
            {answer: 'relatively constant', isCorrect: false},
            {answer: 'none of these', isCorrect: false},
        ],
    },  {
        question:"Which element has two-dimensional layerd structure:",
        answerOptions: [
            {answer: 'carbon', isCorrect: false},
            {answer: 'diamond', isCorrect: false},
            {answer: 'fullerence', isCorrect: false},
            {answer: 'graphite', isCorrect: true},
           
        ],
    }, {
        question:"Density of diamond (3.51g/cc) is greater than  :",
        answerOptions: [
            {answer: 'carbon', isCorrect: false},
            {answer: 'graphite', isCorrect: true},
            {answer: 'fullerence', isCorrect: false},
            {answer: 'none of these', isCorrect: false},
        ],
    },  {
        question:"It is a colourless, transparent, an hardest substance known as:",
        answerOptions: [
            {answer: 'carbon', isCorrect: false},
            {answer: 'diamond', isCorrect: true},
            {answer: 'fullerence', isCorrect: false},
            {answer: 'graphite', isCorrect: false},
        ],
    },  {
        question:"It is carbon clusters having cage like structures :",
        answerOptions: [
            {answer: 'diamond', isCorrect: false},
            {answer: 'graphite', isCorrect: false},
            {answer: 'fullerence', isCorrect: true},
            {answer: 'silicon', isCorrect: false},
        ],
    },  {
        question:"In fullerence π-bonds are delocalized to impart aromatic nature to  :",
        answerOptions: [
            {answer: 'C15', isCorrect: false},
            {answer: 'C30', isCorrect: false},
            {answer: 'C78', isCorrect: false},
            {answer: 'C60', isCorrect: true},
        ],
    },  {
        question:"It is produced when C burns in excess of O2 :",
        answerOptions: [
            {answer: 'CO', isCorrect: false},
            {answer: 'CO2', isCorrect: true},
            {answer: 'O2', isCorrect: false},
            {answer: 'all of these', isCorrect: false},
        ],
    },  {
        question:"The material used in solar cells contain :",
        answerOptions: [
            {answer: 'Ti', isCorrect: false},
            {answer: 'Sn', isCorrect: false},
            {answer: 'Si', isCorrect: false},
            {answer: 'Cs', isCorrect: true},
        ],
    },  {
        question:"Synthesis gas is a mixture of  :",
        answerOptions: [
            {answer: 'CO + N2', isCorrect: false},
            {answer: 'CO + H2', isCorrect: true},
            {answer: 'CH4 + CO', isCorrect: false},
            {answer: 'CO2 + N2', isCorrect: false},
        ],
    },  {
        question:"Which of the following elements forms only one hydride :",
        answerOptions: [
            {answer: 'Si', isCorrect: false},
            {answer: 'Ge', isCorrect: false},
            {answer: 'Sn', isCorrect: false},
            {answer: 'Pb', isCorrect: true},
        ],
    },  {
        question:"CO is absorbed by :",
        answerOptions: [
            {answer: 'oyrogallol', isCorrect: false},
            {answer: 'CHCl3', isCorrect: false},
            {answer: 'NH4OH + CuCl', isCorrect: true},
            {answer: 'CCl4', isCorrect: false},
        ],
    },  {
        question:"Which of the following is true for CO :",
        answerOptions: [
            {answer: 'Acid', isCorrect: false},
            {answer: 'Base', isCorrect: false},
            {answer: 'Salt', isCorrect: false},
            {answer: 'Door and acceptor', isCorrect: true},
        ],
    },  {
        question:"Lead pencil contains :",
        answerOptions: [
            {answer: 'CuS', isCorrect: true},
            {answer: 'PbS', isCorrect: false},
            {answer: 'Pb', isCorrect: false},
            {answer: 'graphite', isCorrect: false},
        ],
    },  {
        question:"Graphite, diamond and fullerence are belongs to :",
        answerOptions: [
            {answer: 'Lead family', isCorrect: false},
            {answer: 'Carbon family', isCorrect: true},
            {answer: 'Sulpher family', isCorrect: false},
            {answer: 'can not be said', isCorrect: false},
        ],
    },  {
        question:"Which of the following halide of C is used as refrigerant :",
        answerOptions: [
            {answer: 'CF4', isCorrect: false},
            {answer: 'CCl4', isCorrect: false},
            {answer: 'CH2F2', isCorrect: false},
            {answer: 'CCl2F2', isCorrect: true},
        ],
    },  {
        question:"It is very stable due to strong Si - O and Si - C bonds. :",
        answerOptions: [
            {answer: 'cyanide', isCorrect: false},
            {answer: 'graphite', isCorrect: false},
            {answer: 'polymers', isCorrect: true},
            {answer: 'carbides', isorrect: false},
        ],
    },
]

export default groupefourteenquestions;