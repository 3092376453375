const algebraquestions = [
  {
    question: "Solve for x: x^2 + 5x + 6 = 0",
    answerOptions: [
      { answer: "x = -1 or x = -6", isCorrect: false },
      { answer: "x = 2 or x = 3", isCorrect: false },
      { answer: "x = -2 or x = -3", isCorrect: true },
      { answer: "x = 1 or x = 6", isCorrect: false },
    ],
    correctAnswer: "x = -2 or x = -3",
    difficulty: "hard",
    category: "quadratic equation",
  },
  {
    question: "Solve for x: 2x^2 - 7x - 4 = 0",
    answerOptions: [
      { answer: "x = 1/2 or x = -4", isCorrect: false },
      { answer: "x = -1/2 or x = 4", isCorrect: true },
      { answer: "x = -1 or x = -4", isCorrect: false },
      { answer: "x = 1 or x = 4", isCorrect: false },
    ],
    correctAnswer: "x = -1/2 or x = 4",
    difficulty: "easy",
    category: "quadratic equation",
  },
  {
    question: "Solve for x: x^2 + 4x + 4 = 0",
    answerOptions: [
      { answer: "x = -2", isCorrect: true },
      { answer: "x = -1", isCorrect: false },
      { answer: "x = 1", isCorrect: false },
      { answer: "x = 2", isCorrect: false },
    ],
    correctAnswer: "x = -2",
    difficulty: "easy",
    category: "quadratic equation",
  },
  {
    question: "Find the complex conjugate of 3 + 4i",
    answerOptions: [
      { answer: "3 - 4i", isCorrect: true },
      { answer: "3 + 4i", isCorrect: false },
      { answer: "-3 + 4i", isCorrect: false },
      { answer: "-3 - 4i", isCorrect: false },
    ],
    correctAnswer: "3 - 4i",
    difficulty: "easy",
    category: "complex number",
  },
  {
    question: "If A = [[1, 2], [3, 4]], find A^2",
    answerOptions: [
      { answer: "[[7, 10], [15, 22]]", isCorrect: true },
      { answer: "[[5, 8], [11, 16]]", isCorrect: false },
      { answer: "[[3, 6], [9, 12]]", isCorrect: false },
      { answer: "[[2, 4], [6, 8]]", isCorrect: false },
    ],
    correctAnswer: "[[7, 10], [15, 22]]",
    difficulty: "medium",
    category: "matrix",
  },
  {
    question: "Solve for x: x^2 - 7x + 12 = 0",
    answerOptions: [
      { answer: "x = 3 or x = 4", isCorrect: true },
      { answer: "x = 1 or x = 2", isCorrect: false },
      { answer: "x = -1 or x = -2", isCorrect: false },
      { answer: "x = -3 or x = -4", isCorrect: false },
    ],
    correctAnswer: "x = 3 or x = 4",
    difficulty: "easy",
    category: "quadratic equation",
  },
  {
    question: "Find the modulus of 2 + 3i",
    answerOptions: [
      { answer: "sqrt(10)", isCorrect: false },
      { answer: "sqrt(5)", isCorrect: false },
      { answer: "sqrt(13)", isCorrect: true },
      { answer: "sqrt(2)", isCorrect: false },
    ],
    correctAnswer: "sqrt(13)",
    difficulty: "easy",
    category: "complex number",
  },
  {
    question: "Solve for x: x^2 + 2x + 1 = 0",
    answerOptions: [
      { answer: "x = -1", isCorrect: true },
      { answer: "x = 1", isCorrect: false },
      { answer: "x = -2", isCorrect: false },
      { answer: "x = 2", isCorrect: false },
    ],
    correctAnswer: "x = -1",
    difficulty: "easy",
    category: "quadratic equation",
  },
  {
    question: "Find the argument of 1 - i",
    answerOptions: [
      { answer: "π/4", isCorrect: false },
      { answer: "-π/4", isCorrect: true },
      { answer: "π/2", isCorrect: false },
      { answer: "-π/2", isCorrect: false },
    ],
    correctAnswer: "-π/4",
    difficulty: "medium",
    category: "complex number",
  },
  {
    question: "Solve for x: x^2 - 9x + 20 = 0",
    answerOptions: [
      { answer: "x = 1 or x = 2", isCorrect: false },
      { answer: "x = -1 or x = -2", isCorrect: false },
      { answer: "x = -4 or x = -5", isCorrect: false },
      { answer: "x = 4 or x = 5", isCorrect: true },
    ],
    correctAnswer: "x = 4 or x = 5",
    difficulty: "easy",
    category: "quadratic equation",
  },
  {
    question: "Find the complex conjugate of 2 - 5i",
    answerOptions: [
      { answer: "2 + 5i", isCorrect: true },
      { answer: "2 - 5i", isCorrect: false },
      { answer: "-2 + 5i", isCorrect: false },
      { answer: "-2 - 5i", isCorrect: false },
    ],
    correctAnswer: "2 + 5i",
    difficulty: "advanced",
    category: "complex number",
  },
  {
    question: "Prove that 2^n > n^2 for all natural numbers n ≥ 5",
    answerOptions: [
      {
        answer:
          "Base case: 2^5 > 5^2, Inductive step: assume true for n=k, prove for n=k+1",
        isCorrect: true,
      },
      {
        answer:
          "Base case: 2^4 > 4^2, Inductive step: assume true for n=k, prove for n=k+1",
        isCorrect: false,
      },
      {
        answer:
          "Base case: 2^3 > 3^2, Inductive step: assume true for n=k, prove for n=k+1",
        isCorrect: false,
      },
      {
        answer:
          "Base case: 2^2 > 2^2, Inductive step: assume true for n=k, prove for n=k+1",
        isCorrect: false,
      },
    ],
    correctAnswer:
      "Base case: 2^5 > 5^2, Inductive step: assume true for n=k, prove for n=k+1",
    difficulty: "advanced",
    category: "proof",
  },
  {
    question: "Find the complex conjugate of 3 + 4i",
    answerOptions: [
      { answer: "3 - 4i", isCorrect: true },
      { answer: "3 + 4i", isCorrect: false },
      { answer: "-3 + 4i", isCorrect: false },
      { answer: "-3 - 4i", isCorrect: false },
    ],
    correctAnswer: "3 - 4i",
    difficulty: "easy",
    category: "complex number",
  },
  {
    question: "Solve for x: x^2 + 5x + 6 = 0",
    answerOptions: [
      { answer: "x = -2 or x = -3", isCorrect: true },
      { answer: "x = -1 or x = -6", isCorrect: false },
      { answer: "x = 2 or x = 3", isCorrect: false },
      { answer: "x = 1 or x = 6", isCorrect: false },
    ],
    correctAnswer: "x = -2 or x = -3",
    difficulty: "easy",
    category: "quadratic equation",
  },
  {
    question: "Solve for x: 2x + 3 = 7",
    answerOptions: [
      { answer: "x = 1", isCorrect: false },
      { answer: "x = -1", isCorrect: false },
      { answer: "x = -2", isCorrect: false },
      { answer: "x = 2", isCorrect: true },
    ],
    correctAnswer: "x = 2",
    difficulty: "easy",
    category: "linear equation",
  },
  {
    question: "Find the determinant of [[2, 1], [4, 3]]",
    answerOptions: [
      { answer: "2", isCorrect: true },
      { answer: "-2", isCorrect: false },
      { answer: "1", isCorrect: false },
      { answer: "-1", isCorrect: false },
    ],
    correctAnswer: "2",
    difficulty: "medium",
    category: "matrix",
  },
  {
    question: "Expand (x + 2)^3",
    answerOptions: [
      { answer: "x^3 + 6x^2 + 12x + 8", isCorrect: true },
      { answer: "x^3 + 3x^2 + 3x + 1", isCorrect: false },
      { answer: "x^3 - 6x^2 + 12x - 8", isCorrect: false },
      { answer: "x^3 - 3x^2 + 3x - 1", isCorrect: false },
    ],
    correctAnswer: "x^3 + 6x^2 + 12x + 8",
    difficulty: "easy",
    category: "polynomial",
  },

  {
    question: "Find the number of ways to choose 3 items from 5 items",
    answerOptions: [
      { answer: "10", isCorrect: true },
      { answer: "5", isCorrect: false },
      { answer: "15", isCorrect: false },
      { answer: "20", isCorrect: false },
    ],
    correctAnswer: "10",
    difficulty: "medium",
    category: "combinatorics",
  },
  {
    question:
      "Find the sum of the first 5 terms of the geometric series: 2 + 4 + 8 + ...",
    answerOptions: [
      { answer: "62", isCorrect: true },
      { answer: "30", isCorrect: false },
      { answer: "42", isCorrect: false },
      { answer: "54", isCorrect: false },
    ],
    correctAnswer: "62",
    difficulty: "medium",
    category: "series",
  },
  {
    question: "Find the modulus of 3 + 4i",
    answerOptions: [
      { answer: "3", isCorrect: false },
      { answer: "4", isCorrect: false },
      { answer: "5", isCorrect: true },
      { answer: "7", isCorrect: false },
    ],
    correctAnswer: "5",
    difficulty: "easy",
    category: "complex number",
  },
  {
    question: "Solve for x: x^2 - 4x - 3 = 0",
    answerOptions: [
      { answer: "x = -1 or x = 3", isCorrect: true },
      { answer: "x = 1 or x = -3", isCorrect: false },
      { answer: "x = -1 or x = -3", isCorrect: false },
      { answer: "x = 1 or x = 3", isCorrect: false },
    ],
    correctAnswer: "x = -1 or x = 3",
    difficulty: "medium",
    category: "quadratic equation",
  },
  {
    question: "Solve for x: x - 2 = 5",
    answerOptions: [
      { answer: "x = 7", isCorrect: true },
      { answer: "x = 5", isCorrect: false },
      { answer: "x = 3", isCorrect: false },
      { answer: "x = 1", isCorrect: false },
    ],
    correctAnswer: "x = 7",
    difficulty: "easy",
    category: "linear equation",
  },
  {
    question: "Find the coefficient of x^2 in (x + 2)^4",
    answerOptions: [
      { answer: "12", isCorrect: true },
      { answer: "6", isCorrect: false },
      { answer: "8", isCorrect: false },
      { answer: "10", isCorrect: false },
    ],
    correctAnswer: "12",
    difficulty: "medium",
    category: "binomial theorem",
  },
  {
    question:
      "Find the determinant of [[1, 1, 1, 1], [1, 2, 3, 4], [1, 3, 5, 7], [1, 4, 7, 10]]",
    answerOptions: [
      { answer: "-6", isCorrect: false },
      { answer: "6", isCorrect: true },
      { answer: "1", isCorrect: false },
      { answer: "-1", isCorrect: false },
    ],
    correctAnswer: "6",
    difficulty: "advanced",
    category: "matrix",
  },
  {
    question: "Find the number of ways to arrange 3 items from 4 items",
    answerOptions: [
      { answer: "24", isCorrect: true },
      { answer: "12", isCorrect: false },
      { answer: "18", isCorrect: false },
      { answer: "20", isCorrect: false },
    ],
    correctAnswer: "24",
    difficulty: "medium",
    category: "permutations",
  },
  {
    question:
      "Find the sum of the first 6 terms of the arithmetic series: 2 + 5 + 8 + ...",
    answerOptions: [
      { answer: "60", isCorrect: false },
      { answer: "72", isCorrect: false },
      { answer: "84", isCorrect: true },
      { answer: "96", isCorrect: false },
    ],
    correctAnswer: "84",
    difficulty: "medium",
    category: "series",
  },
  {
    question: "Prove that 2^n - 1 is divisible by 3 for all natural numbers n",
    answerOptions: [
      {
        answer:
          "Base case: 2^1 - 1 = 1, Inductive step: assume true for n=k, prove for n=k+1",
        isCorrect: true,
      },
      {
        answer:
          "Base case: 2^2 - 1 = 3, Inductive step: assume true for n=k, prove for n=k+1",
        isCorrect: false,
      },
      {
        answer:
          "Base case: 2^3 - 1 = 7, Inductive step: assume true for n=k, prove for n=k+1",
        isCorrect: false,
      },
      {
        answer:
          "Base case: 2^4 - 1 = 15, Inductive step: assume true for n=k, prove for n=k+1",
        isCorrect: false,
      },
    ],
    correctAnswer:
      "Base case: 2^1 - 1 = 1, Inductive step: assume true for n=k, prove for n=k+1",
    difficulty: "advanced",
    category: "proof",
  },

  {
    question: "Solve for z: z^2 + 4z + 5 = 0, where z is a complex number.",
    answerOptions: [
      { answer: "z = -2 + 2i or z = -2 - 2i", isCorrect: false },
      { answer: "z = 2 + i or z = 2 - i", isCorrect: false },
      { answer: "z = -4 + i or z = -4 - i", isCorrect: false },
      { answer: "z = -2 + i or z = -2 - i", isCorrect: true },
    ],
    correctAnswer: "z = -2 + i or z = -2 - i",
    difficulty: "Medium",
    category: "Complex Numbers",
  },
  {
    question: "Solve the quadratic equation: x^2 - 3x + 2 = 0.",
    answerOptions: [
      { answer: "x = 1 or x = 2", isCorrect: true },
      { answer: "x = -1 or x = -2", isCorrect: false },
      { answer: "x = 3 or x = 2", isCorrect: false },
      { answer: "x = -3 or x = -2", isCorrect: false },
    ],
    correctAnswer: "x = 1 or x = 2",
    difficulty: "Easy",
    category: "Quadratic Equations",
  },
  {
    question: "Given matrix A = [[1, 0], [0, 1]], find A^3.",
    answerOptions: [
      { answer: "[[1, 0], [0, 1]]", isCorrect: true },
      { answer: "[[1, 1], [1, 1]]", isCorrect: false },
      { answer: "[[0, 1], [1, 0]]", isCorrect: false },
      { answer: "[[0, 0], [0, 0]]", isCorrect: false },
    ],
    correctAnswer: "[[1, 0], [0, 1]]",
    difficulty: "Easy",
    category: "Matrices",
  },
  {
    question: "Solve the quadratic equation: 4x^2 - 4x + 1 = 0.",
    answerOptions: [
      { answer: "x = -1/2", isCorrect: false },
      { answer: "x = 1/2", isCorrect: true },
      { answer: "x = 1 or x = 0", isCorrect: false },
      { answer: "x = -1 or x = 1", isCorrect: false },
    ],
    correctAnswer: "x = 1/2",
    difficulty: "Medium",
    category: "Quadratic Equations",
  },
  {
    question: "Solve the quadratic equation: x^2 + x - 12 = 0.",
    answerOptions: [
      { answer: "x = -3 or x = 4", isCorrect: false },
      { answer: "x = 2 or x = -6", isCorrect: false },
      { answer: "x = 3 or x = -4", isCorrect: true },
      { answer: "x = -2 or x = 6", isCorrect: false },
    ],
    correctAnswer: "x = 3 or x = -4",
    difficulty: "Easy",
    category: "Quadratic Equations",
  },
  {
    question: "Solve the quadratic equation: 2x^2 - 8x + 6 = 0.",
    answerOptions: [
      { answer: "x = 1 or x = 3", isCorrect: true },
      { answer: "x = -1 or x = -3", isCorrect: false },
      { answer: "x = 2 or x = -3", isCorrect: false },
      { answer: "x = 1 or x = -3", isCorrect: false },
    ],
    correctAnswer: "x = 1 or x = 3",
    difficulty: "Medium",
    category: "Quadratic Equations",
  },
  {
    question:
      "Prove by mathematical induction the formula for the sum of the first n natural numbers.",
    answerOptions: [
      { answer: "n(n + 1)/2", isCorrect: true },
      { answer: "n(n - 1)/2", isCorrect: false },
      { answer: "n(n + 1)/3", isCorrect: false },
      { answer: "n(n - 1)/3", isCorrect: false },
    ],
    correctAnswer: "n(n + 1)/2",
    difficulty: "Medium",
    category: "Mathematical Induction",
  },
  {
    question:
      "Prove by induction that the sum of an arithmetic series is n/2 * (first term + last term).",
    answerOptions: [
      { answer: "True", isCorrect: true },
      { answer: "False", isCorrect: false },
    ],
    correctAnswer: "True",
    difficulty: "Medium",
    category: "Mathematical Induction",
  },
  {
    question: "Find the number of permutations of the word 'INDUCTION'.",
    answerOptions: [
      { answer: "8!", isCorrect: false },
      { answer: "7!", isCorrect: false },
      { answer: "6!", isCorrect: false },
      { answer: "9!", isCorrect: true },
    ],
    correctAnswer: "9!",
    difficulty: "Easy",
    category: "Permutations and Combinations",
  },
  {
    question: "How many ways can you select 3 items from a set of 5?",
    answerOptions: [
      { answer: "10", isCorrect: true },
      { answer: "15", isCorrect: false },
      { answer: "20", isCorrect: false },
      { answer: "25", isCorrect: false },
    ],
    correctAnswer: "10",
    difficulty: "Easy",
    category: "Permutations and Combinations",
  },
  {
    question:
      "Solve the quadratic equation x^2 + 5x + 6 = 0 using the quadratic formula and verify the solution.",
    answerOptions: [
      { answer: "x = -2 or x = -3", isCorrect: true },
      { answer: "x = -1 or x = -6", isCorrect: false },
      { answer: "x = 2 or x = 3", isCorrect: false },
      { answer: "x = 1 or x = 6", isCorrect: false },
    ],
    correctAnswer: "x = -2 or x = -3",
    difficulty: "Hard",
    category: "Quadratic Equations",
  },
  {
    question:
      "Find the argument of the complex number 2 + 3i and express it in terms of an exact trigonometric function.",
    answerOptions: [
      { answer: "tan^(-1)(3/2)", isCorrect: true },
      { answer: "tan^(-1)(2/3)", isCorrect: false },
      { answer: "tan^(-1)(1/2)", isCorrect: false },
      { answer: "tan^(-1)(1/3)", isCorrect: false },
    ],
    correctAnswer: "tan^(-1)(3/2)",
    difficulty: "Hard",
    category: "Complex Numbers",
  },

  {
    question: "Solve the quadratic equation: x^2 + 2x - 6 = 0.",
    answerOptions: [
      { answer: "x = -3 or x = 2", isCorrect: true },
      { answer: "x = -2 or x = 3", isCorrect: false },
      { answer: "x = -1 or x = -6", isCorrect: false },
      { answer: "x = 1 or x = 6", isCorrect: false },
    ],
    correctAnswer: "x = -3 or x = 2",
    difficulty: "Medium",
    category: "Quadratic Equations",
  },

  {
    question:
      "Solve the linear equation 3x - 2 = 11 for x, and then verify the solution by substituting back into the original equation.",
    answerOptions: [
      { answer: "x = 13/3", isCorrect: true },
      { answer: "x = 11/3", isCorrect: false },
      { answer: "x = 10/3", isCorrect: false },
      { answer: "x = 12/3", isCorrect: false },
    ],
    correctAnswer: "x = 13/3",
    difficulty: "Hard",
    category: "Linear Equations",
  },
  {
    question:
      "Expand (x - 2)^3 and then simplify the expression to its most compact form.",
    answerOptions: [
      { answer: "x^3 - 6x^2 + 12x - 8", isCorrect: true },
      { answer: "x^3 + 6x^2 + 12x + 8", isCorrect: false },
      { answer: "x^3 - 6x^2 - 12x + 8", isCorrect: false },
      { answer: "x^3 + 6x^2 - 12x - 8", isCorrect: false },
    ],
    correctAnswer: "x^3 - 6x^2 + 12x - 8",
    difficulty: "Hard",
    category: "Algebraic Expansions",
  },
  {
    question:
      "Find the number of ways to choose 2 items from a set of 6 items, and then calculate the same using the combination formula C(n, k) where n = 6 and k = 2.",
    answerOptions: [
      { answer: "15", isCorrect: true },
      { answer: "10", isCorrect: false },
      { answer: "12", isCorrect: false },
      { answer: "20", isCorrect: false },
    ],
    correctAnswer: "15",
    difficulty: "Hard",
    category: "Combinations",
  },
  {
    question:
      "Find the sum of the first 7 terms of the geometric series: 1 + 2 + 4 + ..., and then derive the formula used to compute this sum.",
    answerOptions: [
      { answer: "127", isCorrect: true },
      { answer: "64", isCorrect: false },
      { answer: "96", isCorrect: false },
      { answer: "112", isCorrect: false },
    ],
    correctAnswer: "127",
    difficulty: "Hard",
    category: "Geometric Series",
  },
  {
    question: "If A = [[1, 2, 3], [4, 5, 6], [7, 8, 9]], find A^2 + 2A - I.",
    answerOptions: [
      {
        answer: "[[21, 30, 39], [48, 66, 84], [75, 102, 129]]",
        isCorrect: true,
      },
      { answer: "[[1, 2, 3], [4, 5, 6], [7, 8, 9]]", isCorrect: false },
      { answer: "[[2, 4, 6], [8, 10, 12], [14, 16, 18]]", isCorrect: false },
      { answer: "[[3, 6, 9], [12, 15, 18], [21, 24, 27]]", isCorrect: false },
    ],
    correctAnswer: "[[21, 30, 39], [48, 66, 84], [75, 102, 129]]",
    difficulty: "Hard",
    category: "Matrices",
  },
  {
    question:
      "Prove that n^2 - 1 is divisible by 8 for all even natural numbers n.",
    answerOptions: [
      {
        answer:
          "Base case: 2^2 - 1 = 3, Inductive step: assume true for n=k, prove for n=k+2",
        isCorrect: true,
      },
      {
        answer:
          "Base case: 2^2 - 1 = 4, Inductive step: assume true for n=k, prove for n=k+2",
        isCorrect: false,
      },
      {
        answer:
          "Base case: 2^2 - 1 = 5, Inductive step: assume true for n=k, prove for n=k+2",
        isCorrect: false,
      },
      {
        answer:
          "Base case: 2^2 - 1 = 7, Inductive step: assume true for n=k, prove for n=k+2",
        isCorrect: false,
      },
    ],
    correctAnswer:
      "Base case: 2^2 - 1 = 3, Inductive step: assume true for n=k, prove for n=k+2",
    difficulty: "Hard",
    category: "Mathematical Induction",
  },
  {
    question:
      "Prove by mathematical induction that for all positive integers n, the sum of the first n positive odd numbers is n^2.",
    answerOptions: [
      { answer: "n^2", isCorrect: true },
      { answer: "n(n+1)/2", isCorrect: false },
      { answer: "n(n+1)", isCorrect: false },
      { answer: "n(n-1)/2", isCorrect: false },
    ],
    correctAnswer: "n^2",
    difficulty: "Medium",
    category: "Mathematical Induction",
  },
  {
    question:
      "Find the modulus of the complex number z = 3 - 4i and then find the argument of the complex number w = 3 + 4i.",
    answerOptions: [
      { answer: "Modulus = 5 and Argument = tan^(-1)(4/3)", isCorrect: true },
      { answer: "Modulus = 7 and Argument = tan^(-1)(3/4)", isCorrect: false },
      { answer: "Modulus = 5 and Argument = pi/4", isCorrect: false },
      { answer: "Modulus = sqrt(13) and Argument = pi/2", isCorrect: false },
    ],
    correctAnswer: "Modulus = 5 and Argument = tan^(-1)(4/3)",
    difficulty: "Medium",
    category: "Complex Numbers",
  },
  {
    question:
      "Solve the quadratic equation x^2 - 5x + 6 = 0, and then determine the sum and product of the roots.",
    answerOptions: [
      { answer: "Sum = 5, Product = 6", isCorrect: true },
      { answer: "Sum = 4, Product = 6", isCorrect: false },
      { answer: "Sum = 5, Product = 4", isCorrect: false },
      { answer: "Sum = 6, Product = 5", isCorrect: false },
    ],
    correctAnswer: "Sum = 5, Product = 6",
    difficulty: "Medium",
    category: "Quadratic Equations",
  },
  {
    question:
      "Solve the linear system of equations: 2x + 3y = 7 and 4x - y = 1. Then, find the value of x + 2y.",
    answerOptions: [
      { answer: "x = 2, y = 1 and x + 2y = 4", isCorrect: true },
      { answer: "x = 1, y = 2 and x + 2y = 5", isCorrect: false },
      { answer: "x = 3, y = 0 and x + 2y = 3", isCorrect: false },
      { answer: "x = 0, y = 3 and x + 2y = 6", isCorrect: false },
    ],
    correctAnswer: "x = 2, y = 1 and x + 2y = 4",
    difficulty: "Medium",
    category: "Linear Equations",
  },
  {
    question: "What is the coefficient of x^2 in the expansion of (2x + 3)^4?",
    answerOptions: [
      { answer: "12", isCorrect: true },
      { answer: "24", isCorrect: false },
      { answer: "18", isCorrect: false },
      { answer: "9", isCorrect: false },
    ],
    correctAnswer: "12",
    difficulty: "Medium",
    category: "Algebraic Expansions",
  },
  {
    question: "Find the determinant of [[1, 2, 3], [4, 5, 6], [7, 8, 9]].",
    answerOptions: [
      { answer: "0", isCorrect: true },
      { answer: "1", isCorrect: false },
      { answer: "-1", isCorrect: false },
      { answer: "2", isCorrect: false },
    ],
    correctAnswer: "0",
    difficulty: "Medium",
    category: "Determinants",
  },
  {
    question:
      "Solve the linear system of equations 3x - 2y = 11 and 5x + 4y = 7. Then, find the value of the expression 2x + 3y using the obtained solutions for x and y.",
    answerOptions: [
      { answer: "x = 3, y = -1 and 2x + 3y = 3", isCorrect: true },
      { answer: "x = 2, y = 1 and 2x + 3y = 7", isCorrect: false },
      { answer: "x = 1, y = 2 and 2x + 3y = 8", isCorrect: false },
      { answer: "x = -1, y = 3 and 2x + 3y = 7", isCorrect: false },
    ],
    correctAnswer: "x = 3, y = -1 and 2x + 3y = 3",
    difficulty: "Hard",
    category: "Linear Equations",
  },
  {
    question:
      "How many permutations can be formed from the letters of the word 'PROBLEM'?",
    answerOptions: [
      { answer: "5040", isCorrect: true },
      { answer: "720", isCorrect: false },
      { answer: "40320", isCorrect: false },
      { answer: "1260", isCorrect: false },
    ],
    correctAnswer: "5040",
    difficulty: "Easy",
    category: "Permutations",
  },
  {
    question:
      "In how many ways can 4 books be selected from a shelf of 10 books?",
    answerOptions: [
      { answer: "120", isCorrect: false },
      { answer: "240", isCorrect: false },
      { answer: "100", isCorrect: false },
      { answer: "210", isCorrect: true },
    ],
    correctAnswer: "210",
    difficulty: "Easy",
    category: "Combinations",
  },
  {
    question:
      "Find the nth term of the arithmetic sequence where the first term is 5 and the common difference is 3.",
    answerOptions: [
      { answer: "5 × (n-1) × 3", isCorrect: false },
      { answer: "5 + n × 3", isCorrect: false },
      { answer: "5 + n × 3 - 3", isCorrect: false },
      { answer: "5 + (n-1) × 3", isCorrect: true },
    ],
    correctAnswer: "5 + (n-1) × 3",
    difficulty: "Easy",
    category: "Arithmetic Sequences",
  },

  {
    question:
      "What is the sum of the first 10 terms of the geometric sequence 2, 6, 18, ...?",
    answerOptions: [
      { answer: "1620", isCorrect: false },
      { answer: "12000", isCorrect: false },
      { answer: "11880", isCorrect: true },
      { answer: "10000", isCorrect: false },
    ],
    correctAnswer: "11880",
    difficulty: "Medium",
    category: "Geometric Sequences",
  },
  {
    question:
      "If the roots of the quadratic equation ax^2 + bx + c = 0 are 3 and -2, find the value of a(b+c).",
    answerOptions: [
      { answer: "-15a", isCorrect: true },
      { answer: "15a", isCorrect: false },
      { answer: "-5a", isCorrect: false },
      { answer: "5a", isCorrect: false },
    ],
    correctAnswer: "-15a",
    difficulty: "Medium",
    category: "Quadratic Equations",
  },
  {
    question: "Determine the sum of the series 1 + 2 + 4 + 8 + ... + 2^n.",
    answerOptions: [
      { answer: "2^(n+1) - 1", isCorrect: true },
      { answer: "2^n - 1", isCorrect: false },
      { answer: "2^(n+1)", isCorrect: false },
      { answer: "2^n + 1", isCorrect: false },
    ],
    correctAnswer: "2^(n+1) - 1",
    difficulty: "Medium",
    category: "Series and Sequences",
  },
  {
    question:
      "Prove by mathematical induction that for all positive integers n, the sum of the first n positive odd numbers is n^2.",
    answerOptions: [
      { answer: "n^2", isCorrect: true },
      { answer: "n(n+1)/2", isCorrect: false },
      { answer: "n(n+1)", isCorrect: false },
      { answer: "n(n-1)/2", isCorrect: false },
    ],
    correctAnswer: "n^2",
    difficulty: "Hard",
    category: "Mathematical Induction",
  },
  {
    question:
      "Find the modulus and argument of the complex number z = 3 - 4i. Then, compute the value of z^2 and its modulus.",
    answerOptions: [
      {
        answer: "|z| = 5, arg(z) = -arctan(4/3), z^2 = -7 - 24i, |z^2| = 25",
        isCorrect: true,
      },
      {
        answer: "|z| = 5, arg(z) = -atan(4/3), z^2 = -7 + 24i, |z^2| = 25",
        isCorrect: false,
      },
      {
        answer: "|z| = √13, arg(z) = -atan(4/3), z^2 = 7 - 24i, |z^2| = √13",
        isCorrect: false,
      },
      {
        answer: "|z| = 5, arg(z) = -atan(4/3), z^2 = 7 + 24i, |z^2| = 49",
        isCorrect: false,
      },
    ],
    correctAnswer:
      "Modulus = 5, Argument = -atan(4/3), z^2 = -7 - 24i, Modulus of z^2 = 25",
    difficulty: "Hard",
    category: "Complex Numbers",
  },
  {
    question: "What is the coefficient of x^2 in the expansion of (2x + 3)^4?",
    answerOptions: [
      { answer: "54", isCorrect: true },
      { answer: "12", isCorrect: false },
      { answer: "24", isCorrect: false },
      { answer: "81", isCorrect: false },
    ],
    correctAnswer: "54",
    difficulty: "Medium",
    category: "Binomial Expansion",
  },
  {
    question:
      "How many different permutations can be made from the letters in the word 'PERMUTATION'?",
    answerOptions: [
      { answer: "11!/2", isCorrect: true },
      { answer: "11!", isCorrect: false },
      { answer: "10!", isCorrect: false },
      { answer: "10!/2", isCorrect: false },
    ],
    correctAnswer: "11!/2",
    difficulty: "Hard",
    category: "Permutations",
  },
  {
    question:
      "What is the 7th term of the arithmetic sequence where the first term is 3 and the common difference is 4?",
    answerOptions: [
      { answer: "31", isCorrect: false },
      { answer: "35", isCorrect: false },
      { answer: "27", isCorrect: true },
      { answer: "39", isCorrect: false },
    ],
    correctAnswer: "27",
    difficulty: "Medium",
    category: "Arithmetic Sequences",
  },
  {
    question: "If A = [[2, 1], [3, 2]], find A^-1",
    answerOptions: [
      { answer: "[[2, -1], [-3, 2]]", isCorrect: true },
      { answer: "[[1, 1], [1, 1]]", isCorrect: false },
      { answer: "[[2, 2], [1, 1]]", isCorrect: false },
      { answer: "[[1, 2], [2, 1]]", isCorrect: false },
    ],
    correctAnswer: "[[2, -1], [-3, 2]]",
    difficulty: "Hard",
    category: "Matrix Inversion",
  },
  {
    question:
      "Find the sum of the first 10 terms of the geometric sequence with the first term 2 and common ratio 3.",
    answerOptions: [
      { answer: "11880", isCorrect: true },
      { answer: "12000", isCorrect: false },
      { answer: "12180", isCorrect: false },
      { answer: "12200", isCorrect: false },
    ],
    correctAnswer: "11880",
    difficulty: "Medium",
    category: "Geometric Sequences",
  },
  {
    question:
      "In how many ways can 5 people be seated around a circular table?",
    answerOptions: [
      { answer: "120", isCorrect: false },
      { answer: "24", isCorrect: true },
      { answer: "60", isCorrect: false },
      { answer: "24", isCorrect: true },
    ],
    correctAnswer: "24",
    difficulty: "Medium",
    category: "Permutations",
  },
  {
    question:
      "What is the binomial coefficient for the term x^3y^2 in the expansion of (2x + 3y)^5?",
    answerOptions: [
      { answer: "10", isCorrect: false },
      { answer: "30", isCorrect: true },
      { answer: "60", isCorrect: false },
      { answer: "90", isCorrect: false },
    ],
    correctAnswer: "30",
    difficulty: "Medium",
    category: "Binomial Expansion",
  },
  {
    question:
      "Using mathematical induction, prove that for all integers n ≥ 1, the inequality 2^n ≥ n^2 holds.",
    answerOptions: [
      { answer: "True", isCorrect: true },
      { answer: "False", isCorrect: false },
    ],
    correctAnswer: "True",
    difficulty: "Hard",
    category: "Mathematical Induction",
  },
  {
    question:
      "Prove by mathematical induction that for all positive integers n, the sum of the first n positive even numbers is n(n+1).",
    answerOptions: [
      { answer: "n(n+1)", isCorrect: true },
      { answer: "n^2 + n", isCorrect: false },
      { answer: "n(n-1)", isCorrect: false },
      { answer: "n(n+2)", isCorrect: false },
    ],
    correctAnswer: "n(n+1)",
    difficulty: "Hard",
    category: "Mathematical Induction",
  },

  {
    question: "Find the argument of the complex number z = -1 + i√3.",
    answerOptions: [
      { answer: "2π/3", isCorrect: true },
      { answer: "π/3", isCorrect: false },
      { answer: "π", isCorrect: false },
      { answer: "π/2", isCorrect: false },
    ],
    correctAnswer: "2π/3",
    difficulty: "Medium",
    category: "Complex Numbers",
  },
  {
    question:
      "Solve the quadratic equation x^2 + 4x - 5 = 0 and find the roots.",
    answerOptions: [
      { answer: "x = 1 or x = -5", isCorrect: false },
      { answer: "x = -1 or x = 5", isCorrect: true },
      { answer: "x = -1 or x = 5", isCorrect: true },
      { answer: "x = 1 or x = -1", isCorrect: false },
    ],
    correctAnswer: "x = -1 or x = 5",
    difficulty: "Easy",
    category: "Quadratic Equations",
  },
  {
    question:
      "Solve the linear system of equations: 3x - 2y = 4 and x + y = 1.",
    answerOptions: [
      { answer: "x = 2, y = -1", isCorrect: true },
      { answer: "x = 1, y = 0", isCorrect: false },
      { answer: "x = 0, y = 1", isCorrect: false },
      { answer: "x = -1, y = 2", isCorrect: false },
    ],
    correctAnswer: "x = 2, y = -1",
    difficulty: "Medium",
    category: "Linear Systems",
  },
  {
    question: "What is the term of x^4 in the expansion of (x + 2)^5?",
    answerOptions: [
      { answer: "80", isCorrect: true },
      { answer: "60", isCorrect: false },
      { answer: "40", isCorrect: false },
      { answer: "120", isCorrect: false },
    ],
    correctAnswer: "80",
    difficulty: "Medium",
    category: "Binomial Expansion",
  },
  {
    question: "How many ways can 4 people be arranged in a line?",
    answerOptions: [
      { answer: "24", isCorrect: true },
      { answer: "12", isCorrect: false },
      { answer: "8", isCorrect: false },
      { answer: "16", isCorrect: false },
    ],
    correctAnswer: "24",
    difficulty: "Easy",
    category: "Permutations",
  },
  {
    question: "What is the sum of the first 5 terms of the Fibonacci sequence?",
    answerOptions: [
      { answer: "15", isCorrect: false },
      { answer: "10", isCorrect: false },
      { answer: "20", isCorrect: false },
      { answer: "12", isCorrect: true },
    ],
    correctAnswer: "12",
    difficulty: "Easy",
    category: "Fibonacci Sequence",
  },
  {
    question: "If A = [[2, 1, 1], [1, 2, 1], [1, 1, 2]], find A^-1.",
    answerOptions: [
      {
        answer: "[[3/4, -1/4, -1/4], [-1/4, 3/4, -1/4], [-1/4, -1/4, 3/4]]",
        isCorrect: true,
      },
      {
        answer: "[[1/2, 1/2, 1/2], [1/2, 1/2, 1/2], [1/2, 1/2, 1/2]]",
        isCorrect: false,
      },
      {
        answer: "[[1/3, 1/3, 1/3], [1/3, 1/3, 1/3], [1/3, 1/3, 1/3]]",
        isCorrect: false,
      },
      {
        answer: "[[1/4, 1/4, 1/4], [1/4, 1/4, 1/4], [1/4, 1/4, 1/4]]",
        isCorrect: false,
      },
    ],
    correctAnswer: "[[3/4, -1/4, -1/4], [-1/4, 3/4, -1/4], [-1/4, -1/4, 3/4]]",
    difficulty: "Hard",
    category: "Matrix Inversion",
  },
  {
    question: "What is the coefficient of x^3 in the expansion of (3x - 2)^4?",
    answerOptions: [
      { answer: "108", isCorrect: true },
      { answer: "144", isCorrect: false },
      { answer: "72", isCorrect: false },
      { answer: "96", isCorrect: false },
    ],
    correctAnswer: "108",
    difficulty: "Medium",
    category: "Binomial Expansion",
  },
  {
    question: "Find the sum of the series 1 + 2 + 4 + 8 + ... + 2^9.",
    answerOptions: [
      { answer: "2046", isCorrect: true },
      { answer: "1024", isCorrect: false },
      { answer: "1023", isCorrect: false },
      { answer: "2048", isCorrect: false },
    ],
    correctAnswer: "2046",
    difficulty: "Medium",
    category: "Geometric Series",
  },
  {
    question: "Find the determinant of [[1, 1, 1], [1, 2, 3], [1, 3, 5]].",
    answerOptions: [
      { answer: "-2", isCorrect: false },
      { answer: "1", isCorrect: false },
      { answer: "2", isCorrect: true },
      { answer: "-1", isCorrect: false },
    ],
    correctAnswer: "2",
    difficulty: "Hard",
    category: "Matrix Determinants",
  },

  {
    question:
      "Find the number of combinations of 4 items taken from a set of 8 items.",
    answerOptions: [
      { answer: "70", isCorrect: true },
      { answer: "56", isCorrect: false },
      { answer: "84", isCorrect: false },
      { answer: "36", isCorrect: false },
    ],
    correctAnswer: "70",
    difficulty: "Medium",
    category: "Combinations",
  },
  {
    question:
      "Using mathematical induction, prove that for all integers n ≥ 1, the inequality 3^n ≥ 2^n + n^2 holds.",
    answerOptions: [
      { answer: "True", isCorrect: true },
      { answer: "False", isCorrect: false },
    ],
    correctAnswer: "True",
    difficulty: "Hard",
    category: "Mathematical Induction",
  },
  {
    question: "Find the complex conjugate of 2 - 5i.",
    answerOptions: [
      { answer: "2 + 5i", isCorrect: true },
      { answer: "2 - 5i", isCorrect: false },
      { answer: "-2 + 5i", isCorrect: false },
      { answer: "-2 - 5i", isCorrect: false },
    ],
    correctAnswer: "2 + 5i",
    difficulty: "Easy",
    category: "Complex Numbers",
  },
  {
    question: "Solve for x: x^2 - 7x + 12 = 0.",
    answerOptions: [
      { answer: "x = 3 or x = 4", isCorrect: true },
      { answer: "x = 1 or x = 2", isCorrect: false },
      { answer: "x = -1 or x = -2", isCorrect: false },
      { answer: "x = -3 or x = -4", isCorrect: false },
    ],
    correctAnswer: "x = 3 or x = 4",
    difficulty: "Easy",
    category: "Quadratic Equations",
  },
  {
    question: "Solve for x: 2x + 5 = 11.",
    answerOptions: [
      { answer: "x = 3", isCorrect: true },
      { answer: "x = 2", isCorrect: false },
      { answer: "x = 1", isCorrect: false },
      { answer: "x = -1", isCorrect: false },
    ],
    correctAnswer: "x = 3",
    difficulty: "Easy",
    category: "Linear Equations",
  },
  {
    question: "Find the coefficient of x^3 in (x + 2)^5.",
    answerOptions: [
      { answer: "40", isCorrect: true },
      { answer: "20", isCorrect: false },
      { answer: "30", isCorrect: false },
      { answer: "50", isCorrect: false },
    ],
    correctAnswer: "40",
    difficulty: "Medium",
    category: "Binomial Expansion",
  },
  {
    question: "Find the number of ways to arrange 4 items from 5 items.",
    answerOptions: [
      { answer: "60", isCorrect: false },
      { answer: "90", isCorrect: false },
      { answer: "100", isCorrect: false },
      { answer: "120", isCorrect: true },
    ],
    correctAnswer: "120",
    difficulty: "Medium",
    category: "Permutations",
  },
  {
    question:
      "Find the sum of the first 8 terms of the arithmetic series: 3 + 6 + 9 + ...",
    answerOptions: [
      { answer: "120", isCorrect: false },
      { answer: "144", isCorrect: false },
      { answer: "156", isCorrect: true },
      { answer: "168", isCorrect: false },
    ],
    correctAnswer: "156",
    difficulty: "Medium",
    category: "Arithmetic Series",
  },
  {
    question: "Prove that 3^n - 1 is divisible by 2 for all natural numbers n.",
    answerOptions: [
      {
        answer:
          "Base case: 3^1 - 1 = 2, Inductive step: assume true for n=k, prove for n=k+1",
        isCorrect: true,
      },
      {
        answer:
          "Base case: 3^2 - 1 = 8, Inductive step: assume true for n=k, prove for n=k+1",
        isCorrect: false,
      },
      {
        answer:
          "Base case: 3^3 - 1 = 26, Inductive step: assume true for n=k, prove for n=k+1",
        isCorrect: false,
      },
      {
        answer:
          "Base case: 3^4 - 1 = 80, Inductive step: assume true for n=k, prove for n=k+1",
        isCorrect: false,
      },
    ],
    correctAnswer:
      "Base case: 3^1 - 1 = 2, Inductive step: assume true for n=k, prove for n=k+1",
    difficulty: "Hard",
    category: "Mathematical Induction",
  },
  {
    question:
      "If α and β are the roots of the quadratic equation x^2 - 6x + 9 = 0, find the value of α^3 + β^3.",
    answerOptions: [
      { answer: "27", isCorrect: true },
      { answer: "18", isCorrect: false },
      { answer: "9", isCorrect: false },
      { answer: "54", isCorrect: false },
    ],
    correctAnswer: "27",
    difficulty: "Medium",
    category: "Quadratic Equations",
  },

  {
    question: "Find the sum of all complex roots of the equation z^4 - 16 = 0.",
    answerOptions: [
      { answer: "4", isCorrect: false },
      { answer: "-4", isCorrect: false },
      { answer: "8", isCorrect: false },
      { answer: "0", isCorrect: true },
    ],
    correctAnswer: "0",
    difficulty: "Medium",
    category: "Complex Numbers",
  },
  {
    question: "If A = [[1, 2], [3, 4]], find |A|.",
    answerOptions: [
      { answer: "-2", isCorrect: true },
      { answer: "2", isCorrect: false },
      { answer: "1", isCorrect: false },
      { answer: "-1", isCorrect: false },
    ],
    correctAnswer: "-2",
    difficulty: "Medium",
    category: "Determinants",
  },
  {
    question: "If x^2 - 4x + 3 = 0 and y^2 - 2y + 1 = 0, find x^2 + y^2.",
    answerOptions: [
      { answer: "7", isCorrect: true },
      { answer: "5", isCorrect: false },
      { answer: "4", isCorrect: false },
      { answer: "6", isCorrect: false },
    ],
    correctAnswer: "7",
    difficulty: "Medium",
    category: "Quadratic Equations",
  },
  {
    question:
      "In how many ways can 3 distinct objects be arranged in 5 distinct boxes?",
    answerOptions: [
      { answer: "125", isCorrect: false },
      { answer: "60", isCorrect: false },
      { answer: "300", isCorrect: true },
      { answer: "90", isCorrect: false },
    ],
    correctAnswer: "300",
    difficulty: "Medium",
    category: "Permutations",
  },
  {
    question: "Find the coefficient of x^3 in the expansion of (2x + 3)^4.",
    answerOptions: [
      { answer: "72", isCorrect: true },
      { answer: "54", isCorrect: false },
      { answer: "108", isCorrect: false },
      { answer: "96", isCorrect: false },
    ],
    correctAnswer: "72",
    difficulty: "Medium",
    category: "Binomial Expansion",
  },
  {
    question: "Find the value of the sum from k = 1 to 10 of (2k - 1).",
    answerOptions: [
      { answer: "110", isCorrect: false },
      { answer: "90", isCorrect: false },
      { answer: "100", isCorrect: true },
      { answer: "80", isCorrect: false },
    ],
    correctAnswer: "100",
    difficulty: "Medium",
    category: "Series",
  },
  {
    question:
      "The sum of the roots of the cubic equation x^3 - 6x^2 + 11x - 6 = 0 is:",
    answerOptions: [
      { answer: "6", isCorrect: true },
      { answer: "11", isCorrect: false },
      { answer: "3", isCorrect: false },
      { answer: "0", isCorrect: false },
    ],
    correctAnswer: "6",
    difficulty: "Medium",
    category: "Cubic Equations",
  },
  {
    question:
      "If the function f(x) = x^2 - 4x + 5 is transformed by a shift of 2 units to the left, what is the new function?",
    answerOptions: [
      { answer: "f(x) = (x + 2)^2 - 4(x + 2) + 5", isCorrect: false },
      { answer: "f(x) = (x - 2)^2 - 4(x - 2) + 5", isCorrect: true },
      { answer: "f(x) = (x + 2)^2 - 4(x - 2) + 5", isCorrect: false },
      { answer: "f(x) = (x - 2)^2 - 4(x + 2) + 5", isCorrect: false },
    ],
    correctAnswer: "f(x) = (x - 2)^2 - 4(x - 2) + 5",
    difficulty: "Medium",
    category: "Quadratic Equation",
  },

  {
    question: "In the geometric progression 3, 6, 12, ..., find the 7th term.",
    answerOptions: [
      { answer: "252", isCorrect: false },
      { answer: "288", isCorrect: false },
      { answer: "384", isCorrect: true },
      { answer: "256", isCorrect: false },
    ],
    correctAnswer: "384",
    difficulty: "Medium",
    category: "Geometric Progression",
  },
  {
    question:
      "If A is a 3x3 matrix such that A^2 = I, where I is the identity matrix, which of the following statements is true?",
    answerOptions: [
      { answer: "All eigenvalues of A are ±1", isCorrect: true },
      { answer: "A is diagonalizable", isCorrect: false },
      { answer: "A must be singular", isCorrect: false },
      { answer: "A is an orthogonal matrix", isCorrect: false },
    ],
    correctAnswer: "All eigenvalues of A are ±1",
    difficulty: "Hard",
    category: "Matrix Theory",
  },
  {
    question:
      "Find the number of real roots of the polynomial P(x) = x^4 - 4x^3 + 6x^2 - 4x + 1.",
    answerOptions: [
      { answer: "1", isCorrect: false },
      { answer: "2", isCorrect: false },
      { answer: "3", isCorrect: false },
      { answer: "4", isCorrect: true },
    ],
    correctAnswer: "4",
    difficulty: "Medium",
    category: "Polynomial Equations",
  },

  {
    question:
      "Find the determinant of the matrix [1 2 3 4; 5 6 7 8; 9 10 11 12; 13 14 15 16].",
    answerOptions: [
      { answer: "0", isCorrect: true },
      { answer: "1", isCorrect: false },
      { answer: "-1", isCorrect: false },
      { answer: "2", isCorrect: false },
    ],
    correctAnswer: "0",
    difficulty: "Hard",
    category: "Matrix Theory",
  },
  {
    question:
      "Find the value of the sum from k=1 to 10 of k^2 / (k^2 + k + 1).",
    answerOptions: [
      { answer: "5", isCorrect: true },
      { answer: "6", isCorrect: false },
      { answer: "4", isCorrect: false },
      { answer: "7", isCorrect: false },
    ],
    correctAnswer: "5",
    difficulty: "Hard",
    category: "Series",
  },
  {
    question:
      "Determine the number of real solutions to the equation x^3 - 3x^2 + 4x - 2 = 0.",
    answerOptions: [
      { answer: "3", isCorrect: true },
      { answer: "2", isCorrect: false },
      { answer: "1", isCorrect: false },
      { answer: "0", isCorrect: false },
    ],
    correctAnswer: "3",
    difficulty: "Medium",
    category: "Polynomial Equations",
  },
  {
    question:
      "Find the value of the integral from 0 to infinity of 1 / (x^2 + 4x + 5) dx.",
    answerOptions: [
      { answer: "π/2", isCorrect: false },
      { answer: "π/8", isCorrect: false },
      { answer: "π/4", isCorrect: true },
      { answer: "π/3", isCorrect: false },
    ],
    correctAnswer: "π/4",
    difficulty: "Medium",
    category: "Integration",
  },
  {
    question:
      "If A is a 2x2 matrix such that A^2 = 2A + I, where I is the identity matrix, find the trace of A.",
    answerOptions: [
      { answer: "2", isCorrect: true },
      { answer: "1", isCorrect: false },
      { answer: "0", isCorrect: false },
      { answer: "3", isCorrect: false },
    ],
    correctAnswer: "2",
    difficulty: "Medium",
    category: "Matrix Theory",
  },
  {
    question:
      "Find the maximum value of f(x) = x^2 - 2x + 5 for x in the real numbers.",
    answerOptions: [
      { answer: "5", isCorrect: true },
      { answer: "4", isCorrect: false },
      { answer: "6", isCorrect: false },
      { answer: "7", isCorrect: false },
    ],
    correctAnswer: "5",
    difficulty: "Medium",
    category: "Optimization",
  },
  {
    question: "The roots of the equation x^4 + 4x^3 - 2x^2 - 8x - 4 = 0 are:",
    answerOptions: [
      { answer: "±1, ±2", isCorrect: true },
      { answer: "±2, ±1", isCorrect: false },
      { answer: "±1, ±4", isCorrect: false },
      { answer: "±2, ±3", isCorrect: false },
    ],
    correctAnswer: "±1, ±2",
    difficulty: "Hard",
    category: "Polynomial Equations",
  },
  {
    question:
      "Evaluate the limit of sqrt(x^2 + 2x + 3) / (x + 1) as x approaches infinity.",
    answerOptions: [
      { answer: "1", isCorrect: true },
      { answer: "2", isCorrect: false },
      { answer: "0", isCorrect: false },
      { answer: "∞", isCorrect: false },
    ],
    correctAnswer: "1",
    difficulty: "Medium",
    category: "Limits",
  },
  {
    question:
      "If z is a complex number such that |z - 1| = 2 and |z + 2i| = 3, then the locus of z is:",
    answerOptions: [
      { answer: "A circle", isCorrect: true },
      { answer: "A straight line", isCorrect: false },
      { answer: "A parabola", isCorrect: false },
      { answer: "An ellipse", isCorrect: false },
    ],
    correctAnswer: "A circle",
    difficulty: "Medium",
    category: "Complex Numbers",
  },
  {
    question:
      "Find the number of positive integers n for which 1 + 1/2 + 1/3 + ... + 1/n is an integer.",
    answerOptions: [
      { answer: "2", isCorrect: false },
      { answer: "3", isCorrect: false },
      { answer: "1", isCorrect: true },
      { answer: "4", isCorrect: false },
    ],
    correctAnswer: "1",
    difficulty: "Medium",
    category: "Number Theory",
  },

  {
    question:
      "If A = [[1, 2, 3], [4, 5, 6], [7, 8, 9]], find A^3 - 3A^2 + 2A - I",
    answerOptions: [
      { answer: "[[0, 0, 0], [0, 0, 0], [0, 0, 0]]", isCorrect: true },
      { answer: "[[1, 1, 1], [1, 1, 1], [1, 1, 1]]", isCorrect: false },
      { answer: "[[2, 2, 2], [2, 2, 2], [2, 2, 2]]", isCorrect: false },
      { answer: "[[3, 3, 3], [3, 3, 3], [3, 3, 3]]", isCorrect: false },
    ],
    correctAnswer: "[[0, 0, 0], [0, 0, 0], [0, 0, 0]]",
    difficulty: "Hard",
    category: "Matrix Theory",
  },

  {
    question:
      "If α and β are the roots of the equation x^2 - 3x + 2 = 0, find α³ + β³",
    answerOptions: [
      { answer: "35", isCorrect: true },
      { answer: "24", isCorrect: false },
      { answer: "28", isCorrect: false },
      { answer: "30", isCorrect: false },
    ],
    correctAnswer: "35",
    difficulty: "medium",
    category: "Algebra",
  },
  {
    question:
      "If ( A ) is a ( 3 \\times 3 ) matrix such that ( A^2 = 2A + I ), where ( I ) is the identity matrix, find the determinant of ( A ).",
    answerOptions: [
      { answer: "2", isCorrect: false },
      { answer: "0", isCorrect: false },
      { answer: "1", isCorrect: true },
      { answer: "-1", isCorrect: false },
    ],
    correctAnswer: "1",
    difficulty: "hard",
    category: "Matrix Algebra",
  },
  {
    question:
      "Let A and B be 2 × 2 matrices such that AB = I. If A = [[1, 2], [3, 4]], find B.",
    answerOptions: [
      { answer: "[[-2, 1], [3/2, -1/2]]", isCorrect: true },
      { answer: "[[-1, 2], [3/2, -1/2]]", isCorrect: false },
      { answer: "[[1, -2], [-3, 4]]", isCorrect: false },
      { answer: "[[2, -1], [-3/2, 1]]", isCorrect: false },
    ],
    correctAnswer: "[[-2, 1], [3/2, -1/2]]",
    difficulty: "Hard",
    category: "Matrix Algebra",
  },
  {
    question:
      "Given the matrix A = [[1, 2, 3], [4, 5, 6], [7, 8, 9]], find the rank of A.",
    answerOptions: [
      { answer: "2", isCorrect: true },
      { answer: "3", isCorrect: false },
      { answer: "1", isCorrect: false },
      { answer: "0", isCorrect: false },
    ],
    correctAnswer: "2",
    difficulty: "Medium",
    category: "Matrix Theory",
  },
  {
    question: "Find the roots of the quadratic equation x^2 + 2√2x + 2 = 0.",
    answerOptions: [
      { answer: "-√2 ± i", isCorrect: true },
      { answer: "-√2 ± √2i", isCorrect: false },
      { answer: "0 ± √2i", isCorrect: false },
      { answer: "√2 ± i", isCorrect: false },
    ],
    correctAnswer: "-√2 ± i",
    difficulty: "Medium",
    category: "Quadratic Equations",
  },
  {
    question:
      "For the quadratic equation x^2 + (2m - 1)x + m^2 - m = 0, the roots are real and distinct if:",
    answerOptions: [
      { answer: "m < 1", isCorrect: false },
      { answer: "m = 1", isCorrect: false },
      { answer: "m > 1", isCorrect: true },
      { answer: "m ≥ 1", isCorrect: false },
    ],
    correctAnswer: "m > 1",
    difficulty: "Medium",
    category: "Quadratic Equations",
  },
  {
    question: "Find the inverse of the matrix A = [[4, 2], [3, 1]].",
    answerOptions: [
      { answer: "[[1, -2], [-3, 4]]", isCorrect: true },
      { answer: "[[1, -2], [-1, 2]]", isCorrect: false },
      { answer: "[[2, -1], [-3, 4]]", isCorrect: false },
      { answer: "[[4, -2], [-3, 1]]", isCorrect: false },
    ],
    correctAnswer: "[[1, -2], [-3, 4]]",
    difficulty: "Hard",
    category: "Matrix Algebra",
  },
  {
    question:
      "Determine the value of the sum from k=0 to n of (n choose k) · 2^(n-k).",
    answerOptions: [
      { answer: "3^n", isCorrect: true },
      { answer: "2^n", isCorrect: false },
      { answer: "n^2", isCorrect: false },
      { answer: "4^n", isCorrect: false },
    ],
    correctAnswer: "3^n",
    difficulty: "Medium",
    category: "Combinatorics",
  },

  {
    question: "Find the eigenvalues of the matrix A = [[2, 1], [1, 2]].",
    answerOptions: [
      { answer: "3, 1", isCorrect: true },
      { answer: "2, 2", isCorrect: false },
      { answer: "1, 1", isCorrect: false },
      { answer: "2, 0", isCorrect: false },
    ],
    correctAnswer: "3, 1",
    difficulty: "medium",
    category: "linear algebra",
  },
  {
    question: "Solve for z if z^2 = -1 - 2i.",
    answerOptions: [
      { answer: "±(1 - i)", isCorrect: true },
      { answer: "±(1 + i)", isCorrect: false },
      { answer: "±(-1 - i)", isCorrect: false },
      { answer: "±(1 + 2i)", isCorrect: false },
    ],
    correctAnswer: "±(1 - i)",
    difficulty: "hard",
    category: "complex numbers",
  },
  {
    question:
      "For the binomial expansion of (x + 2y)^6, find the coefficient of x^3 y^3.",
    answerOptions: [
      { answer: "720", isCorrect: true },
      { answer: "600", isCorrect: false },
      { answer: "540", isCorrect: false },
      { answer: "360", isCorrect: false },
    ],
    correctAnswer: "720",
    difficulty: "medium",
    category: "algebra",
  },
  {
    question:
      "If A and B are 2 × 2 matrices such that AB = BA and tr(A) = 5 and tr(B) = 7, find tr(AB).",
    answerOptions: [
      { answer: "12", isCorrect: false },
      { answer: "35", isCorrect: true },
      { answer: "42", isCorrect: false },
      { answer: "25", isCorrect: false },
    ],
    correctAnswer: "35",
    difficulty: "medium",
    category: "linear algebra",
  },
  {
    question: "Find the determinant of the matrix A = [[2, -1], [-1, 2]].",
    answerOptions: [
      { answer: "4", isCorrect: false },
      { answer: "2", isCorrect: false },
      { answer: "1", isCorrect: false },
      { answer: "3", isCorrect: true },
    ],
    correctAnswer: "3",
    difficulty: "medium",
    category: "linear algebra",
  },
  {
    question: "For the complex number z = 2 + 3i, find |z^2 - 1|.",
    answerOptions: [
      { answer: "13", isCorrect: true },
      { answer: "5", isCorrect: false },
      { answer: "10", isCorrect: false },
      { answer: "8", isCorrect: false },
    ],
    correctAnswer: "13",
    difficulty: "hard",
    category: "complex numbers",
  },
  {
    question: "Find the value of det([[1, 2], [3, 4]]).",
    answerOptions: [
      { answer: "-2", isCorrect: true },
      { answer: "2", isCorrect: false },
      { answer: "1", isCorrect: false },
      { answer: "-1", isCorrect: false },
    ],
    correctAnswer: "-2",
    difficulty: "medium",
    category: "linear algebra",
  },
  {
    question: "Solve the quadratic equation x^2 - (2 + sqrt(3))x + 1 = 0.",
    answerOptions: [
      { answer: "1, 1 + sqrt(3)", isCorrect: true },
      { answer: "1, 1 - sqrt(3)", isCorrect: false },
      { answer: "1 + sqrt(3), 1 - sqrt(3)", isCorrect: false },
      { answer: "2, -1", isCorrect: false },
    ],
    correctAnswer: "1, 1 + sqrt(3)",
    difficulty: "medium",
    category: "algebra",
  },
  {
    question: "If z = 1 + i and w = 2 - 3i, find z * w.",
    answerOptions: [
      { answer: "5 - i", isCorrect: true },
      { answer: "5 + i", isCorrect: false },
      { answer: "-5 + i", isCorrect: false },
      { answer: "-5 - i", isCorrect: false },
    ],
    correctAnswer: "5 - i",
    difficulty: "hard",
    category: "complex numbers",
  },
  {
    question:
      "For the binomial expansion of (x - 3y)^5, find the coefficient of x^2 y^3.",
    answerOptions: [
      { answer: "-540", isCorrect: true },
      { answer: "540", isCorrect: false },
      { answer: "-432", isCorrect: false },
      { answer: "432", isCorrect: false },
    ],
    correctAnswer: "-540",
    difficulty: "medium",
    category: "algebra",
  },
  {
    question: "Find the inverse of the matrix B = [[0, 1], [1, 0]].",
    answerOptions: [
      { answer: "[[0, 1], [1, 0]]", isCorrect: true },
      { answer: "[[1, 0], [0, 1]]", isCorrect: false },
      { answer: "[[1, -1], [-1, 1]]", isCorrect: false },
      { answer: "[[0, -1], [-1, 0]]", isCorrect: false },
    ],
    correctAnswer: "[[0, 1], [1, 0]]",
    difficulty: "easy",
    category: "linear algebra",
  },
  {
    question: "Solve for x if sqrt(x + 3) + sqrt(2x - 1) = 3.",
    answerOptions: [
      { answer: "2", isCorrect: true },
      { answer: "1", isCorrect: false },
      { answer: "3", isCorrect: false },
      { answer: "0", isCorrect: false },
    ],
    correctAnswer: "2",
    difficulty: "medium",
    category: "algebra",
  },
  {
    question:
      "Evaluate the determinant of the matrix [[2, 3, 1], [4, 1, 2], [3, 2, 1]].",
    answerOptions: [
      { answer: "-1", isCorrect: true },
      { answer: "1", isCorrect: false },
      { answer: "0", isCorrect: false },
      { answer: "2", isCorrect: false },
    ],
    correctAnswer: "-1",
    difficulty: "medium",
    category: "linear algebra",
  },
  {
    question:
      "If alpha and beta are roots of the equation x^2 - 3x + 2 = 0, find alpha^2 + beta^2.",
    answerOptions: [
      { answer: "4", isCorrect: false },
      { answer: "6", isCorrect: false },
      { answer: "7", isCorrect: false },
      { answer: "5", isCorrect: true },
    ],
    correctAnswer: "5",
    difficulty: "medium",
    category: "algebra",
  },
  {
    question: "Find the coefficient of x^4 in the expansion of (2x + 3)^5.",
    answerOptions: [
      { answer: "360", isCorrect: false },
      { answer: "240", isCorrect: true },
      { answer: "150", isCorrect: false },
      { answer: "120", isCorrect: false },
    ],
    correctAnswer: "240",
    difficulty: "medium",
    category: "algebra",
  },
  {
    question: "If z = 2 - i, find |z|^2.",
    answerOptions: [
      { answer: "5", isCorrect: true },
      { answer: "4", isCorrect: false },
      { answer: "8", isCorrect: false },
      { answer: "10", isCorrect: false },
    ],
    correctAnswer: "5",
    difficulty: "easy",
    category: "complex numbers",
  },
  {
    question:
      "Find the solution to the matrix equation Ax = b where A = [[1, 2], [3, 4]] and b = [5, 6].",
    answerOptions: [
      { answer: "[-1, 3]", isCorrect: true },
      { answer: "[1, 2]", isCorrect: false },
      { answer: "[2, 1]", isCorrect: false },
      { answer: "[0, 1]", isCorrect: false },
    ],
    correctAnswer: "[-1, 3]",
    difficulty: "medium",
    category: "linear algebra",
  },
  {
    question: "Find the eigenvalues of the matrix A = [[5, 4], [2, 3]].",
    answerOptions: [
      { answer: "7, 1", isCorrect: true },
      { answer: "6, 2", isCorrect: false },
      { answer: "5, 3", isCorrect: false },
      { answer: "4, 4", isCorrect: false },
    ],
    correctAnswer: "7, 1",
    difficulty: "medium",
    category: "linear algebra",
  },
  {
    question: "Solve the quadratic equation 2x^2 - 3x - 2 = 0.",
    answerOptions: [
      { answer: "x = 1, -1", isCorrect: true },
      { answer: "x = 1, -2", isCorrect: false },
      { answer: "x = 1/2, -2", isCorrect: false },
      { answer: "x = -1/2, 2", isCorrect: false },
    ],
    correctAnswer: "x = 1, -1",
    difficulty: "medium",
    category: "algebra",
  },
  {
    question: "If z = 3 + 4i, find z̅ * z.",
    answerOptions: [
      { answer: "25", isCorrect: true },
      { answer: "12", isCorrect: false },
      { answer: "7", isCorrect: false },
      { answer: "13", isCorrect: false },
    ],
    correctAnswer: "25",
    difficulty: "medium",
    category: "complex numbers",
  },
  {
    question:
      "Find the coefficient of x^3 y^2 in the expansion of (2x - 3y)^5.",
    answerOptions: [
      { answer: "-240", isCorrect: true },
      { answer: "240", isCorrect: false },
      { answer: "-180", isCorrect: false },
      { answer: "180", isCorrect: false },
    ],
    correctAnswer: "-240",
    difficulty: "medium",
    category: "algebra",
  },
  {
    question:
      "For the matrix A = [[1, 0, 0], [0, 2, 0], [0, 0, 3]], find tr(A^2), where tr denotes the trace of the matrix.",
    answerOptions: [
      { answer: "14", isCorrect: true },
      { answer: "6", isCorrect: false },
      { answer: "12", isCorrect: false },
      { answer: "9", isCorrect: false },
    ],
    correctAnswer: "14",
    difficulty: "medium",
    category: "linear algebra",
  },

  {
    question:
      "Solve the quadratic equation 3x^2 - 12x + 12 = 0 using the quadratic formula.",
    answerOptions: [
      { answer: "1, 3", isCorrect: false },
      { answer: "1, 2", isCorrect: false },
      { answer: "2, 2", isCorrect: true },
      { answer: "2, 3", isCorrect: false },
    ],
    correctAnswer: "2, 2",
    difficulty: "medium",
    category: "algebra",
  },
  {
    question: "If A = [[2, 1], [1, 2]] and B = [[0, -1], [1, 0]], find AB.",
    answerOptions: [
      { answer: "[[-1, -2], [2, -1]]", isCorrect: true },
      { answer: "[[1, -2], [2, 1]]", isCorrect: false },
      { answer: "[[-1, 1], [-2, -1]]", isCorrect: false },
      { answer: "[[-2, -1], [1, -2]]", isCorrect: false },
    ],
    correctAnswer: "[[-1, -2], [2, -1]]",
    difficulty: "medium",
    category: "matrix algebra",
  },
  {
    question:
      "Find the sum of the roots of the quadratic equation 2x^2 - 3x + 4 = 0.",
    answerOptions: [
      { answer: "1.5", isCorrect: true },
      { answer: "0.67", isCorrect: false },
      { answer: "1.33", isCorrect: false },
      { answer: "1.67", isCorrect: false },
    ],
    correctAnswer: "1.5",
    difficulty: "medium",
    category: "algebra",
  },
  {
    question: "For the complex number z = e^(iπ/4), find z^2.",
    answerOptions: [
      { answer: "e^(iπ/2)", isCorrect: true },
      { answer: "e^(iπ)", isCorrect: false },
      { answer: "e^(iπ/8)", isCorrect: false },
      { answer: "e^(iπ/4)", isCorrect: false },
    ],
    correctAnswer: "e^(iπ/2)",
    difficulty: "medium",
    category: "complex numbers",
  },
  {
    question: "Find the coefficient of x^3 y^2 in the expansion of (x + 2y)^5.",
    answerOptions: [
      { answer: "80", isCorrect: true },
      { answer: "60", isCorrect: false },
      { answer: "120", isCorrect: false },
      { answer: "100", isCorrect: false },
    ],
    correctAnswer: "80",
    difficulty: "medium",
    category: "algebra",
  },
  {
    question: "If A is a 2x2 matrix with eigenvalues 1 and -1, find tr(A^2).",
    answerOptions: [
      { answer: "2", isCorrect: true },
      { answer: "1", isCorrect: false },
      { answer: "0", isCorrect: false },
      { answer: "-2", isCorrect: false },
    ],
    correctAnswer: "2",
    difficulty: "medium",
    category: "matrix algebra",
  },
  {
    question: "Find the roots of the quadratic equation x^2 + 4x + 4 = 0.",
    answerOptions: [
      { answer: "-2, -2", isCorrect: true },
      { answer: "2, -2", isCorrect: false },
      { answer: "0, -4", isCorrect: false },
      { answer: "1, -3", isCorrect: false },
    ],
    correctAnswer: "-2, -2",
    difficulty: "medium",
    category: "algebra",
  },
  {
    question: "If z = 2i, find z^3.",
    answerOptions: [
      { answer: "-8i", isCorrect: true },
      { answer: "8i", isCorrect: false },
      { answer: "-8", isCorrect: false },
      { answer: "8", isCorrect: false },
    ],
    correctAnswer: "-8i",
    difficulty: "medium",
    category: "complex numbers",
  },
  {
    question: "Find the coefficient of x^2 y^2 in the expansion of (3x - y)^4.",
    answerOptions: [
      { answer: "108", isCorrect: true },
      { answer: "81", isCorrect: false },
      { answer: "72", isCorrect: false },
      { answer: "90", isCorrect: false },
    ],
    correctAnswer: "108",
    difficulty: "medium",
    category: "algebra",
  },
  {
    question:
      "If A is a 2x2 matrix with A^2 = I, where I is the identity matrix, then A must be:",
    answerOptions: [
      { answer: "Diagonalizable", isCorrect: true },
      { answer: "Nilpotent", isCorrect: false },
      { answer: "Singular", isCorrect: false },
      { answer: "Invertible", isCorrect: false },
    ],
    correctAnswer: "Diagonalizable",
    difficulty: "medium",
    category: "matrix algebra",
  },
  {
    question: "Solve for x if x^2 - 5x + 6 = 0.",
    answerOptions: [
      { answer: "2, 3", isCorrect: true },
      { answer: "1, 6", isCorrect: false },
      { answer: "3, 4", isCorrect: false },
      { answer: "2, 4", isCorrect: false },
    ],
    correctAnswer: "2, 3",
    difficulty: "medium",
    category: "algebra",
  },
  {
    question: "For the complex number z = 1 - i, find 1/z.",
    answerOptions: [
      { answer: "(1 + i) / 2", isCorrect: true },
      { answer: "(1 - i) / 2", isCorrect: false },
      { answer: "(1 - i) / 2i", isCorrect: false },
      { answer: "(1 + i) / i", isCorrect: false },
    ],
    correctAnswer: "(1 + i) / 2",
    difficulty: "medium",
    category: "complex numbers",
  },
  {
    question: "Find the coefficient of x^2 in the expansion of (1 + x)^7.",
    answerOptions: [
      { answer: "21", isCorrect: true },
      { answer: "7", isCorrect: false },
      { answer: "35", isCorrect: false },
      { answer: "14", isCorrect: false },
    ],
    correctAnswer: "21",
    difficulty: "medium",
    category: "algebra",
  },
  {
    question: "Find the eigenvalues of the matrix A = [[2, 1], [1, 2]].",
    answerOptions: [
      { answer: "3, 1", isCorrect: true },
      { answer: "2, 2", isCorrect: false },
      { answer: "1, 1", isCorrect: false },
      { answer: "0, 3", isCorrect: false },
    ],
    correctAnswer: "3, 1",
    difficulty: "medium",
    category: "matrix algebra",
  },
  {
    question: "Solve the quadratic equation 2x^2 - 5x + 2 = 0.",
    answerOptions: [
      { answer: "1, 1", isCorrect: true },
      { answer: "0.5, 2", isCorrect: false },
      { answer: "2, 0.5", isCorrect: false },
      { answer: "2.5, 2", isCorrect: false },
    ],
    correctAnswer: "1, 1",
    difficulty: "medium",
    category: "algebra",
  },

  {
    question: "If z = 3 + 4i, find the conjugate of z multiplied by z squared.",
    answerOptions: [
      { answer: "25 + 24i", isCorrect: true },
      { answer: "25 - 24i", isCorrect: false },
      { answer: "7 + 24i", isCorrect: false },
      { answer: "7 - 24i", isCorrect: false },
    ],
    correctAnswer: "25 + 24i",
    difficulty: "medium",
    category: "complex numbers",
  },
  {
    question:
      "Determine the coefficient of x^3 y^2 in the expansion of (2x + y)^5.",
    answerOptions: [
      { answer: "80", isCorrect: true },
      { answer: "60", isCorrect: false },
      { answer: "120", isCorrect: false },
      { answer: "100", isCorrect: false },
    ],
    correctAnswer: "80",
    difficulty: "medium",
    category: "algebra",
  },
  {
    question:
      "Find the determinant of the matrix A = [[1, 3, 2], [2, 1, 4], [3, 2, 1]].",
    answerOptions: [
      { answer: "-12", isCorrect: true },
      { answer: "12", isCorrect: false },
      { answer: "0", isCorrect: false },
      { answer: "-6", isCorrect: false },
    ],
    correctAnswer: "-12",
    difficulty: "medium",
    category: "matrix algebra",
  },
  {
    question:
      "Solve the quadratic equation 3x^2 + 7x - 6 = 0 using the quadratic formula.",
    answerOptions: [
      { answer: "1, -2", isCorrect: true },
      { answer: "-1, 2", isCorrect: false },
      { answer: "1.5, -0.5", isCorrect: false },
      { answer: "2, -3", isCorrect: false },
    ],
    correctAnswer: "1, -2",
    difficulty: "medium",
    category: "algebra",
  },
  {
    question: "If A = [[2, 3], [5, 7]], find the inverse of A.",
    answerOptions: [
      { answer: "[[7, -3], [-5, 2]]", isCorrect: true },
      { answer: "[[7, 3], [5, 2]]", isCorrect: false },
      { answer: "[[2, -3], [-5, 7]]", isCorrect: false },
      { answer: "[[-7, 3], [5, -2]]", isCorrect: false },
    ],
    correctAnswer: "[[7, -3], [-5, 2]]",
    difficulty: "medium",
    category: "matrix algebra",
  },
  {
    question: "Find the value of 1 / [(1 + 2i)(3 - i)].",
    answerOptions: [
      { answer: "(3 + 2i) / 10", isCorrect: true },
      { answer: "(1 - 2i) / 10", isCorrect: false },
      { answer: "(1 + 2i) / 10", isCorrect: false },
      { answer: "(3 - 2i) / 10", isCorrect: false },
    ],
    correctAnswer: "(3 + 2i) / 10",
    difficulty: "medium",
    category: "complex numbers",
  },
  {
    question:
      "For the binomial expansion of (2x - y)^6, find the coefficient of x^3 y^3.",
    answerOptions: [
      { answer: "720", isCorrect: true },
      { answer: "540", isCorrect: false },
      { answer: "360", isCorrect: false },
      { answer: "600", isCorrect: false },
    ],
    correctAnswer: "720",
    difficulty: "medium",
    category: "algebra",
  },
  {
    question:
      "Find the rank of the matrix B = [[1, 2, 3], [4, 5, 6], [7, 8, 10]].",
    answerOptions: [
      { answer: "2", isCorrect: true },
      { answer: "3", isCorrect: false },
      { answer: "1", isCorrect: false },
      { answer: "0", isCorrect: false },
    ],
    correctAnswer: "2",
    difficulty: "medium",
    category: "matrix algebra",
  },
  {
    question: "Find the roots of the quadratic equation x^2 - 4x + 4 = 0.",
    answerOptions: [
      { answer: "2, 2", isCorrect: true },
      { answer: "1, 3", isCorrect: false },
      { answer: "0, 4", isCorrect: false },
      { answer: "1, 2", isCorrect: false },
    ],
    correctAnswer: "2, 2",
    difficulty: "medium",
    category: "algebra",
  },
  {
    question: "If z = cos(θ) + i sin(θ), find z^4.",
    answerOptions: [
      { answer: "cos(4θ) + i sin(4θ)", isCorrect: true },
      { answer: "cos(2θ) + i sin(2θ)", isCorrect: false },
      { answer: "cos(3θ) + i sin(3θ)", isCorrect: false },
      { answer: "cos(θ) + i sin(θ)", isCorrect: false },
    ],
    correctAnswer: "cos(4θ) + i sin(4θ)",
    difficulty: "medium",
    category: "complex numbers",
  },
  {
    question: "For the matrix C = [[0, 1], [-1, 0]], find C^3.",
    answerOptions: [
      { answer: "[[0, -1], [1, 0]]", isCorrect: true },
      { answer: "[[0, 1], [-1, 0]]", isCorrect: false },
      { answer: "[[-1, 0], [0, -1]]", isCorrect: false },
      { answer: "[[1, 0], [0, 1]]", isCorrect: false },
    ],
    correctAnswer: "[[0, -1], [1, 0]]",
    difficulty: "medium",
    category: "matrix algebra",
  },
  {
    question:
      "Find the sum of the roots of the quadratic equation x^2 + 6x + 9 = 0.",
    answerOptions: [
      { answer: "-6", isCorrect: true },
      { answer: "6", isCorrect: false },
      { answer: "3", isCorrect: false },
      { answer: "-3", isCorrect: false },
    ],
    correctAnswer: "-6",
    difficulty: "medium",
    category: "algebra",
  },

  {
    question:
      "For the binomial expansion of (x + 3)^4, find the coefficient of x^2.",
    answerOptions: [
      { answer: "108", isCorrect: true },
      { answer: "72", isCorrect: false },
      { answer: "54", isCorrect: false },
      { answer: "36", isCorrect: false },
    ],
    correctAnswer: "108",
    difficulty: "medium",
    category: "algebra",
  },
  {
    question: "If z = 1 - 2i, find the product of z and its conjugate.",
    answerOptions: [
      { answer: "5", isCorrect: true },
      { answer: "4", isCorrect: false },
      { answer: "3", isCorrect: false },
      { answer: "6", isCorrect: false },
    ],
    correctAnswer: "5",
    difficulty: "easy",
    category: "complex numbers",
  },
  {
    question: "Solve for x in the equation 2x - 7 = 3x + 4.",
    answerOptions: [
      { answer: "-11", isCorrect: true },
      { answer: "11", isCorrect: false },
      { answer: "-1", isCorrect: false },
      { answer: "1", isCorrect: false },
    ],
    correctAnswer: "-11",
    difficulty: "easy",
    category: "algebra",
  },
  {
    question: "If A = [[1, 2], [3, 4]], find the determinant of A.",
    answerOptions: [
      { answer: "-2", isCorrect: true },
      { answer: "2", isCorrect: false },
      { answer: "0", isCorrect: false },
      { answer: "8", isCorrect: false },
    ],
    correctAnswer: "-2",
    difficulty: "medium",
    category: "matrix algebra",
  },
  {
    question: "Find the roots of the quadratic equation x^2 - 4x - 5 = 0.",
    answerOptions: [
      { answer: "5, -1", isCorrect: true },
      { answer: "-5, 1", isCorrect: false },
      { answer: "1, -5", isCorrect: false },
      { answer: "2, -3", isCorrect: false },
    ],
    correctAnswer: "5, -1",
    difficulty: "medium",
    category: "algebra",
  },
  {
    question: "What is the coefficient of x^2 in the expansion of (2x - 3)^3?",
    answerOptions: [
      { answer: "-12", isCorrect: true },
      { answer: "12", isCorrect: false },
      { answer: "18", isCorrect: false },
      { answer: "-18", isCorrect: false },
    ],
    correctAnswer: "-12",
    difficulty: "medium",
    category: "algebra",
  },
  {
    question: "If z = 1 + i, find |z|^2, where |z| denotes the modulus of z.",
    answerOptions: [
      { answer: "2", isCorrect: true },
      { answer: "1", isCorrect: false },
      { answer: "0", isCorrect: false },
      { answer: "1 + i", isCorrect: false },
    ],
    correctAnswer: "2",
    difficulty: "easy",
    category: "complex numbers",
  },
  {
    question: "For the matrix B = [[3, 0], [0, -2]], find B^2.",
    answerOptions: [
      { answer: "[[9, 0], [0, 4]]", isCorrect: true },
      { answer: "[[9, 0], [0, -4]]", isCorrect: false },
      { answer: "[[6, 0], [0, -4]]", isCorrect: false },
      { answer: "[[3, 0], [0, 2]]", isCorrect: false },
    ],
    correctAnswer: "[[9, 0], [0, 4]]",
    difficulty: "medium",
    category: "matrix algebra",
  },
  {
    question:
      "If A = [[2, 3], [1, 2]], find A + A^(-1), where A^(-1) is the inverse of A.",
    answerOptions: [
      { answer: "[[4, 3], [1, 4]]", isCorrect: true },
      { answer: "[[1, 3], [1, 1]]", isCorrect: false },
      { answer: "[[2, 5], [1, 3]]", isCorrect: false },
      { answer: "[[4, 1], [2, 2]]", isCorrect: false },
    ],
    correctAnswer: "[[4, 3], [1, 4]]",
    difficulty: "medium",
    category: "matrix algebra",
  },
  {
    question:
      "Find the sum of the roots of the quadratic equation x^2 - 7x + 10 = 0.",
    answerOptions: [
      { answer: "7", isCorrect: true },
      { answer: "10", isCorrect: false },
      { answer: "3", isCorrect: false },
      { answer: "5", isCorrect: false },
    ],
    correctAnswer: "7",
    difficulty: "easy",
    category: "algebra",
  },
  {
    question: "If z = -1 + sqrt(3)i, find z^2.",
    answerOptions: [
      { answer: "-2 - 2sqrt(3)i", isCorrect: true },
      { answer: "2 - 2sqrt(3)i", isCorrect: false },
      { answer: "-2 + 2sqrt(3)i", isCorrect: false },
      { answer: "2 + 2sqrt(3)i", isCorrect: false },
    ],
    correctAnswer: "-2 - 2sqrt(3)i",
    difficulty: "medium",
    category: "complex numbers",
  },

  {
    question: "Find the coefficient of x^4 in the expansion of (x + 2)^5.",
    answerOptions: [
      { answer: "80", isCorrect: true },
      { answer: "60", isCorrect: false },
      { answer: "40", isCorrect: false },
      { answer: "100", isCorrect: false },
    ],
    correctAnswer: "80",
    difficulty: "medium",
    category: "algebra",
  },
  {
    question: "Solve for x in the equation (2x + 3) / 4 = (x - 1) / 2.",
    answerOptions: [
      { answer: "-1", isCorrect: true },
      { answer: "1", isCorrect: false },
      { answer: "0", isCorrect: false },
      { answer: "-2", isCorrect: false },
    ],
    correctAnswer: "-1",
    difficulty: "medium",
    category: "algebra",
  },
  {
    question:
      "Find the value of the determinant of the matrix [[1, 1], [1, 1]].",
    answerOptions: [
      { answer: "0", isCorrect: true },
      { answer: "1", isCorrect: false },
      { answer: "-1", isCorrect: false },
      { answer: "2", isCorrect: false },
    ],
    correctAnswer: "0",
    difficulty: "easy",
    category: "matrix algebra",
  },
  {
    question: "If z = 3 - 2i, find the reciprocal of z.",
    answerOptions: [
      { answer: "(3 + 2i) / 13", isCorrect: true },
      { answer: "(3 - 2i) / 13", isCorrect: false },
      { answer: "(3 + 2i) / 5", isCorrect: false },
      { answer: "(3 - 2i) / 5", isCorrect: false },
    ],
    correctAnswer: "(3 + 2i) / 13",
    difficulty: "medium",
    category: "complex numbers",
  },
  {
    question:
      "For the expansion of (2x - 3y)^4, find the coefficient of x^2 y^2.",
    answerOptions: [
      { answer: "-360", isCorrect: true },
      { answer: "360", isCorrect: false },
      { answer: "-540", isCorrect: false },
      { answer: "540", isCorrect: false },
    ],
    correctAnswer: "-360",
    difficulty: "medium",
    category: "algebra",
  },
  {
    question: "Find the inverse of the matrix [[4, 2], [1, 3]].",
    answerOptions: [
      { answer: "[[3/10, -1/10], [-1/10, 4/10]]", isCorrect: true },
      { answer: "[[4/10, -2/10], [-1/10, 3/10]]", isCorrect: false },
      { answer: "[[4/10, 2/10], [1/10, 3/10]]", isCorrect: false },
      { answer: "[[3/10, 1/10], [1/10, 4/10]]", isCorrect: false },
    ],
    correctAnswer: "[[3/10, -1/10], [-1/10, 4/10]]",
    difficulty: "medium",
    category: "matrix algebra",
  },
  {
    question:
      "Solve the quadratic equation 5x^2 + 6x - 1 = 0 using the quadratic formula.",
    answerOptions: [
      { answer: "(-6 ± sqrt(36 - 20)) / 10", isCorrect: false },
      { answer: "(-5 ± sqrt(25 - 20)) / 10", isCorrect: false },
      { answer: "(-6 ± sqrt(36 + 20)) / 10", isCorrect: true },
      { answer: "(-6 ± sqrt(36 - 25)) / 10", isCorrect: false },
    ],
    correctAnswer: "(-6 ± sqrt(36 + 20)) / 10",
    difficulty: "medium",
    category: "algebra",
  },
  {
    question: "Solve for x in the equation x^4 - 4x^3 + 6x^2 - 4x + 1 = 0.",
    answerOptions: [
      { answer: "x = 1", isCorrect: true },
      { answer: "x = -1", isCorrect: false },
      { answer: "x = 0", isCorrect: false },
      { answer: "x = 2", isCorrect: false },
    ],
    correctAnswer: "x = 1",
    difficulty: "medium",
    category: "algebra",
  },
  {
    question:
      "Find the sum of the roots of the equation 3x^3 - 5x^2 + 2x - 7 = 0.",
    answerOptions: [
      { answer: "5/3", isCorrect: true },
      { answer: "7/3", isCorrect: false },
      { answer: "1", isCorrect: false },
      { answer: "2", isCorrect: false },
    ],
    correctAnswer: "5/3",
    difficulty: "medium",
    category: "algebra",
  },

  {
    question:
      "Determine the roots of the quadratic equation 4x^2 - 12x + 9 = 0 using the quadratic formula.",
    answerOptions: [
      { answer: "x = 3/2", isCorrect: true },
      { answer: "x = 1/2", isCorrect: false },
      { answer: "x = 2", isCorrect: false },
      { answer: "x = 3", isCorrect: false },
    ],
    correctAnswer: "x = 3/2",
    difficulty: "medium",
    category: "algebra",
  },
  {
    question: "Solve for x if 2^x + 2^(x+1) = 48.",
    answerOptions: [
      { answer: "x = 4", isCorrect: true },
      { answer: "x = 3", isCorrect: false },
      { answer: "x = 5", isCorrect: false },
      { answer: "x = 6", isCorrect: false },
    ],
    correctAnswer: "x = 4",
    difficulty: "medium",
    category: "exponents",
  },
  {
    question:
      "Find the value of k for which the equation x^2 + kx + 9 = 0 has exactly one real root.",
    answerOptions: [
      { answer: "-18", isCorrect: true },
      { answer: "-9", isCorrect: false },
      { answer: "18", isCorrect: false },
      { answer: "9", isCorrect: false },
    ],
    correctAnswer: "-18",
    difficulty: "medium",
    category: "algebra",
  },
  {
    question:
      "If f(x) = 2x^3 - 3x^2 + 4x - 5, find f'(x) and then determine f'(2).",
    answerOptions: [
      { answer: "13", isCorrect: true },
      { answer: "12", isCorrect: false },
      { answer: "15", isCorrect: false },
      { answer: "10", isCorrect: false },
    ],
    correctAnswer: "13",
    difficulty: "medium",
    category: "calculus",
  },
  {
    question: "Solve the system of equations: 2x - 3y = 7 and x + 2y = 1.",
    answerOptions: [
      { answer: "x = 5, y = -2", isCorrect: true },
      { answer: "x = -5, y = 2", isCorrect: false },
      { answer: "x = 3, y = -1", isCorrect: false },
      { answer: "x = 7, y = -3", isCorrect: false },
    ],
    correctAnswer: "x = 5, y = -2",
    difficulty: "medium",
    category: "algebra",
  },
  {
    question: "Find the value of sum of squares from 1 to n using the formula.",
    answerOptions: [
      { answer: "n(n+1)(2n+1) / 6", isCorrect: true },
      { answer: "n(n+1) / 2", isCorrect: false },
      { answer: "n(n+1)(n+2) / 6", isCorrect: false },
      { answer: "n(n+1)(n+2) / 3", isCorrect: false },
    ],
    correctAnswer: "n(n+1)(2n+1) / 6",
    difficulty: "medium",
    category: "sequences",
  },
  {
    question: "Determine the value of log2(16) + log2(8) - log2(4).",
    answerOptions: [
      { answer: "6", isCorrect: true },
      { answer: "4", isCorrect: false },
      { answer: "5", isCorrect: false },
      { answer: "7", isCorrect: false },
    ],
    correctAnswer: "6",
    difficulty: "medium",
    category: "logarithms",
  },
  {
    question:
      "Find the determinant of the matrix [[1, 2, 3], [4, 5, 6], [7, 8, 9]].",
    answerOptions: [
      { answer: "0", isCorrect: true },
      { answer: "1", isCorrect: false },
      { answer: "-1", isCorrect: false },
      { answer: "2", isCorrect: false },
    ],
    correctAnswer: "0",
    difficulty: "medium",
    category: "matrix algebra",
  },
  {
    question:
      "For which value of a does the equation x^2 - 2ax + a^2 - 3 = 0 have exactly one root?",
    answerOptions: [
      { answer: "±3", isCorrect: true },
      { answer: "±2", isCorrect: false },
      { answer: "±1", isCorrect: false },
      { answer: "±4", isCorrect: false },
    ],
    correctAnswer: "±3",
    difficulty: "medium",
    category: "algebra",
  },
];

export default algebraquestions;
