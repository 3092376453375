const basciphyquestions = [
  {
    question: " Which of the following have dimension of time :",
    answerOptions: [
      { answer: "LC", isCorrect: false },
      { answer: "R/L", isCorrect: false },
      { answer: "L/R", isCorrect: false },
      { answer: "C/L", isCorrect: true },
    ],
    correctAanswer: "C/L" 
  },
  {
    question: "The dimension formula of magnatic flux is :",
    answerOptions: [
      { answer: "[M L² T^-2 A^-1]", isCorrect: true },
      { answer: "[M L^3 T^-2 A^-1]", isCorrect: false },
      { answer: "[M^0 L^-2 T^-2 A^-2]", isCorrect: false },
      { answer: "[M L² T^-1 A²]", isCorrect: false },
    ],
    correctAanswer: "[M L² T^-2 A^-1]" 
  },
  {
    question: "The unit of permittivity of free space, epsolne-node is :",
    answerOptions: [
      { answer: "coulomb/newton-meter", isCorrect: false },
      { answer: "newton-meter²/columb", isCorrect: false },
      { answer: "coulomb²/newton-meter²", isCorrect: true },
      { answer: "coulomb²/(newton-meter)²", isCorrect: false },
    ],
    correctAanswer: "coulomb²/newton-meter²" 
  },
  {
    question:
      "The physical quantity having the dimension [M^-1 L^-3 T^3 A²] is :",
    answerOptions: [
      { answer: "resistance", isCorrect: false },
      { answer: "resistivity", isCorrect: false },
      { answer: "electrical conductivity", isCorrect: false },
      { answer: "electromotive force", isCorrect: true },
    ],
    correctAanswer: "electromotive force" 
  },
  {
    question: "Dimension velocity of gradient is :",
    answerOptions: [
      { answer: "[M^0 L^0 T^-1]", isCorrect: true },
      { answer: "[M L^-1 T^-1]", isCorrect: false },
      { answer: "[M^0 L T^-1]", isCorrect: false },
      { answer: "[M L T^-1]", isCorrect: false },
    ],
    correctAanswer: "[M^0 L^0 T^-1]" 
  },
  {
    question:
      "A force of F is applied on a body is expressed as F = at + bt², where t taken as time. What is dimension of a and b will be : (Xnetous chellange)",
    answerOptions: [
      { answer: "[M L T^-4] and [M L T^1]", isCorrect: false },
      { answer: "[M L T^1] and [M L T^0]", isCorrect: false },
      { answer: "[M L T^-3] and [M L T^-4]", isCorrect: true },
      { answer: "[M L T^-3] and [M L T^0]", isCorrect: false },
    ],
    correctAanswer: "[M L T^-3] and [M L T^-4]"
  },
  {
    question:
      "The ratio of the dimensions of Plank's constant and that of the moment of inertia is the dimension of :",
    answerOptions: [
      { answer: "frequency", isCorrect: true },
      { answer: "velovity", isCorrect: false },
      { answer: "angular momentum", isCorrect: false },
      { answer: "time", isCorrect: false },
    ],
    correctAanswer: "frequency"
  },
  {
    question: "Dimension of linear impulse are :",
    answerOptions: [
      { answer: "[M L^-2 T^-3]", isCorrect: false },
      { answer: "[M L^-2]", isCorrect: false },
      { answer: "[M L T^-1]", isCorrect: true },
      { answer: "[M L T^-2]", isCorrect: false },
    ],
    correctAanswer: "[M L T^-1]"
  },
  {
    question:
      "If the energy (E), velocity (v) and force (F) be taken as fundamential quantity, then the dimensions of mass will be :",
    answerOptions: [
      { answer: "Fv^-2", isCorrect: false },
      { answer: "F^-1", isCorrect: false },
      { answer: "Ev^-2", isCorrect: true },
      { answer: "Ev^2", isCorrect: false },
    ],
    correctAanswer: "Ev^-2"
  },
  {
    question: "Which one is the vector quantity :",
    answerOptions: [
      { answer: "time", isCorrect: false },
      { answer: "temperature", isCorrect: false },
      { answer: "flux density", isCorrect: false },
      { answer: "magnetic field intensity", isCorrect: true },
    ],
    correctAanswer: true
  },
 {
    question:
      "Given that P = 12, Q = 5 and R = 13 also vector P + vector Q = vector R, then the angle between vector P and vector Q will be :",
    answerOptions: [
      { answer: "π", isCorrect: false },
      { answer: "π/2", isCorrect: true },
      { answer: "0", isCorrect: false },
      { answer: "π/4", isCorrect: false },
    ],
  },
  {
    question: "Which of the following is a scalar quantity :",
    answerOptions: [
      { answer: "displacement", isCorrect: false },
      { answer: "electric field", isCorrect: false },
      { answer: "velocity", isCorrect: false },
      { answer: "speed", isCorrect: true },
    ],
  },
  {
    question:
      "What is the dot product of two vectors os magnitude 3 and 5, if the angle between them is 60° :",
    answerOptions: [
      { answer: "5.2", isCorrect: false },
      { answer: "7.5", isCorrect: true },
      { answer: "8.4", isCorrect: false },
      { answer: "8.6", isCorrect: false },
    ],
  },
  {
    question:
      "A force (3i + 4j) N acts on a body and displaces it by (3i + 4j) m. Then the work done by the force is :",
    answerOptions: [
      { answer: "5 J", isCorrect: false },
      { answer: "25 J", isCorrect: true },
      { answer: "10 J", isCorrect: false },
      { answer: "30 J", isCorrect: false },
    ],
  },
  {
    question:
      "If a unit vector is represented by 0.5i + 0.8j + cK, the velue of c is :",
    answerOptions: [
      { answer: "1", isCorrect: false },
      { answer: "√(0.11)", isCorrect: true },
      { answer: "√(0.01)", isCorrect: false },
      { answer: "0.39", isCorrect: false },
    ],
  },
  {
    question: "Which of the following is vector quantity :",
    answerOptions: [
      { answer: "displacement", isCorrect: false },
      { answer: "velocity", isCorrect: false },
      { answer: "speed", isCorrect: false },
      { answer: "displacement and velocity", isCorrect: true },
    ],
  },
  {
    question:
      "The sum of two vector vector A and vector B is at right angles to their difference. Then :",
    answerOptions: [
      { answer: "A = B", isCorrect: true },
      { answer: "A = 2B", isCorrect: false },
      { answer: "B = 2A", isCorrect: false },
      { answer: "A and B have the same direction", isCorrect: false },
    ],
  },
  {
    question:
      "Young's modulus of steel is 2.0x10^11 M/m². Express it in dyne/cm² :",
    answerOptions: [
      { answer: "2.0x10^12 dyne/cm²", isCorrect: true },
      { answer: "2.0x10^10 dyne/cm²", isCorrect: false },
      { answer: "2x10^12 dyne/cm²", isCorrect: false },
      { answer: "2x10^10 dyne/cm²", isCorrect: false },
    ],
  },
  {
    question:
      "Minimum number of vector of unequal magnitudes which can give 0 resultant are :",
    answerOptions: [
      { answer: "2", isCorrect: false },
      { answer: "3", isCorrect: true },
      { answer: "4", isCorrect: false },
      { answer: "can not be said", isCorrect: false },
    ],
  },
  {
    question: "Astronomical unit represented as :",
    answerOptions: [
      { answer: "mean distance of earth from moon", isCorrect: false },
      { answer: "mean distance of earth from mars", isCorrect: false },
      { answer: "mean distance of earth from sun", isCorrect: true },
      { answer: "mean distance of moon from sun", isCorrect: false },
    ],
  },
  {
    question: "1 horse power is equal to :",
    answerOptions: [
      { answer: "746 Watt", isCorrect: true },
      { answer: "10^28 Watt", isCorrect: false },
      { answer: "453.6 kg Watt", isCorrect: false },
      { answer: "10^5 Pa Watt", isCorrect: false },
    ],
  },
  {
    question:
      "Which of these are vector pressure, surface tesion and current :",
    answerOptions: [
      { answer: "pressure", isCorrect: false },
      { answer: "surface tension", isCorrect: false },
      { answer: "current", isCorrect: false },
      { answer: "None of these", isCorrect: true },
    ],
  },
  {
    question: "A vector of unit magnitude is written as :",
    answerOptions: [
      { answer: "A cap", isCorrect: true },
      { answer: "A dot", isCorrect: false },
      { answer: "A cross", isCorrect: false },
      { answer: "data insufficient", isCorrect: false },
    ],
  },
  {
    question: "Dipole moment and current density are defined as vectors with :",
    answerOptions: [
      { answer: "one direction", isCorrect: false },
      { answer: "two direction", isCorrect: false },
      { answer: "specific direction", isCorrect: true },
      { answer: "all direction", isCorrect: false },
    ],
  },
  {
    question:
      "The resultant of two forces 3P and 2P is R. If the first force si doubled then the resultant is also doubled. The angle between the two forces is :",
    answerOptions: [
      { answer: "60°", isCorrect: false },
      { answer: "120°", isCorrect: true },
      { answer: "30°", isCorrect: false },
      { answer: "135°", isCorrect: false },
    ],
  },
  {
    question:
      "The physical quantity having the dimensions [M^-1 L^-3 T^3 A²] :",
    answerOptions: [
      { answer: "resistance", isCorrect: false },
      { answer: "restivity", isCorrect: false },
      { answer: "conductivity", isCorrect: true },
      { answer: "electromotive force", isCorrect: false },
    ],
  },
  {
    question: "Electric field is which quantity :",
    answerOptions: [
      { answer: "scalar", isCorrect: true },
      { answer: "vector", isCorrect: false },
      { answer: "dimensionless", isCorrect: false },
      { answer: "data insufficient", isCorrect: false },
    ],
  },
  {
    question: "The angle between vector P + Q and vector P - Q will be :",
    answerOptions: [
      { answer: "90°", isCorrect: false },
      { answer: "between 0° and 180°", isCorrect: true },
      { answer: "180°", isCorrect: false },
      { answer: "90°", isCorrect: false },
    ],
  },
  {
    question:
      "The components of vector A = 2i + 3j along the vector i + j is :",
    answerOptions: [
      { answer: "5/√(2)", isCorrect: true },
      { answer: "10 √(2)", isCorrect: false },
      { answer: "5 √(2)", isCorrect: false },
      { answer: "5", isCorrect: false },
    ],
  },
  {
    question: "If dot product of vector a and vector b are positive :",
    answerOptions: [
      { answer: "θ = 90°", isCorrect: false },
      { answer: "θ < 0 <  90°", isCorrect: false },
      { answer: "0 < θ < 90°", isCorrect: true },
      { answer: "90° < θ < 180°", isorrect: false },
    ],
  },
  {
    question:
      "Minimum number of vectors of unequal magnitudes which can give a zero resultant are:",
    answerOptions: [
      { answer: "2", isCorrect: false },
      { answer: "3", isCorrect: true },
      { answer: "4", isCorrect: false },
      { answer: "cannot be said", isCorrect: false },
    ],
  },
  {
    question: "What is the dimension of force?",
    answerOptions: [
      { answer: "[M]", isCorrect: false },
      { answer: "[L]", isCorrect: false },
      { answer: "[M L T^-2]", isCorrect: true },
      { answer: "[M L^2 T^-2]", isCorrect: false },
    ],
  },
  {
    question: "Which of the following is a scalar quantity?",
    answerOptions: [
      { answer: "Displacement", isCorrect: false },
      { answer: "Velocity", isCorrect: false },
      { answer: "Acceleration", isCorrect: false },
      { answer: "Temperature", isCorrect: true },
    ],
  },
  {
    question: "What is the unit of angular momentum?",
    answerOptions: [
      { answer: "kg m^2/s", isCorrect: true },
      { answer: "kg m/s", isCorrect: false },
      { answer: "kg m^2/s^2", isCorrect: false },
      { answer: "kg m/s^2", isCorrect: false },
    ],
  },
  {
    question: "What is the dimension of energy?",
    answerOptions: [
      { answer: "[M L^2 T^-2]", isCorrect: true },
      { answer: "[M L T^-2]", isCorrect: false },
      { answer: "[M L^2 T^-3]", isCorrect: false },
      { answer: "[M L T^-1]", isCorrect: false },
    ],
  },
  {
    question:
      "Which of the following vectors is perpendicular to the plane of motion?",
    answerOptions: [
      { answer: "Position vector", isCorrect: false },
      { answer: "Velocity vector", isCorrect: false },
      { answer: "Acceleration vector", isCorrect: false },
      { answer: "Angular momentum vector", isCorrect: true },
    ],
  },
  {
    question: "What is the unit of torque?",
    answerOptions: [
      { answer: "N m", isCorrect: true },
      { answer: "J", isCorrect: false },
      { answer: "kg m^2/s^2", isCorrect: false },
      { answer: "kg m/s^2", isCorrect: false },
    ],
  },
  {
    question: "What is the dimension of power?",
    answerOptions: [
      { answer: "[M L^2 T^-3]", isCorrect: true },
      { answer: "[M L T^-2]", isCorrect: false },
      { answer: "[M L^2 T^-2]", isCorrect: false },
      { answer: "[M L T^-1]", isCorrect: false },
    ],
  },
  {
    question: "Which of the following is a vector quantity?",
    answerOptions: [
      { answer: "Speed", isCorrect: false },
      { answer: "Velocity", isCorrect: true },
      { answer: "Acceleration", isCorrect: true },
      { answer: "Temperature", isCorrect: false },
    ],
  },
  {
    question: "What is the unit of angular velocity?",
    answerOptions: [
      { answer: "rad/s", isCorrect: true },
      { answer: "rad/s^2", isCorrect: false },
      { answer: "m/s", isCorrect: false },
      { answer: "m/s^2", isCorrect: false },
    ],
  },
  {
    question:
      "A vector has a magnitude of 10 m and a direction of 30° north of east. What is its x-component?",
    answerOptions: [
      { answer: "5 m", isCorrect: false },
      { answer: "8.66 m", isCorrect: true },
      { answer: "10 m", isCorrect: false },
      { answer: "15 m", isCorrect: false },
    ],
  },
  {
    question: "What is the dimension of the unit of force, Newton (N)?",
    answerOptions: [
      { answer: "[M]", isCorrect: false },
      { answer: "[L]", isCorrect: false },
      { answer: "[M L T^-2]", isCorrect: true },
      { answer: "[M L^2 T^-2]", isCorrect: false },
    ],
  },
  {
    question:
      "A vector has a magnitude of 20 m and a direction of 45° south of west. What is its y-component?",
    answerOptions: [
      { answer: "-14.14 m", isCorrect: true },
      { answer: "-20 m", isCorrect: false },
      { answer: "14.14 m", isCorrect: false },
      { answer: "20 m", isCorrect: false },
    ],
  },
  {
    question: "What is the dimension of the unit of energy, Joule (J)?",
    answerOptions: [
      { answer: "[M L^2 T^-2]", isCorrect: true },
      { answer: "[M L T^-2]", isCorrect: false },
      { answer: "[M L^2 T^-3]", isCorrect: false },
      { answer: "[M L T^-1]", isCorrect: false },
    ],
  },
  {
    question:
      "A vector has a magnitude of 15 m and a direction of 60° north of east. What is its y-component?",
    answerOptions: [
      { answer: "7.5 m", isCorrect: false },
      { answer: "12.99 m", isCorrect: true },
      { answer: "15 m", isCorrect: false },
      { answer: "20 m", isCorrect: false },
    ],
  },
  {
    question: "What is the dimension of the unit of power, Watt (W)?",
    answerOptions: [
      { answer: "[M L^2 T^-3]", isCorrect: true },
      { answer: "[M L T^-2]", isCorrect: false },
      { answer: "[M L^2 T^-2]", isCorrect: false },
      { answer: "[M L T^-1]", isCorrect: false },
    ],
  },
  {
    question:
      "A vector has a magnitude of 25 m and a direction of 75° south of west. What is its x-component?",
    answerOptions: [
      { answer: "-22.5 m", isCorrect: true },
      { answer: "-25 m", isCorrect: false },
      { answer: "22.5 m", isCorrect: false },
      { answer: "25 m", isCorrect: false },
    ],
  },
  {
    question:
      "What is the dimension of the unit of velocity, meter per second (m/s)?",
    answerOptions: [
      { answer: "[L T^-1]", isCorrect: true },
      { answer: "[L T]", isCorrect: false },
      { answer: "[L^2 T^-1]", isCorrect: false },
      { answer: "[L^2 T]", isCorrect: false },
    ],
  },
  {
    question:
      "A vector has a magnitude of 30 m and a direction of 90° north. What is its y-component?",
    answerOptions: [
      { answer: "0 m", isCorrect: false },
      { answer: "30 m", isCorrect: true },
      { answer: "-30 m", isCorrect: false },
      { answer: "15 m", isCorrect: false },
    ],
  },
  {
    question:
      "What is the dimension of the unit of acceleration, meter per second squared (m/s^2)?",
    answerOptions: [
      { answer: "[L T^-2]", isCorrect: true },
      { answer: "[L T^-1]", isCorrect: false },
      { answer: "[L T]", isCorrect: false },
      { answer: "[L^2 T^-2]", isCorrect: false },
    ],
  },
  {
    question:
      "A ship is sailing due east at a speed of 20 km/h. A wind is blowing due north at a speed of 15 km/h. What is the magnitude and direction of the resultant velocity of the ship?",
    answerOptions: [
      { answer: "25 km/h, 30° north of east", isCorrect: true },
      { answer: "20 km/h, 45° north of east", isCorrect: false },
      { answer: "25 km/h, 45° north of east", isCorrect: false },
      { answer: "30 km/h, 60° north of east", isCorrect: false },
    ],
  },
  {
    question:
      "A force of 50 N acts on an object at an angle of 60° to the horizontal. What is the horizontal and vertical component of the force?",
    answerOptions: [
      { answer: "25 N, 43.3 N", isCorrect: true },
      { answer: "25 N, 50 N", isCorrect: false },
      { answer: "43.3 N, 25 N", isCorrect: false },
      { answer: "50 N, 0 N", isCorrect: false },
    ],
  },
  {
    question:
      "A car is traveling due north at a speed of 30 m/s. A second car is traveling due east at a speed of 20 m/s. What is the magnitude and direction of the resultant velocity of the two cars relative to each other?",
    answerOptions: [
      { answer: "36.06 m/s, 53.13° north of east", isCorrect: true },
      { answer: "30 m/s, 60° north of east", isCorrect: false },
      { answer: "20 m/s, 30° north of east", isCorrect: false },
      { answer: "40 m/s, 45° north of east", isCorrect: false },
    ],
  },
  {
    question:
      "A force of 100 N acts on an object at an angle of 30° to the horizontal. What is the work done by the force if the object moves 20 m in the direction of the force?",
    answerOptions: [
      { answer: "1500 J", isCorrect: true },
      { answer: "1000 J", isCorrect: false },
      { answer: "2000 J", isCorrect: false },
      { answer: "2500 J", isCorrect: false },
    ],
  },
  {
    question:
      "A vector has a magnitude of 20 m and a direction of 45° south of west. What is the x and y component of the vector?",
    answerOptions: [
      { answer: "-14.14 m, -14.14 m", isCorrect: true },
      { answer: "-20 m, 0 m", isCorrect: false },
      { answer: "14.14 m, -14.14 m", isCorrect: false },
      { answer: "20 m, 20 m", isCorrect: false },
    ],
  },
  {
    question:
      "A ship is sailing due west at a speed of 25 km/h. A current is flowing due south at a speed of 10 km/h. What is the magnitude and direction of the resultant velocity of the ship?",
    answerOptions: [
      { answer: "27.03 km/h, 21.8° south of west", isCorrect: true },
      { answer: "25 km/h, 30° south of west", isCorrect: false },
      { answer: "20 km/h, 45° south of west", isCorrect: false },
      { answer: "30 km/h, 60° south of west", isCorrect: false },
    ],
  },
  {
    question:
      "A force of 200 N acts on an object at an angle of 45° to the horizontal. What is the horizontal and vertical component of the force?",
    answerOptions: [
      { answer: "141.42 N, 141.42 N", isCorrect: true },
      { answer: "100 N, 100 N", isCorrect: false },
      { answer: "200 N, 0 N", isCorrect: false },
      { answer: "141.42 N, 200 N", isCorrect: false },
    ],
  },
];

export default basciphyquestions;
