import React, { useEffect, useState } from "react";
import { auth, fireDB } from "../config/firebase";
import { onAuthStateChanged } from "firebase/auth";
import {
  doc,
  getDoc,
  collection,
  query,
  where,
  getDocs,
  updateDoc,
  arrayUnion,
} from "firebase/firestore";
import { toast } from "react-toastify";
import LoadingSpinner from "./LoadingSpinner";
import "../componentsStyle/postedVote.css";
import PostVote from "./PostVote";
import { useNavigate } from "react-router-dom";

const ITEMS_PER_PAGE = 1;

function PostedVote() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [postedVotes, setPostedVotes] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(postedVotes.length / ITEMS_PER_PAGE);

  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      setLoading(true);
      if (currentUser) {
        setUser(currentUser);
        await fetchPostedVotes(currentUser);
      } else {
        // Redirect to login if user is not authenticated
        navigate("/login");
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const fetchPostedVotes = async (currentUser) => {
    try {
      const userRef = doc(fireDB, "users", currentUser.uid);
      const userDoc = await getDoc(userRef);
      const postedVotesIds = userDoc.data().postedVotes || [];

      if (postedVotesIds.length > 0) {
        const votesQuery = query(
          collection(fireDB, "votes"),
          where("userId", "==", currentUser.uid)
        );
        const votesSnapshot = await getDocs(votesQuery);
        const votesList = votesSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setPostedVotes(votesList);
      } else {
        setPostedVotes([]);
      }
    } catch (error) {
      console.error("Error fetching votes:", error);
      toast.error("Failed to fetch votes. Please try again.");
    }
  };

  const handleVote = async (voteId, option) => {
    try {
      const voteRef = doc(fireDB, "votes", voteId);
      const voteDoc = await getDoc(voteRef);
      const updatedVote = {
        ...voteDoc.data(),
        [option]: (voteDoc.data()[option] || 0) + 1,
      };

      await updateDoc(voteRef, updatedVote);
      toast.success("Vote submitted successfully!");
      await fetchPostedVotes(user); // Refresh votes after update
    } catch (error) {
      console.error("Error submitting vote:", error);
      toast.error("Failed to submit vote. Please try again.");
    }
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const currentVotes = postedVotes.slice(startIndex, startIndex + ITEMS_PER_PAGE);

  return (
    <div className="dashboard-container">
        <PostVote
          user={user}
          fetchPostedVotes={() => fetchPostedVotes(user)}
        />
      <h2>My Posted Votes</h2>

      {postedVotes.length > 0 ? (
        <ul className="votes-list">
          {currentVotes.map((vote) => (
            <li key={vote.id} className="vote-item">
              <p><strong>Q:</strong> {vote.question}</p>
              <div className="vote-buttons">
                <button onClick={() => handleVote(vote.id, "yes")}>Yes ({vote.yes || 0})</button>
                <button onClick={() => handleVote(vote.id, "no")}>No ({vote.no || 0})</button>
              </div>
            </li>
          ))}
        </ul>
      ) : (
        <p>No votes posted yet.</p>
      )}

      {/* Pagination Controls */}
      <div className="pagination">
        <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
          Previous
        </button>
        <span>Page {currentPage} of {totalPages}</span>
        <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
          Next
        </button>
      </div>
    </div>
  );
}

export default PostedVote;
