const coordinatecompquestions = [
    {
        question:"Haemolglobin is a/an, which type of complex:",
        answerOptions: [
            {answer: 'iron (II) complex', isCorrect: true},
            {answer: 'obalt (III) complex', isCorrect: false},
            {answer: 'magnesium (II) complex', isCorrect: false},
            {answer: 'chromium (III) complex', isCorrect: false},
        ],
    }, {
        question:"The number of unpaired electrons in Fe(CO)5 is :",
        answerOptions: [
            {answer: '0', isCorrect: false},
            {answer: '2', isCorrect: false},
            {answer: '3', isCorrect: true},
            {answer: '4', isCorrect: false},
        ],
    },  {
        question:"Chlorophyll is a/an :",
        answerOptions: [
            {answer: 'Mg complex', isCorrect: true},
            {answer: 'Co complex', isCorrect: false},
            {answer: 'Fe complex', isCorrect: false},
            {answer: 'Cr complex', isCorrect: false},
        ],
    },  {
        question:"Which of the following is not expected to absorb visible wave length of electromagnetic radiation in aquous solution :",
        answerOptions: [
            {answer: 'ScCl3', isCorrect: true},
            {answer: 'TiCl3', isCorrect: false},
            {answer: 'VCl3', isCorrect: false},
            {answer: 'CrCl3', isCorrect: false},
        ],
    },  {
        question:" The total number of geometrical isomers possible for [Pt(NH )(NH OH)ClBr] will be:",
        answerOptions: [
            {answer: '3', isCorrect: true},
            {answer: '2', isCorrect: false},
            {answer: '4', isCorrect: false},
            {answer: '6', isCorrect: false},
        ],
    },  {
        question:"The spin-only paramagnetic moment of Cr(CO)6 in BM is :",
        answerOptions: [
            {answer: '3.86', isCorrect: false},
            {answer: '2.86', isCorrect: true},
            {answer: '1.73', isCorrect: false},
            {answer: '0.00', isCorrect: false},
           
        ],
    }, {
        question:"The number of unpaired electrons in the d-orbitals of iron in [Fe(H2O)5 NO]²+ :",
        answerOptions: [
            {answer: '1', isCorrect: false},
            {answer: '2', isCorrect: false},
            {answer: '3', isCorrect: true},
            {answer: '4', isCorrect: false},
        ],
    },  {
        question:"The aquous solution of the following salt(s) is coloured in the case(s) of :",
        answerOptions: [
            {answer: 'Sc(NO3)2', isCorrect: false},
            {answer: 'Co(NO3)2', isCorrect: true},
            {answer: 'Zn(NO3)2', isCorrect: false},
            {answer: 'none of these', isCorrect: false},
        ],
    },  {
        question:" Vitamin B12 is a complex of :",
        answerOptions: [
            {answer: 'Cobalt (II) ion', isCorrect: false},
            {answer: 'Cobalt (II) ion', isCorrect: true},
            {answer: 'Chromium (II) ion', isCorrect: false},
            {answer: 'Chromium (II) ion', isCorrect: false},
        ],
    },  {
        question:"The kind of isomer(s) formed by octahedral Co(NH3)4 Br2Cl will be :",
        answerOptions: [
            {answer: 'Geometrical', isCorrect: true},
            {answer: 'optical', isCorrect: false},
            {answer: 'linear', isCorrect: false},
            {answer: 'linkage', isCorrect: false},
        ],
    },  {
        question:"The Wilson's disease, a genetic disease is caused due to  :",
        answerOptions: [
            {answer: 'excess Cu in the body', isCorrect: true},
            {answer: 'excess Zn in the body', isCorrect: false},
            {answer: 'excess Fe in the body', isCorrect: false},
            {answer: 'excess Mn in the body', isCorrect: false},
        ],
    },  {
        question:"The complex ion which has no electrons in the d orbitals of the complexed metal: :",
        answerOptions: [
            {answer: '[Mn(H2O)6]²+', isCorrect: false},
            {answer: '[MnCl4]²-', isCorrect: false},
            {answer: '[MnO4]-', isCorrect: true},
            {answer: '[Mn(CO2)6]^4+', isCorrect: false},
        ],
    },  {
        question:"The complex which is paramagnetic is :",
        answerOptions: [
            {answer: 'K3[CoF6]', isCorrect: true},
            {answer: 'K[Co(CO)4]', isCorrect: false},
            {answer: '{Co(NH3)6]Cl3', isCorrect: false},
            {answer: 'K3[Co(OX)3]', isCorrect: false},
        ],
    },  {
        question:" The number of ions produced by this complex [Co(NH3)4 Cl2]Cl will be:",
        answerOptions: [
            {answer: '2', isCorrect: false},
            {answer: '3', isCorrect: true},
            {answer: '4', isCorrect: false},
            {answer: '6', isCorrect: false},
        ],
    },  {
        question:"The complex which is expected most stable is :",
        answerOptions: [
            {answer: '[Cr(H2O)6]Cl3', isCorrect: false},
            {answer: '[Cr(NH3)6]Cl3', isCorrect: false},
            {answer: '[Cr(CO2)6]Cl3', isCorrect: false},
            {answer: '[Cr(CN)6]^3-', isCorrect: true},
        ],
    },  {
        question:"The following ions given below, which one has the highest paramagnetism :",
        answerOptions: [
            {answer: '[Cr(H2O)6]^3+', isCorrect: false},
            {answer: '[Fe(H2O)6]^3+', isCorrect: true},
            {answer: '[Cu(H2O)6]^3+', isCorrect: false},
            {answer: '[Zn(H2O)6]^3+', isCorrect: false},
        ],
    },    {
        question:"Geometry of Ni(CO)4 and Ni(PPh3)2 Cl2 will be :",
        answerOptions: [
            {answer: 'Both square planar', isCorrect: false},
            {answer: 'Tetrahedral and square plana', isCorrect: false},
            {answer: 'Both tetrahedral', isCorrect: true},
            {answer: 'Square planar and tetrahedra', isCorrect: false},
        ],
    },  {
        question:"The oxidation number of cobalt in K(Co(CO)4] :",
        answerOptions: [
            {answer: '-1', isCorrect: true},
            {answer: '+1', isCorrect: false},
            {answer: '+2', isCorrect: false},
            {answer: 'data insufficient', isCorrect: false},
        ],
    },  {
        question:"The IUPAC name [Ni(CO)4] is :",
        answerOptions: [
            {answer: 'tetracarbonylnickel (II)', isCorrect: false},
            {answer: 'tetracarbonylnickel (0)', isCorrect: true},
            {answer: 'tetracarbonylnickelate (II)', isCorrect: false},
            {answer: 'tetracarbonylnickelate (0)', isCorrect: false},
        ],
    }, {
        question:" The IUPAC name of K3[Co(NO2)6] is :",
        answerOptions: [
            {answer: 'Mg (I) hexanitrocobaltate (II)', isCorrect: false},
            {answer: 'Mg (I) hexanitrocobaltate (III)', isCorrect: true},
            {answer: 'Mg hexanitrocobalt (0)', isCorrect: false},
            {answer: 'Mg hexanitrocobalt (III)', isorrect: true},
        ],
    },
]

export default coordinatecompquestions;