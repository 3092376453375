import { useState } from 'react';
import { signInWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { auth } from '../config/firebase';
import { useNavigate } from 'react-router-dom';
import '../config/firebase'; // Import the CSS file
import '../authStyle/LoginPage.css'

function LoginPage() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [resetEmail, setResetEmail] = useState('');
    const [resetRequested, setResetRequested] = useState(false);
    const navigate = useNavigate(); // Initialize navigate

    const login = async () => {
        try {
            const result = await signInWithEmailAndPassword(auth, email, password);
            toast.success("Login successful");
            localStorage.setItem('user', JSON.stringify(result));
            navigate('/dashboard'); // Redirect to dashboard after login
        } catch (error) {
            if (error.code === 'auth/too-many-requests') {
                toast.error("Access to this account has been temporarily disabled due to many failed login attempts. Reset your password to restore access.");
            } else {
                toast.error(`Error: ${error.message}`);
            }
            console.log(error);
        }
    };

    const handlePasswordReset = async () => {
        try {
            await sendPasswordResetEmail(auth, resetEmail);
            toast.success("Password reset email sent successfully.");
            setResetRequested(true);
        } catch (error) {
            toast.error(`Error: ${error.message}`);
            console.log(error);
        }
    };

    return (
        <div className="outer-container">
            <div className="login-card">
                <h1 className="login-header">Login</h1>
                <input 
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    name='email'
                    className="login-input"
                    placeholder='Email'
                />
                <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="login-input"
                    placeholder='Password'
                />
                <button
                    onClick={login}
                    className="login-button">
                    Login
                </button>
                <div className="login-footer">
                    <h2>Don't have an account? <Link className="login-link" to={'/signup'}>Signup</Link></h2>
                    <div className="forgot-password">
                        {resetRequested ? (
                            <p className="reset-notification">Check your email for the password reset link.</p>
                        ) : (
                            <>
                                <input 
                                    type="email"
                                    value={resetEmail}
                                    onChange={(e) => setResetEmail(e.target.value)}
                                    className="login-input"
                                    placeholder='Enter your email to reset password'
                                />
                                <button
                                    onClick={handlePasswordReset}
                                    className="forgot-password-button">
                                    Reset Password
                                </button>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LoginPage;
