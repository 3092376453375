const analyticalchequestions = [
    {
        question:"Every salt contains :",
        answerOptions: [
            {answer: 'cation', isCorrect: false},
            {answer: 'anion', isCorrect: false},
            {answer: 'both of these', isCorrect: true},
            {answer: 'none of these', isCorrect: false},
        ],
    }, {
        question:"All carbonates (or hydrogen carbonates) are decomposed by dil. H2SO4 to :",
        answerOptions: [
            {answer: 'CO', isCorrect: false},
            {answer: 'NO2', isCorrect: false},
            {answer: 'O2', isCorrect: false},
            {answer: 'CO2', isCorrect: true},
        ],
    }, {
        question:"What is the primary goal of analytical chemistry :",
        answerOptions: [
            {answer: 'To create new chemical compounds', isCorrect: false},
            {answer: 'To study the behavior of atoms and molecules', isCorrect: false},
            {answer: 'To identify and quantify substances in samples', isCorrect: true},
            {answer: 'To synthesize organic compounds', isCorrect: false},
        ],
    },{
        question:"Which of the following is a separation technique that separates components of a mixture based on their distribution between a stationary phase and a mobile phase :",
        answerOptions: [
            {answer: 'Titration', isCorrect: false},
            {answer: 'Filtration', isCorrect: false},
            {answer: 'Distillation', isCorrect: false},
            {answer: 'Chromatography', isCorrect: true},
        ],
    },{
        question:"Which term describes the ability of an analytical method to consistently produce the same result when applied to repeated measurements of the same sample :",
        answerOptions: [
            {answer: 'Precision', isCorrect: true},
            {answer: 'Accuracy', isCorrect: false},
            {answer: 'Sensitivity', isCorrect: false},
            {answer: 'Specificity', isCorrect: false},
        ],
    }, {
        question:"In gas chromatography, separation is based on differences in what property of the analyte molecules :",
        answerOptions: [
            {answer: 'Boiling point', isCorrect: true},
            {answer: 'Density', isCorrect: false},
            {answer: 'Solubility', isCorrect: false},
            {answer: 'Color', isCorrect: false},
        ],
    }, {
        question:"CO2 is :",
        answerOptions: [
            {answer: 'colorless', isCorrect: false},
            {answer: 'odourless', isCorrect: false},
            {answer: 'evolves with effervescence', isCorrect: false},
            {answer: 'all of these', isCorrect: true},
        ],
    },  {
        question:"Sulphides are common compound of :",
        answerOptions: [
            {answer: 'acidity', isCorrect: false},
            {answer: 'non-metal', isCorrect: false},
            {answer: 'mass', isCorrect: false},
            {answer: 'metal', isCorrect: true},
        ],
    },  {
        question:"Chloride when warmed with conc. H2SO4 from pungent fumes of HCl, in presence of  :",
        answerOptions: [
            {answer: 'O2', isCorrect: false},
            {answer: 'H2', isCorrect: false},
            {answer: 'heat', isCorrect: true},
            {answer: 'base', isCorrect: false},
        ],
    },  {
        question:"An iodide forms yellow curdy ppt. of AgI with :",
        answerOptions: [
            {answer: 'MgNO3', isCorrect: false},
            {answer: 'NaNO3', isCorrect: false},
            {answer: 'AgNO3', isCorrect: true},
            {answer: 'can not be said', isCorrect: false},
           
        ],
    },
]

export default analyticalchequestions;