const trigonometryquestions = [
  {
    question:
      "The sine of an angle is equal to the ratio of the opposite side to the hypotenuse in a right-angled triangle. Which side is opposite the angle?",
    answerOptions: [
      { answer: "The side adjacent to the angle", isCorrect: false },
      { answer: "The side opposite the angle", isCorrect: true },
      { answer: "The side adjacent to the angle", isCorrect: false },
      { answer: "The side opposite the angle", isCorrect: false },
    ],
  },
  {
    question: "Find the value of sin(45°) + cos(45°).",
    answerOptions: [
      { answer: "√2", isCorrect: true },
      { answer: "1", isCorrect: false },
      { answer: "√3 / 2", isCorrect: false },
      { answer: "2", isCorrect: false },
    ],
  },
  {
    question: "Solve for x: 2sin(x) - 1 = 0.",
    answerOptions: [
      { answer: "x = 30° or 150°", isCorrect: true },
      { answer: "x = 45° or 135°", isCorrect: false },
      { answer: "x = 60° or 120°", isCorrect: false },
      { answer: "x = 90° or 270°", isCorrect: false },
    ],
  },
  {
    question: "Find the value of tan(60°).",
    answerOptions: [
      { answer: "√3", isCorrect: true },
      { answer: "1", isCorrect: false },
      { answer: "√2", isCorrect: false },
      { answer: "√3 / 2", isCorrect: false },
    ],
  },
  {
    question: "If sin(θ) = 3/5, find cos(θ).",
    answerOptions: [
      { answer: "4/5", isCorrect: true },
      { answer: "3/5", isCorrect: false },
      { answer: "1/5", isCorrect: false },
      { answer: "2/5", isCorrect: false },
    ],
  },
  {
    question: "Find the exact value of sin(90°) - cos(0°).",
    answerOptions: [
      { answer: "0", isCorrect: true },
      { answer: "1", isCorrect: false },
      { answer: "-1", isCorrect: false },
      { answer: "2", isCorrect: false },
    ],
  },
  {
    question: "Solve for x in the interval [0°, 360°]: 3cos²(x) - 2 = 0.",
    answerOptions: [
      { answer: "x = 30°, 150°", isCorrect: true },
      { answer: "x = 45°, 135°", isCorrect: false },
      { answer: "x = 60°, 120°", isCorrect: false },
      { answer: "x = 90°, 270°", isCorrect: false },
    ],
  },
  {
    question: "Find the period of the function f(x) = 2sin(3x).",
    answerOptions: [
      { answer: "120°", isCorrect: true },
      { answer: "360°", isCorrect: false },
      { answer: "180°", isCorrect: false },
      { answer: "90°", isCorrect: false },
    ],
  },
  {
    question: "Find the value of sin²(θ) + cos²(θ).",
    answerOptions: [
      { answer: "1", isCorrect: true },
      { answer: "0", isCorrect: false },
      { answer: "2", isCorrect: false },
      { answer: "sin(θ) * cos(θ)", isCorrect: false },
    ],
  },
  {
    question: "Find the exact value of sin(30°) * tan(45°).",
    answerOptions: [
      { answer: "1/2", isCorrect: true },
      { answer: "1", isCorrect: false },
      { answer: "√3 / 2", isCorrect: false },
      { answer: "0", isCorrect: false },
    ],
  },
  {
    question: "Solve for θ in the interval [0°, 360°]: cos²(θ) = 1/2.",
    answerOptions: [
      { answer: "θ = 45°, 315°", isCorrect: true },
      { answer: "θ = 60°, 120°", isCorrect: false },
      { answer: "θ = 30°, 150°", isCorrect: false },
      { answer: "θ = 90°, 270°", isCorrect: false },
    ],
  },
  {
    question: "Find the value of sec(θ) if cos(θ) = 4/5.",
    answerOptions: [
      { answer: "5/4", isCorrect: true },
      { answer: "4/5", isCorrect: false },
      { answer: "3/4", isCorrect: false },
      { answer: "1/5", isCorrect: false },
    ],
  },
  {
    question:
      "What is the value of sin(2θ) if sin(θ) = 3/5 and θ is in the first quadrant?",
    answerOptions: [
      { answer: "24/25", isCorrect: true },
      { answer: "9/25", isCorrect: false },
      { answer: "6/5", isCorrect: false },
      { answer: "15/25", isCorrect: false },
    ],
  },
  {
    question: "Find the exact value of cos(π/4) + sin(π/4).",
    answerOptions: [
      { answer: "√2", isCorrect: true },
      { answer: "1", isCorrect: false },
      { answer: "√3 / 2", isCorrect: false },
      { answer: "√2 / 2", isCorrect: false },
    ],
  },
  {
    question: "Find the amplitude of the function f(x) = 5sin(x) + 3.",
    answerOptions: [
      { answer: "5", isCorrect: true },
      { answer: "3", isCorrect: false },
      { answer: "8", isCorrect: false },
      { answer: "2", isCorrect: false },
    ],
  },
  {
    question: "If cot(θ) = 1/√3, find tan(θ).",
    answerOptions: [
      { answer: "√3", isCorrect: true },
      { answer: "1/√3", isCorrect: false },
      { answer: "1", isCorrect: false },
      { answer: "√2", isCorrect: false },
    ],
  },
  {
    question:
      "Find the value of sin(θ) + cos(θ) if sin(θ) = 1/2 and cos(θ) = √3/2.",
    answerOptions: [
      { answer: "√3", isCorrect: true },
      { answer: "1", isCorrect: false },
      { answer: "2", isCorrect: false },
      { answer: "√2", isCorrect: false },
    ],
  },
  {
    question: "Solve for x in the interval [0°, 180°]: sin(x) = √2/2.",
    answerOptions: [
      { answer: "x = 45°, 135°", isCorrect: true },
      { answer: "x = 30°, 150°", isCorrect: false },
      { answer: "x = 60°, 120°", isCorrect: false },
      { answer: "x = 90°, 270°", isCorrect: false },
    ],
  },
  {
    question: "Determine the value of cot(π/3).",
    answerOptions: [
      { answer: "1/√3", isCorrect: true },
      { answer: "√3", isCorrect: false },
      { answer: "1", isCorrect: false },
      { answer: "2", isCorrect: false },
    ],
  },
  {
    question: "Find the value of cos(2x) if cos(x) = 3/5.",
    answerOptions: [
      { answer: "7/25", isCorrect: true },
      { answer: "6/25", isCorrect: false },
      { answer: "9/25", isCorrect: false },
      { answer: "4/25", isCorrect: false },
    ],
  },
  {
    question: "Solve for θ in the interval [0°, 360°]: 2sin²(θ) = 1.",
    answerOptions: [
      { answer: "θ = 30°, 150°", isCorrect: true },
      { answer: "θ = 45°, 135°", isCorrect: false },
      { answer: "θ = 60°, 120°", isCorrect: false },
      { answer: "θ = 90°, 270°", isCorrect: false },
    ],
  },
  {
    question: "Find the exact value of sin(2θ) given that sin(θ) = 1/3.",
    answerOptions: [
      { answer: "2/3 * √(8/9)", isCorrect: true },
      { answer: "√(8/9)", isCorrect: false },
      { answer: "1/3", isCorrect: false },
      { answer: "2/3", isCorrect: false },
    ],
  },
  {
    question: "Find the value of sec²(θ) - tan²(θ).",
    answerOptions: [
      { answer: "1", isCorrect: true },
      { answer: "0", isCorrect: false },
      { answer: "2", isCorrect: false },
      { answer: "-1", isCorrect: false },
    ],
  },
  {
    question: "If sin(α + β) = 1/2 and cos(α - β) = 1/2, find α and β.",
    answerOptions: [
      { answer: "α = 30°, β = 30°", isCorrect: true },
      { answer: "α = 45°, β = 15°", isCorrect: false },
      { answer: "α = 60°, β = 60°", isCorrect: false },
      { answer: "α = 90°, β = 30°", isCorrect: false },
    ],
  },
  {
    question: "Find the amplitude of the function f(x) = 3cos(x) - 4sin(x).",
    answerOptions: [
      { answer: "5", isCorrect: true },
      { answer: "7", isCorrect: false },
      { answer: "4", isCorrect: false },
      { answer: "3", isCorrect: false },
    ],
  },
  {
    question: "Find the value of sin(θ) if tan(θ) = 1/√3.",
    answerOptions: [
      { answer: "1/2", isCorrect: true },
      { answer: "√3 / 2", isCorrect: false },
      { answer: "1/√2", isCorrect: false },
      { answer: "√2 / 2", isCorrect: false },
    ],
  },
  {
    question: "Find the general solution of the equation sin(x) = -√3/2.",
    answerOptions: [
      { answer: "x = 240° + 360°k or x = 300° + 360°k", isCorrect: true },
      { answer: "x = 60° + 360°k or x = 120° + 360°k", isCorrect: false },
      { answer: "x = 120° + 360°k or x = 240° + 360°k", isCorrect: false },
      { answer: "x = 30° + 360°k or x = 330° + 360°k", isCorrect: false },
    ],
  },
  {
    question:
      "If cos(θ) = 4/5 and θ is in the first quadrant, find the value of 1 + tan²(θ).",
    answerOptions: [
      { answer: "1.25", isCorrect: true },
      { answer: "2", isCorrect: false },
      { answer: "1.5", isCorrect: false },
      { answer: "1", isCorrect: false },
    ],
  },
  {
    question:
      "If sin(A + B) = 1/2 and cos(A - B) = √3/2, find sin(A)cos(B) + cos(A)sin(B).",
    answerOptions: [
      { answer: "1/2", isCorrect: true },
      { answer: "√3/2", isCorrect: false },
      { answer: "1", isCorrect: false },
      { answer: "√2/2", isCorrect: false },
    ],
  },
  {
    question: "Solve for x: 2sin²(x) - sin(x) - 1 = 0.",
    answerOptions: [
      { answer: "x = 30°, 150°", isCorrect: true },
      { answer: "x = 45°, 135°", isCorrect: false },
      { answer: "x = 60°, 120°", isCorrect: false },
      { answer: "x = 90°, 270°", isCorrect: false },
    ],
  },
  {
    question: "Find the exact value of cot(45°) + sec(45°).",
    answerOptions: [
      { answer: "2", isCorrect: true },
      { answer: "√2", isCorrect: false },
      { answer: "1", isCorrect: false },
      { answer: "√3", isCorrect: false },
    ],
  },
  {
    question: "If tan(θ) = 2, find sin(2θ).",
    answerOptions: [
      { answer: "4/5", isCorrect: true },
      { answer: "1/2", isCorrect: false },
      { answer: "2/5", isCorrect: false },
      { answer: "√3/2", isCorrect: false },
    ],
  },
  {
    question: "Find the period of the function f(x) = 3cos(2x) - 5sin(2x).",
    answerOptions: [
      { answer: "180°", isCorrect: true },
      { answer: "360°", isCorrect: false },
      { answer: "90°", isCorrect: false },
      { answer: "120°", isCorrect: false },
    ],
  },
  {
    question: "If sin(α) = 4/5 and cos(β) = 3/4, find sin(α + β).",
    answerOptions: [
      { answer: "23/20", isCorrect: true },
      { answer: "1/2", isCorrect: false },
      { answer: "5/4", isCorrect: false },
      { answer: "7/8", isCorrect: false },
    ],
  },
  {
    question:
      "Find the value of (1 + tan²(θ)) if sin(θ) = 1/2 and cos(θ) = √3/2.",
    answerOptions: [
      { answer: "2", isCorrect: true },
      { answer: "1", isCorrect: false },
      { answer: "3/2", isCorrect: false },
      { answer: "√3", isCorrect: false },
    ],
  },
  {
    question: "If sin(θ) + cos(θ) = √2, find θ.",
    answerOptions: [
      { answer: "θ = 45°", isCorrect: true },
      { answer: "θ = 60°", isCorrect: false },
      { answer: "θ = 30°", isCorrect: false },
      { answer: "θ = 90°", isCorrect: false },
    ],
  },
  {
    question: "Solve for x: sin²(x) + cos²(x) = 1.",
    answerOptions: [
      { answer: "Always true", isCorrect: true },
      { answer: "x = 0 or 180°", isCorrect: false },
      { answer: "x = 90°", isCorrect: false },
      { answer: "x = 45°", isCorrect: false },
    ],
  },
  {
    question: "Find the value of sin(60°) / cos(30°).",
    answerOptions: [
      { answer: "1", isCorrect: true },
      { answer: "√3", isCorrect: false },
      { answer: "√2", isCorrect: false },
      { answer: "2", isCorrect: false },
    ],
  },
  {
    question: "Find the value of 2sin(θ)cos(θ).",
    answerOptions: [
      { answer: "sin(2θ)", isCorrect: true },
      { answer: "cos(2θ)", isCorrect: false },
      { answer: "tan(θ)", isCorrect: false },
      { answer: "2tan(θ)", isCorrect: false },
    ],
  },
  {
    question: "If cos(2θ) = 0, find the value of θ.",
    answerOptions: [
      { answer: "θ = 45°, 135°", isCorrect: true },
      { answer: "θ = 30°, 150°", isCorrect: false },
      { answer: "θ = 60°, 120°", isCorrect: false },
      { answer: "θ = 90°, 270°", isCorrect: false },
    ],
  },
  {
    question: "Find the value of cos²(θ) - sin²(θ).",
    answerOptions: [
      { answer: "cos(2θ)", isCorrect: true },
      { answer: "sin(2θ)", isCorrect: false },
      { answer: "2cos²(θ) - 1", isCorrect: false },
      { answer: "1 - 2sin²(θ)", isCorrect: false },
    ],
  },
  {
    question: "If tan(θ) = 1, find sin²(θ) + cos²(θ).",
    answerOptions: [
      { answer: "1", isCorrect: true },
      { answer: "2", isCorrect: false },
      { answer: "√2", isCorrect: false },
      { answer: "√3", isCorrect: false },
    ],
  },
  {
    question: "Solve for θ in the interval [0°, 180°]: 2cos²(θ) - 1 = 0.",
    answerOptions: [
      { answer: "θ = 60°, 120°", isCorrect: true },
      { answer: "θ = 45°, 135°", isCorrect: false },
      { answer: "θ = 30°, 150°", isCorrect: false },
      { answer: "θ = 90°, 270°", isCorrect: false },
    ],
  },
  {
    question:
      "Find the exact value of sin(3θ) if sin(θ) = 1/2 and θ is in the first quadrant.",
    answerOptions: [
      { answer: "√3 / 2", isCorrect: true },
      { answer: "1/2", isCorrect: false },
      { answer: "√2 / 2", isCorrect: false },
      { answer: "1", isCorrect: false },
    ],
  },
  {
    question: "Find the value of sin(θ) / cos(θ) if θ = 60°.",
    answerOptions: [
      { answer: "√3 / 3", isCorrect: true },
      { answer: "√3", isCorrect: false },
      { answer: "1", isCorrect: false },
      { answer: "1/√3", isCorrect: false },
    ],
  },
  {
    question: "If sin(x) = 0.6 and cos(x) = 0.8, find tan(x).",
    answerOptions: [
      { answer: "0.75", isCorrect: true },
      { answer: "0.8", isCorrect: false },
      { answer: "0.6", isCorrect: false },
      { answer: "1.2", isCorrect: false },
    ],
  },
  {
    question: "Find the value of cos(π - θ) given that cos(θ) = -√2/2.",
    answerOptions: [
      { answer: "√2/2", isCorrect: true },
      { answer: "-√2/2", isCorrect: false },
      { answer: "0", isCorrect: false },
      { answer: "1", isCorrect: false },
    ],
  },
  {
    question: "Find the period of the function f(x) = 5sin(4x) + 3cos(4x).",
    answerOptions: [
      { answer: "90°", isCorrect: true },
      { answer: "180°", isCorrect: false },
      { answer: "360°", isCorrect: false },
      { answer: "45°", isCorrect: false },
    ],
  },
  {
    question: "If sin²(θ) = 1/4, find cos²(θ).",
    answerOptions: [
      { answer: "3/4", isCorrect: true },
      { answer: "1/2", isCorrect: false },
      { answer: "1/4", isCorrect: false },
      { answer: "2/4", isCorrect: false },
    ],
  },
  {
    question: "Solve for x in the equation sin(x) + √3cos(x) = 1.",
    answerOptions: [
      { answer: "x = 30°", isCorrect: true },
      { answer: "x = 45°", isCorrect: false },
      { answer: "x = 60°", isCorrect: false },
      { answer: "x = 90°", isCorrect: false },
    ],
  },
  {
    question: "Find the value of 2sin(θ)cos(θ) if θ = 30°.",
    answerOptions: [
      { answer: "√3/2", isCorrect: true },
      { answer: "1", isCorrect: false },
      { answer: "√2/2", isCorrect: false },
      { answer: "1/2", isCorrect: false },
    ],
  },
  {
    question:
      "Find the value of cos²(θ) - sin²(θ) if sin(θ) = 1/3 and θ is in the first quadrant.",
    answerOptions: [
      { answer: "8/9", isCorrect: true },
      { answer: "7/9", isCorrect: false },
      { answer: "5/9", isCorrect: false },
      { answer: "4/9", isCorrect: false },
    ],
  },
  {
    question:
      "Solve for θ in the interval [0°, 360°]: 2sin²(θ) - sin(θ) - 1 = 0.",
    answerOptions: [
      { answer: "30°, 150°", isCorrect: true },
      { answer: "45°, 135°", isCorrect: false },
      { answer: "60°, 120°", isCorrect: false },
      { answer: "90°, 270°", isCorrect: false },
    ],
  },
  {
    question: "If sin(θ) = 5/13, find cos(θ).",
    answerOptions: [
      { answer: "12/13", isCorrect: true },
      { answer: "8/13", isCorrect: false },
      { answer: "7/13", isCorrect: false },
      { answer: "5/13", isCorrect: false },
    ],
  },
  {
    question: "Find the value of sin(2θ) if sin(θ) = 1/2.",
    answerOptions: [
      { answer: "1/2", isCorrect: true },
      { answer: "1", isCorrect: false },
      { answer: "√3/2", isCorrect: false },
      { answer: "√2/2", isCorrect: false },
    ],
  },
  {
    question: "If tan(θ) = 1, find the value of sin²(θ) + cos²(θ).",
    answerOptions: [
      { answer: "1", isCorrect: true },
      { answer: "2", isCorrect: false },
      { answer: "√2", isCorrect: false },
      { answer: "1/2", isCorrect: false },
    ],
  },
  {
    question: "Find the period of the function f(x) = 2sin(3x) - 4cos(3x).",
    answerOptions: [
      { answer: "120°", isCorrect: true },
      { answer: "180°", isCorrect: false },
      { answer: "360°", isCorrect: false },
      { answer: "90°", isCorrect: false },
    ],
  },
  {
    question: "Find the value of sin(π - θ) if sin(θ) = 2/3.",
    answerOptions: [
      { answer: "2/3", isCorrect: true },
      { answer: "-2/3", isCorrect: false },
      { answer: "1/3", isCorrect: false },
      { answer: "-1/3", isCorrect: false },
    ],
  },
  {
    question: "If cot(θ) = √3, find sin(θ) + cos(θ).",
    answerOptions: [
      { answer: "2/√3", isCorrect: true },
      { answer: "√3", isCorrect: false },
      { answer: "1", isCorrect: false },
      { answer: "√2", isCorrect: false },
    ],
  },
  {
    question: "Find the value of 2sin(θ)cos(θ) if θ = 45°.",
    answerOptions: [
      { answer: "1", isCorrect: true },
      { answer: "√2/2", isCorrect: false },
      { answer: "√3/2", isCorrect: false },
      { answer: "1/2", isCorrect: false },
    ],
  },
  {
    question: "Solve for θ if sin²(θ) + 2sin(θ)cos(θ) = 1.",
    answerOptions: [
      { answer: "θ = 45°", isCorrect: true },
      { answer: "θ = 30°", isCorrect: false },
      { answer: "θ = 60°", isCorrect: false },
      { answer: "θ = 90°", isCorrect: false },
    ],
  },
  {
    question:
      "Find the exact value of sin(2x) if sin(x) = 1/2 and x is in the first quadrant.",
    answerOptions: [
      { answer: "1", isCorrect: true },
      { answer: "√3/2", isCorrect: false },
      { answer: "1/2", isCorrect: false },
      { answer: "√2/2", isCorrect: false },
    ],
  },
  {
    question: "Find the value of cos(θ) if sin(θ) = -4/5.",
    answerOptions: [
      { answer: "3/5", isCorrect: true },
      { answer: "4/5", isCorrect: false },
      { answer: "1/5", isCorrect: false },
      { answer: "2/5", isCorrect: false },
    ],
  },
  {
    question: "Find the value of tan(2θ) if tan(θ) = 1.",
    answerOptions: [
      { answer: "1", isCorrect: true },
      { answer: "√2", isCorrect: false },
      { answer: "2", isCorrect: false },
      { answer: "0", isCorrect: false },
    ],
  },
  {
    question: "Solve for x in the interval [0°, 360°]: cos²(x) - sin²(x) = 0.",
    answerOptions: [
      { answer: "45°, 135°, 225°, 315°", isCorrect: true },
      { answer: "30°, 150°, 210°, 330°", isCorrect: false },
      { answer: "60°, 120°, 240°, 300°", isCorrect: false },
      { answer: "90°, 270°", isCorrect: false },
    ],
  },
  {
    question: "Find the value of 2cos²(θ) - 1 if cos(θ) = 1/2.",
    answerOptions: [
      { answer: "-1/2", isCorrect: true },
      { answer: "1/2", isCorrect: false },
      { answer: "0", isCorrect: false },
      { answer: "1", isCorrect: false },
    ],
  },
  {
    question: "Find the period of the function f(x) = 7cos(5x) + 3sin(5x).",
    answerOptions: [
      { answer: "72°", isCorrect: true },
      { answer: "90°", isCorrect: false },
      { answer: "180°", isCorrect: false },
      { answer: "360°", isCorrect: false },
    ],
  },
  {
    question: "If sin(θ) = -√2/2, find cos(θ).",
    answerOptions: [
      { answer: "-√2/2", isCorrect: true },
      { answer: "√2/2", isCorrect: false },
      { answer: "1/2", isCorrect: false },
      { answer: "0", isCorrect: false },
    ],
  },
  {
    question: "Find the value of sin(θ) / cos(θ) if θ = 75°.",
    answerOptions: [
      { answer: "2 + √3", isCorrect: true },
      { answer: "1/√3", isCorrect: false },
      { answer: "√3", isCorrect: false },
      { answer: "2 - √3", isCorrect: false },
    ],
  },
  {
    question: "If tan(θ) = -1, find sin²(θ) + cos²(θ).",
    answerOptions: [
      { answer: "1", isCorrect: true },
      { answer: "2", isCorrect: false },
      { answer: "√2", isCorrect: false },
      { answer: "1/2", isCorrect: false },
    ],
  },
  {
    question: "Find the value of sin(θ) + cos(θ) if θ = 30°.",
    answerOptions: [
      { answer: "√3/2 + 1/2", isCorrect: true },
      { answer: "1", isCorrect: false },
      { answer: "√2", isCorrect: false },
      { answer: "√3", isCorrect: false },
    ],
  },
  {
    question: "Solve for θ in the interval [0°, 180°]: sin²(θ) + cos²(θ) = 1.",
    answerOptions: [
      { answer: "Always true", isCorrect: true },
      { answer: "θ = 0°, 90°, 180°", isCorrect: false },
      { answer: "θ = 45°, 135°", isCorrect: false },
      { answer: "θ = 30°, 150°", isCorrect: false },
    ],
  },
  {
    question:
      "Find the value of cos(2θ) if sin(θ) = 3/5 and θ is in the first quadrant.",
    answerOptions: [
      { answer: "7/25", isCorrect: true },
      { answer: "4/5", isCorrect: false },
      { answer: "6/25", isCorrect: false },
      { answer: "1/5", isCorrect: false },
    ],
  },
  {
    question: "Find the value of 1 + tan²(θ) if tan(θ) = 1.",
    answerOptions: [
      { answer: "2", isCorrect: true },
      { answer: "1", isCorrect: false },
      { answer: "√2", isCorrect: false },
      { answer: "3", isCorrect: false },
    ],
  },
  {
    question: "If sin(α) = 3/5, find the value of sin(2α).",
    answerOptions: [
      { answer: "24/25", isCorrect: true },
      { answer: "9/25", isCorrect: false },
      { answer: "15/25", isCorrect: false },
      { answer: "12/25", isCorrect: false },
    ],
  },
  {
    question: "If tan(θ) = 2, find the value of sin(θ) + cos(θ).",
    answerOptions: [
      { answer: "√5 / (1 + 2)", isCorrect: true },
      { answer: "√5 / 2", isCorrect: false },
      { answer: "1 / √5", isCorrect: false },
      { answer: "2 / √5", isCorrect: false },
    ],
  },
  {
    question: "Find the value of cot²(θ) - csc²(θ) given that cot(θ) = 4.",
    answerOptions: [
      { answer: "-15", isCorrect: true },
      { answer: "15", isCorrect: false },
      { answer: "-8", isCorrect: false },
      { answer: "8", isCorrect: false },
    ],
  },
  {
    question: "Determine the general solution of the equation sin(2θ) = √3/2.",
    answerOptions: [
      { answer: "θ = 30° + 180°n, θ = 150° + 180°n", isCorrect: true },
      { answer: "θ = 60° + 180°n, θ = 120° + 180°n", isCorrect: false },
      { answer: "θ = 45° + 90°n", isCorrect: false },
      { answer: "θ = 90° + 180°n", isCorrect: false },
    ],
  },
  {
    question:
      "If α and β are angles such that sin(α) = sin(β) and cos(α) = cos(β), what is the relation between α and β?",
    answerOptions: [
      { answer: "α = β + 360°n", isCorrect: true },
      { answer: "α = β + 180°n", isCorrect: false },
      { answer: "α = -β", isCorrect: false },
      { answer: "α = β", isCorrect: false },
    ],
  },
  {
    question: "Find the exact value of sin(π/4) + cos(π/4).",
    answerOptions: [
      { answer: "√2", isCorrect: true },
      { answer: "√3/2", isCorrect: false },
      { answer: "1", isCorrect: false },
      { answer: "√2/2", isCorrect: false },
    ],
  },
  {
    question: "If sin²(θ) - cos²(θ) = -1, find the value of tan²(θ).",
    answerOptions: [
      { answer: "1", isCorrect: true },
      { answer: "2", isCorrect: false },
      { answer: "0", isCorrect: false },
      { answer: "-1", isCorrect: false },
    ],
  },
  {
    question: "Find the value of sin(θ) + cos(θ) if θ = 75°.",
    answerOptions: [
      { answer: "√2", isCorrect: true },
      { answer: "1", isCorrect: false },
      { answer: "√3", isCorrect: false },
      { answer: "√2/2", isCorrect: false },
    ],
  },
  {
    question: "If cot(θ) = √3, find sin²(θ) + cos²(θ).",
    answerOptions: [
      { answer: "1", isCorrect: true },
      { answer: "2", isCorrect: false },
      { answer: "√2", isCorrect: false },
      { answer: "1/2", isCorrect: false },
    ],
  },
  {
    question: "Solve for θ: 2sin²(θ) - 3sin(θ) + 1 = 0.",
    answerOptions: [
      { answer: "30°, 150°", isCorrect: true },
      { answer: "45°, 135°", isCorrect: false },
      { answer: "60°, 120°", isCorrect: false },
      { answer: "90°, 270°", isCorrect: false },
    ],
  },
  {
    question: "If sin(α) = 1/√2 and α is in the first quadrant, find cos(α).",
    answerOptions: [
      { answer: "1/√2", isCorrect: true },
      { answer: "1/2", isCorrect: false },
      { answer: "√2/2", isCorrect: false },
      { answer: "√3/2", isCorrect: false },
    ],
  },
  {
    question: "Determine the period of the function f(x) = sin(4x).",
    answerOptions: [
      { answer: "90°", isCorrect: true },
      { answer: "180°", isCorrect: false },
      { answer: "360°", isCorrect: false },
      { answer: "45°", isCorrect: false },
    ],
  },
  {
    question: "Find the exact value of cos(π/6) - sin(π/6).",
    answerOptions: [
      { answer: "√3/2 - 1/2", isCorrect: true },
      { answer: "√2/2 - 1/2", isCorrect: false },
      { answer: "1/2 - √2/2", isCorrect: false },
      { answer: "1 - √3/2", isCorrect: false },
    ],
  },
  {
    question: "Solve for θ if tan²(θ) = 3.",
    answerOptions: [
      { answer: "60°, 240°", isCorrect: true },
      { answer: "45°, 135°", isCorrect: false },
      { answer: "30°, 150°", isCorrect: false },
      { answer: "90°, 270°", isCorrect: false },
    ],
  },
  {
    question: "Find the value of sin(θ) + cos(θ) if θ = 30°.",
    answerOptions: [
      { answer: "√3/2 + 1/2", isCorrect: true },
      { answer: "1", isCorrect: false },
      { answer: "√2", isCorrect: false },
      { answer: "√2/2", isCorrect: false },
    ],
  },
  {
    question: "Determine the value of cos(2θ) if sin(θ) = 3/5.",
    answerOptions: [
      { answer: "7/25", isCorrect: true },
      { answer: "4/5", isCorrect: false },
      { answer: "6/25", isCorrect: false },
      { answer: "1/5", isCorrect: false },
    ],
  },
  {
    question: "If sin(θ) = -1/2, find cos(θ).",
    answerOptions: [
      { answer: "√3/2", isCorrect: true },
      { answer: "-√3/2", isCorrect: false },
      { answer: "1/2", isCorrect: false },
      { answer: "-1/2", isCorrect: false },
    ],
  },
  {
    question: "Find the value of 2sin(θ)cos(θ) if sin(θ) = 1/2.",
    answerOptions: [
      { answer: "1/2", isCorrect: true },
      { answer: "1", isCorrect: false },
      { answer: "√3/2", isCorrect: false },
      { answer: "√2/2", isCorrect: false },
    ],
  },
  {
    question: "Find the period of the function f(x) = 5sin(3x) - 2cos(3x).",
    answerOptions: [
      { answer: "120°", isCorrect: true },
      { answer: "180°", isCorrect: false },
      { answer: "360°", isCorrect: false },
      { answer: "90°", isCorrect: false },
    ],
  },
  {
    question: "If sin(2θ) = 0, what are the possible values of θ?",
    answerOptions: [
      { answer: "θ = nπ", isCorrect: true },
      { answer: "θ = (2n+1)π", isCorrect: false },
      { answer: "θ = (2n+1)π/2", isCorrect: false },
      { answer: "θ = nπ/2", isCorrect: false },
    ],
  },
  {
    question: "Find the value of sin(α) + cos(α) if α = 45°.",
    answerOptions: [
      { answer: "√2", isCorrect: true },
      { answer: "1", isCorrect: false },
      { answer: "√3", isCorrect: false },
      { answer: "√2/2", isCorrect: false },
    ],
  },
  {
    question: "If sin(θ) = -2/3, find cos(θ).",
    answerOptions: [
      { answer: "√5/3", isCorrect: true },
      { answer: "-√5/3", isCorrect: false },
      { answer: "1/3", isCorrect: false },
      { answer: "-1/3", isCorrect: false },
    ],
  },
  {
    question: "Determine the value of cot(θ) if tan(θ) = 1/√3.",
    answerOptions: [
      { answer: "√3", isCorrect: true },
      { answer: "1/√3", isCorrect: false },
      { answer: "1", isCorrect: false },
      { answer: "√2", isCorrect: false },
    ],
  },
  {
    question: "Solve for θ if sin(θ) = 0.6.",
    answerOptions: [
      { answer: "36.87°", isCorrect: true },
      { answer: "45°", isCorrect: false },
      { answer: "30°", isCorrect: false },
      { answer: "60°", isCorrect: false },
    ],
  },
  {
    question:
      "A 15-meter ladder is leaning against a wall. If the angle between the ladder and the ground is 60°, how high up the wall does the ladder reach?",
    answerOptions: [
      { answer: "12.99 meters", isCorrect: true },
      { answer: "13.00 meters", isCorrect: false },
      { answer: "7.5 meters", isCorrect: false },
      { answer: "8.66 meters", isCorrect: false },
    ],
  },
  {
    question:
      "A building casts a shadow of 30 meters long when the angle of elevation of the sun is 45°. What is the height of the building?",
    answerOptions: [
      { answer: "30 meters", isCorrect: true },
      { answer: "45 meters", isCorrect: false },
      { answer: "15 meters", isCorrect: false },
      { answer: "20 meters", isCorrect: false },
    ],
  },
  {
    question:
      "A tree's shadow is 10 meters long when the angle of elevation of the sun is 30°. What is the height of the tree?",
    answerOptions: [
      { answer: "5.77 meters", isCorrect: true },
      { answer: "10 meters", isCorrect: false },
      { answer: "8.66 meters", isCorrect: false },
      { answer: "7.5 meters", isCorrect: false },
    ],
  },
  {
    question:
      "A kite is flying at a height of 50 meters, and the string makes an angle of 40° with the horizontal. What is the length of the string?",
    answerOptions: [
      { answer: "77.66 meters", isCorrect: true },
      { answer: "50 meters", isCorrect: false },
      { answer: "38.29 meters", isCorrect: false },
      { answer: "60 meters", isCorrect: false },
    ],
  },
  {
    question:
      "A cyclist rides up a hill that makes an angle of 5° with the horizontal. If the cyclist travels 200 meters along the hill, how high does he ascend?",
    answerOptions: [
      { answer: "17.45 meters", isCorrect: true },
      { answer: "10 meters", isCorrect: false },
      { answer: "17 meters", isCorrect: false },
      { answer: "20 meters", isCorrect: false },
    ],
  },
  {
    question:
      "A satellite dish is tilted at an angle of 30° to receive signals from a satellite. If the dish has a diameter of 2 meters, what is the height of the dish's center from the ground?",
    answerOptions: [
      { answer: "1 meter", isCorrect: true },
      { answer: "1.73 meters", isCorrect: false },
      { answer: "2 meters", isCorrect: false },
      { answer: "0.5 meter", isCorrect: false },
    ],
  },
  {
    question:
      "A plane is flying at a height of 1000 meters. If the angle of depression to a point on the ground is 30°, how far is the plane from that point on the ground?",
    answerOptions: [
      { answer: "1732 meters", isCorrect: true },
      { answer: "1000 meters", isCorrect: false },
      { answer: "866 meters", isCorrect: false },
      { answer: "1500 meters", isCorrect: false },
    ],
  },
  {
    question:
      "A baseball is hit at an angle of 45° with the horizontal. If it travels 100 meters horizontally before hitting the ground, what is the maximum height it reaches?",
    answerOptions: [
      { answer: "50 meters", isCorrect: true },
      { answer: "70.7 meters", isCorrect: false },
      { answer: "100 meters", isCorrect: false },
      { answer: "35 meters", isCorrect: false },
    ],
  },
  {
    question:
      "A boat sails due east for 100 km and then turns north and sails for another 150 km. What is the straight-line distance from the starting point to the final point?",
    answerOptions: [
      { answer: "180.27 km", isCorrect: true },
      { answer: "200 km", isCorrect: false },
      { answer: "250 km", isCorrect: false },
      { answer: "170 km", isCorrect: false },
    ],
  },
  {
    question:
      "A construction worker needs to measure the height of a crane. He stands 50 meters from the base of the crane and measures the angle of elevation to the top of the crane as 35°. What is the height of the crane?",
    answerOptions: [
      { answer: "35 meters", isCorrect: true },
      { answer: "50 meters", isCorrect: false },
      { answer: "28 meters", isCorrect: false },
      { answer: "40 meters", isCorrect: false },
    ],
  },
  {
    question:
      "A person is standing at the top of a hill that makes an angle of 10° with the horizontal. If the height of the hill is 200 meters, what is the length of the slope?",
    answerOptions: [
      { answer: "1142 meters", isCorrect: true },
      { answer: "1000 meters", isCorrect: false },
      { answer: "200 meters", isCorrect: false },
      { answer: "212 meters", isCorrect: false },
    ],
  },
  {
    question:
      "A rope is tied from the top of a pole to a point on the ground 20 meters away from the base of the pole. If the angle between the rope and the ground is 60°, what is the height of the pole?",
    answerOptions: [
      { answer: "20√3 meters", isCorrect: true },
      { answer: "10 meters", isCorrect: false },
      { answer: "20 meters", isCorrect: false },
      { answer: "30 meters", isCorrect: false },
    ],
  },
  {
    question:
      "A triangular park has sides measuring 50 meters, 60 meters, and 70 meters. Find the area of the park using trigonometric methods.",
    answerOptions: [
      { answer: "1500 square meters", isCorrect: true },
      { answer: "1200 square meters", isCorrect: false },
      { answer: "1800 square meters", isCorrect: false },
      { answer: "2000 square meters", isCorrect: false },
    ],
  },
  {
    question:
      "A person is watching a ship from a lighthouse. If the angle of depression from the top of the lighthouse to the ship is 30° and the lighthouse is 100 meters tall, how far is the ship from the base of the lighthouse?",
    answerOptions: [
      { answer: "173.21 meters", isCorrect: true },
      { answer: "100 meters", isCorrect: false },
      { answer: "57.74 meters", isCorrect: false },
      { answer: "120 meters", isCorrect: false },
    ],
  },
  {
    question:
      "A ski slope makes an angle of 25° with the horizontal. If a skier travels 500 meters down the slope, how high is the skier above the base of the slope?",
    answerOptions: [
      { answer: "211.8 meters", isCorrect: true },
      { answer: "250 meters", isCorrect: false },
      { answer: "300 meters", isCorrect: false },
      { answer: "150 meters", isCorrect: false },
    ],
  },
  {
    question:
      "A satellite dish is designed to be tilted at an angle of 45° to intercept signals. If the dish's radius is 1.5 meters, how high is the center of the dish from the ground when tilted?",
    answerOptions: [
      { answer: "1.06 meters", isCorrect: true },
      { answer: "1.5 meters", isCorrect: false },
      { answer: "2 meters", isCorrect: false },
      { answer: "0.75 meters", isCorrect: false },
    ],
  },
  {
    question:
      "A person standing at a point on the ground measures the angle of elevation to the top of a 200-meter tall building as 30°. How far is the person from the base of the building?",
    answerOptions: [
      { answer: "346.41 meters", isCorrect: true },
      { answer: "200 meters", isCorrect: false },
      { answer: "115.47 meters", isCorrect: false },
      { answer: "173.21 meters", isCorrect: false },
    ],
  },
  {
    question:
      "A man is standing 60 meters away from a vertical tower. If the angle of elevation to the top of the tower is 40°, find the height of the tower.",
    answerOptions: [
      { answer: "50.55 meters", isCorrect: true },
      { answer: "60 meters", isCorrect: false },
      { answer: "70 meters", isCorrect: false },
      { answer: "40 meters", isCorrect: false },
    ],
  },
  {
    question:
      "A ramp is designed with an angle of 15° to the horizontal. If the ramp is 10 meters long, what is the height it reaches?",
    answerOptions: [
      { answer: "2.59 meters", isCorrect: true },
      { answer: "3 meters", isCorrect: false },
      { answer: "5 meters", isCorrect: false },
      { answer: "7.5 meters", isCorrect: false },
    ],
  },
  {
    question:
      "A person is observing a car from a point on a bridge. If the angle of depression to the car is 20° and the bridge is 25 meters above the ground, how far is the car from the base of the bridge?",
    answerOptions: [
      { answer: "68.64 meters", isCorrect: true },
      { answer: "50 meters", isCorrect: false },
      { answer: "100 meters", isCorrect: false },
      { answer: "45 meters", isCorrect: false },
    ],
  },
  {
    question:
      "A building casts a shadow of 60 meters at noon. If the angle of elevation of the sun is 30°, what is the height of the building?",
    answerOptions: [
      { answer: "34.64 meters", isCorrect: true },
      { answer: "60 meters", isCorrect: false },
      { answer: "40 meters", isCorrect: false },
      { answer: "30 meters", isCorrect: false },
    ],
  },
  {
    question: "The number of distinct values of sin^2 θ + cos^2 θ for any angle θ is:",
    answerOptions: [
      { answer: "2", isCorrect: false },
      { answer: "0", isCorrect: false },
      { answer: "1", isCorrect: true },
      { answer: "Infinity", isCorrect: false }
    ],
    correctAnswer: "1",
    difficulty: "Easy",
    category: "Quadratic equation"
  },
];


export default trigonometryquestions;
