import React from 'react';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { fireDB } from '../config/firebase'; // Ensure you import your Firestore instance
import { FaThumbsUp } from "react-icons/fa";

function Like({ questionId, userId, questions, setQuestions }) {
  const handleLike = async (questionId, userId) => {
    try {
      // Get the reference to the global quiz question document
      const questionRef = doc(fireDB, 'quizQuestions', questionId);
      const questionToUpdate = questions.find((q) => q.id === questionId);

      if (!questionToUpdate) {
        console.error('Question not found in trending list.');
        return;
      }

      // Toggle the like status and update the likes count
      const updatedLikes = questionToUpdate.likedByUser
        ? questionToUpdate.likes - 1
        : questionToUpdate.likes + 1;

      const updatedQuestions = questions.map((question) =>
        question.id === questionId
          ? {
              ...question,
              likedByUser: !question.likedByUser,
              likes: updatedLikes,
            }
          : question
      );

      // Update the state in the parent component
      setQuestions(updatedQuestions);

      // Update Firestore with the new like count in the global collection
      await updateDoc(questionRef, { likes: updatedLikes });
      console.log('Global question likes updated in Firestore.');

      // Get the reference to the user's saved questions
      const userSavedRef = doc(fireDB, 'userSavedQuestions', userId);

      // Fetch the user's saved questions to find the matching question
      const userSavedSnapshot = await getDoc(userSavedRef);
      if (userSavedSnapshot.exists()) {
        const userSavedQuestions = userSavedSnapshot.data().questions;
        console.log('User Saved Questions: ', userSavedQuestions);

        // Update the likes for the saved question in the user dashboard if it exists
        const updatedUserSavedQuestions = userSavedQuestions.map((question) =>
          question.id === questionId
            ? {
                ...question,
                likedByUser: !question.likedByUser,
                likes: updatedLikes,
              }
            : question
        );

        console.log('Updated Saved Questions: ', updatedUserSavedQuestions);

        // Update the Firestore document for the user's saved questions
        await updateDoc(userSavedRef, { questions: updatedUserSavedQuestions });
        console.log('User saved questions updated in Firestore.');
      } else {
        console.error('User saved questions not found.');
      }
    } catch (error) {
      console.error('Error updating likes:', error);
    }
  };

  // Get the current question from the questions array
  const currentQuestion = questions.find((q) => q.id === questionId);

  return (
    <div onClick={() => handleLike(questionId, userId)} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
      <FaThumbsUp />
      <span style={{ marginLeft: '5px' }}>{currentQuestion ? currentQuestion.likes : 0}</span> {/* Display like count */}
    </div>
  );
}

export default Like;
