const thermodynamicquestions = [
    {
        question:"Heat content of a system is called :",
        answerOptions: [
            {answer: 'internal energy', isCorrect: false},
            {answer: 'enthalpy', isCorrect: true},
            {answer: 'free energy', isCorrect: false},
            {answer: 'entropy', isCorrect: false},
        ],
    }, {
        question:"Thermochemistry is the study of :",
        answerOptions: [
            {answer: 'heat changes', isCorrect: true},
            {answer: 'entropy change', isCorrect: false},
            {answer: 'free energy change', isCorrect: false},
            {answer: 'none of these', isCorrect: false},
        ],
    },  {
        question:"A process which is proceeding infinnitesimally slowly is called  :",
        answerOptions: [
            {answer: 'irreversible', isCorrect: false},
            {answer: 'reversible', isCorrect: true},
            {answer: 'adiabatic', isCorrect: false},
            {answer: 'isothermal', isCorrect: false},
        ],
    },  {
        question:"If system and surrounding is > 0, the process is :",
        answerOptions: [
            {answer: 'reversible', isCorrect: true},
            {answer: 'irreversible', isCorrect: false},
            {answer: 'both', isCorrect: false},
            {answer: 'none of these', isCorrect: false},
        ],
    },  {
        question:"When a system returns to its original position after a series of changes is called :",
        answerOptions: [
            {answer: 'complite process', isCorrect: false},
            {answer: 'cyclic process', isCorrect: true},
            {answer: 'non cyclic process', isCorrect: false},
            {answer: 'none of these', isCorrect: false},
        ],
    },  {
        question:"The equations represent the effect of temperature change on the heat of reaction is called :",
        answerOptions: [
            {answer: 'Arrhenius eqn.', isCorrect: false},
            {answer: 'Lenz law', isCorrect: false},
            {answer: 'Faraday law', isCorrect: false},
            {answer: 'Kirchhoff eqn.', isCorrect: true},
           
        ],
    }, {
        question:"Carnot theorem is ecpressed by :",
        answerOptions: [
            {answer: 'μ = T2 - T1 / T2', isCorrect: true},
            {answer: 'μ = T2 + T1 / T2', isCorrect: false},
            {answer: 'μ = T1 - T1 / T2', isCorrect: false},
            {answer: 'μ = T2 - T1 / T1', isCorrect: false},
        ],
    },  {
        question:"Identify the intensive quantity :",
        answerOptions: [
            {answer: 'enthalpy', isCorrect: true},
            {answer: 'temperature', isCorrect: false},
            {answer: 'volume', isCorrect: false},
            {answer: 'mass', isCorrect: false},
        ],
    },  {
        question:"Standred heat of formation of an elements is  :",
        answerOptions: [
            {answer: '0', isCorrect: true},
            {answer: '100 kJ', isCorrect: false},
            {answer: '200 kJ', isCorrect: false},
            {answer: '∞', isCorrect: false},
        ],
    },  {
        question:"The sign convention use for irreversible process :",
        answerOptions: [
            {answer: '>', isCorrect: false},
            {answer: '<', isCorrect: true},
            {answer: '=', isCorrect: false},
            {answer: 'none of the above', isCorrect: false},
        ],
    },  {
        question:"The least random state of water is :",
        answerOptions: [
            {answer: 'ice', isCorrect: true},
            {answer: 'liquid', isCorrect: false},
            {answer: 'stream', isCorrect: false},
            {answer: 'all', isCorrect: false},
        ],
    },  {
        question:"For the reaction N2 + 3H2 -> 2NH3 is equal to :",
        answerOptions: [
            {answer: 'qv - 2RT', isCorrect: true},
            {answer: 'qv + 2RT', isCorrect: false},
            {answer: 'qv - RT', isCorrect: false},
            {answer: 'qv + RT', isCorrect: false},
        ],
    },  {
        question:"What is units of entropy :",
        answerOptions: [
            {answer: 'Kelvin (J/K)', isCorrect: true},
            {answer: 'Kelvin (JK)', isCorrect: false},
            {answer: 'Kelvin (J/K)^-1', isCorrect: false},
            {answer: 'Kelvin (J)', isCorrect: false},
        ],
    },  {
        question:"When a liquid crystallises processes into a solid, then entropy will be :",
        answerOptions: [
            {answer: 'increase', isCorrect: false},
            {answer: 'decrease', isCorrect: true},
            {answer: 'remain constant', isCorrect: false},
            {answer: 'can not be said', isCorrect: false},
        ],
    },  {
        question:"Internal energy is a state function but which are not :",
        answerOptions: [
            {answer: 'heat', isCorrect: false},
            {answer: 'work', isCorrect: false},
            {answer: 'heat and work', isCorrect: true},
            {answer: 'all are not', isCorrect: false},
        ],
    },  {
        question:"At a process of isothermal compression of a liquid, entropy will be :",
        answerOptions: [
            {answer: 'increase', isCorrect: false},
            {answer: 'decrease', isCorrect: false},
            {answer: 'almost constant', isCorrect: true},
            {answer: 'can not be said', isCorrect: false},
        ],
    },  {
        question:"The limit T -> 0, the entropy of a crystalline solid is approx. equal to :",
        answerOptions: [
            {answer: '1/2 Cp', isCorrect: false},
            {answer: '2/3 Cp', isCorrect: false},
            {answer: '1/3 Cp', isCorrect: true},
            {answer: '1/5 Cp', isCorrect: false},
        ],
    },  {
        question:"The states of the system are composition of :",
        answerOptions: [
            {answer: 'pressure', isCorrect: false},
            {answer: 'volume', isCorrect: false},
            {answer: 'temperature', isCorrect: false},
            {answer: 'all of these', isCorrect: true},
        ],
    },  {
        question:"A process in which a system comes to its initial position after a series of operations is called :",
        answerOptions: [
            {answer: 'reversible', isCorrect: false},
            {answer: 'cyclic', isCorrect: true},
            {answer: 'adiabatic', isCorrect: false},
            {answer: 'can not be said', isCorrect: false },
        ],
    },  {
        question:"The relationship between decrese in Gibbs free energy and electric energy is :",
        answerOptions: [
            {answer: '- del(G) = nF Ecell', isCorrect: true},
            {answer: 'del(G) = nF Ecell', isCorrect: false},
            {answer: '- del(G) = - nF Ecell', isCorrect: false},
            {answer: '- del(G) = nF In Ecell', isCorrect: false},
        ],
    },  {
        question:"Gibbs-Helmholt equation is :",
        answerOptions: [
            {answer: '- del(G) = nF Ecell', isCorrect: false},
            {answer: '- del(G) = nF Ecell', isCorrect: false},
            {answer: '- del(G) = nF Ecell', isCorrect: false},
            {answer: 'del(G) = - nRT In Ecell', isCorrect: true},
        ],
    },  {
        question:"Which of the following process is silence about the feasibility of a process :",
        answerOptions: [
            {answer: 'zero low of thermodynamic', isCorrect: false},
            {answer: 'first low of thermodynamic', isCorrect: true},
            {answer: 'second low of thermodynamic', isCorrect: false},
            {answer: 'third low of thermodynamic', isCorrect: false},
        ],
    },  {
        question:"When temperature of crystalline sloid is raised from 0 K to 115 K, then processes of entropy will be : (Xnetous chellange)",
        answerOptions: [
            {answer: 'increase', isCorrect: true},
            {answer: 'decrease', isCorrect: false},
            {answer: 'reamin same', isCorrect: false},
            {answer: 'data insufficient', isCorrect: false},
        ],
    },  {
        question:"The mathematical form of the first law of thermodynamic :",
        answerOptions: [
            {answer: 'dq = dU = dw', isCorrect: false},
            {answer: 'dq = dU - dw', isCorrect: false},
            {answer: 'dq = dU + dw', isCorrect: true},
            {answer: 'dq - dU = dw', isCorrect: false},
        ],
    },  {
        question:"If the system is uniform throughout is called  :",
        answerOptions: [
            {answer: 'homogenous', isCorrect: true},
            {answer: 'hetrogenious', isCorrect: false},
            {answer: 'open system', isCorrect: false},
            {answer: 'close system', isCorrect: false},
        ],
    },  {
        question:"The first law of thermodynamic is law of :",
        answerOptions: [
            {answer: 'enthalpy', isCorrect: false},
            {answer: 'free energy', isCorrect: false},
            {answer: 'conservation of energy', isCorrect: true},
            {answer: 'none of these', isCorrect: false},
        ],
    },  {
        question:"Endothermic reaction is one in which :",
        answerOptions: [
            {answer: 'heat is convert', isCorrect: false},
            {answer: 'heat is absorbed', isCorrect: true},
            {answer: 'heat is out', isCorrect: false},
            {answer: 'none of these', isCorrect: false},
        ],
    },  {
        question:"The energy of the universe is :",
        answerOptions: [
            {answer: 'increase', isCorrect: false},
            {answer: 'decrease', isCorrect: false},
            {answer: 'constant', isCorrect: true},
            {answer: 'can not be said', isCorrect: false},
        ],
    },  {
        question:"100 g of ice at 0°C was added to an insulatd beaker containing 150 g of water at 100°C. Then calculate the final temperature of the system :",
        answerOptions: [
            {answer: '26°C', isCorrect: false},
            {answer: '27°C', isCorrect: false},
            {answer: '28°C', isCorrect: true},
            {answer: '29°C', isCorrect: false},
        ],
    },  {
        question:"Nernst heat theorem explain about :",
        answerOptions: [
            {answer: 'entropy and free-energy change', isCorrect: true},
            {answer: 'entropy and free-energy constant', isCorrect: false},
            {answer: 'entropy and free-energy increase', isCorrect: false},
            {answer: 'none of the above', isorrect: false},
        ],
    },
]

export default thermodynamicquestions;