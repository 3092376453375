import React, { useState, useEffect } from 'react';
import { fireDB, auth } from '../config/firebase';
import { collection, getDocs, addDoc, doc, updateDoc, deleteDoc, getDoc } from 'firebase/firestore';
import '../pagesStyle/discussionrooms.css';

const DiscussionRooms = () => {
  const [rooms, setRooms] = useState([]);
  const [newRoomTopic, setNewRoomTopic] = useState('');
  const [currentRoom, setCurrentRoom] = useState(null);
  const [messages, setMessages] = useState('');
  const [roomMessages, setRoomMessages] = useState([]);
  const [creatorNames, setCreatorNames] = useState({});
  const [currentUser, setCurrentUser] = useState(null);
  const [anonymousName, setAnonymousName] = useState('');

  // Generate anonymous name when user isn't authenticated
  useEffect(() => {
    if (!auth.currentUser) {
      setAnonymousName(`User-${Math.floor(Math.random() * 1000)}`);
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [roomsSnapshot, user] = await Promise.all([
          getDocs(collection(fireDB, 'rooms')),
          auth.currentUser ? getDoc(doc(fireDB, 'users', auth.currentUser.uid)) : null
        ]);

        if (user && user.exists()) {
          setCurrentUser({ ...user.data(), userId: auth.currentUser.uid });
        }

        const roomsList = roomsSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        setRooms(roomsList);

        const creatorIds = [...new Set(roomsList.map((room) => room.creatorId))];
        const creatorPromises = creatorIds.map(async (creatorId) => {
          if (creatorId) {
            const creatorDoc = await getDoc(doc(fireDB, 'users', creatorId));
            if (creatorDoc.exists()) {
              return { id: creatorId, name: creatorDoc.data().name };
            }
          }
        });

        const creators = await Promise.all(creatorPromises);
        const newCreatorNames = creators.reduce((acc, creator) => {
          if (creator) acc[creator.id] = creator.name;
          return acc;
        }, {});

        setCreatorNames(newCreatorNames);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  // Create a new room
  const createRoom = async (e) => {
    e.preventDefault();
    if (!newRoomTopic || !currentUser) return;

    try {
      const roomRef = await addDoc(collection(fireDB, 'rooms'), {
        topic: newRoomTopic,
        creatorId: currentUser.userId,
        createdAt: new Date(),
        messages: [],
      });
      setRooms([...rooms, { id: roomRef.id, topic: newRoomTopic, creatorId: currentUser.userId }]);
      setNewRoomTopic('');
    } catch (error) {
      console.error('Error creating room:', error);
    }
  };

  // Join a room and fetch messages
  const joinRoom = async (roomId) => {
    try {
      const roomRef = doc(fireDB, 'rooms', roomId);
      const roomSnapshot = await getDoc(roomRef);

      if (roomSnapshot.exists()) {
        setCurrentRoom({ id: roomSnapshot.id, ...roomSnapshot.data() });
        setRoomMessages(roomSnapshot.data().messages || []);
      }
    } catch (error) {
      console.error('Error joining room:', error);
    }
  };

  // Send a message to the room
  const sendMessage = async (e) => {
    e.preventDefault();
    if (!messages || !currentRoom || (!currentUser && !anonymousName)) return;

    const senderName = currentUser ? creatorNames[currentUser.userId] : anonymousName;
    const newMessage = { text: messages, userId: currentUser ? currentUser.userId : anonymousName, sender: senderName, time: new Date() };
    const updatedMessages = [...roomMessages, newMessage];

    try {
      const roomRef = doc(fireDB, 'rooms', currentRoom.id);
      await updateDoc(roomRef, { messages: updatedMessages });
      setRoomMessages(updatedMessages);
      setMessages('');
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  // Update room topic
  const updateRoomTopic = async (roomId, newTopic) => {
    if (!newTopic) return;

    // Check if the current user is an admin or the room creator
    if (currentUser && (currentUser.role === 'admin' || currentUser.userId === roomId.creatorId)) {
      try {
        const roomRef = doc(fireDB, 'rooms', roomId);
        await updateDoc(roomRef, { topic: newTopic });
        setRooms(rooms.map(room => room.id === roomId ? { ...room, topic: newTopic } : room));
      } catch (error) {
        console.error('Error updating room topic:', error);
      }
    } else {
      alert("You are not authorized to edit this room.");
    }
  };

  // Delete a room
  const deleteRoom = async (roomId) => {
    if (!currentUser) {
      alert("You must be logged in to delete a room.");
      return;
    }

    // Check if the current user is either an admin or the room creator
    if (currentUser.role === 'admin' || currentUser.userId === roomId.creatorId) {
      try {
        const roomRef = doc(fireDB, 'rooms', roomId);
        await deleteDoc(roomRef);
        setRooms(rooms.filter(room => room.id !== roomId));
        setCurrentRoom(null);  // Reset current room if deleted
      } catch (error) {
        console.error('Error deleting room:', error);
      }
    } else {
      alert("You are not authorized to delete this room.");
    }
  };

  // Delete a message
  const deleteMessage = async (messageIndex) => {
    const updatedMessages = roomMessages.filter((_, index) => index !== messageIndex);

    try {
      const roomRef = doc(fireDB, 'rooms', currentRoom.id);
      await updateDoc(roomRef, { messages: updatedMessages });
      setRoomMessages(updatedMessages);
    } catch (error) {
      console.error('Error deleting message:', error);
    }
  };

  // Edit a message
  const editMessage = async (messageIndex) => {
    const newText = prompt("Edit your message:", roomMessages[messageIndex].text);
    if (newText === null || newText === "") return;

    const updatedMessages = [...roomMessages];
    updatedMessages[messageIndex].text = newText;

    try {
      const roomRef = doc(fireDB, 'rooms', currentRoom.id);
      await updateDoc(roomRef, { messages: updatedMessages });
      setRoomMessages(updatedMessages);
    } catch (error) {
      console.error('Error editing message:', error);
    }
  };

  return (
    <div className="discussion-container">
      <h2>Discussion Rooms</h2>
      
      {/* Create New Room */}
      <form onSubmit={createRoom}>
        <input
          type="text"
          value={newRoomTopic}
          onChange={(e) => setNewRoomTopic(e.target.value)}
          placeholder="Enter room topic"
          className="input-field"
        />
        <button type="submit" className="create-room-btn">Create Room</button>
      </form>

      {/* List of Available Rooms */}
      <h3>Available Rooms</h3>
      <ul className="rooms-list">
        {rooms.map((room) => (
          <li key={room.id} onClick={() => joinRoom(room.id)}>
            {room.topic} - Created by {creatorNames[room.creatorId] || 'Unknown'}
            {currentUser && (currentUser.role === 'admin' || currentUser.userId === room.creatorId) && (
              <>
                <button onClick={(e) => { e.stopPropagation(); updateRoomTopic(room.id, prompt("New topic:", room.topic)); }} className="edit-btn">Edit</button>
                <button onClick={(e) => { e.stopPropagation(); deleteRoom(room.id); }} className="delete-btn">Delete</button>
              </>
            )}
          </li>
        ))}
      </ul>

      {/* Chat Room Section */}
      {currentRoom && (
        <div className="chat-room">
          <h3>{currentRoom.topic}</h3>
          <form onSubmit={sendMessage}>
            <input
              type="text"
              value={messages}
              onChange={(e) => setMessages(e.target.value)}
              placeholder="Type a message"
              className="input-field"
            />
            <button type="submit" className="send-message-btn">Send</button>
          </form>

          <div className="messages-list">
            <h4>Messages:</h4>
            <ul>
              {roomMessages.map((msg, index) => (
                <li key={index}>
                  <strong>{msg.sender}:</strong> {msg.text} 
                  <span className="timestamp">{new Date(msg.time.seconds * 1000).toLocaleString()}</span>
                  {currentUser && (currentUser.role === 'admin' || currentUser.userId === msg.userId) && (
                    <>
                      <button onClick={() => deleteMessage(index)} className="delete-msg-btn">Delete</button>
                      <button onClick={() => editMessage(index)} className="edit-msg-btn">Edit</button>
                    </>
                  )}
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default DiscussionRooms;
