const reactionintermediatequestions = [
    {
        question:"The process of breaking of covalent bonds present in organic molecules :",
        answerOptions: [
            {answer: 'bond length', isCorrect: false},
            {answer: 'bond fission', isCorrect: true},
            {answer: 'bond fusion', isCorrect: false},
            {answer: 'bond energy', isCorrect: false},
        ],
    },  {
        question:"Does more energy needed for homolysis than for heterolysis bond fission. :",
        answerOptions: [
            {answer: 'no', isCorrect: false},
            {answer: 'may be', isCorrect: false},
            {answer: 'yes', isCorrect: true},
            {answer: 'can not be said', isCorrect: false},
        ],
    }, {
        question:"Nucleophile are species that have :",
        answerOptions: [
            {answer: 'lone pair of e^-', isCorrect: true},
            {answer: 'gain of e^-', isCorrect: false},
            {answer: 'neutral', isCorrect: false},
            {answer: 'can not be said ', isCorrect: false},
        ],
    },  {
        question:"Ambident nucleophiles have two nucleophilic centres, one of which is   :",
        answerOptions: [
            {answer: 'positively charged and neutral', isCorrect: false},
            {answer: 'negatively and positively charged', isCorrect: false},
            {answer: 'negatively charged only', isCorrect: false},
            {answer: 'negatively charged and neutral', isCorrect: true},
        ],
    },  {
        question:"The stability is decided by :",
        answerOptions: [
            {answer: 'resonance', isCorrect: false},
            {answer: 'hyperconjugation', isCorrect: false},
            {answer: '+I effect', isCorrect: false},
            {answer: 'all of these', isCorrect: true},
        ],
    },  {
        question:"Carbanion is anion having a negatively charged carbon atom with  :",
        answerOptions: [
            {answer: '3 electrons', isCorrect: false},
            {answer: '5 electrons', isCorrect: false},
            {answer: '8 electrons', isCorrect: true},
            {answer: '10 electrons', isCorrect: false},
        ],
    }, {
        question:"Which reaction carbens shows :",
        answerOptions: [
            {answer: 'Addition with alkene', isCorrect: false},
            {answer: 'Insertion reaction', isCorrect: false},
            {answer: 'Ring expansion reaction', isCorrect: false},
            {answer: 'All of these', isCorrect: true},
        ],
    },  {
        question:"One C-atom X attached to benzene at top in presence of NaNH2 or KNH2 and St. Base, what will be the product :",
        answerOptions: [
            {answer: 'benzyne', isCorrect: false},
            {answer: 'X^-1', isCorrect: false},
            {answer: 'benzyne and X^-1', isCorrect: true},
            {answer: 'can not be said', isCorrect: false},
        ],
    },  {
        question:"It is shown by electron releasing species :",
        answerOptions: [
            {answer: '-I', isCorrect: false},
            {answer: '+I', isCorrect: true},
            {answer: '1/-I', isCorrect: false},
            {answer: '1/+I', isCorrect: false},
        ],
    }, {
        question:"Stability of Carbocations ∝:",
        answerOptions: [
            {answer: '+I', isCorrect: true},
            {answer: '-I', isCorrect: false},
            {answer: 'both of these', isCorrect: false},
            {answer: 'can not be said', isCorrect: false},
        ],
    },  {
        question:"Resonance energy ∝ :",
        answerOptions: [
            {answer: '1/reactivity', isCorrect: false},
            {answer: 'stability', isCorrect: false},
            {answer: 'no. of canonical structure', isCorrect: false},
            {answer: 'all of these', isCorrect: true},
        ],
    }, {
        question:"Hyper Conjugation is called  :",
        answerOptions: [
            {answer: 'π conjugation', isCorrect: false},
            {answer: 'σ conjugation', isCorrect: false},
            {answer: 'σ-π conjugation', isCorrect: true},
            {answer: 'none of these', isCorrect: false},
        ],
    }, {
        question:"Which of the following has the highest nucleophi-licity :",
        answerOptions: [
            {answer: 'F-', isCorrect: false},
            {answer: 'OH-', isCorrect: false},
            {answer: 'CH3-', isCorrect: true},
            {answer: 'NH2-', isCorrect: false},
        ],
    }, {
        question:"2–methyl but–2–ene is less reactive than but–2–ene, because of :",
        answerOptions: [
            {answer: 'resonance', isCorrect: false},
            {answer: 'hyperconjugation', isCorrect: true},
            {answer: 'electromeric effect', isCorrect: false},
            {answer: 'inductive effect', isCorrect: false},
        ],
    },  {
        question:"Resonance energy per benzene ring is maximum for :",
        answerOptions: [
            {answer: ' naphthalene', isCorrect: false},
            {answer: ' anthracene', isCorrect: false},
            {answer: ' benzene', isCorrect: true},
            {answer: ' phenanthrene', isCorrect: false},
        ],
    },  {
        question:"The kind of delocalization involving sigma bond orbitals is called  : ",
        answerOptions: [
            {answer: 'inductive effect', isCorrect: false},
            {answer: 'hyperconjugation effect', isCorrect: true},
            {answer: 'electrometric effect', isCorrect: false},
            {answer: 'mesomeric effect', isCorrect: true},
        ],
    },  {
        question:"Which of the following carbon atoms is most electronegative, CH3 - CH2 - C ≡ CH  :",
        answerOptions: [
            {answer: '9σ and 9π', isCorrect: false},
            {answer: '5σ and 9π', isCorrect: false},
            {answer: '9σ and 7π', isCorrect: true},
            {answer: '5σ and 8π', isCorrect: false},
        ],
    }, {
        question:"Free radicals are involved in mechanism of :",
        answerOptions: [
            {answer: 'Kharash effect', isCorrect: false},
            {answer: 'Cracking of alkanes', isCorrect: false},
            {answer: 'Halogenation of alkanes', isCorrect: false},
            {answer: 'can not be said', isCorrect: true},
        ],
    },  {
        question:"Which of the following is the strongest nucleophile :",
        answerOptions: [
            {answer: 'NH2-', isCorrect: false},
            {answer: 'CH3-CH2-', isCorrect: true},
            {answer: 'HC≡C', isCorrect: false},
            {answer: 'H2C=CH-', isCorrect: false},
        ],
    },  {
        question:"Which of the following is not polar  :",
        answerOptions: [
            {answer: 'tert–Butyl free radical', isCorrect: false},
            {answer: 'tert–Butyl carbocation', isCorrect: false},
            {answer: 'tert–Butyl carbanion', isCorrect: true},
            {answer: 'allyl cabanion', isCorrect: false},
        ],
    }, 
]

export default reactionintermediatequestions;