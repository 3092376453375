import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, signInWithRedirect, getRedirectResult, onAuthStateChanged } from 'firebase/auth';
import { getFirestore, serverTimestamp, doc, addDoc, getDoc, updateDoc, getDocs, collection, arrayUnion, arrayRemove } from 'firebase/firestore';
import { getStorage } from 'firebase/storage'; // Fixed import for Firebase Storage


// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBlACPLuSFOPtgYCMLrZBpkuAgibkETuOU",
  authDomain: "intelligencyhunt-4a9a9.firebaseapp.com",
  databaseURL: "https://intelligencyhunt-4a9a9-default-rtdb.firebaseio.com",
  projectId: "intelligencyhunt-4a9a9",
  storageBucket: "intelligencyhunt-4a9a9.appspot.com",
  messagingSenderId: "583990321374",
  appId: "1:583990321374:web:3d36f660c952501bd2a24f"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase services
const auth = getAuth(app);
const provider = new GoogleAuthProvider();
const fireDB = getFirestore(app);
const storage = getStorage(app); // Initialize Firebase Storage

export { auth, serverTimestamp, provider, fireDB, storage, doc, addDoc, getDoc, updateDoc, getDocs, collection, arrayUnion, arrayRemove, signInWithRedirect, getRedirectResult, onAuthStateChanged };

 









