import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import {
  collection,
  doc,
  setDoc,
  updateDoc,
  deleteDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { fireDB } from "../config/firebase";
import "../componentsStyle/postVote.css";

function PostVote({ user }) {
  const [question, setQuestion] = useState("");
  const [loading, setLoading] = useState(false);
  const [votes, setVotes] = useState([]);
  const [editingVoteId, setEditingVoteId] = useState(null);
  const [editedQuestion, setEditedQuestion] = useState("");

  useEffect(() => {
    if (user) {
      fetchVotes();
    }
  }, [user]);

  const fetchVotes = async () => {
    try {
      const votesQuery = query(
        collection(fireDB, "voteQuestions"),
        where("userId", "==", user.uid)
      );
      const querySnapshot = await getDocs(votesQuery);
      const votesList = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setVotes(votesList);
    } catch (error) {
      console.error("Error fetching votes:", error);
      toast.error("Failed to fetch votes.");
    }
  };

  const handlePostVote = async (e) => {
    e.preventDefault();

    if (!question) {
      toast.error("Please enter a question for the vote");
      return;
    }

    setLoading(true);

    try {
      const auth = getAuth();
      const currentUser = auth.currentUser;

      const voteRef = doc(collection(fireDB, "voteQuestions"));
      await setDoc(voteRef, {
        question,
        options: ["Yes", "No"],
        Yes: 0,
        No: 0,
        createdAt: new Date(),
        userId: currentUser.uid,
        username: currentUser.displayName || "Anonymous", // Store username with each question
      });

      toast.success("Vote question posted successfully!");
      setQuestion("");
      fetchVotes();
    } catch (error) {
      console.error("Error posting vote question:", error);
      toast.error("Failed to post vote question. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleEditVote = (vote) => {
    setEditingVoteId(vote.id);
    setEditedQuestion(vote.question);
  };

  const handleUpdateVote = async () => {
    if (!editedQuestion) {
      toast.error("Please enter a question for the vote");
      return;
    }

    try {
      const voteRef = doc(fireDB, "voteQuestions", editingVoteId);
      await updateDoc(voteRef, { question: editedQuestion });

      toast.success("Vote question updated successfully!");
      setEditingVoteId(null);
      fetchVotes();
    } catch (error) {
      console.error("Error updating vote question:", error);
      toast.error("Failed to update vote question. Please try again.");
    }
  };

  const handleDeleteVote = async (voteId) => {
    try {
      const voteRef = doc(fireDB, "voteQuestions", voteId);
      await deleteDoc(voteRef);

      toast.success("Vote question deleted successfully!");
      fetchVotes();
    } catch (error) {
      console.error("Error deleting vote question:", error);
      toast.error("Failed to delete vote question. Please try again.");
    }
  };

  return (
    <div className="post-vote-container">
      <h3>Post a New Vote Question</h3>
      <form onSubmit={handlePostVote} className="post-vote-form">
        <div className="form-group">
          <label htmlFor="question">Vote Question</label>
          <input
            type="text"
            id="question"
            value={question}
            onChange={(e) => setQuestion(e.target.value)}
            placeholder="Enter your voting question"
          />
        </div>
        <button
          type="submit"
          className="post-button"
          disabled={loading}
          style={{ opacity: loading ? 0.6 : 1 }}
        >
          {loading ? "Posting..." : "Post Vote Question"}
        </button>
      </form>

      <div className="posted-votes">
        <h3>My Posted Votes</h3>
        <ul>
          {votes.map((vote) => (
            <li key={vote.id} className="vote-item">
              {editingVoteId === vote.id ? (
                <div>
                  <input
                    type="text"
                    value={editedQuestion}
                    onChange={(e) => setEditedQuestion(e.target.value)}
                  />
                  <button onClick={handleUpdateVote}>Save</button>
                  <button onClick={() => setEditingVoteId(null)}>Cancel</button>
                </div>
              ) : (
                <div>
                  <p>{vote.question}</p>
                  <button onClick={() => handleEditVote(vote)}>Edit</button>
                  <button onClick={() => handleDeleteVote(vote.id)}>
                    Delete
                  </button>
                </div>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default PostVote;
