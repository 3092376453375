import React from "react";
import '../pagesStyle/disclaimer.css';

const Disclaimer = () => {
    return (
        <div className="disclaimer-page">
            <div className="disclaimer-container">
                <h1 className="disclaimer-title">Disclaimer</h1>
                <p className="disclaimer-intro">
                    Welcome to our quiz social networking website! Please read this disclaimer carefully before using our platform. By accessing or using the website, you agree to be bound by the terms and conditions outlined in this disclaimer. If you do not agree to these terms, you should not use our platform.
                </p>

                <section className="disclaimer-section">
                    <h2 className="section-title">General Information</h2>
                    <p>
                        The content on this platform, including but not limited to quizzes, posts, and comments, is intended for educational and entertainment purposes only. We make no representations or warranties of any kind, express or implied, about the accuracy, completeness, reliability, suitability, or availability of any information provided on this platform. Any reliance you place on such information is strictly at your own risk.
                    </p>
                </section>

                <section className="disclaimer-section">
                    <h2 className="section-title">No Professional Advice</h2>
                    <p>
                        The quizzes and content provided on this platform are not meant to replace professional advice in any field, including but not limited to academic, medical, legal, financial, or psychological matters. Always seek the guidance of a qualified professional before making any decisions based on information obtained from this platform. The creators and administrators of this platform are not liable for any outcomes arising from the use of information on the site.
                    </p>
                </section>

                <section className="disclaimer-section">
                    <h2 className="section-title">Accuracy of Information</h2>
                    <p>
                        While we strive to provide accurate and up-to-date information, we cannot guarantee that all content, including quiz questions and answers, will be error-free or current at all times. We reserve the right to modify or update any information, including quiz content, without prior notice. Users should verify any information found on the platform before relying on it.
                    </p>
                </section>

                <section className="disclaimer-section">
                    <h2 className="section-title">User-Generated Content</h2>
                    <p>
                        This platform allows users to post content, including but not limited to quizzes, comments, and interactions such as likes, shares, and retweets. The views and opinions expressed in user-generated content are solely those of the authors and do not necessarily reflect the official stance of the platform. We do not endorse, validate, or take responsibility for the accuracy or appropriateness of any user-generated content. Users are encouraged to engage in respectful and constructive interactions.
                    </p>
                </section>

                <section className="disclaimer-section">
                    <h2 className="section-title">External Links Disclaimer</h2>
                    <p>
                        This platform may contain links to third-party websites or services that are not owned or controlled by us. We have no control over the content, privacy policies, or practices of any third-party websites or services. We do not assume any responsibility for these external sites and recommend that you review their terms and policies before engaging with them.
                    </p>
                </section>

                <section className="disclaimer-section">
                    <h2 className="section-title">Limitation of Liability</h2>
                    <p>
                        In no event shall the creators, administrators, or affiliates of this platform be liable for any direct, indirect, incidental, consequential, or special damages, including but not limited to loss of data, profits, or other intangible losses, arising out of or in connection with your use of or inability to use the platform. Your use of this platform is at your own risk.
                    </p>
                </section>

                <section className="disclaimer-section">
                    <h2 className="section-title">Changes to This Disclaimer</h2>
                    <p>
                        We reserve the right to modify this disclaimer at any time without prior notice. Any changes to the disclaimer will be posted on this page, and the updated version will be effective as of the date of posting. Your continued use of the platform after the posting of changes constitutes your acceptance of the modified disclaimer.
                    </p>
                </section>

            </div>
        </div>
    );
};

export default Disclaimer;
