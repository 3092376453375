const nobelgasquestions = [
    {
        question:"All noble gases are :",
        answerOptions: [
            {answer: 'monoatomic', isCorrect: true},
            {answer: 'diatomic', isCorrect: false},
            {answer: 'triatomic', isCorrect: false},
            {answer: 'none of these', isCorrect: false},
        ],
    }, {
        question:"Noble gases are more soluble in :",
        answerOptions: [
            {answer: 'Air', isCorrect: false},
            {answer: 'N2', isCorrect: false},
            {answer: 'Water', isCorrect: true},
            {answer: 'Can not be said', isCorrect: false},
        ],
    },  {
        question:" The solubility of noble gases increases with increase in :",
        answerOptions: [
            {answer: 'atomic number', isCorrect: true},
            {answer: 'atomic mass', isCorrect: false},
            {answer: 'atomic radii', isCorrect: false},
            {answer: 'can not be said', isCorrect: false},
        ],
    },  {
        question:" As polarizabillity of an atom depends on its  :",
        answerOptions: [
            {answer: 'nature', isCorrect: false},
            {answer: 'reactivity', isCorrect: false},
            {answer: 'mass', isCorrect: false},
            {answer: 'size', isCorrect: true},
        ],
    },  {
        question:"How many form has liquid helium  :",
        answerOptions: [
            {answer: 'One', isCorrect: false},
            {answer: 'Two', isCorrect: true},
            {answer: 'Three', isCorrect: false},
            {answer: 'Four', isCorrect: false},
        ],
    },  {
        question:"Xenon reacts directly with :",
        answerOptions: [
            {answer: 'helium', isCorrect: false},
            {answer: 'neon', isCorrect: false},
            {answer: 'fluorine', isCorrect: true},
            {answer: 'can not be said', isCorrect: false},
           
        ],
    }, {
        question:"Which of the following noble gas is not found in atmosphere :",
        answerOptions: [
            {answer: 'He', isCorrect: false},
            {answer: 'Ar', isCorrect: false},
            {answer: 'Xe', isCorrect: false},
            {answer: 'Rn', isCorrect: true},
        ],
    },  {
        question:"The hybridization of Xe in XeO2F2:",
        answerOptions: [
            {answer: 'sp^3', isCorrect: false},
            {answer: 'Sd^3', isCorrect: false},
            {answer: 'sp^3d', isCorrect: true},
            {answer: 'sp^3d²', isCorrect: false},
        ],
    },  {
        question:" Zero point energy of He is :",
        answerOptions: [
            {answer: 'low', isCorrect: false},
            {answer: 'medium', isCorrect: false},
            {answer: 'high', isCorrect: false},
            {answer: 'very high', isCorrect: true},
        ],
    },  {
        question:" The main use of He is as the cryoscopic liquid :",
        answerOptions: [
            {answer: 'yes', isCorrect: true},
            {answer: 'may be', isCorrect: false},
            {answer: 'no', isCorrect: false},
            {answer: 'can not be said', isCorrect: false},
        ],
    },  {
        question:" He(II) is :",
        answerOptions: [
            {answer: 'insultor', isCorrect: false},
            {answer: 'semi conductor', isCorrect: true},
            {answer: 'conductor', isCorrect: false},
            {answer: 'super conductor', isCorrect: false},
        ],
    },  {
        question:"Xe + PtF6 -----> :",
        answerOptions: [
            {answer: 'yellow solid', isCorrect: true},
            {answer: 'red solid', isCorrect: false},
            {answer: 'green solid', isCorrect: false},
            {answer: 'can not ba said', isCorrect: false},
        ],
    },  {
        question:"Which of the following noble gas is used in G – M counter :",
        answerOptions: [
            {answer: 'Ne', isCorrect: false},
            {answer: 'Ar', isCorrect: true},
            {answer: 'Kr', isCorrect: false},
            {answer: 'Xe', isCorrect: false},
        ],
    },  {
        question:"XeF6 reacts with excess water to give :",
        answerOptions: [
            {answer: 'XeO4', isCorrect: false},
            {answer: 'XeO3', isCorrect: true},
            {answer: 'XeOF4', isCorrect: false},
            {answer: 'XeO6', isCorrect: false},
        ],
    },  {
        question:"The inert gas which is most abundant is  :",
        answerOptions: [
            {answer: 'Ne', isCorrect: false},
            {answer: 'Ar', isCorrect: true},
            {answer: 'Kr', isCorrect: false},
            {answer: 'Xe', isorrect: false},
        ],
    },
]

export default nobelgasquestions;