import React, { useState } from "react";
import './navbar.css';
import { NavLink } from "react-router-dom";
import { FaBars } from "react-icons/fa";

const routes = [
  { path: "/", name: "Home" },
  { path: "/trends", name: "Trends" },
  { path: "/recommendation", name: "Recommendation" },
  { path: "/premium", name: "Premium" },
  { path: "/ranking", name: "Ranking" },
  { path: "/dashboard", name: "Dashboard" },
  { path: "/discussion-rooms", name: "Rooms" },
  { path: "/about", name: "About" },
  { path: "/support", name: "Support" },
];

const Navbar = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <div className="navbar-container">
      <nav className="navbar">
        <div className="navbar-content">
          <div className="logo">Xnetous</div>

          <div className={`nav-links ${isOpen ? "open" : ""}`}>
            {routes.map((route, index) => (
              <div key={index} className="nav-item">
                <NavLink
                  to={route.path}
                  className={({ isActive }) =>
                    isActive ? "nav-link active" : "nav-link"
                  }
                  onClick={() => setIsOpen(false)}
                >
                  {route.name}
                </NavLink>
              </div>
            ))}
          </div>

          <div className="nav-icons">
            <FaBars className="icon bars-icon" onClick={toggle} />
          </div>
        </div>
      </nav>

      <main>{children}</main>
    </div>
  );
};

export default Navbar;
