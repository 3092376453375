import React from 'react';
import { FaShareAlt } from "react-icons/fa";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import { doc, updateDoc } from 'firebase/firestore';
import { fireDB } from '../config/firebase'; // Ensure you import your Firestore instance

const Share = ({ question, toggleShareDropdown, shareDropdownOpen, questions, setQuestions }) => {
  const handleShare = async (questionId) => {
    try {
      // Update the share count in Firestore
      const questionRef = doc(fireDB, 'quizQuestions', questionId);
      const updatedQuestions = questions.map((q) =>
        q.id === questionId
          ? { ...q, shares: q.shares + 1 } // Increment share count
          : q
      );

      // Update local state
      setQuestions(updatedQuestions);

      // Update Firestore with the new share count
      await updateDoc(questionRef, { shares: updatedQuestions.find(q => q.id === questionId).shares });
      console.log('Share count updated in Firestore.');
    } catch (error) {
      console.error('Error updating share count:', error);
    }
  };

  const shareOptions = [
    { label: "Share on Facebook", Button: FacebookShareButton, url: `https://example.com/question/${question.id}` },
    { label: "Share on Twitter", Button: TwitterShareButton, url: `https://example.com/question/${question.id}` },
    { label: "Share on WhatsApp", Button: WhatsappShareButton, url: `https://example.com/question/${question.id}` },
  ];

  return (
    <div>
      {/* Share icon with count */}
      <div onClick={() => toggleShareDropdown(question.id)} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
        <FaShareAlt />
        <span style={{ marginLeft: '5px' }}>{question.shares}</span> {/* Display share count */}
      </div>
      
      {/* Share dropdown */}
      {shareDropdownOpen === question.id && (
        <div style={{ marginTop: "10px" }}>
          {shareOptions.map(({ label, Button, url }, index) => (
            <Button
              key={index}
              url={url}
              onClick={() => handleShare(question.id)}
              style={{ display: 'block', margin: '5px 0' }} // Style for share buttons
            >
              {label}
            </Button>
          ))}
        </div>
      )}
    </div>
  );
};

export default Share;
