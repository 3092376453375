//import Quiz from './subjects/Quiz';
import React from "react";
import { Link } from "react-router-dom";
import "../pagesStyle/Home.css";
import QuizProblem from "../pages/QuizProblem";

function Premium() {
  return (
    <div>
      <h2 className="xnetous">
        This quiz application is guided and maintained by{" "}
        <a className="xnetous-link" href="https://xnetous.com">
          Xnetous
        </a>{" "}
      </h2>
      <p className="home-para">
        Welcome to the beginner's guide to Intelligency hunt! If you're new to
        intelligency hunt, looking to get started, or just here to brush up on
        some of the fundamentals of this site, you're in the right place. We
        will take a look at the various features that make intelligency hunt
        such a unique quiz site and what it has to offer you personally.
      </p>

      <QuizProblem />

      <div>
        <h2 className="demo-1">
          This quiz application is helpful for students who are taking Jee
          Mains, SAT, ACT, and other competitive engineering exams and also
          helpful for first years BS student and engineering students
        </h2>
      </div>
      <div className="demo-2">
        All are high quality theory, question with answers, you can directly
        following the link at given below and read theory or solve problems{" "}
      </div>

      <div className="home-mechanies-quiz">
        <h3>Mechanies quiz questions</h3>
        <li>
          <Link to="/physics/unit-dimension-and-vector/quiz-questions">
            Unit, dimensions and vector quiz questions
          </Link>
          <Link to="/physics/motion-in-plane-and-projectile-motion/quiz-questions">
            Kinematics quiz questions
          </Link>
        </li>
        <li>
          <Link to="/physics/law-of-motion-work-poer-energy-gravitional-force/quiz-questions">
            Mechanies quiz questions
          </Link>
        </li>
        <li>
          <Link to="/physics/circular-motion-center-of-mass-rotational-motion/quiz-questions">
            Circular Motion, center of mass quiz questions
          </Link>
        </li>
        <li>
          <Link to="/physics/mechanics-mechnical-property-of-solid-and-flude">
            Elasticity and Flude Dynamic quiz questions
          </Link>
        </li>
        <li>
          <Link to="/physics/simple-harmonic-motion-and-wave/quiz-questions">
            SHM and Wave Motion quiz questions
          </Link>
        </li>
      </div>

      <div className="home-physics-quiz">
        <h3>Physics quiz questions</h3>
        <li>
          <Link to="/physics/electrostatics/quiz-questions">
            {" "}
            Electrostatic quiz questions
          </Link>
        </li>
        <li>
          <Link to="/physics/current-electricity/quiz-questions">
            Current Electricity quiz questions
          </Link>
        </li>
        <li>
          <Link to="/physics/megnetic-field-overview/quiz-questions">
            {" "}
            Magnatics field quiz questions
          </Link>
        </li>
        <li>
          <Link to="/physics/electromegantic-induction-and-A.C-current/quiz-questions">
            Induction and A.C current quiz questions
          </Link>
        </li>
        <li>
          <Link to="/physics/optics/quiz-questions ">
            {" "}
            Optics quiz questions
          </Link>
        </li>
        <li>
          <Link to="/physics/modern-physics/quiz-questions">
            Modern physics quiz questions
          </Link>
        </li>
      </div>

      <div className="home-mechanies-quiz">
        <h3>Mathematics quiz questions</h3>
        <li>
          <Link to="/algebra/quiz-questions">Algebra</Link>
        </li>
        <li>
          <Link to="/geometry/quiz-questions">Geometry</Link>
        </li>
        <li>
          <Link to="/trigonometry/quiz-questions">Trigonometry</Link>
        </li>
        <li>
          <Link to="/coordinate-geometry/quiz-questions">
            Coordinate Geometry
          </Link>
        </li>
        <li>
          <Link to="/calculus/quiz-questions">Calculus</Link>
        </li>

        <li>
          <Link to="/three-dimentional-geometry/quiz-questions">
            Three dimentional geometry
          </Link>
        </li>
        <li>
          <Link to="/statistics/quiz-questions">Statistics</Link>
        </li>
      </div>

      <div className="home-physical-chemistry-quiz">
        <h3>Physical Chemistry quiz</h3>
        <li>
          <Link to="/physical-chemistry/basic-concept-of-chemistry/quiz-questions">
            Basic Concept of Chemistry quiz questions
          </Link>
        </li>
        <li>
          <Link to="/physical-chemistry/solid-liquid-and-gasous/quiz-questions">
            {" "}
            States quiz questions
          </Link>
        </li>
        <li>
          <Link to="/physical-chemistry/chemical-equilibrium/quiz-questions">
            Chemical Equilibrium quiz questions
          </Link>
        </li>
        <li>
          <Link to="/physical-chemistry/chemical-kinetics/quiz-questions">
            {" "}
            Chemical kinetic quiz questions
          </Link>
        </li>
        <li>
          <Link to="/physical-chemistry/chemical-reaction/quiz-questions">
            Chemical Reaction quiz questions
          </Link>
        </li>
        <li>
          <Link to="/physical-chemistry/acid-base-and-salts/quiz-questions">
            {" "}
            Acid Base and Salt quiz questions
          </Link>
        </li>
        <li>
          <Link to="/physical-chemistry/abundance-and-metallurgy/quiz-questions">
            {" "}
            Abundance and Metallurgy quiz questions
          </Link>
        </li>
        <li>
          <Link to="/physical-chemistry/chemical-bonding-and-molecular-structure/quiz-questions">
            Chemical Bonding and Molecular structure quiz
          </Link>
        </li>
        <li>
          <Link to="/physical-chemistry/electrochemistry/quiz-questions">
            {" "}
            Electrochemistry quiz questions
          </Link>
        </li>
        <li>
          <Link to="/physical-chemistry/thermodynamic/quiz-questions">
            {" "}
            Thermodynamic quiz questions
          </Link>
        </li>
        <li>
          <Link to="/physical-chemistry/quantum-mechanies/quiz-questions">
            {" "}
            Atomic structure quiz questions
          </Link>
        </li>
        <li>
          <Link to="/physical-chemistry/dilute-solution-and-collagitive-properties/quiz-questions">
            Dilute Solution and Collagative Properties quiz
          </Link>
        </li>
      </div>

      <div className="home-inorganic-chemistry-quiz">
        <h3>Inorganic chemistry quiz</h3>
        <li>
          <Link to="/inorganic-chemistry/perodic-table/quiz-questions">
            {" "}
            Perodic Table quiz questions
          </Link>
        </li>
        <li>
          <Link to="/inorganic-chemistry/trans-elements/quiz-questions">
            {" "}
            Transiation Elements quiz questions{" "}
          </Link>
        </li>
        <li>
          <Link to="/inorganic-chemistry/hydrogen/quiz-questions">
            {" "}
            Hydrogen quiz questions{" "}
          </Link>
        </li>
        <li>
          <Link to="/inorganic-chemistry/alkali-metal/quiz-questions">
            {" "}
            Alkali Metal quiz questions
          </Link>
        </li>
        <li>
          <Link to="/inorganic-chemistry/group-eleven-elements/quiz-questions">
            {" "}
            Group Eleven Elements quiz questions{" "}
          </Link>
        </li>
        <li>
          <Link to="/inorganic-chemistry/group-twelve-elements/quiz-questions">
            {" "}
            Group Twelve Elements quiz questions{" "}
          </Link>
        </li>
        <li>
          <Link to="/inorganic-chemistry/group-thirteen-elements/quiz-questions">
            {" "}
            Group Thirteen Elements quiz questions
          </Link>
        </li>
        <li>
          <Link to="/inorganic-chemistry/group-fourteen-elements/quiz-questions">
            {" "}
            Group Fourteen elements quiz questions
          </Link>
        </li>
        <li>
          <Link to="/inorganic-chemistry/group-fifteen-elements/quiz-questions">
            {" "}
            Group Fifteen Elements quiz questions
          </Link>
        </li>
        <li>
          <Link to="/inorganic-chemistry/group-sixteen-elements/quiz-questions">
            {" "}
            Group Sixteen elements quiz questions
          </Link>
        </li>
        <li>
          <Link to="/inorganic-chemistry/halogen/quiz-questions">
            {" "}
            Halogen Group quiz questions
          </Link>
        </li>
        <li>
          <Link to="/inorganic-chemistry/noble-gas/quiz-questions">
            {" "}
            Noble Gas quiz questions
          </Link>
        </li>
        <li>
          <Link to="/inorganic-chemistry/analytic-chemistry/quiz-questions">
            {" "}
            Analytical Chemistry quiz questions
          </Link>
        </li>
      </div>

      <div className="home-organic-chemistry-quiz">
        <h3>Organic Chemistry quiz</h3>
        <li>
          <Link to="/organic-chemistry/nomenclature/quiz-questions">
            {" "}
            Nomenclature quiz questions
          </Link>
        </li>
        <li>
          <Link to="/organic-chemistry/isomers/quiz-questions">
            {" "}
            Isomers quiz questions
          </Link>
        </li>
        <li>
          <Link to="/organic-chemistry/reaction-intermediate/quiz-questions">
            {" "}
            Reaction Intermediate quiz questions
          </Link>
        </li>
        <li>
          <Link to="/organic-chemistry/carbon-and-it's-compound/quiz-questions">
            {" "}
            Carbon and its Compound quiz questions
          </Link>
        </li>
        <li>
          <Link to="/organic-chemistry/hydrocarbon/quiz-questions">
            {" "}
            HydroCarbon quiz questions
          </Link>
        </li>
        <li>
          <Link to="/organic-chemistry/reaction-mechanism/quiz-questions">
            {" "}
            Reaction Mechanism quiz questions
          </Link>
        </li>
        <li>
          <Link to="/organic-chemistry/environmental-chemistry/quiz-questions">
            {" "}
            Environmental chemistry Quiz questions
          </Link>
        </li>
      </div>

      <div className="home-background">
        <h2>Intelligencyhunt</h2>
        <h5>Sayyed kazi street, Masjid Bunder west, Mumbai- 400003</h5>
        <div className="home-background-link">
          <li>
            <Link to="/about">About Us</Link>
          </li>
          <li>
            <Link to="/about/privacy-policy">Privacy Policy</Link>
          </li>
          <li>
            <Link to="/about/term-and-conditions">Term and Conditions</Link>
          </li>
          <li>
            <Link to="/about/contact-us">Contact Us</Link>
          </li>
        </div>
      </div>
    </div>
  );
}

export default Premium;
