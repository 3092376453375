const bondingandmolestrquestions= [
    {
        question:"Electron loss and gain during electrostatic forces between ions :",
        answerOptions: [
            {answer: 'covalent', isCorrect: false},
            {answer: 'metallic', isCorrect: false},
            {answer: 'ionic', isCorrect: true},
            {answer: 'H-bond', isCorrect: false},
        ],
    }, {
        question:"Carbon atoms in tetracyanoethene are :",
        answerOptions: [
            {answer: 'sp hybridized', isCorrect: false},
            {answer: 'sp² hybridized', isCorrect: false},
            {answer: 'sp² and sp hybridized', isCorrect: true},
            {answer: 'sp and sp^3 hybridized', isCorrect: false},
        ],
    },  {
        question:"Electron sharing to from an electron pair bond  :",
        answerOptions: [
            {answer: 'covalent', isCorrect: true},
            {answer: 'ionic', isCorrect: false},
            {answer: 'metallic', isCorrect: false},
            {answer: 'H-bond', isCorrect: false},
        ],
    },  {
        question:"Effect on physical and chemical properties is due to :",
        answerOptions: [
            {answer: 'covalent', isCorrect: false},
            {answer: 'ionic', isCorrect: false},
            {answer: 'metallic', isCorrect: false},
            {answer: 'H-bond', isCorrect: true},
        ],
    },  {
        question:"The molecular orbital model of metals is called the :",
        answerOptions: [
            {answer: 'ionic model', isCorrect: false},
            {answer: 'metallic model', isCorrect: false},
            {answer: 'band model', isCorrect: true},
            {answer: 'none of these', isCorrect: false},
        ],
    },  {
        question:"The group of chemical species which are diamagnetic is ",
        answerOptions: [
            {answer: 'C2, O2, F2', isCorrect: true},
            {answer: 'O2, N2, F2', isCorrect: false},
            {answer: 'B2, N2, O2', isCorrect: false},
            {answer: 'B2, C2, N2', isCorrect: false},
           
        ],
    }, {
        question:" Electronegativity of an element change with its :",
        answerOptions: [
            {answer: 'oxidation state', isCorrect: true},
            {answer: 'redox reaction', isCorrect: false},
            {answer: 'covalent bond', isCorrect: false},
            {answer: 'solubility', isCorrect: false},
        ],
    },  {
        question:"In every molecule atoms are separated at a distance of :",
        answerOptions: [
            {answer: 'equilibrium', isCorrect: true},
            {answer: 'bond formation', isCorrect: false},
            {answer: 'covalent radius', isCorrect: false},
            {answer: 'none of these', isCorrect: false},
        ],
    },  {
        question:" Compound having more polarizing cation has lower  :",
        answerOptions: [
            {answer: 'melting point', isCorrect: true},
            {answer: 'boiling point', isCorrect: false},
            {answer: 'solubility', isCorrect: false},
            {answer: 'bond length', isCorrect: false},
        ],
    },  {
        question:"Polarization has effect on :",
        answerOptions: [
            {answer: 'time', isCorrect: false},
            {answer: 'temperature', isCorrect: false},
            {answer: 'solubility', isCorrect: true},
            {answer: 'none of these', isCorrect: false},
        ],
    },  {
        question:" Bond order are also directly related with :",
        answerOptions: [
            {answer: 'bond length', isCorrect: false},
            {answer: 'bond energy', isCorrect: true},
            {answer: 'bond order', isCorrect: false},
            {answer: 'bond formation', isCorrect: false},
        ],
    },  {
        question:"Bond order is the inverse of :",
        answerOptions: [
            {answer: 'bond length', isCorrect: true},
            {answer: 'bond energy', isCorrect: false},
            {answer: 'bond order', isCorrect: false},
            {answer: 'bond formation', isCorrect: false},  
        ],
    },  {
        question:"The weak force which arise between molecules (or atom or ions) is known as :",
        answerOptions: [
            {answer: 'ionic bond', isCorrect: false},
            {answer: 'covalent bond', isCorrect: false},
            {answer: 'metallic bond', isCorrect: false},
            {answer: 'verder Waals force', isCorrect: true},
        ],
    },  {
        question:"When a H-bond is formed between atoms in the same molecule, it is called :",
        answerOptions: [
            {answer: 'intramolecular π-bond.', isCorrect: false},
            {answer: 'intramolecular sigma bond.', isCorrect: false},
            {answer: 'intramolecular ionic bond.', isCorrect: false},
            {answer: 'intramolecular H-bond.', isCorrect: true},
        ],
    }, {
        question:"The force present in a crystal of naphthalene is followed by :",
        answerOptions: [
            {answer: 'Electrostatic force', isCorrect: false},
            {answer: 'Covalent force', isCorrect: false},
            {answer: 'dipole – dipole force', isCorrect: true},
            {answer: 'Van der Waals force', isCorrect: false},
        ],
    },  {
        question:" The hypochlorite ion disproportionates in basic medium as  3ClO ---> ClO3 + 2Cl, The change in hybrid nature of chlorine is :",
        answerOptions: [
            {answer: 'sp to sp2', isCorrect: false},
            {answer: ' sp2 to sp3', isCorrect: false},
            {answer: 'sp to sp3', isCorrect: false},
            {answer: 'sp3 to sp3', isCorrect: true},
        ],
    }, {
        question:"The species which have equal bond order are :",
        answerOptions: [
            {answer: 'CN-', isCorrect: false},
            {answer: 'NO-', isCorrect: false},
            {answer: 'CO', isCorrect: false},
            {answer: 'All', isCorrect: true},
        ],
    },  {
        question:" The type of hybrid orbitals used by chlorine in hypochlorite ion is :",
        answerOptions: [
            {answer: 'sp', isCorrect: false},
            {answer: 'sp²', isCorrect: false},
            {answer: 'sp^3', isCorrect: false},
            {answer: 'dsp²', isCorrect: true},
        ],
    }, {
        question:"Which of the following is a superoxide :",
        answerOptions: [
            {answer: 'BaO2', isCorrect: false},
            {answer: 'SnO2', isCorrect: false},
            {answer: 'RbO2', isCorrect: true},
            {answer: 'PbO2', isCorrect: false},
        ],
    },  {
        question:" How many equivalent perfect Lewis structures are possible for - N3 ion :",
        answerOptions: [
            {answer: 'one', isCorrect: false},
            {answer: 'two', isCorrect: false},
            {answer: 'three', isCorrect: true},
            {answer: 'four', isCorrect: false},
        ],
    }, {
        question:"Which is expected least stable :",
        answerOptions: [
            {answer: 'Be-', isCorrect: true},
            {answer: 'C-', isCorrect: false},
            {answer: 'Li-', isCorrect: false},
            {answer: 'B-', isCorrect: false},
        ],
    },  {
        question:"Which of the following ion has (18 + 2) e stable electron configuration? :",
        answerOptions: [
            {answer: 'Ca²', isCorrect: false},
            {answer: 'Fe²', isCorrect: false},
            {answer: 'Zn²', isCorrect: false},
            {answer: 'Sn²', isCorrect: true},
        ],
    }, {
        question:" Intramolecular H – bonding increases water solubility of a compound  :",
        answerOptions: [
            {answer: 'no', isCorrect: true},
            {answer: 'maybe', isCorrect: false},
            {answer: 'yes', isCorrect: false},
            {answer: 'can not be said', isCorrect: false},
        ],
    },  {
        question:"The geometry of O3(or H2S) and its dipole moment are :",
        answerOptions: [
            {answer: 'linear and zero', isCorrect: false},
            {answer: 'angular and non-zero', isCorrect: true},
            {answer: 'angular and zero', isCorrect: false},
            {answer: 'linear and non-zero', isCorrect: false},
        ],
    }, {
        question:"Carbon atoms in C3O2 are :",
        answerOptions: [
            {answer: 'sp hybridized', isCorrect: false},
            {answer: 'sp3 hybridized', isCorrect: false},
            {answer: 'sp3 hybridized', isCorrect: true},
            {answer: 'sp and sp2 hybridized', isCorrect: false},
        ],
    },  {
        question:"Which H - bond is expected to be weakest :",
        answerOptions: [
            {answer: 'O – H - - - N', isCorrect: false},
            {answer: 'O – H - - - S', isCorrect: false},
            {answer: 'O – H - - - Cl', isCorrect: false},
            {answer: 'O – H - - - O', isCorrect: true},
        ],
    }, {
        question:"When angle between two covalent bonds is minimum is :",
        answerOptions: [
            {answer: 'NO2+', isCorrect: true},
            {answer: 'NO2', isCorrect: false},
            {answer: 'NO', isCorrect: false},
            {answer: 'none of these', isCorrect: false},
        ],
    },  {
        question:"The octet rule is not strictly followed in :",
        answerOptions: [
            {answer: 'CO2', isCorrect: false},
            {answer: 'NO', isCorrect: false},
            {answer: 'NO2', isCorrect: true},
            {answer: 'SO2', isCorrect: false},
        ],
    },  {
        question:" If a molecule AB has zero dipole moment, the σ bonding orbitals used by A are :",
        answerOptions: [
            {answer: 'sp hybrids', isCorrect: true},
            {answer: 'sp² hybrids', isCorrect: false},
            {answer: 'sp^3 hybrids', isCorrect: false},
            {answer: 'p-orbitals', isorrect: false},
        ],
    },
]

export default bondingandmolestrquestions;