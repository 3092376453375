import { useState } from "react";
import { FaComment, FaTrashAlt } from "react-icons/fa";
import { doc, updateDoc } from "firebase/firestore";
import { fireDB } from "../config/firebase"; // Adjust the import path if needed

const Comment = ({ question, userId, isAuthenticated, updateQuestions }) => {
  const [newComment, setNewComment] = useState("");
  const [isCommentOpen, setIsCommentOpen] = useState(false); // State to toggle comments dropdown

  const handleAddComment = async (questionId, comment) => {
    if (comment.trim() === "") return; // Prevent adding empty comments
    const questionRef = doc(fireDB, "quizQuestions", questionId);
    const newComments = [...question.comments, comment];

    // Update local state for comments
    updateQuestions(questionId, newComments, "comments"); // Ensure this function is passed as a prop
    await updateDoc(questionRef, { comments: newComments, commentCount: newComments.length }); // Update Firestore with new comment count
    setNewComment(""); // Clear the input field after adding
  };

  const handleDeleteComment = async (questionId, commentIndex) => {
    const questionRef = doc(fireDB, "quizQuestions", questionId);
    const updatedComments = question.comments.filter(
      (_, index) => index !== commentIndex
    );

    // Update local state for comments
    updateQuestions(questionId, updatedComments, "comments"); // Use the passed prop function
    await updateDoc(questionRef, { comments: updatedComments, commentCount: updatedComments.length }); // Update Firestore with new comment count
  };

  const toggleComments = () => {
    setIsCommentOpen(!isCommentOpen); // Toggle the comment section
  };

  return (
    <div>
      <div>
        {/* Toggle Comment Dropdown on Icon Click */}
        <div onClick={toggleComments} style={{ cursor: "pointer" }}>
          <FaComment /> {/* Comment icon to open/close comments */}
          {isCommentOpen ? " Hide Comments" : " Comments"}
          <span style={{ marginLeft: "5px" }}>{question.comments.length}</span> {/* Display the number of comments */}
        </div>
      </div>

      {isCommentOpen && (
        <div style={{ marginTop: "10px" }}>
          {/* Comment Section Dropdown */}
          {question.comments.map((comment, index) => (
            <div key={index} style={{ marginBottom: "10px" }}>
              <p>{comment}</p>
              {isAuthenticated && (
                <div
                  onClick={() => handleDeleteComment(question.id, index)}
                  style={{ marginLeft: "10px", cursor: "pointer" }}
                >
                  <FaTrashAlt /> {/* Trash icon to delete comment */}
                </div>
              )}
            </div>
          ))}

          {isAuthenticated && (
            <div>
              <input
                type="text"
                value={newComment}
                onChange={(e) => setNewComment(e.target.value)}
                placeholder="Add a comment..."
              />
              <button onClick={() => handleAddComment(question.id, newComment)}>
                Add Comment
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Comment;
