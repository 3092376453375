const acidbasequestions= [
    {
        question:"Which of the following is a hard base in the Lewis sense :",
        answerOptions: [
            {answer: 'CN-', isCorrect: false},
            {answer: 'HO-', isCorrect: true},
            {answer: 'SCN-', isCorrect: false},
            {answer: 'S²-', isCorrect: false},
        ],
    }, {
        question:"The correct acidity order is represented by :",
        answerOptions: [
            {answer: 'H₃PO₂ > H₃PO₃ > H₃PO₄', isCorrect: true},
            {answer: 'H₃PO₄ > H₃PO₃ > H₃PO₂', isCorrect: false},
            {answer: 'H₃PO₄ > H₃PO₂ > H₃PO₃', isCorrect: false},
            {answer: 'H₃PO₃ > H₃PO₂ > H₃PO₄', isCorrect: false},
        ],
    },  {
        question:" Aqueous solution of a salt made by strong acid and weak base is :",
        answerOptions: [
            {answer: 'acidic', isCorrect: true},
            {answer: 'basic', isCorrect: false},
            {answer: 'neutral', isCorrect: false},
            {answer: 'buffer sol.', isCorrect: false},
        ],
    },  {
        question:" The strongest acid among the following in water, is:",
        answerOptions: [
            {answer: 'SO₂(OH)₂', isCorrect: false},
            {answer: 'ClO₂(OH)', isCorrect: false},
            {answer: 'ClO₂(OH)', isCorrect: true},
            {answer: 'H-bond', isCorrect: false},
        ],
    },  {
        question:"If Ka > 10 then, acid is :",
        answerOptions: [
            {answer: 'weak', isCorrect: false},
            {answer: 'strong', isCorrect: true},
            {answer: 'neutral', isCorrect: false},
            {answer: 'none of these', isCorrect: false},
        ],
    },  {
        question:"Which of the following is weakest base : ",
        answerOptions: [
            {answer: 'Ca(OH)₂', isCorrect: false},
            {answer: 'Zn(OH)₂', isCorrect: true},
            {answer: 'KOH', isCorrect: false},
            {answer: 'NaOH', isCorrect: false},
           
        ],
    }, {
        question:"Strongest acid is :",
        answerOptions: [
            {answer: 'Ka = 10^4', isCorrect: true},
            {answer: 'Ka = 10²', isCorrect: false},
            {answer: 'Ka = 0.1', isCorrect: false},
            {answer: 'Ka = 0.01', isCorrect: false},
        ],
    },  {
        question:"The compound that is not a Lewis acid :",
        answerOptions: [
            {answer: 'BF₃', isCorrect: false},
            {answer: 'BaCl₂', isCorrect: true},
            {answer: 'SnCl₄', isCorrect: false},
            {answer: 'NaOH', isCorrect: false},
        ],
    },  {
        question:"Lewis base is :",
        answerOptions: [
            {answer: 'BF₃', isCorrect: false},
            {answer: 'BaCl₂', isCorrect: false},
            {answer: 'H₅', isCorrect: true},
            {answer: 'AlCl₅', isCorrect: false},
        ],
    },  {
        question:"All positive ions are Lewis acids as they can accept :",
        answerOptions: [
            {answer: 'electron pairs', isCorrect: true},
            {answer: 'electron acceptors', isCorrect: false},
            {answer: 'electron doners', isCorrect: false},
            {answer: 'none of these', isCorrect: false},
        ],
    },  {
        question:"Oxidation state of the central atom decides bond strength with :",
        answerOptions: [
            {answer: 'H⁺', isCorrect: true},
            {answer: 'H⁻', isCorrect: false},
            {answer: 'OH⁺', isCorrect: false},
            {answer: 'OH⁻', isCorrect: false},
        ],
    },  {
        question:"Ka < 1, the acid is :",
        answerOptions: [
            {answer: 'strong', isCorrect: false},
            {answer: 'neutral', isCorrect: false},
            {answer: 'bond order', isCorrect: false},
            {answer: 'weak', isCorrect: true},  
        ],
    },  {
        question:"The tendency and ability of a chemical substance to donate electron pair to proton is called :",
        answerOptions: [
            {answer: 'ionic bond', isCorrect: false},
            {answer: 'covalent bond', isCorrect: false},
            {answer: 'acidity', isCorrect: false},
            {answer: 'basicity', isCorrect: true},
        ],
    },  {
        question:"Acids which contain more than one ionizable hydrogen are called :",
        answerOptions: [
            {answer: 'strong base.', isCorrect: false},
            {answer: 'weak acid.', isCorrect: false},
            {answer: 'buffer solution.', isCorrect: false},
            {answer: 'polyprotic', isCorrect: true},
        ],
    }, {
        question:"Strongest acid having :",
        answerOptions: [
            {answer: 'PH = 10', isCorrect: false},
            {answer: 'PH = 8', isCorrect: false},
            {answer: 'PH = 4', isCorrect: false},
            {answer: 'PH = 3', isCorrect: true},
        ],
    },  {
        question:" Bond strength, In simple terms, it is proton :",
        answerOptions: [
            {answer: 'acceptors', isCorrect: false},
            {answer: 'doners', isCorrect: false},
            {answer: 'affinity', isCorrect: true},
            {answer: 'none of these', isCorrect: false},
        ],
    }, {
        question:" A substance is acidic only when another substance capable of accepting  :",
        answerOptions: [
            {answer: 'neutron', isCorrect: false},
            {answer: 'electron', isCorrect: false},
            {answer: 'H-atom', isCorrect: false},
            {answer: 'proton', isCorrect: true},
        ],
    },  {
        question:"According tp Bronsted-Lowrt Theory acids are H+ :",
        answerOptions: [
            {answer: 'doners', isCorrect: true},
            {answer: 'acceptors', isCorrect: false},
            {answer: 'none of these', isCorrect: false},
            {answer: 'all of these', isCorrect: false},
        ],
    }, {
        question:"The correct order of acidity is :",
        answerOptions: [
            {answer: 'HOCl > HOBr > HOI', isCorrect: true},
            {answer: 'HOI > HOBr > HOCl', isCorrect: false},
            {answer: 'HOBr > HOCl > HOI', isCorrect: false},
            {answer: 'HOCl > HOI > HOBr', isCorrect: false},
        ],
    },  {
        question:"According tp Bronsted-Lowrt Theory acids are OH  :",
        answerOptions: [
            {answer: 'doners', isCorrect: false},
            {answer: 'acceptors', isCorrect: true},
            {answer: 'none of these', isCorrect: false},
            {answer: 'all of these', isCorrect: false},
        ],
    }, {
        question:"A  solution that maintains its pH constant on addition of a small amounts of acid or base is called :",
        answerOptions: [
            {answer: 'buffer solution', isCorrect: true},
            {answer: 'polyprotic', isCorrect: false},
            {answer: 'water', isCorrect: false},
            {answer: 'none of these', isCorrect: false},
        ],
    },  {
        question:"The PH of 10^-9 M NaOH solution is :",
        answerOptions: [
            {answer: '7', isCorrect: false},
            {answer: '< 5', isCorrect: false},
            {answer: '> 5', isCorrect: false},
            {answer: '> 7', isCorrect: true},
        ],
    }, {
        question:"When a substance donates a proton it is converted to a  :",
        answerOptions: [
            {answer: 'acid', isCorrect: false},
            {answer: 'salt', isCorrect: false},
            {answer: 'water', isCorrect: false},
            {answer: 'base', isCorrect: true},
        ],
    },  {
        question:"The conjugate acid of NH2 :",
        answerOptions: [
            {answer: 'NH₃', isCorrect: true},
            {answer: 'NH₃', isCorrect: false},
            {answer: 'N₂H₄', isCorrect: false},
            {answer: 'NH₄OH', isCorrect: false},
        ],
    }, {
        question:"The PH of 10^-8 M HCl is :",
        answerOptions: [
            {answer: '< 6', isCorrect: false},
            {answer: '< 7', isCorrect: true},
            {answer: '> 7', isCorrect: false},
            {answer: '8', isCorrect: false},
        ],
    },  {
        question:" Chemical species which differ in formula by a proton are called :",
        answerOptions: [
            {answer: 'conjugate pair', isCorrect: true},
            {answer: 'electron acceptor', isCorrect: false},
            {answer: 'conjuage base', isCorrect: false},
            {answer: 'conjugate acid', isCorrect: false},
        ],
    }, {
        question:"The conjugate acid of a weak base is a :",
        answerOptions: [
            {answer: 'strong acid', isCorrect: true},
            {answer: 'strong base', isCorrect: false},
            {answer: 'weak acid', isCorrect: false},
            {answer: 'none of these', isCorrect: false},
        ],
    },  {
        question:"Value of ionic product of water at 25°C is :",
        answerOptions: [
            {answer: '< 10^-14', isCorrect: false},
            {answer: '> 10^14', isCorrect: false},
            {answer: '= 10^14', isCorrect: true},
            {answer: '10^-12', isCorrect: false},
        ],
    },  {
        question:" Which of the following is a soft acid :",
        answerOptions: [
            {answer: 'Na⁺', isCorrect: false},
            {answer: 'Ag⁺', isCorrect: true},
            {answer: 'Ca⁺₂', isCorrect: false},
            {answer: 'Al⁺₃', isorrect: false},
        ],
    },
]

export default acidbasequestions;