const geometryquestions = [
  {
    question:
      "In a triangle, one angle is 50°, and the other is 70°. What is the third angle?",
    answerOptions: [
      { answer: "60°", isCorrect: true },
      { answer: "50°", isCorrect: false },
      { answer: "70°", isCorrect: false },
      { answer: "80°", isCorrect: false },
    ],
  },
  {
    question: "The diagonals of a rhombus bisect each other at:",
    answerOptions: [
      { answer: "Right angles", isCorrect: true },
      { answer: "Acute angles", isCorrect: false },
      { answer: "Obtuse angles", isCorrect: false },
      { answer: "Parallel lines", isCorrect: false },
    ],
  },
  {
    question:
      "In a circle, a chord is 8 cm away from the center, and the radius is 10 cm. What is the length of the chord?",
    answerOptions: [
      { answer: "12 cm", isCorrect: true },
      { answer: "15 cm", isCorrect: false },
      { answer: "8 cm", isCorrect: false },
      { answer: "10 cm", isCorrect: false },
    ],
  },
  {
    question: "The sum of all angles in a quadrilateral is:",
    answerOptions: [
      { answer: "360°", isCorrect: true },
      { answer: "180°", isCorrect: false },
      { answer: "90°", isCorrect: false },
      { answer: "540°", isCorrect: false },
    ],
  },
  {
    question: "The area of a circle is 154 cm². Find the radius of the circle.",
    answerOptions: [
      { answer: "7 cm", isCorrect: true },
      { answer: "14 cm", isCorrect: false },
      { answer: "5 cm", isCorrect: false },
      { answer: "10 cm", isCorrect: false },
    ],
  },
  {
    question: "In an equilateral triangle, each angle measures:",
    answerOptions: [
      { answer: "60°", isCorrect: true },
      { answer: "45°", isCorrect: false },
      { answer: "90°", isCorrect: false },
      { answer: "30°", isCorrect: false },
    ],
  },
  {
    question:
      "The perimeter of a rectangle is 20 cm. If the length is 6 cm, what is the width?",
    answerOptions: [
      { answer: "4 cm", isCorrect: true },
      { answer: "5 cm", isCorrect: false },
      { answer: "3 cm", isCorrect: false },
      { answer: "7 cm", isCorrect: false },
    ],
  },
  {
    question:
      "If the length of each side of a square is doubled, the area becomes:",
    answerOptions: [
      { answer: "Four times", isCorrect: true },
      { answer: "Double", isCorrect: false },
      { answer: "Three times", isCorrect: false },
      { answer: "Half", isCorrect: false },
    ],
  },
  {
    question:
      "The length of the hypotenuse of a right-angled triangle with sides 6 cm and 8 cm is:",
    answerOptions: [
      { answer: "10 cm", isCorrect: true },
      { answer: "12 cm", isCorrect: false },
      { answer: "14 cm", isCorrect: false },
      { answer: "8 cm", isCorrect: false },
    ],
  },
  {
    question: "The sum of all interior angles of a pentagon is:",
    answerOptions: [
      { answer: "540°", isCorrect: true },
      { answer: "360°", isCorrect: false },
      { answer: "180°", isCorrect: false },
      { answer: "720°", isCorrect: false },
    ],
  },
  {
    question:
      "A line segment joining two points on the circumference of a circle is called:",
    answerOptions: [
      { answer: "A chord", isCorrect: true },
      { answer: "A radius", isCorrect: false },
      { answer: "A diameter", isCorrect: false },
      { answer: "A secant", isCorrect: false },
    ],
  },
  {
    question:
      "In a triangle, if two sides are 5 cm and 12 cm and the hypotenuse is 13 cm, the triangle is:",
    answerOptions: [
      { answer: "A right triangle", isCorrect: true },
      { answer: "An equilateral triangle", isCorrect: false },
      { answer: "An isosceles triangle", isCorrect: false },
      { answer: "An obtuse triangle", isCorrect: false },
    ],
  },
  {
    question: "The number of diagonals in a hexagon is:",
    answerOptions: [
      { answer: "9", isCorrect: true },
      { answer: "7", isCorrect: false },
      { answer: "5", isCorrect: false },
      { answer: "10", isCorrect: false },
    ],
  },
  {
    question:
      "If the ratio of the lengths of two sides of a triangle is 3:4, and the perimeter of the triangle is 14 cm, what is the length of the shortest side?",
    answerOptions: [
      { answer: "3 cm", isCorrect: true },
      { answer: "4 cm", isCorrect: false },
      { answer: "5 cm", isCorrect: false },
      { answer: "6 cm", isCorrect: false },
    ],
  },
  {
    question: "The volume of a cube with side 5 cm is:",
    answerOptions: [
      { answer: "125 cm³", isCorrect: true },
      { answer: "25 cm³", isCorrect: false },
      { answer: "100 cm³", isCorrect: false },
      { answer: "150 cm³", isCorrect: false },
    ],
  },
  {
    question:
      "The angle subtended by a diameter at the circumference of a circle is always:",
    answerOptions: [
      { answer: "90°", isCorrect: true },
      { answer: "180°", isCorrect: false },
      { answer: "60°", isCorrect: false },
      { answer: "120°", isCorrect: false },
    ],
  },
  {
    question:
      "The ratio of the circumference to the diameter of any circle is:",
    answerOptions: [
      { answer: "π", isCorrect: true },
      { answer: "2π", isCorrect: false },
      { answer: "1/2", isCorrect: false },
      { answer: "π²", isCorrect: false },
    ],
  },
  {
    question:
      "In a regular polygon, each interior angle is 120°. How many sides does the polygon have?",
    answerOptions: [
      { answer: "6", isCorrect: true },
      { answer: "5", isCorrect: false },
      { answer: "8", isCorrect: false },
      { answer: "4", isCorrect: false },
    ],
  },
  {
    question:
      "The area of a parallelogram with a base of 8 cm and height of 5 cm is:",
    answerOptions: [
      { answer: "40 cm²", isCorrect: true },
      { answer: "20 cm²", isCorrect: false },
      { answer: "35 cm²", isCorrect: false },
      { answer: "25 cm²", isCorrect: false },
    ],
  },
  {
    question: "The perimeter of a semicircle with radius 7 cm is:",
    answerOptions: [
      { answer: "36.43 cm", isCorrect: true },
      { answer: "44 cm", isCorrect: false },
      { answer: "28 cm", isCorrect: false },
      { answer: "22 cm", isCorrect: false },
    ],
  },
  {
    question:
      "In an isosceles triangle, if the base is 10 cm and the equal sides are 8 cm each, what is the height of the triangle?",
    answerOptions: [
      { answer: "6 cm", isCorrect: true },
      { answer: "5 cm", isCorrect: false },
      { answer: "7 cm", isCorrect: false },
      { answer: "8 cm", isCorrect: false },
    ],
  },
  {
    question:
      "In a right-angled triangle, if one leg is 9 cm and the hypotenuse is 15 cm, what is the length of the other leg?",
    answerOptions: [
      { answer: "12 cm", isCorrect: true },
      { answer: "6 cm", isCorrect: false },
      { answer: "8 cm", isCorrect: false },
      { answer: "10 cm", isCorrect: false },
    ],
  },
  {
    question: "The diagonal of a square is 14 cm. Find the side of the square.",
    answerOptions: [
      { answer: "7√2 cm", isCorrect: true },
      { answer: "14√2 cm", isCorrect: false },
      { answer: "12 cm", isCorrect: false },
      { answer: "10 cm", isCorrect: false },
    ],
  },
  {
    question:
      "In a right-angled triangle, if the lengths of the hypotenuse and one of the legs are 13 cm and 5 cm respectively, find the length of the other leg.",
    answerOptions: [
      { answer: "12 cm", isCorrect: true },
      { answer: "10 cm", isCorrect: false },
      { answer: "8 cm", isCorrect: false },
      { answer: "9 cm", isCorrect: false },
    ],
  },
  {
    question:
      "The area of a rhombus is 120 cm² and the length of one diagonal is 12 cm. Find the length of the other diagonal.",
    answerOptions: [
      { answer: "20 cm", isCorrect: true },
      { answer: "15 cm", isCorrect: false },
      { answer: "24 cm", isCorrect: false },
      { answer: "18 cm", isCorrect: false },
    ],
  },
  {
    question:
      "A sector of a circle has an area of 50 cm² and a radius of 5 cm. Find the angle of the sector in radians.",
    answerOptions: [
      { answer: "2 radians", isCorrect: true },
      { answer: "1.5 radians", isCorrect: false },
      { answer: "3 radians", isCorrect: false },
      { answer: "4 radians", isCorrect: false },
    ],
  },
  {
    question:
      "In an equilateral triangle, the altitude is 8√3 cm. Find the perimeter of the triangle.",
    answerOptions: [
      { answer: "48 cm", isCorrect: true },
      { answer: "36 cm", isCorrect: false },
      { answer: "32 cm", isCorrect: false },
      { answer: "40 cm", isCorrect: false },
    ],
  },
  {
    question:
      "In a right circular cone, the radius of the base is 4 cm and the slant height is 5 cm. Find the height of the cone.",
    answerOptions: [
      { answer: "3 cm", isCorrect: true },
      { answer: "4 cm", isCorrect: false },
      { answer: "6 cm", isCorrect: false },
      { answer: "5 cm", isCorrect: false },
    ],
  },
  {
    question:
      "In a trapezium, if the lengths of the parallel sides are 8 cm and 12 cm, and the height is 7 cm, find the area of the trapezium.",
    answerOptions: [
      { answer: "140 cm²", isCorrect: true },
      { answer: "100 cm²", isCorrect: false },
      { answer: "120 cm²", isCorrect: false },
      { answer: "160 cm²", isCorrect: false },
    ],
  },
  {
    question:
      "The sum of the squares of the lengths of the diagonals of a rectangle is 520 cm². If one side of the rectangle is 8 cm, find the length of the other side.",
    answerOptions: [
      { answer: "12 cm", isCorrect: true },
      { answer: "14 cm", isCorrect: false },
      { answer: "10 cm", isCorrect: false },
      { answer: "16 cm", isCorrect: false },
    ],
  },
  {
    question:
      "In a circle, the length of an arc is 15 cm and the radius of the circle is 5 cm. Find the angle in degrees subtended by the arc.",
    answerOptions: [
      { answer: "54°", isCorrect: true },
      { answer: "60°", isCorrect: false },
      { answer: "45°", isCorrect: false },
      { answer: "72°", isCorrect: false },
    ],
  },
  {
    question:
      "The area of an inscribed circle in a regular hexagon is 36π cm². Find the side length of the hexagon.",
    answerOptions: [
      { answer: "12 cm", isCorrect: true },
      { answer: "10 cm", isCorrect: false },
      { answer: "15 cm", isCorrect: false },
      { answer: "8 cm", isCorrect: false },
    ],
  },
  {
    question:
      "A sphere has a surface area of 288π cm². Find the volume of the sphere.",
    answerOptions: [
      { answer: "288π cm³", isCorrect: true },
      { answer: "216π cm³", isCorrect: false },
      { answer: "324π cm³", isCorrect: false },
      { answer: "250π cm³", isCorrect: false },
    ],
  },
  {
    question:
      "In a rhombus, the lengths of the diagonals are 16 cm and 30 cm. Find the area of the rhombus.",
    answerOptions: [
      { answer: "240 cm²", isCorrect: true },
      { answer: "200 cm²", isCorrect: false },
      { answer: "280 cm²", isCorrect: false },
      { answer: "180 cm²", isCorrect: false },
    ],
  },
  {
    question:
      "In a triangle, the ratio of the lengths of the sides is 3:4:5. If the perimeter of the triangle is 36 cm, find the area of the triangle.",
    answerOptions: [
      { answer: "54 cm²", isCorrect: true },
      { answer: "48 cm²", isCorrect: false },
      { answer: "60 cm²", isCorrect: false },
      { answer: "72 cm²", isCorrect: false },
    ],
  },
  {
    question:
      "The sum of the squares of the lengths of the diagonals of a parallelogram is 500 cm². If one side of the parallelogram is 10 cm and the other side is 24 cm, find the length of the other diagonal.",
    answerOptions: [
      { answer: "26 cm", isCorrect: true },
      { answer: "20 cm", isCorrect: false },
      { answer: "22 cm", isCorrect: false },
      { answer: "24 cm", isCorrect: false },
    ],
  },
  {
    question:
      "In a circle with radius 10 cm, a chord is at a distance of 6 cm from the center. Find the length of the chord.",
    answerOptions: [
      { answer: "16 cm", isCorrect: true },
      { answer: "12 cm", isCorrect: false },
      { answer: "18 cm", isCorrect: false },
      { answer: "14 cm", isCorrect: false },
    ],
  },
  {
    question:
      "The diagonals of a rhombus are in the ratio 3:4. If the area of the rhombus is 120 cm², find the length of the longer diagonal.",
    answerOptions: [
      { answer: "16 cm", isCorrect: true },
      { answer: "18 cm", isCorrect: false },
      { answer: "20 cm", isCorrect: false },
      { answer: "22 cm", isCorrect: false },
    ],
  },
  {
    question:
      "In a right-angled triangle, the hypotenuse is 25 cm and the altitude to the hypotenuse is 15 cm. Find the area of the triangle.",
    answerOptions: [
      { answer: "150 cm²", isCorrect: true },
      { answer: "175 cm²", isCorrect: false },
      { answer: "200 cm²", isCorrect: false },
      { answer: "225 cm²", isCorrect: false },
    ],
  },
  {
    question:
      "Find the radius of a circle inscribed in a right-angled triangle whose sides are 9 cm, 12 cm, and 15 cm.",
    answerOptions: [
      { answer: "6 cm", isCorrect: true },
      { answer: "5 cm", isCorrect: false },
      { answer: "7 cm", isCorrect: false },
      { answer: "8 cm", isCorrect: false },
    ],
  },
  {
    question:
      "The area of a regular hexagon is 108√3 cm². Find the side length of the hexagon.",
    answerOptions: [
      { answer: "6 cm", isCorrect: true },
      { answer: "8 cm", isCorrect: false },
      { answer: "5 cm", isCorrect: false },
      { answer: "7 cm", isCorrect: false },
    ],
  },
  {
    question:
      "A trapezium has non-parallel sides of length 13 cm and 15 cm and parallel sides of length 10 cm and 20 cm. Find the area of the trapezium if the height is 12 cm.",
    answerOptions: [
      { answer: "180 cm²", isCorrect: true },
      { answer: "200 cm²", isCorrect: false },
      { answer: "220 cm²", isCorrect: false },
      { answer: "240 cm²", isCorrect: false },
    ],
  },
  {
    question:
      "In a cyclic quadrilateral, if the measures of two opposite angles are 70° and 110°, find the measure of the other two angles.",
    answerOptions: [
      { answer: "70°, 110°", isCorrect: true },
      { answer: "80°, 100°", isCorrect: false },
      { answer: "90°, 90°", isCorrect: false },
      { answer: "60°, 120°", isCorrect: false },
    ],
  },
  {
    question:
      "In a circle with radius 8 cm, find the length of a chord that is 6 cm away from the center.",
    answerOptions: [
      { answer: "10 cm", isCorrect: true },
      { answer: "12 cm", isCorrect: false },
      { answer: "8 cm", isCorrect: false },
      { answer: "14 cm", isCorrect: false },
    ],
  },
  {
    question:
      "A cylinder has a height of 10 cm and a volume of 300π cm³. Find the radius of the base.",
    answerOptions: [
      { answer: "6 cm", isCorrect: true },
      { answer: "5 cm", isCorrect: false },
      { answer: "7 cm", isCorrect: false },
      { answer: "8 cm", isCorrect: false },
    ],
  },
  
];


export default geometryquestions;
