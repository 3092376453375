const groupthirteenquestions = [
    {
        question:"Boron, Al, Ga, In and Tl are :",
        answerOptions: [
            {answer: 's - block', isCorrect: false},
            {answer: 'p - block', isCorrect: true},
            {answer: 'd - block', isCorrect: false},
            {answer: 'none of these', isCorrect: false},
        ],
    }, {
        question:"Highest atomic number in p - block elements :",
        answerOptions: [
            {answer: 'B', isCorrect: false},
            {answer: 'Al', isCorrect: false},
            {answer: 'Ti', isCorrect: true},
            {answer: 'In', isCorrect: false},
        ],
    },  {
        question:"When Al + NaOH + H2OH ---> NaAlO2  :",
        answerOptions: [
            {answer: 'H2O', isCorrect: false},
            {answer: '2H2', isCorrect: false},
            {answer: 'H2', isCorrect: true},
            {answer: 'none of these', isCorrect: false},
        ],
    },  {
        question:"Highest electronegativity in p - block elements :",
        answerOptions: [
            {answer: 'B', isCorrect: true},
            {answer: 'Al', isCorrect: false},
            {answer: 'Ti', isCorrect: false},
            {answer: 'Ga', isCorrect: false},
        ],
    },  {
        question:"Highest melting point in p - block elements  :",
        answerOptions: [
            {answer: 'Ga', isCorrect: false},
            {answer: 'Al', isCorrect: false},
            {answer: 'B', isCorrect: true},
            {answer: 'Ti', isCorrect: false},
        ],
    },  {
        question:"Lowest ionization in p - block elements  :",
        answerOptions: [
            {answer: 'Al', isCorrect: false},
            {answer: 'Ga', isCorrect: false},
            {answer: 'Ti', isCorrect: false},
            {answer: 'In', isCorrect: true},
           
        ],
    }, {
        question:"IN Van Arkel methods, heat is  :",
        answerOptions: [
            {answer: 'observed', isCorrect: false},
            {answer: 'release', isCorrect: true},
            {answer: 'remain constant', isCorrect: false},
            {answer: 'none of these', isCorrect: false},
        ],
    },  {
        question:" B2O3 can dissolve in water to form :",
        answerOptions: [
            {answer: 'One acid', isCorrect: false},
            {answer: 'Two acid', isCorrect: false},
            {answer: 'Three acid', isCorrect: true},
            {answer: 'Four acid', isCorrect: false},
        ],
    },  {
        question:"Crystalline Boron can be prepared by :",
        answerOptions: [
            {answer: 'Dow process', isCorrect: false},
            {answer: 'Down process', isCorrect: false},
            {answer: 'Van Arkel method', isCorrect: true},
            {answer: 'Degusa process', isCorrect: false},
        ],
    },  {
        question:"B2O3 + 6HF ---> 2BF3 +  :",
        answerOptions: [
            {answer: 'H2O', isCorrect: false},
            {answer: '2H2O', isCorrect: false},
            {answer: '3H2O', isCorrect: true},
            {answer: 'H2O2', isCorrect: false},
        ],
    },  {
        question:"The impurities are removed by washing with aquous :",
        answerOptions: [
            {answer: 'NaOH', isCorrect: false},
            {answer: 'HCl', isCorrect: false},
            {answer: 'HF', isCorrect: false},
            {answer: 'all of these', isCorrect: true},
        ],
    },  {
        question:"All elements form halides of the type MX3 :",
        answerOptions: [
            {answer: 'no', isCorrect: false},
            {answer: 'may be', isCorrect: false},
            {answer: 'yes', isCorrect: true},
            {answer: 'can not be said', isCorrect: false},
        ],
    },  {
        question:" Which of the following processes does not involve a catalys :",
        answerOptions: [
            {answer: 'Ostwald', isCorrect: false},
            {answer: 'Contact', isCorrect: false},
            {answer: 'Thermite', isCorrect: true},
            {answer: 'Lane', isCorrect: false},
        ],
    },  {
        question:"Powdered borax + conc. HCl is heated to produce :",
        answerOptions: [
            {answer: 'borax', isCorrect: false},
            {answer: 'kernite', isCorrect: false},
            {answer: 'boric acid', isCorrect: true},
            {answer: 'coleminite', isCorrect: false},
        ],
    },  {
        question:"Which of the following is expected not to exist :",
        answerOptions: [
            {answer: 'B5H11', isCorrect: false},
            {answer: 'B5H9', isCorrect: false},
            {answer: 'B5H10', isCorrect: true},
            {answer: 'B6H10', isCorrect: false},
        ],
    },  {
        question:" How many isomeric disubstituted borazine, B3 N3 H4 X2 are possible :",
        answerOptions: [
            {answer: 'six', isCorrect: false},
            {answer: 'four', isCorrect: true},
            {answer: 'five', isCorrect: false},
            {answer: 'three', isCorrect: false},
        ],
    },  {
        question:"When aluminum hydroxide dissolves in NaOH solution, the product is :",
        answerOptions: [
            {answer: '[Al(H2O)2(OH)4]-', isCorrect: true},
            {answer: '[Al(H2O)3(OH)3]', isCorrect: false},
            {answer: '[Al(H2O)4(OH)2]+', isCorrect: false},
            {answer: '[Al(H2O)(OH)5]^2+', isCorrect: false},
        ],
    },  {
        question:"The correct stability order of BX3 (X = F, Cl, Br, I) molecules is :",
        answerOptions: [
            {answer: 'BF3 > BCl3 > BBr3 > BI3', isCorrect: true},
            {answer: 'BF3 > BBr3 > BCl3 > BI3', isCorrect: false},
            {answer: 'BI3 > BBr3 > BCl3 > BF', isCorrect: false},
            {answer: 'BCl5> BI3 > BBr3 > BF3', isCorrect: false},
        ],
    },  {
        question:"Which of the following is not a proton acid in general way:",
        answerOptions: [
            {answer: 'PO(OH)3', isCorrect: false},
            {answer: 'B(OH)3', isCorrect: true},
            {answer: 'SO2(OH)2', isCorrect: false},
            {answer: 'So(OH)2', isCorrect: false},
        ],
    },  {
        question:"The correct order of atomic radii of Gr 13 elements is :",
        answerOptions: [
            {answer: 'B < Al < Ga < In', isCorrect: false},
            {answer: 'B < Al ~ Ga < In', isCorrect: true},
            {answer: 'B < Al < Ga ~ In', isCorrect: false},
            {answer: 'B < Ga < Al ~ In', isorrect: false},
        ],
    },
]

export default groupthirteenquestions;