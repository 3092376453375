import React from "react";
import '../pagesStyle/PrivacyPolicy.css';

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy">
      <div className="privacy-container">
        <h1 className="privacy-title">Privacy Policy</h1>

        <p className="privacy-paragraph">
          Welcome to our quiz social networking platform! This privacy policy explains how we handle your personal information when you use our website. By using our platform, you agree to the terms and conditions of this privacy policy. We are committed to ensuring that your privacy is protected.
        </p>

        <h2 className="privacy-subtitle">1. Information We Collect</h2>

        <p className="privacy-paragraph">
          We collect the following types of information from users:
          <ul>
            <li><strong>Account Information:</strong> When you sign up, we collect your name, email address, and password.</li>
            <li><strong>Profile Information:</strong> You can provide additional information such as a profile picture, bio, and location.</li>
            <li><strong>Quiz Interaction:</strong> We track your quiz participation, answers, scores, and performance statistics.</li>
            <li><strong>Engagement Data:</strong> We collect information related to your engagement on the platform, including likes, shares, retweets, and comments.</li>
            <li><strong>Activity Logs:</strong> We log your activity on the platform, including how you interact with quizzes and other users' posts.</li>
          </ul>
        </p>

        <h2 className="privacy-subtitle">2. How We Use Your Information</h2>

        <p className="privacy-paragraph">
          The information we collect is used for the following purposes:
          <ul>
            <li><strong>To Improve Your Experience:</strong> We use your profile data to tailor your experience and recommend quizzes that match your interests.</li>
            <li><strong>Analytics:</strong> We analyze quiz performance, user engagement, and activity to provide meaningful statistics on user rankings, quiz accuracy, and overall platform engagement.</li>
            <li><strong>Notifications:</strong> We may send you notifications about new quizzes, trending quizzes, or other important updates on the platform.</li>
            <li><strong>Security:</strong> We use information such as your IP address to protect the security of our platform and prevent fraudulent activity.</li>
          </ul>
        </p>

        <h2 className="privacy-subtitle">3. Data Sharing and Security</h2>

        <p className="privacy-paragraph">
          We do not share your personal information with third parties except in the following circumstances:
          <ul>
            <li>When it is necessary to comply with legal obligations or to protect the rights of our users and the public.</li>
            <li>With trusted partners and service providers who assist in operating our platform, provided they also maintain the confidentiality of your data.</li>
          </ul>
          We take reasonable precautions to protect your information from unauthorized access or disclosure. Your account information is protected by your password, which you should keep confidential.
        </p>

        <h2 className="privacy-subtitle">4. Your Privacy Rights</h2>

        <p className="privacy-paragraph">
          You have the right to:
          <ul>
            <li>Access the personal information we hold about you.</li>
            <li>Request corrections or updates to your information.</li>
            <li>Request deletion of your account and personal data.</li>
          </ul>
          You can manage your account settings through your profile page on our platform or contact us for assistance.
        </p>

        <h2 className="privacy-subtitle">5. Changes to this Privacy Policy</h2>

        <p className="privacy-paragraph">
          We may update this privacy policy from time to time to reflect changes in our practices or legal requirements. We will notify you of any significant changes and update the "Last Updated" date at the bottom of this policy.
        </p>

        <p className="privacy-paragraph">Last Updated: [9.20.2024]</p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
