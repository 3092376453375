const isomerismquestions = [
     {
        question:"Optical activity is measured by  :",
        answerOptions: [
            {answer: 'refeactometer', isCorrect: false},
            {answer: 'tracer technique', isCorrect: false},
            {answer: 'spectrograph', isCorrect: false},
            {answer: 'polarimeter', isCorrect: true},
        ],
    }, {
        question:"Which of the following shows geometrical isomerism  :",
        answerOptions: [
            {answer: 'but-1-ene', isCorrect: false},
            {answer: 'but-2-ene', isCorrect: true},
            {answer: '2,3-dichlorobutane', isCorrect: false},
            {answer: 'ethene', isCorrect: false},
        ],
    }, {
        question:"The total number of isomers for C4H8 are:",
        answerOptions: [
            {answer: '8', isCorrect: false},
            {answer: '7', isCorrect: false},
            {answer: '6', isCorrect: true},
            {answer: '5', isCorrect: false},
        ],
    }, {
        question:"Which of the following has zero dipole moment :",
        answerOptions: [
            {answer: '1-butene', isCorrect: false},
            {answer: 'cis-2-butene', isCorrect: false},
            {answer: 'trans-2-butene', isCorrect: true},
            {answer: 'can ne said', isCorrect: false},
        ],
    }, {
        question:"The number of isomers possible for C7H8O are :",
        answerOptions: [
            {answer: '3', isCorrect: false},
            {answer: '4', isCorrect: false},
            {answer: '5', isCorrect: true},
            {answer: '7', isCorrect: false},
        ],
    }, {
        question:"An isomer of ethanol is :",
        answerOptions: [
            {answer: 'methanol', isCorrect: false},
            {answer: 'diethyle ether', isCorrect: false},
            {answer: 'acetone', isCorrect: false},
            {answer: 'dimethyle ether', isCorrect: true},
        ],
    },   {
        question:"Which one of the following conformations of cyclohexane is chiral :(AIEEE 2007)",
        answerOptions: [
            {answer: 'rigid', isCorrect: false},
            {answer: 'chair', isCorrect: false},
            {answer: 'boat', isCorrect: false},
            {answer: 'twist boat', isCorrect: true},
        ],
    }, {
        question:"Same species lie on diagonally opposite side :",
        answerOptions: [
            {answer: 'cis', isCorrect: false},
            {answer: 'trans', isCorrect: true},
            {answer: 'both', isCorrect: false},
            {answer: 'none', isCorrect: false},
        ],
    }, {
        question:"Trans form are :",
        answerOptions: [
            {answer: 'opposite side', isCorrect: false},
            {answer: 'more stable', isCorrect: false},
            {answer: 'less reative', isCorrect: false},
            {answer: 'all of these', isCorrect: true},
        ],
    },{
        question:"Geometrical isomerism of CH3-CH=CH-CH=CH-Cl :",
        answerOptions: [
            {answer: '2', isCorrect: false},
            {answer: '3', isCorrect: false},
            {answer: '4', isCorrect: true},
            {answer: '5', isCorrect: false},
        ],
    }, {
        question:"Calculation of DBE is calculated by :",
        answerOptions: [
            {answer: 'n(V-2)/2 + 1', isCorrect: true},
            {answer: 'n(V-2) + 1', isCorrect: false},
            {answer: 'n(V-2)/2', isCorrect: false},
            {answer: 'n(V-2)/2 - 1', isCorrect: false},
        ],
    },{
        question:"The isomers will have either  :",
        answerOptions: [
            {answer: 'a double bond', isCorrect: false},
            {answer: 'a ring', isCorrect: false},
            {answer: 'two double bond or a ring', isCorrect: false},
            {answer: 'a double bond or a ring', isCorrect: true},
        ],
    },{
        question:"Which of the following is the correct IUPAC name : (NCERT)",
        answerOptions: [
            {answer: '3-Ethyl-4, 4-dimethylheptane', isCorrect: false},
            {answer: '4,4-Dimethyl-3-ethylheptane', isCorrect: true},
            {answer: '5-Ethyl-4, 4-dimethylheptane', isCorrect: false},
            {answer: '4,4-Bis(methyl)-3-ethylheptane', isCorrect: false},
        ],
    }, {
        question:"The most stable conformation of ethylene glycol is :",
        answerOptions: [
            {answer: 'anti', isCorrect: false},
            {answer: 'gauche', isCorrect: true},
            {answer: 'fully eclipsed', isCorrect: false},
            {answer: 'partially eclipdes', isCorrect: false},
        ],
    }, {
        question:"How many structural isomers of C4H10O that are ethers :",
        answerOptions: [
            {answer: '1', isCorrect: false},
            {answer: '2', isCorrect: false},
            {answer: '3', isCorrect: true},
            {answer: '4', isCorrect: false},
        ],
    }, {
        question:"The most stable conformation of n-butane is  :",
        answerOptions: [
            {answer: 'eclipsed', isCorrect: false},
            {answer: 'gauche', isCorrect: false},
            {answer: 'anti-staggered', isCorrect: true},
            {answer: 'skew-boat', isCorrect: false},
        ],
    }, {
        question:"Which of the following compounds will show metamerism  :",
        answerOptions: [
            {answer: 'CH3-O-C2H5', isCorrect: false},
            {answer: 'CO3-O-CH3', isCorrect: false},
            {answer: 'CH3-CO-C2H5', isCorrect: false},
            {answer: 'C2H5-S-C2H5', isCorrect: true},
        ],
    }, {
        question:"Among the following, the most stable compound is :",
        answerOptions: [
            {answer: 'cis-1, 2-cyclohexanediol', isCorrect: false},
            {answer: 'trans-1, 2-cyclohexanediol', isCorrect: false},
            {answer: 'cis-1, 3-cyclohexenediol', isCorrect: false},
            {answer: 'trans-1,3-cyclohexanediol', isCorrect: true},
        ],
    },  {
        question:" 2-methylpenta-2,3-diene is achiral as it has  :",
        answerOptions: [
            {answer: 'a centre of symmetry', isCorrect: false},
            {answer: 'a plane of symmetry', isCorrect: false},
            {answer: 'a 2 axis of symmetry', isCorrect: true},
            {answer: 'both centre and plane of symmetry', isCorrect: false},
        ],
    },  {
        question:"Identify the compound that exhibits tautomerism :(AIEEE 2011)",
        answerOptions: [
            {answer: 'lactic acid', isCorrect: false},
            {answer: '2-pantanone', isCorrect: true},
            {answer: 'phen-2-ol', isCorrect: false},
            {answer: '3-butene', isCorrect: false},
        ],
    },  {
        question:"How many chiral carbon atoms are present in 2, 3, 4-trichloropentane  :",
        answerOptions: [
            {answer: '3', isCorrect: false},
            {answer: '2', isCorrect: true},
            {answer: '1', isCorrect: false},
            {answer: '4', isCorrect: false},
        ],
    },  {
        question:"Of the five isomeric hexanes, the isomer which can give two monochlorination compound is  :(AIEEE 2005)",
        answerOptions: [
            {answer: 'n-hexane', isCorrect: false},
            {answer: '2, 3-dimethylbutane ', isCorrect: true},
            {answer: '2, 2-dimethylbutane', isCorrect: false},
            {answer: '2-methylpentane', isCorrect: false},
        ],
    },  {
        question:"The Cl – C – Cl angle in 1, 1, 2, 2-tetrachloroethene and tetrachloromethane will be about  :",
        answerOptions: [
            {answer: '120° and 109.5°', isCorrect: true},
            {answer: '90° and 109.5°', isCorrect: false},
            {answer: '109.5° and 90°', isCorrect: false},
            {answer: '109.5° and 120°', isCorrect: false},
        ],
    },  {
        question:"The number of optical isomers formed by hydrogenation of the compound, (CH3)2C = CHCH3 are  :",
        answerOptions: [
            {answer: '0', isCorrect: true},
            {answer: '1', isCorrect: false},
            {answer: '2', isCorrect: false},
            {answer: '3', isCorrect: false},
        ],
    },  {
        question:"The total number of optically active isomers for CH2OH(CHOH)3CHO are  :",
        answerOptions: [
            {answer: '16', isCorrect: false},
            {answer: '8', isCorrect: true},
            {answer: '4', isCorrect: false},
            {answer: '2', isCorrect: false},
        ],
    },  {
        question:"Keto – enol tautomerism is observed in :",
        answerOptions: [
            {answer: 'C6H5CHO', isCorrect: false},
            {answer: 'C6H5OCH3', isCorrect: false},
            {answer: 'C6H5COCH2COOH', isCorrect: true},
            {answer: 'C6COC6H5', isCorrect: false},
        ],
    },  {
        question:"The number of possible open chain (acyclic) isomeric compounds for molecular formula C5H10 would be  :",
        answerOptions: [
            {answer: '5', isCorrect: false},
            {answer: '6', isCorrect: true},
            {answer: '7', isCorrect: false},
            {answer: '4', isCorrect: false},
        ],
    },  {
        question:"Which will exhibit geometrical isomerism here :",
        answerOptions: [
            {answer: 'A2C=CAB', isCorrect: false},
            {answer: 'A2C=CB2', isCorrect: false},
            {answer: 'ABC=CAB', isCorrect: true},
            {answer: 'ABC=CX2', isCorrect: false},
        ],
    },  {
        question:"On monochlorination of n-pentane, the number of isomers formed is are :",
        answerOptions: [
            {answer: '4', isCorrect: false},
            {answer: '3', isCorrect: true},
            {answer: '2', isCorrect: false},
            {answer: '1', isCorrect: true},
        ],
    },  {
        question:"But-2-ene exhibits cis-transisomerism due to :",
        answerOptions: [
            {answer: 'rotation around C3–C4 sigma bond', isCorrect: false},
            {answer: 'rotation around C1–C2 bond', isCorrect: false},
            {answer: 'rotation around C=C bond', isCorrect: true},
            {answer: 'rotation around C2–C3 sigma bond', isCorrect: false},
        ],
    }, 
]

export default isomerismquestions;