const nomenclaturequestions = [
    {
        question:"The catenation bond strength follows the order :",
        answerOptions: [
            {answer: 'C > Si > S > P', isCorrect: true},
            {answer: 'Si > C > S > P', isCorrect: false},
            {answer: 'C > Si > P > S', isCorrect: false},
            {answer: 'C > S > Si > P', isCorrect: false},
        ],
    },  {
        question:"Cyclic compound have atleast  :",
        answerOptions: [
            {answer: 'one ring', isCorrect: false},
            {answer: 'one chain', isCorrect: false},
            {answer: 'one ring and one chain', isCorrect: true},
            {answer: 'can not be said', isCorrect: false},
        ],
    }, {
        question:"The number of primary amines of formula C4H11N are  :",
        answerOptions: [
            {answer: '2', isCorrect: false},
            {answer: '3', isCorrect: false},
            {answer: '4', isCorrect: true},
            {answer: '5', isCorrect: false},
        ],
    },  {
        question:"A carbon atom which is attached to two other carbon atoms is called  :",
        answerOptions: [
            {answer: 'primary C-atom', isCorrect: false},
            {answer: 'secondary C-atom', isCorrect: true},
            {answer: 'tetiary C-atom', isCorrect: false},
            {answer: 'can not be said', isCorrect: false},
        ],
    },  {
        question:"Compounds contain rings which are made up of :",
        answerOptions: [
            {answer: '1 kinds of atom', isCorrect: true},
            {answer: '2 kinds of atom', isCorrect: false},
            {answer: '3 kinds of atom', isCorrect: false},
            {answer: '4 kindds of atom', isCorrect: false},
        ],
    },  {
        question:"Suffix name of -OH functional group is :",
        answerOptions: [
            {answer: 'al', isCorrect: false},
            {answer: 'ol', isCorrect: true},
            {answer: 'thiol', isCorrect: false},
            {answer: 'oate', isCorrect: false},
        ],
    }, {
        question:"This is CH3-CH2-CH2-CH3 :",
        answerOptions: [
            {answer: '4-butane', isCorrect: false},
            {answer: 'n-butane', isCorrect: true},
            {answer: 'n-butene', isCorrect: false},
            {answer: 'n-butyne', isCorrect: false},
        ],
    },  {
        question:"How many H-atoms and C-atoms in 2-Chloro-pent-3-en-1-ol :",
        answerOptions: [
            {answer: '5 and 8', isCorrect: false},
            {answer: '8 and 5', isCorrect: false},
            {answer: '7 and 9', isCorrect: false},
            {answer: '5 and 9', isCorrect: true},
        ],
    },  {
        question:"CH2=CH-CH2-CH2-CH3, this is :",
        answerOptions: [
            {answer: 'pentane-1', isCorrect: false},
            {answer: 'pantene-2', isCorrect: false},
            {answer: 'pantene-1', isCorrect: true},
            {answer: 'pantene', isCorrect: false},
        ],
    }, {
        question:"CH3-CH2-CH2OH :",
        answerOptions: [
            {answer: 'propanol', isCorrect: true},
            {answer: 'propanal', isCorrect: false},
            {answer: '1-propanal', isCorrect: false},
            {answer: 'can not be said', isCorrect: false},
        ],
    },  {
        question:"C4H10O- it has :",
        answerOptions: [
            {answer: '2 alcohols and 2 ehters', isCorrect: false},
            {answer: '3 alcohols and 2 ehters', isCorrect: false},
            {answer: '2 alcohols and 3 ehters', isCorrect: false},
            {answer: '4 alcohols and 3 ehters', isCorrect: true},
        ],
    }, {
        question:"How many number of C-atoms and triple bond in But-2-yn-1-ol :",
        answerOptions: [
            {answer: '4 and 2', isCorrect: false},
            {answer: '4 and 0', isCorrect: false},
            {answer: '4 and 3', isCorrect: false},
            {answer: '4 and 1', isCorrect: true},
        ],
    }, {
        question:"Double Bond Equivalent of C4H6 :",
        answerOptions: [
            {answer: '1', isCorrect: false},
            {answer: '2', isCorrect: true},
            {answer: '3', isCorrect: false},
            {answer: '4', isCorrect: false},
        ],
    }, {
        question:"In which of the following, functional group isomerism is not possible :",
        answerOptions: [
            {answer: 'alcohols', isCorrect: false},
            {answer: 'aldehydes', isCorrect: false},
            {answer: 'alkyl halides', isCorrect: true},
            {answer: 'cyanides', isCorrect: false},
        ],
    },  {
        question:"The number of primary amines of formula C4H11N are :",
        answerOptions: [
            {answer: '2', isCorrect: false},
            {answer: '3', isCorrect: false},
            {answer: '4', isCorrect: true},
            {answer: '5', isCorrect: false},
        ],
    },  {
        question:"The hybridization of carbon atoms in C–C single bond of HC≡C–CH=CH2 is : ",
        answerOptions: [
            {answer: '3', isCorrect: false},
            {answer: '4', isCorrect: false},
            {answer: '5', isCorrect: false},
            {answer: '6', isCorrect: true},
        ],
    },  {
        question:"How many σ and π-bonds are there in the molecule of tetracyanoethylene :",
        answerOptions: [
            {answer: '9σ and 9π', isCorrect: true},
            {answer: '5σ and 9π', isCorrect: false},
            {answer: '9σ and 7π', isCorrect: false},
            {answer: '5σ and 8π', isCorrect: false},
        ],
    }, {
        question:"How many double bond and alcohal group in 3-Vinyl pentan-1,4-diol :",
        answerOptions: [
            {answer: '2 and 1', isCorrect: true},
            {answer: '1 and 2', isCorrect: false},
            {answer: '1 and 1', isCorrect: false},
            {answer: 'can not be said', isCorrect: false},
        ],
    },  {
        question:"The IUPAC name of neopentane is :",
        answerOptions: [
            {answer: '2, 2- dimethylpropane', isCorrect: true},
            {answer: '2- mehylpropane', isCorrect: false},
            {answer: '2, 2- dimethylbutane', isCorrect: false},
            {answer: '2- methylbytane', isCorrect: false},
        ],
    },  {
        question:"The maximum number of carbon atoms arranged linearly in the molecules,– C ≡ C – CH = CH2 are :",
        answerOptions: [
            {answer: '3', isCorrect: false},
            {answer: '4', isCorrect: true},
            {answer: '5', isCorrect: false},
            {answer: '6', isCorrect: false},
        ],
    }, 
]

export default nomenclaturequestions;