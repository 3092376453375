import React, { useEffect, useState } from "react";
import { auth, fireDB } from "../config/firebase";
import { onAuthStateChanged } from "firebase/auth";
import {
  doc,
  getDoc,
  collection,
  query,
  where,
  getDocs,
  setDoc,
  deleteDoc,
  updateDoc,
  arrayRemove,
} from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import PostQuiz from "./PostQuiz";
import "../componentsStyle/postedquiz.css";
import { increment } from "firebase/firestore";
import LoadingSpinner from "./LoadingSpinner";

const ITEMS_PER_PAGE = 1; // Number of questions to display per page

function PostedQuiz() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [postedQuestions, setPostedQuestions] = useState([]);
  const [editingQuestion, setEditingQuestion] = useState(null);
  const [updatedQuestion, setUpdatedQuestion] = useState("");
  const [updatedOptions, setUpdatedOptions] = useState(["", "", "", ""]);
  const [updatedCorrectAnswer, setUpdatedCorrectAnswer] = useState("");
  const [updatedCategory, setUpdatedCategory] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(postedQuestions.length / ITEMS_PER_PAGE);

  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      setLoading(true);
      if (currentUser) {
        setUser(currentUser);
        await fetchPostedQuestions(currentUser);
        setLoading(false);
      } else {
        navigate("/login");
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  const fetchPostedQuestions = async (currentUser) => {
    try {
      const userRef = doc(fireDB, "users", currentUser.uid);
      const userDoc = await getDoc(userRef);
      const postedQuestionsIds = userDoc.data().postedQuestions || [];

      if (postedQuestionsIds.length > 0) {
        const questionsQuery = query(
          collection(fireDB, "quizQuestions"),
          where("userId", "==", currentUser.uid)
        );
        const questionsSnapshot = await getDocs(questionsQuery);
        const questionsList = questionsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setPostedQuestions(questionsList);
      } else {
        setPostedQuestions([]);
      }
    } catch (error) {
      console.error("Error fetching posted questions:", error);
      toast.error("Failed to fetch posted questions. Please try again.");
    }
  };

  const calculateEngagementScore = (question) => {
    return (
      (question.likes || 0) * 1.5 +
      ((question.comments && question.comments.length) || 0) * 1.2 +
      (question.shares || 0) * 1.3 +
      ((question.retweetedBy && question.retweetedBy.length) || 0) * 1.1
    );
  };

  const calculateTotalEngagements = () => {
    return postedQuestions.reduce((total, question) => {
      return total + calculateEngagementScore(question);
    }, 0);
  };

  const handleDelete = async (questionId) => {
    try {
      const questionRef = doc(fireDB, "quizQuestions", questionId);
      await deleteDoc(questionRef);

      const userRef = doc(fireDB, "users", user.uid);
      await updateDoc(userRef, {
        postedQuestions: arrayRemove(questionId),
      });

      setPostedQuestions((prevQuestions) =>
        prevQuestions.filter((q) => q.id !== questionId)
      );
      toast.success("Question deleted successfully!");
    } catch (error) {
      console.error("Error deleting question:", error);
      toast.error("Failed to delete question. Please try again.");
    }
  };

  const handleEdit = (question) => {
    setEditingQuestion(question.id);
    setUpdatedQuestion(question.question);
    setUpdatedOptions(question.options);
    setUpdatedCorrectAnswer(question.correctAnswer);
    setUpdatedCategory(question.category);
  };

  const handleUpdate = async () => {
    if (
      !updatedQuestion ||
      updatedOptions.some((option) => !option) ||
      !updatedCorrectAnswer ||
      !updatedCategory
    ) {
      toast.error("Please fill in all fields");
      return;
    }

    try {
      const questionRef = doc(fireDB, "quizQuestions", editingQuestion);
      await updateDoc(questionRef, {
        question: updatedQuestion,
        options: updatedOptions,
        correctAnswer: updatedCorrectAnswer,
        category: updatedCategory,
      });

      toast.success("Question updated successfully!");
      setEditingQuestion(null);
      await fetchPostedQuestions(user); // Refresh questions after update
    } catch (error) {
      console.error("Error updating question:", error);
      toast.error("Failed to update question. Please try again.");
    }
  };

  const handleCancelEdit = () => {
    setEditingQuestion(null);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  // Slice the posted questions for the current page
  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const currentQuestions = postedQuestions.slice(
    startIndex,
    startIndex + ITEMS_PER_PAGE
  );

  return (
    <div className="dashboard-container">
      <div className="dashboard-content">
        <h2>My Posted Questions</h2>
        <PostQuiz
          user={user}
          fetchPostedQuestions={() => fetchPostedQuestions(user)}
        />

        <div className="total-stats">
          <h3>Total Posts: {postedQuestions.length}</h3>
          <h3>Total Engagements: {calculateTotalEngagements().toFixed(2)}</h3>
        </div>

        {postedQuestions.length > 0 ? (
          <ul className="questions-list">
            {currentQuestions.map((question) => (
              <li key={question.id} className="question-item">
                {editingQuestion === question.id ? (
                  <div className="edit-question-form">
                    <input
                      type="text"
                      value={updatedQuestion}
                      onChange={(e) => setUpdatedQuestion(e.target.value)}
                      placeholder="Question"
                    />
                    {updatedOptions.map((option, index) => (
                      <input
                        key={index}
                        type="text"
                        value={option}
                        onChange={(e) =>
                          setUpdatedOptions((prev) =>
                            prev.map((opt, idx) =>
                              idx === index ? e.target.value : opt
                            )
                          )
                        }
                        placeholder={`Option ${index + 1}`}
                      />
                    ))}
                    <input
                      type="text"
                      value={updatedCorrectAnswer}
                      onChange={(e) => setUpdatedCorrectAnswer(e.target.value)}
                      placeholder="Correct Answer"
                    />
                    <input
                      type="text"
                      value={updatedCategory}
                      onChange={(e) => setUpdatedCategory(e.target.value)}
                      placeholder="Category"
                    />
                    <button onClick={handleUpdate}>Save Changes</button>
                    <button onClick={handleCancelEdit}>Cancel</button>
                  </div>
                ) : (
                  <div>
                    <p>
                      <strong>Q:</strong> {question.question}
                    </p>
                    <p>
                      <strong>Options:</strong> {question.options.join(", ")}
                    </p>
                    <p>
                      <strong>Correct Answer:</strong> {question.correctAnswer}
                    </p>
                    <p>
                      <strong>Category:</strong> {question.category}
                    </p>

                    <p>
                      <strong>Likes:</strong> {question.likes || 0}
                    </p>
                    <p>
                      <strong>Shares:</strong> {question.shares || 0}
                    </p>
                    <p>
                      <strong>Comments:</strong>{" "}
                      {question.comments?.length || 0}
                    </p>
                    <p>
                      <strong>Retweets:</strong>{" "}
                      {question.retweetedBy?.length || 0}
                    </p>
                    <p>
                      Engagement Score:{" "}
                      {calculateEngagementScore(question).toFixed(2)}
                    </p>

                    <button onClick={() => handleEdit(question)}>Edit</button>
                    <button onClick={() => handleDelete(question.id)}>
                      Delete
                    </button>
                  </div>
                )}
              </li>
            ))}
          </ul>
        ) : (
          <p>No questions posted yet.</p>
        )}

        {/* Pagination Controls */}
        <div className="pagination">
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            Previous
          </button>
          <span>
            Page {currentPage} of {totalPages}
          </span>
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
}

export default PostedQuiz;
