const electrochemistryquestions = [
    {
        question:"The fraction of the current carried by an ion is calleed :",
        answerOptions: [
            {answer: 'transport number', isCorrect: false},
            {answer: 'Hittorf number', isCorrect: false},
            {answer: 'both of these', isCorrect: true},
            {answer: 'none of these', isCorrect: false},
        ],
    }, {
        question:"Current carried by the anion is equal to :",
        answerOptions: [
            {answer: 'K.ua', isCorrect: true},
            {answer: 'K.uc', isCorrect: false},
            {answer: 'ua', isCorrect: false},
            {answer: 'uc', isCorrect: false},
        ],
    },  {
        question:"How many type of buffer solutions :",
        answerOptions: [
            {answer: 'one', isCorrect: false},
            {answer: 'two', isCorrect: true},
            {answer: 'three', isCorrect: false},
            {answer: 'four', isCorrect: false},
        ],
    },  {
        question:"Total current carried is equal to :",
        answerOptions: [
            {answer: 'only anion', isCorrect: false},
            {answer: 'only cation', isCorrect: false},
            {answer: 'anion + cation', isCorrect: true},
            {answer: 'cation - anion', isCorrect: false},
        ],
    },  {
        question:"Conductance is inverse of :",
        answerOptions: [
            {answer: 'temperature', isCorrect: false},
            {answer: 'pressure', isCorrect: false},
            {answer: 'chemical potential', isCorrect: false},
            {answer: 'restivity', isCorrect: true},
        ],
    },  {
        question:"A device used to convert chemical energy of a reaction into elctrical energy is called :",
        answerOptions: [
            {answer: 'electrolytic', isCorrect: false},
            {answer: 'electrochemical', isCorrect: true},
            {answer: 'Zn + CuSO4', isCorrect: false},
            {answer: 'all', isCorrect: false},
           
        ],
    }, {
        question:"The effct that trends to retard the mobilities of ions in solution is :",
        answerOptions: [
            {answer: 'asymmetry effect', isCorrect: false},
            {answer: 'relaxation effect', isCorrect: false},
            {answer: 'electrophoretic effect', isCorrect: false},
            {answer: 'all of these', isCorrect: true},
        ],
    },  {
        question:"At anode: B^-1 ----> B + e^-1 is called  :",
        answerOptions: [
            {answer: 'oxidation', isCorrect: true},
            {answer: 'equilibrium', isCorrect: false},
            {answer: 'reduction', isCorrect: false},
            {answer: 'redox', isCorrect: false},
        ],
    },  {
        question:"Kohlrasch's law can be expressed as :",
        answerOptions: [
            {answer: 'λ(∞) = λ(a) + λ(c)', isCorrect: true},
            {answer: 'λ(∞) = λ(c) - λ(a)', isCorrect: false},
            {answer: 'λ(∞) = λ(a) + λ(c)', isCorrect: false},
            {answer: 'λ(∞) = λ(c) + λ(a)', isCorrect: false},
        ],
    },  {
        question:"The fraction of total current carried by the cation or anion is termed as :",
        answerOptions: [
            {answer: 'fractional number', isCorrect: false},
            {answer: 'speed number', isCorrect: false},
            {answer: 'carried number', isCorrect: false},
            {answer: 'all of these', isCorrect: true},
        ],
    },  {
        question:"During the tittation of a weak acide against a weak base, there is a sharp increase in -- at the end point :",
        answerOptions: [
            {answer: 'conductivity', isCorrect: true},
            {answer: 'equivalent conductance', isCorrect: false},
            {answer: 'specific conductance', isCorrect: false},
            {answer: 'none of these', isCorrect: false},
        ],
    },  {
        question:"When a strong acid is titrated against a strong base, the end point is the point of :",
        answerOptions: [
            {answer: 'zero conductance', isCorrect: false},
            {answer: 'maximum conductance', isCorrect: false},
            {answer: 'minimum conductance', isCorrect: true},
            {answer: '∞ conductance', isCorrect: false},
        ],
    },  {
        question:"The example of copper-copper ion electrode, when oxidation takes place at the electrode, the reaction may be represented as :",
        answerOptions: [
            {answer: 'Cu ---> Cu^+2 + 2e^-1', isCorrect: true},
            {answer: 'Cu ---> Cu^-2 + 2e^-1', isCorrect: false},
            {answer: 'Cu ---> Cu² + 2e^1', isCorrect: false},
            {answer: 'Cu ---> Cu^-2 + 2e^-1', isCorrect: false},
        ],
    },  {
        question:"Arrange the following metals in the order in which they displace each other from the solution of their salt; Al, Cu, Fe, Mg :",
        answerOptions: [
            {answer: 'Mg, Al, Fe, Zn', isCorrect: true},
            {answer: 'Mg, Fe, Al, Zn', isCorrect: false},
            {answer: 'Mg, Al, Zn, Al', isCorrect: false},
            {answer: 'Al, Mg, Fe, Zn', isCorrect: false},
        ],
    },  {
        question:"In an electrochemical cell :",
        answerOptions: [
            {answer: 'potentail energy decrese', isCorrect: false},
            {answer: 'kinetic energy decrease', isCorrect: false},
            {answer: 'potentail energy changes into electrical energy', isCorrect: false},
            {answer: 'chemical energy changes into electrical energy', isCorrect: true},
        ],
    },  {
        question:"Both anionic and cationic hydrolysis occur in the case of slats of weak acid and :",
        answerOptions: [
            {answer: 'strong base', isCorrect: false},
            {answer: 'weak base', isCorrect: true},
            {answer: 'strong acid', isCorrect: false},
            {answer: 'weak acid', isCorrect: false},
        ],
    },  {
        question:"Does buffer solutions useful in industrical and biological processes :",
        answerOptions: [
            {answer: 'no', isCorrect: false},
            {answer: 'yes', isCorrect: true},
            {answer: 'may be', isCorrect: false},
            {answer: 'in past time', isCorrect: false},
        ],
    },  {
        question:"An example of an acid buffer is a solution of :",
        answerOptions: [
            {answer: 'ammonium acetate', isCorrect: false},
            {answer: 'mix. of NH4OH + NH4Cl', isCorrect: false},
            {answer: 'mix. of CH3COOH + CH3COONa', isCorrect: true},
            {answer: 'sol. of CH3COONa', isCorrect: false},
        ],
    },  {
        question:"Three elements A, B and C have reduction potential -1.5, -0.05, and +1.50. Then the correct order of their reducing power is :",
        answerOptions: [
            {answer: 'A > B > C', isCorrect: true},
            {answer: 'B > A > C', isCorrect: false},
            {answer: 'C > B > A', isCorrect: false},
            {answer: 'B > C > A', isCorrect: false },
        ],
    },  {
        question:"Electrod where the oxidation takes place is called :",
        answerOptions: [
            {answer: 'thermal equilibrium', isCorrect: false},
            {answer: 'phase diagram', isCorrect: false},
            {answer: 'cathod', isCorrect: false},
            {answer: 'anode', isCorrect: true},
        ],
    },  {
        question:"The pH of an acidic buffer according to the Henderson equation is given by :",
        answerOptions: [
            {answer: 'pKa - log[salt]/[acid]', isCorrect: false},
            {answer: 'pKa + log[salt]/[acid]', isCorrect: true},
            {answer: 'pKa - log[acid]/[salt]', isCorrect: false},
            {answer: 'pKa + log[acid]/[salt]', isCorrect: false},
        ],
    },  {
        question:"Which is flow from positive pole to negative pole :",
        answerOptions: [
            {answer: 'proton', isCorrect: false},
            {answer: 'electron', isCorrect: true},
            {answer: 'electrical energy', isCorrect: false},
            {answer: 'none of these', isCorrect: false},
        ],
    },  {
        question:"The tendency of an electrolyte to lose or gain electrons when it is in contact with its own ions in the solution is called :",
        answerOptions: [
            {answer: 'electrolytic', isCorrect: false},
            {answer: 'electrochemical', isCorrect: false},
            {answer: 'electrode potential', isCorrect: true},
            {answer: 'none of these', isCorrect: false},
        ],
    },  {
        question:"The pH of a solution of salt of strong acid and weak base is represented by :",
        answerOptions: [
            {answer: '1/2(log Kw + log Kb + log C)', isCorrect: false},
            {answer: '1/2(log Kw - log Kb - log C)', isCorrect: false},
            {answer: '1/2(log Kw - log Kb + log C)', isCorrect: true},
            {answer: '1/2(log Kw - log Kb - log C)', isCorrect: false},
        ],
    },  {
        question:"The force which cause the flow of electrons from one electrods to the other is called :",
        answerOptions: [
            {answer: 'electrods', isCorrect: false},
            {answer: 'electrochemical', isCorrect: false},
            {answer: 'electromotive', isCorrect: true},
            {answer: 'data insufficient', isCorrect: false},
        ],
    },  {
        question:"The pH pf a solution of strong acid and weak base is less than 57.3 kL/mol. This is because :",
        answerOptions: [
            {answer: 'at 7', isCorrect: false},
            {answer: 'more than 7', isCorrect: false},
            {answer: 'less than 7', isCorrect: true},
            {answer: 'data insufficient', isCorrect: false},
        ],
    },  {
        question:"Oxidized state + n electrons is equal to :",
        answerOptions: [
            {answer: 'electrolytic', isCorrect: false},
            {answer: 'reduced state', isCorrect: true},
            {answer: 'oxidised state', isCorrect: false},
            {answer: 'redox state', isCorrect: false},
        ],
    },  {
        question:"Ostwald diluation law is expressed by :",
        answerOptions: [
            {answer: 'K = C aplha² / 1 - alpha', isCorrect: true},
            {answer: 'k = C aplha² / 1 + alpha', isCorrect: false},
            {answer: 'k = C aplha^-1 / 1 - alpha', isCorrect: false},
            {answer: 'k = C aplha / 1 - alpha', isCorrect: false},
        ],
    },  {
        question:"Advantages of using glass electrodes other electrodes because :",
        answerOptions: [
            {answer: 'easy to use', isCorrect: false},
            {answer: 'not poisoned easily', isCorrect: false},
            {answer: 'easy to use and not poisoned easily', isCorrect: false},
            {answer: 'all of these', isCorrect: true},
        ],
    },  {
        question:"The process of slow eating away of the metal due to the attack of atmospheric gases is know as :",
        answerOptions: [
            {answer: 'electrolytic', isCorrect: false},
            {answer: 'rusting', isCorrect: false},
            {answer: 'corrosion', isCorrect: true},
            {answer: 'all are these', isorrect: false},
        ],
    },
]

export default electrochemistryquestions;