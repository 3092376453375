import React, { useEffect, useState } from "react";
import { collection, getDocs, orderBy, limit, query, startAfter } from "firebase/firestore";
import { fireDB } from "../config/firebase"; // Ensure you're importing from the right config file
import { getAuth } from "firebase/auth"; // Import getAuth from firebase/auth
import Vote from "./Vote"; // Assuming Vote component is in the same directory

const VotingTrends = () => {
  const [topQuestions, setTopQuestions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [userId, setUserId] = useState(null); // Initialize userId state
  const [lastVisible, setLastVisible] = useState(null); // Store the last visible document for pagination

  useEffect(() => {
    const auth = getAuth(); // Use getAuth to get the current user
    const user = auth.currentUser;
    if (user) {
      setUserId(user.uid); // Set userId from current user
      localStorage.setItem("userId", user.uid); // Store userId in localStorage
    } else {
      const storedUserId = localStorage.getItem("userId"); // Retrieve userId from localStorage if not authenticated
      if (storedUserId) {
        setUserId(storedUserId); // Set the stored userId if available
      }
    }
  }, []);

  // Function to fetch top-rated questions
  const fetchTopRatedQuestions = async () => {
    try {
      setLoading(true);

      const questionsQuery = query(
        collection(fireDB, "voteQuestions"),
        orderBy("totalRatings", "desc"),
        limit(5) // Limit to top 5 questions initially
      );

      const querySnapshot = await getDocs(questionsQuery);
      const questions = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      console.log(questions); // Log the fetched questions to check the data structure

      setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]); // Set last visible document
      setTopQuestions(questions);
    } catch (error) {
      console.error("Error fetching top-rated questions:", error);
    } finally {
      setLoading(false);
    }
  };

  // Function to fetch more questions
  const fetchMoreQuestions = async () => {
    if (!lastVisible) return; // Don't fetch more if no last visible document

    try {
      setLoading(true);

      const questionsQuery = query(
        collection(fireDB, "voteQuestions"),
        orderBy("totalRatings", "desc"),
        startAfter(lastVisible), // Start after the last fetched document
        limit(5) // Limit to the next 5 questions
      );

      const querySnapshot = await getDocs(questionsQuery);
      const questions = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      console.log(questions); // Log the fetched questions to check the data structure

      setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]); // Update last visible
      setTopQuestions((prevQuestions) => [...prevQuestions, ...questions]); // Append new questions
    } catch (error) {
      console.error("Error fetching more questions:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTopRatedQuestions();
  }, []);

  const handleScroll = (e) => {
    // Check if user has scrolled to the bottom of the list
    const bottom =
      e.target.scrollHeight === e.target.scrollTop + e.target.clientHeight;
    if (bottom && !loading) {
      fetchMoreQuestions(); // Fetch more questions when at the bottom
    }
  };

  if (loading && topQuestions.length === 0) {
    return <p>Loading trending questions...</p>;
  }

  return (
    <div onScroll={handleScroll} style={{ maxHeight: "500px", overflowY: "scroll" }}>
      <h3>Top Trending Questions</h3>
      <ul>
        {topQuestions.length > 0 ? (
          topQuestions.map((question) => {
            const avgRating =
              question.totalRatings > 0
                ? (question.ratingSum / question.totalRatings).toFixed(1)
                : 0;

            return (
              <li key={question.id} style={{ marginBottom: "20px" }}>
                <h4>{question.question || "No question text available"}</h4>
                <p>
                  Average Rating: {avgRating} ★
                  {avgRating > 0 && <span> ({question.totalRatings} ratings)</span>}
                </p>
                <Vote
                  questionId={question.id}
                  yesCount={question.Yes || 0}
                  noCount={question.No || 0}
                  onVoteUpdate={() => {
                    console.log("Vote updated for question:", question.id);
                  }}
                />
              </li>
            );
          })
        ) : (
          <p>No questions available.</p>
        )}
      </ul>
      {loading && <p>Loading more questions...</p>}
    </div>
  );
};

export default VotingTrends;
