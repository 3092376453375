const electrostaticsquestions = [
  {
    question: " The charge on electron is :",
    answerOptions: [
      { answer: "1.6 x 10⁻¹⁹ ", isCorrect: false }, //
      { answer: "-1.6 x 10⁻¹⁹", isCorrect: true },
      { answer: "0", isCorrect: false },
      { answer: "3.10 x 10⁻¹⁹", isCorrect: false },
    ],
  },
  {
    question:
      " Ten capicitors are joined in parallel and charged with a battery up to a potentail V. They are disconneced from the battery and joined in series. Then, the potential of this combination will be :",
    answerOptions: [
      { answer: "1 V", isCorrect: false },
      { answer: "10 V", isCorrect: true },
      { answer: "5 V", isCorrect: false },
      { answer: "2 V", isCorrect: false },
    ],
  },
  {
    question: " Can body having a charge of 4.8x10^-18  :", //
    answerOptions: [
      { answer: "0", isCorrect: false },
      { answer: "-3.2x10^-10", isCorrect: true },
      { answer: "3.2x10^-10", isCorrect: false },
      { answer: "None of these", isCorrect: false },
    ],
  },
  {
    question:
      " The potential function of an electrostatics field is given by V=2x². Determine the electric field strength at the point(2m, 0, 3m) :",
    answerOptions: [
      { answer: "E=-4i(N/C)", isCorrect: false },
      { answer: "E=-4i(N/C)", isCorrect: true },
      { answer: "E=8i(N/C)", isCorrect: false },
      { answer: "E=4i(N/C)", isCorrect: false },
    ],
  },
  {
    question:
      "The force between two point of charges in air is 100 N. Calculate the force if the the distance them is increased by 50% :",
    answerOptions: [
      { answer: "44 N", isCorrect: true },
      { answer: "440 N", isCorrect: true },
      { answer: "Both", isCorrect: false },
      { answer: "None of these", isCorrect: false },
    ],
  },
  {
    question:
      " Three equal charges, each +q, are placed on corner of equilateral triangle. The electric field intensity at thr corner of triangle :",
    answerOptions: [
      { answer: "kq/r²", isCorrect: false },
      { answer: "3kq/r²", isCorrect: false },
      { answer: "3kq/r", isCorrect: false },
      { answer: "0", isCorrect: true },
    ],
  },
  {
    question:
      "When the seperation between charges is increased the electric potential energy of two charges :",
    answerOptions: [
      { answer: "increases", isCorrect: false },
      { answer: "remain the same", isCorrect: true },
      { answer: "decreses", isCorrect: false },
      { answer: "all", isCorrect: false },
    ],
  },
  {
    question:
      " A point charge of 100 muC is placed at 3i+4j m. Find the electric field intensity at the point located at 9i+12j :",
    answerOptions: [
      { answer: "8000 V/m", isCorrect: false },
      { answer: "9000 V/m", isCorrect: true },
      { answer: "2250V/m", isCorrect: false },
      { answer: "None of these", isCorrect: false },
    ],
  },
  {
    question:
      " Two equal charges each of 10 muC are placed at the two diametrically opposite point of a circle of radius 10cm. Calculate the resultant field at the centre :",
    answerOptions: [
      { answer: "1", isCorrect: false },
      { answer: "0", isCorrect: true },
      { answer: "3.2x10^-10", isCorrect: false },
      { answer: "None of these", isCorrect: false },
    ],
  },
  {
    question:
      " Two charges q1 = 18 μC and q2 = -2 μC are seperated by the distance r, and q1 is on left of q2. The distance of a point where the net electric field is zero is :",
    answerOptions: [
      { answer: "between q1 and q2", isCorrect: false },
      { answer: "left on q1 at r/2", isCorrect: false },
      { answer: "right of q2 at r", isCorrect: false },
      { answer: "right of q2 at r/2", isCorrect: false },
    ],
  },
  {
    question:
      " A cylinder of length L and radius b has its axis coincident with x-axis. The electric field in this region is E = 200. Find the flux through the left end of the cylinder.",
    answerOptions: [
      { answer: "200 πb²", isCorrect: false },
      { answer: "0", isCorrect: false },
      { answer: "100 πb²", isCorrect: false },
      { answer: "-200 πb²", isCorrect: true },
    ],
  },
  {
    question:
      " A hollow matallic sphare of radius 10 cm is given a charge of 3.2 x 10⁻⁹C. The electric intensity at a point 4 cm from the centre is :",
    answerOptions: [
      { answer: "9 x 10⁻⁹ N/C", isCorrect: false },
      { answer: "288 N/C", isCorrect: false },
      { answer: "2.88 N/C", isCorrect: false },
      { answer: "0", isCorrect: true },
    ],
  },
  {
    question:
      " The surface density on a copper sphare is s. The electric field strength of the sphare is: ",
    answerOptions: [
      { answer: "s", isCorrect: false },
      { answer: "s/2", isCorrect: false },
      { answer: "s/2ε0", isCorrect: false },
      { answer: "s/ε0", isCorrect: true },
    ],
  },
  {
    question:
      "A cube with edge length l produces an electric flux of the form π. The value of the charge will be when the enclosed charge is cut in half and an edge of the cube is made 2L : ",
    answerOptions: [
      { answer: "2π", isCorrect: false },
      { answer: "π/2", isCorrect: true },
      { answer: "π", isCorrect: false },
      { answer: "4π", isCorrect: false },
    ],
  },
  {
    question:
      "A 0.1 μC q charge is contained within a cube of 10 cm in size. Determine the quantity of force lines passing through each cube face.",
    answerOptions: [
      { answer: "1883", isCorrect: true },
      { answer: "1.113x10^11", isCorrect: false },
      { answer: "1.13x10^9", isCorrect: false },
      { answer: "1.13x10^4", isCorrect: false },
    ],
  },
  {
    question:
      " A capacitor is charged to store an energy U. The charging battery is disconnected. An identical capacitor is now connected to the first capacitor in parallel. The energy in each capacitor is now :",
    answerOptions: [
      { answer: "3U/2", isCorrect: false },
      { answer: "U/2", isCorrect: false },
      { answer: "U/4", isCorrect: true },
      { answer: "U", isCorrect: false },
    ],
  },
  {
    question:
      " A cylender of radius R and length L is placed in a uniform electrci field E parallel to the axis of the cylender. The total flux over the curved surface of the cylender is :",
    answerOptions: [
      { answer: "E/πR²", isCorrect: false },
      { answer: "πR²E", isCorrect: false },
      { answer: "2πR²E", isCorrect: false },
      { answer: "0", isCorrect: true },
    ],
  },
  {
    question:
      "In a region of space, the electric field is given by E = 8i + 4j + 3k. The electric flux through a surface of area 100 units in xy plane is :",
    answerOptions: [
      { answer: "300", isCorrect: true },
      { answer: "1500", isCorrect: false },
      { answer: "800", isCorrect: false },
      { answer: "400", isCorrect: false },
    ],
  },
  {
    question:
      " A 2 μF capicitor is charged to 100 V, and then its plates are connected by a conducting wire. The heat produced is :",
    answerOptions: [
      { answer: "0.001 J", isCorrect: false },
      { answer: "0.01 J", isCorrect: true },
      { answer: "0.1 J", isCorrect: false },
      { answer: "1 J", isCorrect: false },
    ],
  },
  {
    question: " SI unit of electric flex :",
    answerOptions: [
      { answer: "Volt", isCorrect: false },
      { answer: "Vm", isCorrect: true },
      { answer: "Columb", isCorrect: false },
      { answer: "None of these", isCorrect: false },
    ],
  },
  {
    question:
      "A spherical shell of radius R = 1.5 cm has a charge q = 20 μC uniformly distributed over it. The force exerted  by one half over the other half is :",
    answerOptions: [
      { answer: "600", isCorrect: false },
      { answer: "800", isCorrect: false },
      { answer: "2000", isCorrect: true },
      { answer: "1600", isCorrect: false },
    ],
  },
  {
    question:
      " A 16 μF capacitor initially charged to 5V, is started charging at t = 0 by a source at the rate of 40μC/s. How long will it take to raise its potential to 10V :",
    answerOptions: [
      { answer: "1s", isCorrect: false },
      { answer: "2s", isCorrect: true },
      { answer: "3s", isCorrect: false },
      { answer: "none of these", isCorrect: false },
    ],
  },
  {
    question:
      " Claculate the electric flex over the surface of a sphere if it is charged with 10 μC of charge :",
    answerOptions: [
      { answer: "36x10^-5", isCorrect: true },
      { answer: "36", isCorrect: false },
      { answer: "0", isCorrect: true },
      { answer: "None of these", isCorrect: false },
    ],
  },
  {
    question: "calculate the flux which will originate from a charge of 1 μC :",
    answerOptions: [
      { answer: "0", isCorrect: false },
      { answer: "-1.6x10^-19", isCorrect: false },
      { answer: "1.13πx10^5", isCorrect: false },
      { answer: "1.13x10^5", isCorrect: true },
    ],
  },
  {
    question: " Can body having a charge of 4.8x10^-18 :",
    answerOptions: [
      { answer: "0", isCorrect: false },
      { answer: "-3.2x10^-10", isCorrect: true },
      { answer: "3.2x10^-10", isCorrect: false },
      { answer: "None of these", isCorrect: false },
    ],
  },
  {
    question:
      "At a point in a space, the elctric field point toward north. In the region surrounding this point, the rate of charge of potential will be 0 along",
    answerOptions: [
      { answer: "north", isCorrect: false },
      { answer: "south", isCorrect: false },
      { answer: "north-south", isCorrect: false },
      { answer: "east-west", isCorrect: true },
    ],
  },
  {
    question:
      " Find the potential V of an electrostatics field E = a(yi+xj), where a is constant :",
    answerOptions: [
      { answer: "axy + C", isCorrect: false },
      { answer: "-axy + C", isCorrect: true },
      { answer: "axy", isCorrect: false },
      { answer: "-axy", isCorrect: false },
    ],
  },
  {
    question:
      " The work done in increasing the potential of a capicitor from V volt to 2V volt is W. Then, the done increasing the potential of the same capicitor from 2V volt to 4V volt will be :",
    answerOptions: [
      { answer: "W", isCorrect: false },
      { answer: "2W", isCorrect: false },
      { answer: "4W", isCorrect: true },
      { answer: "8W", isCorrect: false },
    ],
  },
  {
    question:
      " The potential V is varying with x as V=1/2(y²-4x) volt. The field at x = 1m, y = 1m :",
    answerOptions: [
      { answer: "2i+j", isCorrect: false },
      { answer: "-2i+j", isCorrect: false },
      { answer: "2i-j", isCorrect: true },
      { answer: "-2i+2j", isCorrect: false },
    ],
  },
  {
    question:
      "In a region of space, the electric field is given by E = 8i + 4j + 3k. The electric flux through a surface of area 100 units in the plane is  :",
    answerOptions: [
      { answer: "800 units", isCorrect: true },
      { answer: "1500 units", isCorrect: false },
      { answer: "400 units", isCorrect: false },
      { answer: "300 units", isCorrect: false },
    ],
  },
  {
    question:
      "Two point charges, +3 µC and -2 µC, are placed 10 cm apart. What is the magnitude of the electrostatic force between them? (Use Coulomb's constant ( k = 8.99 \times 10^9 \text{ N m}^2/\text{C}^2 ))",
    answerOptions: [
      { answer: "5.39 N", isCorrect: true },
      { answer: "7.19 N", isCorrect: false },
      { answer: "2.77 N", isCorrect: false },
      { answer: "10.99 N", isCorrect: false },
    ],
  },
  {
    question:
      "A charge of +6 µC is placed in an electric field of 2000 N/C. What is the force experienced by the charge?",
    answerOptions: [
      { answer: "12 N", isCorrect: true },
      { answer: "6 N", isCorrect: false },
      { answer: "2 N", isCorrect: false },
      { answer: "8 N", isCorrect: false },
    ],
  },
  {
    question:
      "What is the potential difference between two points if the electric field between them is 500 N/C and the distance is 2 meters?",
    answerOptions: [
      { answer: "1000 V", isCorrect: true },
      { answer: "250 V", isCorrect: false },
      { answer: "500 V", isCorrect: false },
      { answer: "2000 V", isCorrect: false },
    ],
  },
  {
    question:
      "A capacitor of 10 µF is connected to a 12V battery. What is the charge stored in the capacitor?",
    answerOptions: [
      { answer: "120 µC", isCorrect: true },
      { answer: "60 µC", isCorrect: false },
      { answer: "240 µC", isCorrect: false },
      { answer: "12 µC", isCorrect: false },
    ],
  },
  {
    question:
      "If a capacitor with a capacitance of 5 µF is charged to a potential difference of 10 V, what is the energy stored in the capacitor?",
    answerOptions: [
      { answer: "0.25 mJ", isCorrect: true },
      { answer: "0.5 mJ", isCorrect: false },
      { answer: "1 mJ", isCorrect: false },
      { answer: "2.5 mJ", isCorrect: false },
    ],
  },
  {
    question:
      "What is the electric field at a distance of 4 meters from a point charge of +8 µC?",
    answerOptions: [
      { answer: "5 \times 10^3 N/C", isCorrect: true },
      { answer: "1 \times 10^3 N/C", isCorrect: false },
      { answer: "2 \times 10^3 N/C", isCorrect: false },
      { answer: "10^4 N/C", isCorrect: false },
    ],
  },
  {
    question:
      "A sphere of radius 2 meters has a total charge of +10 µC uniformly distributed on its surface. What is the surface charge density?",
    answerOptions: [
      { answer: "7.96 \times 10^{-9} C/m²", isCorrect: true },
      { answer: "3.98 \times 10^{-8} C/m²", isCorrect: false },
      { answer: "5 \times 10^{-8} C/m²", isCorrect: false },
      { answer: "10^{-8} C/m²", isCorrect: false },
    ],
  },
  {
    question:
      "If two charges of +5 µC and -5 µC are placed 0.5 meters apart, what is the potential energy of the system? (Use Coulomb's constant ( k = 8.99 \times 10^9 \text{ N m}^2/\text{C}^2 ))",
    answerOptions: [
      { answer: "-89.9 J", isCorrect: true },
      { answer: "-44.95 J", isCorrect: false },
      { answer: "89.9 J", isCorrect: false },
      { answer: "44.95 J", isCorrect: false },
    ],
  },
  {
    question:
      "A parallel plate capacitor has a plate area of 0.01 m² and a separation of 1 mm. If the capacitor is charged to a potential difference of 500 V, what is the capacitance?",
    answerOptions: [
      { answer: "8.85 mu F", isCorrect: true },
      { answer: "5 mu F", isCorrect: false },
      { answer: "10 mu F", isCorrect: false },
      { answer: "4.4 mu F", isCorrect: false },
    ],
  },
  {
    question:
      "A dielectric material with a relative permittivity of 8 is placed between the plates of a capacitor. If the initial capacitance was 10 µF, what will be the new capacitance?",
    answerOptions: [
      { answer: "80 µF", isCorrect: true },
      { answer: "18 µF", isCorrect: false },
      { answer: "40 µF", isCorrect: false },
      { answer: "12 µF", isCorrect: false },
    ],
  },
  {
    question:
      "A charged conductor is brought near an uncharged conductor. What happens to the charge distribution in the uncharged conductor?",
    answerOptions: [
      { answer: "Charge distribution becomes polarized", isCorrect: true },
      { answer: "Charge distribution remains unchanged", isCorrect: false },
      { answer: "Charge distribution becomes uniform", isCorrect: false },
      {
        answer: "Charge is transferred to the charged conductor",
        isCorrect: false,
      },
    ],
  },
  {
    question:
      "What is the total electric potential due to two point charges of +3 µC and -2 µC located 0.5 meters apart at a point midway between them? (Use Coulomb's constant ( k = 8.99 \times 10^9 \text{ N m}^2/\text{C}^2 ))",
    answerOptions: [
      { answer: "5.39 \times 10^4 V", isCorrect: true },
      { answer: "2.69 \times 10^4 V", isCorrect: false },
      { answer: "8.99 \times 10^4 V", isCorrect: false },
      { answer: "4.49 \times 10^4 V", isCorrect: false },
    ],
  },
  {
    question:
      "If a capacitor is connected to a 12V battery and stores a charge of 24 µC, what is its capacitance?",
    answerOptions: [
      { answer: "2 µF", isCorrect: true },
      { answer: "4 µF", isCorrect: false },
      { answer: "6 µF", isCorrect: false },
      { answer: "8 µF", isCorrect: false },
    ],
  },
  {
    question:
      "An electric field of 1000 N/C is applied to a region. If the electric potential in that region changes by 10 V, what is the distance over which this change occurs?",
    answerOptions: [
      { answer: "10 meters", isCorrect: true },
      { answer: "5 meters", isCorrect: false },
      { answer: "20 meters", isCorrect: false },
      { answer: "50 meters", isCorrect: false },
    ],
  },
  {
    question:
      "What is the magnitude of the electric field at a point 2 meters away from a charge of +5 µC?",
    answerOptions: [
      { answer: "1.12 \times 10^3 N/C", isCorrect: true },
      { answer: "2.5 \times 10^3 N/C", isCorrect: false },
      { answer: "5 \times 10^2 N/C", isCorrect: false },
      { answer: "4 \times 10^3 N/C", isCorrect: false },
    ],
  },
  {
    question:
      "A point charge of +8 µC is placed at the origin. What is the electric potential at a point 3 meters away from the charge?",
    answerOptions: [
      { answer: "2.40 \times 10^4 V", isCorrect: true },
      { answer: "1.60 \times 10^4 V", isCorrect: false },
      { answer: "8 \times 10^3 V", isCorrect: false },
      { answer: "4 \times 10^4 V", isCorrect: false },
    ],
  },
  {
    question:
      "A conductor has a charge of +4 µC. If the conductor is placed in a uniform electric field of 5000 N/C, what is the force experienced by the conductor?",
    answerOptions: [
      { answer: "20 mN", isCorrect: true },
      { answer: "10 mN", isCorrect: false },
      { answer: "40 mN", isCorrect: false },
      { answer: "30 mN", isCorrect: false },
    ],
  },
  {
    question:
      "What is the total electric flux through a spherical surface of radius 5 meters enclosing a charge of +3 µC?",
    answerOptions: [
      { answer: "2.70 \times 10^{-6} \text{ Nm}^2/\text{C}", isCorrect: true },
      { answer: "1.20 \times 10^{-6} \text{ Nm}^2/\text{C}", isCorrect: false },
      { answer: "4.50 \times 10^{-6} \text{ Nm}^2/\text{C}", isCorrect: false },
      { answer: "6.00 \times 10^{-6} \text{ Nm}^2/\text{C}", isCorrect: false },
    ],
  },
  {
    question:
      "A capacitor is connected to a 15V battery and stores 45 µC of charge. What is the capacitance of the capacitor?",
    answerOptions: [
      { answer: "3 µF", isCorrect: true },
      { answer: "4 µF", isCorrect: false },
      { answer: "2 µF", isCorrect: false },
      { answer: "6 µF", isCorrect: false },
    ],
  },
  {
    question:
      "An isolated conductor is given a charge of +6 µC. What happens to the electric potential energy of the conductor if the charge is doubled to +12 µC?",
    answerOptions: [
      {
        answer: "The electric potential energy increases by a factor of 4",
        isCorrect: true,
      },
      {
        answer: "The electric potential energy increases by a factor of 2",
        isCorrect: false,
      },
      {
        answer: "The electric potential energy remains the same",
        isCorrect: false,
      },
      {
        answer: "The electric potential energy decreases by a factor of 4",
        isCorrect: false,
      },
    ],
  },
  {
    question:
      "Two parallel plates of a capacitor are separated by 1 mm and have an area of 0.02 m² each. If the potential difference between the plates is 2000 V, what is the electric field between the plates?",
    answerOptions: [
      { answer: "2 \times 10^6 N/C", isCorrect: true },
      { answer: "1 \times 10^6 N/C", isCorrect: false },
      { answer: "3 \times 10^6 N/C", isCorrect: false },
      { answer: "4 \times 10^6 N/C", isCorrect: false },
    ],
  },
  {
    question:
      "A metal sphere of radius 0.5 meters carries a charge of +10 µC. What is the electric field strength on the surface of the sphere?",
    answerOptions: [
      { answer: "5.40 \times 10^4 N/C", isCorrect: true },
      { answer: "2.40 \times 10^4 N/C", isCorrect: false },
      { answer: "1.20 \times 10^4 N/C", isCorrect: false },
      { answer: "3.60 \times 10^4 N/C", isCorrect: false },
    ],
  },
  {
    question:
      "What is the capacitance of a capacitor if the stored charge is 20 µC and the voltage across it is 4 V?",
    answerOptions: [
      { answer: "5 µF", isCorrect: true },
      { answer: "10 µF", isCorrect: false },
      { answer: "2 µF", isCorrect: false },
      { answer: "8 µF", isCorrect: false },
    ],
  },
  {
    question:
      "If a capacitor is charged to 50 V and stores 80 µC of charge, what is the energy stored in the capacitor?",
    answerOptions: [
      { answer: "0.2 J", isCorrect: true },
      { answer: "0.1 J", isCorrect: false },
      { answer: "0.4 J", isCorrect: false },
      { answer: "0.8 J", isCorrect: false },
    ],
  },
  {
    question:
      "In a parallel plate capacitor, if the distance between the plates is doubled while the voltage remains constant, what happens to the capacitance?",
    answerOptions: [
      { answer: "Capacitance is halved", isCorrect: true },
      { answer: "Capacitance is doubled", isCorrect: false },
      { answer: "Capacitance remains unchanged", isCorrect: false },
      { answer: "Capacitance is quadrupled", isCorrect: false },
    ],
  },
  {
    question:
      "A dipole is placed in a uniform electric field of magnitude 5000 N/C. If the dipole moment is 2 × 10⁻² C·m, what is the torque acting on the dipole?",
    answerOptions: [
      { answer: "0.1 Nm", isCorrect: true },
      { answer: "0.05 Nm", isCorrect: false },
      { answer: "0.2 Nm", isCorrect: false },
      { answer: "0.15 Nm", isCorrect: false },
    ],
  },
  {
    question:
      "A spherical conductor of radius 0.5 meters has a charge of +6 µC. What is the electric field just outside the surface of the conductor?",
    answerOptions: [
      { answer: "1.08 × 10⁴ N/C", isCorrect: true },
      { answer: "1.20 × 10⁴ N/C", isCorrect: false },
      { answer: "1.00 × 10⁴ N/C", isCorrect: false },
      { answer: "1.50 × 10⁴ N/C", isCorrect: false },
    ],
  },
  {
    question:
      "Two charges of +5 µC and -5 µC are placed 10 cm apart. Calculate the electric potential at a point midway between the two charges.",
    answerOptions: [
      { answer: "0 V", isCorrect: true },
      { answer: "1.00 × 10⁴ V", isCorrect: false },
      { answer: "5.00 × 10³ V", isCorrect: false },
      { answer: "2.50 × 10⁴ V", isCorrect: false },
    ],
  },
  {
    question:
      "A capacitor of 10 µF is connected to a 20 V battery. What is the energy stored in the capacitor?",
    answerOptions: [
      { answer: "2 J", isCorrect: true },
      { answer: "1 J", isCorrect: false },
      { answer: "4 J", isCorrect: false },
      { answer: "5 J", isCorrect: false },
    ],
  },
  {
    question:
      "A charge of 3 µC is placed in a uniform electric field of 4 × 10³ N/C. What is the magnitude of the force acting on the charge?",
    answerOptions: [
      { answer: "12 × 10⁻³ N", isCorrect: true },
      { answer: "6 × 10⁻³ N", isCorrect: false },
      { answer: "15 × 10⁻³ N", isCorrect: false },
      { answer: "9 × 10⁻³ N", isCorrect: false },
    ],
  },
  {
    question:
      "A parallel plate capacitor has an area of 0.02 m² and a plate separation of 0.01 m. If the capacitance is 0.1 µF, what is the dielectric constant of the material between the plates?",
    answerOptions: [
      { answer: "1.00", isCorrect: true },
      { answer: "2.00", isCorrect: false },
      { answer: "3.00", isCorrect: false },
      { answer: "4.00", isCorrect: false },
    ],
  },
  {
    question:
      "A spherical shell of radius 0.25 meters has a charge of +2 µC uniformly distributed over its surface. What is the electric potential at a point 0.1 meters from the center of the shell?",
    answerOptions: [
      { answer: "3.6 × 10⁴ V", isCorrect: true },
      { answer: "2.4 × 10⁴ V", isCorrect: false },
      { answer: "1.8 × 10⁴ V", isCorrect: false },
      { answer: "4.8 × 10⁴ V", isCorrect: false },
    ],
  },
  {
    question:
      "The electric field between two parallel plates is 2 × 10⁵ V/m. If the separation between the plates is 1 cm, what is the potential difference between the plates?",
    answerOptions: [
      { answer: "2000 V", isCorrect: true },
      { answer: "1000 V", isCorrect: false },
      { answer: "3000 V", isCorrect: false },
      { answer: "1500 V", isCorrect: false },
    ],
  },
  {
    question:
      "A capacitor is charged to a potential difference of 9 V and then disconnected from the battery. If the capacitance of the capacitor is 6 µF, what is the charge stored in the capacitor?",
    answerOptions: [
      { answer: "54 µC", isCorrect: true },
      { answer: "45 µC", isCorrect: false },
      { answer: "63 µC", isCorrect: false },
      { answer: "36 µC", isCorrect: false },
    ],
  },
  {
    question:
      "If a dielectric material with a dielectric constant of 8 is inserted between the plates of a capacitor, and the capacitance increases from 5 µF to 40 µF, what is the thickness of the dielectric material?",
    answerOptions: [
      { answer: "0.5 cm", isCorrect: true },
      { answer: "1 cm", isCorrect: false },
      { answer: "1.5 cm", isCorrect: false },
      { answer: "2 cm", isCorrect: false },
    ],
  },
  {
    question:
      "A capacitor with a capacitance of 12 µF is charged to 15 V. After being disconnected from the battery, it is connected in series with another capacitor of 8 µF. What is the final potential difference across the capacitors?",
    answerOptions: [
      { answer: "9 V", isCorrect: true },
      { answer: "7.5 V", isCorrect: false },
      { answer: "10 V", isCorrect: false },
      { answer: "6 V", isCorrect: false },
    ],
  },
  {
    question:
      "A charge of +5 µC is placed in an electric field of 3000 N/C. If the field is uniform, what is the electric potential difference between two points that are 2 meters apart?",
    answerOptions: [
      { answer: "6,000 V", isCorrect: true },
      { answer: "5,000 V", isCorrect: false },
      { answer: "4,000 V", isCorrect: false },
      { answer: "7,000 V", isCorrect: false },
    ],
  },
  {
    question:
      "A capacitor of capacitance 20 µF is connected to a voltage of 50 V. What is the stored energy in the capacitor?",
    answerOptions: [
      { answer: "25 mJ", isCorrect: true },
      { answer: "50 mJ", isCorrect: false },
      { answer: "10 mJ", isCorrect: false },
      { answer: "75 mJ", isCorrect: false },
    ],
  },
  {
    question:
      "Two identical capacitors are connected in series and then in parallel to a battery of 12 V. If each capacitor has a capacitance of 10 µF, what is the total capacitance when they are connected in parallel?",
    answerOptions: [
      { answer: "20 µF", isCorrect: true },
      { answer: "10 µF", isCorrect: false },
      { answer: "5 µF", isCorrect: false },
      { answer: "30 µF", isCorrect: false },
    ],
  },
  {
    question:
      "A point charge of +6 µC is located at a distance of 0.3 meters from a point. What is the electric potential at that point?",
    answerOptions: [
      { answer: "2 × 10⁴ V", isCorrect: true },
      { answer: "1 × 10⁴ V", isCorrect: false },
      { answer: "3 × 10⁴ V", isCorrect: false },
      { answer: "4 × 10⁴ V", isCorrect: false },
    ],
  },
  {
    question:
      "A charged particle of mass 0.02 kg and charge 2 µC is placed in a uniform electric field of 5 × 10³ N/C. What is the acceleration of the particle?",
    answerOptions: [
      { answer: "500 m/s²", isCorrect: true },
      { answer: "200 m/s²", isCorrect: false },
      { answer: "400 m/s²", isCorrect: false },
      { answer: "600 m/s²", isCorrect: false },
    ],
  },
  {
    question:
      "A capacitor has a capacitance of 8 µF. If the potential difference across it is increased from 5 V to 20 V, how much energy is stored in the capacitor after the increase?",
    answerOptions: [
      { answer: "2.4 mJ", isCorrect: true },
      { answer: "1.6 mJ", isCorrect: false },
      { answer: "4.8 mJ", isCorrect: false },
      { answer: "3.2 mJ", isCorrect: false },
    ],
  },
  {
    question:
      "If the electric potential at a point is 400 V and the electric field is 2000 N/C, what is the distance from the point to the reference point where the potential is zero?",
    answerOptions: [
      { answer: "0.2 m", isCorrect: true },
      { answer: "0.4 m", isCorrect: false },
      { answer: "0.1 m", isCorrect: false },
      { answer: "0.5 m", isCorrect: false },
    ],
  },
  {
    question:
      "A capacitor of 100 µF is connected to a 10 V battery. If a dielectric with a dielectric constant of 4 is inserted between the plates, what will be the new capacitance?",
    answerOptions: [
      { answer: "400 µF", isCorrect: true },
      { answer: "300 µF", isCorrect: false },
      { answer: "200 µF", isCorrect: false },
      { answer: "500 µF", isCorrect: false },
    ],
  },
  {
    question:
      "The potential difference across a capacitor of capacitance 12 µF is increased from 6 V to 18 V. What is the change in the energy stored in the capacitor?",
    answerOptions: [
      { answer: "1.08 mJ", isCorrect: true },
      { answer: "0.72 mJ", isCorrect: false },
      { answer: "0.54 mJ", isCorrect: false },
      { answer: "0.90 mJ", isCorrect: false },
    ],
  },
  {
    question:
      "A charged particle with a charge of -4 µC is placed in a uniform electric field of 2000 N/C. What is the magnitude of the force acting on the particle?",
    answerOptions: [
      { answer: "8 × 10⁻³ N", isCorrect: true },
      { answer: "6 × 10⁻³ N", isCorrect: false },
      { answer: "10 × 10⁻³ N", isCorrect: false },
      { answer: "5 × 10⁻³ N", isCorrect: false },
    ],
  },
  {
    question:
      "A spherical conductor with a radius of 0.2 m carries a charge of 8 µC. What is the electric potential at a distance of 0.1 m from the center of the conductor?",
    answerOptions: [
      { answer: "4.5 × 10⁴ V", isCorrect: true },
      { answer: "3.5 × 10⁴ V", isCorrect: false },
      { answer: "5.5 × 10⁴ V", isCorrect: false },
      { answer: "6.5 × 10⁴ V", isCorrect: false },
    ],
  },
  {
    question:
      "A parallel plate capacitor with an area of 0.1 m² and plate separation of 0.02 m has a capacitance of 5 µF. What is the dielectric constant of the material between the plates if the dielectric increases the capacitance to 10 µF?",
    answerOptions: [
      { answer: "2.0", isCorrect: true },
      { answer: "1.5", isCorrect: false },
      { answer: "3.0", isCorrect: false },
      { answer: "4.0", isCorrect: false },
    ],
  },
  {
    question:
      "A parallel plate capacitor has a capacitance of 50 µF and is connected to a 9 V battery. What is the total energy stored in the capacitor?",
    answerOptions: [
      { answer: "2.025 mJ", isCorrect: true },
      { answer: "1.8 mJ", isCorrect: false },
      { answer: "2.25 mJ", isCorrect: false },
      { answer: "1.5 mJ", isCorrect: false },
    ],
  },
  {
    question:
      "The electric potential at a point is 1200 V, and the electric field strength is 6000 N/C. What is the distance to a point where the potential is 0 V?",
    answerOptions: [
      { answer: "0.2 m", isCorrect: true },
      { answer: "0.3 m", isCorrect: false },
      { answer: "0.4 m", isCorrect: false },
      { answer: "0.5 m", isCorrect: false },
    ],
  },
  {
    question:
      "If a dielectric material with a dielectric constant of 5 is inserted into a capacitor, which originally had a capacitance of 8 µF, what will be the new capacitance?",
    answerOptions: [
      { answer: "40 µF", isCorrect: true },
      { answer: "35 µF", isCorrect: false },
      { answer: "45 µF", isCorrect: false },
      { answer: "50 µF", isCorrect: false },
    ],
  },

  {
    question:
      "A smartphone has a battery with a voltage of 3.7 V and a capacitance of 2000 µF. How much energy is stored in the battery?",
    answerOptions: [
      { answer: "13.7 mJ", isCorrect: true },
      { answer: "27.4 mJ", isCorrect: false },
      { answer: "7.4 mJ", isCorrect: false },
      { answer: "14.8 mJ", isCorrect: false },
    ],
  },
  {
    question:
      "An inkjet printer uses an electric field to control the ink droplets. If the electric field required to deflect the ink droplets is 1 × 10⁴ N/C and the charge on each droplet is 1 × 10⁻¹² C, what force acts on each droplet?",
    answerOptions: [
      { answer: "1 × 10⁻⁸ N", isCorrect: true },
      { answer: "2 × 10⁻⁸ N", isCorrect: false },
      { answer: "1 × 10⁻⁹ N", isCorrect: false },
      { answer: "2 × 10⁻⁹ N", isCorrect: false },
    ],
  },
  {
    question:
      "A lightning bolt discharges with a charge of 30 C. If the potential difference between the cloud and the ground is 100 MV, what is the energy released by the lightning bolt?",
    answerOptions: [
      { answer: "3 × 10¹² J", isCorrect: true },
      { answer: "1 × 10¹² J", isCorrect: false },
      { answer: "5 × 10¹² J", isCorrect: false },
      { answer: "2 × 10¹² J", isCorrect: false },
    ],
  },
  {
    question:
      "A car's static electricity buildup is measured as 10 µC. If a person touches the car, causing the charge to dissipate, what is the total energy released if the electric potential is 12 kV?",
    answerOptions: [
      { answer: "0.6 mJ", isCorrect: true },
      { answer: "1.2 mJ", isCorrect: false },
      { answer: "0.8 mJ", isCorrect: false },
      { answer: "1.0 mJ", isCorrect: false },
    ],
  },
  {
    question:
      "In a photocopier, an electrostatic charge is used to attract toner particles. If the copier uses an electric field of 3 × 10³ N/C and the charge on a toner particle is 2 × 10⁻¹² C, what force acts on the particle?",
    answerOptions: [
      { answer: "6 × 10⁻⁹ N", isCorrect: true },
      { answer: "4 × 10⁻⁹ N", isCorrect: false },
      { answer: "8 × 10⁻⁹ N", isCorrect: false },
      { answer: "2 × 10⁻⁹ N", isCorrect: false },
    ],
  },
  {
    question:
      "A capacitor in a camera flash unit stores energy to produce a bright flash. If a capacitor with a capacitance of 100 µF is charged to 300 V, what is the stored energy?",
    answerOptions: [
      { answer: "4.5 J", isCorrect: true },
      { answer: "3 J", isCorrect: false },
      { answer: "6 J", isCorrect: false },
      { answer: "5 J", isCorrect: false },
    ],
  },
  {
    question:
      "A capacitor is used to filter noise in an audio system. If a capacitor with a capacitance of 220 µF is used in a circuit with a frequency of 60 Hz, what is the capacitive reactance?",
    answerOptions: [
      { answer: "12.03 Ω", isCorrect: true },
      { answer: "10.03 Ω", isCorrect: false },
      { answer: "8.03 Ω", isCorrect: false },
      { answer: "15.03 Ω", isCorrect: false },
    ],
  },
  {
    question:
      "A person experiences an electric shock with a charge transfer of 1 µC and a potential difference of 5 kV. How much energy is transferred during the shock?",
    answerOptions: [
      { answer: "5 mJ", isCorrect: true },
      { answer: "10 mJ", isCorrect: false },
      { answer: "1 mJ", isCorrect: false },
      { answer: "2 mJ", isCorrect: false },
    ],
  },
  {
    question:
      "A computer uses capacitors in its power supply. If a capacitor with a capacitance of 50 µF is connected to a 12 V power source, what is the total charge stored in the capacitor?",
    answerOptions: [
      { answer: "0.6 mC", isCorrect: true },
      { answer: "0.5 mC", isCorrect: false },
      { answer: "0.7 mC", isCorrect: false },
      { answer: "0.4 mC", isCorrect: false },
    ],
  },
  {
    question:
      "An electric fence for livestock uses a voltage of 10 kV and has a capacitance of 1 µF. What is the stored energy in the fence?",
    answerOptions: [
      { answer: "50 J", isCorrect: true },
      { answer: "40 J", isCorrect: false },
      { answer: "30 J", isCorrect: false },
      { answer: "60 J", isCorrect: false },
    ],
  },
  {
    question:
      "A CRT monitor uses an electric field to direct electrons onto the screen. If the electric field strength is 5 × 10⁴ N/C and the charge of an electron is 1.6 × 10⁻¹⁹ C, what is the force on an electron?",
    answerOptions: [
      { answer: "8 × 10⁻¹⁵ N", isCorrect: true },
      { answer: "6 × 10⁻¹⁵ N", isCorrect: false },
      { answer: "4 × 10⁻¹⁵ N", isCorrect: false },
      { answer: "2 × 10⁻¹⁵ N", isCorrect: false },
    ],
  },
];

export default electrostaticsquestions;
