import React from 'react';
import '../pagesStyle/aboutDeveloper.css'
import AdminLogin from '../auth/AdminLogin';
import { Link } from 'react-router-dom';

const AboutDeveloper = () => {
    return (
        <div className="about-developer">
            <h1 className="about-title"> About Aqueeq Azam</h1>
            <div className="developer-info">
                <p>
                    Meet Aqueeq Azam, the visionary developer behind this innovative quiz social networking platform. With a strong background in mathematics and programming, Aqueeq has dedicated countless hours to creating a user-friendly and engaging application for quiz enthusiasts. His journey is a testament to his commitment to lifelong learning and exploration in both education and technology.
                </p>

                <h2 className="section-title">Early Life and Education</h2>
                <p>
                    Growing up, Aqueeq was always intrigued by the complexities of mathematics. His fascination with numbers and problem-solving led him to pursue a degree in physics. During his college years, he delved deeply into theoretical and applied physics, which laid the groundwork for his analytical thinking and research skills. Aqueeq's college journey was marked by a thirst for knowledge, as he sought to understand the underlying principles that govern our universe.
                </p>

                <h2 className="section-title">Role in College Journey</h2>
                <p>
                    While studying physics, Aqueeq became involved in various research projects, collaborating with peers and professors to explore advanced topics in the field. His role as a research assistant not only honed his analytical skills but also introduced him to the practical applications of physics in technology. However, his college journey took an unexpected turn as he found himself increasingly drawn to the world of artificial intelligence (AI) and machine learning.
                </p>
                <p>
                    The more he learned about these technologies, the more he realized their potential to revolutionize education and other fields. After careful consideration, Aqueeq made the bold decision to drop out of college to fully dedicate himself to math research and the development of AI technologies.
                </p>

                <h2 className="section-title">AI Journey and Turning Points</h2>
                <p>
                    Aqueeq's journey into AI began in earnest after leaving college. He immersed himself in online courses, tutorials, and research papers, seeking to build a solid foundation in the principles of AI and machine learning. This period was marked by intense curiosity and self-directed learning, as Aqueeq sought to understand the algorithms and frameworks that power AI applications.
                </p>
                <p>
                    One pivotal moment came when he participated in a hackathon focused on AI solutions for education. Collaborating with like-minded individuals, Aqueeq and his team developed a prototype for an intelligent tutoring system that adapted to the learning pace of individual students. This project fueled his desire to create educational tools that leverage AI to enhance learning experiences.
                </p>

                <h2 className="section-title">Mathematics and Research Skills</h2>
                <p>
                    Throughout his academic journey, Aqueeq has maintained a strong emphasis on mathematics. His background in physics has provided him with a deep understanding of mathematical concepts, which he has applied to various research endeavors. His research focused on applying mathematical techniques to solve complex problems in physics and data analysis, developing algorithms for data interpretation, and exploring how mathematical frameworks could enhance the effectiveness of AI systems.
                </p>

                <h2 className="section-title">Programming Skills</h2>
                <p>
                    Aqueeq is proficient in several programming languages, including Python and JavaScript. His expertise in Python has been instrumental in developing backend functionalities, ensuring smooth data handling and user authentication. On the frontend, he utilized JavaScript along with React to create a dynamic and responsive user interface, making the application accessible across various devices.
                </p>

                <h2 className="section-title">Curiosity and Programming Journey</h2>
                <p>
                    Aqueeq's curiosity about programming began in 2017 when he first encountered coding. What started as a hobby quickly evolved into a passion as he discovered the potential of programming to solve real-world problems. He began learning various programming languages and applied his newfound skills to create small projects and tools that automated tasks and improved efficiency.
                </p>
                <p>
                    His programming journey has been characterized by continuous learning and experimentation. Aqueeq has utilized platforms like GitHub to collaborate with other developers, contributing to open-source projects and gaining valuable insights into best practices in software development. This collaborative spirit has allowed him to grow not only as a programmer but also as a member of the developer community.
                </p>

                <h2 className="section-title">Developing the Quiz Application</h2>
                <p>
                    Aqueeq’s passion for education and technology culminated in the development of the quiz social networking platform. He envisioned a space where users could engage in quizzes while interacting with one another through likes, shares, comments, and personalized recommendations. The application serves as an educational tool and fosters a community of learners who can share their knowledge and insights.
                </p>
                <p>
                    Leveraging his programming skills, Aqueeq built the application using React for the frontend and Python for the backend. He integrated various features such as user rankings, accuracy tracking, and an interactive dashboard to enhance the user experience. Additionally, he incorporated AI algorithms to provide personalized quiz recommendations, ensuring that users receive content tailored to their interests and performance levels.
                </p>

                <h2 className="section-title">Future Aspirations</h2>
                <p>
                    As a developer, Aqueeq is continuously looking to expand his skill set and stay updated with the latest technologies. He envisions adding more advanced features to the quiz platform, such as machine learning algorithms for adaptive learning and gamification elements to further engage users. His commitment to creating an enriching educational experience is evident in every aspect of the application.
                </p>
                <p>
                    In conclusion, Aqueeq Azam's journey is a remarkable example of how passion, curiosity, and determination can lead to innovation. With a solid foundation in programming and a passion for technology, he is on a mission to make learning fun and interactive. Join him on this exciting journey of knowledge and discovery through quizzes!
                </p>
            </div>
            <Link to="/admin-login">Admin</Link>
        </div>
    );
};

export default AboutDeveloper;
