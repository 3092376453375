const questions = [
    {
        question:"Which modern web framework is maintained by facebook :",
        answerOptions: [
            {answer: 'Django', isCorrect: false},
            {answer: 'Angular', isCorrect: false},
            {answer: 'Tkinter', isCorrect: false},
            {answer: 'React js', isCorrect: true},
        ],
        correctAnswer: "React js",
        difficulty: "easy", 
        category: "programming languages"
       
    },  {
        question:"Which community gave the tensor flow liberary for deep learning :",
        answerOptions: [
            {answer: 'Facebook', isCorrect: false},
            {answer: 'Tesla', isCorrect: false},
            {answer: 'Google', isCorrect: true},
            {answer: 'Microsoft', isCorrect: false},
        ],
       // correctAnswer: "Google",
        difficulty: "medium",
        category: "technology"
       
    },  {
        question:"Which programming language best for artificial intelligence :",
        answerOptions: [
            {answer: 'JavaScript', isCorrect: false},
            {answer: 'C++', isCorrect: false},
            {answer: 'Java', isCorrect: false},
            {answer: 'Python', isCorrect: true},
        ],
       // correctAnswer: "Python",
        difficulty: "easy",
        category: "programming languages",
       
    }, 
]

export default questions;