const chemicalequilibriumquestions = [
    {
        question:"With increase in temperature, equilibrium constant of a reaction :",
        answerOptions: [
            {answer: 'decrease', isCorrect: false},
            {answer: 'increase', isCorrect: false},
            {answer: 'both the condition', isCorrect: true},
            {answer: 'remain unchanged', isCorrect: false},
        ],
    }, {
        question:"The rate of a reaction, on increasing concentration of the reactants :",
        answerOptions: [
            {answer: 'increase', isCorrect: true},
            {answer: 'decrease', isCorrect: false},
            {answer: 'unaltered', isCorrect: false},
            {answer: 'none of these', isCorrect: false},
        ],
    },  {
        question:"Under what condition are Kp, Kc, Ka and Kx all equal  :",
        answerOptions: [
            {answer: 'n = 0, np = nr (gaseous)', isCorrect: true},
            {answer: 'n = 0, np = nr (solid)', isCorrect: false},
            {answer: 'n = 0, np = nr (liquid)', isCorrect: false},
            {answer: 'data insufficient', isCorrect: false},
        ],
    },  {
        question:"A gas has two degrees of freedom is called :",
        answerOptions: [
            {answer: 'univarient', isCorrect: false},
            {answer: 'blvariant', isCorrect: true},
            {answer: 'both of these', isCorrect: false},
            {answer: 'none of these', isCorrect: false},
        ],
    },  {
        question:"Which of the following remain constant when equilibrium is attained :",
        answerOptions: [
            {answer: 'temperature', isCorrect: false},
            {answer: 'pressure', isCorrect: false},
            {answer: 'chemical potentisl', isCorrect: false},
            {answer: 'all of these', isCorrect: true},
        ],
    },  {
        question:"If the state of the equilibrium system can be atteined only from one direction :",
        answerOptions: [
            {answer: 'thermal', isCorrect: false},
            {answer: 'metastate', isCorrect: true},
            {answer: 'true', isCorrect: false},
            {answer: 'all', isCorrect: false},
           
        ],
    }, {
        question:"On increase temperature, rate of a reaction increase, because of :",
        answerOptions: [
            {answer: 'activated molecules increase', isCorrect: false},
            {answer: 'activated molecules decrease', isCorrect: true},
            {answer: 'activateed molecules become 0', isCorrect: false},
            {answer: 'data insufficient', isCorrect: false},
        ],
    },  {
        question:"Which is every part of a system must be same :",
        answerOptions: [
            {answer: 'rate', isCorrect: false},
            {answer: 'equilibrium', isCorrect: false},
            {answer: 'temperature', isCorrect: true},
            {answer: 'all of the above', isCorrect: false},
        ],
    },  {
        question:"For a reaction in equilibrium in terms of free energy is  :",
        answerOptions: [
            {answer: 'del.G = 0', isCorrect: true},
            {answer: 'del.G = 1', isCorrect: false},
            {answer: 'del.G = 2', isCorrect: false},
            {answer: 'none of these', isCorrect: false},
        ],
    },  {
        question:"A gas in equilibrium with its solution in a liquid, calculate the number of phase of the system :",
        answerOptions: [
            {answer: 'P = 1', isCorrect: false},
            {answer: 'P = 2', isCorrect: true},
            {answer: 'P = 3', isCorrect: false},
            {answer: 'P = 0', isCorrect: false},
        ],
    },  {
        question:"For a reaction taking place under the standred state :",
        answerOptions: [
            {answer: '40°C, 1 atm.', isCorrect: false},
            {answer: '25°C, 10 atm.', isCorrect: false},
            {answer: '25°C, 0 atm.', isCorrect: false},
            {answer: '25°C, 1 atm.', isCorrect: true},
        ],
    },  {
        question:"There is no effect of addition of a catalyst on the :",
        answerOptions: [
            {answer: 'rate of reaction', isCorrect: false},
            {answer: 'more volume', isCorrect: false},
            {answer: 'equilibrium state', isCorrect: true},
            {answer: 'latent heat', isCorrect: false},
        ],
    },  {
        question:"There are thre type of equilibrium constans, Kp, Kc, and Kx depends upon the :",
        answerOptions: [
            {answer: 'mocular mass', isCorrect: false},
            {answer: 'concentration', isCorrect: true},
            {answer: 'speed', isCorrect: false},
            {answer: 'data insufficient', isCorrect: false},
        ],
    },  {
        question:"A gas in equilibrium with its solution in a liquid, calculate the degree of freedom of the system  :",
        answerOptions: [
            {answer: 'C = 1', isCorrect: false},
            {answer: 'C = 2', isCorrect: true},
            {answer: 'C = 3', isCorrect: false},
            {answer: 'can not be said', isCorrect: false},
        ],
    },  {
        question:"According to law of mass of action, for the reaction 2A + B -> Products, which equation holds goes :",
        answerOptions: [
            {answer: 'Rate = k[A]²[B]', isCorrect: true},
            {answer: 'Rate = k[A][B]²', isCorrect: false},
            {answer: 'Rate = k[A]²[B]²', isCorrect: false},
            {answer: 'Rate = k[A][B]', isCorrect: false},
        ],
    },  {
        question:"What are the number of components, phases and degree of freedom in the following equilibrium system of CaCO3(s) -> CaO(s) + CO2(g) :",
        answerOptions: [
            {answer: 'no. of components = 2 and F = 2', isCorrect: false},
            {answer: 'no. of components = 2 and F = 1', isCorrect: true},
            {answer: 'no. of components = 1 and F = 2', isCorrect: false},
            {answer: 'no. of components = 1 and F = 1', isCorrect: false},
        ],
    },  {
        question:"Pressure having every part of the system must be same :",
        answerOptions: [
            {answer: 'mechanical', isCorrect: true},
            {answer: 'chemical', isCorrect: false},
            {answer: 'thermal', isCorrect: false},
            {answer: 'metastate', isCorrect: false},
        ],
    },  {
        question:"The degree of freedom F of the system is given by :",
        answerOptions: [
            {answer: 'C + P - 2', isCorrect: false},
            {answer: 'C - P  2', isCorrect: false},
            {answer: 'C + P + 2', isCorrect: false},
            {answer: 'C - P + 2', isCorrect: true},
        ],
    },  {
        question:"If the vapour pressure of toluene is 60 mm at 40.3°C and 20 mm at 18.4°C, calculate the heat :",
        answerOptions: [
            {answer: '28.02 kJ/mol', isCorrect: false},
            {answer: '28.04 kJ/mol', isCorrect: false},
            {answer: '28.06 kJ/mol', isCorrect: false},
            {answer: '28.08 kJ/mol', isCorrect: true },
        ],
    },  {
        question:"The conditiosn of a equilibrium between differed phases of the system are called :",
        answerOptions: [
            {answer: 'thermal equilibrium', isCorrect: false},
            {answer: 'phase diagram', isCorrect: true},
            {answer: 'coordinate system', isCorrect: false},
            {answer: 'molar mass', isCorrect: false},
        ],
    },  {
        question:"Calculate the degree of freedom of sulpher at the transition point  :",
        answerOptions: [
            {answer: 'F = 0', isCorrect: false},
            {answer: 'F = 1/2', isCorrect: false},
            {answer: 'F = 1', isCorrect: true},
            {answer: 'F = 2', isCorrect: false},
        ],
    },  {
        question:"What type of systems are represented by areas in one components systems :",
        answerOptions: [
            {answer: 'univarient', isCorrect: false},
            {answer: 'bivarient', isCorrect: true},
            {answer: 'non-varient', isCorrect: false},
            {answer: 'none of these', isCorrect: false},
        ],
    },  {
        question:"Calculate the number of components of N2O4(g) -> 2NO2(g) :",
        answerOptions: [
            {answer: '0', isCorrect: false},
            {answer: '1', isCorrect: true},
            {answer: '2', isCorrect: false},
            {answer: '3', isCorrect: false},
        ],
    },  {
        question:"When the system is in equilibrium, the free-energy change accompanying the process is :",
        answerOptions: [
            {answer: '0', isCorrect: false},
            {answer: '1', isCorrect: false},
            {answer: '2', isCorrect: true},
            {answer: '3', isCorrect: false},
        ],
    },  {
        question:"Calculate the number of phase of N2O4(g) -> 2NO2(g) :",
        answerOptions: [
            {answer: 'P = 0', isCorrect: false},
            {answer: 'P = 1', isCorrect: true},
            {answer: 'P = 2', isCorrect: false},
            {answer: 'data insufficient', isCorrect: false},
        ],
    },  {
        question:"If the vapour pressure of toluene is 60 mm at 40.3°C and 20 mm at 18.4°C, calculate the vapour pressure at 25 degree C :",
        answerOptions: [
            {answer: '28.1 mm', isCorrect: false},
            {answer: '28.2 mm', isCorrect: false},
            {answer: '28.4 mm', isCorrect: true},
            {answer: 'none of these', isCorrect: false},
        ],
    },  {
        question:"Can the equilibrium CaCo3 -> CaO(s) + CO2(g) be attained in an open vessel :",
        answerOptions: [
            {answer: 'yes', isCorrect: false},
            {answer: 'may be', isCorrect: false},
            {answer: 'no', isCorrect: true},
            {answer: 'anythings may be', isCorrect: false},
        ],
    },  {
        question:"Calculate the degree of freedom of N2O4(g) -> 2NO2(g) :",
        answerOptions: [
            {answer: 'F = 1', isCorrect: false},
            {answer: 'F = 2', isCorrect: true},
            {answer: 'F = 0', isCorrect: false},
            {answer: 'F = 3', isCorrect: false},
        ],
    },  {
        question:"What is van't Hoff reaction isotherm :",
        answerOptions: [
            {answer: 'del (G)', isCorrect: true},
            {answer: 'del (E)', isCorrect: false},
            {answer: 'both of these', isCorrect: false},
            {answer: 'none of these', isCorrect: false},
        ],
    },  {
        question:"What are the criteria for two phase equilibria for non-component system :",
        answerOptions: [
            {answer: 'at temp. for free energy', isCorrect: false},
            {answer: 'at pre. for free energy', isCorrect: false},
            {answer: 'at temp. and pre. for free energy', isCorrect: true},
            {answer: 'all are these', isorrect: false},
        ],
    },
]

export default chemicalequilibriumquestions;