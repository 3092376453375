// UserRecommendations.js

import React, { useEffect, useState } from "react";
import { collection, getDocs, doc, getDoc } from "firebase/firestore";
import { fireDB, auth } from "../config/firebase";
import { getAuth } from "firebase/auth";

const UserRecommendations = ({ userId }) => {
  const [recommendedQuestions, setRecommendedQuestions] = useState([]);

  useEffect(() => {
    const fetchUserRecommendations = async () => {
      try {
        const userRef = doc(fireDB, "users", userId);
        const userSnap = await getDoc(userRef);

        if (userSnap.exists()) {
          const userData = userSnap.data();
          const userInteractions = userData.interactions || {};

          const recommendedQuestionsList = [];

          const questionsSnapshot = await getDocs(collection(fireDB, "quizQuestions"));
          questionsSnapshot.forEach((doc) => {
            const question = doc.data();

            // Check if the question has similar characteristics as the user's past interactions
            if (userInteractions.likes && question.likes > 0) {
              recommendedQuestionsList.push({
                id: doc.id,
                ...question,
              });
            }
            if (userInteractions.shares && question.shares > 0) {
              recommendedQuestionsList.push({
                id: doc.id,
                ...question,
              });
            }
            // You can add more conditions based on your engagement logic
          });

          setRecommendedQuestions(recommendedQuestionsList);
        }
      } catch (error) {
        console.error("Error fetching user recommendations:", error);
      }
    };

    if (userId) {
      fetchUserRecommendations();
    }
  }, [userId]);

  return (
    <div className="recommendations-container" style={{ padding: "1rem", background: "#f9f9f9", borderRadius: "8px", margin: "1rem 0" }}>
      <h2 style={{ marginBottom: "1rem" }}>Recommended Questions for You</h2>
      {recommendedQuestions.length > 0 ? (
        recommendedQuestions.map((question) => (
          <div key={question.id} className="recommendation-card" style={{ marginBottom: "1rem", padding: "1rem", background: "#fff", borderRadius: "8px", boxShadow: "0 4px 6px rgba(0,0,0,0.1)" }}>
            <h3 style={{ color: "#333", fontWeight: "bold", fontSize: "1.25rem" }}>{question.question}</h3>
            <p style={{ color: "#666" }}>Category: <strong>{question.category}</strong></p>
          </div>
        ))
      ) : (
        <p>No recommendations available at this time.</p>
      )}
    </div>
  );
};

export default UserRecommendations;
