const grouptwelvequestions = [
    {
        question:"Whcih elements comes in group twelve group :",
        answerOptions: [
            {answer: 'Zn, Pb, Hg', isCorrect: false},
            {answer: 'Li, Pb, Hg', isCorrect: false},
            {answer: 'B, Zn, Hg', isCorrect: false},
            {answer: 'Zn, Cd, Hg', isCorrect: true},
        ],
    }, {
        question:"Which  is the only metal which is liquid at room temperature :",
        answerOptions: [
            {answer: 'Pb', isCorrect: false},
            {answer: 'Cd', isCorrect: false},
            {answer: 'Hg', isCorrect: true},
            {answer: 'Li', isCorrect: false},
        ],
    },  {
        question:"Atomic number of mercury is :",
        answerOptions: [
            {answer: '78', isCorrect: false},
            {answer: '92', isCorrect: false},
            {answer: '86', isCorrect: false},
            {answer: '80', isCorrect: true},
        ],
    },  {
        question:" Does this order correct of atomic radii, Zn < Cd ~ Hg :",
        answerOptions: [
            {answer: 'yes', isCorrect: true},
            {answer: 'may be', isCorrect: false},
            {answer: 'no', isCorrect: false},
            {answer: 'none of these', isCorrect: false},
        ],
    },  {
        question:"Which elements of group 12th has highest boiling point :",
        answerOptions: [
            {answer: 'Zn', isCorrect: true},
            {answer: 'Cd', isCorrect: false},
            {answer: 'Hg', isCorrect: false},
            {answer: 'all of these', isCorrect: false},
        ],
    },  {
        question:"Calamine is an ore of :",
        answerOptions: [
            {answer: 'Ca', isCorrect: false},
            {answer: 'Cd', isCorrect: false},
            {answer: 'Cu', isCorrect: false},
            {answer: 'Zn', isCorrect: true},
           
        ],
    }, {
        question:"Which elements of group 12th has lowest melting point :",
        answerOptions: [
            {answer: 'Zn', isCorrect: false},
            {answer: 'Cd', isCorrect: false},
            {answer: 'Hg', isCorrect: true},
            {answer: 'none of these', isCorrect: false},
        ],
    },  {
        question:"Correct order of electrode potential of group 12th elements is :",
        answerOptions: [
            {answer: 'Zn < Cd < Hg', isCorrect: false},
            {answer: 'Zn < Cd > Hg', isCorrect: false},
            {answer: 'Zn < Cd < Hg', isCorrect: true},
            {answer: 'Zn > Cd < Hg', isCorrect: false},
        ],
    },  {
        question:"Which oxidation state is stable for all the metals :",
        answerOptions: [
            {answer: '+1', isCorrect: false},
            {answer: '+2', isCorrect: true},
            {answer: '+3', isCorrect: false},
            {answer: '-1', isCorrect: false},
        ],
    },  {
        question:"Which of the following contains Ni :",
        answerOptions: [
            {answer: 'brass', isCorrect: false},
            {answer: 'bronze', isCorrect: false},
            {answer: 'german silver', isCorrect: true},
            {answer: 'gun metal', isCorrect: false},
        ],
    },  {
        question:"The metals and their +2 ions have all their electrons paired and so are :",
        answerOptions: [
            {answer: 'paramegnetic', isCorrect: false},
            {answer: 'dimeganatic', isCorrect: true},
            {answer: 'electromegnatic', isCorrect: false},
            {answer: 'none of these', isCorrect: false},
        ],
    },  {
        question:" Tobacco smoke contains :",
        answerOptions: [
            {answer: 'Pb', isCorrect: false},
            {answer: 'Sn', isCorrect: false},
            {answer: 'Cd', isCorrect: true},
            {answer: 'none of thee', isCorrect: false},
        ],
    },  {
        question:"Which of the following is phosphorescent :",
        answerOptions: [
            {answer: 'ZnO', isCorrect: false},
            {answer: 'ZnS', isCorrect: true},
            {answer: 'ZnCl2', isCorrect: false},
            {answer: 'Zn3P2', isCorrect: false},
        ],
    },  {
        question:"Nesseler's reagent is :",
        answerOptions: [
            {answer: 'K2HgI4', isCorrect: false},
            {answer: 'K2HgI4 + NH4OH', isCorrect: false},
            {answer: 'K2HgI4 + KOH', isCorrect: true},
            {answer: 'HgI2 + KOH', isCorrect: false},
        ],
    },  {
        question:"Mercury does not form amalgam with :",
        answerOptions: [
            {answer: 'Al', isCorrect: false},
            {answer: 'Ag', isCorrect: false},
            {answer: 'Fe', isCorrect: true},
            {answer: 'Zn', isCorrect: false},
        ],
    },  {
        question:"Lucas reagent is :",
        answerOptions: [
            {answer: 'HgCl2 + HCl', isCorrect: false},
            {answer: 'ZnCl2 + conc. HCl', isCorrect: true},
            {answer: 'CdCl2 + HCl', isCorrect: false},
            {answer: 'none of these', isCorrect: false},
        ],
    },  {
        question:"The ionization energy of Zn is greater than Cd because  :",
        answerOptions: [
            {answer: 'ionization energy', isCorrect: false},
            {answer: 'melting point', isCorrect: false},
            {answer: 'atomic radius', isCorrect: true},
            {answer: 'anythings may be', isCorrect: false},
        ],
    },  {
        question:"Which of the following is a carbonate :",
        answerOptions: [
            {answer: 'cuprite', isCorrect: false},
            {answer: 'villemite', isCorrect: false},
            {answer: 'siderite', isCorrect: true},
            {answer: 'limonite', isCorrect: false},
        ],
    },  {
        question:"Which of the following forms H2 when heated with caustic soda :",
        answerOptions: [
            {answer: 'Zn', isCorrect: true},
            {answer: 'Hg', isCorrect: false},
            {answer: 'Mg', isCorrect: false},
            {answer: 'Cu', isCorrect: false},
        ],
    },  {
        question:"Zinc blende ore is concentrated by :",
        answerOptions: [
            {answer: 'gravity seperation', isCorrect: false},
            {answer: 'magnetic seperation', isCorrect: false},
            {answer: 'froth floatation', isCorrect: true},
            {answer: 'all are these', isorrect: false},
        ],
    },
]

export default grouptwelvequestions;