const hydrocarbonquestions = [
    {
        question:" These are compounds having :",
        answerOptions: [
            {answer: 'C-atom', isCorrect: false},
            {answer: 'H-atom', isCorrect: false},
            {answer: 'Both C and H-atom', isCorrect: true},
            {answer: 'None of these', isCorrect: false},
        ],
    },  {
        question:"Alkanes show :",
        answerOptions: [
            {answer: 'chain', isCorrect: false},
            {answer: 'position', isCorrect: true},
            {answer: 'conformation', isCorrect: false},
            {answer: 'none of these', isCorrect: false},
        ],
    },  {
        question:"The general formula of alkanes is  :",
        answerOptions: [
            {answer: 'CnH(2n+1)', isCorrect: true},
            {answer: 'CnH(2n-1)', isCorrect: false},
            {answer: 'CnH(n+1)', isCorrect: false},
            {answer: 'C(n-1)H(2n+1)', isCorrect: false},
        ],
    }, {
        question:"Density of alkanes is  :",
        answerOptions: [
            {answer: 'Molecular density', isCorrect: false},
            {answer: 'Molecular mass', isCorrect: false},
            {answer: 'Molecular weight', isCorrect: true},
            {answer: 'Data insufficent', isCorrect: false},
        ],
    }, {
        question:"Alkenes are :",
        answerOptions: [
            {answer: 'colorless', isCorrect: false},
            {answer: 'odourless', isCorrect: false},
            {answer: 'lighter than air', isCorrect: false},
            {answer: 'all of these', isCorrect: true},
        ],
    }, {
        question:"R-CH=CH2 -------> R-CH2-CH2-OH, catylist ued in this reaction is :",
        answerOptions: [
            {answer: 'B2H6', isCorrect: false},
            {answer: 'NaoH', isCorrect: false},
            {answer: 'H2O2', isCorrect: false},
            {answer: 'all of these', isCorrect: true},
        ],
    }, {
        question:"Hybridisation of alkynes are :",
        answerOptions: [
            {answer: 'sp', isCorrect: true},
            {answer: 'sp²', isCorrect: false},
            {answer: 'sp^3', isCorrect: false},
            {answer: 'can not be said', isCorrect: false},
        ],
    }, {
        question:"Name of CH3-C≡C-CH3 :",
        answerOptions: [
            {answer: 'butene-2', isCorrect: false},
            {answer: 'butyne-2', isCorrect: true},
            {answer: 'but-2-yne', isCorrect: false},
            {answer: 'but-1-yne', isCorrect: false},
        ],
    }, {
        question:"What will be product, if reaction takes place in presence of C2H5OH and HgSO4 in CH≡CH  :",
        answerOptions: [
            {answer: 'CH2 = CH-OC2H5', isCorrect: true},
            {answer: 'CH = CH2-OC2H5', isCorrect: false},
            {answer: 'CH3 - CH-OC2H5', isCorrect: false},
            {answer: 'CH2 = CH2-OC2H4', isCorrect: false},
        ],
    }, {
        question:"Chlorobenzene or halobenzene on reduction with Na/alcohol or Ni–Al alloy, what will be give  :",
        answerOptions: [
            {answer: 'benzene', isCorrect: false},
            {answer: 'benzene + HCl', isCorrect: true},
            {answer: 'phenol + HCl', isCorrect: false},
            {answer: 'can not be said', isCorrect: false},
        ],
    },{
        question:"Phenol on distillation with zinc dust gives :",
        answerOptions: [
            {answer: 'benzoic acid', isCorrect: false},
            {answer: 'cyclohexane', isCorrect: false},
            {answer: 'benzene', isCorrect: true},
            {answer: 'H-benzene', isCorrect: false},
        ],
    },{
        question:"When benzene and furming 3HNO3 treated with Conc. H2SO4 formed Tri-nitro-benzene, this reaction takes place at whcih temperature :(Xnetous Chellange)",
        answerOptions: [
            {answer: '40°C', isCorrect: false},
            {answer: '50°C', isCorrect: true},
            {answer: '60°C', isCorrect: false},
            {answer: '65°C', isCorrect: false},
        ],
    }, {
        question:" Toluene is a :",
        answerOptions: [
            {answer: 'white liquid', isCorrect: false},
            {answer: 'green liquid', isCorrect: false},
            {answer: 'red liquid', isCorrect: false},
            {answer: 'colorless liquid', isCorrect: true},
        ],
    }, {
        question:"1 Natural gas is composed primarily of :",
        answerOptions: [
            {answer: 'methane', isCorrect: false},
            {answer: 'n-butane', isCorrect: true},
            {answer: 'n-octane', isCorrect: false},
            {answer: 'a mixture of alkanes', isCorrect: false},
        ],
    }, {
        question:"6 Acetylene reacts with acetic acid in presence of Hg+ ions at room temperature to give :",
        answerOptions: [
            {answer: 'ethyl acetate', isCorrect: false},
            {answer: 'acetaldehyde', isCorrect: true},
            {answer: 'vinyl acetate', isCorrect: false},
            {answer: 'methyl acetate', isCorrect: false},
        ],
    }, {
        question:"14 Antidote of Lewsite is  :",
        answerOptions: [
            {answer: '3, 3-dimercaptopropan-1-ol', isCorrect: false},
            {answer: '2-mercaptopropan-1, 3-diol', isCorrect: true},
            {answer: '2, 3-dimercaptopropan-1-ol', isCorrect: false},
            {answer: '2, 2-dimercaptopropan-1-ol', isCorrect: false},
        ],
    }, {
        question:"13 Among the following compounds, the strongest acid is :",
        answerOptions: [
            {answer: 'HC ≡ CH', isCorrect: true},
            {answer: 'C6H6', isCorrect: false},
            {answer: 'C2H6', isCorrect: false},
            {answer: 'CH3OH', isCorrect: false},
        ],
    },{
        question:"18 Which will have smallest heat of hydrogenation per mole  :",
        answerOptions: [
            {answer: 'cis-2-butene ', isCorrect: false},
            {answer: 'trans-2-butene', isCorrect: true},
            {answer: '1, 3-butadiene ', isCorrect: false},
            {answer: '1-butene ', isCorrect: false},
        ],
    }, {
        question:"22  Which one of these is not compatible with arenes :",
        answerOptions: [
            {answer: 'greater stability', isCorrect: true},
            {answer: 'resonance', isCorrect: false},
            {answer: 'delocalization of π-electrons', isCorrect: false},
            {answer: 'electrophillic addition', isCorrect: false},
        ],
    }, {
        question:"35 The highest boiling point is expected for :",
        answerOptions: [
            {answer: 'iso-octane', isCorrect: false},
            {answer: 'n-octane', isCorrect: false},
            {answer: '2, 2, 3, 3-tetramethyl butane', isCorrect: true},
            {answer: 'n-butane', isCorrect: false},
        ],
    }, {
        question:"46 In the given reaction CH3-C≡C-CH3 --Na/NH3(I)---->[X] wil be :",
        answerOptions: [
            {answer: 'butane ', isCorrect: false},
            {answer: 'trans-2-butene', isCorrect: true},
            {answer: 'cis-2-butene', isCorrect: false},
            {answer: '1-butene', isCorrect: false},
        ],
    },{
        question:"58 Which of the following shows acidic character :",
        answerOptions: [
            {answer: 'CH3-CH3', isCorrect: false},
            {answer: 'CH3-C≡CH', isCorrect: true},
            {answer: 'CH2=CH2', isCorrect: false},
            {answer: 'CH3≡C-CH3', isCorrect: false},
        ],
    }, {
        question:" 53 The product formed on reaction of HBr with propene is :",
        answerOptions: [
            {answer: '1-bromoproapne', isCorrect: true},
            {answer: '2--bromoproapne', isCorrect: false},
            {answer: '3--bromoproapne', isCorrect: false},
            {answer: 'no reaction occurs', isCorrect: false},
        ],
    },{
        question:"56 Potassium salt of 2, 3-dimethyl maleic acid on electrolysis gives :",
        answerOptions: [
            {answer: 'ethyene', isCorrect: false},
            {answer: 'propyne', isCorrect: true},
            {answer: '2-butyne', isCorrect: false},
            {answer: '1-butyne', isCorrect: false},
        ],
    },{
        question:"150 Acetylene reacts with hypochlorous acid to form :(AIEEE 2002)",
        answerOptions: [
            {answer: 'Cl2CHCHO', isCorrect: false},
            {answer: 'ClCH2COOH', isCorrect: false},
            {answer: 'CH3COCl', isCorrect: true},
            {answer: 'ClCH2CHO', isCorrect: false},
        ],
    },{
        question:"Alkynes is prepared by Berthelot's synthesis in presence of :",
        answerOptions: [
            {answer: 'Electric arc', isCorrect: false},
            {answer: 'Conc. acid', isCorrect: true},
            {answer: 'Heat', isCorrect: false},
            {answer: 'Presence of halo.', isCorrect: false},
        ],
    },{
        question:"171 2-Hexyne gives trans–2– Hexene on treatment with :(AIEEE 2012)",
        answerOptions: [
            {answer: 'Pd/BaSO4', isCorrect: false},
            {answer: 'Li/NH3', isCorrect: false},
            {answer: 'Pt/H2', isCorrect: false},
            {answer: 'LIAIH4', isCorrect: true},
        ],
    }, {
        question:"Dry ether used in :",
        answerOptions: [
            {answer: 'Frankland reaction', isCorrect: false},
            {answer: 'Wurtz reaction', isCorrect: true},
            {answer: 'Kolbe synthesis', isCorrect: false},
            {answer: 'Nitration', isCorrect: false},
        ],
    }, {
        question:"Alkenes reacts with H2O -------> :",
        answerOptions: [
            {answer: 'alkyne', isCorrect: false},
            {answer: 'phenol', isCorrect: false},
            {answer: 'alkane', isCorrect: true},
            {answer: 'ether', isCorrect: false},
        ],
    }, {
        question:"120 18C–H and 7C–C sigma bonds are present in :",
        answerOptions: [
            {answer: 'n-heptane ', isCorrect: false},
            {answer: 'cyclohexane', isCorrect: true},
            {answer: '3, 3-dimethyle [entane', isCorrect: false},
            {answer: '2, 2, 3-trimethyle pentane', isCorrect: false},
        ],
    }, {
        question:"Arrange the halogens F2, Cl2, Br2, I2, in order of their increasing reactivity with alkanes:",
        answerOptions: [
            {answer: 'I2 < Br2 < Cl2 < F2', isCorrect: true},
            {answer: 'Br2 < Cl2 < F2 < I2', isCorrect: false},
            {answer: 'F2 < Cl2 < Br2 < I2', isCorrect: false},
            {answer: 'Br2 < I2 < Cl2 < F2', isCorrect: false},
        ],
    }, {
        question:"Which has 80% methane, 10% C2H6 and 10% long chain hydrocarbons :",
        answerOptions: [
            {answer: 'petrolenum', isCorrect: false},
            {answer: 'natural gas', isCorrect: true},
            {answer: 'kerosene', isCorrect: false},
            {answer: 'medicinal oil', isCorrect: false},
        ],
    }, {
        question:"Number of C-atoms in gaseous hydrocarbons :",
        answerOptions: [
            {answer: 'C1 - C3', isCorrect: false},
            {answer: 'C1 - C4', isCorrect: true},
            {answer: 'C1 - C5', isCorrect: false},
            {answer: 'C1 - C6', isCorrect: false},
        ],
    }, {
        question:"Boiling range in K of fuel oils like diesel, gas oil : ",
        answerOptions: [
            {answer: '514-626', isCorrect: false},
            {answer: '512-624', isCorrect: false},
            {answer: '514-624', isCorrect: true},
            {answer: 'can not be said', isCorrect: true},
        ],
    }, {
        question:"It involves synthesis of alcohals or ether from alkenes in accordance with Markovnikov's rule : (Xnetous chellange)",
        answerOptions: [
            {answer: 'Stereochemistry of hydroxylation', isCorrect: false},
            {answer: 'Oxymercuration–demercuration', isCorrect: true},
            {answer: 'Isomerization', isCorrect: false},
            {answer: 'Polymerization:', isCorrect: false},
        ],
    }, {
        question:"In combustion (CnH2n+1) + (3n+1)/2  in presence of O2 ------> (n+1)H2O and :",
        answerOptions: [
            {answer: 'CO2', isCorrect: false},
            {answer: '(n-1)CO2', isCorrect: false},
            {answer: '(n+1)CO2', isCorrect: false},
            {answer: 'nCO2', isCorrect: true},
        ],
    }, {
        question:"Bond length of alkanes :",
        answerOptions: [
            {answer: 'C-C', isCorrect: true},
            {answer: 'H-C', isCorrect: false},
            {answer: 'C-H', isCorrect: false},
            {answer: 'H-H', isCorrect: false},
        ],
    },  {
        question:"In cracking higher alkane split into lower :",
        answerOptions: [
            {answer: 'alkane', isCorrect: false},
            {answer: 'alkene', isCorrect: false},
            {answer: 'hydrogen', isCorrect: false},
            {answer: 'all of these', isCorrect: true},
        ],
    },  {
        question:"In cracking CH4 -----> C + 2H2, at which temperature :",
        answerOptions: [
            {answer: '800°C', isCorrect: false},
            {answer: '900°C', isCorrect: false},
            {answer: '1000°C', isCorrect: true},
            {answer: 'can not be said', isCorrect: false},
        ],
    },  {
        question:"CH4 + Br2 -------> CCl4, which will be catylist of this reaction :",
        answerOptions: [
            {answer: 'heat', isCorrect: false},
            {answer: 'light', isCorrect: true},
            {answer: 'acid', isCorrect: false},
            {answer: 'H2', isCorrect: false},
        ],
    }, 
]

export default hydrocarbonquestions;