const threedimensionalquestions = [
  // 3-D Geometry and Vectors Questions

  {
    question: "Find the distance between the points A(1, 2, 3) and B(4, 6, 8).",
    answerOptions: [
      { answer: "7", isCorrect: true },
      { answer: "6", isCorrect: false },
      { answer: "8", isCorrect: false },
      { answer: "9", isCorrect: false },
    ],
  },
  {
    question:
      "Determine the angle between the vectors A = i + 2j + 3k and B = 2i - j + k.",
    answerOptions: [
      { answer: "54.74°", isCorrect: true },
      { answer: "45°", isCorrect: false },
      { answer: "60°", isCorrect: false },
      { answer: "30°", isCorrect: false },
    ],
  },
  {
    question:
      "Find the equation of the plane passing through the points (1, 2, 3), (4, 5, 6), and (7, 8, 9).",
    answerOptions: [
      { answer: "x - y + z = 0", isCorrect: true },
      { answer: "x + y + z = 12", isCorrect: false },
      { answer: "x - y + z = 6", isCorrect: false },
      { answer: "x + y - z = 0", isCorrect: false },
    ],
  },
  {
    question:
      "Find the volume of the parallelepiped formed by the vectors A = i + j + k, B = 2i - j + 2k, and C = i - 2j + 3k.",
    answerOptions: [
      { answer: "6", isCorrect: true },
      { answer: "4", isCorrect: false },
      { answer: "8", isCorrect: false },
      { answer: "12", isCorrect: false },
    ],
  },
  {
    question:
      "Find the coordinates of the centroid of the triangle with vertices A(1, 2, 3), B(4, 5, 6), and C(7, 8, 9).",
    answerOptions: [
      { answer: "(4, 5, 6)", isCorrect: true },
      { answer: "(3, 4, 5)", isCorrect: false },
      { answer: "(5, 6, 7)", isCorrect: false },
      { answer: "(2, 3, 4)", isCorrect: false },
    ],
  },
  {
    question:
      "Find the distance from the point (2, 3, 4) to the plane x - 2y + 3z = 6.",
    answerOptions: [
      { answer: "2", isCorrect: true },
      { answer: "3", isCorrect: false },
      { answer: "1", isCorrect: false },
      { answer: "4", isCorrect: false },
    ],
  },
  {
    question:
      "Find the projection of the vector A = 3i - 4j + 5k onto the vector B = i + j + k.",
    answerOptions: [
      { answer: "(6/3, 6/3, 6/3)", isCorrect: true },
      { answer: "(3, 4, 5)", isCorrect: false },
      { answer: "(4, 5, 6)", isCorrect: false },
      { answer: "(2, 2, 2)", isCorrect: false },
    ],
  },
  {
    question:
      "Find the vector equation of the line passing through the points (1, 2, 3) and (4, 5, 6).",
    answerOptions: [
      { answer: "r = (1, 2, 3) + t(3, 3, 3)", isCorrect: true },
      { answer: "r = (1, 2, 3) + t(1, 1, 1)", isCorrect: false },
      { answer: "r = (1, 2, 3) + t(2, 2, 2)", isCorrect: false },
      { answer: "r = (1, 2, 3) + t(4, 5, 6)", isCorrect: false },
    ],
  },
  {
    question:
      "Find the angle between the planes x + 2y - z = 3 and 2x - y + z = 4.",
    answerOptions: [
      { answer: "90°", isCorrect: true },
      { answer: "60°", isCorrect: false },
      { answer: "45°", isCorrect: false },
      { answer: "30°", isCorrect: false },
    ],
  },
  {
    question:
      "Find the equation of the plane passing through the points (1, 1, 1), (2, 2, 2), and (3, 3, 3).",
    answerOptions: [
      { answer: "x = y = z", isCorrect: true },
      { answer: "x + y + z = 6", isCorrect: false },
      { answer: "x - y + z = 0", isCorrect: false },
      { answer: "2x - y + z = 1", isCorrect: false },
    ],
  },
  {
    question:
      "Find the shortest distance between the skew lines r1 = (1, 2, 3) + t(1, 1, 1) and r2 = (4, 5, 6) + s(1, 2, 3).",
    answerOptions: [
      { answer: "sqrt(14)", isCorrect: true },
      { answer: "sqrt(10)", isCorrect: false },
      { answer: "sqrt(7)", isCorrect: false },
      { answer: "sqrt(9)", isCorrect: false },
    ],
  },
  {
    question:
      "Find the volume of the tetrahedron with vertices (0, 0, 0), (1, 0, 0), (0, 1, 0), and (0, 0, 1).",
    answerOptions: [
      { answer: "1/6", isCorrect: true },
      { answer: "1/2", isCorrect: false },
      { answer: "1/3", isCorrect: false },
      { answer: "1", isCorrect: false },
    ],
  },
  {
    question:
      "Find the area of the parallelogram formed by the vectors A = i + 2j + k and B = 2i - j + 3k.",
    answerOptions: [
      { answer: "√14", isCorrect: true },
      { answer: "√12", isCorrect: false },
      { answer: "√20", isCorrect: false },
      { answer: "√18", isCorrect: false },
    ],
  },
  {
    question:
      "Find the scalar triple product of the vectors A = i + j + k, B = 2i - j + 2k, and C = i - 2j + 3k.",
    answerOptions: [
      { answer: "6", isCorrect: true },
      { answer: "8", isCorrect: false },
      { answer: "4", isCorrect: false },
      { answer: "12", isCorrect: false },
    ],
  },
  {
    question: "Find the direction cosines of the vector A = 3i - 4j + 12k.",
    answerOptions: [
      { answer: "(1/√19, -4/√19, 12/√19)", isCorrect: true },
      { answer: "(3/√19, -4/√19, 12/√19)", isCorrect: false },
      { answer: "(3/√19, -4/√19, 12/√20)", isCorrect: false },
      { answer: "(3/√12, -4/√12, 12/√12)", isCorrect: false },
    ],
  },
  {
    question:
      "Find the equation of the sphere with center (1, 2, 3) and radius 4.",
    answerOptions: [
      { answer: "(x - 1)² + (y - 2)² + (z - 3)² = 16", isCorrect: true },
      { answer: "(x - 1)² + (y - 2)² + (z - 3)² = 8", isCorrect: false },
      { answer: "(x + 1)² + (y + 2)² + (z + 3)² = 16", isCorrect: false },
      { answer: "x² + y² + z² - 6x - 4y - 6z = -16", isCorrect: false },
    ],
  },
  {
    question:
      "Find the distance between the plane 2x - 3y + 4z = 7 and the point (1, 1, 1).",
    answerOptions: [
      { answer: "3", isCorrect: true },
      { answer: "4", isCorrect: false },
      { answer: "5", isCorrect: false },
      { answer: "2", isCorrect: false },
    ],
  },
  {
    question:
      "Find the vector equation of the line passing through the point (2, 3, 4) and parallel to the vector (1, -1, 2).",
    answerOptions: [
      { answer: "r = (2, 3, 4) + t(1, -1, 2)", isCorrect: true },
      { answer: "r = (2, 3, 4) + t(2, -2, 4)", isCorrect: false },
      { answer: "r = (2, 3, 4) + t(1, 1, 1)", isCorrect: false },
      { answer: "r = (2, 3, 4) + t(0, -1, 2)", isCorrect: false },
    ],
  },
  {
    question:
      "Find the coordinates of the orthocenter of the triangle with vertices (1, 2, 3), (4, 5, 6), and (7, 8, 9).",
    answerOptions: [
      { answer: "(4, 5, 6)", isCorrect: true },
      { answer: "(3, 4, 5)", isCorrect: false },
      { answer: "(2, 3, 4)", isCorrect: false },
      { answer: "(5, 6, 7)", isCorrect: false },
    ],
  },
  {
    question:
      "Find the angle between the vectors A = (1, 0, 1) and B = (0, 1, 1).",
    answerOptions: [
      { answer: "45°", isCorrect: true },
      { answer: "60°", isCorrect: false },
      { answer: "30°", isCorrect: false },
      { answer: "90°", isCorrect: false },
    ],
  },
  {
    question:
      "Find the midpoint of the line segment joining the points (2, 4, 6) and (8, 10, 12).",
    answerOptions: [
      { answer: "(5, 7, 9)", isCorrect: true },
      { answer: "(6, 7, 8)", isCorrect: false },
      { answer: "(4, 6, 8)", isCorrect: false },
      { answer: "(5, 8, 10)", isCorrect: false },
    ],
  },
  {
    question:
      "Find the equation of the plane that passes through the points (1, 1, 1), (2, 2, 2), and (3, 1, 1).",
    answerOptions: [
      { answer: "x - y + z = 1", isCorrect: true },
      { answer: "x + y + z = 4", isCorrect: false },
      { answer: "x + y - z = 2", isCorrect: false },
      { answer: "2x - y + z = 1", isCorrect: false },
    ],
  },
  {
    question:
      "Find the volume of the tetrahedron with vertices (0, 0, 0), (1, 2, 3), (4, 5, 6), and (7, 8, 9).",
    answerOptions: [
      { answer: "0", isCorrect: true },
      { answer: "1", isCorrect: false },
      { answer: "2", isCorrect: false },
      { answer: "3", isCorrect: false },
    ],
  },
  {
    question:
      "Find the distance between the parallel planes x - y + 2z = 3 and x - y + 2z = 5.",
    answerOptions: [
      { answer: "1/√6", isCorrect: true },
      { answer: "1/√2", isCorrect: false },
      { answer: "2/√6", isCorrect: false },
      { answer: "1", isCorrect: false },
    ],
  },
  {
    question:
      "Find the equation of the plane that is perpendicular to the vector (1, 2, -1) and passes through the point (2, 3, 4).",
    answerOptions: [
      { answer: "x + 2y - z = 5", isCorrect: true },
      { answer: "x - 2y + z = 5", isCorrect: false },
      { answer: "x + y - z = 4", isCorrect: false },
      { answer: "x - y + z = 5", isCorrect: false },
    ],
  },
  {
    question: "Find the length of the vector A = (4, -3, 12).",
    answerOptions: [
      { answer: "13", isCorrect: true },
      { answer: "14", isCorrect: false },
      { answer: "15", isCorrect: false },
      { answer: "16", isCorrect: false },
    ],
  },
  {
    question:
      "Find the coordinates of the foot of the perpendicular from the point (3, 4, 5) to the plane 2x - 3y + 4z = 6.",
    answerOptions: [
      { answer: "(2, 1, 1)", isCorrect: true },
      { answer: "(1, 2, 1)", isCorrect: false },
      { answer: "(3, 2, 2)", isCorrect: false },
      { answer: "(2, 3, 2)", isCorrect: false },
    ],
  },
  // 3-D Geometry and Vectors Questions at JEE Mains Level
  {
    question:
      "Find the distance between the points A(1, 2, 3) and B(-1, -2, -3).",
    answerOptions: [
      { answer: "6", isCorrect: true },
      { answer: "8", isCorrect: false },
      { answer: "7", isCorrect: false },
      { answer: "5", isCorrect: false },
    ],
  },
  {
    question:
      "Find the angle between the vectors A = 2i - j + 3k and B = i + 2j - k.",
    answerOptions: [
      { answer: "90°", isCorrect: true },
      { answer: "60°", isCorrect: false },
      { answer: "45°", isCorrect: false },
      { answer: "30°", isCorrect: false },
    ],
  },
  {
    question:
      "Find the equation of the plane passing through the points (1, 2, 3), (4, 5, 6), and (7, 8, 9).",
    answerOptions: [
      { answer: "x - y + z = 0", isCorrect: true },
      { answer: "x + y + z = 12", isCorrect: false },
      { answer: "x - y + z = 6", isCorrect: false },
      { answer: "x + y - z = 0", isCorrect: false },
    ],
  },
  {
    question:
      "Find the volume of the parallelepiped formed by the vectors A = i + j + k, B = 2i - j + k, and C = i - 2j + 3k.",
    answerOptions: [
      { answer: "6", isCorrect: true },
      { answer: "4", isCorrect: false },
      { answer: "8", isCorrect: false },
      { answer: "12", isCorrect: false },
    ],
  },
  {
    question:
      "Find the coordinates of the centroid of the triangle with vertices A(1, 2, 3), B(4, 5, 6), and C(7, 8, 9).",
    answerOptions: [
      { answer: "(4, 5, 6)", isCorrect: true },
      { answer: "(3, 4, 5)", isCorrect: false },
      { answer: "(5, 6, 7)", isCorrect: false },
      { answer: "(2, 3, 4)", isCorrect: false },
    ],
  },
  {
    question:
      "Find the distance from the point (2, 3, 4) to the plane x - 2y + 3z = 6.",
    answerOptions: [
      { answer: "2", isCorrect: true },
      { answer: "3", isCorrect: false },
      { answer: "1", isCorrect: false },
      { answer: "4", isCorrect: false },
    ],
  },
  {
    question:
      "Find the projection of the vector A = 3i - 4j + 5k onto the vector B = i + j + k.",
    answerOptions: [
      { answer: "(6/3, 6/3, 6/3)", isCorrect: true },
      { answer: "(3, 4, 5)", isCorrect: false },
      { answer: "(4, 5, 6)", isCorrect: false },
      { answer: "(2, 2, 2)", isCorrect: false },
    ],
  },
  {
    question:
      "Find the vector equation of the line passing through the points (1, 2, 3) and (4, 5, 6).",
    answerOptions: [
      { answer: "r = (1, 2, 3) + t(3, 3, 3)", isCorrect: true },
      { answer: "r = (1, 2, 3) + t(1, 1, 1)", isCorrect: false },
      { answer: "r = (1, 2, 3) + t(2, 2, 2)", isCorrect: false },
      { answer: "r = (1, 2, 3) + t(4, 5, 6)", isCorrect: false },
    ],
  },
  {
    question:
      "Find the angle between the planes x + 2y - z = 3 and 2x - y + z = 4.",
    answerOptions: [
      { answer: "90°", isCorrect: true },
      { answer: "60°", isCorrect: false },
      { answer: "45°", isCorrect: false },
      { answer: "30°", isCorrect: false },
    ],
  },
  {
    question:
      "Find the equation of the plane passing through the points (1, 1, 1), (2, 2, 2), and (3, 1, 1).",
    answerOptions: [
      { answer: "x - y + z = 1", isCorrect: true },
      { answer: "x + y + z = 4", isCorrect: false },
      { answer: "x + y - z = 2", isCorrect: false },
      { answer: "2x - y + z = 1", isCorrect: false },
    ],
  },
  {
    question:
      "Find the volume of the tetrahedron with vertices (0, 0, 0), (1, 0, 0), (0, 1, 0), and (0, 0, 1).",
    answerOptions: [
      { answer: "1/6", isCorrect: true },
      { answer: "1/2", isCorrect: false },
      { answer: "1/3", isCorrect: false },
      { answer: "1", isCorrect: false },
    ],
  },
  {
    question:
      "Find the area of the parallelogram formed by the vectors A = i + 2j + k and B = 2i - j + 3k.",
    answerOptions: [
      { answer: "√14", isCorrect: true },
      { answer: "√12", isCorrect: false },
      { answer: "√20", isCorrect: false },
      { answer: "√18", isCorrect: false },
    ],
  },
  {
    question:
      "Find the scalar triple product of the vectors A = i + j + k, B = 2i - j + 2k, and C = i - 2j + 3k.",
    answerOptions: [
      { answer: "6", isCorrect: true },
      { answer: "8", isCorrect: false },
      { answer: "4", isCorrect: false },
      { answer: "12", isCorrect: false },
    ],
  },
  {
    question: "Find the direction cosines of the vector A = 3i - 4j + 12k.",
    answerOptions: [
      { answer: "(3/√19, -4/√19, 12/√19)", isCorrect: true },
      { answer: "(3/√19, -4/√19, 12/√20)", isCorrect: false },
      { answer: "(3/√12, -4/√12, 12/√12)", isCorrect: false },
      { answer: "(3/√14, -4/√14, 12/√14)", isCorrect: false },
    ],
  },
  {
    question:
      "Find the equation of the sphere with center (1, 2, 3) and radius 4.",
    answerOptions: [
      { answer: "(x - 1)² + (y - 2)² + (z - 3)² = 16", isCorrect: true },
      { answer: "(x - 1)² + (y - 2)² + (z - 3)² = 8", isCorrect: false },
      { answer: "(x + 1)² + (y + 2)² + (z + 3)² = 16", isCorrect: false },
      { answer: "x² + y² + z² - 6x - 4y - 6z = -16", isCorrect: false },
    ],
  },
  {
    question:
      "Find the distance between the point (2, 3, 4) and the plane 2x - 3y + 4z = 7.",
    answerOptions: [
      { answer: "3", isCorrect: true },
      { answer: "4", isCorrect: false },
      { answer: "5", isCorrect: false },
      { answer: "2", isCorrect: false },
    ],
  },
  {
    question:
      "Find the vector equation of the line passing through the point (2, 3, 4) and parallel to the vector (1, -1, 2).",
    answerOptions: [
      { answer: "r = (2, 3, 4) + t(1, -1, 2)", isCorrect: true },
      { answer: "r = (2, 3, 4) + t(2, -2, 4)", isCorrect: false },
      { answer: "r = (2, 3, 4) + t(1, 1, 1)", isCorrect: false },
      { answer: "r = (2, 3, 4) + t(0, -1, 2)", isCorrect: false },
    ],
  },
  {
    question:
      "Find the coordinates of the orthocenter of the triangle with vertices (1, 2, 3), (4, 5, 6), and (7, 8, 9).",
    answerOptions: [
      { answer: "(4, 5, 6)", isCorrect: true },
      { answer: "(3, 4, 5)", isCorrect: false },
      { answer: "(2, 3, 4)", isCorrect: false },
      { answer: "(5, 6, 7)", isCorrect: false },
    ],
  },
  {
    question:
      "Find the distance between the two parallel planes x - y + 2z = 3 and x - y + 2z = 7.",
    answerOptions: [
      { answer: "2/√6", isCorrect: true },
      { answer: "1/√6", isCorrect: false },
      { answer: "2/√3", isCorrect: false },
      { answer: "4/√6", isCorrect: false },
    ],
  },
  {
    question: "Find the length of the vector A = (3, -4, 12).",
    answerOptions: [
      { answer: "13", isCorrect: true },
      { answer: "14", isCorrect: false },
      { answer: "15", isCorrect: false },
      { answer: "16", isCorrect: false },
    ],
  },
  {
    question:
      "Find the coordinates of the centroid of the tetrahedron with vertices (1, 2, 3), (4, 5, 6), (7, 8, 9), and (10, 11, 12).",
    answerOptions: [
      { answer: "(5.5, 6.5, 7.5)", isCorrect: true },
      { answer: "(5, 6, 7)", isCorrect: false },
      { answer: "(6, 7, 8)", isCorrect: false },
      { answer: "(4, 5, 6)", isCorrect: false },
    ],
  },
  {
    question:
      "Find the angle between the vectors A = (2, 3, -1) and B = (-1, 2, 1).",
    answerOptions: [
      { answer: "90°", isCorrect: true },
      { answer: "60°", isCorrect: false },
      { answer: "45°", isCorrect: false },
      { answer: "30°", isCorrect: false },
    ],
  },
  {
    question:
      "Find the projection of the vector A = 3i + 4j - k onto the vector B = i - j + 2k.",
    answerOptions: [
      { answer: "(2, -2, 4)", isCorrect: true },
      { answer: "(3, 4, -1)", isCorrect: false },
      { answer: "(1, -1, 2)", isCorrect: false },
      { answer: "(4, -3, 2)", isCorrect: false },
    ],
  },
  {
    question:
      "Find the equation of the plane passing through the point (1, -2, 3) and perpendicular to the vector (2, -1, 2).",
    answerOptions: [
      { answer: "2x - y + 2z = 4", isCorrect: true },
      { answer: "2x + y - 2z = 4", isCorrect: false },
      { answer: "x - y + z = 1", isCorrect: false },
      { answer: "x + 2y - z = 4", isCorrect: false },
    ],
  },
  {
    question:
      "Find the distance between the point (3, 4, 5) and the plane x + y + z = 10.",
    answerOptions: [
      { answer: "2", isCorrect: true },
      { answer: "3", isCorrect: false },
      { answer: "4", isCorrect: false },
      { answer: "1", isCorrect: false },
    ],
  },
  {
    question:
      "Find the volume of the parallelepiped formed by the vectors A = (1, 2, 3), B = (4, 5, 6), and C = (7, 8, 9).",
    answerOptions: [
      { answer: "0", isCorrect: true },
      { answer: "1", isCorrect: false },
      { answer: "2", isCorrect: false },
      { answer: "3", isCorrect: false },
    ],
  },
  {
    question:
      "Find the midpoint of the line segment joining the points (2, 3, 4) and (6, 7, 8).",
    answerOptions: [
      { answer: "(4, 5, 6)", isCorrect: true },
      { answer: "(3, 4, 5)", isCorrect: false },
      { answer: "(5, 6, 7)", isCorrect: false },
      { answer: "(4, 6, 8)", isCorrect: false },
    ],
  },
  {
    question:
     "If the angle between the vectors a = (1, 2, 3) and b = (4, -1, 2) is θ, find cos(θ).",
    answerOptions: [
      { answer: "0.8", isCorrect: true },
      { answer: "0.6", isCorrect: false },
      { answer: "0.4", isCorrect: false },
      { answer: "0.2", isCorrect: false },
    ],
    correctAnswer: "0.8",
    difficulty: "Hard",
    category: "Vectors",
  },
  {
    question:
      "Find the distance from the point (1, 1, 1) to the line x/2 = (y - 2)/3 = (z + 1)/4.",
    answerOptions: [
      { answer: "√6/5", isCorrect: true },
      { answer: "√3/5", isCorrect: false },
      { answer: "√2/5", isCorrect: false },
      { answer: "√4/5", isCorrect: false },
    ],
  },
  {
    question:
      "Find the vector equation of the line passing through the point (2, 3, 4) and perpendicular to the plane x + 2y - z = 6.",
    answerOptions: [
      { answer: "r = (2, 3, 4) + t(1, 2, -1)", isCorrect: true },
      { answer: "r = (2, 3, 4) + t(1, -2, 1)", isCorrect: false },
      { answer: "r = (2, 3, 4) + t(-1, 2, 1)", isCorrect: false },
      { answer: "r = (2, 3, 4) + t(-1, -2, -1)", isCorrect: false },
    ],
  },
  {
    question:
      "Find the volume of the tetrahedron with vertices (1, 0, 0), (0, 1, 0), (0, 0, 1), and (0, 0, 0).",
    answerOptions: [
      { answer: "1/6", isCorrect: true },
      { answer: "1/3", isCorrect: false },
      { answer: "1/2", isCorrect: false },
      { answer: "1", isCorrect: false },
    ],
  },
  {
    question:
      "Find the distance between the two skew lines r1 = (1, 2, 3) + t(1, 0, -1) and r2 = (4, 5, 6) + s(0, 1, 1).",
    answerOptions: [
      { answer: "√6", isCorrect: true },
      { answer: "√8", isCorrect: false },
      { answer: "√10", isCorrect: false },
      { answer: "√12", isCorrect: false },
    ],
  },
  {
    question:
      "Find the angle between the vectors A = (2, -3, 4) and B = (-1, 2, -2).",
    answerOptions: [
      { answer: "90°", isCorrect: true },
      { answer: "45°", isCorrect: false },
      { answer: "60°", isCorrect: false },
      { answer: "30°", isCorrect: false },
    ],
  },
  {
    question:
      "Find the length of the diagonal of the cuboid with sides 3, 4, and 5.",
    answerOptions: [
      { answer: "√50", isCorrect: true },
      { answer: "√52", isCorrect: false },
      { answer: "√54", isCorrect: false },
      { answer: "√56", isCorrect: false },
    ],
  },
  {
    question:
      "Find the direction ratios of the line of intersection of the planes x + y - z = 1 and 2x - y + z = 3.",
    answerOptions: [
      { answer: "(-1, 1, 1)", isCorrect: true },
      { answer: "(-1, 1, -1)", isCorrect: false },
      { answer: "(1, -1, 1)", isCorrect: false },
      { answer: "(1, -1, -1)", isCorrect: false },
    ],
  },
  {
    question:
      "Find the distance between the point (2, 3, 4) and the line x/2 = (y - 1)/3 = (z + 2)/4.",
    answerOptions: [
      { answer: "√6/5", isCorrect: true },
      { answer: "√5/4", isCorrect: false },
      { answer: "√7/6", isCorrect: false },
      { answer: "√8/7", isCorrect: false },
    ],
  },
  {
    question:
      "Find the scalar product of the vectors A = (2, -1, 3) and B = (1, 2, -1).",
    answerOptions: [
      { answer: "-1", isCorrect: true },
      { answer: "0", isCorrect: false },
      { answer: "1", isCorrect: false },
      { answer: "-2", isCorrect: false },
    ],
  },
  {
    question:
      "Find the coordinates of the foot of the perpendicular from the point (2, 3, 4) to the plane x + y + z = 6.",
    answerOptions: [
      { answer: "(1, 2, 3)", isCorrect: true },
      { answer: "(2, 2, 2)", isCorrect: false },
      { answer: "(3, 2, 1)", isCorrect: false },
      { answer: "(1, 3, 2)", isCorrect: false },
    ],
  },
  {
    question:
      "Find the vector equation of the plane passing through the points (1, 1, 1), (2, 2, 2), and (3, 3, 3).",
    answerOptions: [
      { answer: "x - y + z = 0", isCorrect: true },
      { answer: "x + y + z = 6", isCorrect: false },
      { answer: "x - y - z = 0", isCorrect: false },
      { answer: "x + y - z = 4", isCorrect: false },
    ],
  },
  {
    question:
      "Find the distance between the parallel planes 2x - y + z = 3 and 2x - y + z = 7.",
    answerOptions: [
      { answer: "2/√6", isCorrect: true },
      { answer: "3/√6", isCorrect: false },
      { answer: "1/√6", isCorrect: false },
      { answer: "4/√6", isCorrect: false },
    ],
  },
  {
    question:
      "Find the angle between the vectors A = (1, 2, 3) and B = (4, -5, 6).",
    answerOptions: [
      { answer: "90°", isCorrect: true },
      { answer: "45°", isCorrect: false },
      { answer: "60°", isCorrect: false },
      { answer: "30°", isCorrect: false },
    ],
  },
  {
    question:
      "Find the length of the perpendicular from the origin to the plane 3x - 4y + 12z = 7.",
    answerOptions: [
      { answer: "7/√169", isCorrect: true },
      { answer: "7/√13", isCorrect: false },
      { answer: "7/√14", isCorrect: false },
      { answer: "7/√15", isCorrect: false },
    ],
  },
  {
    question:
      "Find the volume of the parallelepiped formed by the vectors A = (1, 1, 1), B = (1, 2, 3), and C = (1, 1, 1).",
    answerOptions: [
      { answer: "0", isCorrect: true },
      { answer: "1", isCorrect: false },
      { answer: "2", isCorrect: false },
      { answer: "3", isCorrect: false },
    ],
  },
  {
    question:
      "Find the coordinates of the centroid of the tetrahedron with vertices (1, 2, 3), (4, 5, 6), (7, 8, 9), and (10, 11, 12).",
    answerOptions: [
      { answer: "(5.5, 6.5, 7.5)", isCorrect: true },
      { answer: "(6, 7, 8)", isCorrect: false },
      { answer: "(4, 5, 6)", isCorrect: false },
      { answer: "(3, 4, 5)", isCorrect: false },
    ],
  },
  {
    question:
      "Find the distance between the point (1, 2, 3) and the line x/2 = (y - 1)/3 = (z + 2)/4.",
    answerOptions: [
      { answer: "√6/5", isCorrect: true },
      { answer: "√5/4", isCorrect: false },
      { answer: "√7/6", isCorrect: false },
      { answer: "√8/7", isCorrect: false },
    ],
  },
  {
    question: "Find the length of the vector A = (1, -2, 3) + (4, 5, -6).",
    answerOptions: [
      { answer: "√78", isCorrect: true },
      { answer: "√80", isCorrect: false },
      { answer: "√82", isCorrect: false },
      { answer: "√76", isCorrect: false },
    ],
  },
  {
    question:
      "Find the equation of the plane passing through the point (1, 1, 1) and perpendicular to the vector (2, 2, 1).",
    answerOptions: [
      { answer: "2x + 2y + z = 6", isCorrect: true },
      { answer: "x + y + z = 3", isCorrect: false },
      { answer: "2x - y + z = 4", isCorrect: false },
      { answer: "x - y + 2z = 1", isCorrect: false },
    ],
  },
  {
    question:
      "Find the projection of the vector (3, -4, 5) onto the vector (1, 2, -2).",
    answerOptions: [
      { answer: "(2, -4, -4)", isCorrect: true },
      { answer: "(3, -4, -3)", isCorrect: false },
      { answer: "(1, -2, 2)", isCorrect: false },
      { answer: "(4, -4, -5)", isCorrect: false },
    ],
  },
  {
    question:
      "A drone is flying at a height of 100 meters above the ground and moves horizontally at a speed of 15 meters per second. What will be its displacement after 10 seconds?",
    answerOptions: [
      { answer: "150 meters", isCorrect: true },
      { answer: "100 meters", isCorrect: false },
      { answer: "200 meters", isCorrect: false },
      { answer: "50 meters", isCorrect: false },
    ],
  },
  {
    question:
      "A car travels 3 km north, then 4 km east. What is the straight-line distance from the starting point to the final point?",
    answerOptions: [
      { answer: "5 km", isCorrect: true },
      { answer: "6 km", isCorrect: false },
      { answer: "7 km", isCorrect: false },
      { answer: "8 km", isCorrect: false },
    ],
  },
  {
    question:
      "A box has dimensions 2 m x 3 m x 6 m. What is the length of the diagonal of the box?",
    answerOptions: [
      { answer: "7 m", isCorrect: true },
      { answer: "8 m", isCorrect: false },
      { answer: "9 m", isCorrect: false },
      { answer: "10 m", isCorrect: false },
    ],
  },
  {
    question:
      "A vector A has components (3, -2, 5) and a vector B has components (-1, 4, 2). What is the dot product of A and B?",
    answerOptions: [
      { answer: "10", isCorrect: true },
      { answer: "8", isCorrect: false },
      { answer: "12", isCorrect: false },
      { answer: "14", isCorrect: false },
    ],
  },
  {
    question:
      "A particle moves along a path defined by the vector function r(t) = (2t, t^2, 3t). What is the velocity vector at t = 2 seconds?",
    answerOptions: [
      { answer: "(2, 4, 3)", isCorrect: true },
      { answer: "(4, 8, 6)", isCorrect: false },
      { answer: "(6, 4, 3)", isCorrect: false },
      { answer: "(2, 2, 3)", isCorrect: false },
    ],
  },
  {
    question:
      "A plane passes through the points (1, 2, 3), (4, 5, 6), and (7, 8, 9). What is the normal vector to this plane?",
    answerOptions: [
      { answer: "(1, -2, 1)", isCorrect: true },
      { answer: "(2, -1, 1)", isCorrect: false },
      { answer: "(1, 1, -2)", isCorrect: false },
      { answer: "(1, -1, 0)", isCorrect: false },
    ],
  },
  {
    question:
      "Two forces are acting on a point: 10 N at an angle of 30° to the horizontal and 20 N at an angle of 45° to the horizontal. What is the magnitude of the resultant force?",
    answerOptions: [
      { answer: "25.8 N", isCorrect: true },
      { answer: "28.3 N", isCorrect: false },
      { answer: "30.0 N", isCorrect: false },
      { answer: "22.5 N", isCorrect: false },
    ],
  },
  {
    question:
      "A ship sails 30 km north and then 40 km east. How far is the ship from its starting point?",
    answerOptions: [
      { answer: "50 km", isCorrect: true },
      { answer: "45 km", isCorrect: false },
      { answer: "60 km", isCorrect: false },
      { answer: "70 km", isCorrect: false },
    ],
  },
  {
    question:
      "Given the vector field F(x, y, z) = (x^2, y^2, z^2), what is the divergence of F?",
    answerOptions: [
      { answer: "2x + 2y + 2z", isCorrect: true },
      { answer: "x + y + z", isCorrect: false },
      { answer: "x^2 + y^2 + z^2", isCorrect: false },
      { answer: "0", isCorrect: false },
    ],
  },
  {
    question:
      "A cylinder has a height of 10 meters and a base radius of 4 meters. What is the surface area of the cylinder?",
    answerOptions: [
      { answer: "879.6 square meters", isCorrect: true },
      { answer: "800 square meters", isCorrect: false },
      { answer: "900 square meters", isCorrect: false },
      { answer: "850 square meters", isCorrect: false },
    ],
  },
  {
    question:
      "The coordinates of three points in space are A(1, 2, 3), B(4, 5, 6), and C(7, 8, 9). Are these points collinear?",
    answerOptions: [
      { answer: "Yes", isCorrect: true },
      { answer: "No", isCorrect: false },
      { answer: "Cannot be determined", isCorrect: false },
      { answer: "Only two points are collinear", isCorrect: false },
    ],
  },
  {
    question:
      "A force of 50 N acts at an angle of 60° to the direction of displacement of 20 meters. What is the work done by the force?",
    answerOptions: [
      { answer: "500 J", isCorrect: true },
      { answer: "400 J", isCorrect: false },
      { answer: "300 J", isCorrect: false },
      { answer: "600 J", isCorrect: false },
    ],
  },
  {
    question:
      "A line passes through the points (1, 2, 3) and (4, 5, 6). What is the direction ratios of the line?",
    answerOptions: [
      { answer: "3, 3, 3", isCorrect: true },
      { answer: "1, 1, 1", isCorrect: false },
      { answer: "2, 2, 2", isCorrect: false },
      { answer: "3, 4, 5", isCorrect: false },
    ],
  },
  {
    question:
      "A rectangular prism has dimensions 4 meters, 5 meters, and 6 meters. What is the volume of the prism?",
    answerOptions: [
      { answer: "120 cubic meters", isCorrect: true },
      { answer: "100 cubic meters", isCorrect: false },
      { answer: "110 cubic meters", isCorrect: false },
      { answer: "130 cubic meters", isCorrect: false },
    ],
  },
  {
    question:
      "A vector A = (3, -2, 4) and a vector B = (1, 5, -2). What is the angle between vectors A and B?",
    answerOptions: [
      { answer: "90°", isCorrect: true },
      { answer: "45°", isCorrect: false },
      { answer: "60°", isCorrect: false },
      { answer: "30°", isCorrect: false },
    ],
  },
  {
    question:
      "A ladder leans against a wall making an angle of 75° with the ground. If the length of the ladder is 10 meters, how high up the wall does the ladder reach?",
    answerOptions: [
      { answer: "9.66 meters", isCorrect: true },
      { answer: "8.66 meters", isCorrect: false },
      { answer: "7.66 meters", isCorrect: false },
      { answer: "10 meters", isCorrect: false },
    ],
  },
  {
    question:
      "A particle moves in 3D space along the path defined by the parametric equations x = t, y = t^2, z = t^3. What is the speed of the particle at t = 2 seconds?",
    answerOptions: [
      { answer: "15 units per second", isCorrect: true },
      { answer: "12 units per second", isCorrect: false },
      { answer: "20 units per second", isCorrect: false },
      { answer: "10 units per second", isCorrect: false },
    ],
  },
  {
    question:
      "If a vector field F(x, y, z) = (x^2 + y^2, y^2 + z^2, z^2 + x^2) is given, what is the curl of F?",
    answerOptions: [
      { answer: "(0, 0, 0)", isCorrect: true },
      { answer: "(2x, 2y, 2z)", isCorrect: false },
      { answer: "(x^2, y^2, z^2)", isCorrect: false },
      { answer: "(2y - 2z, 2z - 2x, 2x - 2y)", isCorrect: false },
    ],
  },
  {
    question: "If the vectors a = (1, 2, 3) and b = (4, -1, 2) are perpendicular, what is the value of a · b?",
    answerOptions: [
      { answer: "1", isCorrect: false },
      { answer: "-1", isCorrect: false },
      { answer: "2", isCorrect: false },
      { answer: "0", isCorrect: true }
    ],
    correctAnswer: "0",
    difficulty: "Medium",
    category: "Vectors"
  },
];


export default threedimensionalquestions;
