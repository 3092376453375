const groupefifteenquestions = [
    {
        question:"Group-15 elements are called :",
        answerOptions: [
            {answer: 'Pincogens', isCorrect: true},
            {answer: 'Polonium', isCorrect: false},
            {answer: 'Halogens', isCorrect: false},
            {answer: 'Noble gas', isCorrect: false},
        ],
    }, {
        question:"Physical state of nitrogen is :",
        answerOptions: [
            {answer: 'solid', isCorrect: false},
            {answer: 'liquid', isCorrect: false},
            {answer: 'diatomic', isCorrect: true},
            {answer: 'triangular', isCorrect: false},
        ],
    },  {
        question:"Structure of nitrigen is :",
        answerOptions: [
            {answer: 'N - N', isCorrect: false},
            {answer: 'N = N', isCorrect: false},
            {answer: 'N ≡ N', isCorrect: true},
            {answer: 'can not be said', isCorrect: false},
        ],
    },  {
        question:"Phosphorous has how many main allotropes :",
        answerOptions: [
            {answer: 'one', isCorrect: false},
            {answer: 'two', isCorrect: false},
            {answer: 'three', isCorrect: true},
            {answer: 'four', isCorrect: false},
        ],
    },  {
        question:"Which elements has high electronegativity in group-15  :",
        answerOptions: [
            {answer: 'P', isCorrect: false},
            {answer: 'Sb', isCorrect: false},
            {answer: 'Bi', isCorrect: false},
            {answer: 'N', isCorrect: true},
        ],
    },  {
        question:"Nitrogen shows oxidation states from :",
        answerOptions: [
            {answer: '-3 to +3', isCorrect: false},
            {answer: '-3 to +2', isCorrect: false},
            {answer: '-3 to +5', isCorrect: true},
            {answer: '-3 to +6', isCorrect: false},
           
        ],
    }, {
        question:" All elements of this group form which type of hydrites :",
        answerOptions: [
            {answer: 'MH2', isCorrect: false},
            {answer: 'MH3', isCorrect: true},
            {answer: 'MH4', isCorrect: false},
            {answer: 'MH5', isCorrect: false},
        ],
    },  {
        question:" Toxicity odf this group is :",
        answerOptions: [
            {answer: 'decreases', isCorrect: false},
            {answer: 'increases', isCorrect: true},
            {answer: 'remain unchanged', isCorrect: false},
            {answer: 'can not be said', isCorrect: false},
        ],
    },  {
        question:"Heat of formation (ΔHf) of this group is :",
        answerOptions: [
            {answer: 'decreases', isCorrect: true},
            {answer: 'increases', isCorrect: false},
            {answer: 'remain constant', isCorrect: false},
            {answer: 'can not be said', isCorrect: false},
        ],
    },  {
        question:"Any ammonium salt on heating with alkali forms  :",
        answerOptions: [
            {answer: 'NH3', isCorrect: true},
            {answer: 'NH2', isCorrect: false},
            {answer: 'NH4', isCorrect: false},
            {answer: 'NH', isCorrect: false},
        ],
    },  {
        question:"Ionic nitrides are hydrolyzed by water to produce :",
        answerOptions: [
            {answer: 'NH', isCorrect: false},
            {answer: 'NH2', isCorrect: false},
            {answer: '2NH', isCorrect: false},
            {answer: 'NH3', isCorrect: true},
        ],
    },  {
        question:"When a mixture of white P(P4) and NaOH solution are heated, which is formed :",
        answerOptions: [
            {answer: 'PH3', isCorrect: true},
            {answer: 'PH2', isCorrect: false},
            {answer: '2Ph', isCorrect: false},
            {answer: 'can not be said', isCorrect: false},
        ],
    },  {
        question:" NH3 is manufactured by  :",
        answerOptions: [
            {answer: 'Haber process', isCorrect: false},
            {answer: 'Cyanamid process', isCorrect: false},
            {answer: 'Electric furnace', isCorrect: false},
            {answer: 'All of these', isCorrect: true},
        ],
    },  {
        question:"Copper reacts with dil. HNO3(8M) to form NO. It is reduction of :",
        answerOptions: [
            {answer: 'NO3', isCorrect: true},
            {answer: 'NO2', isCorrect: false},
            {answer: '2No', isCorrect: false},
            {answer: 'can not be said', isCorrect: false},
        ],
    },  {
        question:"Cu + conc. HNO3 ----> NO2, catylist used in this reaaction will be :",
        answerOptions: [
            {answer: 'O2', isCorrect: false},
            {answer: 'Heat', isCorrect: true},
            {answer: 'conc. acid', isCorrect: false},
            {answer: 'conc. base', isCorrect: false},
        ],
    },  {
        question:"Which is prepared in large scale by liquefaction and fractional distillation of liquid air :",
        answerOptions: [
            {answer: 'NO2', isCorrect: false},
            {answer: 'CO2', isCorrect: false},
            {answer: 'PO4', isCorrect: false},
            {answer: 'N2', isCorrect: true},
        ],
    },  {
        question:"By furnance process, which can be prepared :",
        answerOptions: [
            {answer: 'H2PO4', isCorrect: false},
            {answer: 'HPO4', isCorrect: false},
            {answer: 'H3PO4', isCorrect: true},
            {answer: 'can not ba said', isCorrect: false},
        ],
    },  {
        question:"White phosphours has :",
        answerOptions: [
            {answer: 'Six P - P bonds', isCorrect: false},
            {answer: 'Four long pairs', isCorrect: false},
            {answer: 'P P P angle of 60°', isCorrect: false},
            {answer: 'All of these', isCorrect: true},
        ],
    },  {
        question:"The number of P - O - P bonds in cyclic trimetaphosphate ion is  :",
        answerOptions: [
            {answer: 'one', isCorrect: false},
            {answer: 'two', isCorrect: false},
            {answer: 'three', isCorrect: true},
            {answer: 'four', isCorrect: false},
        ],
    },  {
        question:"Which of the following is the strongest base :",
        answerOptions: [
            {answer: 'SbH3', isCorrect: false},
            {answer: 'AsH3', isCorrect: false},
            {answer: 'PH3', isCorrect: false},
            {answer: 'NH3', isorrect: true},
        ],
    },
]

export default groupefifteenquestions;