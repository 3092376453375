const basicconceptquestions= [
    {
        question:"S.I units of density of substance :",
        answerOptions: [
            {answer: 'mol/L', isCorrect: false},
            {answer: 'kg/m^3', isCorrect: true},
            {answer: 'g/L', isCorrect: false},
            {answer: 'g/cm^3', isCorrect: false},
        ],
    }, {
        question:"1 joule is equal to :",
        answerOptions: [
            {answer: '1 kg m/s²', isCorrect: false},
            {answer: '1 kg m² s^-2', isCorrect: true},
            {answer: '1 kg m² s^-1', isCorrect: false},
            {answer: '1 kg m^3 s^-1', isCorrect: false},
        ],
    },  {
        question:"Which is the following among is not a base unit :",
        answerOptions: [
            {answer: 'area', isCorrect: true},
            {answer: 'length', isCorrect: false},
            {answer: 'time', isCorrect: false},
            {answer: 'mass', isCorrect: false},
        ],
    },  {
        question:" What is inclination of line whose gradient is 1 :",
        answerOptions: [
            {answer: '30°', isCorrect: false},
            {answer: '45°', isCorrect: true},
            {answer: '60°', isCorrect: false},
            {answer: '90°', isCorrect: false},
        ],
    },  {
        question:"The value of cos(60)° is equal to :",
        answerOptions: [
            {answer: '1/2', isCorrect: true},
            {answer: '1', isCorrect: false},
            {answer: '0', isCorrect: false},
            {answer: '∞', isCorrect: false},
        ],
    },  {
        question:" Derivative of e^x w.r.t. x is",
        answerOptions: [
            {answer: '1', isCorrect: false},
            {answer: '0', isCorrect: false},
            {answer: 'e^x', isCorrect: true},
            {answer: 'none of these', isCorrect: false},
           
        ],
    }, {
        question:"cos(180 + θ)° is equal to :",
        answerOptions: [
            {answer: '-cos θ', isCorrect: true},
            {answer: 'cos θ', isCorrect: false},
            {answer: 'sin θ', isCorrect: false},
            {answer: '-sin θ', isCorrect: false},
        ],
    },  {
        question:"Dimension of linear impulse are :",
        answerOptions: [
            {answer: '[M L^-2 T^-3]', isCorrect: false},
            {answer: '[M L^-2]', isCorrect: false},
            {answer: '[M L T^-1]', isCorrect: true},
            {answer: '[M L T^-2]', isCorrect: false},
        ],
    },  {
        question:": If N = a^x; then log(a) N is equal to :",
        answerOptions: [
            {answer: 'N', isCorrect: false},
            {answer: '1', isCorrect: false},
            {answer: 'x', isCorrect: true},
            {answer: '10', isCorrect: false},
        ],
    },  {
        question:"Which one is the vector quantity :",
        answerOptions: [
            {answer: 'time', isCorrect: false},
            {answer: 'temperature', isCorrect: false},
            {answer: 'flux density', isCorrect: false},
            {answer: 'magnetic field intensity', isCorrect: true},
        ],
    },  {
        question:"The value of 8!/6! is :",
        answerOptions: [
            {answer: '224', isCorrect: false},
            {answer: '28', isCorrect: false},
            {answer: '20', isCorrect: false},
            {answer: '10', isCorrect: true},
        ],
    },  {
        question:"Derivative of x + 1/x w.r.t.x is :",
        answerOptions: [
            {answer: '1 - 1/x²', isCorrect: true},
            {answer: '1 + 1/x²', isCorrect: false},
            {answer: '- 1 - 1/x²', isCorrect: false},
            {answer: '- 1 + 1/x²', isCorrect: false},
        ],
    },  {
        question:"Integrate of x w.r.t.x with the limits x = 2 to x = 4 is :",
        answerOptions: [
            {answer: '60', isCorrect: true},
            {answer: '40', isCorrect: false},
            {answer: '20', isCorrect: false},
            {answer: '10', isCorrect: false},
        ],
    },  {
        question:" Slope of line 2x - y = 3 :",
        answerOptions: [
            {answer: '-2', isCorrect: false},
            {answer: '-1/2', isCorrect: false},
            {answer: '1/2', isCorrect: false},
            {answer: '2', isCorrect: true},
        ],
    },  {
        question:"log(a) 1 is equal to :",
        answerOptions: [
            {answer: '0', isCorrect: true},
            {answer: '1', isCorrect: false},
            {answer: '∞', isCorrect: false},
            {answer: '10', isorrect: false},
        ],
    },
]

export default basicconceptquestions;