import React, { useState } from 'react';
import { auth, fireDB } from '../config/firebase';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { setDoc, doc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import '../authStyle/signup.css';

function Signup() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [role, setRole] = useState('student'); // Default role
    const [schoolOrCollege, setSchoolOrCollege] = useState('');
    const [country, setCountry] = useState('');
    const navigate = useNavigate();

    const signup = async () => {
        try {
            // Create user with email and password
            const result = await createUserWithEmailAndPassword(auth, email, password);
            const user = result.user;

            // Store user data in Firestore
            await setDoc(doc(fireDB, 'users', user.uid), {
                userId: user.uid,  // Add userId (UID) here
                name,
                email: user.email,
                description,
                role,
                school_or_college: schoolOrCollege,
                country,
                time: new Date(),
            });

            // Display success toast
            toast.success("Signup successful", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });

            // Redirect to dashboard
            navigate('/');
        } catch (error) {
            // Handle specific Firebase Auth errors
            if (error.code === 'auth/email-already-in-use') {
                toast.error("Email already in use. Please login instead.", {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                // Optionally redirect to login page
                navigate('/login');
            } else {
                // Display other errors
                toast.error(`Signup failed: ${error.message}`, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }
        }
    };

    return (
        <div className="flex justify-center items-center h-screen">
            <div className="bg-gray-800 px-10 py-10 rounded-xl">
                <h1 className="text-center text-white text-xl mb-4 font-bold">Signup</h1>
                <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="bg-gray-600 mb-4 px-2 py-2 w-full lg:w-[20em] rounded-lg text-white placeholder:text-gray-200 outline-none"
                    placeholder="Name"
                />
                <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="bg-gray-600 mb-4 px-2 py-2 w-full lg:w-[20em] rounded-lg text-white placeholder:text-gray-200 outline-none"
                    placeholder="Email"
                />
                <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="bg-gray-600 mb-4 px-2 py-2 w-full lg:w-[20em] rounded-lg text-white placeholder:text-gray-200 outline-none"
                    placeholder="Create new password"
                />
                
                <button
                    onClick={signup}
                    className="bg-yellow-500 w-full text-black font-bold px-2 py-2 rounded-lg"
                >
                    Signup
                </button>
                <p className="text-white mt-4 text-center">
                    Already have an account?{" "}
                    <span
                        className="text-yellow-500 cursor-pointer"
                        onClick={() => navigate('/login')}
                    >
                        Login here
                    </span>
                </p>
            </div>
        </div>
    );
}

export default Signup;
