const chemicalkenaticquestions = [
    {
        question:"Arrhenious equations is denoted by :",
        answerOptions: [
            {answer: 'k = - Ae^Ea/RT', isCorrect: false},
            {answer: 'k = Ae^-Ea/RT', isCorrect: true},
            {answer: 'k = - Ae^-Ea/RT', isCorrect: false},
            {answer: 'k = Ae^Ea/RT', isCorrect: false},
        ],
    }, {
        question:"The rate of a reaction, on increasing concentration of the reactants :",
        answerOptions: [
            {answer: 'increase', isCorrect: true},
            {answer: 'decrease', isCorrect: false},
            {answer: 'unaltered', isCorrect: false},
            {answer: 'none of these', isCorrect: false},
        ],
    },  {
        question:"According to the collision theory, the rate of a reaction depends on the number of colliding molecules per ml unit time :",
        answerOptions: [
            {answer: 'no', isCorrect: false},
            {answer: 'may be', isCorrect: false},
            {answer: 'yes', isCorrect: true},
            {answer: 'data insufficient', isCorrect: false},
        ],
    },  {
        question:"Subdivision of a catalyst makes it more efficient due to increase of :",
        answerOptions: [
            {answer: 'valence bond', isCorrect: false},
            {answer: 'valence electron', isCorrect: false},
            {answer: 'free valence bond', isCorrect: true},
            {answer: 'free radical', isCorrect: false},
        ],
    },  {
        question:"The one which is a unimolecular reaction is :",
        answerOptions: [
            {answer: '2HI -> H2 + I2', isCorrect: false},
            {answer: 'N2O5 -> N2O5 + (1/2)O2', isCorrect: true},
            {answer: 'H2 + Cl2 -> 2HCl', isCorrect: false},
            {answer: 'PCl3 + Cl2 -> PCl5', isCorrect: false},
        ],
    },  {
        question:"A catalyst dont not initiate a :",
        answerOptions: [
            {answer: 'reaction', isCorrect: true},
            {answer: 'product', isCorrect: false},
            {answer: 'order', isCorrect: false},
            {answer: 'all', isCorrect: false},
           
        ],
    }, {
        question:"On increase temperature, rate of a reaction increase, because of :",
        answerOptions: [
            {answer: 'activated molecules increase', isCorrect: false},
            {answer: 'activated molecules decrease', isCorrect: true},
            {answer: 'activateed molecules become 0', isCorrect: false},
            {answer: 'data insufficient', isCorrect: false},
        ],
    },  {
        question:"Main purpose of catalyst in rate of a reaction :",
        answerOptions: [
            {answer: 'increase', isCorrect: true},
            {answer: 'decrease', isCorrect: false},
            {answer: 'remain unchanged', isCorrect: false},
            {answer: 'all of the above', isCorrect: false},
        ],
    },  {
        question:"The half-life period t(1/2) of a reaction varies with the initial concentration of a reaction as. The order of reaction is  :",
        answerOptions: [
            {answer: '0', isCorrect: false},
            {answer: '1', isCorrect: false},
            {answer: '2', isCorrect: false},
            {answer: 'none of these', isCorrect: true},
        ],
    },  {
        question:"Hydrolysis of cane suger in the presence of a mineral acid is an example of which catalysis :",
        answerOptions: [
            {answer: 'homogenius', isCorrect: true},
            {answer: 'hetrogenius', isCorrect: false},
            {answer: 'both', isCorrect: false},
            {answer: 'none of the above', isCorrect: false},
        ],
    },  {
        question:"The specific rate constant of a first-order-reaction depends on :",
        answerOptions: [
            {answer: 'concentration', isCorrect: false},
            {answer: 'tme', isCorrect: false},
            {answer: 'product', isCorrect: false},
            {answer: 'temperature', isCorrect: true},
        ],
    },  {
        question:"The enzyme zymase converts glucose into :",
        answerOptions: [
            {answer: 'alcohol', isCorrect: true},
            {answer: 'carbon', isCorrect: false},
            {answer: 'phenol', isCorrect: false},
            {answer: 'ethene', isCorrect: false},
        ],
    },  {
        question:"The equations which expressed the effect of temperature on the velocity constant of reaction is :",
        answerOptions: [
            {answer: 'faraday eqn.', isCorrect: false},
            {answer: 'arrheniusn eqn.', isCorrect: true},
            {answer: 'avogadro eqn.', isCorrect: false},
            {answer: 'lenz eqn.', isCorrect: false},
        ],
    },  {
        question:"Which theory explains the mechanism of enzyme reactions :",
        answerOptions: [
            {answer: 'lock', isCorrect: false},
            {answer: 'kay', isCorrect: false},
            {answer: 'lock and key', isCorrect: true},
            {answer: 'can not be said', isCorrect: false},
        ],
    },  {
        question:"The sum of concentration term on which the rate of a reaction actually depends is called :",
        answerOptions: [
            {answer: 'power of reaction', isCorrect: false},
            {answer: 'order of reaction', isCorrect: true},
            {answer: 'root of raction', isCorrect: false},
            {answer: 'fraction of ractions', isCorrect: false},
        ],
    },  {
        question:"Nitric oxide reacts with H2 to give N2 and :",
        answerOptions: [
            {answer: 'H2O', isCorrect: true},
            {answer: 'NO3', isCorrect: false},
            {answer: '2H2O', isCorrect: false},
            {answer: 'NO', isCorrect: false},
        ],
    },  {
        question:"If the rate of reactions depends upon two concentration term is called :",
        answerOptions: [
            {answer: 'first order', isCorrect: true},
            {answer: 'second order', isCorrect: false},
            {answer: 'third order', isCorrect: false},
            {answer: 'none of these', isCorrect: false},
        ],
    },  {
        question:"The name catalyst was first proposed by :",
        answerOptions: [
            {answer: 'Ruterford', isCorrect: false},
            {answer: 'Graham', isCorrect: false},
            {answer: 'Berzelius', isCorrect: true},
            {answer: 'Langmuir', isCorrect: false},
        ],
    },  {
        question:"Decomposition of nitrogen peroxide :",
        answerOptions: [
            {answer: 'first order', isCorrect: true},
            {answer: 'second order', isCorrect: false},
            {answer: 'third order', isCorrect: false},
            {answer: 'all of these', isCorrect: false },
        ],
    },  {
        question:"Efficiency of a catalyst depends upon :",
        answerOptions: [
            {answer: 'room temp', isCorrect: false},
            {answer: 'absolute temp', isCorrect: false},
            {answer: 'optimum temp', isCorrect: true},
            {answer: 'molar mass', isCorrect: false},
        ],
    },  {
        question:"For the chemical change A -> B, it is found that the rate raction double when the concentration is incresed four times, The order in A for this reaction is :",
        answerOptions: [
            {answer: 'zero', isCorrect: false},
            {answer: 'half', isCorrect: false},
            {answer: 'one', isCorrect: false},
            {answer: 'two', isCorrect: true},
        ],
    },  {
        question:"A substance which decreases the rate of a reaction is a :",
        answerOptions: [
            {answer: 'inhibitor', isCorrect: true},
            {answer: 'moderatir', isCorrect: false},
            {answer: 'promoter', isCorrect: false},
            {answer: 'poison', isCorrect: false},
        ],
    },  {
        question:"Platimum catalyst used in the oxidation of H2 is poisoned by :",
        answerOptions: [
            {answer: 'CO2', isCorrect: false},
            {answer: 'H2O', isCorrect: false},
            {answer: 'CO', isCorrect: true},
            {answer: 'data insufficient', isCorrect: false},
        ],
    },  {
        question:"If the concentration is expressed moles/litre, the unit of rate constant for a first-order reaction is :",
        answerOptions: [
            {answer: 'moles liter^-1 s^-1', isCorrect: false},
            {answer: 'moles liter^-1', isCorrect: false},
            {answer: 's^-1', isCorrect: true},
            {answer: 'moles^-1', isCorrect: false},
        ],
    },  {
        question:"The rate at which a substance reacts is proportional to its :",
        answerOptions: [
            {answer: 'active mass', isCorrect: true},
            {answer: 'mole fraction', isCorrect: false},
            {answer: 'concentration in g/litre', isCorrect: false},
            {answer: 'data insufficient', isCorrect: false},
        ],
    },  {
        question:"The rate of a reaction usually :",
        answerOptions: [
            {answer: 'increase with increase in temp', isCorrect: true},
            {answer: 'decrease with increase in temp', isCorrect: false},
            {answer: 'remains unaffected', isCorrect: false},
            {answer: 'none of these', isCorrect: false},
        ],
    },  {
        question:"Which of the following are most efficient catalysts :",
        answerOptions: [
            {answer: 'transition metals', isCorrect: true},
            {answer: 'alkali metals', isCorrect: false},
            {answer: 'radioactive metals', isCorrect: false},
            {answer: 'alkaline earth metals', isCorrect: false},
        ],
    },  {
        question:"Which is unchanged in mass and chemical composition at end of the reaction :",
        answerOptions: [
            {answer: 'molar mass', isCorrect: false},
            {answer: 'catalyist', isCorrect: true},
            {answer: 'degree of freedom', isCorrect: false},
            {answer: 'order of reaction', isCorrect: false},
        ],
    },  {
        question:"The statement which is true in respect of order of a reaction is :",
        answerOptions: [
            {answer: 'whole number', isCorrect: false},
            {answer: 'fraction', isCorrect: false},
            {answer: 'both', isCorrect: true},
            {answer: 'never be a fraction', isCorrect: false},
        ],
    },  {
        question:"The rate equation of reaction is dx/dt = k[A]^a[B]^b for which the order of the reaction is :",
        answerOptions: [
            {answer: 'a + b', isCorrect: true},
            {answer: 'a - b', isCorrect: false},
            {answer: 'a', isCorrect: false},
            {answer: 'b', isorrect: false},
        ],
    },
]

export default chemicalkenaticquestions;