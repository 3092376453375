import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { auth, fireDB } from "../config/firebase"; // Update with your Firebase config
import {
  doc,
  getDoc,
  updateDoc,
  increment,
  query,
  collection,
  where,
  getDocs,
} from "firebase/firestore";
import LoadingSpinner from "../components/LoadingSpinner";
import PostedQuiz from "../components/PostedQuiz"; // Assuming you have this component
import ProfileUpdate from "../components/ProfileUpdate";
import SubscriptionPage from "../components/SubscriptionPage"; // Assuming you have this component
import { toast } from "react-toastify";
import UserAccuracy from "../components/UserAccuracy";
import Ranking from "../components/Ranking";
import PostVote from "../components/PostVote";
import PostedVote from "../components/PostedVote";
//import "./Dashboard.css";

function Dashboard() {
  const [user, setUser] = useState(null);
  const [userData, setUserData] = useState({});
  const [followers, setFollowers] = useState([]); // State for followers
  const [following, setFollowing] = useState([]); // Following state
  const [loading, setLoading] = useState(true);
  const [showUpdateProfile, setShowUpdateProfile] = useState(false);
  const [postedQuestions, setPostedQuestions] = useState([]);
  const [PostedVotes, setPostedVotes] = useState([]);
  const [isFollowersOpen, setIsFollowersOpen] = useState(false); // State for dropdown followers
  const [isFollowingOpen, setIsFollowingOpen] = useState(false); // State for dropdown following
  const userId = user?.uid; // Get user ID from the authenticated user
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      setLoading(true);
      if (currentUser) {
        setUser(currentUser);
        try {
          const userDoc = doc(fireDB, "users", currentUser.uid);
          const docSnap = await getDoc(userDoc);
          if (docSnap.exists()) {
            setUserData(docSnap.data());
            await fetchPostedQuestions(); // Fetch posted questions
            await fetchFollowers(); // Fetch followers
          } else {
            toast.error("User data not found");
          }
        } catch (error) {
          console.error("Error fetching data:", error);
          toast.error("Failed to load data. Please try again.");
        } finally {
          setLoading(false);
        }
      } else {
        navigate("/login");
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userRef = doc(fireDB, "users", userId);
        const userSnap = await getDoc(userRef);
        const currentUser = userSnap.data();

        // Fetch followers
        const followersIds = currentUser.followers || [];
        const followersPromises = followersIds.map(async (id) => {
          const followerRef = doc(fireDB, "users", id);
          const followerSnap = await getDoc(followerRef);
          return { id, ...followerSnap.data() }; // Include id with follower data
        });
        const followersList = await Promise.all(followersPromises);
        setFollowers(followersList);

        // Fetch following users
        const followingIds = currentUser.following || [];
        const followingPromises = followingIds.map(async (id) => {
          const followingRef = doc(fireDB, "users", id);
          const followingSnap = await getDoc(followingRef);
          return { id, ...followingSnap.data() }; // Include id with following data
        });
        const followingList = await Promise.all(followingPromises);
        setFollowing(followingList);
      } catch (error) {
        setError("Failed to fetch user data");
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, [userId]);

  const incrementProfileViews = async () => {
    if (!user) return;

    try {
      const userRef = doc(fireDB, "users", user.uid);
      await updateDoc(userRef, {
        profileViews: increment(1),
      });
    } catch (error) {
      console.error("Error incrementing profile views:", error);
    }
  };

  // Call this function when you display the profile
  useEffect(() => {
    incrementProfileViews();
  }, [user]);

  // Fetch posted questions using user.uid
  const fetchPostedQuestions = async () => {
    if (!user) return; // Ensure user is not null
    try {
      const userRef = doc(fireDB, "users", user.uid);
      const userDoc = await getDoc(userRef);

      if (!userDoc.exists()) {
        console.error("User document does not exist");
        return;
      }

      const postedQuestionsIds = userDoc.data().postedQuestions || [];

      // Update total posts count
      await updateDoc(userRef, { totalPosts: postedQuestionsIds.length });

      if (postedQuestionsIds.length > 0) {
        const questionsQuery = query(
          collection(fireDB, "quizQuestions"),
          where("id", "in", postedQuestionsIds)
        );
        const questionsSnapshot = await getDocs(questionsQuery);
        const questionsList = questionsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(), // Include likes, comments, shares, retweets
        }));
        setPostedQuestions(questionsList);
      }
    } catch (error) {
      console.error("Error fetching posted questions:", error);
      toast.error("Failed to fetch posted questions. Please try again.");
    }
  };

  // Fetch followers data

  const fetchFollowers = async () => {
    if (!user) return; // Ensure user is not null

    try {
      // Fetch the current user's document
      const userRef = doc(fireDB, "users", user.uid);
      const userDoc = await getDoc(userRef);

      if (!userDoc.exists()) {
        console.error("User document does not exist");
        return;
      }

      // Get the list of follower IDs
      const followersIds = userDoc.data().followers || [];
      console.log("Followers IDs:", followersIds); // Debugging log

      if (followersIds.length > 0) {
        // Query for users whose IDs are in the followersIds array
        const followersQuery = query(
          collection(fireDB, "users"),
          where("uid", "in", followersIds)
        );

        // Fetch the documents
        const followersSnapshot = await getDocs(followersQuery);
        console.log("Followers Snapshot:", followersSnapshot.docs); // Debugging log

        if (!followersSnapshot.empty) {
          const followersList = followersSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setFollowers(followersList);
        } else {
          console.log("No followers found in the database.");
          setFollowers([]);
        }
      } else {
        console.log("No followers IDs found.");
        setFollowers([]);
      }
    } catch (error) {
      console.error("Error fetching followers:", error);
      toast.error("Failed to fetch followers. Please try again.");
    }
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      toast.success("Logged out successfully");
      navigate("/login");
    } catch (error) {
      console.error("Error during logout:", error);
      toast.error("Failed to log out. Please try again.");
    }
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  if (!user || !userData) {
    return <div className="dashboard-container">Loading your dashboard...</div>;
  }

  return (
    <div className="dashboard-container">
      <header className="dashboard-header">
        <h1>Hello, {userData.name || "User"}</h1>
      </header>
      <br />

      <section className="dashboard-header">
        <h2>Current Performance</h2>
        <p>Profile Views: {userData.profileViews || 0}</p>

        <div>
          <Ranking />
        </div>

        <div>
          <UserAccuracy />
        </div>

        <div>
          <p onClick={() => setIsFollowersOpen(!isFollowersOpen)}>
            Followers ({followers.length})
          </p>
          {isFollowersOpen && (
            <ul className="followers-list">
              {followers.length > 0 ? (
                followers.map((follower) => (
                  <li key={follower.id} className="follower-item">
                    <p>{follower.name}</p>
                  </li>
                ))
              ) : (
                <p>No followers yet</p>
              )}
            </ul>
          )}
        </div>

        <div>
          <p onClick={() => setIsFollowingOpen(!isFollowingOpen)}>
            Following ({following.length})
          </p>
          {isFollowingOpen && (
            <ul className="following-list">
              {following.length > 0 ? (
                following.map((user) => (
                  <li key={user.id} className="following-item">
                    <p>{user.name}</p>
                  </li>
                ))
              ) : (
                <p>Not following anyone yet</p>
              )}
            </ul>
          )}
        </div>
      </section>
      <br />

      <section className="user-details-card">
        <h2>Your Dashboard</h2>

        <p>Description: {userData.description || "No description provided"}</p>
        <p>Role: {userData.role || "No role assigned"}</p>
        <p>Institution: {userData.institution || "No institution specified"}</p>
        <p>Country: {userData.country || "No country specified"}</p>
        <p>Email: {user.email}</p>
        {userData.time && (
          <p>Account created: {userData.time.toDate().toString()}</p>
        )}

        <button
          className="update-profile-button"
          onClick={() => setShowUpdateProfile(true)}
        >
          Update Profile
        </button>
      </section>
      <br />

      <section>
        {" "}
        <SubscriptionPage />
      </section>

      <br />
      <section className="posted-quizzes">
        <PostedQuiz postedQuestions={postedQuestions} />
      </section>

      <section>
        <PostedVote PostedVote={PostedVotes}/>
      </section>

      <button onClick={handleLogout} className="logout-button">
        Logout
      </button>

      {showUpdateProfile && (
        <ProfileUpdate
          userData={userData}
          onClose={() => setShowUpdateProfile(false)}
        />
      )}
    </div>
  );
}

export default Dashboard;
