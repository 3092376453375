const abundanceandmetaquestions = [
    {
        question:"The composition of cuprite is :",
        answerOptions: [
            {answer: 'CuFeS2', isCorrect: false},
            {answer: 'Cu2S', isCorrect: false},
            {answer: 'Cu2O', isCorrect: true},
            {answer: 'Cu(OH).CuCO3', isCorrect: false},
        ],
    }, {
        question:"Spelter is :",
        answerOptions: [
            {answer: 'pure-Zn', isCorrect: false},
            {answer: 'impure-Zn', isCorrect: true},
            {answer: 'Zn-Hg', isCorrect: false},
            {answer: 'ZnO', isCorrect: false},
        ],
    },  {
        question:"Which of the following is not concentrated by froth floatation process :",
        answerOptions: [
            {answer: 'Zine blende', isCorrect: false},
            {answer: 'Pyrolusute', isCorrect: true},
            {answer: 'Copperpyrite', isCorrect: false},
            {answer: 'Pentlandite', isCorrect: false},
        ],
    },  {
        question:"The process of removing matrix by fusing with flux is known as :",
        answerOptions: [
            {answer: 'Calcination', isCorrect: false},
            {answer: 'Roasting', isCorrect: false},
            {answer: 'Smelting', isCorrect: true},
            {answer: 'Sintering', isCorrect: false},
        ],
    },  {
        question:" Which of the following is obtained by alumino thermic reduction :",
        answerOptions: [
            {answer: 'Cr', isCorrect: true},
            {answer: 'Pb', isCorrect: false},
            {answer: 'Hg', isCorrect: false},
            {answer: 'Ag', isCorrect: false},
        ],
    },  {
        question:"The electrolytic reduction method is used to obtain  :",
        answerOptions: [
            {answer: 'transition metal', isCorrect: false},
            {answer: 'metalloid', isCorrect: false},
            {answer: 'electropositive metal', isCorrect: true},
            {answer: 'electronegatice metal', isCorrect: false},
           
        ],
    }, {
        question:"The metal which is obtained by carbon reduction process is :",
        answerOptions: [
            {answer: 'Zn', isCorrect: true},
            {answer: 'Mg', isCorrect: false},
            {answer: 'Al', isCorrect: false},
            {answer: 'Ca', isCorrect: false},
        ],
    },  {
        question:"Heating a mixture of Cu2 S and Cu2 O gives  :",
        answerOptions: [
            {answer: 'Cu + SO2', isCorrect: true},
            {answer: 'Cu + SO3', isCorrect: false},
            {answer: 'CuO + SO2', isCorrect: false},
            {answer: 'none of these', isCorrect: false},
        ],
    },  {
        question:"Which of the following alloy(s) contain(s) Ni :",
        answerOptions: [
            {answer: 'bell metal', isCorrect: false},
            {answer: 'monel metal', isCorrect: true},
            {answer: 'gun metal', isCorrect: false},
            {answer: 'none of these', isCorrect: false},
        ],
    },  {
        question:"The metal(s) which is obtained by C-reduction and self-reduction both :",
        answerOptions: [
            {answer: 'Pb', isCorrect: true},
            {answer: 'Zn', isCorrect: false},
            {answer: 'Sn', isCorrect: false},
            {answer: 'Fe', isCorrect: false},
        ],
    },  {
        question:"The anode mud in the electrolytic refining of Cu contains  :",
        answerOptions: [
            {answer: 'Mg', isCorrect: false},
            {answer: 'Sn', isCorrect: false},
            {answer: 'Zn', isCorrect: false},
            {answer: 'Ag', isCorrect: true},
        ],
    },  {
        question:"Which of the following ore does not contain iron :",
        answerOptions: [
            {answer: 'limonite', isCorrect: false},
            {answer: 'magnesite', isCorrect: true},
            {answer: 'magnetite', isCorrect: false},
            {answer: 'siderite', isCorrect: false},
        ],
    },  {
        question:" Which of the following has lowest percentage of carbon :",
        answerOptions: [
            {answer: 'cast iron', isCorrect: false},
            {answer: 'steel', isCorrect: false},
            {answer: 'wrought iron', isCorrect: true},
            {answer: 'none of these', isCorrect: false},
        ],
    },  {
        question:"Lead from low grade ore is extracted by  :",
        answerOptions: [
            {answer: 'C-reduction', isCorrect: false},
            {answer: 'self-reduction', isCorrect: false},
            {answer: 'electrolytic reduction', isCorrect: false},
            {answer: 'metal reduction', isCorrect: true},
        ],
    },  {
        question:"Which of the following alloy(s) contain Cu :",
        answerOptions: [
            {answer: 'duralumine', isCorrect: true},
            {answer: 'solder', isCorrect: false},
            {answer: 'magnelium', isCorrect: false},
            {answer: 'none of these', isCorrect: false},
        ],
    },  {
        question:"Iron is not extracted from which of the following:",
        answerOptions: [
            {answer: 'FeS2', isCorrect: true},
            {answer: 'Fe2O3', isCorrect: false},
            {answer: 'FeCO3', isCorrect: false},
            {answer: 'FeO(OH)', isCorrect: false},
        ],
    },  {
        question:"For removal of iron from roasted ore of Cu the flux used is :",
        answerOptions: [
            {answer: 'CO2', isCorrect: false},
            {answer: 'SiO2', isCorrect: true},
            {answer: 'SO2', isCorrect: false},
            {answer: 'NO3', isCorrect: false},
        ],
    },  {
        question:"Which of the following ore(s) is concentrated by froth floatation process :",
        answerOptions: [
            {answer: 'Magnetite', isCorrect: false},
            {answer: 'Maachite', isCorrect: false},
            {answer: 'Sphalerite', isCorrect: true},
            {answer: 'none of these', isCorrect: false},
        ],
    },  {
        question:" Which of the following metal is obtained by electrolytic reduction :",
        answerOptions: [
            {answer: 'Fe', isCorrect: false},
            {answer: 'Pb', isCorrect: false},
            {answer: 'Sn', isCorrect: false},
            {answer: 'Al', isCorrect: true },
        ],
    },  {
        question:"Electrolysis of brine (Saturated solution of NaCl) produces :",
        answerOptions: [
            {answer: 'H2', isCorrect: false},
            {answer: 'NaoH', isCorrect: false},
            {answer: 'O2', isCorrect: false},
            {answer: 'all of these', isCorrect: true},
        ],
    },  
]

export default abundanceandmetaquestions;