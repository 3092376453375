
import React from "react";
import '../../../quizzesStyle/physicsanswer.css';

import electrostaticsquestions from "../physicsData/ElectrostaticsData";


function ElectrostaticAnswer(props) {
  const { Score, CorrectAnswer, answers, totalTimeTaken, handlePlayAgain, attempts } = props;

  // Calculate the percentage of correct answers
  const accuracy = ((CorrectAnswer / answers.length) * 100).toFixed(2);

  // Identify the user's strongest and weakest areas
  const topicPerformance = {};
  answers.forEach((ans, index) => {
    const topic = electrostaticsquestions[index].topic; // Assuming each question has a 'topic' field
    if (!topicPerformance[topic]) {
      topicPerformance[topic] = { correct: 0, total: 0 };
    }
    topicPerformance[topic].total += 1;
    if (ans.isCorrect) {
      topicPerformance[topic].correct += 1;
    }
  });

  const strongestTopic = Object.keys(topicPerformance).reduce((a, b) =>
    topicPerformance[a].correct / topicPerformance[a].total >
    topicPerformance[b].correct / topicPerformance[b].total
      ? a
      : b
  );

  const weakestTopic = Object.keys(topicPerformance).reduce((a, b) =>
    topicPerformance[a].correct / topicPerformance[a].total <
    topicPerformance[b].correct / topicPerformance[b].total
      ? a
      : b
  );

  return (
    <div className="quiz-result">
      <h2>Quiz Completed!</h2>
      <div className="result-summary">
        <h3>Score: {Score}</h3>
        <p>
          Correct Answers: {CorrectAnswer} out of {answers.length} (
          {accuracy}% accuracy)
        </p>
        <p>Total Time Taken: {totalTimeTaken} seconds</p>
      </div>

      <div className="performance-insights">
        <h4>Performance Insights</h4>
        <p>
          <strong>Strongest Topic:</strong> {strongestTopic}
        </p>
        <p>
          <strong>Weakest Topic:</strong> {weakestTopic}
        </p>
      </div>

      <div className="attempt-history">
        <h4>Your Past Attempts</h4>
        {attempts.length > 0 ? (
          <ul>
            {attempts.map((attempt, index) => (
              <li key={index}>
                Attempt {index + 1}: Scored {attempt.score} in{" "}
                {attempt.totalTime} seconds
              </li>
            ))}
          </ul>
        ) : (
          <p>No previous attempts recorded.</p>
        )}
      </div>

      <button className="play-again-button" onClick={handlePlayAgain}>
        Play Again
      </button>
    </div>
  );
}


export default ElectrostaticAnswer;
