import React, { useState } from 'react';
import { auth } from '../config/firebase'; // Firebase auth
import { signInWithEmailAndPassword } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore'; // Firestore imports
import { fireDB } from '../config/firebase'; // Ensure fireDB is correctly imported
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import '../authStyle/signup.css';

function AdminLogin() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const login = async () => {
        try {
            setLoading(true);
            // Login with email and password
            const result = await signInWithEmailAndPassword(auth, email, password);
            const user = result.user;
            
            // Check if the user has the 'admin' role
            const userRef = doc(fireDB, 'users', user.uid); // Access the correct Firestore document
            const userDoc = await getDoc(userRef); // Fetch the user document
            
            if (userDoc.exists() && userDoc.data().role === 'admin') {
                // If the user is an admin, redirect to the admin dashboard
                toast.success("Admin Login successful", {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                navigate('/admin-dashboard');
            } else {
                toast.error("You are not authorized to access the admin panel.", {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                setLoading(false);
            }
        } catch (error) {
            toast.error(`Login failed: ${error.message}`, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            setLoading(false);
        }
    };

    return (
        <div className="flex justify-center items-center h-screen">
            <div className="bg-gray-800 px-10 py-10 rounded-xl">
                <h1 className="text-center text-white text-xl mb-4 font-bold">Admin Login</h1>
                <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="bg-gray-600 mb-4 px-2 py-2 w-full lg:w-[20em] rounded-lg text-white placeholder:text-gray-200 outline-none"
                    placeholder="Admin Email"
                />
                <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="bg-gray-600 mb-4 px-2 py-2 w-full lg:w-[20em] rounded-lg text-white placeholder:text-gray-200 outline-none"
                    placeholder="Admin Password"
                />
                
                <button
                    onClick={login}
                    className="bg-yellow-500 w-full text-black font-bold px-2 py-2 rounded-lg"
                    disabled={loading}
                >
                    {loading ? "Logging in..." : "Login"}
                </button>
                <p className="text-white mt-4 text-center">
                    Not an admin?{" "}
                    <span
                        className="text-yellow-500 cursor-pointer"
                        onClick={() => navigate('/login')}
                    >
                        Login as User
                    </span>
                </p>
            </div>
        </div>
    );
}

export default AdminLogin;
