const opticsquestions = [
  {
    question:
      " What is angle of incidence for an equilaterial prism of refractive index √3 so that the ray is parallel to the base inside the prism :",
    answerOptions: [
      { answer: "45°", isCorrect: false },
      { answer: "60°", isCorrect: true },
      { answer: "30°", isCorrect: false },
      { answer: "either 30 or 60°", isCorrect: false },
    ],
  },
  {
    question:
      " Light from a denser medium 1 passes to a rarer medium 2. When the angle of incident is x the partially reflected and refracted rays are mutually prependicular. The critical angle will be :",
    answerOptions: [
      { answer: "sin^-1(cot x)", isCorrect: false },
      { answer: "sin^-1(tan x)", isCorrect: true },
      { answer: "sin^-1(cos x)", isCorrect: false },
      { answer: "sin^-1(sec x)", isCorrect: false },
    ],
  },
  {
    question:
      "Critical angle of a glass is x1 and that of water is x2. The critical angle for water and glass surface would be :",
    answerOptions: [
      { answer: "less than x2", isCorrect: false },
      { answer: "between x1 and x2", isCorrect: false },
      { answer: "greater than x2", isCorrect: true },
      { answer: "less than x1", isCorrect: false },
    ],
  },
  {
    question:
      "In YDSE, D = 1 m and d = 1 mm, lamda= 5000 nm. The distance of 100th maxima from the central maxima is :",
    answerOptions: [
      { answer: "1/2 m", isCorrect: false },
      { answer: "3/2 m", isCorrect: false },
      { answer: "1/√3", isCorrect: true },
      { answer: "does not exist", isCorrect: false },
    ],
  },
  {
    question:
      " The refractive index of a prism is 2. The prism can have a maximum refracting angle of :",
    answerOptions: [
      { answer: "90°", isCorrect: false },
      { answer: "60°", isCorrect: true },
      { answer: "45°", isCorrect: false },
      { answer: "30°", isCorrect: false },
    ],
  },
  {
    question:
      "A ray of light is incident on a glass sphere of refractive index 3/2. What should be the angle of incident so that the ray which enter the sphere does not come out of the sphere  :",
    answerOptions: [
      { answer: "30°", isCorrect: false },
      { answer: "90°", isCorrect: false },
      { answer: "60°", isCorrect: true },
      { answer: "tan^-1(2/3)", isCorrect: false },
    ],
  },
  {
    question:
      "In a YDSE with identical slits, the intensity of the central bright fringe is I. If one of slits is covered, the intensity at the same point is :",
    answerOptions: [
      { answer: "2I", isCorrect: false },
      { answer: "I", isCorrect: false },
      { answer: "I/2", isCorrect: false },
      { answer: "I/4", isCorrect: true },
    ],
  },
  {
    question:
      "In Young's double-slit interference experiment. if the slit seperation is made threefold, the fringe width becomes:",
    answerOptions: [
      { answer: "sixfold", isCorrect: false },
      { answer: "threefold", isCorrect: false },
      { answer: "3/6-fold", isCorrect: false },
      { answer: "1/3-fold", isCorrect: true },
    ],
  },
  {
    question:
      "Angle of minimum deviation is equal to the angle of prism A of an equilateral glass prism. The angle of incidence at which minimum deviation will be obtained :",
    answerOptions: [
      { answer: "60°", isCorrect: true },
      { answer: "90°", isCorrect: false },
      { answer: "90°", isCorrect: false },
      { answer: "sin^-1(2/3)", isCorrect: false },
    ],
  },
  {
    question:
      "An object is put at a distance of 5 cm from the first focus of a convex lens of focal length 10 cm. If a real image is formed its distance from the lens will be :",
    answerOptions: [
      { answer: "15 cm", isCorrect: false },
      { answer: "20 cm", isCorrect: false },
      { answer: "25 cm", isCorrect: false },
      { answer: "30 cm", isCorrect: true },
    ],
  },
  {
    question:
      "A spherical mirror formed an image of maginification 3. The object distance, if focal length of mirror is 24 cm, may be :",
    answerOptions: [
      { answer: "32 cm, 24 cm", isCorrect: false },
      { answer: "32 cm, 16 cm", isCorrect: true },
      { answer: "32 cm only", isCorrect: false },
      { answer: "16 cm only", isCorrect: false },
    ],
  },
  {
    question:
      "Light waves travel in vaccum along the y-axis. Which of the following represent the wavefront :",
    answerOptions: [
      { answer: "x = constant", isCorrect: false },
      { answer: "y = constant", isCorrect: true },
      { answer: "z = constant", isCorrect: false },
      { answer: "x+y+z = constant", isCorrect: false },
    ],
  },
  {
    question:
      " In a double slit experiment, the distance between the slits is d. The screen is at a distance D from the slits. If a bright frings is formed opposite to a slit on the screen, the order of the fringe is: ",
    answerOptions: [
      { answer: "d²/2 lamda D", isCorrect: true },
      { answer: "d/2 lamda D", isCorrect: false },
      { answer: "d²/4 lamda D", isCorrect: false },
      { answer: "0", isCorrect: false },
    ],
  },
  {
    question:
      "The refractive angle of a prism is A and refractive index of the material of prism is cot(A/2). The angle of minimum deviation will be : ",
    answerOptions: [
      { answer: "180° - 3A", isCorrect: false },
      { answer: "180° + 3A", isCorrect: false },
      { answer: "90° - 3A", isCorrect: false },
      { answer: "180° - 2A", isCorrect: true },
    ],
  },
  {
    question:
      "A lens froms a diminished and eract image of an object. The magnification is 1/4. Find ratio of distance between object an d focus and focus and image :",
    answerOptions: [
      { answer: "4 : 1", isCorrect: false },
      { answer: "1 : 4", isCorrect: false },
      { answer: "8 : 1", isCorrect: true },
      { answer: "2 : 1", isCorrect: false },
    ],
  },
  {
    question:
      "A fish looks upward at an unobstructed overcase sky. What total angle does the sky appear to subtend :",
    answerOptions: [
      { answer: "180°", isCorrect: false },
      { answer: "90°", isCorrect: false },
      { answer: "75°", isCorrect: true },
      { answer: "60°", isCorrect: false },
    ],
  },
  {
    question:
      "An object is approaching a fixed plane mirror with velocity 5 m/s making an angle 45° with the normal. The speed of image w.r.t the mirror is :",
    answerOptions: [
      { answer: "5 m/s", isCorrect: true },
      { answer: " 5/2 m/s", isCorrect: false },
      { answer: "0", isCorrect: false },
      { answer: "1 m/s", isCorrect: false },
    ],
  },
  {
    question:
      "The cricita; angle for light going from vaccum X into medium Y is x. The speed of light in medium X is v. The speed of light in medium Y is :",
    answerOptions: [
      { answer: "v cosx", isCorrect: false },
      { answer: "v/cosx", isCorrect: false },
      { answer: "v sinx", isCorrect: false },
      { answer: "v/sinx", isCorrect: true },
    ],
  },
  {
    question: " :",
    answerOptions: [
      { answer: "0.001 J", isCorrect: false },
      { answer: "0.01 J", isCorrect: true },
      { answer: "0.1 J", isCorrect: false },
      { answer: "1 J", isCorrect: false },
    ],
  },
  {
    question:
      "When a ray of light passes through prism, it splites up into rays of constituent :",
    answerOptions: [
      { answer: "forms", isCorrect: false },
      { answer: "colors", isCorrect: true },
      { answer: "rays", isCorrect: false },
      { answer: "particles", isCorrect: false },
    ],
  },
  {
    question:
      "It is done in Young's double-slit experiment, Fresnel's biprism, Lloyd's mirror, etc. :",
    answerOptions: [
      { answer: "Division of Amplitude", isCorrect: false },
      { answer: "Division of interference", isCorrect: false },
      { answer: "Division of Wavefront", isCorrect: true },
      { answer: "Division of light", isCorrect: false },
    ],
  },
  {
    question:
      "An angle formed when incident ray turns in passing through prism :",
    answerOptions: [
      { answer: "i + e - A", isCorrect: true },
      { answer: "i + e + A", isCorrect: false },
      { answer: "i - e + A", isCorrect: false },
      { answer: "i + e + A", isCorrect: false },
    ],
  },
  {
    question: "Which of the following obay laws of conservation of energy :",
    answerOptions: [
      { answer: "differaction", isCorrect: true },
      { answer: "huygen principel", isCorrect: false },
      { answer: "interference", isCorrect: true },
      { answer: "None of these", isCorrect: false },
    ],
  },
  {
    question:
      "A wave produce of same frequency with a constant phase difference is called:",
    answerOptions: [
      { answer: "coherent", isCorrect: true },
      { answer: "wavefront", isCorrect: false },
      { answer: "interference", isCorrect: false },
      { answer: "differaction", isCorrect: false },
    ],
  },
  {
    question:
      "One of the refacting surfaces of a prism of angle 30° is slivered. A ray of light incident at angle of 60° retraces its path. The refractive index of the material of prism is :",
    answerOptions: [
      { answer: "√2)", isCorrect: false },
      { answer: "√3)", isCorrect: true },
      { answer: "3/2", isCorrect: false },
      { answer: "2", isCorrect: false },
    ],
  },
  {
    question:
      "If the medium on two sides of lens are not same, then the ratio of two focal length is ",
    answerOptions: [
      { answer: "f1/f2", isCorrect: true },
      { answer: "f2/f1", isCorrect: false },
      { answer: "2f2/f1", isCorrect: false },
      { answer: "can not be said", isCorrect: false },
    ],
  },
  {
    question:
      " In a slide show event, the image on the screen has an area 900 times that of the slide. IF the distance between slide an screen is x times the distance between the slide and the projector lens, then :",
    answerOptions: [
      { answer: "x = 30", isCorrect: false },
      { answer: "x = 31", isCorrect: true },
      { answer: "x = 500", isCorrect: false },
      { answer: "x = 1/30", isCorrect: false },
    ],
  },
  {
    question:
      "In a Young's double-slit experiment, λ = 500nm, d = 1nm, and D = 1m. The minimum distance from the central maximum for which the intensity is half of the maximum intensity is :",
    answerOptions: [
      { answer: "2 x 10^-4 m", isCorrect: false },
      { answer: "1.25 x 10^-4 m", isCorrect: true },
      { answer: "4 x 10^-4 m", isCorrect: false },
      { answer: "2.5 x 10^-4 m", isCorrect: false },
    ],
  },
  {
    question:
      " Refractive index of prism is √7/3) and the angle of prism is 60°. The minimum angle of incident of a ray that will be transmitted through the prism is :",
    answerOptions: [
      { answer: "30°", isCorrect: true },
      { answer: "45°", isCorrect: false },
      { answer: "50°", isCorrect: false },
      { answer: "15°", isCorrect: false },
    ],
  },
  {
    question:
      "For a prism kept in air, it is found that for an angle of incident 60°, the angle of refraction A, angle of deviation x and angle of emergence e become equal. Then, the refractive index of the prism is  :",
    answerOptions: [
      { answer: "1.73", isCorrect: true },
      { answer: "1.15", isCorrect: false },
      { answer: "1.5", isCorrect: false },
      { answer: "1.33", isCorrect: false },
    ],
  },
  {
    question:
      "A light ray passes from air into glass. If the angle of incidence is 30°, what is the angle of refraction given that the refractive index of glass is 1.5?",
    answerOptions: [
      { answer: "19.47°", isCorrect: true },
      { answer: "22.07°", isCorrect: false },
      { answer: "25.48°", isCorrect: false },
      { answer: "28.13°", isCorrect: false },
    ],
  },
  {
    question:
      "A concave mirror has a focal length of 20 cm. If an object is placed 40 cm in front of the mirror, where will the image be formed?",
    answerOptions: [
      { answer: "40 cm in front of the mirror", isCorrect: true },
      { answer: "20 cm behind the mirror", isCorrect: false },
      { answer: "60 cm in front of the mirror", isCorrect: false },
      { answer: "10 cm behind the mirror", isCorrect: false },
    ],
  },
  {
    question: "Which phenomenon explains the formation of a rainbow?",
    answerOptions: [
      { answer: "Dispersion of light", isCorrect: true },
      { answer: "Interference of light", isCorrect: false },
      { answer: "Polarization of light", isCorrect: false },
      { answer: "Scattering of light", isCorrect: false },
    ],
  },
  {
    question:
      "What is the focal length of a convex lens with a power of +2.5 diopters?",
    answerOptions: [
      { answer: "0.4 meters", isCorrect: true },
      { answer: "2.5 meters", isCorrect: false },
      { answer: "0.25 meters", isCorrect: false },
      { answer: "4.0 meters", isCorrect: false },
    ],
  },
  {
    question:
      "In Young's double-slit experiment, if the slit separation is 0.2 mm and the wavelength of light used is 600 nm, what is the fringe spacing on a screen 2 meters away?",
    answerOptions: [
      { answer: "6 mm", isCorrect: true },
      { answer: "3 mm", isCorrect: false },
      { answer: "9 mm", isCorrect: false },
      { answer: "12 mm", isCorrect: false },
    ],
  },
  {
    question: "A plane mirror forms an image that is:",
    answerOptions: [
      {
        answer: "Virtual, upright, and the same size as the object",
        isCorrect: true,
      },
      {
        answer: "Real, inverted, and smaller than the object",
        isCorrect: false,
      },
      { answer: "Virtual, inverted, and magnified", isCorrect: false },
      {
        answer: "Real, upright, and the same size as the object",
        isCorrect: false,
      },
    ],
  },
  {
    question: "What causes the sky to appear blue?",
    answerOptions: [
      { answer: "Rayleigh scattering", isCorrect: true },
      { answer: "Reflection of sunlight", isCorrect: false },
      { answer: "Absorption of light by the atmosphere", isCorrect: false },
      { answer: "Dispersion of light", isCorrect: false },
    ],
  },
  {
    question:
      "If light travels from water (n=1.33) into air (n=1.00), which of the following will occur?",
    answerOptions: [
      { answer: "The light will bend away from the normal", isCorrect: true },
      { answer: "The light will bend towards the normal", isCorrect: false },
      {
        answer: "The light will continue in the same direction",
        isCorrect: false,
      },
      { answer: "The light will be completely reflected", isCorrect: false },
    ],
  },
  {
    question: "Which type of lens is used to correct myopia (nearsightedness)?",
    answerOptions: [
      { answer: "Concave lens", isCorrect: true },
      { answer: "Convex lens", isCorrect: false },
      { answer: "Bifocal lens", isCorrect: false },
      { answer: "Cylindrical lens", isCorrect: false },
    ],
  },
  {
    question:
      "Which phenomenon is responsible for the separation of white light into its constituent colors by a prism?",
    answerOptions: [
      { answer: "Dispersion", isCorrect: true },
      { answer: "Reflection", isCorrect: false },
      { answer: "Diffraction", isCorrect: false },
      { answer: "Polarization", isCorrect: false },
    ],
  },
  {
    question:
      "What happens to the frequency of light as it moves from air into water?",
    answerOptions: [
      { answer: "It remains the same", isCorrect: true },
      { answer: "It increases", isCorrect: false },
      { answer: "It decreases", isCorrect: false },
      { answer: "It fluctuates", isCorrect: false },
    ],
  },
  {
    question:
      "The critical angle for light traveling from glass (n=1.5) to air (n=1.0) is closest to:",
    answerOptions: [
      { answer: "41.8°", isCorrect: true },
      { answer: "48.6°", isCorrect: false },
      { answer: "53.1°", isCorrect: false },
      { answer: "61.3°", isCorrect: false },
    ],
  },
  {
    question:
      "What is the image distance for an object placed 15 cm from a concave mirror with a focal length of 10 cm?",
    answerOptions: [
      { answer: "30 cm in front of the mirror", isCorrect: true },
      { answer: "20 cm behind the mirror", isCorrect: false },
      { answer: "15 cm in front of the mirror", isCorrect: false },
      { answer: "10 cm behind the mirror", isCorrect: false },
    ],
  },
  {
    question: "Which of the following is true about total internal reflection?",
    answerOptions: [
      {
        answer:
          "It occurs when light passes from a medium with a higher refractive index to one with a lower refractive index",
        isCorrect: true,
      },
      {
        answer:
          "It occurs when light passes from a medium with a lower refractive index to one with a higher refractive index",
        isCorrect: false,
      },
      { answer: "It can occur at any angle of incidence", isCorrect: false },
      {
        answer: "It is responsible for the bending of light",
        isCorrect: false,
      },
    ],
  },
  {
    question:
      "A fiber optic cable uses which principle to transmit light over long distances?",
    answerOptions: [
      { answer: "Total internal reflection", isCorrect: true },
      { answer: "Diffraction", isCorrect: false },
      { answer: "Dispersion", isCorrect: false },
      { answer: "Interference", isCorrect: false },
    ],
  },
  {
    question:
      "A person standing in front of a convex mirror sees a smaller, upright image of themselves. Which of the following is true?",
    answerOptions: [
      {
        answer: "The image is virtual and located behind the mirror",
        isCorrect: true,
      },
      {
        answer: "The image is real and located in front of the mirror",
        isCorrect: false,
      },
      {
        answer: "The image is real and located behind the mirror",
        isCorrect: false,
      },
      {
        answer: "The image is virtual and located in front of the mirror",
        isCorrect: false,
      },
    ],
  },
  {
    question:
      "Which device uses lenses to converge or diverge light to form images?",
    answerOptions: [
      { answer: "Microscope", isCorrect: true },
      { answer: "Concave mirror", isCorrect: false },
      { answer: "Optical fiber", isCorrect: false },
      { answer: "Spectrometer", isCorrect: false },
    ],
  },
  {
    question: "Which type of lens is thicker at the center than at the edges?",
    answerOptions: [
      { answer: "Convex lens", isCorrect: true },
      { answer: "Concave lens", isCorrect: false },
      { answer: "Cylindrical lens", isCorrect: false },
      { answer: "Bifocal lens", isCorrect: false },
    ],
  },
  {
    question:
      "A beam of light passes through two polarizing filters. If the filters are oriented at 90° to each other, what happens to the intensity of light?",
    answerOptions: [
      { answer: "The light intensity is reduced to zero", isCorrect: true },
      { answer: "The light intensity is reduced by half", isCorrect: false },
      { answer: "The light intensity is doubled", isCorrect: false },
      { answer: "The light intensity remains unchanged", isCorrect: false },
    ],
  },
  {
    question: "What is the primary cause of the twinkling of stars?",
    answerOptions: [
      { answer: "Atmospheric refraction", isCorrect: true },
      { answer: "Light scattering", isCorrect: false },
      { answer: "Dispersion of light", isCorrect: false },
      { answer: "Polarization of light", isCorrect: false },
    ],
  },
  {
    question: "Which of the following best describes the nature of light?",
    answerOptions: [
      {
        answer: "Light exhibits both wave-like and particle-like properties",
        isCorrect: true,
      },
      { answer: "Light is purely a wave", isCorrect: false },
      { answer: "Light is purely a particle", isCorrect: false },
      { answer: "Light has no specific nature", isCorrect: false },
    ],
  },
  {
    question:
      "The apparent depth of an object submerged in water appears less than its real depth due to which phenomenon?",
    answerOptions: [
      { answer: "Refraction", isCorrect: true },
      { answer: "Reflection", isCorrect: false },
      { answer: "Diffraction", isCorrect: false },
      { answer: "Dispersion", isCorrect: false },
    ],
  },
  {
    question:
      "A light ray strikes a plane mirror at an angle of 45°. What is the angle of reflection?",
    answerOptions: [
      { answer: "45°", isCorrect: true },
      { answer: "30°", isCorrect: false },
      { answer: "60°", isCorrect: false },
      { answer: "90°", isCorrect: false },
    ],
  },
  {
    question: "What type of image is formed by a concave lens?",
    answerOptions: [
      {
        answer: "Virtual, upright, and smaller than the object",
        isCorrect: true,
      },
      {
        answer: "Real, inverted, and larger than the object",
        isCorrect: false,
      },
      {
        answer: "Virtual, upright, and the same size as the object",
        isCorrect: false,
      },
      {
        answer: "Real, upright, and smaller than the object",
        isCorrect: false,
      },
    ],
  },
  {
    question:
      "In a double-slit experiment, how does the fringe spacing change if the wavelength of light is increased?",
    answerOptions: [
      { answer: "Fringe spacing increases", isCorrect: true },
      { answer: "Fringe spacing decreases", isCorrect: false },
      { answer: "Fringe spacing remains the same", isCorrect: false },
      { answer: "Fringe spacing disappears", isCorrect: false },
    ],
  },
  {
    question:
      "If white light passes through a diffraction grating, what is observed on a screen?",
    answerOptions: [
      { answer: "A spectrum of colors", isCorrect: true },
      { answer: "A single color band", isCorrect: false },
      { answer: "Uniform white light", isCorrect: false },
      { answer: "Complete darkness", isCorrect: false },
    ],
  },
  {
    question:
      "Which phenomenon allows holograms to be created by recording the light wavefronts reflected from an object?",
    answerOptions: [
      { answer: "Interference", isCorrect: true },
      { answer: "Refraction", isCorrect: false },
      { answer: "Dispersion", isCorrect: false },
      { answer: "Polarization", isCorrect: false },
    ],
  },
  {
    question:
      "What happens to the wavelength of light as it enters a medium with a higher refractive index?",
    answerOptions: [
      { answer: "The wavelength decreases", isCorrect: true },
      { answer: "The wavelength increases", isCorrect: false },
      { answer: "The wavelength remains the same", isCorrect: false },
      { answer: "The wavelength fluctuates", isCorrect: false },
    ],
  },
  {
    question:
      "Which of the following is an application of total internal reflection?",
    answerOptions: [
      { answer: "Optical fibers", isCorrect: true },
      { answer: "Magnifying glasses", isCorrect: false },
      { answer: "Telescope lenses", isCorrect: false },
      { answer: "Prism dispersers", isCorrect: false },
    ],
  },
  {
    question:
      "A convex lens can be used as a magnifying glass. What type of image does it produce in this scenario?",
    answerOptions: [
      {
        answer: "Virtual, upright, and larger than the object",
        isCorrect: true,
      },
      {
        answer: "Real, inverted, and smaller than the object",
        isCorrect: false,
      },
      {
        answer: "Virtual, upright, and the same size as the object",
        isCorrect: false,
      },
      { answer: "Real, upright, and larger than the object", isCorrect: false },
    ],
  },
  {
    question: "What is the significance of the Pauli exclusion principle?",
    answerOptions: [
      {
        answer:
          "No two fermions can occupy the same quantum state simultaneously.",
        isCorrect: true,
      },
      {
        answer:
          "No two bosons can occupy the same quantum state simultaneously.",
        isCorrect: false,
      },
      {
        answer: "Fermions and bosons cannot exist in the same quantum state.",
        isCorrect: false,
      },
      {
        answer: "Two fermions must occupy the same quantum state.",
        isCorrect: false,
      },
    ],
  },
  {
    question:
      "What is the energy of a photon with a wavelength of 400 nm? (Planck's constant h = 6.626 × 10⁻³⁴ Js, speed of light c = 3 × 10⁸ m/s)",
    answerOptions: [
      { answer: "4.97 × 10⁻¹⁹ J", isCorrect: true },
      { answer: "5.44 × 10⁻¹⁹ J", isCorrect: false },
      { answer: "3.32 × 10⁻¹⁹ J", isCorrect: false },
      { answer: "6.22 × 10⁻¹⁹ J", isCorrect: false },
    ],
  },
  {
    question:
      "Which particle is responsible for mediating the strong nuclear force?",
    answerOptions: [
      { answer: "Gluon", isCorrect: true },
      { answer: "Photon", isCorrect: false },
      { answer: "W boson", isCorrect: false },
      { answer: "Z boson", isCorrect: false },
    ],
  },
  {
    question: "What phenomenon does the term 'quantum tunneling' describe?",
    answerOptions: [
      {
        answer:
          "A particle passes through a potential barrier that it classically should not be able to.",
        isCorrect: true,
      },
      {
        answer:
          "A particle disappears from one location and reappears in another.",
        isCorrect: false,
      },
      {
        answer: "A particle splits into two and then recombines.",
        isCorrect: false,
      },
      { answer: "A particle decays into other particles.", isCorrect: false },
    ],
  },
  {
    question: "What is the typical energy scale for nuclear reactions?",
    answerOptions: [
      { answer: "MeV (Mega electron volts)", isCorrect: true },
      { answer: "eV (electron volts)", isCorrect: false },
      { answer: "keV (Kilo electron volts)", isCorrect: false },
      { answer: "GeV (Giga electron volts)", isCorrect: false },
    ],
  },
  {
    question: "What does the term 'quark confinement' refer to?",
    answerOptions: [
      {
        answer:
          "Quarks are never found in isolation; they are always confined within hadrons.",
        isCorrect: true,
      },
      {
        answer:
          "Quarks can exist independently but are confined by electromagnetic forces.",
        isCorrect: false,
      },
      {
        answer: "Quarks can escape a nucleus but are trapped within atoms.",
        isCorrect: false,
      },
      {
        answer: "Quarks are only confined within protons and neutrons.",
        isCorrect: false,
      },
    ],
  },
  {
    question:
      "Which of the following is a consequence of time dilation in special relativity?",
    answerOptions: [
      {
        answer: "Moving clocks tick slower compared to stationary clocks.",
        isCorrect: true,
      },
      {
        answer: "Moving clocks tick faster compared to stationary clocks.",
        isCorrect: false,
      },
      {
        answer: "Moving clocks remain synchronized with stationary clocks.",
        isCorrect: false,
      },
      {
        answer: "Moving clocks tick at the same rate as stationary clocks.",
        isCorrect: false,
      },
    ],
  },
  {
    question:
      "Which equation is used to describe the relationship between energy and momentum for a photon?",
    answerOptions: [
      { answer: "E = pc", isCorrect: true },
      { answer: "E = mc²", isCorrect: false },
      { answer: "E = mv²", isCorrect: false },
      { answer: "E = hf", isCorrect: false },
    ],
  },
  {
    question:
      "What is the threshold energy required for a photon to produce an electron-positron pair?",
    answerOptions: [
      { answer: "1.022 MeV", isCorrect: true },
      { answer: "0.511 MeV", isCorrect: false },
      { answer: "2.044 MeV", isCorrect: false },
      { answer: "1.536 MeV", isCorrect: false },
    ],
  },
  {
    question: "What does the term 'neutrino oscillation' refer to?",
    answerOptions: [
      {
        answer:
          "The phenomenon where a neutrino changes its flavor as it propagates.",
        isCorrect: true,
      },
      {
        answer: "The oscillation of a neutrinos spin as it moves.",
        isCorrect: false,
      },
      {
        answer: "The oscillation of a neutrinos mass as it moves.",
        isCorrect: false,
      },
      {
        answer: "The oscillation of a neutrinos charge as it moves.",
        isCorrect: false,
      },
    ],
  },
  {
    question:
      "Which principle in quantum mechanics explains the inability to simultaneously know both the position and momentum of a particle with absolute certainty?",
    answerOptions: [
      { answer: "Heisenberg uncertainty principle", isCorrect: true },
      { answer: "Pauli exclusion principle", isCorrect: false },
      { answer: "Schrödingers cat principle", isCorrect: false },
      { answer: "Bohrs complementarity principle", isCorrect: false },
    ],
  },
  {
    question:
      "What is the primary significance of the Dirac equation in quantum mechanics?",
    answerOptions: [
      { answer: "It predicts the existence of antimatter.", isCorrect: true },
      {
        answer: "It describes the behavior of electrons in an atom.",
        isCorrect: false,
      },
      { answer: "It explains the photoelectric effect.", isCorrect: false },
      {
        answer: "It describes the wavefunction of a particle.",
        isCorrect: false,
      },
    ],
  },
  {
    question:
      "What is the significance of the cosmic microwave background radiation?",
    answerOptions: [
      {
        answer: "It provides evidence for the Big Bang theory.",
        isCorrect: true,
      },
      { answer: "It is the source of dark energy.", isCorrect: false },
      {
        answer: "It is the radiation emitted by black holes.",
        isCorrect: false,
      },
      {
        answer: "It is the primary source of solar radiation.",
        isCorrect: false,
      },
    ],
  },
  {
    question:
      "What is the phenomenon where particles can exist in multiple states simultaneously called?",
    answerOptions: [
      { answer: "Quantum superposition", isCorrect: true },
      { answer: "Quantum entanglement", isCorrect: false },
      { answer: "Quantum tunneling", isCorrect: false },
      { answer: "Quantum decoherence", isCorrect: false },
    ],
  },
  {
    question:
      "Which fundamental force is responsible for holding the nucleus of an atom together?",
    answerOptions: [
      { answer: "Strong nuclear force", isCorrect: true },
      { answer: "Electromagnetic force", isCorrect: false },
      { answer: "Weak nuclear force", isCorrect: false },
      { answer: "Gravitational force", isCorrect: false },
    ],
  },
  {
    question: "Which effect demonstrates the particle nature of light?",
    answerOptions: [
      { answer: "Photoelectric effect", isCorrect: true },
      { answer: "Double-slit experiment", isCorrect: false },
      { answer: "Compton effect", isCorrect: false },
      { answer: "Diffraction of light", isCorrect: false },
    ],
  },
  {
    question: "What is the predicted temperature of absolute zero in Kelvin?",
    answerOptions: [
      { answer: "0 K", isCorrect: true },
      { answer: "-273.15 K", isCorrect: false },
      { answer: "0.01 K", isCorrect: false },
      { answer: "-273.15 °C", isCorrect: false },
    ],
  },
  {
    question:
      "What is the primary significance of Bell's theorem in quantum mechanics?",
    answerOptions: [
      {
        answer: "It demonstrates the non-locality of quantum mechanics.",
        isCorrect: true,
      },
      { answer: "It disproves the uncertainty principle.", isCorrect: false },
      { answer: "It proves that particles are waves.", isCorrect: false },
      {
        answer: "It shows that quantum mechanics is deterministic.",
        isCorrect: false,
      },
    ],
  },
  {
    question: "What is the significance of Bose-Einstein condensate?",
    answerOptions: [
      {
        answer: "It is a state of matter formed at extremely low temperatures.",
        isCorrect: true,
      },
      {
        answer: "It is a state of matter found inside stars.",
        isCorrect: false,
      },
      {
        answer: "It is a state of matter at extremely high temperatures.",
        isCorrect: false,
      },
      { answer: "It is a state of matter formed by quarks.", isCorrect: false },
    ],
  },
  {
    question: "What does the term 'wave-particle duality' refer to?",
    answerOptions: [
      {
        answer: "Particles exhibit both wave and particle properties.",
        isCorrect: true,
      },
      { answer: "Waves can behave like particles.", isCorrect: false },
      { answer: "Particles can move through waves.", isCorrect: false },
      {
        answer: "Waves and particles are separate entities.",
        isCorrect: false,
      },
    ],
  },
  {
    question: "What is the primary significance of the Casimir effect?",
    answerOptions: [
      {
        answer: "It provides evidence for quantum vacuum fluctuations.",
        isCorrect: true,
      },
      {
        answer: "It demonstrates the existence of dark matter.",
        isCorrect: false,
      },
      { answer: "It is responsible for superconductivity.", isCorrect: false },
      { answer: "It explains the weak nuclear force.", isCorrect: false },
    ],
  },
  {
    question:
      "In Young's double-slit experiment, the fringe width is doubled if:",
    answerOptions: [
      { answer: "The distance between the slits is halved", isCorrect: true },
      { answer: "The wavelength of light is halved", isCorrect: false },
      { answer: "The screen is moved closer to the slits", isCorrect: false },
      { answer: "The intensity of light is doubled", isCorrect: false },
    ],
  },
  {
    question:
      "Which phenomenon demonstrates the wave nature of light by producing constructive and destructive interference patterns?",
    answerOptions: [
      { answer: "Interference", isCorrect: true },
      { answer: "Reflection", isCorrect: false },
      { answer: "Refraction", isCorrect: false },
      { answer: "Polarization", isCorrect: false },
    ],
  },
  {
    question:
      "The condition for constructive interference in a double-slit experiment is:",
    answerOptions: [
      {
        answer: "Path difference equals an integer multiple of the wavelength",
        isCorrect: true,
      },
      {
        answer:
          "Path difference equals a half-integer multiple of the wavelength",
        isCorrect: false,
      },
      { answer: "The two waves are out of phase", isCorrect: false },
      { answer: "The two waves are polarized", isCorrect: false },
    ],
  },
  {
    question:
      "In a single-slit diffraction experiment, what happens to the width of the central maximum if the slit width is decreased?",
    answerOptions: [
      { answer: "The central maximum becomes wider", isCorrect: true },
      { answer: "The central maximum becomes narrower", isCorrect: false },
      { answer: "The central maximum disappears", isCorrect: false },
      { answer: "The width remains the same", isCorrect: false },
    ],
  },
  {
    question:
      "Which of the following is a necessary condition for observing interference patterns?",
    answerOptions: [
      { answer: "Coherent sources", isCorrect: true },
      { answer: "High-intensity light", isCorrect: false },
      { answer: "Multiple wavelengths", isCorrect: false },
      { answer: "Polarized light", isCorrect: false },
    ],
  },
  {
    question:
      "What is the phase difference between two waves that result in destructive interference?",
    answerOptions: [
      { answer: "π radians (180°)", isCorrect: true },
      { answer: "0 radians", isCorrect: false },
      { answer: "π/2 radians (90°)", isCorrect: false },
      { answer: "2π radians", isCorrect: false },
    ],
  },
  {
    question:
      "Which principle explains why waves bend around obstacles or spread out after passing through a small aperture?",
    answerOptions: [
      { answer: "Diffraction", isCorrect: true },
      { answer: "Interference", isCorrect: false },
      { answer: "Reflection", isCorrect: false },
      { answer: "Refraction", isCorrect: false },
    ],
  },
  {
    question: "A diffraction grating is used to:",
    answerOptions: [
      {
        answer: "Disperse light into its component wavelengths",
        isCorrect: true,
      },
      { answer: "Reflect light at different angles", isCorrect: false },
      { answer: "Focus light to a point", isCorrect: false },
      { answer: "Absorb unwanted wavelengths", isCorrect: false },
    ],
  },
  {
    question:
      "In the context of wave optics, what is the significance of a phase shift of π?",
    answerOptions: [
      { answer: "It leads to destructive interference", isCorrect: true },
      { answer: "It leads to constructive interference", isCorrect: false },
      { answer: "It causes reflection", isCorrect: false },
      { answer: "It causes polarization", isCorrect: false },
    ],
  },
  {
    question:
      "The wavelength of light used in a double-slit experiment is increased. What is the effect on the interference fringes?",
    answerOptions: [
      { answer: "Fringe spacing increases", isCorrect: true },
      { answer: "Fringe spacing decreases", isCorrect: false },
      { answer: "Fringe visibility decreases", isCorrect: false },
      { answer: "Fringe visibility increases", isCorrect: false },
    ],
  },
  {
    question: "Which of the following is true about the diffraction of light?",
    answerOptions: [
      {
        answer: "It occurs more prominently with longer wavelengths",
        isCorrect: true,
      },
      {
        answer: "It occurs more prominently with shorter wavelengths",
        isCorrect: false,
      },
      { answer: "It only occurs with monochromatic light", isCorrect: false },
      { answer: "It does not occur with light", isCorrect: false },
    ],
  },
  {
    question:
      "In a double-slit experiment, what would be the effect of using light with a shorter wavelength?",
    answerOptions: [
      { answer: "Fringes would be closer together", isCorrect: true },
      { answer: "Fringes would be further apart", isCorrect: false },
      { answer: "Fringes would disappear", isCorrect: false },
      { answer: "Fringes would remain the same", isCorrect: false },
    ],
  },
  {
    question:
      "A thin film of oil on water appears colored due to which phenomenon?",
    answerOptions: [
      { answer: "Interference", isCorrect: true },
      { answer: "Diffraction", isCorrect: false },
      { answer: "Dispersion", isCorrect: false },
      { answer: "Reflection", isCorrect: false },
    ],
  },
  {
    question:
      "Which experiment demonstrated that light exhibits wave properties by showing interference patterns?",
    answerOptions: [
      { answer: "Young's double-slit experiment", isCorrect: true },
      { answer: "Michelson-Morley experiment", isCorrect: false },
      { answer: "Photoelectric effect experiment", isCorrect: false },
      { answer: "Newton's prism experiment", isCorrect: false },
    ],
  },
  {
    question:
      "What happens to the angle of diffraction when the wavelength of light passing through a single slit is increased?",
    answerOptions: [
      { answer: "The angle of diffraction increases", isCorrect: true },
      { answer: "The angle of diffraction decreases", isCorrect: false },
      { answer: "The angle of diffraction remains the same", isCorrect: false },
      { answer: "The angle of diffraction becomes zero", isCorrect: false },
    ],
  },
  {
    question:
      "In a double-slit experiment, the bright and dark fringes are equally spaced. What would happen to the fringe spacing if the distance between the slits is increased?",
    answerOptions: [
      { answer: "Fringe spacing decreases", isCorrect: true },
      { answer: "Fringe spacing increases", isCorrect: false },
      { answer: "Fringe spacing remains the same", isCorrect: false },
      { answer: "Fringes disappear", isCorrect: false },
    ],
  },
  {
    question:
      "Which type of wave interference results in a wave with greater amplitude than either of the original waves?",
    answerOptions: [
      { answer: "Constructive interference", isCorrect: true },
      { answer: "Destructive interference", isCorrect: false },
      { answer: "Diffraction", isCorrect: false },
      { answer: "Polarization", isCorrect: false },
    ],
  },
  {
    question:
      "In Young's double-slit experiment, if the intensity of light through one slit is reduced, what happens to the overall interference pattern?",
    answerOptions: [
      {
        answer: "The contrast between the bright and dark fringes decreases",
        isCorrect: true,
      },
      {
        answer: "The contrast between the bright and dark fringes increases",
        isCorrect: false,
      },
      { answer: "The interference pattern disappears", isCorrect: false },
      { answer: "The fringe spacing increases", isCorrect: false },
    ],
  },
  {
    question:
      "Which of the following statements is true about polarized light?",
    answerOptions: [
      {
        answer: "The electric field oscillates in one direction",
        isCorrect: true,
      },
      {
        answer: "The light consists of multiple wavelengths",
        isCorrect: false,
      },
      {
        answer: "The magnetic field oscillates in all directions",
        isCorrect: false,
      },
      { answer: "It is always monochromatic", isCorrect: false },
    ],
  },
  {
    question:
      "What is the role of a diffraction grating in wave optics experiments?",
    answerOptions: [
      {
        answer: "It disperses light into its component wavelengths",
        isCorrect: true,
      },
      { answer: "It reflects light at different angles", isCorrect: false },
      { answer: "It focuses light to a point", isCorrect: false },
      { answer: "It absorbs unwanted wavelengths", isCorrect: false },
    ],
  },
];

export default opticsquestions;
