const transmetalsquestions = [
    {
        question:"The first ionization energy of Cu, Ag and Au decrease as :",
        answerOptions: [
            {answer: 'Au > Cu > Ag', isCorrect: true},
            {answer: 'Cu > Ag > Au', isCorrect: false},
            {answer: 'Au > Ag > Cu', isCorrect: false},
            {answer: 'Cu > Au > Ag', isCorrect: false},
        ],
    }, {
        question:"T Which of the following has highest second ionisation energy :",
        answerOptions: [
            {answer: 'Al > Cu > Ag', isCorrect: false},
            {answer: 'Ag > Cu > Al', isCorrect: true},
            {answer: 'Cu > Ag > Al', isCorrect: false},
            {answer: 'Al > Ag > Cu', isCorrect: false},
        ],
    },  {
        question:"The ground state electron configuration of Cr(24) is: :",
        answerOptions: [
            {answer: '3d^4 4s²', isCorrect: false},
            {answer: '3s² 4p^4', isCorrect: false},
            {answer: '3d^5 4s^1', isCorrect: true},
            {answer: '3d^6', isCorrect: false},
        ],
    },  {
        question:" Which of the following will give colourless aquous solution  :",
        answerOptions: [
            {answer: 'Fe²+', isCorrect: true},
            {answer: 'Cu²+', isCorrect: false},
            {answer: 'Ni²+', isCorrect: false},
            {answer: 'Sc^3+', isCorrect: false},
        ],
    },  {
        question:"Which compounds will form colourless aquous solution:",
        answerOptions: [
            {answer: 'VOSO4', isCorrect: false},
            {answer: 'Na3VO4', isCorrect: true},
            {answer: 'VCl3', isCorrect: false},
            {answer: 'TiCl3', isCorrect: false},
        ],
    },  {
        question:"The enzyme ceruloplasmin which has function in iron metabolism, contains the metal ion :",
        answerOptions: [
            {answer: 'Co²+', isCorrect: false},
            {answer: 'Zn²+', isCorrect: false},
            {answer: 'Cu²+', isCorrect: true},
            {answer: 'Mg²+', isCorrect: false},
           
        ],
    }, {
        question:"When a transition metal forms interstitial compound, it becomes :",
        answerOptions: [
            {answer: 'more ductile', isCorrect: false},
            {answer: 'more ductile', isCorrect: true},
            {answer: 'more soft', isCorrect: false},
            {answer: 'less brittle', isCorrect: false},
        ],
    },  {
        question:"The vandrium (V) oxide is :",
        answerOptions: [
            {answer: 'strong reducing agent', isCorrect: false},
            {answer: 'a bleaching agent', isCorrect: false},
            {answer: 'strong sehydrating agent', isCorrect: false},
            {answer: 'strong oxidizing agent', isCorrect: true},
        ],
    },  {
        question:"Li2O dopped NiO is  :",
        answerOptions: [
            {answer: 'an insulator', isCorrect: false},
            {answer: 'a semiconductor', isCorrect: true},
            {answer: 'a conductor', isCorrect: false},
            {answer: 'a superconductor', isCorrect: false},
        ],
    },  {
        question:"The pair of ions which has equal number of unapired electrons:",
        answerOptions: [
            {answer: 'Fe²+, Co²+', isCorrect: false},
            {answer: 'Mn²+, Fe²+', isCorrect: false},
            {answer: 'Cu²+, Co²+', isCorrect: false},
            {answer: 'Co^3+, Fe²+', isCorrect: true},
        ],
    },  {
        question:"For which of the lanthanides +2 oxidation state is expected most stable  :",
        answerOptions: [
            {answer: 'Ce', isCorrect: false},
            {answer: 'Ga', isCorrect: false},
            {answer: 'Eu', isCorrect: true},
            {answer: 'Pm', isCorrect: false},
        ],
    },  {
        question:" Which of the following transition metals shows maximum number of oxidation states :",
        answerOptions: [
            {answer: 'V', isCorrect: false},
            {answer: 'Cr', isCorrect: false},
            {answer: 'Mn', isCorrect: true},
            {answer: 'Fe', isCorrect: false},
        ],
    },  {
        question:"The oxide of chromium which is amphoteric is :",
        answerOptions: [
            {answer: 'CrO3', isCorrect: false},
            {answer: 'CrO', isCorrect: false},
            {answer: 'CrO5', isCorrect: false},
            {answer: 'Cr2O3', isCorrect: true},
        ],
    },  {
        question:"Paramegnetic moment of √(8) BM is associated with which of the following ion :",
        answerOptions: [
            {answer: 'Fe²+', isCorrect: false},
            {answer: 'V²+', isCorrect: false},
            {answer: 'Ni²+', isCorrect: true},
            {answer: 'Cr²+', isCorrect: false},
        ],
    },  {
        question:"The metal which is leached by CN^- ions is aquous solution is :",
        answerOptions: [
            {answer: 'Cu', isCorrect: false},
            {answer: 'Ag', isCorrect: true},
            {answer: 'Al', isCorrect: false},
            {answer: 'Fe', isCorrect: false},
        ],
    },  {
        question:"The chemically equivalent Cr–O bonds in Cr2O7 is :",
        answerOptions: [
            {answer: '4', isCorrect: false},
            {answer: '5', isCorrect: false},
            {answer: '6', isCorrect: true},
            {answer: '7', isCorrect: false},
        ],
    },    {
        question:"Barium titanate is :",
        answerOptions: [
            {answer: 'an antiferroelectric solid', isCorrect: false},
            {answer: 'a ferroelectric solid', isCorrect: true},
            {answer: 'an antiferromagnetic solid', isCorrect: false},
            {answer: 'an ferromagnetic solid', isCorrect: false},
        ],
    },  {
        question:"Which of the following is not a transition metal ion:",
        answerOptions: [
            {answer: 'Ti^4+', isCorrect: true},
            {answer: 'V^4+', isCorrect: false},
            {answer: 'Mn^4+', isCorrect: false},
            {answer: 'data insufficient', isCorrect: false},
        ],
    },  {
        question:"The basic nature of transition metal oxides MO follow the order :",
        answerOptions: [
            {answer: 'CrO > TiO > FeO', isCorrect: false},
            {answer: 'VO > FeO > TiO', isCorrect: false},
            {answer: 'VO > VrO > FeO', isCorrect: true},
            {answer: 'TiO > FeO > VO', isCorrect: false},
        ],
    }, {
        question:"Which of the following elements has highest second ionisation energy  :",
        answerOptions: [
            {answer: 'V', isCorrect: false},
            {answer: 'Mn', isCorrect: true},
            {answer: 'Cr', isCorrect: false},
            {answer: 'Mg', isorrect: true},
        ],
    },
]

export default transmetalsquestions;