const perodictablequestions = [
    {
        question:"Which is smallest atom of perodic table  :",
        answerOptions: [
            {answer: 'nitrogen', isCorrect: false},
            {answer: 'cesium', isCorrect: false},
            {answer: 'hydrogen', isCorrect: true},
            {answer: 'osmium', isCorrect: false},
        ],
    },  {
        question:"Which elements haveing a low boiling point :",
        answerOptions: [
            {answer: 'H', isCorrect: false},
            {answer: 'He', isCorrect: true},
            {answer: 'Li', isCorrect: false},
            {answer: 'U', isCorrect: false},
        ],
    },  {
        question:"Most aboudent elements on the earth  :",
        answerOptions: [
            {answer: 'H', isCorrect: false},
            {answer: 'O2', isCorrect: true},
            {answer: 'Cs', isCorrect: false},
            {answer: 'Li', isCorrect: false},
        ],
    }, {
        question:"Which is largest elements of perodic table :",
        answerOptions: [
            {answer: 'Cs', isCorrect: true},
            {answer: 'Li', isCorrect: false},
            {answer: 'He', isCorrect: false},
            {answer: 'Br', isCorrect: false},
        ],
    }, {
        question:"Which elements have a strongest reducing agent :",
        answerOptions: [
            {answer: 'Br', isCorrect: false},
            {answer: 'Li', isCorrect: true},
            {answer: 'Mg', isCorrect: false},
            {answer: 'Al', isCorrect: false},
        ],
    }, {
        question:"Which elements having a least electronegativity :",
        answerOptions: [
            {answer: 'Cl', isCorrect: false},
            {answer: 'F', isCorrect: true},
            {answer: 'H', isCorrect: false},
            {answer: 'He', isCorrect: false},
        ],
    }, {
        question:"Which elements having a high density :",
        answerOptions: [
            {answer: 'He', isCorrect: false},
            {answer: 'Mg', isCorrect: false},
            {answer: 'Al', isCorrect: false},
            {answer: 'Ir', isCorrect: true},
        ],
    }, {
        question:"Most ablundent elements of the universe  :",
        answerOptions: [
            {answer: 'H', isCorrect: true},
            {answer: 'Li', isCorrect: false},
            {answer: 'C', isCorrect: false},
            {answer: 'Co', isCorrect: false},
        ],
    }, {
        question:"Which elements having a highest boiling point :",
        answerOptions: [
            {answer: 'Li', isCorrect: false},
            {answer: 'Re', isCorrect: true},
            {answer: 'Mg', isCorrect: false},
            {answer: 'Al', isCorrect: false},
        ],
    }, {
        question:"Which of these elements are in correct order of radii is  :",
        answerOptions: [
            {answer: 'N < Be < B', isCorrect: false},
            {answer: 'F < O2 < N3', isCorrect: true},
            {answer: 'Na < Li < K', isCorrect: false},
            {answer: 'Fe^3 < Fe² < Fe^4', isCorrect: false},
        ],
    },{
        question:"Electronegativity of these elements increases in the order of :",
        answerOptions: [
            {answer: 'C, N, Si, P', isCorrect: false},
            {answer: 'Si, P, C, N', isCorrect: true},
            {answer: 'N, Si, C, P', isCorrect: false},
            {answer: 'P, Si, N, C', isCorrect: false},
        ],
    },{
        question:"Which of the following are most basic oxide is :",
        answerOptions: [
            {answer: 'Na2O', isCorrect: true},
            {answer: 'MgO', isCorrect: false},
            {answer: 'Al2O3', isCorrect: false},
            {answer: 'Fe2O3', isCorrect: false},
        ],
    }, {
        question:"The electron affinity order for halogens is :",
        answerOptions: [
            {answer: 'F > Cl < Br < I', isCorrect: false},
            {answer: 'F > Cl < Br > I', isCorrect: false},
            {answer: 'F < Cl > Br > I', isCorrect: true},
            {answer: 'F > Cl ? Br > I', isCorrect: false},
        ],
    }, {
        question:"The correct oder of ionic radii of these elements are :",
        answerOptions: [
            {answer: 'I+ > I- > I', isCorrect: false},
            {answer: 'I > I+ > I-', isCorrect: false},
            {answer: 'I > I > I+', isCorrect: false},
            {answer: 'I- > I > I+', isCorrect: true},
        ],
    }, {
        question:"Which of the following elements is metalloid :",
        answerOptions: [
            {answer: 'C', isCorrect: false},
            {answer: 'Pb', isCorrect: true},
            {answer: 'As', isCorrect: false},
            {answer: 'Mg', isCorrect: false},
        ],
    }, {
        question:"The most electronegative elements is represented by :",
        answerOptions: [
            {answer: 'ns² np^4', isCorrect: false},
            {answer: 'ns² np^3', isCorrect: false},
            {answer: 'ns² np^5', isCorrect: true},
            {answer: 'ns² np^6', isCorrect: false},
        ],
    }, {
        question:"Which of the following elements has highest value of electron affinity :",
        answerOptions: [
            {answer: 'O', isCorrect: false},
            {answer: 'Se', isCorrect: false},
            {answer: 'Te', isCorrect: false},
            {answer: 'S', isCorrect: true},
        ],
    },{
        question:"The Correct order of first ionization energy in decreasing order :",
        answerOptions: [
            {answer: 'C > B > Be > Li', isCorrect: false},
            {answer: 'C > Be > B > Li', isCorrect: true},
            {answer: 'Be > Li > B > C', isCorrect: false},
            {answer: 'B > C > Be > Li', isCorrect: false},
        ],
    }, {
        question:"When electrons is added to an isolatd netural atom :",
        answerOptions: [
            {answer: 'energy released', isCorrect: true},
            {answer: 'energy absorved', isCorrect: false},
            {answer: 'energy conserved', isCorrect: false},
            {answer: 'energy remain same', isCorrect: false},
        ],
    }, {
        question:"Distance between the electron and nucleus is called :",
        answerOptions: [
            {answer: 'atomic radius', isCorrect: false},
            {answer: 'atomic structure', isCorrect: false},
            {answer: 'atomic charge', isCorrect: false},
            {answer: 'atomic size', isCorrect: true},
        ],
    }, {
        question:"The correct order of increasing first ionizatin energy is :",
        answerOptions: [
            {answer: 'C > B > Be > Li', isCorrect: false},
            {answer: 'C > Be > B > Li', isCorrect: true},
            {answer: 'B > C > Be > Li', isCorrect: false},
            {answer: 'Be > Li > B > C', isCorrect: false},
        ],
    },{
        question:"Property of the alkaline earth metals that increases with their atomic number is :",
        answerOptions: [
            {answer: 'ionization energy', isCorrect: false},
            {answer: 'hydroxide solubility', isCorrect: true},
            {answer: 'electronegativity', isCorrect: false},
            {answer: 'electron affinity', isCorrect: false},
        ],
    }, {
        question:"Which of the following are correct order of electron affinity :",
        answerOptions: [
            {answer: 'F > Cl > Br', isCorrect: false},
            {answer: 'F > Cl < Br', isCorrect: false},
            {answer: 'F < Cl > Br', isCorrect: true},
            {answer: 'F < Cl < Br', isCorrect: false},
        ],
    },{
        question:"Which of the following elements has the highest value of electron affinity :",
        answerOptions: [
            {answer: 'Se', isCorrect: false},
            {answer: 'S', isCorrect: true},
            {answer: 'O', isCorrect: false},
            {answer: 'Mg', isCorrect: false},
        ],
    },{
        question:"The correct order of first ionization energy is :",
        answerOptions: [
            {answer: 'C > N > O', isCorrect: false},
            {answer: 'C > N < O', isCorrect: false},
            {answer: 'C < N > O', isCorrect: true},
            {answer: 'can not be said', isCorrect: false},
        ],
    },{
        question:"Size of atoms in perodic are in :",
        answerOptions: [
            {answer: 'increasing order', isCorrect: true},
            {answer: 'decreasing order', isCorrect: false},
            {answer: 'both of these', isCorrect: false},
            {answer: 'none og these', isCorrect: false},
        ],
    },{
        question:" :",
        answerOptions: [
            {answer: 'condensation', isCorrect: false},
            {answer: 'dialysis', isCorrect: false},
            {answer: 'diffusion', isCorrect: false},
            {answer: 'additive of electrolyte', isCorrect: true},
        ],
    }, {
        question:"Metallic nature in peroid table are in form of :",
        answerOptions: [
            {answer: 'increases', isCorrect: false},
            {answer: '1st increase then decrease', isCorrect: false},
            {answer: 'decreases', isCorrect: true},
            {answer: 'can not be said', isCorrect: false},
        ],
    }, {
        question:"Ionization potential of Na would numerically the same as  :",
        answerOptions: [
            {answer: 'Electron affinity of Na+', isCorrect: true},
            {answer: 'Electronegativity of Na+', isCorrect: false},
            {answer: 'Ionization potential of Mg', isCorrect: false},
            {answer: 'Electron affinity of He', isCorrect: false},
        ],
    }, {
        question:" The electron affinity of the members of oxygen family follow the ordering :",
        answerOptions: [
            {answer: 'O > S > Se ', isCorrect: false},
            {answer: 'S > O > Se', isCorrect: false},
            {answer: 'S > Se > O', isCorrect: true},
            {answer: 'Se > O > S', isCorrect: false},
        ],
    },
]

export default perodictablequestions;