import React, { useEffect, useState } from "react";
import { doc, updateDoc, increment, getDoc, setDoc, arrayUnion } from "firebase/firestore";
import { fireDB } from "../config/firebase";
import { collection, getDocs } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import StarRating from '../components/StarRating';

const Vote = ({ questionId, yesCount, noCount, onVoteUpdate }) => {
  const [hasVoted, setHasVoted] = useState(null);
  const [userId, setUserId] = useState(null);
  const [userNames, setUserNames] = useState({});
  const [posterName, setPosterName] = useState("Unknown User"); // For the question poster's name
  const [performanceData, setPerformanceData] = useState({ totalVotes: 0, userVotes: [] });
  const [loadingUserData, setLoadingUserData] = useState(true);
  const [userError, setUserError] = useState(null);
  

  const navigate = useNavigate();

  useEffect(() => {
    const auth = getAuth();
    const user = auth.currentUser;
    setUserId(user ? user.uid : null);
  }, []);

  useEffect(() => {
    const fetchPosterName = async () => {
      try {
        const questionDoc = await getDoc(doc(fireDB, "voteQuestions", questionId));
        if (questionDoc.exists()) {
          const { userId: posterId } = questionDoc.data();
          const userDoc = await getDoc(doc(fireDB, "users", posterId));
          if (userDoc.exists()) {
            setPosterName(userDoc.data().name);
          }
        }
      } catch (error) {
        console.error("Error fetching question poster's name:", error);
        setUserError("Error loading user data.");
      }
    };

    const fetchUsers = async () => {
      try {
        const userSnapshot = await getDocs(collection(fireDB, "users"));
        const userData = {};
        userSnapshot.forEach((doc) => {
          const user = doc.data();
          userData[doc.id] = user.name;
        });
        setUserNames(userData);
      } catch (error) {
        console.error("Error fetching users:", error);
        setUserError("Error loading users.");
      } finally {
        setLoadingUserData(false);
      }
    };

    fetchPosterName();
    fetchUsers();
  }, [questionId]);

  useEffect(() => {
    const checkVoteStatus = async () => {
      if (!userId) return;
      try {
        const voteRef = doc(fireDB, "userVotes", `${userId}_${questionId}`);
        const voteDoc = await getDoc(voteRef);
        setHasVoted(voteDoc.exists() ? voteDoc.data().vote : null);

        const performanceRef = doc(fireDB, "votePerformances", userId);
        const performanceDoc = await getDoc(performanceRef);
        if (performanceDoc.exists()) {
          setPerformanceData(performanceDoc.data());
        } else {
          setPerformanceData({ totalVotes: 0, userVotes: [] });
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    checkVoteStatus();
  }, [questionId, userId]);

  const handleVote = async (option) => {
    if (hasVoted) {
      alert("You have already voted!");
      return;
    }

    try {
      const voteField = option === "Yes" ? "Yes" : "No";
      const questionRef = doc(fireDB, "voteQuestions", questionId);
      const voteRef = doc(fireDB, "userVotes", `${userId}_${questionId}`);
      const performanceRef = doc(fireDB, "votePerformances", userId);

      await updateDoc(questionRef, { [voteField]: increment(1) });
      await setDoc(voteRef, { userId, questionId, vote: option });
      await updateDoc(performanceRef, {
        totalVotes: increment(1),
        userVotes: arrayUnion(questionId),
      });

      onVoteUpdate();
      setHasVoted(option);
    } catch (error) {
      console.error("Error updating vote:", error);
    }
  };

  if (loadingUserData) return <p>Loading user information...</p>;
  if (userError) return <p>{userError}</p>;

  return (
    <div>
      <p style={{ margin: 0 }}>
        Posted by{" "}
        <span
          onClick={() => navigate(`/profile/${userId}`)}
          style={{
            cursor: "pointer",
            color: "#007bff",
            fontWeight: "600",
            textDecoration: "underline",
            transition: "color 0.2s ease",
          }}
          onMouseEnter={(e) => (e.target.style.color = "#0056b3")}
          onMouseLeave={(e) => (e.target.style.color = "#007bff")}
        >
          {posterName}
        </span>
      </p>

      <button onClick={() => handleVote("Yes")} disabled={hasVoted === "Yes"}>
        Yes ({yesCount || 0})
      </button>
      <button onClick={() => handleVote("No")} disabled={hasVoted === "No"}>
        No ({noCount || 0})
      </button>
      {hasVoted && <p>You have voted: {hasVoted}</p>}
      {performanceData.userVotes.includes(questionId) && <p>You have voted on this question.</p>}

      <h4>Rate this question:</h4>
      <StarRating questionId={questionId} userId={userId} />
    </div>
  );
};

export default Vote;
