const halogensquestions = [
    {
        question:"This group are generally known as :",
        answerOptions: [
            {answer: 'Pincogens', isCorrect: false},
            {answer: 'Polonium', isCorrect: false},
            {answer: 'Halogen', isCorrect: true},
            {answer: 'Noble gas', isCorrect: false},
        ],
    }, {
        question:"Atomic number of chlorine is :",
        answerOptions: [
            {answer: '16', isCorrect: false},
            {answer: '17', isCorrect: true},
            {answer: '18', isCorrect: false},
            {answer: '17', isCorrect: false},
        ],
    },  {
        question:"Physical state of bromine is :",
        answerOptions: [
            {answer: 'solid', isCorrect: false},
            {answer: 'liquid', isCorrect: true},
            {answer: 'gas', isCorrect: false},
            {answer: 'can not be said', isCorrect: false},
        ],
    },  {
        question:"All the elements of this group are  :",
        answerOptions: [
            {answer: 'metallic', isCorrect: false},
            {answer: 'acidic', isCorrect: false},
            {answer: 'basic', isCorrect: false},
            {answer: 'non-metallic', isCorrect: true},
        ],
    },  {
        question:"Which elements is radioactive and does not occur in nature :",
        answerOptions: [
            {answer: 'Chlorine', isCorrect: false},
            {answer: 'Bromine', isCorrect: false},
            {answer: 'Iodine', isCorrect: false},
            {answer: 'Astatine', isCorrect: true},
        ],
    },  {
        question:"he colour of halogens is due to :",
        answerOptions: [
            {answer: 'Electronegativity', isCorrect: false},
            {answer: 'Acidic', isCorrect: false},
            {answer: 'Reducing agent', isCorrect: false},
            {answer: 'Charge transfer', isCorrect: true},         
        ],
    }, {
        question:"Ionization energy, electronegativity and oxidizing power of this group is :",
        answerOptions: [
            {answer: 'increases', isCorrect: false},
            {answer: 'decreases', isCorrect: true},
            {answer: 'remain unchanged', isCorrect: false},
            {answer: 'can not be said', isCorrect: false},
        ],
    },  {
        question:"Atomic radius, ionic radius, bond length, mp, bp and reducing power of this group is  :",
        answerOptions: [
            {answer: 'increases', isCorrect: true},
            {answer: 'decreases', isCorrect: false},
            {answer: 'remain unchanged', isCorrect: false},
            {answer: 'can not be said', isCorrect: false},
        ],
    },  {
        question:"Fluorine is most oxidizing and therefore, common oxidizing agents fail to oxidize F- to produce :",
        answerOptions: [
            {answer: 'F3', isCorrect: false},
            {answer: 'F2', isCorrect: true},
            {answer: 'F', isCorrect: false},
            {answer: 'can not be said', isCorrect: false},
        ],
    },  {
        question:"Cl2, Br2 and I2 can be prepared by heating respective halides with MnO2 and :",
        answerOptions: [
            {answer: 'H2SO4', isCorrect: false},
            {answer: 'dil. H2SO4', isCorrect: false},
            {answer: 'conc. H2SO4', isCorrect: true},
            {answer: 'all of these', isCorrect: false},
        ],
    },  {
        question:"NaX + MnO2 + H2SO4 ----> X2, which catylist used in this reaction  : (Xnetous Chellange) ",
        answerOptions: [
            {answer: 'conc. H2SO4', isCorrect: false},
            {answer: 'H2O2', isCorrect: false},
            {answer: 'heat', isCorrect: true},
            {answer: 'CO2', isCorrect: false},
        ],
    },  {
        question:"Which elements is most reactive of all the elements.:",
        answerOptions: [
            {answer: 'Bromine', isCorrect: false},
            {answer: 'Chlorine', isCorrect: false},
            {answer: 'Iodine', isCorrect: false},
            {answer: 'Fluorine', isCorrect: true},
        ],
    },  {
        question:"Lower atomic number halogen oxidizes halide ion having higher atomic number to form : ",
        answerOptions: [
            {answer: '2X', isCorrect: false},
            {answer: 'X2', isCorrect: true},
            {answer: 'X3', isCorrect: false},
            {answer: 'X4', isCorrect: false},
        ],
    },  {
        question:"Which are very comparable in their reaction :",
        answerOptions: [
            {answer: 'Cl and I', isCorrect: false},
            {answer: 'F and I', isCorrect: false},
            {answer: 'Cl and Br', isCorrect: true},
            {answer: 'F and Br', isCorrect: false},
        ],
    },  {
        question:"Reactivity order :",
        answerOptions: [
            {answer: 'F > Cl > Br > I', isCorrect: true},
            {answer: 'F > Br > Cl > I', isCorrect: false},
            {answer: 'F > I > Br > Br', isCorrect: false},
            {answer: 'F > Br > Cl > I', isCorrect: false},
        ],
    },  {
        question:"NaCl + H2SO4 ----> NaHSO4 + HCl, this reaction takes place in :",
        answerOptions: [
            {answer: 'high pressure', isCorrect: false},
            {answer: 'low pressure', isCorrect: false},
            {answer: 'high temperature', isCorrect: false},
            {answer: 'low temperature', isCorrect: true},
        ],
    },  {
        question:"Hydrogen bond is strongest in :",
        answerOptions: [
            {answer: 'F - H -- N', isCorrect: false},
            {answer: 'F - H -- O', isCorrect: false},
            {answer: 'F - H -- F', isCorrect: true},
            {answer: 'F - H -- Cl', isCorrect: false},
        ],
    },  {
        question:"The correct X - X bond dissociation energy is represented by :",
        answerOptions: [
            {answer: 'F-F > Cl-Cl > Br-Br > I-I', isCorrect: false},
            {answer: 'Cl-Cl > Br-Br > F-F > I-I', isCorrect: true},
            {answer: 'Br-BR > Cl-Cl > F-F > I-I', isCorrect: false},
            {answer: 'I-I > Br-Br > Cl-Cl > F-F', isCorrect: false},
        ],
    },  {
        question:"Chlorine gas is passed on wet blue litmus. The colour is  :",
        answerOptions: [
            {answer: 'Red', isCorrect: false},
            {answer: 'Yellow', isCorrect: false},
            {answer: 'Colorless', isCorrect: true},
            {answer: 'Uncolored', isCorrect: false},
        ],
    },  {
        question:"Which of the following reactions is possible :",
        answerOptions: [
            {answer: '2NaCl + I2 ----> 2NaI + Cl2', isCorrect: false},
            {answer: '2NaCl + Br2 ----> 2NaBr + Cl2', isCorrect: false},
            {answer: '2NaBr + Cl2 ----> 2NaCl + Br2', isCorrect: true},
            {answer: '2NaBr + I2 ----> 2NaI + Br2', isorrect: false},
        ],
    },
]

export default halogensquestions;