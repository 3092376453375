const quantummechaniesquestions = [
    {
        question:"Packet of energy is called:",
        answerOptions: [
            {answer: 'maximum energy', isCorrect: false},
            {answer: 'infinite energy', isCorrect: false},
            {answer: 'quanta', isCorrect: true},
            {answer: 'spectra', isCorrect: false},
        ],
    }, {
        question:"The kinetic energy of the emitted electrons depends upon the frequency of the :",
        answerOptions: [
            {answer: 'radiation', isCorrect: false},
            {answer: 'photoelectric effect', isCorrect: false},
            {answer: 'incident ray', isCorrect: true},
            {answer: 'reflected ray', isCorrect: false},
        ],
    },  {
        question:"The energy emitted by the surface per unit area is called :",
        answerOptions: [
            {answer: 'radiation', isCorrect: false},
            {answer: 'emissive energy', isCorrect: false},
            {answer: 'emissive power', isCorrect: true},
            {answer: 'radiation energy', isCorrect: false},
        ],
    },  {
        question:"Stefan boltzmann's law explained as :",
        answerOptions: [
            {answer: 'EB proportional to T^4', isCorrect: true},
            {answer: 'EB proportional to T^3', isCorrect: false},
            {answer: 'EB proportional to T^3', isCorrect: false},
            {answer: 'EB proportional to T^3', isCorrect: false},
        ],
    },  {
        question:"Wien's Displacement law expressed as :",
        answerOptions: [
            {answer: 'λ(m) = T', isCorrect: false},
            {answer: 'λ(m) = 1/T', isCorrect: true},
            {answer: 'λ(m) = 1/T²', isCorrect: false},
            {answer: 'λ(m) = 1/T^-2', isCorrect: false},
        ],
    },  {
        question:" The angular monenum of an electron in an atom is also :",
        answerOptions: [
            {answer: 'radiated', isCorrect: false},
            {answer: 'quantised', isCorrect: true},
            {answer: 'incident', isCorrect: false},
            {answer: 'all', isCorrect: false},
           
        ],
    }, {
        question:"A body that compelitly absorbs radient energy falling on it called :",
        answerOptions: [
            {answer: 'asymmetry effect', isCorrect: false},
            {answer: 'relaxation effect', isCorrect: false},
            {answer: 'electrophoretic effect', isCorrect: false},
            {answer: 'black body radiation', isCorrect: true},
        ],
    },  {
        question:"Energy is emitted or abosorbed not continously but discontinously in the form of :",
        answerOptions: [
            {answer: 'packet of radiation', isCorrect: false},
            {answer: 'packet of energy', isCorrect: true},
            {answer: 'packet of spectra', isCorrect: false},
            {answer: 'none of these', isCorrect: false},
        ],
    },  {
        question:"A quantitative realtionship between the energy absorbed and energy emitted by a body was given by :",
        answerOptions: [
            {answer: 'Enistein', isCorrect: false},
            {answer: 'Kirchhoff', isCorrect: true},
            {answer: 'Hess', isCorrect: false},
            {answer: 'Fermi', isCorrect: false},
        ],
    },  {
        question:"What happens to energy of the particle in a 1-D box if the length of the box is made larger :",
        answerOptions: [
            {answer: 'E become smaller', isCorrect: true},
            {answer: 'E become larger', isCorrect: false},
            {answer: 'E become ∞', isCorrect: false},
            {answer: 'E become zero', isCorrect: false},
        ],
    },  {
        question:"SI unit of wave number  :",
        answerOptions: [
            {answer: 'cm', isCorrect: false},
            {answer: 'cm^2', isCorrect: false},
            {answer: 'cm^-1', isCorrect: true},
            {answer: 'none of these', isCorrect: false},
        ],
    },  {
        question:"What are the conditions which an eigen function must satisfy :",
        answerOptions: [
            {answer: 'single valued', isCorrect: false},
            {answer: 'continuous', isCorrect: false},
            {answer: 'zero at infinty', isCorrect: false},
            {answer: 'all of these', isCorrect: true},
        ],
    },  {
        question:"1 hertz is equal to :",
        answerOptions: [
            {answer: '1 s^-1', isCorrect: true},
            {answer: '1 s', isCorrect: false},
            {answer: 'unit s^-1', isCorrect: false},
            {answer: 'unit s', isCorrect: false},
        ],
    },  {
        question:"Which of the following moleculaes will show  rotational Roman Spectra  :",
        answerOptions: [
            {answer: 'HCl', isCorrect: false},
            {answer: 'CO', isCorrect: false},
            {answer: 'H2O', isCorrect: false},
            {answer: 'all of these', isCorrect: true},
        ],
    },  {
        question:"The rotational spectra involve :",
        answerOptions: [
            {answer: 'high energy change', isCorrect: false},
            {answer: 'small energy change', isCorrect: true},
            {answer: 'no energy change', isCorrect: false},
            {answer: '∞ energy', isCorrect: false},
        ],
    },  {
        question:"In the Raman spectrum, the middle line is called :",
        answerOptions: [
            {answer: 'Raman line', isCorrect: false},
            {answer: 'Ryleigh line', isCorrect: true},
            {answer: 'functional group line', isCorrect: false},
            {answer: 'absorpation of energy', isCorrect: false},
        ],
    },  {
        question:"The electromegnatic radiation of higher wavelength has :",
        answerOptions: [
            {answer: 'higher energy', isCorrect: false},
            {answer: 'lower energy', isCorrect: true},
            {answer: 'zero energy', isCorrect: false},
            {answer: 'intermediate energy', isCorrect: false},
        ],
    },  {
        question:"The spectra caused in the infraed region by the transition in vibrational level in different modes of vibrationa are called :",
        answerOptions: [
            {answer: 'rotational spectra', isCorrect: false},
            {answer: 'electronic spectra', isCorrect: false},
            {answer: 'vibrational spectra', isCorrect: true},
            {answer: 'none of these', isCorrect: false},
        ],
    },  {
        question:"It is only the absorbed light radiations that are effective in producing a chemical reactions. This is the statements of  :",
        answerOptions: [
            {answer: 'Lambert Law', isCorrect: false},
            {answer: 'Lambert-Beer Law', isCorrect: false},
            {answer: 'Grotthus-Draper Law', isCorrect: true},
            {answer: 'Stark-Einstein Law', isCorrect: false },
        ],
    },  {
        question:"The light emitted in a chemiluminescent raction is also called :",
        answerOptions: [
            {answer: 'cold light', isCorrect: true},
            {answer: 'hot light', isCorrect: false},
            {answer: 'bright light', isCorrect: false},
            {answer: 'none og these', isCorrect: false},
        ],
    },  {
        question:"Equation followed by rate of radioactive disintegration :",
        answerOptions: [
            {answer: 'λ = 2.303/t log N1xN', isCorrect: false},
            {answer: 'λ = 2.303/t log N1/N', isCorrect: true},
            {answer: 'λ = 2.303/t log N1', isCorrect: false},
            {answer: 'λ = 2.303/t log N', isCorrect: false},
        ],
    },  {
        question:"The number of molecules reacted or formed per photon of light absorbed is called :",
        answerOptions: [
            {answer: 'yield of reaction', isCorrect: false},
            {answer: 'quantum efficient', isCorrect: false},
            {answer: 'quantum yield', isCorrect: true},
            {answer: 'quantum productivity', isCorrect: false},
        ],
    },  {
        question:"Isotopes are atom having the  :",
        answerOptions: [
            {answer: 'same atomic number but different atomic mass', isCorrect: true},
            {answer: 'different atomic numbers but same atomic mass', isCorrect: false},
            {answer: 'same atomic number and atomic mass', isCorrect: false},
            {answer: 'different atomic numbers and atomic mass', isCorrect: false},
        ],
    },  {
        question:"The formation of hydrogen bomb is based upon the principle of :",
        answerOptions: [
            {answer: 'nuclear fusion', isCorrect: true},
            {answer: 'nuclear fission', isCorrect: false},
            {answer: 'both of these', isCorrect: false},
            {answer: 'none of these', isCorrect: false},
        ],
    },  {
        question:"Radioactive disintegration follows :",
        answerOptions: [
            {answer: 'electrods', isCorrect: false},
            {answer: 'zero-order kinetic', isCorrect: false},
            {answer: 'first-order kinetic', isCorrect: true},
            {answer: 'second-order kinetic', isCorrect: false},
        ],
    },  {
        question:"Transmutation of elements by conversion of one element into another by artificial means is called :",
        answerOptions: [
            {answer: 'binding energy', isCorrect: false},
            {answer: 'artificial disintegration', isCorrect: true},
            {answer: 'stable elements', isCorrect: false},
            {answer: 'data insufficient', isCorrect: false},
        ],
    },  {
        question:"Energy required th break up a nucleus into its individual nucleons is called :",
        answerOptions: [
            {answer: 'electrolytic', isCorrect: false},
            {answer: 'stability', isCorrect: false},
            {answer: 'binding energy', isCorrect: true},
            {answer: 'π-mesons', isCorrect: false},
        ],
    },  {
        question:"Which of the following is not a method of detection and measurement of radioactivity :",
        answerOptions: [
            {answer: 'slater', isCorrect: true},
            {answer: 'wilson cloud cham method', isCorrect: false},
            {answer: 'geiger-muller counter', isCorrect: false},
            {answer: 'scintillation method', isCorrect: false},
        ],
    },  {
        question:"The process of splitting up of a heavy nucleus into two, more or less, equal halves is called :",
        answerOptions: [
            {answer: 'nuclear fission', isCorrect: true},
            {answer: 'nuclear fusion', isCorrect: false},
            {answer: 'carbon fusion', isCorrect: false},
            {answer: 'all of these', isCorrect: false},
        ],
    },  {
        question:"Nuclei of radioactive elements lie above and below the belt of :",
        answerOptions: [
            {answer: 'π-mesons', isCorrect: false},
            {answer: 'stability', isCorrect: true},
            {answer: 'corrosion', isCorrect: true},
            {answer: 'all are these', isorrect: false},
        ],
    },
]

export default quantummechaniesquestions;