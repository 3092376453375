const groupsixteenquestions = [
    {
        question:"Group of this elements are called :",
        answerOptions: [
            {answer: 'Pincogens', isCorrect: false},
            {answer: 'Polonium', isCorrect: true},
            {answer: 'Halogen', isCorrect: false},
            {answer: 'Noble gas', isCorrect: false},
        ],
    }, {
        question:"Which is non-metallic :",
        answerOptions: [
            {answer: 'O', isCorrect: false},
            {answer: 'S', isCorrect: false},
            {answer: 'Se', isCorrect: false},
            {answer: 'All', isCorrect: true},
        ],
    },  {
        question:"Polonium is discovered by :",
        answerOptions: [
            {answer: 'J. J. Thompson', isCorrect: false},
            {answer: 'M. Curie', isCorrect: true},
            {answer: 'Stephen Hawlking', isCorrect: false},
            {answer: 'can not be said', isCorrect: false},
        ],
    },  {
        question:"It is stable due to very effective pπ - pπ bonding  :",
        answerOptions: [
            {answer: 'O2', isCorrect: true},
            {answer: 'S', isCorrect: false},
            {answer: 'Se', isCorrect: false},
            {answer: 'Te', isCorrect: false},
        ],
    },  {
        question:"How many allotropes oxygen have  :",
        answerOptions: [
            {answer: 'One', isCorrect: false},
            {answer: 'Two', isCorrect: true},
            {answer: 'Three', isCorrect: false},
            {answer: 'Four', isCorrect: false},
        ],
    },  {
        question:"The central O-atom :",
        answerOptions: [
            {answer: 'sp hybridized', isCorrect: false},
            {answer: 'sp² hybridized', isCorrect: true},
            {answer: 'sp^3 hybridized', isCorrect: false},
            {answer: 'can not be said', isCorrect: false},
           
        ],
    }, {
        question:" Oxygen has positive oxidation state only with :",
        answerOptions: [
            {answer: 'F', isCorrect: true},
            {answer: 'S', isCorrect: false},
            {answer: 'Se', isCorrect: false},
            {answer: 'Td', isCorrect: false},
        ],
    },  {
        question:"Which elements has more electronegativity in group-16  :",
        answerOptions: [
            {answer: 'O', isCorrect: true},
            {answer: 'S', isCorrect: false},
            {answer: 'Se', isCorrect: false},
            {answer: 'Te', isCorrect: false},
        ],
    },  {
        question:"All the elements of group-16 form hydrides of the type :",
        answerOptions: [
            {answer: 'MH', isCorrect: false},
            {answer: 'MH2', isCorrect: true},
            {answer: 'MH3', isCorrect: false},
            {answer: 'can not be said', isCorrect: false},
        ],
    },  {
        question:"Acidic strength of H2O :",
        answerOptions: [
            {answer: 'increases', isCorrect: true},
            {answer: 'decreases', isCorrect: false},
            {answer: 'remain unchanged', isCorrect: false},
            {answer: 'can not be said', isCorrect: false},
        ],
    },  {
        question:"Anode solution is hydrolyzed by water to give  : (Xnetous Chellange)",
        answerOptions: [
            {answer: 'H2O', isCorrect: false},
            {answer: 'H2O2', isCorrect: false},
            {answer: '2HO', isCorrect: false},
            {answer: 'can not be said', isCorrect: true},
        ],
    },  {
        question:"Pure acid is colourle :",
        answerOptions: [
            {answer: 'solid', isCorrect: false},
            {answer: 'liquid', isCorrect: true},
            {answer: 'water', isCorrect: false},
            {answer: 'can not be said', isCorrect: false},
        ],
    },  {
        question:"HCOOH -----> CO + [H2O.H2SO4], which actylist used in this reaction : (Xnetous Chellange)",
        answerOptions: [
            {answer: 'Heat', isCorrect: false},
            {answer: 'Conc. H2SO4', isCorrect: false},
            {answer: 'H2SO4', isCorrect: false},
            {answer: 'Conc. H2SO4 and heat', isCorrect: true},
        ],
    },  {
        question:"The geometry and dipole moment of O3 are :",
        answerOptions: [
            {answer: 'Linear and zero', isCorrect: false},
            {answer: 'Angular and zero', isCorrect: false},
            {answer: 'Angular and non-zero', isCorrect: true},
            {answer: 'can not be said', isCorrect: false},
        ],
    },  {
        question:" Shape of O3 is :",
        answerOptions: [
            {answer: 'Angular', isCorrect: true},
            {answer: 'Linear', isCorrect: false},
            {answer: 'Tetradhdral', isCorrect: false},
            {answer: 'Triangular', isCorrect: false},
        ],
    },  {
        question:"The correct order of electron attachment enthalpy of O, S and Se is :",
        answerOptions: [
            {answer: 'O > S > Se', isCorrect: false},
            {answer: 'S > Se > O', isCorrect: true},
            {answer: 'S > O > Se', isCorrect: false},
            {answer: 'Se > S > O', isCorrect: false},
        ],
    },  {
        question:"Tumber of atoms in α-sulphur is :",
        answerOptions: [
            {answer: '4', isCorrect: false},
            {answer: '6', isCorrect: true},
            {answer: '8', isCorrect: false},
            {answer: 'can not ba said', isCorrect: false},
        ],
    },  {
        question:"H2S acts likes :",
        answerOptions: [
            {answer: 'Oxidizing agent', isCorrect: false},
            {answer: 'Reducing agent', isCorrect: true},
            {answer: 'Oxidizing agent as well reducing agent', isCorrect: false},
            {answer: 'None of these', isCorrect: false},
        ],
    },  {
        question:"The number of S – S bonds in SO3 trimer, S3O9 :",
        answerOptions: [
            {answer: 'Zero', isCorrect: true},
            {answer: 'One', isCorrect: false},
            {answer: 'Two', isCorrect: false},
            {answer: 'Three', isCorrect: false},
        ],
    },  {
        question:"The gaseous mixture N2O and O2 is used as :",
        answerOptions: [
            {answer: 'Anesthetic', isCorrect: true},
            {answer: 'Fuel', isCorrect: false},
            {answer: 'Oxidizing agent', isCorrect: false},
            {answer: 'None of these', isorrect: false},
        ],
    },
]

export default groupsixteenquestions;