import { useState, useEffect } from "react";
import { doc, updateDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { toast } from "react-toastify";
import { fireDB, storage } from "../config/firebase"; // Import Firebase services
import { auth } from "../config/firebase";
import { onAuthStateChanged } from "firebase/auth";
import "../componentsStyle/profileupdate.css";

const ProfileUpdate = ({ userData, onClose }) => {
  const [description, setDescription] = useState(userData?.description || "");
  const [role, setRole] = useState(userData?.role || "");
  const [institution, setInstitution] = useState(userData?.institution || "");
  const [country, setCountry] = useState(userData?.country || "");
  const [image, setImage] = useState(null);
  const [user, setUser] = useState(null); // Store the authenticated user
  const [loading, setLoading] = useState(true); // Loading state for when authentication is in progress

  // Ensure userData updates the local state when it's passed as a prop
  useEffect(() => {
    if (userData) {
      setDescription(userData.description || "");
      setRole(userData.role || "");
      setInstitution(userData.institution || "");
      setCountry(userData.country || "");
    }
  }, [userData]);

  // Monitor authentication state
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (authUser) => {
      if (authUser) {
        setUser(authUser); // Set the authenticated user
      } else {
        setUser(null); // No authenticated user
      }
      setLoading(false); // Authentication check completed
    });

    // Clean up the listener when the component unmounts
    return () => unsubscribe();
  }, []);

  // Handle image selection
  const handleImageChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setImage(e.target.files[0]); // Set the image file in state
    }
  };

  // Handle profile update
  const handleUpdate = async () => {
    if (!user || !user.uid) {
      toast.error("User is not authenticated.");
      return;
    }

    try {
      const userDoc = doc(fireDB, "users", user.uid);

      let updates = {
        description,
        role,
        institution,
        country,
      };

      // Upload image if selected
      if (image) {
        const imageRef = ref(storage, `profileImages/${user.uid}`);
        await uploadBytes(imageRef, image);
        const imageUrl = await getDownloadURL(imageRef);
        updates = { ...updates, profileImage: imageUrl }; // Add image URL to updates
      }

      // Update user document in Firestore
      await updateDoc(userDoc, updates);
      toast.success("Profile updated successfully");

      onClose(); // Optionally close the modal
    } catch (error) {
      console.error("Error updating profile:", error);
      toast.error("Failed to update profile. Please try again.");
    }
  };

  if (loading) {
    return <p>Loading...</p>; // Show loading state if user is not authenticated yet
  }

  if (!user) {
    return <p>You must be logged in to update your profile.</p>; // If no user is authenticated, show this message
  }

  return (
    <div className="profile-update-modal">
      <div className="profile-update-content">
        <h2>Update Profile</h2>
        <input
          type="text"
          placeholder="Description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        <input
          type="text"
          placeholder="Role"
          value={role}
          onChange={(e) => setRole(e.target.value)}
        />
        <input
          type="text"
          placeholder="Institution"
          value={institution}
          onChange={(e) => setInstitution(e.target.value)}
        />
        <input
          type="text"
          placeholder="Country"
          value={country}
          onChange={(e) => setCountry(e.target.value)}
        />
        <input type="file" accept="image/*" onChange={handleImageChange} />
        <button onClick={handleUpdate}>Update Profile</button>
        <button onClick={onClose}>Cancel</button>
      </div>
    </div>
  );
};

export default ProfileUpdate;
