const calculusquestions = [
  {
    question: "If ( A = {1, 2, 3} ) and ( B = {2, 3, 4} ), find ( A cup B ).",
    answerOptions: [
      { answer: "{1, 2, 3, 4}", isCorrect: true },
      { answer: "{2, 3}", isCorrect: false },
      { answer: "{1, 2, 3}", isCorrect: false },
      { answer: "{1, 4}", isCorrect: false },
    ],
  },
  {
    question: "Determine the domain of the function ( f(x) = \frac{1}{x-2} ).",
    answerOptions: [
      { answer: "( x > 2 )", isCorrect: false },
      { answer: "( x < 2 )", isCorrect: false },
      { answer: "( x \neq 2 )", isCorrect: true },
      { answer: "( x geq 2 )", isCorrect: false },
    ],
  },
  {
    question: "Find the limit: ( lim_{x \to 0} \frac{sin(x)}{x} ).",
    answerOptions: [
      { answer: "1", isCorrect: true },
      { answer: "0", isCorrect: false },
      { answer: "Infinity", isCorrect: false },
      { answer: "Undefined", isCorrect: false },
    ],
  },
  {
    question: "Is the function ( f(x) = |x| ) continuous at ( x = 0 )?",
    answerOptions: [
      { answer: "No", isCorrect: false },
      { answer: "Undefined", isCorrect: false },
      { answer: "Yes", isCorrect: true },
      { answer: "Discontinuous", isCorrect: false },
    ],
  },
  {
    question:
      "Determine if the function ( f(x) = x^2 ) is differentiable at ( x = 0 ).",
    answerOptions: [
      { answer: "Yes", isCorrect: true },
      { answer: "No", isCorrect: false },
      { answer: "Only from the right", isCorrect: false },
      { answer: "Only from the left", isCorrect: false },
    ],
  },
  {
    question: "Find the derivative of ( f(x) = 3x^2 + 2x + 1 ).",
    answerOptions: [
      { answer: "6x + 2", isCorrect: true },
      { answer: "6x + 1", isCorrect: false },
      { answer: "3x + 2", isCorrect: false },
      { answer: "2x + 1", isCorrect: false },
    ],
  },
  {
    question: "Evaluate the indefinite integral ( int 2x , dx ).",
    answerOptions: [
      { answer: "x^2 + C", isCorrect: true },
      { answer: "x + C", isCorrect: false },
      { answer: "2x + C", isCorrect: false },
      { answer: "2x^2 + C", isCorrect: false },
    ],
  },
  {
    question: "Find the definite integral ( int_{0}^{2} x^2 , dx ).",
    answerOptions: [
      { answer: "4", isCorrect: false },
      { answer: "\frac{4}{3}", isCorrect: false },
      { answer: "\frac{8}{3}", isCorrect: true },
      { answer: "2", isCorrect: false },
    ],
  },
  {
    question: "Evaluate ( int_0^pi sin(x) , dx ).",
    answerOptions: [
      { answer: "2", isCorrect: true },
      { answer: "1", isCorrect: false },
      { answer: "-1", isCorrect: false },
      { answer: "0", isCorrect: false },
    ],
  },
  {
    question: "Find the inverse of the function ( f(x) = 3x + 2 ).",
    answerOptions: [
      { answer: "f^{-1}(x) = \frac{x+2}{3}", isCorrect: false },
      { answer: "f^{-1}(x) = \frac{x-3}{2}", isCorrect: false },
      { answer: "f^{-1}(x) = \frac{x-2}{3}", isCorrect: true },
      { answer: "f^{-1}(x) = \frac{x-2}{2}", isCorrect: false },
    ],
  },
  {
    question: "Find the derivative of ( f(x) = e^x ).",
    answerOptions: [
      { answer: "e^x", isCorrect: true },
      { answer: "xe^x", isCorrect: false },
      { answer: "e^{x+1}", isCorrect: false },
      { answer: "e^{x-1}", isCorrect: false },
    ],
  },
  {
    question: "Evaluate the indefinite integral ( int cos(x) , dx ).",
    answerOptions: [
      { answer: "sin(x) + C", isCorrect: true },
      { answer: "-sin(x) + C", isCorrect: false },
      { answer: "cos(x) + C", isCorrect: false },
      { answer: "-cos(x) + C", isCorrect: false },
    ],
  },
  {
    question:
      "Find the value of ( lim_{x \to infty} \frac{2x^2 + 3}{x^2 + 5} ).",
    answerOptions: [
      { answer: "Infinity", isCorrect: false },
      { answer: "2", isCorrect: true },
      { answer: "3", isCorrect: false },
      { answer: "0", isCorrect: false },
    ],
  },
  {
    question:
      "Determine whether the function ( f(x) = \frac{1}{x-1} ) is continuous at ( x = 1 ).",
    answerOptions: [
      { answer: "No", isCorrect: true },
      { answer: "Yes", isCorrect: false },
      { answer: "Only from the left", isCorrect: false },
      { answer: "Only from the right", isCorrect: false },
    ],
  },
  {
    question: "Find the derivative of ( f(x) = ln(x) ).",
    answerOptions: [
      { answer: "x", isCorrect: false },
      { answer: "\frac{1}{x}", isCorrect: true },
      { answer: "ln(x)", isCorrect: false },
      { answer: "\frac{1}{ln(x)}", isCorrect: false },
    ],
  },
  {
    question: "Evaluate ( int \frac{1}{x} , dx ).",
    answerOptions: [
      { answer: "ln|x| + C", isCorrect: true },
      { answer: "ln(x) + C", isCorrect: false },
      { answer: "\frac{1}{x} + C", isCorrect: false },
      { answer: "ln(x^2) + C", isCorrect: false },
    ],
  },
  {
    question:
      "Let ( A = \\{1, 2, 3\\} ) and ( B = \\{4, 5\\} ). Find the number of possible relations from set ( A ) to set ( B ).",
    answerOptions: [
      { answer: "32", isCorrect: true },
      { answer: "16", isCorrect: false },
      { answer: "64", isCorrect: false },
      { answer: "8", isCorrect: false },
    ],
  },
  {
    question: "If ( f(x) = x^2 + 3x + 2 ), find ( f(f(2)) ).",
    answerOptions: [
      { answer: "24", isCorrect: true },
      { answer: "26", isCorrect: false },
      { answer: "16", isCorrect: false },
      { answer: "22", isCorrect: false },
    ],
  },
  {
    question: "Evaluate ( lim_{x \to 2} \frac{x^2 - 4}{x - 2} ).",
    answerOptions: [
      { answer: "0", isCorrect: false },
      { answer: "2", isCorrect: false },
      { answer: "4", isCorrect: true },
      { answer: "8", isCorrect: false },
    ],
  },
  {
    question: "Find the derivative of ( f(x) = sin(x) cdot e^x ).",
    answerOptions: [
      { answer: "( e^x (cos(x) + sin(x)) )", isCorrect: true },
      { answer: "( e^x (cos(x) - sin(x)) )", isCorrect: false },
      { answer: "( cos(x) cdot e^x )", isCorrect: false },
      { answer: "( sin(x) cdot e^x )", isCorrect: false },
    ],
  },
  {
    question: "Evaluate ( int (3x^2 + 2x + 1) , dx ).",
    answerOptions: [
      { answer: "( x^3 + x^2 + 1 + C )", isCorrect: false },
      { answer: "( x^3 + x^2 + x + C )", isCorrect: true },
      { answer: "( 3x^3 + x^2 + C )", isCorrect: false },
      { answer: "( x^3 + 2x^2 + x + C )", isCorrect: false },
    ],
  },
  {
    question:
      "Determine whether the function ( f(x) = |x| ) is differentiable at ( x = 0 ).",
    answerOptions: [
      { answer: "Yes", isCorrect: false },
      { answer: "Not differentiable", isCorrect: false },
      { answer: "Not continuous", isCorrect: false },
      { answer: "No", isCorrect: true },
    ],
  },
  {
    question: "Evaluate the limit ( lim_{x \to 0} \frac{sin(2x)}{x} ).",
    answerOptions: [
      { answer: "1", isCorrect: false },
      { answer: "0", isCorrect: false },
      { answer: "4", isCorrect: false },
      { answer: "2", isCorrect: true },
    ],
  },
  {
    question: "If ( f(x) = sqrt{x + 2} ), find the domain of ( f(x) ).",
    answerOptions: [
      { answer: "(-infty < x leq 2)", isCorrect: false },
      { answer: "(-2 < x < infty)", isCorrect: false },
      { answer: "(-2 leq x < infty)", isCorrect: true },
      { answer: "(-infty < x < 2)", isCorrect: false },
    ],
  },
  {
    question: "Evaluate ( int_0^pi sin(x) , dx ).",
    answerOptions: [
      { answer: "2", isCorrect: true },
      { answer: "0", isCorrect: false },
      { answer: "1", isCorrect: false },
      { answer: "(pi)", isCorrect: false },
    ],
  },
  {
    question: "Find the range of the function ( f(x) = e^{x^2 - 1} ).",
    answerOptions: [
      { answer: "[0, ∞)", isCorrect: false },
      { answer: "(1, ∞)", isCorrect: false },
      { answer: "(0, ∞)", isCorrect: true },
      { answer: "(-∞, 0)", isCorrect: false },
    ],
  },
  {
    question: "Evaluate ( lim_{x \to 1} \frac{x^3 - 1}{x - 1} ).",
    answerOptions: [
      { answer: "3", isCorrect: true },
      { answer: "2", isCorrect: false },
      { answer: "1", isCorrect: false },
      { answer: "0", isCorrect: false },
    ],
  },
  {
    question:
      "Find the value of ( c ) such that the function ( f(x) = cx^2 ) is continuous at ( x = 0 ).",
    answerOptions: [
      { answer: "c = 0", isCorrect: false },
      { answer: "c = 1", isCorrect: false },
      { answer: "c = 2", isCorrect: false },
      { answer: "Any value of c", isCorrect: true },
    ],
  },
  {
    question: "Evaluate the definite integral ( int_1^2 (3x + 1) , dx ).",
    answerOptions: [
      { answer: "7", isCorrect: true },
      { answer: "5", isCorrect: false },
      { answer: "8", isCorrect: false },
      { answer: "6", isCorrect: false },
    ],
  },
  {
    question: "Find the derivative of ( f(x) = x^2 ln(x) ).",
    answerOptions: [
      { answer: "( 2x ln(x) - x )", isCorrect: false },
      { answer: "( x^2 )", isCorrect: false },
      { answer: "( ln(x) )", isCorrect: false },
      { answer: "( 2x ln(x) + x )", isCorrect: true },
    ],
  },
  {
    question: "Evaluate ( int \frac{1}{x^2 + 4} , dx ).",
    answerOptions: [
      {
        answer: "( \frac{1}{2} \tan^{-1}left(\frac{x}{2}\right) + C )",
        isCorrect: true,
      },
      {
        answer: "( \frac{1}{4} \tan^{-1}left(\frac{x}{2}\right) + C )",
        isCorrect: false,
      },
      { answer: "( \frac{1}{2} \tan^{-1}(x) + C )", isCorrect: false },
      { answer: "( \frac{1}{4} \tan^{-1}(x) + C )", isCorrect: false },
    ],
  },
  {
    question: "If ( f(x) = x^3 - 3x^2 + 5 ), find ( f'(x) ).",
    answerOptions: [
      { answer: "( x^2 - 3x )", isCorrect: false },
      { answer: "( 3x^2 - 3x )", isCorrect: false },
      { answer: "( 3x^2 - 6x )", isCorrect: true },
      { answer: "( 3x - 6 )", isCorrect: false },
    ],
  },
  {
    question: "Evaluate ( int_0^1 (2x + 1) , dx ).",
    answerOptions: [
      { answer: "1", isCorrect: false },
      { answer: "1.5", isCorrect: true },
      { answer: "0.5", isCorrect: false },
      { answer: "2", isCorrect: false },
    ],
  },
  {
    question: "Find the value of ( lim_{x \to 0} \frac{ln(1 + x)}{x} ).",
    answerOptions: [
      { answer: "1", isCorrect: true },
      { answer: "0", isCorrect: false },
      { answer: "2", isCorrect: false },
      { answer: "Infinity", isCorrect: false },
    ],
  },
  {
    question: "If ( f(x) = e^x ), find ( f''(x) ).",
    answerOptions: [
      { answer: "2e^x", isCorrect: false },
      { answer: "xe^x", isCorrect: false },
      { answer: "e^x", isCorrect: true },
      { answer: "e^x + 1", isCorrect: false },
    ],
  },
  {
    question: "Find the derivative of the function f(x) = 3x³ - 5x² + 6x - 4.",
    answerOptions: [
      { answer: "9x² - 10x + 6", isCorrect: true },
      { answer: "9x² - 10x + 4", isCorrect: false },
      { answer: "9x² - 5x + 6", isCorrect: false },
      { answer: "6x² - 10x + 4", isCorrect: false },
    ],
  },
  {
    question: "Evaluate the integral ∫ (2x³ - 4x² + x - 3) dx.",
    answerOptions: [
      { answer: "0.5x⁴ - (4/3)x³ + 0.5x² - 3x + C", isCorrect: true },
      { answer: "2x⁴ - (4/3)x³ + x² - 3x + C", isCorrect: false },
      { answer: "x⁴ - (4/3)x³ + x² - 3x + C", isCorrect: false },
      { answer: "x⁴ - 4x³ + x² - 3x + C", isCorrect: false },
    ],
  },
  {
    question: "Find the second derivative of the function f(x) = e^x sin(x).",
    answerOptions: [
      { answer: "e^x (sin(x) + cos(x))", isCorrect: true },
      { answer: "e^x (sin(x) - cos(x))", isCorrect: false },
      { answer: "e^x (2sin(x) + cos(x))", isCorrect: false },
      { answer: "e^x (2sin(x) - cos(x))", isCorrect: false },
    ],
  },
  {
    question: "Determine the integral ∫ (5x² - 3x + 1)² dx.",
    answerOptions: [
      { answer: "1/3x³ - 2x² + x + C", isCorrect: true },
      { answer: "1/3x³ - 2x² + x + 1", isCorrect: false },
      { answer: "1/5x³ - 3x² + x + C", isCorrect: false },
      { answer: "1/4x³ - 2x² + x + C", isCorrect: false },
    ],
  },
  {
    question: "If f(x) = x² + 2x + 1, find f''(x).",
    answerOptions: [
      { answer: "2", isCorrect: true },
      { answer: "1", isCorrect: false },
      { answer: "0", isCorrect: false },
      { answer: "-2", isCorrect: false },
    ],
  },
  {
    question: "Evaluate the integral ∫ (sin(x) cos(x)) dx.",
    answerOptions: [
      { answer: "0.5 sin²(x) + C", isCorrect: true },
      { answer: "-0.5 sin²(x) + C", isCorrect: false },
      { answer: "0.5 cos²(x) + C", isCorrect: false },
      { answer: "-0.5 cos²(x) + C", isCorrect: false },
    ],
  },
  {
    question: "Find the integral of the function f(x) = x² e^x.",
    answerOptions: [
      { answer: "e^x (x² - 2x + 2) + C", isCorrect: true },
      { answer: "e^x (x² + 2x + 2) + C", isCorrect: false },
      { answer: "e^x (x² - x + 1) + C", isCorrect: false },
      { answer: "e^x (x² + x + 1) + C", isCorrect: false },
    ],
  },
  {
    question: "Find the derivative of the function f(x) = ln(x² + 1).",
    answerOptions: [
      { answer: "2x / (x² + 1)", isCorrect: true },
      { answer: "x / (x² + 1)", isCorrect: false },
      { answer: "2 / (x² + 1)", isCorrect: false },
      { answer: "1 / (x² + 1)", isCorrect: false },
    ],
  },
  {
    question: "Evaluate the integral ∫ (3x² - 2x + 1) dx from 1 to 2.",
    answerOptions: [
      { answer: "9", isCorrect: true },
      { answer: "8", isCorrect: false },
      { answer: "7", isCorrect: false },
      { answer: "6", isCorrect: false },
    ],
  },
  {
    question:
      "Determine the derivative of the function f(x) = (x³ + 3x) / (x² + 1).",
    answerOptions: [
      { answer: "(x³ - 3x + 3) / (x² + 1)²", isCorrect: true },
      { answer: "(x³ - 3x + 1) / (x² + 1)²", isCorrect: false },
      { answer: "(x³ + 3x - 1) / (x² + 1)²", isCorrect: false },
      { answer: "(x³ + 3x - 3) / (x² + 1)²", isCorrect: false },
    ],
  },
  {
    question: "Find the integral of f(x) = (x² + 2x + 3) / (x + 1).",
    answerOptions: [
      { answer: "x + 2 + 3 ln|x + 1| + C", isCorrect: true },
      { answer: "x + 1 + 3 ln|x + 1| + C", isCorrect: false },
      { answer: "x + 3 + 2 ln|x + 1| + C", isCorrect: false },
      { answer: "x + 3 + ln|x + 1| + C", isCorrect: false },
    ],
  },
  {
    question: "Differentiate the function f(x) = x e^(-x).",
    answerOptions: [
      { answer: "e^(-x) - x e^(-x)", isCorrect: true },
      { answer: "-e^(-x) - x e^(-x)", isCorrect: false },
      { answer: "e^(-x) + x e^(-x)", isCorrect: false },
      { answer: "-e^(-x) + x e^(-x)", isCorrect: false },
    ],
  },
  {
    question: "Evaluate the integral ∫ (x e^x) dx.",
    answerOptions: [
      { answer: "x e^x - e^x + C", isCorrect: true },
      { answer: "x e^x + e^x + C", isCorrect: false },
      { answer: "x e^x - e^x", isCorrect: false },
      { answer: "x e^x + e^x", isCorrect: false },
    ],
  },
  {
    question: "If f(x) = x² sin(x), find f'(x).",
    answerOptions: [
      { answer: "x² cos(x) + 2x sin(x)", isCorrect: true },
      { answer: "x² cos(x) - 2x sin(x)", isCorrect: false },
      { answer: "2x cos(x) - x² sin(x)", isCorrect: false },
      { answer: "2x cos(x) + x² sin(x)", isCorrect: false },
    ],
  },
  {
    question:
      "Find the area under the curve y = x³ - 3x² + 2 from x = 0 to x = 2.",
    answerOptions: [
      { answer: "4", isCorrect: true },
      { answer: "6", isCorrect: false },
      { answer: "8", isCorrect: false },
      { answer: "2", isCorrect: false },
    ],
  },
  {
    question: "Find the derivative of f(x) = ln(x² + 2x + 2).",
    answerOptions: [
      { answer: "2x + 2 / (x² + 2x + 2)", isCorrect: true },
      { answer: "2x / (x² + 2x + 2)", isCorrect: false },
      { answer: "2 / (x² + 2x + 2)", isCorrect: false },
      { answer: "2x + 1 / (x² + 2x + 2)", isCorrect: false },
    ],
  },
  {
    question: "Evaluate the integral ∫ (x² + 2x + 1) dx.",
    answerOptions: [
      { answer: "x³/3 + x² + x + C", isCorrect: true },
      { answer: "x³/3 + 2x² + x + C", isCorrect: false },
      { answer: "x³/3 + 2x² + 2x + C", isCorrect: false },
      { answer: "x³/3 + 2x² + C", isCorrect: false },
    ],
  },
  {
    question: "Differentiate the function f(x) = e^(2x) sin(x).",
    answerOptions: [
      { answer: "2e^(2x) sin(x) + e^(2x) cos(x)", isCorrect: true },
      { answer: "e^(2x) (2 sin(x) + cos(x))", isCorrect: false },
      { answer: "2e^(2x) cos(x) - e^(2x) sin(x)", isCorrect: false },
      { answer: "2e^(2x) cos(x) + e^(2x) sin(x)", isCorrect: false },
    ],
  },
  {
    question: "Find the integral ∫ (x³ - 4x + 2) dx from 0 to 1.",
    answerOptions: [
      { answer: "0", isCorrect: true },
      { answer: "1", isCorrect: false },
      { answer: "-1", isCorrect: false },
      { answer: "2", isCorrect: false },
    ],
  },
  {
    question: "Find the area of the region bounded by y = x² and y = 4.",
    answerOptions: [
      { answer: "8/3", isCorrect: true },
      { answer: "16/3", isCorrect: false },
      { answer: "4", isCorrect: false },
      { answer: "2", isCorrect: false },
    ],
  },
  {
    question:
      "Find the derivative of the function f(x) = (2x² - 3x + 1) / (x + 2).",
    answerOptions: [
      { answer: "(-x² + 7x - 6) / (x + 2)²", isCorrect: true },
      { answer: "(-x² + 5x - 3) / (x + 2)²", isCorrect: false },
      { answer: "(-x² + 5x + 1) / (x + 2)²", isCorrect: false },
      { answer: "(-x² + 6x - 4) / (x + 2)²", isCorrect: false },
    ],
  },
  {
    question: "Evaluate the integral ∫ (cos(x) / x) dx.",
    answerOptions: [
      { answer: "Integral does not have a closed form", isCorrect: true },
      { answer: "sin(x) / x", isCorrect: false },
      { answer: "cos(x) + C", isCorrect: false },
      { answer: "ln|x| + C", isCorrect: false },
    ],
  },
  {
    question: "Find the derivative of f(x) = x³ ln(x).",
    answerOptions: [
      { answer: "3x² ln(x) + x²", isCorrect: true },
      { answer: "3x² ln(x) + x", isCorrect: false },
      { answer: "x³ / ln(x)", isCorrect: false },
      { answer: "x³ (1/x + ln(x))", isCorrect: false },
    ],
  },
  {
    question: "If f(x) = e^(-x²), find f'(x).",
    answerOptions: [
      { answer: "-2x e^(-x²)", isCorrect: true },
      { answer: "2x e^(-x²)", isCorrect: false },
      { answer: "-e^(-x²)", isCorrect: false },
      { answer: "e^(-x²)", isCorrect: false },
    ],
  },
  {
    question:
      "Find the area under the curve y = x² - 2x + 1 from x = 1 to x = 3.",
    answerOptions: [
      { answer: "4", isCorrect: true },
      { answer: "6", isCorrect: false },
      { answer: "8", isCorrect: false },
      { answer: "10", isCorrect: false },
    ],
  },
  {
    question: "Find the critical points of the function f(x) = x³ - 3x² + 2x.",
    answerOptions: [
      { answer: "x = 0, x = 1, x = 2", isCorrect: true },
      { answer: "x = -1, x = 1, x = 2", isCorrect: false },
      { answer: "x = 0, x = 1", isCorrect: false },
      { answer: "x = 0, x = 1", isCorrect: false },
    ],
  },
  {
    question:
      "Determine the intervals where the function f(x) = x³ - 6x² + 9x is increasing.",
    answerOptions: [
      { answer: "x > 3", isCorrect: true },
      { answer: "x < 3", isCorrect: false },
      { answer: "x > 2 and x < 4", isCorrect: false },
      { answer: "x < 2 and x > 4", isCorrect: false },
    ],
  },
  {
    question:
      "Find the local maxima and minima of the function f(x) = -x² + 4x - 3.",
    answerOptions: [
      { answer: "Maxima at x = 2, minima at none", isCorrect: true },
      { answer: "Minima at x = 2, maxima at none", isCorrect: false },
      { answer: "Maxima at x = 2, minima at x = 0", isCorrect: false },
      { answer: "Minima at x = 2, maxima at x = 0", isCorrect: false },
    ],
  },
  {
    question:
      "For the function f(x) = 2x² - 4x + 1, determine the nature of the function at x = 1.",
    answerOptions: [
      { answer: "Minimum", isCorrect: true },
      { answer: "Maximum", isCorrect: false },
      { answer: "Point of inflection", isCorrect: false },
      { answer: "Neither", isCorrect: false },
    ],
  },
  {
    question:
      "Find the intervals where the function f(x) = x⁴ - 4x³ is decreasing.",
    answerOptions: [
      { answer: "0 < x < 3", isCorrect: true },
      { answer: "x > 3", isCorrect: false },
      { answer: "x < 0", isCorrect: false },
      { answer: "x < 3", isCorrect: false },
    ],
  },
  {
    question:
      "Find the global maximum and minimum values of f(x) = x³ - 3x² on the interval [0, 3].",
    answerOptions: [
      { answer: "Maximum at x = 3, minimum at x = 0", isCorrect: true },
      { answer: "Maximum at x = 0, minimum at x = 3", isCorrect: false },
      { answer: "Maximum and minimum both at x = 1", isCorrect: false },
      { answer: "Maximum at x = 1, minimum at x = 3", isCorrect: false },
    ],
  },
  {
    question: "Determine the local extrema of the function f(x) = x³ - 3x + 1.",
    answerOptions: [
      {
        answer: "Local maximum at x = -1, local minimum at x = 1",
        isCorrect: true,
      },
      {
        answer: "Local minimum at x = -1, local maximum at x = 1",
        isCorrect: false,
      },
      {
        answer: "Local maximum at x = 1, local minimum at x = -1",
        isCorrect: false,
      },
      { answer: "No local extrema", isCorrect: false },
    ],
  },
  {
    question:
      "For the function f(x) = x² - 2x + 2, find the vertex and determine if it's a maximum or minimum.",
    answerOptions: [
      { answer: "Vertex at (1, 1), minimum", isCorrect: true },
      { answer: "Vertex at (1, 0), maximum", isCorrect: false },
      { answer: "Vertex at (0, 1), minimum", isCorrect: false },
      { answer: "Vertex at (2, 2), maximum", isCorrect: false },
    ],
  },
  {
    question:
      "Find the critical points and determine the nature (maxima or minima) for the function f(x) = sin(x) + cos(x).",
    answerOptions: [
      {
        answer:
          "Critical points at x = π/4 + nπ, minima at π/4, maxima at 5π/4",
        isCorrect: true,
      },
      {
        answer:
          "Critical points at x = π/2 + nπ, minima at π/2, maxima at 3π/2",
        isCorrect: false,
      },
      {
        answer: "Critical points at x = nπ, maxima at 0, minima at π",
        isCorrect: false,
      },
      {
        answer: "Critical points at x = nπ, minima at π/2, maxima at 3π/2",
        isCorrect: false,
      },
    ],
  },
  {
    question:
      "For the function f(x) = e^x - 2e^(-x), find the critical points and determine if they are maxima or minima.",
    answerOptions: [
      { answer: "Critical point at x = 0, local maximum", isCorrect: true },
      { answer: "Critical point at x = 0, local minimum", isCorrect: false },
      {
        answer: "Critical points at x = ±1, local maximum at x = -1",
        isCorrect: false,
      },
      {
        answer: "Critical points at x = ±1, local minimum at x = 1",
        isCorrect: false,
      },
    ],
  },
  {
    question:
      "For the function f(x) = x⁴ - 4x², find and classify the critical points.",
    answerOptions: [
      {
        answer: "Critical points at x = ±√2, minima at x = ±√2",
        isCorrect: true,
      },
      {
        answer: "Critical points at x = ±2, maxima at x = ±2",
        isCorrect: false,
      },
      { answer: "Critical points at x = 0, maxima at x = 0", isCorrect: false },
      {
        answer: "Critical points at x = ±1, minima at x = ±1",
        isCorrect: false,
      },
    ],
  },
  {
    question: "Find the derivative of the function f(x) = 3x² - 5x + 2.",
    answerOptions: [
      { answer: "6x - 5", isCorrect: true },
      { answer: "6x + 5", isCorrect: false },
      { answer: "3x - 5", isCorrect: false },
      { answer: "6x - 2", isCorrect: false },
    ],
  },
  {
    question: "Evaluate the integral ∫ (2x³ - x + 1) dx.",
    answerOptions: [
      { answer: "0.5x⁴ - 0.5x² + x + C", isCorrect: true },
      { answer: "0.5x⁴ - x² + x + C", isCorrect: false },
      { answer: "x⁴ - 0.5x² + x + C", isCorrect: false },
      { answer: "x³ - 0.5x² + x + C", isCorrect: false },
    ],
  },
  {
    question:
      "Find the local maxima and minima of the function f(x) = x³ - 6x² + 9x.",
    answerOptions: [
      {
        answer: "Local maximum at x = 1, local minimum at x = 3",
        isCorrect: true,
      },
      {
        answer: "Local minimum at x = 1, local maximum at x = 3",
        isCorrect: false,
      },
      {
        answer: "Local maximum at x = 2, local minimum at x = 4",
        isCorrect: false,
      },
      { answer: "No local extrema", isCorrect: false },
    ],
  },
  {
    question: "Find the integral of f(x) = e^x from 0 to 1.",
    answerOptions: [
      { answer: "e - 1", isCorrect: true },
      { answer: "e", isCorrect: false },
      { answer: "1", isCorrect: false },
      { answer: "e - 2", isCorrect: false },
    ],
  },
  {
    question:
      "Determine the points where the function f(x) = x² - 4x + 4 has an inflection point.",
    answerOptions: [
      { answer: "No inflection points", isCorrect: true },
      { answer: "At x = 0", isCorrect: false },
      { answer: "At x = 2", isCorrect: false },
      { answer: "At x = -2", isCorrect: false },
    ],
  },
  {
    question: "Find the derivative of the function f(x) = sin(x)cos(x).",
    answerOptions: [
      { answer: "cos²(x) - sin²(x)", isCorrect: true },
      { answer: "2sin(x)cos(x)", isCorrect: false },
      { answer: "-sin²(x) + cos²(x)", isCorrect: false },
      { answer: "-sin(x)cos(x)", isCorrect: false },
    ],
  },
  {
    question: "Evaluate the integral ∫ (3x² - 2x) dx.",
    answerOptions: [
      { answer: "x³ - x² + C", isCorrect: true },
      { answer: "x³ - x² - C", isCorrect: false },
      { answer: "3x³ - x² + C", isCorrect: false },
      { answer: "x³ - 2x² + C", isCorrect: false },
    ],
  },
  {
    question:
      "Find the area under the curve of f(x) = 4 - x² from x = -2 to x = 2.",
    answerOptions: [
      { answer: "16/3", isCorrect: true },
      { answer: "8", isCorrect: false },
      { answer: "16", isCorrect: false },
      { answer: "4/3", isCorrect: false },
    ],
  },
  {
    question:
      "Determine the interval where the function f(x) = x³ - 3x + 2 is increasing.",
    answerOptions: [
      { answer: "x > 1", isCorrect: true },
      { answer: "x < 1", isCorrect: false },
      { answer: "x > 2", isCorrect: false },
      { answer: "x < -1", isCorrect: false },
    ],
  },
  {
    question: "Find the local minimum of the function f(x) = 2x² - 4x + 1.",
    answerOptions: [
      { answer: "x = 1", isCorrect: true },
      { answer: "x = 0", isCorrect: false },
      { answer: "x = 2", isCorrect: false },
      { answer: "x = -1", isCorrect: false },
    ],
  },
  {
    question: "Evaluate the integral ∫ (2x + 3)/(x² + 1) dx.",
    answerOptions: [
      { answer: "2ln|x² + 1| + 3arctan(x) + C", isCorrect: true },
      { answer: "2ln|x + 1| + 3arctan(x) + C", isCorrect: false },
      { answer: "2ln|x² + 1| + 3x + C", isCorrect: false },
      { answer: "2ln|x² + 1| + 3arcsin(x) + C", isCorrect: false },
    ],
  },
  {
    question: "Evaluate the limit: lim (x → 0) [ (sin(x) - x) / x³ ].",
    answerOptions: [
      { answer: "-1/6", isCorrect: true },
      { answer: "0", isCorrect: false },
      { answer: "1/6", isCorrect: false },
      { answer: "-1/2", isCorrect: false },
    ],
  },
  {
    question:
      "Find the local extrema of the function f(x) = x³ - 6x² + 9x + 1.",
    answerOptions: [
      {
        answer: "Local minimum at x = 1 and local maximum at x = 3",
        isCorrect: true,
      },
      {
        answer: "Local maximum at x = 1 and local minimum at x = 3",
        isCorrect: false,
      },
      { answer: "No local extrema", isCorrect: false },
      {
        answer: "Local minimum at x = 2 and local maximum at x = 4",
        isCorrect: false,
      },
    ],
  },
  {
    question:
      "Determine the convergence of the integral ∫ (1 / (x² + 4)) dx from -∞ to ∞.",
    answerOptions: [
      { answer: "Convergent", isCorrect: true },
      { answer: "Divergent", isCorrect: false },
      { answer: "Convergent conditionally", isCorrect: false },
      { answer: "Convergent absolutely", isCorrect: false },
    ],
  },
  {
    question:
      "Find the Taylor series expansion of f(x) = e^(-x²) around x = 0.",
    answerOptions: [
      { answer: "1 - x² + x⁴/2! - x⁶/3! + ...", isCorrect: true },
      { answer: "1 - x²/2 + x⁴/4 - x⁶/6 + ...", isCorrect: false },
      { answer: "1 + x² - x⁴/2 + x⁶/6 + ...", isCorrect: false },
      { answer: "1 - x²/2 + x⁴/4 - x⁶/8 + ...", isCorrect: false },
    ],
  },
  {
    question: "Find the integral of the function f(x) = x² * e^x dx.",
    answerOptions: [
      { answer: "x² * e^x - 2x * e^x + 2e^x + C", isCorrect: true },
      { answer: "x² * e^x - 2x * e^x + e^x + C", isCorrect: false },
      { answer: "x² * e^x - 2e^x + C", isCorrect: false },
      { answer: "x² * e^x + 2x * e^x + C", isCorrect: false },
    ],
  },
  {
    question:
      "Find the point(s) where the function f(x) = ln(x) - x² has an inflection point.",
    answerOptions: [
      { answer: "x = 1", isCorrect: true },
      { answer: "x = 0", isCorrect: false },
      { answer: "x = -1", isCorrect: false },
      { answer: "x = 2", isCorrect: false },
    ],
  },
  {
    question: "Solve the differential equation dy/dx = x * y + y².",
    answerOptions: [
      { answer: "y = 1 / (C - x² - 2x)", isCorrect: true },
      { answer: "y = 1 / (C + x² + 2x)", isCorrect: false },
      { answer: "y = 1 / (C + x² - 2x)", isCorrect: false },
      { answer: "y = 1 / (C - x² + 2x)", isCorrect: false },
    ],
  },
  {
    question:
      "Find the volume of the solid obtained by rotating the region bounded by y = x² and y = 4x around the x-axis.",
    answerOptions: [
      { answer: "64π / 15", isCorrect: true },
      { answer: "32π / 15", isCorrect: false },
      { answer: "16π / 15", isCorrect: false },
      { answer: "64π / 30", isCorrect: false },
    ],
  },
  {
    question:
      "Evaluate the double integral ∫∫_R (x² + y²) dA where R is the region bounded by x² + y² = 4.",
    answerOptions: [
      { answer: "16π", isCorrect: true },
      { answer: "8π", isCorrect: false },
      { answer: "4π", isCorrect: false },
      { answer: "32π", isCorrect: false },
    ],
  },
  {
    question:
      "Find the global maximum and minimum values of f(x) = x³ - 3x² + 2x on the interval [0, 3].",
    answerOptions: [
      {
        answer: "Global maximum at x = 0, global minimum at x = 3",
        isCorrect: true,
      },
      {
        answer: "Global maximum at x = 3, global minimum at x = 0",
        isCorrect: false,
      },
      {
        answer: "Global maximum at x = 1, global minimum at x = 2",
        isCorrect: false,
      },
      {
        answer: "Global maximum at x = 2, global minimum at x = 1",
        isCorrect: false,
      },
    ],
  },
  {
    question: "Find the length of the curve y = ln(x) from x = 1 to x = e.",
    answerOptions: [
      { answer: "e - 1", isCorrect: true },
      { answer: "e - 2", isCorrect: false },
      { answer: "2e - 1", isCorrect: false },
      { answer: "e² - 1", isCorrect: false },
    ],
  },
  {
    question: "Evaluate the limit: lim (x → ∞) [ (3x² - x) / (2x² + 5x - 1) ].",
    answerOptions: [
      { answer: "3/2", isCorrect: true },
      { answer: "1", isCorrect: false },
      { answer: "2", isCorrect: false },
      { answer: "3", isCorrect: false },
    ],
  },
  {
    question:
      "Find the derivative of the function f(x) = (x³ + 2x² + 1) * e^x.",
    answerOptions: [
      { answer: "e^x * (x³ + 5x² + 5x + 1)", isCorrect: true },
      { answer: "e^x * (x³ + 2x² + 1)", isCorrect: false },
      { answer: "e^x * (x³ + 2x² + 3x + 1)", isCorrect: false },
      { answer: "e^x * (x³ + 2x² + 1 + x)", isCorrect: false },
    ],
  },
  {
    question: "Find the integral ∫ (x² + 3x + 2) / (x + 1) dx.",
    answerOptions: [
      { answer: "x² + x + C", isCorrect: true },
      { answer: "x² + 2x + C", isCorrect: false },
      { answer: "x² + 3x + C", isCorrect: false },
      { answer: "x² + x + 1 + C", isCorrect: false },
    ],
  },
  {
    question: "Evaluate the integral ∫ (x² + 2x + 1) * e^x dx.",
    answerOptions: [
      { answer: "e^x * (x² + 2x + 1) - 2e^x + C", isCorrect: true },
      { answer: "e^x * (x² + 2x + 2) - e^x + C", isCorrect: false },
      { answer: "e^x * (x² + 2x + 1) - e^x + C", isCorrect: false },
      { answer: "e^x * (x² + 2x) - e^x + C", isCorrect: false },
    ],
  },
  {
    question:
      "Find the local maxima and minima of the function f(x) = x³ - 6x² + 9x + 1.",
    answerOptions: [
      {
        answer: "Local maxima at x = 1 and local minima at x = 3",
        isCorrect: true,
      },
      {
        answer: "Local maxima at x = 3 and local minima at x = 1",
        isCorrect: false,
      },
      {
        answer: "Local maxima at x = 2 and local minima at x = 4",
        isCorrect: false,
      },
      {
        answer: "Local minima at x = 1 and local maxima at x = 3",
        isCorrect: false,
      },
    ],
  },
  {
    question: "Find the length of the curve y = ln(x) from x = 1 to x = e².",
    answerOptions: [
      { answer: "e² - 1", isCorrect: true },
      { answer: "e² - 2", isCorrect: false },
      { answer: "2e - 1", isCorrect: false },
      { answer: "e² - 1/e", isCorrect: false },
    ],
  },
  {
    question:
      "Find the volume of the solid formed by rotating the curve y = x³ about the x-axis from x = 0 to x = 1.",
    answerOptions: [
      { answer: "π/5", isCorrect: true },
      { answer: "π/4", isCorrect: false },
      { answer: "π/3", isCorrect: false },
      { answer: "π/6", isCorrect: false },
    ],
  },
  {
    question:
      "Find the maximum value of the function f(x) = 2x² - 4x + 3 on the interval [0, 2].",
    answerOptions: [
      { answer: "3", isCorrect: true },
      { answer: "2", isCorrect: false },
      { answer: "4", isCorrect: false },
      { answer: "5", isCorrect: false },
    ],
  },
  {
    question: "Evaluate the limit: lim (x → 0) [(e^x - 1) / x].",
    answerOptions: [
      { answer: "1", isCorrect: true },
      { answer: "0", isCorrect: false },
      { answer: "e", isCorrect: false },
      { answer: "1/e", isCorrect: false },
    ],
  },
  {
    question: "Solve the differential equation dy/dx = (x² + y²) / (x - y).",
    answerOptions: [
      { answer: "y = x + C", isCorrect: true },
      { answer: "y = -x + C", isCorrect: false },
      { answer: "y = x² + C", isCorrect: false },
      { answer: "y = -x² + C", isCorrect: false },
    ],
  },
  {
    question:
      "Find the global maximum and minimum values of f(x) = x³ - 3x² + 2x on the interval [0, 3].",
    answerOptions: [
      {
        answer: "Global maximum at x = 0, global minimum at x = 3",
        isCorrect: true,
      },
      {
        answer: "Global maximum at x = 3, global minimum at x = 0",
        isCorrect: false,
      },
      {
        answer: "Global maximum at x = 1, global minimum at x = 2",
        isCorrect: false,
      },
      {
        answer: "Global maximum at x = 2, global minimum at x = 1",
        isCorrect: false,
      },
    ],
  },
  {
    question: "Find the derivative of the function f(x) = sin²(x) + cos²(x).",
    answerOptions: [
      { answer: "0", isCorrect: true },
      { answer: "2sin(x)cos(x)", isCorrect: false },
      { answer: "sin(x) - cos(x)", isCorrect: false },
      { answer: "sin²(x) - cos²(x)", isCorrect: false },
    ],
  },
  {
    question: "Evaluate the integral ∫ (x³ - 2x² + x) e^x dx.",
    answerOptions: [
      { answer: "e^x (x³ - 3x² + 6x - 6) + C", isCorrect: true },
      { answer: "e^x (x³ - 2x² + x - 1) + C", isCorrect: false },
      { answer: "e^x (x³ - 2x² + x) + C", isCorrect: false },
      { answer: "e^x (x³ - x² + 1) + C", isCorrect: false },
    ],
  },
  {
    question: "Find the integral ∫ (x / (x² + 1)) dx.",
    answerOptions: [
      { answer: "1/2 * ln(x² + 1) + C", isCorrect: true },
      { answer: "ln(x² + 1) + C", isCorrect: false },
      { answer: "1/2 * ln(x² + 2) + C", isCorrect: false },
      { answer: "ln(x + 1) + C", isCorrect: false },
    ],
  },
  {
    question:
      "Find the critical points of the function f(x) = x³ - 3x² + 3x - 1.",
    answerOptions: [
      { answer: "x = 1", isCorrect: true },
      { answer: "x = 0 and x = 1", isCorrect: false },
      { answer: "x = 2 and x = 1", isCorrect: false },
      { answer: "x = 0 and x = 2", isCorrect: false },
    ],
  },
  {
    question:
      "Determine the radius of convergence of the power series ∑ (n = 1 to ∞) [x^n / n²].",
    answerOptions: [
      { answer: "∞", isCorrect: true },
      { answer: "1", isCorrect: false },
      { answer: "0", isCorrect: false },
      { answer: "2", isCorrect: false },
    ],
  },
  {
    question: "Find the length of the curve y = e^x from x = 0 to x = 1.",
    answerOptions: [
      { answer: "e - 1", isCorrect: true },
      { answer: "e - 2", isCorrect: false },
      { answer: "e² - 1", isCorrect: false },
      { answer: "e - e²", isCorrect: false },
    ],
  },
  {
    question: "Find the integral ∫ (1 / √(1 - x²)) dx.",
    answerOptions: [
      { answer: "arcsin(x) + C", isCorrect: true },
      { answer: "arccos(x) + C", isCorrect: false },
      { answer: "arctan(x) + C", isCorrect: false },
      { answer: "ln(x) + C", isCorrect: false },
    ],
  },
  {
    question:
      "Find the maximum and minimum values of the function f(x) = 2x³ - 9x² + 12x - 1.",
    answerOptions: [
      { answer: "Maximum at x = 1, minimum at x = 2", isCorrect: true },
      { answer: "Maximum at x = 2, minimum at x = 1", isCorrect: false },
      { answer: "Maximum at x = 3, minimum at x = 0", isCorrect: false },
      { answer: "Maximum at x = 0, minimum at x = 3", isCorrect: false },
    ],
  },
  {
    question:
      "Find the limit of the function f(x) = (x² - 1) / (x - 1) as x approaches 1.",
    answerOptions: [
      { answer: "2", isCorrect: true },
      { answer: "0", isCorrect: false },
      { answer: "1", isCorrect: false },
      { answer: "undefined", isCorrect: false },
    ],
  },
  {
    question:
      "Determine if the function f(x) = |x| / x is continuous at x = 0.",
    answerOptions: [
      { answer: "No, it is not continuous at x = 0", isCorrect: true },
      { answer: "Yes, it is continuous at x = 0", isCorrect: false },
      {
        answer: "It depends on the direction from which x approaches 0",
        isCorrect: false,
      },
      { answer: "The function is only defined for x ≠ 0", isCorrect: false },
    ],
  },
  {
    question: "Find the derivative of the function f(x) = x² * ln(x).",
    answerOptions: [
      { answer: "x² / x + 2x * ln(x)", isCorrect: true },
      { answer: "x * ln(x) + 2x²", isCorrect: false },
      { answer: "2x * ln(x) + x² / x", isCorrect: false },
      { answer: "x * ln(x) + 2x", isCorrect: false },
    ],
  },
  {
    question:
      "Determine the value of k for which the function f(x) = { kx + 2 for x < 1, 3x² - 4x + 1 for x ≥ 1 } is continuous at x = 1.",
    answerOptions: [
      { answer: "1", isCorrect: true },
      { answer: "2", isCorrect: false },
      { answer: "3", isCorrect: false },
      { answer: "0", isCorrect: false },
    ],
  },
  {
    question:
      "Find the points of discontinuity for the function f(x) = (x² - 4) / (x - 2).",
    answerOptions: [
      { answer: "x = 2", isCorrect: true },
      { answer: "x = -2", isCorrect: false },
      { answer: "x = 0", isCorrect: false },
      { answer: "No discontinuity", isCorrect: false },
    ],
  },
  {
    question: "Find the second derivative of the function f(x) = e^(2x).",
    answerOptions: [
      { answer: "4e^(2x)", isCorrect: true },
      { answer: "2e^(2x)", isCorrect: false },
      { answer: "e^(2x)", isCorrect: false },
      { answer: "4e^(x)", isCorrect: false },
    ],
  },
  {
    question:
      "Determine whether the function f(x) = x³ - 3x² + 2x is increasing or decreasing at x = 1.",
    answerOptions: [
      { answer: "Decreasing", isCorrect: true },
      { answer: "Increasing", isCorrect: false },
      { answer: "Neither", isCorrect: false },
      { answer: "Cannot be determined", isCorrect: false },
    ],
  },
  {
    question:
      "Find the maximum value of the function f(x) = x² - 4x + 4 over the interval [0, 3].",
    answerOptions: [
      { answer: "4", isCorrect: true },
      { answer: "3", isCorrect: false },
      { answer: "5", isCorrect: false },
      { answer: "6", isCorrect: false },
    ],
  },
  {
    question:
      "For which values of x is the function f(x) = x² + 2x - 3 differentiable?",
    answerOptions: [
      { answer: "For all x", isCorrect: true },
      { answer: "For x > 0 only", isCorrect: false },
      { answer: "For x < 0 only", isCorrect: false },
      { answer: "For x = 0 only", isCorrect: false },
    ],
  },
  {
    question: "Find the value of the limit: lim (x → 0) [ (sin(x) - x) / x³ ].",
    answerOptions: [
      { answer: "-1/6", isCorrect: true },
      { answer: "0", isCorrect: false },
      { answer: "1/6", isCorrect: false },
      { answer: "-1/3", isCorrect: false },
    ],
  },
  {
    question:
      "If f(x) = x³ - 6x² + 9x + 1, find the local maximum and minimum values.",
    answerOptions: [
      {
        answer: "Local maximum at x = 1, local minimum at x = 3",
        isCorrect: true,
      },
      {
        answer: "Local maximum at x = 2, local minimum at x = 1",
        isCorrect: false,
      },
      {
        answer: "Local maximum at x = 3, local minimum at x = 1",
        isCorrect: false,
      },
      { answer: "No local maxima or minima", isCorrect: false },
    ],
  },
  {
    question:
      "Find the limit of the function f(x) = (x² - 4) / (x² - x - 6) as x approaches 2.",
    answerOptions: [
      { answer: "1", isCorrect: true },
      { answer: "2", isCorrect: false },
      { answer: "0", isCorrect: false },
      { answer: "-1", isCorrect: false },
    ],
  },
  {
    question:
      "Determine if the function f(x) = x³ - 2x² + 3x - 4 is continuous at x = 2.",
    answerOptions: [
      { answer: "Yes, it is continuous at x = 2", isCorrect: true },
      { answer: "No, it is not continuous at x = 2", isCorrect: false },
      {
        answer: "It depends on the direction from which x approaches 2",
        isCorrect: false,
      },
      { answer: "The function is only defined for x ≠ 2", isCorrect: false },
    ],
  },
  {
    question: "Find the derivative of f(x) = tan(x) * cos(x).",
    answerOptions: [
      { answer: "tan(x) - sin(x)", isCorrect: true },
      { answer: "tan(x) * sin(x)", isCorrect: false },
      { answer: "cos(x) - sin(x)", isCorrect: false },
      { answer: "tan(x) + cos(x)", isCorrect: false },
    ],
  },
  {
    question: "Evaluate the integral ∫ (x² + 1) * e^x dx.",
    answerOptions: [
      { answer: "e^x * (x² - 2x + 2) + C", isCorrect: true },
      { answer: "e^x * (x² + 2x + 2) + C", isCorrect: false },
      { answer: "e^x * (x² + 2x + 1) + C", isCorrect: false },
      { answer: "e^x * (x² + x) + C", isCorrect: false },
    ],
  },
  {
    question:
      "Determine the continuity of the function f(x) = { sin(x) for x ≤ 0, 1/x for x > 0 } at x = 0.",
    answerOptions: [
      { answer: "The function is not continuous at x = 0", isCorrect: true },
      { answer: "The function is continuous at x = 0", isCorrect: false },
      { answer: "The function is only defined for x ≠ 0", isCorrect: false },
      {
        answer:
          "The function has a discontinuity at x = 0 but can be made continuous",
        isCorrect: false,
      },
    ],
  },
  {
    question:
      "Find the points where the function f(x) = 1 / (x² - 1) is discontinuous.",
    answerOptions: [
      { answer: "x = ±1", isCorrect: true },
      { answer: "x = 0", isCorrect: false },
      { answer: "x = 1", isCorrect: false },
      { answer: "x = -1", isCorrect: false },
    ],
  },
  {
    question:
      "Find the maximum value of the function f(x) = 2x³ - 9x² + 12x - 3 over the interval [0, 2].",
    answerOptions: [
      { answer: "3", isCorrect: true },
      { answer: "4", isCorrect: false },
      { answer: "5", isCorrect: false },
      { answer: "6", isCorrect: false },
    ],
  },
  {
    question: "Find the limit: lim (x → ∞) [ (x³ - 3x² + x) / (2x³ + 4) ].",
    answerOptions: [
      { answer: "1/2", isCorrect: true },
      { answer: "1", isCorrect: false },
      { answer: "0", isCorrect: false },
      { answer: "∞", isCorrect: false },
    ],
  },
  {
    question: "Evaluate the derivative of f(x) = x² * e^(-x).",
    answerOptions: [
      { answer: "e^(-x) * (2x - x²)", isCorrect: true },
      { answer: "e^(-x) * (x² - 2x)", isCorrect: false },
      { answer: "e^(-x) * (x² + 2x)", isCorrect: false },
      { answer: "e^(-x) * (2x + x²)", isCorrect: false },
    ],
  },
  {
    question: "Find the derivative of f(x) = e^(x²).",
    answerOptions: [
      { answer: "2x * e^(x²)", isCorrect: true },
      { answer: "e^(x²)", isCorrect: false },
      { answer: "2x * e^x", isCorrect: false },
      { answer: "e^(2x)", isCorrect: false },
    ],
  },
  {
    question: "Find the value of the limit: lim (x → 0) [ (e^x - 1) / x ].",
    answerOptions: [
      { answer: "1", isCorrect: true },
      { answer: "0", isCorrect: false },
      { answer: "e", isCorrect: false },
      { answer: "∞", isCorrect: false },
    ],
  },
  {
    question: "Determine the derivative of the function f(x) = x / (x² + 1).",
    answerOptions: [
      { answer: "1 / (x² + 1) - 2x / (x² + 1)²", isCorrect: true },
      { answer: "1 / (x² + 1) + 2x / (x² + 1)²", isCorrect: false },
      { answer: "1 / (x² + 1) - x / (x² + 1)²", isCorrect: false },
      { answer: "1 / (x² + 1) + x / (x² + 1)²", isCorrect: false },
    ],
  },
  {
    question: "Find the derivative of the function f(x) = ln(x² + 1).",
    answerOptions: [
      { answer: "2x / (x² + 1)", isCorrect: true },
      { answer: "x / (x² + 1)", isCorrect: false },
      { answer: "2 / (x² + 1)", isCorrect: false },
      { answer: "x / (x² + 1)²", isCorrect: false },
    ],
  },
  {
    question: "Evaluate the integral from 0 to 1 of x^2 dx.",
    answerOptions: [
      { answer: "1/3", isCorrect: true },
      { answer: "1/2", isCorrect: false },
      { answer: "1/4", isCorrect: false },
      { answer: "2/3", isCorrect: false },
    ],
    correctAnswer: "1/3",
    difficulty: "Medium",
    category: "Integrals",
  },
  {
    question:
      "Find the area of the region bounded by y = x^2 and y = 4 - x^2",
    answerOptions: [
      { answer: "16/3", isCorrect: true },
      { answer: "8/3", isCorrect: false },
      { answer: "4", isCorrect: false },
      { answer: "8", isCorrect: false },
    ],
    correctAnswer: "16/3",
    difficulty: "Hard",
    category: "Integration",
  },
  {
    question:
      "Find the area of the region bounded by y = x^2 and y = 4 - x^2",
    answerOptions: [
      { answer: "p + 2", isCorrect: true },
      { answer: "p - 2", isCorrect: false },
      { answer: "2p", isCorrect: false },
      { answer: "p + 1", isCorrect: false },
    ],
    correctAnswer: "p + 2",
    difficulty: "Medium",
    category: "Roots of Equations",
  },
  {
    question: "Find the value of the derivative of x^3 sin x at x = π.",
    answerOptions: [
      { answer: "-π^3", isCorrect: true },
      { answer: "0", isCorrect: false },
      { answer: "π^3", isCorrect: false },
      { answer: "-π^2", isCorrect: false }
    ],
    correctAnswer: "-π^3",
    difficulty: "Hard",
    category: "Differentiation"
  },
  {
    question: "For the function f(x) = x^3 - 3x^2 + 4, find the local maxima and minima.",
    answerOptions: [
      { answer: "Maxima at x = 0, Minima at x = 2", isCorrect: false },
      { answer: "Maxima at x = 1, Minima at x = 2", isCorrect: true },
      { answer: "Maxima at x = 2, Minima at x = 1", isCorrect: false },
      { answer: "No local maxima or minima", isCorrect: false }
    ],
    correctAnswer: "Maxima at x = 1, Minima at x = 2",
    difficulty: "Hard",
    category: "Critical Points"
  },
  {
    question: "What is the general solution of the differential equation dy/dx = y tan x?",
    answerOptions: [
      { answer: "y = C cos x", isCorrect: false },
      { answer: "y = C sec x", isCorrect: true },
      { answer: "y = C sin x", isCorrect: false },
      { answer: "y = C cot x", isCorrect: false }
    ],
    correctAnswer: "y = C sec x",
    difficulty: "Hard",
    category: "Differential Equations"
  },
  {
    question: "Evaluate the limit of sin(3x) / x as x approaches 0.",
    answerOptions: [
      { answer: "3", isCorrect: true },
      { answer: "1", isCorrect: false },
      { answer: "0", isCorrect: false },
      { answer: "-3", isCorrect: false }
    ],
    correctAnswer: "3",
    difficulty: "Medium",
    category: "Limits"
  },
  {
    question: "Evaluate the integral from 0 to π/2 of sin^2 x / (cos^2 x + 1) dx.",
    answerOptions: [
      { answer: "1/4", isCorrect: true },
      { answer: "1/2", isCorrect: false },
      { answer: "3/4", isCorrect: false },
      { answer: "1", isCorrect: false }
    ],
    correctAnswer: "1/4",
    difficulty: "Medium",
    category: "Integration"
  },
];

export default calculusquestions;
