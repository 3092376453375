const coordinatequestions = [
  {
    question: "Find the distance between the points (3, 4) and (-1, 2).",
    answerOptions: [
      { answer: "√20", isCorrect: true },
      { answer: "5", isCorrect: false },
      { answer: "√10", isCorrect: false },
      { answer: "10", isCorrect: false },
    ],
  },
  {
    question:
      "What is the midpoint of the line segment joining the points (6, -3) and (2, 5)?",
    answerOptions: [
      { answer: "(4, 1)", isCorrect: true },
      { answer: "(3, 1)", isCorrect: false },
      { answer: "(4, -1)", isCorrect: false },
      { answer: "(2, 3)", isCorrect: false },
    ],
  },
  {
    question:
      "If the slope of a line passing through the points (x, 2) and (3, 6) is 2, what is the value of x?",
    answerOptions: [
      { answer: "0", isCorrect: true },
      { answer: "1", isCorrect: false },
      { answer: "-1", isCorrect: false },
      { answer: "2", isCorrect: false },
    ],
  },
  {
    question:
      "Find the area of a triangle with vertices at (0, 0), (6, 0), and (0, 8).",
    answerOptions: [
      { answer: "24", isCorrect: true },
      { answer: "48", isCorrect: false },
      { answer: "30", isCorrect: false },
      { answer: "12", isCorrect: false },
    ],
  },
  {
    question:
      "What is the equation of a line that passes through the point (1, 2) and has a slope of 3?",
    answerOptions: [
      { answer: "y - 2 = 3(x - 1)", isCorrect: true },
      { answer: "y = 3x + 1", isCorrect: false },
      { answer: "y + 2 = 3(x + 1)", isCorrect: false },
      { answer: "y = 3x - 2", isCorrect: false },
    ],
  },
  {
    question:
      "Find the coordinates of the centroid of a triangle with vertices at (1, 2), (3, 4), and (5, 6).",
    answerOptions: [
      { answer: "(3, 4)", isCorrect: true },
      { answer: "(2, 3)", isCorrect: false },
      { answer: "(4, 5)", isCorrect: false },
      { answer: "(1, 5)", isCorrect: false },
    ],
  },
  {
    question:
      "If a line has an equation of 3x - 4y + 5 = 0, what is the slope of the line?",
    answerOptions: [
      { answer: "3/4", isCorrect: false },
      { answer: "4/3", isCorrect: true },
      { answer: "-3/4", isCorrect: false },
      { answer: "-4/3", isCorrect: false },
    ],
  },
  {
    question:
      "What is the length of the line segment joining the points (1, 1) and (4, 5)?",
    answerOptions: [
      { answer: "5", isCorrect: true },
      { answer: "4", isCorrect: false },
      { answer: "3", isCorrect: false },
      { answer: "6", isCorrect: false },
    ],
  },
  {
    question:
      "Find the equation of the circle with center at (3, -4) and radius 5.",
    answerOptions: [
      { answer: "(x - 3)² + (y + 4)² = 25", isCorrect: true },
      { answer: "(x + 3)² + (y - 4)² = 25", isCorrect: false },
      { answer: "(x - 3)² + (y - 4)² = 25", isCorrect: false },
      { answer: "(x + 3)² + (y + 4)² = 25", isCorrect: false },
    ],
  },
  {
    question:
      "What is the slope of the line perpendicular to the line with equation 2x - 3y = 6?",
    answerOptions: [
      { answer: "-3/2", isCorrect: true },
      { answer: "3/2", isCorrect: false },
      { answer: "-2/3", isCorrect: false },
      { answer: "2/3", isCorrect: false },
    ],
  },
  {
    question: "The line 2x + 3y = 5 cuts the x-axis at what point?",
    answerOptions: [
      { answer: "(5/2, 0)", isCorrect: true },
      { answer: "(0, 5/3)", isCorrect: false },
      { answer: "(-5/2, 0)", isCorrect: false },
      { answer: "(2.5, 0)", isCorrect: false },
    ],
  },
  {
    question:
      "Find the coordinates of the point that divides the line segment joining (4, -2) and (8, 6) in the ratio 3:1.",
    answerOptions: [
      { answer: "(7, 4)", isCorrect: true },
      { answer: "(5, 3)", isCorrect: false },
      { answer: "(6, 5)", isCorrect: false },
      { answer: "(8, 2)", isCorrect: false },
    ],
  },
  {
    question:
      "What is the equation of a line parallel to y = 2x + 3 and passing through the point (2, -1)?",
    answerOptions: [
      { answer: "y = 2x - 5", isCorrect: true },
      { answer: "y = -2x + 5", isCorrect: false },
      { answer: "y = 2x + 5", isCorrect: false },
      { answer: "y = -2x - 5", isCorrect: false },
    ],
  },
  {
    question:
      "The point (3, 4) is reflected about the x-axis. What are the coordinates of the reflected point?",
    answerOptions: [
      { answer: "(3, -4)", isCorrect: true },
      { answer: "(-3, 4)", isCorrect: false },
      { answer: "(4, -3)", isCorrect: false },
      { answer: "(-4, 3)", isCorrect: false },
    ],
  },
  {
    question:
      "If a line passes through the points (1, 2) and (3, k), and its slope is 2, what is the value of k?",
    answerOptions: [
      { answer: "6", isCorrect: true },
      { answer: "4", isCorrect: false },
      { answer: "5", isCorrect: false },
      { answer: "3", isCorrect: false },
    ],
  },
  {
    question:
      "Find the equation of a line perpendicular to 4x - 5y = 1 and passing through the origin.",
    answerOptions: [
      { answer: "5x + 4y = 0", isCorrect: true },
      { answer: "5x - 4y = 0", isCorrect: false },
      { answer: "-5x + 4y = 0", isCorrect: false },
      { answer: "4x - 5y = 0", isCorrect: false },
    ],
  },
  {
    question:
      "What is the length of the perpendicular from the point (1, 2) to the line 3x + 4y - 12 = 0?",
    answerOptions: [
      { answer: "2.4", isCorrect: true },
      { answer: "3.5", isCorrect: false },
      { answer: "4.1", isCorrect: false },
      { answer: "1.8", isCorrect: false },
    ],
  },
  {
    question:
      "Find the radius of the circle with equation x² + y² - 6x + 8y - 11 = 0.",
    answerOptions: [
      { answer: "5", isCorrect: true },
      { answer: "6", isCorrect: false },
      { answer: "7", isCorrect: false },
      { answer: "8", isCorrect: false },
    ],
  },
  {
    question:
      "What is the slope of the line that passes through the points (2, 5) and (4, -3)?",
    answerOptions: [
      { answer: "-4", isCorrect: true },
      { answer: "-2", isCorrect: false },
      { answer: "2", isCorrect: false },
      { answer: "4", isCorrect: false },
    ],
  },
  {
    question: "The line x + y = 6 intersects the y-axis at what point?",
    answerOptions: [
      { answer: "(0, 6)", isCorrect: true },
      { answer: "(6, 0)", isCorrect: false },
      { answer: "(3, 3)", isCorrect: false },
      { answer: "(0, -6)", isCorrect: false },
    ],
  },
  {
    question:
      "Find the equation of the locus of a point that moves such that its distance from (3, 2) is always twice its distance from (-1, -1).",
    answerOptions: [
      { answer: "x² - 2x + y² - 6y = 0", isCorrect: false },
      { answer: "x² - 6x + y² + 4y = 0", isCorrect: false },
      { answer: "x² - 2x + y² + 4y = 0", isCorrect: false },
      { answer: "x² - 2x + y² + 4y = 5", isCorrect: true },
    ],
  },
  {
    question:
      "The area of the triangle formed by the points (1, 2), (4, 6), and (1, k) is 9 square units. What is the value of k?",
    answerOptions: [
      { answer: "10", isCorrect: true },
      { answer: "-10", isCorrect: false },
      { answer: "12", isCorrect: false },
      { answer: "4", isCorrect: false },
    ],
  },
  {
    question:
      "If the slope of one of the diagonals of a rhombus is 2 and one vertex is at (1, 1), then find the slope of the other diagonal.",
    answerOptions: [
      { answer: "-1/2", isCorrect: true },
      { answer: "1/2", isCorrect: false },
      { answer: "-2", isCorrect: false },
      { answer: "2", isCorrect: false },
    ],
  },
  {
    question:
      "Find the equation of the circle passing through (2, -1) and touching the x-axis at (3, 0).",
    answerOptions: [
      { answer: "(x - 3)² + (y + 1)² = 1", isCorrect: false },
      { answer: "(x - 3)² + y² = 1", isCorrect: false },
      { answer: "(x - 3)² + (y + 1)² = 2", isCorrect: true },
      { answer: "(x - 2)² + (y + 1)² = 1", isCorrect: false },
    ],
  },
  {
    question:
      "If the line x + 2y = 1 meets the coordinate axes at A and B, find the coordinates of the centroid of ΔOAB, where O is the origin.",
    answerOptions: [
      { answer: "(1/3, 1/3)", isCorrect: true },
      { answer: "(1/3, 1/6)", isCorrect: false },
      { answer: "(2/3, 1/3)", isCorrect: false },
      { answer: "(1/2, 1/2)", isCorrect: false },
    ],
  },
  {
    question:
      "If the equation x² + y² + 2gx + 2fy + c = 0 represents a circle touching the x-axis at (2, 0), find the value of g.",
    answerOptions: [
      { answer: "-2", isCorrect: true },
      { answer: "2", isCorrect: false },
      { answer: "4", isCorrect: false },
      { answer: "-4", isCorrect: false },
    ],
  },
  {
    question:
      "Find the length of the latus rectum of the parabola given by the equation 4y² = 9x.",
    answerOptions: [
      { answer: "4.5", isCorrect: false },
      { answer: "3", isCorrect: true },
      { answer: "2", isCorrect: false },
      { answer: "5", isCorrect: false },
    ],
  },
  {
    question:
      "If the points A(2, 3), B(4, k), and C(6, -3) are collinear, find the value of k.",
    answerOptions: [
      { answer: "1", isCorrect: true },
      { answer: "2", isCorrect: false },
      { answer: "3", isCorrect: false },
      { answer: "0", isCorrect: false },
    ],
  },
  {
    question:
      "If the point P(2, 3) is reflected in the line x - y + 1 = 0, find the coordinates of the image of P.",
    answerOptions: [
      { answer: "(3, 2)", isCorrect: false },
      { answer: "(1, 4)", isCorrect: false },
      { answer: "(3, -2)", isCorrect: true },
      { answer: "(4, 1)", isCorrect: false },
    ],
  },
  {
    question:
      "Find the value of 'a' if the line y = 3x + a passes through the point of intersection of the lines 2x + y = 1 and x - y = 2.",
    answerOptions: [
      { answer: "3", isCorrect: false },
      { answer: "-3", isCorrect: true },
      { answer: "4", isCorrect: false },
      { answer: "-4", isCorrect: false },
    ],
  },
  {
    question:
      "Find the equation of the circle whose diameter is the line segment joining the points (1, 2) and (4, 6).",
    answerOptions: [
      { answer: "(x - 2.5)² + (y - 4)² = 6.5", isCorrect: true },
      { answer: "(x - 2.5)² + (y - 4)² = 5", isCorrect: false },
      { answer: "(x - 2)² + (y - 4)² = 6.5", isCorrect: false },
      { answer: "(x - 2)² + (y - 3)² = 5", isCorrect: false },
    ],
  },
  {
    question:
      "A line passes through the point (2, 3) and is perpendicular to the line joining (2, 3) and (5, 7). What is its slope?",
    answerOptions: [
      { answer: "-3/4", isCorrect: false },
      { answer: "4/3", isCorrect: true },
      { answer: "3/4", isCorrect: false },
      { answer: "-4/3", isCorrect: false },
    ],
  },
  {
    question:
      "Find the circumcenter of the triangle formed by the points (0, 0), (6, 0), and (0, 8).",
    answerOptions: [
      { answer: "(3, 4)", isCorrect: true },
      { answer: "(4, 3)", isCorrect: false },
      { answer: "(5, 5)", isCorrect: false },
      { answer: "(2, 3)", isCorrect: false },
    ],
  },
  {
    question:
      "The line 4x - 3y = 12 is tangent to a circle with center at (1, 2). What is the radius of the circle?",
    answerOptions: [
      { answer: "5", isCorrect: true },
      { answer: "4", isCorrect: false },
      { answer: "3", isCorrect: false },
      { answer: "2", isCorrect: false },
    ],
  },
  {
    question:
      "Find the angle between the lines given by the equation 2x² + 7xy + 3y² = 0.",
    answerOptions: [
      { answer: "45°", isCorrect: true },
      { answer: "30°", isCorrect: false },
      { answer: "60°", isCorrect: false },
      { answer: "90°", isCorrect: false },
    ],
  },
  {
    question:
      "If the tangents drawn from a point P to a circle are at right angles, then find the length of the tangent from P to the circle.",
    answerOptions: [
      { answer: "√2 * radius", isCorrect: true },
      { answer: "radius", isCorrect: false },
      { answer: "2 * radius", isCorrect: false },
      { answer: "0.5 * radius", isCorrect: false },
    ],
  },
  {
    question:
      "Find the equation of a line passing through (3, 2) and parallel to the line 4x - y + 5 = 0.",
    answerOptions: [
      { answer: "4x - y - 10 = 0", isCorrect: true },
      { answer: "4x + y - 10 = 0", isCorrect: false },
      { answer: "2x - y + 5 = 0", isCorrect: false },
      { answer: "x + 4y + 5 = 0", isCorrect: false },
    ],
  },
  {
    question:
      "Find the coordinates of the point which divides the line joining (1, 4) and (5, 8) in the ratio 3:2.",
    answerOptions: [
      { answer: "(3.6, 6.4)", isCorrect: true },
      { answer: "(2.6, 5.4)", isCorrect: false },
      { answer: "(4.6, 7.4)", isCorrect: false },
      { answer: "(1.6, 4.4)", isCorrect: false },
    ],
  },
  {
    question:
      "If the equation of an ellipse is 4x² + 9y² = 36, then find the length of the major axis.",
    answerOptions: [
      { answer: "4", isCorrect: false },
      { answer: "6", isCorrect: false },
      { answer: "8", isCorrect: false },
      { answer: "12", isCorrect: true },
    ],
  },
  {
    question:
      "The locus of the midpoint of the chords of a circle x² + y² = 4 which subtend a right angle at the origin is:",
    answerOptions: [
      { answer: "x² + y² = 2", isCorrect: true },
      { answer: "x² + y² = 4", isCorrect: false },
      { answer: "x² + y² = 8", isCorrect: false },
      { answer: "x² + y² = 6", isCorrect: false },
    ],
  },
  {
    question:
      "Find the equation of a circle passing through the point (1, 2) and having the center at (3, -4).",
    answerOptions: [
      { answer: "(x - 3)² + (y + 4)² = 40", isCorrect: true },
      { answer: "(x - 3)² + (y - 4)² = 20", isCorrect: false },
      { answer: "(x + 3)² + (y + 4)² = 20", isCorrect: false },
      { answer: "(x + 3)² + (y - 4)² = 40", isCorrect: false },
    ],
  },
  {
    question:
      "Find the length of the latus rectum of a parabola with the equation y² = 8x.",
    answerOptions: [
      { answer: "4", isCorrect: true },
      { answer: "2", isCorrect: false },
      { answer: "8", isCorrect: false },
      { answer: "6", isCorrect: false },
    ],
  },
  {
    question:
      "If the focus of a parabola is at (3, 2) and the directrix is x - 4 = 0, find its equation.",
    answerOptions: [
      { answer: "(x - 4)² = 2(y - 2)", isCorrect: false },
      { answer: "(x - 4)² = 8(y - 2)", isCorrect: true },
      { answer: "(x - 4)² = 4(y - 2)", isCorrect: false },
      { answer: "(x - 4)² = 16(y - 2)", isCorrect: false },
    ],
  },
  {
    question:
      "Find the coordinates of the foci of the ellipse given by the equation 9x² + 16y² = 144.",
    answerOptions: [
      { answer: "(0, ±√7)", isCorrect: true },
      { answer: "(0, ±3)", isCorrect: false },
      { answer: "(0, ±5)", isCorrect: false },
      { answer: "(0, ±√5)", isCorrect: false },
    ],
  },
  {
    question:
      "Find the eccentricity of the hyperbola given by the equation 9x² - 16y² = 144.",
    answerOptions: [
      { answer: "5/3", isCorrect: true },
      { answer: "4/3", isCorrect: false },
      { answer: "3/5", isCorrect: false },
      { answer: "3/4", isCorrect: false },
    ],
  },
  {
    question:
      "The line y = mx + c touches the parabola y² = 4ax. Find the condition for tangency.",
    answerOptions: [
      { answer: "c = a/m", isCorrect: true },
      { answer: "c = 2a/m", isCorrect: false },
      { answer: "c = 3a/m", isCorrect: false },
      { answer: "c = 4a/m", isCorrect: false },
    ],
  },
  {
    question:
      "If the equation of a circle is x² + y² - 6x + 8y - 11 = 0, find its radius.",
    answerOptions: [
      { answer: "√36", isCorrect: false },
      { answer: "√25", isCorrect: true },
      { answer: "5", isCorrect: false },
      { answer: "4", isCorrect: false },
    ],
  },
  {
    question:
      "The equation of a hyperbola is 4x² - 9y² = 36. Find the length of its transverse axis.",
    answerOptions: [
      { answer: "6", isCorrect: true },
      { answer: "12", isCorrect: false },
      { answer: "4", isCorrect: false },
      { answer: "8", isCorrect: false },
    ],
  },
  {
    question:
      "For the ellipse given by 3x² + 4y² = 12, find the length of its semi-major axis.",
    answerOptions: [
      { answer: "2", isCorrect: false },
      { answer: "√3", isCorrect: false },
      { answer: "√2", isCorrect: true },
      { answer: "3", isCorrect: false },
    ],
  },
  {
    question:
      "Find the slope of the tangent to the parabola y² = 4ax at the point (at², 2at).",
    answerOptions: [
      { answer: "1/t", isCorrect: true },
      { answer: "2/t", isCorrect: false },
      { answer: "t/2", isCorrect: false },
      { answer: "2t", isCorrect: false },
    ],
  },
  {
    question:
      "The foci of a hyperbola are at (±5, 0) and its vertices are at (±3, 0). Find the equation of the hyperbola.",
    answerOptions: [
      { answer: "x²/9 - y²/16 = 1", isCorrect: false },
      { answer: "x²/9 - y²/25 = 1", isCorrect: true },
      { answer: "x²/25 - y²/9 = 1", isCorrect: false },
      { answer: "x²/16 - y²/9 = 1", isCorrect: false },
    ],
  },
  {
    question:
      "Find the length of the major axis of the ellipse 4x² + 9y² = 36.",
    answerOptions: [
      { answer: "6", isCorrect: true },
      { answer: "4", isCorrect: false },
      { answer: "5", isCorrect: false },
      { answer: "7", isCorrect: false },
    ],
  },
  {
    question:
      "The equation of a parabola is y² = 4x. What is the length of its latus rectum?",
    answerOptions: [
      { answer: "4", isCorrect: true },
      { answer: "2", isCorrect: false },
      { answer: "6", isCorrect: false },
      { answer: "8", isCorrect: false },
    ],
  },
  {
    question:
      "Find the eccentricity of an ellipse whose equation is x²/16 + y²/9 = 1.",
    answerOptions: [
      { answer: "1/2", isCorrect: false },
      { answer: "√7/4", isCorrect: true },
      { answer: "3/4", isCorrect: false },
      { answer: "5/4", isCorrect: false },
    ],
  },
  {
    question:
      "Find the equation of a circle passing through the origin and having the center at (2, 3).",
    answerOptions: [
      { answer: "(x - 2)² + (y - 3)² = 13", isCorrect: true },
      { answer: "(x + 2)² + (y + 3)² = 13", isCorrect: false },
      { answer: "(x - 2)² + (y + 3)² = 13", isCorrect: false },
      { answer: "(x + 2)² + (y - 3)² = 13", isCorrect: false },
    ],
  },
  {
    question:
      "The equation of a parabola is y² = -16x. What is the direction of its axis?",
    answerOptions: [
      { answer: "Negative x-axis", isCorrect: true },
      { answer: "Positive x-axis", isCorrect: false },
      { answer: "Positive y-axis", isCorrect: false },
      { answer: "Negative y-axis", isCorrect: false },
    ],
  },
  {
    question:
      "For the hyperbola x²/25 - y²/16 = 1, find the coordinates of the foci.",
    answerOptions: [
      { answer: "(±√41, 0)", isCorrect: true },
      { answer: "(±5, 0)", isCorrect: false },
      { answer: "(0, ±√41)", isCorrect: false },
      { answer: "(0, ±5)", isCorrect: false },
    ],
  },
  {
    question:
      "The line x + y = 1 touches the parabola y² = 4x. Find the point of contact.",
    answerOptions: [
      { answer: "(1, 0)", isCorrect: true },
      { answer: "(0, 1)", isCorrect: false },
      { answer: "(1, 1)", isCorrect: false },
      { answer: "(0, 0)", isCorrect: false },
    ],
  },
  {
    question:
      "Find the equation of an ellipse with vertices at (±5, 0) and foci at (±3, 0).",
    answerOptions: [
      { answer: "x²/25 + y²/16 = 1", isCorrect: true },
      { answer: "x²/16 + y²/25 = 1", isCorrect: false },
      { answer: "x²/9 + y²/25 = 1", isCorrect: false },
      { answer: "x²/25 + y²/9 = 1", isCorrect: false },
    ],
  },
  {
    question:
      "For the hyperbola given by 9x² - 16y² = 144, find the length of its conjugate axis.",
    answerOptions: [
      { answer: "8", isCorrect: true },
      { answer: "12", isCorrect: false },
      { answer: "6", isCorrect: false },
      { answer: "10", isCorrect: false },
    ],
  },
  {
    question:
      "The equation of a circle is x² + y² - 4x + 6y + 9 = 0. Find the coordinates of its center.",
    answerOptions: [
      { answer: "(2, -3)", isCorrect: true },
      { answer: "(-2, 3)", isCorrect: false },
      { answer: "(3, -2)", isCorrect: false },
      { answer: "(4, -6)", isCorrect: false },
    ],
  },
  {
    question:
      "Find the equation of the tangent to the ellipse 4x² + 9y² = 36 at the point (1, √2).",
    answerOptions: [
      { answer: "4x + 6√2y = 24", isCorrect: false },
      { answer: "4x + 6√2y = 6", isCorrect: true },
      { answer: "4x + 6y = 36", isCorrect: false },
      { answer: "4x + 9y = 12", isCorrect: false },
    ],
  },
  {
    question: "Find the equation of the directrix of the parabola y² = 12x.",
    answerOptions: [
      { answer: "x = -3", isCorrect: true },
      { answer: "x = 3", isCorrect: false },
      { answer: "y = 3", isCorrect: false },
      { answer: "y = -3", isCorrect: false },
    ],
  },
  {
    question:
      "The eccentricity of an ellipse is 1/2. If the distance between the foci is 6, find the length of its major axis.",
    answerOptions: [
      { answer: "12", isCorrect: true },
      { answer: "6", isCorrect: false },
      { answer: "8", isCorrect: false },
      { answer: "10", isCorrect: false },
    ],
  },
  {
    question:
      "If the equation of a hyperbola is 9x² - 16y² = 144, what is the length of its latus rectum?",
    answerOptions: [
      { answer: "32/3", isCorrect: true },
      { answer: "16/3", isCorrect: false },
      { answer: "8/3", isCorrect: false },
      { answer: "8", isCorrect: false },
    ],
  },
  {
    question:
      "The radius of a circle is 5, and a chord is 8 units away from the center. Find the length of the chord.",
    answerOptions: [
      { answer: "6", isCorrect: false },
      { answer: "4", isCorrect: false },
      { answer: "3", isCorrect: false },
      { answer: "6√2", isCorrect: true },
    ],
  },
  {
    question:
      "Find the coordinates of the foci for the ellipse given by x²/16 + y²/9 = 1.",
    answerOptions: [
      { answer: "(±√7, 0)", isCorrect: false },
      { answer: "(0, ±√7)", isCorrect: true },
      { answer: "(±4, 0)", isCorrect: false },
      { answer: "(0, ±3)", isCorrect: false },
    ],
  },
  {
    question:
      "For a parabola with the equation y² = 12x, find the coordinates of the vertex.",
    answerOptions: [
      { answer: "(0, 0)", isCorrect: true },
      { answer: "(3, 0)", isCorrect: false },
      { answer: "(0, 3)", isCorrect: false },
      { answer: "(1, 1)", isCorrect: false },
    ],
  },
  {
    question:
      "The vertices of a hyperbola are at (±4, 0) and its eccentricity is 2. Find the equation of the hyperbola.",
    answerOptions: [
      { answer: "x²/16 - y²/48 = 1", isCorrect: true },
      { answer: "x²/9 - y²/16 = 1", isCorrect: false },
      { answer: "x²/25 - y²/9 = 1", isCorrect: false },
      { answer: "x²/25 - y²/16 = 1", isCorrect: false },
    ],
  },
  {
    question:
      "Find the equation of a parabola whose vertex is at the origin and directrix is x + 4 = 0.",
    answerOptions: [
      { answer: "y² = 16x", isCorrect: false },
      { answer: "y² = -16x", isCorrect: true },
      { answer: "x² = -16y", isCorrect: false },
      { answer: "x² = 16y", isCorrect: false },
    ],
  },
  {
    question:
      "For the ellipse x²/9 + y²/4 = 1, find the length of its minor axis.",
    answerOptions: [
      { answer: "4", isCorrect: true },
      { answer: "6", isCorrect: false },
      { answer: "8", isCorrect: false },
      { answer: "3", isCorrect: false },
    ],
  },
  {
    question:
      "Find the coordinates of the center of the hyperbola given by the equation 4x² - 9y² + 8x + 18y - 23 = 0.",
    answerOptions: [
      { answer: "(-1, -1)", isCorrect: true },
      { answer: "(1, 1)", isCorrect: false },
      { answer: "(-1, 1)", isCorrect: false },
      { answer: "(1, -1)", isCorrect: false },
    ],
  },
  {
    question:
      "The focal distance of a point on the ellipse x²/25 + y²/16 = 1 is 7. Find its eccentric angle.",
    answerOptions: [
      { answer: "π/3", isCorrect: true },
      { answer: "π/4", isCorrect: false },
      { answer: "π/6", isCorrect: false },
      { answer: "π/2", isCorrect: false },
    ],
  },
  {
    question:
      "Find the equation of the normal to the parabola y² = 4ax at the point (at², 2at).",
    answerOptions: [
      { answer: "y + tx = 2at + at³", isCorrect: true },
      { answer: "y + tx = at + 2at³", isCorrect: false },
      { answer: "y + tx = 4at + 2at³", isCorrect: false },
      { answer: "y - tx = 2at - at³", isCorrect: false },
    ],
  },
  {
    question:
      "A circle passes through the points (1, 0) and (0, 1) and its center lies on the line x + y = 2. Find its equation.",
    answerOptions: [
      { answer: "(x - 1)² + (y - 1)² = 2", isCorrect: true },
      { answer: "(x + 1)² + (y - 1)² = 2", isCorrect: false },
      { answer: "(x - 1)² + (y + 1)² = 2", isCorrect: false },
      { answer: "(x + 1)² + (y + 1)² = 2", isCorrect: false },
    ],
  },
  {
    question:
      "The ellipse x²/9 + y²/16 = 1 is rotated by 45°. Find its new equation.",
    answerOptions: [
      { answer: "x² + y² = 1", isCorrect: false },
      { answer: "5x² + 7xy + 5y² = 18", isCorrect: true },
      { answer: "7x² + 5xy + 7y² = 18", isCorrect: false },
      { answer: "3x² + 4xy + 3y² = 18", isCorrect: false },
    ],
  },
  {
    question:
      "The chord of the circle x² + y² = 25 that passes through the point (1, 2) and is bisected at this point is given by:",
    answerOptions: [
      { answer: "x + 2y = 5", isCorrect: true },
      { answer: "2x + y = 5", isCorrect: false },
      { answer: "x + 2y = 1", isCorrect: false },
      { answer: "2x + y = 1", isCorrect: false },
    ],
  },
  {
    question:
      "Find the length of the latus rectum of the hyperbola x²/25 - y²/16 = 1.",
    answerOptions: [
      { answer: "32/5", isCorrect: true },
      { answer: "25/4", isCorrect: false },
      { answer: "40/9", isCorrect: false },
      { answer: "16/3", isCorrect: false },
    ],
  },
  {
    question:
      "For the ellipse 9x² + 16y² = 144, find the distance between its foci.",
    answerOptions: [
      { answer: "4√7", isCorrect: true },
      { answer: "5√7", isCorrect: false },
      { answer: "6√7", isCorrect: false },
      { answer: "7√7", isCorrect: false },
    ],
  },
  {
    question:
      "If the tangent to the parabola y² = 4ax at a point makes an angle of 45° with the x-axis, find the slope of the tangent.",
    answerOptions: [
      { answer: "1", isCorrect: true },
      { answer: "-1", isCorrect: false },
      { answer: "2", isCorrect: false },
      { answer: "-2", isCorrect: false },
    ],
  },
  {
    question:
      "Find the distance between the foci of the ellipse given by x²/25 + y²/16 = 1.",
    answerOptions: [
      { answer: "4√7", isCorrect: true },
      { answer: "6", isCorrect: false },
      { answer: "8", isCorrect: false },
      { answer: "4", isCorrect: false },
    ],
  },
  {
    question:
      "The equation of a circle is x² + y² - 6x + 8y + 9 = 0. Find the radius of the circle.",
    answerOptions: [
      { answer: "4", isCorrect: true },
      { answer: "5", isCorrect: false },
      { answer: "3", isCorrect: false },
      { answer: "2", isCorrect: false },
    ],
  },
  {
    question: "Find the coordinates of the vertex of the parabola y² = 8x.",
    answerOptions: [
      { answer: "(0, 0)", isCorrect: true },
      { answer: "(2, 0)", isCorrect: false },
      { answer: "(0, 2)", isCorrect: false },
      { answer: "(4, 4)", isCorrect: false },
    ],
  },
  {
    question:
      "For the hyperbola x²/36 - y²/16 = 1, find the length of the latus rectum.",
    answerOptions: [
      { answer: "16/6", isCorrect: true },
      { answer: "12", isCorrect: false },
      { answer: "8/3", isCorrect: false },
      { answer: "10", isCorrect: false },
    ],
  },
  {
    question:
      "The coordinates of the center of a circle are (3, -2) and it passes through the point (7, 4). Find the equation of the circle.",
    answerOptions: [
      { answer: "(x - 3)² + (y + 2)² = 52", isCorrect: true },
      { answer: "(x - 3)² + (y + 2)² = 40", isCorrect: false },
      { answer: "(x + 3)² + (y - 2)² = 52", isCorrect: false },
      { answer: "(x + 3)² + (y - 2)² = 40", isCorrect: false },
    ],
  },
  {
    question: "Find the equation of the directrix of the parabola x² = 12y.",
    answerOptions: [
      { answer: "y = -3", isCorrect: true },
      { answer: "y = 3", isCorrect: false },
      { answer: "x = -3", isCorrect: false },
      { answer: "x = 3", isCorrect: false },
    ],
  },
  {
    question:
      "The ellipse x²/16 + y²/9 = 1 is rotated 30° counterclockwise about its center. Find the new equation of the ellipse.",
    answerOptions: [
      { answer: "25x² - 30xy + 16y² = 144", isCorrect: true },
      { answer: "24x² - 30xy + 16y² = 144", isCorrect: false },
      { answer: "25x² + 30xy + 16y² = 144", isCorrect: false },
      { answer: "25x² + 30xy - 16y² = 144", isCorrect: false },
    ],
  },
  {
    question:
      "Find the length of the major axis of the ellipse given by 4x² + 9y² = 36.",
    answerOptions: [
      { answer: "6", isCorrect: true },
      { answer: "8", isCorrect: false },
      { answer: "10", isCorrect: false },
      { answer: "12", isCorrect: false },
    ],
  },
  {
    question:
      "Find the equation of the hyperbola whose foci are (±4, 0) and vertices are (±3, 0).",
    answerOptions: [
      { answer: "x²/9 - y²/16 = 1", isCorrect: true },
      { answer: "x²/16 - y²/9 = 1", isCorrect: false },
      { answer: "x²/25 - y²/16 = 1", isCorrect: false },
      { answer: "x²/16 - y²/25 = 1", isCorrect: false },
    ],
  },
  {
    question:
      "Find the equation of the tangent to the circle x² + y² = 25 at the point (3, 4).",
    answerOptions: [
      { answer: "3x + 4y = 25", isCorrect: true },
      { answer: "3x - 4y = 25", isCorrect: false },
      { answer: "4x + 3y = 25", isCorrect: false },
      { answer: "4x - 3y = 25", isCorrect: false },
    ],
  },
  {
    question: "The coordinates of the focus of the parabola y² = 8x are:",
    answerOptions: [
      { answer: "(2, 0)", isCorrect: true },
      { answer: "(4, 0)", isCorrect: false },
      { answer: "(0, 2)", isCorrect: false },
      { answer: "(0, 4)", isCorrect: false },
    ],
  },
  {
    question: "The circle x² + y² - 10x + 4y + 9 = 0 is tangent to which line?",
    answerOptions: [
      { answer: "x - y = 1", isCorrect: true },
      { answer: "x + y = 1", isCorrect: false },
      { answer: "x - y = 2", isCorrect: false },
      { answer: "x + y = 2", isCorrect: false },
    ],
  },
  {
    question:
      "The equation of the parabola with its vertex at the origin and axis parallel to the x-axis passing through (4, 3) is:",
    answerOptions: [
      { answer: "y² = 3x", isCorrect: true },
      { answer: "x² = 3y", isCorrect: false },
      { answer: "y² = 4x", isCorrect: false },
      { answer: "x² = 4y", isCorrect: false },
    ],
  },
  {
    question:
      "Find the coordinates of the center of the circle given by x² + y² - 8x - 6y + 15 = 0.",
    answerOptions: [
      { answer: "(4, 3)", isCorrect: true },
      { answer: "(3, 4)", isCorrect: false },
      { answer: "(5, 2)", isCorrect: false },
      { answer: "(2, 5)", isCorrect: false },
    ],
  },
  {
    question: "Find the length of the latus rectum of the parabola x² = 8y.",
    answerOptions: [
      { answer: "8", isCorrect: true },
      { answer: "4", isCorrect: false },
      { answer: "6", isCorrect: false },
      { answer: "10", isCorrect: false },
    ],
  },
  {
    question:
      "The equation of the ellipse is given by 9x² + 4y² = 36. Find its foci.",
    answerOptions: [
      { answer: "(±2, 0)", isCorrect: true },
      { answer: "(±3, 0)", isCorrect: false },
      { answer: "(0, ±2)", isCorrect: false },
      { answer: "(0, ±3)", isCorrect: false },
    ],
  },
  {
    question:
      "Find the equation of the hyperbola with vertices (±5, 0) and foci (±7, 0).",
    answerOptions: [
      { answer: "x²/25 - y²/16 = 1", isCorrect: true },
      { answer: "x²/16 - y²/25 = 1", isCorrect: false },
      { answer: "x²/25 - y²/9 = 1", isCorrect: false },
      { answer: "x²/9 - y²/25 = 1", isCorrect: false },
    ],
  },
  {
    question: "The circle x² + y² = 4x + 6y + 9 has its center at:",
    answerOptions: [
      { answer: "(2, 3)", isCorrect: true },
      { answer: "(3, 2)", isCorrect: false },
      { answer: "(1, 4)", isCorrect: false },
      { answer: "(4, 1)", isCorrect: false },
    ],
  },
  {
    question: "The equation of a circle with radius 5 and center (1, -2) is:",
    answerOptions: [
      { answer: "(x - 1)² + (y + 2)² = 25", isCorrect: true },
      { answer: "(x + 1)² + (y - 2)² = 25", isCorrect: false },
      { answer: "(x - 1)² + (y - 2)² = 25", isCorrect: false },
      { answer: "(x + 1)² + (y + 2)² = 25", isCorrect: false },
    ],
  },
  {
    question: "Find the length of the directrix of the parabola x² = -12y.",
    answerOptions: [
      { answer: "3", isCorrect: true },
      { answer: "6", isCorrect: false },
      { answer: "4", isCorrect: false },
      { answer: "5", isCorrect: false },
    ],
  },
  {
    question:
      "The distance between the foci of the ellipse x²/16 + y²/9 = 1 is:",
    answerOptions: [
      { answer: "2√7", isCorrect: true },
      { answer: "√7", isCorrect: false },
      { answer: "4√7", isCorrect: false },
      { answer: "√10", isCorrect: false },
    ],
  },
  {
    question:
      "The equation of the parabola with vertex at (0, 0) and focus at (0, 4) is:",
    answerOptions: [
      { answer: "x² = 16y", isCorrect: true },
      { answer: "y² = 16x", isCorrect: false },
      { answer: "x² = 8y", isCorrect: false },
      { answer: "y² = 8x", isCorrect: false },
    ],
  },
  {
    question:
      "Find the coordinates of the foci of the hyperbola x²/36 - y²/25 = 1.",
    answerOptions: [
      { answer: "(±7, 0)", isCorrect: true },
      { answer: "(±5, 0)", isCorrect: false },
      { answer: "(±6, 0)", isCorrect: false },
      { answer: "(±8, 0)", isCorrect: false },
    ],
  },
  {
    question:
      "The equation of the circle passing through (0, 0) and tangent to the line x + y = 1 is:",
    answerOptions: [
      { answer: "x² + y² - 2x - 2y = 0", isCorrect: true },
      { answer: "x² + y² - x - y = 1", isCorrect: false },
      { answer: "x² + y² - 1 = 0", isCorrect: false },
      { answer: "x² + y² - 2x + 1 = 0", isCorrect: false },
    ],
  },
  {
    question:
      "The equation of the circle with diameter 4 and center (1, -2) is:",
    answerOptions: [
      { answer: "(x - 1)² + (y + 2)² = 4", isCorrect: true },
      { answer: "(x - 1)² + (y + 2)² = 16", isCorrect: false },
      { answer: "(x + 1)² + (y - 2)² = 4", isCorrect: false },
      { answer: "(x + 1)² + (y - 2)² = 16", isCorrect: false },
    ],
  },
  {
    question:
      "The equation of the ellipse with foci (±2, 0) and vertices (±3, 0) is:",
    answerOptions: [
      { answer: "x²/9 + y²/4 = 1", isCorrect: true },
      { answer: "x²/16 + y²/9 = 1", isCorrect: false },
      { answer: "x²/4 + y²/9 = 1", isCorrect: false },
      { answer: "x²/9 + y²/16 = 1", isCorrect: false },
    ],
  },
  {
    question:
      "Find the coordinates of the point of intersection of the line x + y = 1 and the circle x² + y² = 1.",
    answerOptions: [
      { answer: "(1/2, 1/2)", isCorrect: true },
      { answer: "(0, 1)", isCorrect: false },
      { answer: "(1, 0)", isCorrect: false },
      { answer: "(1/2, 0)", isCorrect: false },
    ],
  },
  {
    question:
      "The length of the transverse axis of the hyperbola x²/25 - y²/16 = 1 is:",
    answerOptions: [
      { answer: "10", isCorrect: true },
      { answer: "8", isCorrect: false },
      { answer: "5", isCorrect: false },
      { answer: "12", isCorrect: false },
    ],
  },
  {
    question:
      "Find the equation of the circle with center (3, -4) and passing through the origin.",
    answerOptions: [
      { answer: "(x - 3)² + (y + 4)² = 25", isCorrect: true },
      { answer: "(x - 3)² + (y + 4)² = 16", isCorrect: false },
      { answer: "(x + 3)² + (y - 4)² = 25", isCorrect: false },
      { answer: "(x + 3)² + (y - 4)² = 16", isCorrect: false },
    ],
  },
  {
    question:
      "Find the equation of the hyperbola with center at the origin and asymptotes y = ±2x.",
    answerOptions: [
      { answer: "x²/4 - y²/16 = 1", isCorrect: true },
      { answer: "x²/16 - y²/4 = 1", isCorrect: false },
      { answer: "x²/9 - y²/25 = 1", isCorrect: false },
      { answer: "x²/25 - y²/9 = 1", isCorrect: false },
    ],
  },
  {
    question: "Find the coordinates of the focus of the parabola x² = -12y.",
    answerOptions: [
      { answer: "(0, -3)", isCorrect: true },
      { answer: "(0, 3)", isCorrect: false },
      { answer: "(3, 0)", isCorrect: false },
      { answer: "(-3, 0)", isCorrect: false },
    ],
  },
  {
    question:
      "The equation of the ellipse with foci (±4, 0) and vertices (±6, 0) is:",
    answerOptions: [
      { answer: "x²/36 + y²/16 = 1", isCorrect: true },
      { answer: "x²/25 + y²/16 = 1", isCorrect: false },
      { answer: "x²/16 + y²/36 = 1", isCorrect: false },
      { answer: "x²/36 + y²/25 = 1", isCorrect: false },
    ],
  },
  {
    question:
      "Find the equation of the ellipse with center at (2, -3), major axis 10, and minor axis 6.",
    answerOptions: [
      { answer: "(x - 2)²/25 + (y + 3)²/9 = 1", isCorrect: true },
      { answer: "(x - 2)²/16 + (y + 3)²/36 = 1", isCorrect: false },
      { answer: "(x + 2)²/25 + (y - 3)²/9 = 1", isCorrect: false },
      { answer: "(x + 2)²/16 + (y - 3)²/36 = 1", isCorrect: false },
    ],
  },
  {
    question:
      "The equation of the circle x² + y² - 10x - 6y + 9 = 0 can be written in standard form as:",
    answerOptions: [
      { answer: "(x - 5)² + (y - 3)² = 25", isCorrect: true },
      { answer: "(x - 5)² + (y - 6)² = 36", isCorrect: false },
      { answer: "(x + 5)² + (y + 3)² = 25", isCorrect: false },
      { answer: "(x + 5)² + (y + 6)² = 36", isCorrect: false },
    ],
  },
  {
    question:
      "Find the length of the latus rectum of the hyperbola x²/49 - y²/16 = 1.",
    answerOptions: [
      { answer: "32/7", isCorrect: true },
      { answer: "16/7", isCorrect: false },
      { answer: "28/7", isCorrect: false },
      { answer: "24/7", isCorrect: false },
    ],
  },
  {
    question:
      "Find the coordinates of the center of the ellipse x²/25 + y²/9 = 1.",
    answerOptions: [
      { answer: "(0, 0)", isCorrect: true },
      { answer: "(1, 1)", isCorrect: false },
      { answer: "(5, 3)", isCorrect: false },
      { answer: "(-5, -3)", isCorrect: false },
    ],
  },
  {
    question:
      "The equation of the hyperbola with center at (0, 0), foci (±√13, 0), and vertices (±2, 0) is:",
    answerOptions: [
      { answer: "x²/4 - y²/9 = 1", isCorrect: true },
      { answer: "x²/9 - y²/4 = 1", isCorrect: false },
      { answer: "x²/16 - y²/9 = 1", isCorrect: false },
      { answer: "x²/25 - y²/16 = 1", isCorrect: false },
    ],
  },
  {
    question:
      "Find the length of the transverse axis of the hyperbola given by 4x² - y²/9 = 1.",
    answerOptions: [
      { answer: "4", isCorrect: true },
      { answer: "6", isCorrect: false },
      { answer: "8", isCorrect: false },
      { answer: "10", isCorrect: false },
    ],
  },
  {
    question:
      "Find the equation of the circle with center (1, -1) and radius 3.",
    answerOptions: [
      { answer: "(x - 1)² + (y + 1)² = 9", isCorrect: true },
      { answer: "(x + 1)² + (y - 1)² = 9", isCorrect: false },
      { answer: "(x - 1)² + (y - 1)² = 9", isCorrect: false },
      { answer: "(x + 1)² + (y + 1)² = 9", isCorrect: false },
    ],
  },
  {
    question:
      "For the ellipse x²/16 + y²/25 = 1, find the coordinates of the foci.",
    answerOptions: [
      { answer: "(±√9, 0)", isCorrect: true },
      { answer: "(±√7, 0)", isCorrect: false },
      { answer: "(±√11, 0)", isCorrect: false },
      { answer: "(±√4, 0)", isCorrect: false },
    ],
  },
  {
    question:
      "A circular fountain has a radius of 10 meters. If a person is standing at a point 15 meters from the center of the fountain, what is the distance between the person and the edge of the fountain?",
    answerOptions: [
      { answer: "5 meters", isCorrect: true },
      { answer: "15 meters", isCorrect: false },
      { answer: "25 meters", isCorrect: false },
      { answer: "10 meters", isCorrect: false },
    ],
  },
  {
    question:
      "A car travels along a straight road and then makes a turn to travel along a curved path described by the equation x²/36 + y²/16 = 1. If the car starts at the origin and the center of the curve is at (5, 0), what is the distance the car has traveled along the curved path to reach the point (5, 4)?",
    answerOptions: [
      { answer: "5 meters", isCorrect: true },
      { answer: "7 meters", isCorrect: false },
      { answer: "9 meters", isCorrect: false },
      { answer: "11 meters", isCorrect: false },
    ],
  },
  {
    question:
      "An architect is designing a building with a parabolic arch. The arch can be modeled by the equation y = -x²/8 + 4. If the arch spans 8 meters wide and its highest point is 4 meters above the ground, what is the width of the arch at a height of 2 meters?",
    answerOptions: [
      { answer: "4 meters", isCorrect: true },
      { answer: "6 meters", isCorrect: false },
      { answer: "8 meters", isCorrect: false },
      { answer: "10 meters", isCorrect: false },
    ],
  },
  {
    question:
      "A company needs to build a circular track for its employees to jog. If the track is to be built with a radius of 50 meters, what is the area of the track?",
    answerOptions: [
      { answer: "7850 square meters", isCorrect: true },
      { answer: "5000 square meters", isCorrect: false },
      { answer: "3140 square meters", isCorrect: false },
      { answer: "1570 square meters", isCorrect: false },
    ],
  },
  {
    question:
      "A circular garden has a radius of 20 meters. If a path of width 2 meters is built around the garden, what is the area of the path?",
    answerOptions: [
      { answer: "384 square meters", isCorrect: true },
      { answer: "256 square meters", isCorrect: false },
      { answer: "528 square meters", isCorrect: false },
      { answer: "420 square meters", isCorrect: false },
    ],
  },
  {
    question:
      "A satellite orbits the Earth in an elliptical path described by the equation x²/10000 + y²/40000 = 1. If the semi-major axis is 200 kilometers and the semi-minor axis is 100 kilometers, what is the distance from the satellite to the center of the Earth when it is at the point (0, 100) kilometers?",
    answerOptions: [
      { answer: "100 kilometers", isCorrect: true },
      { answer: "150 kilometers", isCorrect: false },
      { answer: "200 kilometers", isCorrect: false },
      { answer: "250 kilometers", isCorrect: false },
    ],
  },
  {
    question:
      "A drone is flying above a circular field with a radius of 30 meters. If the drone is directly above the center of the field and moves 10 meters east and 10 meters north, how far is it from the edge of the field?",
    answerOptions: [
      { answer: "20 meters", isCorrect: true },
      { answer: "25 meters", isCorrect: false },
      { answer: "30 meters", isCorrect: false },
      { answer: "35 meters", isCorrect: false },
    ],
  },
  {
    question:
      "A racetrack is shaped like an ellipse with a major axis of 200 meters and a minor axis of 100 meters. If a runner is at one focus of the ellipse, how far is the runner from the center of the track?",
    answerOptions: [
      { answer: "√15000 meters", isCorrect: true },
      { answer: "√10000 meters", isCorrect: false },
      { answer: "√5000 meters", isCorrect: false },
      { answer: "√20000 meters", isCorrect: false },
    ],
  },
  {
    question:
      "A sports field is shaped like a rectangle with a semicircular area on one end. If the length of the rectangular part is 50 meters and the radius of the semicircular end is 20 meters, what is the total area of the field?",
    answerOptions: [
      { answer: "1500 square meters", isCorrect: true },
      { answer: "1600 square meters", isCorrect: false },
      { answer: "1700 square meters", isCorrect: false },
      { answer: "1800 square meters", isCorrect: false },
    ],
  },
  {
    question:
      "An architect is designing a parabolic bridge where the equation of the parabola is given by y = x²/16 - 4. If the bridge spans 8 meters horizontally, what is the height of the bridge at its midpoint?",
    answerOptions: [
      { answer: "-3 meters", isCorrect: true },
      { answer: "-2 meters", isCorrect: false },
      { answer: "-4 meters", isCorrect: false },
      { answer: "-5 meters", isCorrect: false },
    ],
  },
  {
    question:
      "A circular swimming pool has a diameter of 12 meters. If a person walks from one point on the edge of the pool to the point directly opposite across the diameter, how far have they walked?",
    answerOptions: [
      { answer: "12 meters", isCorrect: true },
      { answer: "6 meters", isCorrect: false },
      { answer: "24 meters", isCorrect: false },
      { answer: "18 meters", isCorrect: false },
    ],
  },
  {
    question: "The area of the triangle with vertices at (0,0), (4,0), and (2,6) is:",
    answerOptions: [
      { answer: "12", isCorrect: true },
      { answer: "8", isCorrect: false },
      { answer: "10", isCorrect: false },
      { answer: "15", isCorrect: false }
    ],
    correctAnswer: "12",
    difficulty: "Medium",
    category: "Geometry"
  },
];

export default coordinatequestions
