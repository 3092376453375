// StarRating.js
import React, { useState, useEffect } from "react";
import { doc, updateDoc, getDoc, setDoc, increment } from "firebase/firestore";
import { fireDB } from "../config/firebase";

const StarRating = ({ questionId, userId }) => {
  const [rating, setRating] = useState(0); // Current rating value
  const [hasRated, setHasRated] = useState(false); // Track if the user has rated
  const [totalRatings, setTotalRatings] = useState(0); // Track total ratings
  const [averageRating, setAverageRating] = useState(0); // Track average rating

  // Fetch existing rating and total rating data from Firestore on component mount
  useEffect(() => {
    const fetchRatingData = async () => {
      try {
        // Fetch the user's individual rating for this question
        const ratingRef = doc(fireDB, "ratings", `${userId}_${questionId}`);
        const ratingDoc = await getDoc(ratingRef);

        if (ratingDoc.exists()) {
          const userRating = ratingDoc.data().rating;
          setRating(userRating);
          setHasRated(true);
        }

        // Fetch the overall rating data for the question
        const questionRef = doc(fireDB, "voteQuestions", questionId);
        const questionDoc = await getDoc(questionRef);

        if (questionDoc.exists()) {
          const questionData = questionDoc.data();
          setTotalRatings(questionData.totalRatings || 0);
          setAverageRating(
            questionData.totalRatings ? questionData.ratingSum / questionData.totalRatings : 0
          );
        }
      } catch (error) {
        console.error("Error fetching rating data:", error);
      }
    };

    fetchRatingData();
  }, [questionId, userId]);

  // Function to handle rating click
  const handleRating = async (value) => {
    if (hasRated) {
      alert("You have already rated!");
      return; // Prevent multiple ratings
    }

    try {
      // Reference to the Firestore document where the user's rating is stored
      const ratingRef = doc(fireDB, "ratings", `${userId}_${questionId}`);
      
      // Set the user's rating in Firestore
      await setDoc(ratingRef, {
        userId: userId,
        questionId: questionId,
        rating: value,
      });

      // Update the question's total rating count and rating sum in Firestore
      const questionRef = doc(fireDB, "voteQuestions", questionId);
      await updateDoc(questionRef, {
        totalRatings: increment(1),
        ratingSum: increment(value),
      });

      // Update local state to reflect the new rating
      setRating(value);
      setHasRated(true);
      setTotalRatings((prevTotal) => prevTotal + 1);
      setAverageRating((prevAverage) => ((prevAverage * totalRatings) + value) / (totalRatings + 1));
    } catch (error) {
      console.error("Error saving rating:", error);
    }
  };

  return (
    <div>
      {[1, 2, 3, 4, 5].map((star) => (
        <span
          key={star}
          onClick={() => handleRating(star)}
          style={{
            cursor: "pointer",
            color: star <= rating ? "#FFD700" : "#ccc", // Highlight selected stars
            fontSize: "24px",
          }}
        >
          ★
        </span>
      ))}
      {hasRated && <p>You rated this question: {rating} stars</p>}
      <p>Total Ratings: {totalRatings}</p>
      <p>Average Rating: {averageRating.toFixed(2)}</p>
    </div>
  );
};

export default StarRating;
