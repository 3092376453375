import React, { useState } from "react";
import "../pagesStyle/trends.css"; // Import the CSS file
import EngagementTrends from "./EngagementTrends"; // Assuming EngagementTrends component exists
import VotingTrends from "./VotingTrends"; // Assuming VotingTrends component exists

function Trends() {
  const [selectedTab, setSelectedTab] = useState("engagement"); // State to track the selected tab

  const handleTabChange = (tab) => {
    setSelectedTab(tab); // Update the selected tab
  };

  return (
    <div className="trends-container">
      <p>
        <button onClick={() => handleTabChange("engagement")}>Engagement Trends</button>
        <button onClick={() => handleTabChange("vote")}>Voting Trends</button>
      </p>

      {/* Conditionally render components based on the selected tab */}
      {selectedTab === "engagement" && <EngagementTrends />}
      {selectedTab === "vote" && <VotingTrends />}
    </div>
  );
}

export default Trends;
