const elesticityquestions = [
  {
    question: "The bulk modules for an incompressible liquis is :",
    answerOptions: [
      { answer: "0", isCorrect: false },
      { answer: "1", isCorrect: false },
      { answer: "∞", isCorrect: true },
      { answer: "between 0 and 1", isCorrect: false },
    ],
  },
  {
    question:
      "Which equations is correct for variation in pressure with depth :",
    answerOptions: [
      { answer: "P2 - p1 = - ⍴g(y2 - y1)", isCorrect: true },
      { answer: "P2 - p1 = - ⍴g(y2 - y1)", isCorrect: false },
      { answer: "P2 - p1 = - ⍴g(y2 - y1)", isCorrect: false },
      { answer: "P2 - p1 = - ⍴g(y2 - y1)", isCorrect: false },
    ],
  },
  {
    question:
      "The maximum load that a wire can sustain is W. If the wire is cut to half its value, the maximum load it can sustain is :",
    answerOptions: [
      { answer: "W/2", isCorrect: false },
      { answer: "W", isCorrect: true },
      { answer: "W/4", isCorrect: false },
      { answer: "2W", isCorrect: false },
    ],
  },
  {
    question:
      "When a sphere in a viscious flude attains a terminal velocity, then :",
    answerOptions: [
      { answer: "F acting on sphere is 0", isCorrect: true },
      { answer: "drag F balance the buyant F", isCorrect: false },
      { answer: "drag F balances the W of sphere", isCorrect: false },
      { answer: "buoyant F balances the W and drag F", isCorrect: false },
    ],
  },
  {
    question:
      "Which of these follow the correct equation for modulus of rigidity :",
    answerOptions: [
      { answer: "μ = F/A θ", isCorrect: true },
      { answer: "⍴ = F/a θ", isCorrect: false },
      { answer: "μ = F/A m", isCorrect: false },
      { answer: "None of these", isCorrect: false },
    ],
  },
  {
    question:
      " The length is steel wire is l1, when the stretching force T1 and l2 when is stretching force is T2. The natural length of a wire is :",
    answerOptions: [
      { answer: "l1T1 + l2T2 / T1 + T2", isCorrect: false },
      { answer: "l2T1 + l1T2 / T1 + T2", isCorrect: false },
      { answer: "l2T1 - l1T2 / T1 - T2", isCorrect: true },
      { answer: "l1T1 - l2T2 / T1 + T2", isCorrect: false },
    ],
  },
  {
    question: "Viscocity of liquids : (Here taken, temperature = temp)",
    answerOptions: [
      { answer: "incresases with increase in temp", isCorrect: false },
      { answer: "is independent of temp", isCorrect: false },
      { answer: "decreases in decreses in temp", isCorrect: false },
      { answer: "decresses in increases in temp", isCorrect: true },
    ],
  },
  {
    question:
      "A uniform steel rod of cross-sectional area A and length L is suspended so that it hangs vertically. The stress at the middle point of the rod is :",
    answerOptions: [
      { answer: "1/2 ⍴gL", isCorrect: true },
      { answer: "1/4 ⍴gL", isCorrect: false },
      { answer: "⍴gL", isCorrect: false },
      { answer: "Nothing can be said", isCorrect: false },
    ],
  },
  {
    question:
      "The bulk modules of water is 2.0x10^9 N/m². The pressure required to increase the density of water by 0.1% is :",
    answerOptions: [
      { answer: "2.0x10^3 N/m²", isCorrect: false },
      { answer: "2.0x10^3 N/m²", isCorrect: true },
      { answer: "2.0x10^3 N/m²", isCorrect: false },
      { answer: "2.0x10^3 N/m²", isCorrect: false },
    ],
  },
  {
    question:
      "A capillary tube is dipped in a liquid. Let pressure at a points Pa, Pb and Pc respectively then :",
    answerOptions: [
      { answer: "Pa = Pb = Pc", isCorrect: false },
      { answer: "Pa = Pb < Pc", isCorrect: false },
      { answer: "Pa = Pc < Pb", isCorrect: false },
      { answer: "Pa = Pc > Pb", isCorrect: true },
    ],
  },
  {
    question: "Correct formula of Gauge pressure is :",
    answerOptions: [
      { answer: "Gauge pressure = Absolute pressure", isCorrect: false },
      {
        answer: "Gauge pressure = Absolute pressure - Atmospheric pressure",
        isCorrect: true,
      },
      {
        answer: "Gauge pressure = Absolute pressure + Atmospheric pressure",
        isCorrect: false,
      },
      { answer: "All are false", isCorrect: false },
    ],
  },
  {
    question:
      "The young's modules of a wire of length L and radius r is Y. If the length is reduced L/2  and radius r/2, then its Young's modules will be :",
    answerOptions: [
      { answer: "Y/2", isCorrect: false },
      { answer: "Y", isCorrect: true },
      { answer: "2Y", isCorrect: false },
      { answer: "4Y", isCorrect: false },
    ],
  },
  {
    question:
      "Mass of body is M is attached to the lower end of a metel wire, whose upper end is fixed. The elongation of a wire at botton level will be : (Xnetous Chellange)",
    answerOptions: [
      { answer: "Produced heat is 1/2 Mgl", isCorrect: false },
      {
        answer: "Elastic potential energy stored in wire is 1/2 Mgl",
        isCorrect: false,
      },
      {
        answer: "Gravitational potential energy of M is Mgl will be lose",
        isCorrect: false,
      },
      { answer: "All are correct", isCorrect: true },
    ],
  },
  {
    question:
      "Two shop bubble in a vaccum of radius 3 cm and 4 cm coalesce to from a single bubble under isothermal conditions. Then the radius of bigger bubble is :",
    answerOptions: [
      { answer: "7 cm", isCorrect: false },
      { answer: "12/7 cm", isCorrect: false },
      { answer: "12 cm", isCorrect: false },
      { answer: "5 cm", isCorrect: true },
    ],
  },
  {
    question:
      "The terminal velocity os rain drop is 30 cm/s If the velocity of air is 1.8x10^-5 Ns/m². The radius of rain drop is :",
    answerOptions: [
      { answer: "0.05 mm", isCorrect: true },
      { answer: "1 mm", isCorrect: false },
      { answer: "0.5 mm", isCorrect: false },
      { answer: "5 mm", isCorrect: false },
    ],
  },
  {
    question:
      "A piece of ice is floating in a beaker containing thick suger solution of water. As the ice melts, the total level of the liquid is :",
    answerOptions: [
      { answer: "increases", isCorrect: true },
      { answer: "decreases", isCorrect: false },
      { answer: "remain same", isCorrect: false },
      { answer: "Can not be say", isCorrect: false },
    ],
  },
  {
    question:
      "If a capillary tube si dipped and the liquid levels inside and outside the tube are same, then angle of contact is :",
    answerOptions: [
      { answer: "0", isCorrect: false },
      { answer: "90°", isCorrect: true },
      { answer: "45°", isCorrect: false },
      { answer: "can not be say", isCorrect: false },
    ],
  },
  {
    question:
      "The bulk modules of water is 2.3x10^9 N/m² and Xnetous community team calculate compressibility 4.4x10^-5 atm^-1. Does this calculation correct or not :",
    answerOptions: [
      { answer: "No", isCorrect: false },
      { answer: "Yes", isCorrect: true },
      { answer: "can not be said", isCorrect: false },
    ],
  },
  {
    question:
      "Uniform speed of 2cm diameter ball is 20cm/s in a viscous liquid. Then, the speed of 1 cm diameter ball in the same liquid is :",
    answerOptions: [
      { answer: "5 cm/s", isCorrect: true },
      { answer: "10 cm/s", isCorrect: false },
      { answer: "40 cm/s", isCorrect: false },
      { answer: "80 cm/s", isCorrect: false },
    ],
  },
  {
    question:
      "Two wire A and B of same dimentions are stretched by same amount of force. Young's modulus of A is twice that of B. Which wire will get more elongated :",
    answerOptions: [
      { answer: "Wire A", isCorrect: false },
      { answer: "both A and B", isCorrect: false },
      { answer: "Wire B", isCorrect: true },
      { answer: "Impossible to say", isCorrect: false },
    ],
  },
  {
    question:
      "A bulk of ice of total area A and thickness 0.5 m is floating in water. In order to jump support a man of mass 100 kg, the area A should be :",
    answerOptions: [
      { answer: "2.2 m²", isCorrect: false },
      { answer: "1.0 m²", isCorrect: false },
      { answer: "0.5 m²", isCorrect: false },
      { answer: "None of these", isCorrect: true },
    ],
  },
  {
    question:
      "A U-tube is partially filled with water. Oil which does not mixed with water is next poured into one side, intil water raised by 25 cm on the other side, If the density of oil is 0.8 g/cm^3, the oil level will stand higher tan the water level by :",
    answerOptions: [
      { answer: "6.25 cm", isCorrect: false },
      { answer: "12.50 cm", isCorrect: true },
      { answer: "31.75 cm", isCorrect: false },
      { answer: "25 cm", isCorrect: false },
    ],
  },
  {
    question:
      "A weightless ballon is filled with water. What will be its apparent weight when weighted in water :",
    answerOptions: [
      { answer: "∞", isCorrect: false },
      { answer: "0", isCorrect: true },
      { answer: "same", isCorrect: false },
      { answer: "not define", isCorrect: false },
    ],
  },
  {
    question: "Hook's Law is define as :",
    answerOptions: [
      { answer: "stress is ∝ to strain", isCorrect: true },
      { answer: "stress is ∝ to 1/strain", isCorrect: false },
      { answer: "stress is = to strain", isCorrect: false },
      { answer: "Nothing can be said", isCorrect: false },
    ],
  },
  {
    question: "The density of a liquid is :",
    answerOptions: [
      { answer: "⍴ = m/V", isCorrect: true },
      { answer: "⍴ = mV", isCorrect: false },
      { answer: "⍴ = V", isCorrect: false },
      { answer: "Data insufficient", isCorrect: false },
    ],
  },
  {
    question: "Potential energy in a stretched wire is :",
    answerOptions: [
      { answer: "u = 1/2(strain)/(stress)", isCorrect: false },
      { answer: "u = 1/2(strain)x(stress)", isCorrect: true },
      { answer: "u = (strain)x(stress)", isCorrect: false },
      { answer: "u = 1/2(strain)+(stress)", isCorrect: false },
    ],
  },
  {
    question:
      "A piece of ice is floating in a glass vessels filled with water. How will the level of water in the vessel change when the ice melt :",
    answerOptions: [
      { answer: "solwly", isCorrect: false },
      { answer: "not change", isCorrect: true },
      { answer: "downword", isCorrect: false },
      { answer: "upward", isCorrect: false },
    ],
  },
  {
    question:
      "A block of woods floating in a bucket of water placed in lift. Will the block sink more or less if the lift starts accelerating up :",
    answerOptions: [
      { answer: "will float at same level", isCorrect: true },
      { answer: "will float at different level", isCorrect: false },
      { answer: "will stsrt float after some minute", isCorrect: false },
      { answer: "will not float", isCorrect: false },
    ],
  },
  {
    question:
      "Two vessels A and B have same base area. Eqaul volumes of liquid are poured in the two vessels to different heights hA and hB , where hB > hA. In which vessel, the force on the base of vessel will be more :",
    answerOptions: [
      { answer: "Both", isCorrect: false },
      { answer: "A", isCorrect: false },
      { answer: "B", isCorrect: true },
      { answer: "can be said", isCorrect: false },
    ],
  },
  {
    question: "Law of conservation of mass in flude dynamics is :",
    answerOptions: [
      { answer: "A1 v1 = A2 v2", isCorrect: true },
      { answer: "A2 v2 = A2 v2", isCorrect: false },
      { answer: "A1 v1 = A1 v1", isCorrect: false },
      { answer: "A1 v1 X A2 v2", isCorrect: false },
    ],
  },
  {
    question: "What is the formula for Young's modulus (E) of a material?",
    answerOptions: [
      { answer: "E = stress / strain", isCorrect: true },
      { answer: "E = force / area", isCorrect: false },
      { answer: "E = volume / pressure", isCorrect: false },
      { answer: "E = mass / length", isCorrect: false },
    ],
  },
  {
    question:
      "A wire of length L and cross-sectional area A stretches by an amount ΔL under a tensile force F. What is the Young's modulus of the wire?",
    answerOptions: [
      { answer: "E = F L / (A ΔL)", isCorrect: true },
      { answer: "E = A F / (L ΔL)", isCorrect: false },
      { answer: "E = ΔL / (F A)", isCorrect: false },
      { answer: "E = F A / (L ΔL)", isCorrect: false },
    ],
  },
  {
    question: "What is the unit of Young's modulus?",
    answerOptions: [
      { answer: "Pascals (Pa)", isCorrect: true },
      { answer: "Newtons (N)", isCorrect: false },
      { answer: "Joules (J)", isCorrect: false },
      { answer: "Watts (W)", isCorrect: false },
    ],
  },
  {
    question:
      "What is the term for the property of a material to return to its original shape after the removal of the applied force?",
    answerOptions: [
      { answer: "Elasticity", isCorrect: true },
      { answer: "Plasticity", isCorrect: false },
      { answer: "Ductility", isCorrect: false },
      { answer: "Brittleness", isCorrect: false },
    ],
  },
  {
    question:
      "In a fluid flow, the principle stating that an increase in the speed of the fluid occurs simultaneously with a decrease in pressure or a decrease in the fluid's potential energy is known as:",
    answerOptions: [
      { answer: "Bernoullis principle", isCorrect: true },
      { answer: "Pascals principle", isCorrect: false },
      { answer: "Archimedes principle", isCorrect: false },
      { answer: "Boyles law", isCorrect: false },
    ],
  },
  {
    question:
      "What is the relationship between pressure and depth in a fluid at rest?",
    answerOptions: [
      { answer: "Pressure increases with depth", isCorrect: true },
      { answer: "Pressure decreases with depth", isCorrect: false },
      { answer: "Pressure remains constant with depth", isCorrect: false },
      {
        answer: "Pressure varies inversely with the density of the fluid",
        isCorrect: false,
      },
    ],
  },
  {
    question:
      "What is the equation for the hydrostatic pressure in a fluid of density ρ and height h?",
    answerOptions: [
      { answer: "P = ρgh", isCorrect: true },
      { answer: "P = ρg / h", isCorrect: false },
      { answer: "P = ρ / (gh)", isCorrect: false },
      { answer: "P = g / (ρh)", isCorrect: false },
    ],
  },
  {
    question:
      "What does Pascal's principle state about pressure in an enclosed fluid?",
    answerOptions: [
      {
        answer:
          "Pressure applied to a confined fluid is transmitted undiminished in every direction",
        isCorrect: true,
      },
      {
        answer: "Pressure varies with the depth of the fluid",
        isCorrect: false,
      },
      {
        answer:
          "The pressure in a fluid decreases with the increase in velocity",
        isCorrect: false,
      },
      {
        answer: "Pressure is inversely proportional to the volume of the fluid",
        isCorrect: false,
      },
    ],
  },
  {
    question:
      "What is the term for the resistance of a fluid to flow, which depends on the internal friction between its layers?",
    answerOptions: [
      { answer: "Viscosity", isCorrect: true },
      { answer: "Density", isCorrect: false },
      { answer: "Pressure", isCorrect: false },
      { answer: "Surface tension", isCorrect: false },
    ],
  },
  {
    question: "What is the SI unit of viscosity?",
    answerOptions: [
      { answer: "Pascal-second (Pa·s)", isCorrect: true },
      { answer: "Newton (N)", isCorrect: false },
      { answer: "Joule (J)", isCorrect: false },
      { answer: "Meter per second (m/s)", isCorrect: false },
    ],
  },
  {
    question:
      "A fluid with low viscosity flows easily, while a fluid with high viscosity flows slowly. Which of the following fluids is most viscous?",
    answerOptions: [
      { answer: "Honey", isCorrect: true },
      { answer: "Water", isCorrect: false },
      { answer: "Air", isCorrect: false },
      { answer: "Mercury", isCorrect: false },
    ],
  },
  {
    question:
      "The rate of flow of a fluid through a pipe is directly proportional to the pressure difference and inversely proportional to the viscosity of the fluid. This relationship is known as:",
    answerOptions: [
      { answer: "Poiseuilles law", isCorrect: true },
      { answer: "Bernoullis equation", isCorrect: false },
      { answer: "Archimedes principle", isCorrect: false },
      { answer: "Pascals principle", isCorrect: false },
    ],
  },
  {
    question:
      "What is the formula for calculating the flow rate of a fluid through a pipe using Poiseuille's law?",
    answerOptions: [
      { answer: "Q = π R^4 ΔP / (8 η L)", isCorrect: true },
      { answer: "Q = ΔP / (η R)", isCorrect: false },
      { answer: "Q = η L / (π R^4 ΔP)", isCorrect: false },
      { answer: "Q = 8 η L / (π R^4 ΔP)", isCorrect: false },
    ],
  },
  {
    question:
      "What is the term for the upward force exerted by a fluid on an object immersed in it, which is equal to the weight of the displaced fluid?",
    answerOptions: [
      { answer: "Buoyancy", isCorrect: true },
      { answer: "Gravity", isCorrect: false },
      { answer: "Pressure", isCorrect: false },
      { answer: "Surface tension", isCorrect: false },
    ],
  },
  {
    question:
      "According to Archimedes' principle, what determines whether an object will float or sink in a fluid?",
    answerOptions: [
      {
        answer: "The objects density relative to the fluid density",
        isCorrect: true,
      },
      { answer: "The objects mass", isCorrect: false },
      { answer: "The objects volume", isCorrect: false },
      { answer: "The objects shape", isCorrect: false },
    ],
  },
  {
    question:
      "What happens to the pressure in a fluid as the velocity of the fluid increases according to Bernoulli's principle?",
    answerOptions: [
      { answer: "Pressure decreases", isCorrect: true },
      { answer: "Pressure increases", isCorrect: false },
      { answer: "Pressure remains constant", isCorrect: false },
      { answer: "Pressure becomes zero", isCorrect: false },
    ],
  },
  {
    question:
      "The continuity equation for incompressible fluids states that the product of the cross-sectional area and velocity at any point in the flow is constant. This is represented by:",
    answerOptions: [
      { answer: "A1 v1 = A2 v2", isCorrect: true },
      { answer: "A1 v1 = A2 + v2", isCorrect: false },
      { answer: "A1 + v1 = A2 v2", isCorrect: false },
      { answer: "A1 / v1 = A2 / v2", isCorrect: false },
    ],
  },
  {
    question:
      "In a fluid, what is the term for the force per unit area exerted on the walls of a container by the fluid?",
    answerOptions: [
      { answer: "Pressure", isCorrect: true },
      { answer: "Density", isCorrect: false },
      { answer: "Viscosity", isCorrect: false },
      { answer: "Buoyancy", isCorrect: false },
    ],
  },
  {
    question:
      "What is the principle that explains why an airplane wing generates lift, causing the aircraft to rise?",
    answerOptions: [
      { answer: "Bernoullis principle", isCorrect: true },
      { answer: "Pascals principle", isCorrect: false },
      { answer: "Archimedes principle", isCorrect: false },
      { answer: "Newtons third law", isCorrect: false },
    ],
  },
  {
    question:
      "When a fluid flows through a pipe with varying diameter, where does the fluid velocity increase according to the continuity equation?",
    answerOptions: [
      { answer: "In the narrower sections of the pipe", isCorrect: true },
      { answer: "In the wider sections of the pipe", isCorrect: false },
      { answer: "At the pipe entrance", isCorrect: false },
      { answer: "At the pipe exit", isCorrect: false },
    ],
  },
  {
    question:
      "What is the term for the phenomenon where a fluid's velocity is highest at the center of the pipe and lowest near the edges due to friction?",
    answerOptions: [
      { answer: "Laminar flow", isCorrect: false },
      { answer: "Turbulent flow", isCorrect: false },
      { answer: "Boundary layer effect", isCorrect: true },
      { answer: "Compressible flow", isCorrect: false },
    ],
  },
  {
    question:
      "A fluid with a high density flows through a pipe. What effect does the fluid's density have on its pressure according to Bernoulli's principle?",
    answerOptions: [
      { answer: "Higher density increases the pressure", isCorrect: true },
      { answer: "Higher density decreases the pressure", isCorrect: false },
      { answer: "Density has no effect on pressure", isCorrect: false },
      {
        answer: "Pressure and density are inversely proportional",
        isCorrect: false,
      },
    ],
  },
  {
    question:
      "A column of mercury in a barometer is 76 cm high. What is the atmospheric pressure in Pascals if the density of mercury is 13600 kg/m³?",
    answerOptions: [
      { answer: "1.01 × 10^5 Pa", isCorrect: true },
      { answer: "1.36 × 10^5 Pa", isCorrect: false },
      { answer: "7.6 × 10^4 Pa", isCorrect: false },
      { answer: "1.76 × 10^4 Pa", isCorrect: false },
    ],
  },
  {
    question:
      "A fluid is flowing through a pipe of varying diameter. If the diameter decreases, what happens to the fluid velocity and pressure?",
    answerOptions: [
      { answer: "Velocity increases and pressure decreases", isCorrect: true },
      { answer: "Velocity decreases and pressure increases", isCorrect: false },
      { answer: "Velocity increases and pressure increases", isCorrect: false },
      { answer: "Velocity decreases and pressure decreases", isCorrect: false },
    ],
  },
  {
    question:
      "A car’s suspension system uses shock absorbers to reduce the impact of road bumps. If a shock absorber extends by 0.2 meters under a force of 5000 Newtons, what is the effective spring constant of the shock absorber?",
    answerOptions: [
      { answer: "25,000 N/m", isCorrect: true },
      { answer: "10,000 N/m", isCorrect: false },
      { answer: "50,000 N/m", isCorrect: false },
      { answer: "5,000 N/m", isCorrect: false },
    ],
  },
  {
    question:
      "In a hydraulic lift, if the area of the input piston is 0.1 m² and the area of the output piston is 1 m², and a force of 2000 N is applied to the input piston, what is the force exerted on the output piston?",
    answerOptions: [
      { answer: "20,000 N", isCorrect: true },
      { answer: "2,000 N", isCorrect: false },
      { answer: "200 N", isCorrect: false },
      { answer: "10,000 N", isCorrect: false },
    ],
  },
  {
    question:
      "A water pipe has a diameter of 0.5 meters and carries water at a speed of 3 m/s. If the diameter of another section of the pipe is reduced to 0.2 meters, what will be the speed of the water in the narrower section?",
    answerOptions: [
      { answer: "7.5 m/s", isCorrect: true },
      { answer: "6 m/s", isCorrect: false },
      { answer: "4.5 m/s", isCorrect: false },
      { answer: "3 m/s", isCorrect: false },
    ],
  },
  {
    question:
      "A concrete bridge is designed to support a maximum load of 100,000 kg. If the Young's modulus of concrete is 30 GPa and the bridge has a cross-sectional area of 50 m², what is the maximum stress the bridge can withstand?",
    answerOptions: [
      { answer: "2 MPa", isCorrect: true },
      { answer: "4 MPa", isCorrect: false },
      { answer: "6 MPa", isCorrect: false },
      { answer: "8 MPa", isCorrect: false },
    ],
  },
  {
    question:
      "An airplane wing generates lift due to its shape. If the speed of the air over the top surface of the wing is 200 m/s and the speed below the wing is 150 m/s, what happens to the pressure above the wing compared to below?",
    answerOptions: [
      { answer: "Pressure above the wing is lower", isCorrect: true },
      { answer: "Pressure above the wing is higher", isCorrect: false },
      { answer: "Pressure remains the same", isCorrect: false },
      { answer: "Pressure varies depending on the altitude", isCorrect: false },
    ],
  },
  {
    question:
      "A hydraulic system in a car uses a fluid to transmit force. If the input piston has a diameter of 0.05 meters and the output piston has a diameter of 0.2 meters, what is the ratio of the forces applied to the input and output pistons if the input force is 500 N?",
    answerOptions: [
      { answer: "16", isCorrect: true },
      { answer: "4", isCorrect: false },
      { answer: "8", isCorrect: false },
      { answer: "2", isCorrect: false },
    ],
  },
  {
    question:
      "An engineer measures the strain in a steel beam under load. If the original length of the beam is 5 meters and it stretches by 1 mm under a load of 10,000 N, what is the Young's modulus of the steel?",
    answerOptions: [
      { answer: "50 GPa", isCorrect: true },
      { answer: "25 GPa", isCorrect: false },
      { answer: "75 GPa", isCorrect: false },
      { answer: "100 GPa", isCorrect: false },
    ],
  },
  {
    question:
      "A person is swimming in a pool. If the swimmer has a density of 1.1 g/cm³ and the water density is 1 g/cm³, will the swimmer float or sink?",
    answerOptions: [
      { answer: "Sink", isCorrect: true },
      { answer: "Float", isCorrect: false },
      { answer: "Stay neutral", isCorrect: false },
      { answer: "Depends on the swimmers volume", isCorrect: false },
    ],
  },
  {
    question:
      "A factory uses a pneumatic system to operate machines. If the pressure in the system is 6 bar and the volume of the compressed air is 2 m³, what is the volume of the air at atmospheric pressure (1 bar) assuming isothermal conditions?",
    answerOptions: [
      { answer: "12 m³", isCorrect: true },
      { answer: "6 m³", isCorrect: false },
      { answer: "4 m³", isCorrect: false },
      { answer: "8 m³", isCorrect: false },
    ],
  },
  {
    question:
      "A dam holds back water with a depth of 20 meters. If the density of water is 1000 kg/m³, what is the pressure at the base of the dam?",
    answerOptions: [
      { answer: "200,000 Pa", isCorrect: true },
      { answer: "100,000 Pa", isCorrect: false },
      { answer: "50,000 Pa", isCorrect: false },
      { answer: "25,000 Pa", isCorrect: false },
    ],
  },
  {
    question:
      "A flexible balloon is inflated with air. If the volume of the balloon increases from 1 liter to 2 liters while maintaining constant temperature, what happens to the pressure of the air inside the balloon?",
    answerOptions: [
      { answer: "Pressure decreases", isCorrect: true },
      { answer: "Pressure increases", isCorrect: false },
      { answer: "Pressure remains constant", isCorrect: false },
      { answer: "Pressure becomes zero", isCorrect: false },
    ],
  },
  {
    question:
      "Two point charges, +4 µC and -6 µC, are separated by 30 cm. Calculate the electric potential energy of the system.",
    answerOptions: [
      { answer: "-2.40 \times 10^{-2} J", isCorrect: true },
      { answer: "-1.20 \times 10^{-2} J", isCorrect: false },
      { answer: "-3.60 \times 10^{-2} J", isCorrect: false },
      { answer: "-4.80 \times 10^{-2} J", isCorrect: false },
    ],
  },
  {
    question:
      "A spherical conductor with a radius of 0.1 meters carries a charge of +12 µC. What is the electric potential on its surface? (Use Coulomb's constant ( k = 8.99 \times 10^9 \text{ N m}^2/\text{C}^2 ))",
    answerOptions: [
      { answer: "1.08 \times 10^5 V", isCorrect: true },
      { answer: "1.08 \times 10^6 V", isCorrect: false },
      { answer: "9.00 \times 10^4 V", isCorrect: false },
      { answer: "8.00 \times 10^4 V", isCorrect: false },
    ],
  },
  {
    question:
      "A capacitor of capacitance 20 µF is charged to a potential difference of 25 V. What is the total energy stored in the capacitor?",
    answerOptions: [
      { answer: "6.25 mJ", isCorrect: true },
      { answer: "5.00 mJ", isCorrect: false },
      { answer: "10.00 mJ", isCorrect: false },
      { answer: "12.50 mJ", isCorrect: false },
    ],
  },
  {
    question:
      "If the electric potential at a point is 300 V and the electric field is 1500 N/C, what is the distance from the point where the electric potential is measured?",
    answerOptions: [
      { answer: "0.2 meters", isCorrect: true },
      { answer: "0.5 meters", isCorrect: false },
      { answer: "1.0 meters", isCorrect: false },
      { answer: "2.0 meters", isCorrect: false },
    ],
  },
  {
    question:
      "A charged particle with a charge of +3 µC is placed in a uniform electric field of 2000 N/C. What is the work done in moving the particle 0.5 meters in the direction of the electric field?",
    answerOptions: [
      { answer: "3 J", isCorrect: true },
      { answer: "6 J", isCorrect: false },
      { answer: "1.5 J", isCorrect: false },
      { answer: "0.5 J", isCorrect: false },
    ],
  },
  {
    question:
      "A parallel plate capacitor has a plate separation of 2 mm and a plate area of 0.05 m². If the capacitor is connected to a 6V battery, what is the electric field between the plates?",
    answerOptions: [
      { answer: "3000 V/m", isCorrect: true },
      { answer: "2000 V/m", isCorrect: false },
      { answer: "5000 V/m", isCorrect: false },
      { answer: "1500 V/m", isCorrect: false },
    ],
  },
  {
    question:
      "What is the capacitance of a capacitor if the stored energy is 0.2 J and the potential difference across it is 10 V?",
    answerOptions: [
      { answer: "4 µF", isCorrect: true },
      { answer: "5 µF", isCorrect: false },
      { answer: "8 µF", isCorrect: false },
      { answer: "2 µF", isCorrect: false },
    ],
  },
  {
    question:
      "A spherical shell of radius 0.2 meters has a surface charge density of 5 µC/m². What is the total charge on the shell?",
    answerOptions: [
      { answer: "1.26 µC", isCorrect: true },
      { answer: "0.63 µC", isCorrect: false },
      { answer: "2.52 µC", isCorrect: false },
      { answer: "0.50 µC", isCorrect: false },
    ],
  },
  {
    question:
      "What is the force between two charges of +2 µC and -3 µC placed 15 cm apart in a vacuum? (Use Coulomb's constant ( k = 8.99 \times 10^9 \text{ N m}^2/\text{C}^2 ))",
    answerOptions: [
      { answer: "1.20 N", isCorrect: true },
      { answer: "2.40 N", isCorrect: false },
      { answer: "0.90 N", isCorrect: false },
      { answer: "3.60 N", isCorrect: false },
    ],
  },
  {
    question:
      "A parallel plate capacitor has a capacitance of 5 µF. If the potential difference across the capacitor is 12 V, what is the charge stored in the capacitor?",
    answerOptions: [
      { answer: "60 µC", isCorrect: true },
      { answer: "50 µC", isCorrect: false },
      { answer: "40 µC", isCorrect: false },
      { answer: "70 µC", isCorrect: false },
    ],
  },
  {
    question:
      "A charge of -4 µC is placed at the center of a cubic Gaussian surface of side 0.1 meters. What is the electric flux through one face of the cube?",
    answerOptions: [
      { answer: "-1.13 \times 10^4 \text{ Nm}^2/\text{C}", isCorrect: true },
      { answer: "-1.12 \times 10^4 \text{ Nm}^2/\text{C}", isCorrect: false },
      { answer: "-1.14 \times 10^4 \text{ Nm}^2/\text{C}", isCorrect: false },
      { answer: "-1.11 \times 10^4 \text{ Nm}^2/\text{C}", isCorrect: false },
    ],
  },
  {
    question:
      "Two point charges of +5 µC and -3 µC are placed 2 meters apart. What is the electric potential at a point 1 meter away from the +5 µC charge along the line joining the two charges?",
    answerOptions: [
      { answer: "1.80 \times 10^4 V", isCorrect: true },
      { answer: "2.70 \times 10^4 V", isCorrect: false },
      { answer: "3.60 \times 10^4 V", isCorrect: false },
      { answer: "4.50 \times 10^4 V", isCorrect: false },
    ],
  },
  {
    question:
      "A dielectric material is inserted into a capacitor and increases the capacitance from 10 µF to 40 µF. What is the relative permittivity of the dielectric material?",
    answerOptions: [
      { answer: "4", isCorrect: true },
      { answer: "3", isCorrect: false },
      { answer: "5", isCorrect: false },
      { answer: "6", isCorrect: false },
    ],
  },
  {
    question:
      "An isolated conductor is given a charge of -8 µC. What is the electric potential at a point 0.3 meters from the conductor's surface?",
    answerOptions: [
      { answer: "-2.40 \times 10^4 V", isCorrect: true },
      { answer: "-1.80 \times 10^4 V", isCorrect: false },
      { answer: "-3.00 \times 10^4 V", isCorrect: false },
      { answer: "-1.20 \times 10^4 V", isCorrect: false },
    ],
  },
  {
    question:
      "A capacitor of capacitance 50 µF is connected to a battery of 6V. After charging, the capacitor is disconnected from the battery and connected to another capacitor of 25 µF. What is the final voltage across the capacitors?",
    answerOptions: [
      { answer: "4 V", isCorrect: true },
      { answer: "3 V", isCorrect: false },
      { answer: "2 V", isCorrect: false },
      { answer: "5 V", isCorrect: false },
    ],
  },
  {
    question:
      "What is the magnitude of the electric field at a distance of 0.1 meters from a point charge of +7 µC?",
    answerOptions: [
      { answer: "6.29 \times 10^4 N/C", isCorrect: true },
      { answer: "7.99 \times 10^4 N/C", isCorrect: false },
      { answer: "5.99 \times 10^4 N/C", isCorrect: false },
      { answer: "4.99 \times 10^4 N/C", isCorrect: false },
    ],
  },
  {
    question:
      "A cylindrical capacitor has an inner radius of 0.02 m and an outer radius of 0.04 m. If the length of the cylinder is 0.1 m and the potential difference between the cylinders is 300 V, what is the capacitance?",
    answerOptions: [
      { answer: "0.05 µF", isCorrect: true },
      { answer: "0.10 µF", isCorrect: false },
      { answer: "0.15 µF", isCorrect: false },
      { answer: "0.20 µF", isCorrect: false },
    ],
  },
  {
    question:
      "A point charge of +3 µC is located at the center of a spherical shell with an inner radius of 0.5 m and an outer radius of 1 m. What is the electric field strength at the inner surface of the shell?",
    answerOptions: [
      { answer: "1.08 \times 10^4 N/C", isCorrect: true },
      { answer: "2.16 \times 10^4 N/C", isCorrect: false },
      { answer: "3.24 \times 10^4 N/C", isCorrect: false },
      { answer: "4.32 \times 10^4 N/C", isCorrect: false },
    ],
  },
  {
    question:
      "If a capacitor with a capacitance of 50 µF is connected to a 12 V battery, what is the charge stored in the capacitor?",
    answerOptions: [
      { answer: "600 µC", isCorrect: true },
      { answer: "500 µC", isCorrect: false },
      { answer: "700 µC", isCorrect: false },
      { answer: "400 µC", isCorrect: false },
    ],
  },
  {
    question:
      "The electric field at a distance of 0.4 m from a long charged wire is 5000 N/C. What is the linear charge density of the wire?",
    answerOptions: [
      { answer: "2.8 \times 10^{-7} C/m", isCorrect: true },
      { answer: "3.6 \times 10^{-7} C/m", isCorrect: false },
      { answer: "1.4 \times 10^{-7} C/m", isCorrect: false },
      { answer: "4.5 \times 10^{-7} C/m", isCorrect: false },
    ],
  },
  {
    question:
      "A parallel plate capacitor has a dielectric constant of 6 and a capacitance of 15 µF when the dielectric is inserted. What is the capacitance without the dielectric?",
    answerOptions: [
      { answer: "2.5 µF", isCorrect: true },
      { answer: "3.0 µF", isCorrect: false },
      { answer: "4.0 µF", isCorrect: false },
      { answer: "5.0 µF", isCorrect: false },
    ],
  },
  {
    question:
      "A capacitor with a capacitance of 30 µF is charged to a potential difference of 8 V. If the capacitor is disconnected from the battery and connected to a capacitor of 15 µF, what is the final voltage across both capacitors?",
    answerOptions: [
      { answer: "4.8 V", isCorrect: true },
      { answer: "5.0 V", isCorrect: false },
      { answer: "3.5 V", isCorrect: false },
      { answer: "6.0 V", isCorrect: false },
    ],
  },
];

export default elesticityquestions;
