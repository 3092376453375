const diluteandcollagativequestions = [
    {
        question:"Of the following properties, which are collagitive :",
        answerOptions: [
            {answer: 'vapour pressure', isCorrect: false},
            {answer: 'refravtive index', isCorrect: false},
            {answer: 'boiling point', isCorrect: false},
            {answer: 'depression in freezing point', isCorrect: true},
        ],
    }, {
        question:"In isotonic solutions, how molar concentation related to each other :",
        answerOptions: [
            {answer: 'π = CR', isCorrect: false},
            {answer: 'π = CRT', isCorrect: true},
            {answer: 'π = CR²T', isCorrect: false},
            {answer: 'none of these', isCorrect: false},
        ],
    },  {
        question:"How many type of buffer solutions :",
        answerOptions: [
            {answer: 'one', isCorrect: false},
            {answer: 'two', isCorrect: true},
            {answer: 'three', isCorrect: false},
            {answer: 'four', isCorrect: false},
        ],
    },  {
        question:"Which of the following are colligative property :",
        answerOptions: [
            {answer: 'molar refractivity', isCorrect: false},
            {answer: 'optical rotation', isCorrect: false},
            {answer: 'viscosity', isCorrect: false},
            {answer: 'none of these', isCorrect: true},
        ],
    },  {
        question:"Freezing-point depression is measured by :",
        answerOptions: [
            {answer: 'Beakmann method', isCorrect: false},
            {answer: 'Rast camphor method', isCorrect: false},
            {answer: 'both of these', isCorrect: true},
            {answer: 'none of these', isCorrect: false},
        ],
    },  {
        question:"The process of adsorpation is :",
        answerOptions: [
            {answer: 'exothermic', isCorrect: true},
            {answer: 'endothermic', isCorrect: false},
            {answer: 'absolute zero', isCorrect: false},
            {answer: 'all of these', isCorrect: false},
           
        ],
    }, {
        question:"The law of the relative lowering or vapour pressure was proposed by  :",
        answerOptions: [
            {answer: 'vant Hoff', isCorrect: false},
            {answer: 'Ostward', isCorrect: false},
            {answer: 'Raoult', isCorrect: true},
            {answer: 'Henery', isCorrect: false},
        ],
    },  {
        question:"Physical adsorption is a  :",
        answerOptions: [
            {answer: 'reversible process', isCorrect: true},
            {answer: 'irreversible process', isCorrect: false},
            {answer: 'exothermic process', isCorrect: false},
            {answer: 'none of these', isCorrect: false},
        ],
    },  {
        question:"The adsorption of gases on metal serface is called :",
        answerOptions: [
            {answer: 'calalysis', isCorrect: false},
            {answer: 'occlusion', isCorrect: true},
            {answer: 'adsorption', isCorrect: false},
            {answer: 'absorption', isCorrect: false},
        ],
    },  {
        question:"The vapour pressure of a solution of urea is 736.2 nm at 100°C. What is osmotic pressure of the solution at 15°C. :",
        answerOptions: [
            {answer: '41 atm', isCorrect: false},
            {answer: '41.6 atm', isCorrect: false},
            {answer: '41.1 atm', isCorrect: true},
            {answer: '41.3 atm', isCorrect: false},
        ],
    },  {
        question:"physical adsorption generally decrease with increasing in :",
        answerOptions: [
            {answer: 'conductivity', isCorrect: false},
            {answer: 'pressure', isCorrect: false},
            {answer: 'catylisis', isCorrect: false},
            {answer: 'temperature', isCorrect: true},
        ],
    },  {
        question:"To account for abnormal cases, van't Hoff introduced a factor i known as :",
        answerOptions: [
            {answer: 'dissociation', isCorrect: false},
            {answer: 'conductance', isCorrect: false},
            {answer: 'minimum conductance', isCorrect: false},
            {answer: 'vant Hoff faactor', isCorrect: true},
        ],
    },  {
        question:"Freundlich isotherms is not applicable at  :",
        answerOptions: [
            {answer: 'high pressure', isCorrect: true},
            {answer: 'low pressure', isCorrect: false},
            {answer: '273 K', isCorrect: false},
            {answer: 'room temperature', isCorrect: false},
        ],
    },  {
        question:"A 4% solution of cane suger gave an osmotic pressure of 208.0 cm of Hg at 15°C. Find its molecular mass :",
        answerOptions: [
            {answer: '345.1', isCorrect: false},
            {answer: '345.2', isCorrect: true},
            {answer: '345.3', isCorrect: false},
            {answer: '245.4', isCorrect: false},
        ],
    },  {
        question:"vant Hoff factor is expressed as :",
        answerOptions: [
            {answer: '(i) = 1 - alpha + n(alpha)', isCorrect: false},
            {answer: '(i) = 1 - alpha - n(alpha)/1', isCorrect: false},
            {answer: '(i) = 1 - alpha - n(alpha)', isCorrect: false},
            {answer: '(i) = 1 - alpha + n(alpha)/1', isCorrect: true},
        ],
    },  {
        question:"A vapour pressure of 2.1% of an aqueous solution of non-electrolyte at 100°C is 755 mm :",
        answerOptions: [
            {answer: '58.68', isCorrect: true},
            {answer: '58.69', isCorrect: false},
            {answer: '59.68', isCorrect: false},
            {answer: '59.69', isCorrect: false},
        ],
    },  {
        question:"Multimolecular layers are formed in :",
        answerOptions: [
            {answer: 'adsorpation', isCorrect: false},
            {answer: 'chemisorpation', isCorrect: true},
            {answer: 'irreversible adsorpation', isCorrect: false},
            {answer: 'reversible adsorpation', isCorrect: false},
        ],
    },  {
        question:"The latent heat of fusion of ice is 79.7 cal/g. Calculate the molal depression constant of water :",
        answerOptions: [
            {answer: '1.85 kg mol', isCorrect: false},
            {answer: '1.86 kg mol', isCorrect: true},
            {answer: '1.87 kg mol', isCorrect: false},
            {answer: '1.88 kg mol', isCorrect: false},
        ],
    },  {
        question:"At low pressure, the amount of the gas adsorbed is proportional to the :",
        answerOptions: [
            {answer: 'temperature', isCorrect: false},
            {answer: 'heat', isCorrect: false},
            {answer: 'pressure', isCorrect: true},
            {answer: 'absolute zero', isCorrect: false },
        ],
    },  {
        question:"Depression in freezing point which takes place when the molality of the solution is unity :",
        answerOptions: [
            {answer: 'thermal equilibrium', isCorrect: false},
            {answer: 'phase diagram', isCorrect: false},
            {answer: 'molal depression', isCorrect: true},
            {answer: 'molal constant', isCorrect: false},
        ],
    },
]

export default diluteandcollagativequestions;