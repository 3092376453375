import React from 'react';
import { Link } from 'react-router-dom';
import '../pagesStyle/footer.css'; // Import your CSS file


const Footer = () => {
    return (
        <footer className="footer">
            <p className="footer-text">
                © {new Date().getFullYear()} Xnetous. All rights reserved.
            </p>
            <div className="footer-links">
                <Link to="/terms-and-conditions">Terms and Conditions</Link>
                <Link to="/privacy-policy">Privacy Policy</Link>
                <Link to="/disclaimer">Disclaimer</Link>
                <Link to="/contact-us">Contact Us</Link>
                <Link to="/support">Support</Link>
                <Link to="/about-xnetous-developer-aqueeqazam">About Developer (Aqueeq Azam)</Link>
            </div>
        </footer>
    );
};

export default Footer;
