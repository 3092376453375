import React, { useState, useEffect } from "react";
import { collection, getDocs, query } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { fireDB } from "../config/firebase";

const UserRanking = () => {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState("");

    const navigate = useNavigate();

    const calculateEngagementScore = (questions) => {
        return questions.reduce((totalScore, question) => {
            return (
                totalScore +
                (question.likes || 0) * 1.5 +
                (question.comments?.length || 0) * 1.2 +
                (question.shares || 0) * 1.3 +
                (question.retweetedBy?.length || 0) * 1.1
            );
        }, 0);
    };

    useEffect(() => {
        const fetchUsersAndScores = async () => {
            try {
                // Fetch users data
                const usersQuery = query(collection(fireDB, "users"));
                const userSnapshot = await getDocs(usersQuery);
                const usersList = userSnapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));

                // Fetch quiz questions and calculate engagement scores
                const questionsQuery = query(collection(fireDB, "quizQuestions"));
                const questionSnapshot = await getDocs(questionsQuery);
                const questionsList = questionSnapshot.docs.map((doc) => doc.data());

                // Calculate engagement scores for each user
                const usersWithScores = usersList.map((user) => {
                    const userQuestions = questionsList.filter((q) => q.userId === user.id);
                    const engagementScore = calculateEngagementScore(userQuestions);
                    return {
                        ...user,
                        engagementScore,
                    };
                });

                // Sort users based on their engagement score
                const sortedUsers = usersWithScores.sort((a, b) => b.engagementScore - a.engagementScore);

                setUsers(sortedUsers);
            } catch (error) {
                console.error("Error fetching users or performance data:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchUsersAndScores();
    }, []);

    // Filter users based on search term
    const filteredUsers = users.filter((user) =>
        user.name?.toLowerCase().includes(searchTerm.toLowerCase()) || user.email?.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className="user-ranking" style={{ maxWidth: "600px", margin: "0 auto", padding: "1rem", background: "#f9f9f9" }}>
            <h2 style={{ borderBottom: "1px solid #ddd", paddingBottom: "0.5rem" }}>User Engagement Rankings</h2>
            
            {/* Search input */}
            <input
                type="text"
                placeholder="Search users..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                style={{ marginBottom: "1rem", padding: "0.5rem", width: "100%", borderRadius: "4px", border: "1px solid #ddd" }}
            />

            {loading ? (
                <p>Loading...</p>
            ) : (
                <ol>
                    {filteredUsers.map((user, index) => (
                        <li
                            key={user.id}
                            style={{ padding: "0.5rem", borderBottom: "1px solid #ddd", cursor: "pointer" }}
                            onClick={() => navigate(`/profile/${user.id}`)} // Navigate to UserProfile
                        >
                            <span style={{ fontWeight: "bold" }}>
                                {index + 1}. {user.name || "Unknown User"}
                            </span>
                            <span style={{ float: "right", color: "#888" }}>
                                {user.engagementScore.toFixed(2)} score
                            </span>
                        </li>
                    ))}
                </ol>
            )}
        </div>
    );
};

export default UserRanking;
