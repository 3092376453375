import React, { useState, useEffect } from 'react';
import { fireDB, doc, getDoc, updateDoc, auth, onAuthStateChanged, serverTimestamp  } from '../config/firebase';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import GooglePayButton from '@google-pay/button-react'; // Google Pay button
import { QRCodeCanvas } from "qrcode.react";
import '../componentsStyle/subscription.css';


const SubscriptionPage = () => {
  const [isSubscribed, setIsSubscribed] = useState(null);  // Track if user is subscribed
  const [subscriptionPlan, setSubscriptionPlan] = useState('');  // Current subscription plan
  const [userId, setUserId] = useState(null);  // Logged-in user's ID
  const [loading, setLoading] = useState(true);  // Loading state for async actions
  const [editingSubscription, setEditingSubscription] = useState(false);  // Track editing state
  const navigate = useNavigate();

  // Fetch user authentication and subscription status on component mount
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setUserId(user.uid);
        await checkSubscription(user.uid);  // Fetch subscription details for authenticated user

        const savedPlan = localStorage.getItem('planToSubscribe');  // Check if user saved a subscription plan
        if (savedPlan) {
          await handleSubscription(savedPlan);  // Automatically subscribe to the saved plan
          localStorage.removeItem('planToSubscribe');  // Remove the saved plan after subscribing
        }
      } else {
        setUserId(null);
        setIsSubscribed(false);
        navigate('/login');  // Redirect to login if not authenticated
      }
      setLoading(false);  // Set loading state to false once data is fetched
    });

    return () => unsubscribe();  // Clean up the subscription on unmount
  }, [navigate]);

  // Check the user's subscription status in Firestore
  const checkSubscription = async (uid) => {
    try {
      const userRef = doc(fireDB, 'users', uid);
      const docSnap = await getDoc(userRef);
      if (docSnap.exists()) {
        const userData = docSnap.data();
        setIsSubscribed(!!userData.subscription);  // Set subscription status
        setSubscriptionPlan(userData.subscription || '');  // Set current subscription plan
      } else {
        setIsSubscribed(false);
      }
    } catch (error) {
      console.error('Error checking subscription:', error);
      setIsSubscribed(false);
      toast.error('Failed to check subscription status.');
    }
  };

  // Handle subscribing to a plan
  const handleSubscription = async (plan) => {
    if (!userId) {
      console.error('No user is logged in');
      toast.error('You need to log in first to manage your subscription.');
      localStorage.setItem('planToSubscribe', plan);  // Save the selected plan to local storage
      navigate('/login');
      return;
    }

    try {
      const userRef = doc(fireDB, 'users', userId);
      await updateDoc(userRef, { subscription: plan });  // Update Firestore with the new subscription plan
      setIsSubscribed(true);
      setSubscriptionPlan(plan);  // Update the local state with the new plan
      toast.success(`Successfully subscribed to the ${plan} plan!`);
    } catch (error) {
      console.error('Error updating subscription:', error);
      toast.error('Failed to update the subscription. Please try again.');
    }
  };

  // Handle removing the subscription
  const handleRemoveSubscription = async () => {
    if (!userId) return;

    try {
      const userRef = doc(fireDB, 'users', userId);
      await updateDoc(userRef, { subscription: '' });  // Remove the subscription from Firestore
      setIsSubscribed(false);
      setSubscriptionPlan('');  // Clear the subscription state
      toast.success('Your subscription has been removed.');
    } catch (error) {
      console.error('Error removing subscription:', error);
      toast.error('Failed to remove subscription. Please try again.');
    }
  };

  // Toggle the edit subscription mode
  const toggleEditSubscription = () => {
    setEditingSubscription(!editingSubscription);
  };

  const onSubscribeClick = (plan) => {
    if (!userId) {
      localStorage.setItem('planToSubscribe', plan);  // Save the plan for later if not logged in
      navigate('/login');
    } else {
      handleSubscription(plan);  // Subscribe to the plan directly if logged in
    }
  };

  return (
    <div className="subscription-page">
      <h1>Subscription</h1>
      {loading ? (
        <div>Loading...</div>  // Show loading state until data is fetched
      ) : isSubscribed ? (
        <div>
          <p>Your current subscription plan: <strong>{subscriptionPlan}</strong></p>
          <button onClick={toggleEditSubscription}>
            {editingSubscription ? 'Cancel' : 'Change Subscription'}
          </button>
          {editingSubscription && (
            <div className="subscription-options">
              <button onClick={() => onSubscribeClick('monthly_student')}>
                Subscribe Monthly ($3)
              </button>
              <button onClick={() => onSubscribeClick('yearly_professional')}>
                Subscribe Yearly ($24)
              </button>
              <button onClick={() => onSubscribeClick('lifetime_professional')}>
                Subscribe Lifetime ($120)
              </button>
            </div>
          )}
          <button onClick={handleRemoveSubscription}>
            Remove Subscription
          </button>
        </div>
      ) : (
        <div className="subscription-options">
          <button onClick={() => onSubscribeClick('monthly_student')}>
            Subscribe Monthly ($3)
          </button>
          <button onClick={() => onSubscribeClick('yearly_professional')}>
            Subscribe Yearly ($24)
          </button>
          <button onClick={() => onSubscribeClick('lifetime_professional')}>
            Subscribe Lifetime ($120)
          </button>
        </div>
      )}
    </div>
  );
};

export default SubscriptionPage; 




/*const SubscriptionPage = () => {
  const [isSubscribed, setIsSubscribed] = useState(null);
  const [subscriptionPlan, setSubscriptionPlan] = useState('');
  const [userId, setUserId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showQR, setShowQR] = useState(false);  // Show QR code for Google Pay
  const [paymentURL, setPaymentURL] = useState('');  // Store payment URL
  const [expiryTime, setExpiryTime] = useState(null);  // Store subscription expiry time
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setUserId(user.uid);
        await checkSubscription(user.uid);

        const savedPlan = localStorage.getItem('planToSubscribe');
        if (savedPlan) {
          await handleSubscriptionActivation(savedPlan);
          localStorage.removeItem('planToSubscribe');
        }
      } else {
        setUserId(null);
        setIsSubscribed(false);
        navigate('/login');
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, [navigate]);

  const checkSubscription = async (uid) => {
    try {
      const userRef = doc(fireDB, 'users', uid);
      const docSnap = await getDoc(userRef);
      if (docSnap.exists()) {
        const userData = docSnap.data();
        const subscriptionExpiry = userData.subscription_expiry?.toDate();

        // Check if the subscription has expired
        if (subscriptionExpiry && new Date() > subscriptionExpiry) {
          await removeSubscriptionAutomatically(uid);
        } else {
          setIsSubscribed(!!userData.subscription);
          setSubscriptionPlan(userData.subscription || '');
          setExpiryTime(subscriptionExpiry);
        }
      } else {
        setIsSubscribed(false);
      }
    } catch (error) {
      console.error('Error checking subscription:', error);
      setIsSubscribed(false);
      toast.error('Failed to check subscription status.');
    }
  };

  const handleSubscriptionActivation = async (plan) => {
    if (!userId) {
      toast.error('You need to log in first to manage your subscription.');
      localStorage.setItem('planToSubscribe', plan);
      navigate('/login');
      return;
    }

    try {
      const userRef = doc(fireDB, 'users', userId);

      // Calculate the expiration time based on the plan
      let expirationTime;
      if (plan === 'test_plan_24hr') {
        expirationTime = new Date(Date.now() + 24 * 60 * 60 * 1000); // 24 hours
      } else if (plan === 'monthly_student') {
        expirationTime = new Date();
        expirationTime.setMonth(expirationTime.getMonth() + 1); // 1 month
      } else if (plan === 'yearly_professional') {
        expirationTime = new Date();
        expirationTime.setFullYear(expirationTime.getFullYear() + 1); // 1 year
      }

      await updateDoc(userRef, {
        subscription: plan,
        subscription_expiry: expirationTime, // Save expiration time in Firestore
        timestamp: serverTimestamp(),
      });

      setIsSubscribed(true);
      setSubscriptionPlan(plan);
      setExpiryTime(expirationTime);  // Store the expiration time in state
      toast.success(`Successfully subscribed to the ${plan}!`);

      // Schedule automatic removal based on the plan's expiry
      const timeUntilExpiry = expirationTime - Date.now();
      setTimeout(() => removeSubscriptionAutomatically(userId), timeUntilExpiry);

      // Trigger Google Pay
      openGooglePay(plan);
    } catch (error) {
      console.error('Error activating subscription:', error);
      toast.error('Failed to activate subscription. Please try again.');
    }
  };

  // Function to trigger Google Pay and display the QR code
  const openGooglePay = (plan) => {
    const paymentLink = `https://example.com/pay?plan=${plan}&userId=${userId}`;
    setPaymentURL(paymentLink);
    setShowQR(true);  // Show QR code for payment
  };

  // Function to automatically remove the subscription after it expires
  const removeSubscriptionAutomatically = async (uid) => {
    try {
      const userRef = doc(fireDB, 'users', uid);
      await updateDoc(userRef, {
        subscription: '', // Remove subscription
        subscription_expiry: '', // Clear expiration time
      });
      setIsSubscribed(false);
      setSubscriptionPlan('');
      toast.info('Your subscription has expired.');
    } catch (error) {
      console.error('Error removing subscription automatically:', error);
    }
  };

  // Helper function to calculate time remaining
  const calculateTimeRemaining = () => {
    if (!expiryTime) return null;

    const currentTime = new Date();
    const timeDifference = expiryTime - currentTime;

    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timeDifference / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((timeDifference / (1000 * 60)) % 60);

    return `${days > 0 ? `${days} days ` : ''}${hours} hours ${minutes} minutes`;
  };

  return (
    <div className="subscription-page">
      <h1>Subscription</h1>
      {loading ? (
        <div>Loading...</div>
      ) : isSubscribed ? (
        <div>
          <p>Your current subscription plan: <strong>{subscriptionPlan}</strong></p>
          <p>Expires in {calculateTimeRemaining()}.</p>
        </div>
      ) : (
        <div className="subscription-options">
          <button onClick={() => handleSubscriptionActivation('test_plan_24hr')}>
            Subscribe Test Plan ($0.50 for 24 hours)
          </button>
          <button onClick={() => handleSubscriptionActivation('monthly_student')}>
            Subscribe Monthly ($3)
          </button>
          <button onClick={() => handleSubscriptionActivation('yearly_professional')}>
            Subscribe Yearly ($24)
          </button>
        </div>
      )}

      {showQR && (
        <div className="qr-code-container">
          <h3>Scan to Pay via Google Pay</h3>
          <QRCodeCanvas value={paymentURL} size={256} /> {/* QR Code for Google Pay 
        </div>
      )}

      {showQR && (
        <GooglePayButton
          environment="TEST"
          paymentRequest={{
            apiVersion: 2,
            apiVersionMinor: 0,
            allowedPaymentMethods: [
              {
                type: 'CARD',
                parameters: {
                  allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
                  allowedCardNetworks: ['MASTERCARD', 'VISA'],
                },
                tokenizationSpecification: {
                  type: 'PAYMENT_GATEWAY',
                  parameters: {
                    gateway: 'example',
                    gatewayMerchantId: 'exampleMerchantId',
                  },
                },
              },
            ],
            merchantInfo: {
              merchantId: '01234567890123456789',
              merchantName: 'Example Merchant',
            },
            transactionInfo: {
              totalPriceStatus: 'FINAL',
              totalPriceLabel: 'Total',
              totalPrice: '0.50', // Update dynamically for test plan
              currencyCode: 'USD',
              countryCode: 'US',
            },
          }}
          onLoadPaymentData={(paymentData) => {
            console.log('load payment data', paymentData);
            toast.success('Payment successful!');
          }}
        />
      )}
    </div>
  );
};

export default SubscriptionPage;*/



