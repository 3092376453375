import React, { useState, useEffect, useCallback } from 'react';
import "../../../quizzesStyle/quizproblem.css";
import { auth, fireDB, doc, getDoc  } from "../../../config/firebase";
import analyticalchequestions from '../chemistryData/AnalyticalCheData';
import AnalyticalCheAnswer from '../chemistryAnswer/AnalyticalCheAnswer';
import { onAuthStateChanged } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';



function shuffleArray(array) {
  let currentIndex = array.length;
  let temporaryValue;
  let randomIndex;

  while (currentIndex !== 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}

function AnalyticalChe() {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [quizStarted, setQuizStarted] = useState(false);
  const [timeLeft, setTimeLeft] = useState(120); // Set initial time for each question
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [clicked, setClicked] = useState(false);
  const [correctAnswer, setCurrentAnswer] = useState(0);
  const [score, setScore] = useState(0);
  const [showResult, setShowResult] = useState(false);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [answers, setAnswers] = useState([]);

  const [paused, setPaused] = useState(false);
  const [pausedTimeLeft, setPausedTimeLeft] = useState(null);
  const [attempts, setAttempts] = useState([]);
  const [questionOrder, setQuestionOrder] = useState([]);
  const [jumpToQuestion, setJumpToQuestion] = useState("");
  const [extensions, setExtensions] = useState(0);
  const maxExtensions = 2;
  const [feedback, setFeedback] = useState("");

  const autoResumeDuration = 5000;

  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
        navigate("/login");
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  // Shuffle analyticalchequestions without filtering
  useEffect(() => {
    setQuestionOrder(shuffleArray(analyticalchequestions).map((_, index) => index));
  }, []);

  const totalTimeTaken =
    startTime && endTime ? Math.floor((endTime - startTime) / 1000) : 0;

  const saveState = useCallback(() => {
    const quizState = {
      quizStarted,
      startTime,
      endTime,
      timeLeft,
      currentQuestion,
      score,
      correctAnswer,
      answers,

      paused,

      attempts,
    };
    localStorage.setItem("quizState", JSON.stringify(quizState));
  }, [
    quizStarted,
    startTime,
    endTime,
    timeLeft,
    currentQuestion,
    score,
    correctAnswer,
    answers,

    paused,

    attempts,
  ]);

  const saveAttempt = useCallback(() => {
    const newAttempt = {
      date: new Date().toISOString(),
      score,
      answers,
      timeLeft,
      totalTime: totalTimeTaken,
    };
    const updatedAttempts = [...attempts, newAttempt];
    setAttempts(updatedAttempts);
    localStorage.setItem(
      "quizState",
      JSON.stringify({
        ...JSON.parse(localStorage.getItem("quizState")),
        attempts: updatedAttempts,
      })
    );
  }, [score, answers, timeLeft, totalTimeTaken, attempts]);

  const handleResume = useCallback(() => {
    setPaused(false);
    setTimeLeft(pausedTimeLeft);
    saveState();
  }, [pausedTimeLeft, saveState]);

  useEffect(() => {
    let timerId;
    let autoResumeId;

    if (quizStarted && !paused && timeLeft > 0) {
      timerId = setInterval(() => {
        setTimeLeft((prevTime) => {
          const newTime = prevTime - 1;
          if (newTime <= 0) {
            clearInterval(timerId);
            setShowResult(true);
            setEndTime(new Date());
            saveAttempt();
          }
          saveState();
          return newTime;
        });
      }, 1000);

      return () => clearInterval(timerId);
    } else if (paused && pausedTimeLeft !== null) {
      autoResumeId = setTimeout(() => {
        handleResume();
      }, autoResumeDuration);

      return () => clearTimeout(autoResumeId);
    }

    return () => {
      clearInterval(timerId);
      clearTimeout(autoResumeId);
    };
  }, [
    quizStarted,
    paused,
    timeLeft,
    pausedTimeLeft,
    handleResume,
    saveAttempt,
    saveState,
  ]);

  const handleStartQuiz = () => {
    setQuizStarted(true);
    setStartTime(new Date());
    setShowResult(false);
    setPaused(false);
    setTimeLeft(120); // Set initial time for the quiz
    saveState();
    setFeedback("");
    setExtensions(0);
  };

  // Extend time
  const handleExtendTime = () => {
    if (extensions < maxExtensions) {
      setTimeLeft((prevTime) => prevTime + 30);
      setExtensions((prevExtensions) => prevExtensions + 1);
    }
  };

  // Handle answering a question
  const handleAnswer = (answer, isCorrect) => {
    if (!clicked) {
      const updatedAnswers = [...answers];
      updatedAnswers[currentQuestion] = { answer, isCorrect };
      setAnswers(updatedAnswers);

      if (feedback === "") {
        // Prevent multiple feedbacks
        if (isCorrect) {
          setFeedback("Correct!");
        } else {
          setFeedback("Incorrect!");
        }
      }

      if (isCorrect) {
        setScore((prevScore) => prevScore + 5);
        setCurrentAnswer((prevAnswer) => prevAnswer + 1);
      }
      setSelectedAnswer(answer);
      setClicked(true);
      saveState();
    }
  };

  const handleNext = () => {
    setClicked(false);
    setSelectedAnswer(null);
    const nextQuestion = currentQuestion + 1;
    if (nextQuestion < analyticalchequestions.length) {
      setCurrentQuestion(nextQuestion);
      setFeedback(""); // Clear feedback on moving to next question
      setTimeLeft(120); // Reset time for the next question
    } else {
      setShowResult(true);
      setEndTime(new Date()); // Set the end time when the quiz is completed
    }
  };

  const handlePrevious = () => {
    setClicked(true);
    setSelectedAnswer(null);
    const previousQuestion = currentQuestion - 1;
    if (previousQuestion >= 0) {
      setCurrentQuestion(previousQuestion);
      setTimeLeft(120); // Reset time for the previous question
    }
  };

  const handleSkip = () => {
    setClicked(false);

    setSelectedAnswer(null);
    const nextQuestion = currentQuestion + 1;
    if (nextQuestion < questionOrder.length) {
      setCurrentQuestion(nextQuestion);
      setTimeLeft(120); // Reset time for the next question
    } else {
      setShowResult(true);
      setEndTime(new Date());
      saveAttempt();
    }
    saveState();
  };

  const handlePlayAgain = () => {
    setCurrentQuestion(0);
    setScore(0);
    setCurrentAnswer(0);
    setShowResult(false);
    setClicked(false);
    setSelectedAnswer(null);
    setAnswers([]);
    setTimeLeft(120); // Set initial time for the quiz
    setQuizStarted(false);
    setStartTime(null);
    setEndTime(null);
    setPaused(false);
    setPausedTimeLeft(null);
    setAttempts([]);
    setJumpToQuestion("");

    localStorage.removeItem("quizState");
  };

  const handleQuit = () => {
    setCurrentQuestion(0);
    setScore(0);
    setCurrentAnswer(0);
    setShowResult(false);
    setClicked(false);
    setSelectedAnswer(null);
    setAnswers([]);
    setTimeLeft(120); // Reset time for the quiz
    setQuizStarted(false);
    setStartTime(null);
    setEndTime(null);
    setPaused(false);
    setPausedTimeLeft(null);
    setAttempts([]);
    setJumpToQuestion("");

    localStorage.removeItem("quizState");
  };

  const handlePause = () => {
    setPaused(true);
    setPausedTimeLeft(timeLeft);
    saveState();
  };

  const handleJumpToQuestion = () => {
    const index = parseInt(jumpToQuestion, 10) - 1;
    if (index >= 0 && index < questionOrder.length) {
      setCurrentQuestion(index);
      setTimeLeft(120); // Reset time for the jumped question
    }
  };

  // ... (rest of the code)
  if (isAuthenticated === null) {
    return <div>Loading...</div>;
  }

  if (!isAuthenticated) {
    return <div>Redirecting to login...</div>;
  }

  return (
    <div className="app">
      <h2 className="headline">Analytical Chemistry</h2>
      {showResult ? (
        <AnalyticalCheAnswer
          Score={score}
          CorrectAnswer={correctAnswer}
          answers={answers}
          correctAnswers={analyticalchequestions.map((q) => q.correctAnswer)}
          handlePlayAgain={handlePlayAgain}
          totalTimeTaken={totalTimeTaken}
          attempts={attempts}
        />
      ) : (
        <>
          {!quizStarted ? (
            <div className="start-screen">
    
              <button className="start-button" onClick={handleStartQuiz}>
                Start Quiz
              </button>
            </div>
          ) : (
            <>
              <div className="filters">
                <h4 className="score">
                  Score: <span>{score}</span>
                </h4>
              </div>
              <div className="question">
                <div className="question-count">
                  <span>
                    Question {currentQuestion + 1} of {questionOrder.length}
                  </span>
                  <span
                    className="timer"
                    style={{ color: timeLeft <= 10 ? "red" : "green" }}
                  >
                    Time Left: {timeLeft}s
                  </span>
                  <span className="progress">
                    Progress:{" "}
                    {Math.round(
                      ((currentQuestion + 1) / questionOrder.length) * 100
                    )}
                    %
                  </span>
                </div>

                <div className="question-text">
                  {questionOrder.length > 0
                    ? analyticalchequestions[questionOrder[currentQuestion]]
                        ?.question || "Loading..."
                    : "No analyticalchequestions Available"}
                </div>
              </div>
              <div className="answer">
                {analyticalchequestions[
                  questionOrder[currentQuestion]
                ]?.answerOptions.map((ans, i) => (
                  <button
                    key={i}
                    className={`buttons ${
                      clicked && ans.isCorrect ? "correct" : ""
                    } ${
                      clicked && ans.answer === selectedAnswer && !ans.isCorrect
                        ? "incorrect"
                        : ""
                    }`}
                    onClick={() => handleAnswer(ans.answer, ans.isCorrect)}
                  >
                    {ans.answer}
                  </button>
                ))}

                <div className="actions">
                  <button className="next" onClick={handleSkip}>
                    Skip
                  </button>
                  <button className="pause" onClick={handlePause}>
                    Pause
                  </button>

                  <button
                    className="next"
                    disabled={!clicked}
                    onClick={handleNext}
                  >
                    Next{" "}
                  </button>

                  <button className="previous" onClick={handlePrevious}>
                    Previous{" "}
                  </button>
                  <button className="quit" onClick={handleQuit}>
                    Quit
                  </button>
                </div>
              </div>
              <div
                className="feedback"
                style={{
                  color:
                    feedback === "Correct!"
                      ? "green"
                      : feedback === "Incorrect!"
                      ? "red"
                      : "black",
                }}
              >
                {feedback}
              </div>

              <div className="jump-to">
                <input
                  type="number"
                  min="1"
                  max={questionOrder.length}
                  value={jumpToQuestion}
                  onChange={(e) => setJumpToQuestion(e.target.value)}
                  placeholder="Jump to question"
                />

                <button onClick={handleJumpToQuestion}>Go</button>
                <button className="btn-secondary" onClick={handleExtendTime}>
                  Extend Time (+30s)
                </button>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}


export default AnalyticalChe;
