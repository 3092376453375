const mechaniesquestions = [
  {
    question:
      "A force of 20 N acts on an object for a distance of 4 m. What is the work done?",
    answerOptions: [
      { answer: "80 J", isCorrect: true },
      { answer: "40 J", isCorrect: false },
      { answer: "20 J", isCorrect: false },
      { answer: "10 J", isCorrect: false },
    ],
  },
  {
    question:
      "An object of mass 3 kg is moving with a velocity of 4 m/s. What is its kinetic energy?",
    answerOptions: [
      { answer: "24 J", isCorrect: true },
      { answer: "12 J", isCorrect: false },
      { answer: "6 J", isCorrect: false },
      { answer: "3 J", isCorrect: false },
    ],
  },
  {
    question: "A machine does 200 J of work in 10 s. What is its power output?",
    answerOptions: [
      { answer: "20 W", isCorrect: true },
      { answer: "10 W", isCorrect: false },
      { answer: "50 W", isCorrect: false },
      { answer: "25 W", isCorrect: false },
    ],
  },
  {
    question: "What is the SI unit of energy?",
    answerOptions: [
      { answer: "Joule", isCorrect: true },
      { answer: "Watt", isCorrect: false },
      { answer: "Newton", isCorrect: false },
      { answer: "Kilogram", isCorrect: false },
    ],
  },
  {
    question:
      "An object is lifted to a height of 15 m. What is its potential energy?",
    answerOptions: [
      { answer: "150 J", isCorrect: true },
      { answer: "75 J", isCorrect: false },
      { answer: "30 J", isCorrect: false },
      { answer: "15 J", isCorrect: false },
    ],
  },
  {
    question:
      "A car accelerates from 0 to 30 m/s in 6 s. What is its average power output?",
    answerOptions: [
      { answer: "1125 W", isCorrect: true },
      { answer: "562.5 W", isCorrect: false },
      { answer: "281.25 W", isCorrect: false },
      { answer: "140.625 W", isCorrect: false },
    ],
  },
  {
    question:
      "What is the work done by a force of 10 N acting on an object for a distance of 3 m?",
    answerOptions: [
      { answer: "30 J", isCorrect: true },
      { answer: "15 J", isCorrect: false },
      { answer: "6 J", isCorrect: false },
      { answer: "3 J", isCorrect: false },
    ],
  },
  {
    question:
      "An object of mass 4 kg is moving with a velocity of 5 m/s. What is its kinetic energy?",
    answerOptions: [
      { answer: "50 J", isCorrect: true },
      { answer: "25 J", isCorrect: false },
      { answer: "10 J", isCorrect: false },
      { answer: "5 J", isCorrect: false },
    ],
  },
  {
    question: "A machine does 300 J of work in 15 s. What is its power output?",
    answerOptions: [
      { answer: "20 W", isCorrect: true },
      { answer: "10 W", isCorrect: false },
      { answer: "50 W", isCorrect: false },
      { answer: "25 W", isCorrect: false },
    ],
  },
  {
    question: "What is the SI unit of power?",
    answerOptions: [
      { answer: "Watt", isCorrect: true },
      { answer: "Joule", isCorrect: false },
      { answer: "Newton", isCorrect: false },
      { answer: "Kilogram", isCorrect: false },
    ],
  },
  {
    question:
      "Two balls A and B of same size are dropped from same height under the influence of gravity. The mass of A grater then that of B. If the air resistance acting on each ball is same, then :",
    answerOptions: [
      { answer: "Ball A reach earlier", isCorrect: true },
      { answer: "Ball B reach earlier", isCorrect: false },
      { answer: "Reach simultaneously", isCorrect: false },
      { answer: "Nothing can be said", isCorrect: false },
    ],
  },
  {
    question:
      "There are two planets. The ratio of radius of two planets is k but the ratio of acceleration due to gravity of both planets is g. What will be the ratio of their escape velocity :",
    answerOptions: [
      { answer: "(kg)^1/2", isCorrect: true },
      { answer: "(kg)^-1/2", isCorrect: false },
      { answer: "(kg)²", isCorrect: false },
      { answer: "(kg)^-2", isCorrect: false },
    ],
  },
  {
    question:
      "Three equal weight A, B and C of masses 2 kg each are hanging on a string passing over a fixed frictionless pully while A is connected left side of pully on the other hand B and C are conneced right side of pully one after another. The tension in the string connecting weight  B and C is :",
    answerOptions: [
      { answer: "zero", isCorrect: false },
      { answer: "3.3 N", isCorrect: false },
      { answer: "13 N", isCorrect: true },
      { answer: "19.6 N", isCorrect: false },
    ],
  },
  {
    question:
      "Identify, which of the following energies can be positive only :",
    answerOptions: [
      { answer: "Kenatic", isCorrect: true },
      { answer: "Mechanical", isCorrect: false },
      { answer: "Potential", isCorrect: false },
      { answer: "Impossible to say", isCorrect: false },
    ],
  },
  {
    question:
      "A body of mass m is lifted up from the surface of hte earth to a height three times the radius of earth R. The change in potential energy of the body is :",
    answerOptions: [
      { answer: "3mgR", isCorrect: true },
      { answer: "5/4mgR", isCorrect: false },
      { answer: "3/4mgR", isCorrect: false },
      { answer: "4R/3", isCorrect: false },
    ],
  },
  {
    question:
      "The minimum stopping distance of a stoping car moving with velocity v is x. If the car is moving with velocity 2v, then the minimum stopping distance will be :",
    answerOptions: [
      { answer: "2x", isCorrect: false },
      { answer: "4x", isCorrect: true },
      { answer: "3x", isCorrect: false },
      { answer: "8x", isCorrect: false },
    ],
  },
  {
    question:
      "A froce F = (3t i + 5 j)N acts on a body due to which its displacement varies as S = (2t² i - 5)m. Work done by this force in 2 second is :",
    answerOptions: [
      { answer: "32 J", isCorrect: false },
      { answer: "46 J", isCorrect: false },
      { answer: "24 J", isCorrect: true },
      { answer: "20 J", isCorrect: false },
    ],
  },
  {
    question:
      "A planet has twice the density of earth but the acceleration due to gravity on its surface is exactly the same as on the surface of earth, Its radius in terms of earth's radius R wiil be :",
    answerOptions: [
      { answer: "R/4", isCorrect: false },
      { answer: "R/2", isCorrect: true },
      { answer: "R/3", isCorrect: false },
      { answer: "R/8", isCorrect: false },
    ],
  },
  {
    question:
      "Work done when a foece F = (i+2j+3K) N acting on a particle takes it from the point r = (i+j+k) to the point r = (i-j+k) :",
    answerOptions: [
      { answer: "2 J", isCorrect: false },
      { answer: "-3 J", isCorrect: false },
      { answer: "-1 J", isCorrect: true },
      { answer: "0", isCorrect: false },
    ],
  },
  {
    question:
      "Under the action of a force, a 2 kg body moves such that its position x as a function of time is given by x = t^3/3, where x is in meter and t is in second. The work done by a force in the first two seconds is :",
    answerOptions: [
      { answer: "1600 J", isCorrect: false },
      { answer: "16 J", isCorrect: true },
      { answer: "160 J", isCorrect: false },
      { answer: "1.6 J", isCorrect: false },
    ],
  },
  {
    question:
      "A 2 kg object is moving with a velocity of 6 m/s. What is its kinetic energy?",
    answerOptions: [
      { answer: "36 J", isCorrect: true },
      { answer: "18 J", isCorrect: false },
      { answer: "12 J", isCorrect: false },
      { answer: "6 J", isCorrect: false },
    ],
  },
  {
    question: "A machine does 400 J of work in 20 s. What is its power output?",
    answerOptions: [
      { answer: "20 W", isCorrect: true },
      { answer: "10 W", isCorrect: false },
      { answer: "40 W", isCorrect: false },
      { answer: "80 W", isCorrect: false },
    ],
  },
  {
    question:
      "An object is lifted to a height of 25 m. What is its potential energy?",
    answerOptions: [
      { answer: "250 J", isCorrect: true },
      { answer: "125 J", isCorrect: false },
      { answer: "50 J", isCorrect: false },
      { answer: "25 J", isCorrect: false },
    ],
  },
  {
    question:
      "A car accelerates from 0 to 40 m/s in 8 s. What is its average power output?",
    answerOptions: [
      { answer: "1600 W", isCorrect: true },
      { answer: "800 W", isCorrect: false },
      { answer: "400 W", isCorrect: false },
      { answer: "200 W", isCorrect: false },
    ],
  },
  {
    question:
      "What is the work done by a force of 15 N acting on an object for a distance of 2 m?",
    answerOptions: [
      { answer: "30 J", isCorrect: true },
      { answer: "15 J", isCorrect: false },
      { answer: "10 J", isCorrect: false },
      { answer: "5 J", isCorrect: false },
    ],
  },
  {
    question:
      "An object of mass 5 kg is moving with a velocity of 8 m/s. What is its kinetic energy?",
    answerOptions: [
      { answer: "160 J", isCorrect: true },
      { answer: "80 J", isCorrect: false },
      { answer: "40 J", isCorrect: false },
      { answer: "20 J", isCorrect: false },
    ],
  },
  {
    question: "A machine does 600 J of work in 30 s. What is its power output?",
    answerOptions: [
      { answer: "20 W", isCorrect: true },
      { answer: "10 W", isCorrect: false },
      { answer: "30 W", isCorrect: false },
      { answer: "60 W", isCorrect: false },
    ],
  },
  {
    question:
      "What is the potential energy of an object of mass 3 kg at a height of 20 m?",
    answerOptions: [
      { answer: "600 J", isCorrect: true },
      { answer: "300 J", isCorrect: false },
      { answer: "150 J", isCorrect: false },
      { answer: "75 J", isCorrect: false },
    ],
  },
  {
    question:
      "A car travels a distance of 500 m in 20 s. What is its average power output if it accelerates uniformly from rest?",
    answerOptions: [
      { answer: "2500 W", isCorrect: true },
      { answer: "1250 W", isCorrect: false },
      { answer: "625 W", isCorrect: false },
      { answer: "312.5 W", isCorrect: false },
    ],
  },
  {
    question:
      "What is the kinetic energy of an object of mass 2 kg moving with a velocity of 10 m/s?",
    answerOptions: [
      { answer: "100 J", isCorrect: true },
      { answer: "50 J", isCorrect: false },
      { answer: "25 J", isCorrect: false },
      { answer: "12.5 J", isCorrect: false },
    ],
  },
  {
    question:
      "If M is the mass of the earth and R its radius, the ratio of the gravitational acceleration and the gravitational constant is :",
    answerOptions: [
      { answer: "R²/M", isCorrect: false },
      { answer: "M/R²", isCorrect: true },
      { answer: "MR²", isCorrect: false },
      { answer: "M/R", isCorrect: false },
    ],
  },
  {
    question:
      "Power applied to a particles varies with time as P = (3t² - 2t + 1) W, where t is sin second. Find the change in its kenatic energy betwee time t = 2s and time = 4s :",
    answerOptions: [
      { answer: "32 J", isCorrect: false },
      { answer: "46 J", isCorrect: true },
      { answer: "61 J", isCorrect: false },
      { answer: "102 J", isCorrect: false },
    ],
  },
  {
    question:
      "The height above the surface of a earth at which the gravitational field intensity is reduced to 1% of its value on the surface of the earth is :",
    answerOptions: [
      { answer: "100Re", isCorrect: false },
      { answer: "10R3", isCorrect: false },
      { answer: "99Re", isCorrect: false },
      { answer: "9Re", isCorrect: true },
    ],
  },
  {
    question:
      "For a body to escape from earth from at which it should be fired is :",
    answerOptions: [
      { answer: "60°", isCorrect: false },
      { answer: "0", isCorrect: false },
      { answer: "any angle", isCorrect: true },
      { answer: "90°", isCorrect: false },
    ],
  },
  {
    question:
      "A ball of mass 12 kg and  another of mass 6 kg are dropped from a 60 feet tall building. After a fall of 30 feet each, towards earth, there kenatic energy willbe in the ratio of :",
    answerOptions: [
      { answer: "√(2) : 1", isCorrect: false },
      { answer: "1 : 4", isCorrect: false },
      { answer: "2 : 1", isCorrect: true },
      { answer: "1 : √(2)", isCorrect: false },
    ],
  },
  {
    question:
      "A particle moves along the x-axis from x = 0 to x = 5m under the influence of a force given by F = (7 - 2x + 3x²). The work done in the process is :",
    answerOptions: [
      { answer: "360 J", isCorrect: false },
      { answer: "85 J", isCorrect: false },
      { answer: "185 J", isCorrect: false },
      { answer: "135 J", isCorrect: true },
    ],
  },
  {
    question:
      "The potentail energy function os a particle in y-z plane is expressed by U = m(y+z), where m is a constant. the work done by the conservatie force in oving a particles from (1, 1) to (2, 3) will be :  (Xnetous chellanger)",
    answerOptions: [
      { answer: "-3m", isCorrect: true },
      { answer: "+3m", isCorrect: false },
      { answer: "m", isCorrect: false },
      { answer: "Nothing can be said", isCorrect: false },
    ],
  },
  {
    question:
      "The gravitational potential energy of a body at a distance r from the centre of earth is U. Its weight at a distance 2r from the center of earth is : ",
    answerOptions: [
      { answer: "U/r", isCorrect: false },
      { answer: "U/2r", isCorrect: false },
      { answer: "U/4r", isCorrect: true },
      { answer: "U/8r", isCorrect: false },
    ],
  },
  {
    question:
      "The potential energy function for a diatimic molecules si U(x) = a/x^12 - b/x^6. In stable equilibrium, the distance between the particles is : ",
    answerOptions: [
      { answer: "(2a/b)^1/6", isCorrect: true },
      { answer: "(a/b)^1/6", isCorrect: false },
      { answer: "(b/2a)^1/6", isCorrect: false },
      { answer: "(b/a)^1/6", isCorrect: false },
    ],
  },
  {
    question:
      "A body takes time t to reach the bottom of a smooth inclined plane of angle x with the horizontal. If the plane is made rough, time taken now is 2t. The coefficient of friction of the rough surface is :",
    answerOptions: [
      { answer: "3/4 tan x", isCorrect: false },
      { answer: "2/3 tan x", isCorrect: false },
      { answer: "1/4 tan x", isCorrect: true },
      { answer: "1/2 tan x", isCorrect: false },
    ],
  },
  {
    question:
      "If the gravitational field intensity at a given by g = GM/r², Then the potential at distance r is :",
    answerOptions: [
      { answer: "-2GM/3r^1.5", isCorrect: true },
      { answer: "-GM/3r^3.5", isCorrect: false },
      { answer: "GM/3r^1.5", isCorrect: false },
      { answer: "-GM/3r^3.5", isCorrect: false },
    ],
  },
  {
    question:
      "A mass m is at a distance a from one end of a uniform rod of length l and mass M. The gravitational force on the mass due to the rod is :",
    answerOptions: [
      { answer: "GMm/(a+l)²", isCorrect: false },
      { answer: "GMm/a(l+a)", isCorrect: true },
      { answer: "GMm/a²", isCorrect: false },
      { answer: "GMm/2(l+a)²", isCorrect: false },
    ],
  },
  {
    question:
      "A force F1 accelerates a particle from rest to a velocity v. Another force F2 decelerates the same particle from v to rest, then :",
    answerOptions: [
      { answer: "F1 = F2", isCorrect: false },
      { answer: "F2 > F1", isCorrect: false },
      { answer: "F2 < F1", isCorrect: false },
      { answer: "All of these", isCorrect: true },
    ],
  },
  {
    question:
      "A bullet moving with a speed of 100m/s can jump penerates into two planks of equal thickness. Then then number of such planks, if the speed is doubled will be :",
    answerOptions: [
      { answer: "6", isCorrect: false },
      { answer: "10", isCorrect: false },
      { answer: "4", isCorrect: false },
      { answer: "8", isCorrect: true },
    ],
  },
  {
    question:
      "A block of mass 4 kg is placed on a rough horizontal plane. A time dependent horizontal force F = kt acts on the block, k = 2N/s. The frictional force between the block and plane at time t = 2s is (μ = 0.2) :",
    answerOptions: [
      { answer: "4 N", isCorrect: false },
      { answer: "8 N", isCorrect: true },
      { answer: "12 N", isCorrect: false },
      { answer: "10 N", isCorrect: false },
    ],
  },
  {
    question:
      "A block rests on a rough inclined plane making an angle 30° with horizontal. The coefficient of static friction between the block and inclined plane is 0.8. If the fricitional force on the block is 10 N, the mass of the block in kg is (g = 10m/s²) :",
    answerOptions: [
      { answer: "2.0", isCorrect: true },
      { answer: "4.0", isCorrect: false },
      { answer: "1.6", isCorrect: false },
      { answer: "2.5", isCorrect: false },
    ],
  },
  {
    question:
      "A 1500 kg car travels up a hill with an incline of 12°. If the car's velocity is 20 m/s, what is the force of gravity acting on it?",
    answerOptions: [
      { answer: "2400 N", isCorrect: true },
      { answer: "1200 N", isCorrect: false },
      { answer: "600 N", isCorrect: false },
      { answer: "300 N", isCorrect: false },
    ],
  },
  {
    question:
      "A 200 kg object is moving in a circular path with a radius of 9 m. If its angular velocity is decreasing at a rate of 1.5 rad/s², what is the magnitude of the net torque acting on the object?",
    answerOptions: [
      { answer: "2700 Nm", isCorrect: true },
      { answer: "1350 Nm", isCorrect: false },
      { answer: "675 Nm", isCorrect: false },
      { answer: "337.5 Nm", isCorrect: false },
    ],
  },
  {
    question:
      "A 1000 kg elevator accelerates upward at 1.2 m/s². What is the tension in the elevator cable?",
    answerOptions: [
      { answer: "2400 N", isCorrect: true },
      { answer: "1200 N", isCorrect: false },
      { answer: "600 N", isCorrect: false },
      { answer: "300 N", isCorrect: false },
    ],
  },
  {
    question:
      "A 50 kg person does 3000 J of work in 10 seconds. What is their average power output?",
    answerOptions: [
      { answer: "300 W", isCorrect: true },
      { answer: "150 W", isCorrect: false },
      { answer: "75 W", isCorrect: false },
      { answer: "37.5 W", isCorrect: false },
    ],
  },
  {
    question:
      "A 2000 kg truck accelerates from 0 to 25 m/s in 15 seconds. What is the average power output of the truck's engine?",
    answerOptions: [
      { answer: "8333 W", isCorrect: true },
      { answer: "4166 W", isCorrect: false },
      { answer: "2083 W", isCorrect: false },
      { answer: "1041 W", isCorrect: false },
    ],
  },
  {
    question:
      "A 100 kg object is moving in a circular path with a radius of 6 m. If its angular velocity is increasing at a rate of 2.5 rad/s², what is the magnitude of the net torque acting on the object?",
    answerOptions: [
      { answer: "1500 Nm", isCorrect: true },
      { answer: "750 Nm", isCorrect: false },
      { answer: "375 Nm", isCorrect: false },
      { answer: "187.5 Nm", isCorrect: false },
    ],
  },
  {
    question:
      "A 1500 kg car travels down a hill with an incline of 10°. If the car's velocity is 30 m/s, what is the force of gravity acting on it?",
    answerOptions: [
      { answer: "2250 N", isCorrect: true },
      { answer: "1125 N", isCorrect: false },
      { answer: "562.5 N", isCorrect: false },
      { answer: "281.25 N", isCorrect: false },
    ],
  },
  {
    question:
      "A 500 kg elevator accelerates upward at 1.5 m/s². What is the tension in the elevator cable?",
    answerOptions: [
      { answer: "2250 N", isCorrect: true },
      { answer: "1125 N", isCorrect: false },
      { answer: "562.5 N", isCorrect: false },
      { answer: "281.25 N", isCorrect: false },
    ],
  },
  {
    question:
      "A 200 kg object is moving in a circular path with a radius of 8 m. If its angular velocity is decreasing at a rate of 2 rad/s², what is the magnitude of the net torque acting on the object?",
    answerOptions: [
      { answer: "3200 Nm", isCorrect: true },
      { answer: "1600 Nm", isCorrect: false },
      { answer: "800 Nm", isCorrect: false },
      { answer: "400 Nm", isCorrect: false },
    ],
  },
  {
    question:
      "For a planet revolving around sun, if a and b are the respective sami major and sami minor axes, then the square of its of its time period is proportional :",
    answerOptions: [
      { answer: "(a/2 + b/2)²", isCorrect: false },
      { answer: "(a/2 + b/2)^3", isCorrect: false },
      { answer: "b^3", isCorrect: false },
      { answer: "a^3", isCorrect: true },
    ],
  },
  {
    question:
      "A ball is dropped onto a floor from a height of 10 m. If 20% of its initial energy is lost, then the height os bounce is :",
    answerOptions: [
      { answer: "8 m", isCorrect: true },
      { answer: "4 m", isCorrect: false },
      { answer: "2 m", isCorrect: false },
      { answer: "6.4 m", isCorrect: false },
    ],
  },
  {
    question:
      "A man of mass m slide down along a rope which is connected to the celing of an elevator with deceleration a relative to the rope. If the elevator is going upward with an acceleration a relative to the ground, then tension in hte rope is :",
    answerOptions: [
      { answer: "mg", isCorrect: false },
      { answer: "m(g+2a)", isCorrect: true },
      { answer: "m(g+a)", isCorrect: false },
      { answer: "0", isCorrect: false },
    ],
  },
  {
    question:
      "A spring force constant k is cut in two parts at its one-third length. When both the parts are strached by same amount. The work done in hte two parts will be :",
    answerOptions: [
      { answer: "equal in both", isCorrect: false },
      { answer: "greater for longer parts", isCorrect: false },
      { answer: "greater for shorter parts", isCorrect: true },
      { answer: "Impossible to say", isCorrect: false },
    ],
  },
  {
    question:
      "A force of 50 N is applied to move a 10 kg block horizontally with an acceleration of 2 m/s² over a distance of 5 m. What is the work done by the force?",
    answerOptions: [
      { answer: "250 J", isCorrect: true },
      { answer: "500 J", isCorrect: false },
      { answer: "100 J", isCorrect: false },
      { answer: "750 J", isCorrect: false },
    ],
  },
  {
    question:
      "A 10 kg object is lifted vertically to a height of 8 m. What is the potential energy gained by the object? (g = 9.8 m/s²)",
    answerOptions: [
      { answer: "784 J", isCorrect: true },
      { answer: "800 J", isCorrect: false },
      { answer: "700 J", isCorrect: false },
      { answer: "900 J", isCorrect: false },
    ],
  },
  {
    question:
      "A 500 W motor is used to lift a load of 100 kg. How long will it take to lift the load to a height of 10 m? (g = 9.8 m/s²)",
    answerOptions: [
      { answer: "19.6 seconds", isCorrect: true },
      { answer: "25 seconds", isCorrect: false },
      { answer: "15 seconds", isCorrect: false },
      { answer: "12.5 seconds", isCorrect: false },
    ],
  },
  {
    question:
      "A spring with a spring constant of 200 N/m is compressed by 0.1 m. What is the elastic potential energy stored in the spring?",
    answerOptions: [
      { answer: "1 J", isCorrect: true },
      { answer: "2 J", isCorrect: false },
      { answer: "0.5 J", isCorrect: false },
      { answer: "4 J", isCorrect: false },
    ],
  },
  {
    question:
      "A 20 kg object is at rest on a frictionless surface. A force of 100 N is applied for 4 seconds. What is the work done by the force?",
    answerOptions: [
      { answer: "800 J", isCorrect: true },
      { answer: "1600 J", isCorrect: false },
      { answer: "400 J", isCorrect: false },
      { answer: "1200 J", isCorrect: false },
    ],
  },
  {
    question:
      "An object is dropped from a height of 20 m. What is its speed just before hitting the ground? (Ignore air resistance, g = 9.8 m/s²)",
    answerOptions: [
      { answer: "19.8 m/s", isCorrect: true },
      { answer: "14 m/s", isCorrect: false },
      { answer: "28 m/s", isCorrect: false },
      { answer: "9.8 m/s", isCorrect: false },
    ],
  },
  {
    question:
      "A 2500 kg truck travels up a hill with an incline of 15°. If the truck's velocity is 20 m/s, what is the force of gravity acting on it?",
    answerOptions: [
      { answer: "3750 N", isCorrect: true },
      { answer: "1875 N", isCorrect: false },
      { answer: "937.5 N", isCorrect: false },
      { answer: "468.75 N", isCorrect: false },
    ],
  },
  {
    question:
      "A 150 kg person does 4000 J of work in 12 seconds. What is their average power output?",
    answerOptions: [
      { answer: "333 W", isCorrect: true },
      { answer: "166 W", isCorrect: false },
      { answer: "83 W", isCorrect: false },
      { answer: "41.5 W", isCorrect: false },
    ],
  },
  {
    question:
      "A 1000 kg elevator accelerates upward at 1.8 m/s². What is the tension in the elevator cable?",
    answerOptions: [
      { answer: "2880 N", isCorrect: true },
      { answer: "1440 N", isCorrect: false },
      { answer: "720 N", isCorrect: false },
      { answer: "360 N", isCorrect: false },
    ],
  },
  {
    question:
      "A 200 kg object is moving in a circular path with a radius of 10 m. If its angular velocity is increasing at a rate of 3 rad/s², what is the magnitude of the net torque acting on the object?",
    answerOptions: [
      { answer: "6000 Nm", isCorrect: true },
      { answer: "3000 Nm", isCorrect: false },
      { answer: "1500 Nm", isCorrect: false },
      { answer: "750 Nm", isCorrect: false },
    ],
  },
  {
    question:
      "A 1500 kg car travels down a hill with an incline of 12°. If the car's velocity is 35 m/s, what is the force of gravity acting on it?",
    answerOptions: [
      { answer: "3150 N", isCorrect: true },
      { answer: "1575 N", isCorrect: false },
      { answer: "787.5 N", isCorrect: false },
      { answer: "393.75 N", isCorrect: false },
    ],
  },
  {
    question:
      "A 500 kg elevator accelerates upward at 2 m/s². What is the tension in the elevator cable?",
    answerOptions: [
      { answer: "3000 N", isCorrect: true },
      { answer: "1500 N", isCorrect: false },
      { answer: "750 N", isCorrect: false },
      { answer: "375 N", isCorrect: false },
    ],
  },
  {
    question:
      "A 2000 kg truck accelerates from 0 to 30 m/s in 18 seconds. What is the average power output of the truck's engine?",
    answerOptions: [
      { answer: "11111 W", isCorrect: true },
      { answer: "5555 W", isCorrect: false },
      { answer: "2777 W", isCorrect: false },
      { answer: "1388 W", isCorrect: false },
    ],
  },
  {
    question:
      "A 100 kg object is moving in a circular path with a radius of 7 m. If its angular velocity is decreasing at a rate of 2.5 rad/s², what is the magnitude of the net torque acting on the object?",
    answerOptions: [
      { answer: "1750 Nm", isCorrect: true },
      { answer: "875 Nm", isCorrect: false },
      { answer: "437.5 Nm", isCorrect: false },
      { answer: "218.75 Nm", isCorrect: false },
    ],
  },
  {
    question:
      "A 2500 kg truck travels up a hill with an incline of 18°. If the truck's velocity is 25 m/s, what is the force of gravity acting on it?",
    answerOptions: [
      { answer: "4500 N", isCorrect: true },
      { answer: "2250 N", isCorrect: false },
      { answer: "1125 N", isCorrect: false },
      { answer: "562.5 N", isCorrect: false },
    ],
  },
  {
    question:
      "A cyclist generates 600 W of power while pedaling. If the cyclist maintains this power for 30 minutes, how much energy is expended?",
    answerOptions: [
      { answer: "1080 kJ", isCorrect: true },
      { answer: "1800 kJ", isCorrect: false },
      { answer: "360 kJ", isCorrect: false },
      { answer: "720 kJ", isCorrect: false },
    ],
  },
  {
    question:
      "A 5 kg block is pulled along a horizontal surface with a force of 20 N. If the block moves 10 m, what is the work done?",
    answerOptions: [
      { answer: "200 J", isCorrect: true },
      { answer: "100 J", isCorrect: false },
      { answer: "50 J", isCorrect: false },
      { answer: "25 J", isCorrect: false },
    ],
  },
  {
    question:
      "A satellite orbits the Earth at an altitude of 400 km. If its orbital velocity is 7.5 km/s, what is its centripetal force?",
    answerOptions: [
      { answer: "4500 N", isCorrect: true },
      { answer: "2250 N", isCorrect: false },
      { answer: "1125 N", isCorrect: false },
      { answer: "562.5 N", isCorrect: false },
    ],
  },
  {
    question:
      "A 10 kg object is moving at 5 m/s. If a force of 20 N is applied in the opposite direction, what is the object's deceleration?",
    answerOptions: [
      { answer: "2 m/s^2", isCorrect: true },
      { answer: "1 m/s^2", isCorrect: false },
      { answer: "0.5 m/s^2", isCorrect: false },
      { answer: "0.25 m/s^2", isCorrect: false },
    ],
  },
  {
    question:
      "A car accelerates from 0 to 30 m/s in 6 seconds. What is its average power output?",
    answerOptions: [
      { answer: "3750 W", isCorrect: true },
      { answer: "1875 W", isCorrect: false },
      { answer: "937.5 W", isCorrect: false },
      { answer: "468.75 W", isCorrect: false },
    ],
  },
  {
    question:
      "A force of 50 N is applied to a 20 kg box, causing it to move 15 m. What is the work done?",
    answerOptions: [
      { answer: "750 J", isCorrect: true },
      { answer: "375 J", isCorrect: false },
      { answer: "187.5 J", isCorrect: false },
      { answer: "93.75 J", isCorrect: false },
    ],
  },
  {
    question:
      "A skater glides along a horizontal surface with an initial velocity of 10 m/s. If she comes to rest after 30 seconds, what is her average deceleration?",
    answerOptions: [
      { answer: "0.33 m/s^2", isCorrect: true },
      { answer: "0.67 m/s^2", isCorrect: false },
      { answer: "1 m/s^2", isCorrect: false },
      { answer: "1.33 m/s^2", isCorrect: false },
    ],
  },
  {
    question:
      "A 50 kg person climbs a staircase with a height of 20 m. If they climb at a constant rate, what is their power output?",
    answerOptions: [
      { answer: "490 W", isCorrect: true },
      { answer: "245 W", isCorrect: false },
      { answer: "122.5 W", isCorrect: false },
      { answer: "61.25 W", isCorrect: false },
    ],
  },
  {
    question:
      "A car travels up a hill with an incline of 15°. If the car's velocity is 20 m/s, what is the force of gravity acting on it?",
    answerOptions: [
      { answer: "1200 N", isCorrect: true },
      { answer: "600 N", isCorrect: false },
      { answer: "300 N", isCorrect: false },
      { answer: "150 N", isCorrect: false },
    ],
  },
  {
    question:
      "A 10 kg object is moving in a circular path with a radius of 5 m. If its velocity is 5 m/s, what is its centripetal acceleration?",
    answerOptions: [
      { answer: "5 m/s^2", isCorrect: true },
      { answer: "2.5 m/s^2", isCorrect: false },
      { answer: "1.25 m/s^2", isCorrect: false },
      { answer: "0.625 m/s^2", isCorrect: false },
    ],
  },
  {
    question:
      "A 20 kg block is attached to a horizontal, massless spring with a force constant of 100 N/m. The block is displaced by 0.2 m from its equilibrium position and released. What is the block's kinetic energy when it passes through the equilibrium position?",
    answerOptions: [
      { answer: "40 J", isCorrect: true },
      { answer: "20 J", isCorrect: false },
      { answer: "10 J", isCorrect: false },
      { answer: "5 J", isCorrect: false },
    ],
  },
  {
    question:
      "A 50 kg person is standing on a platform that is rotating with an angular velocity of 2 rad/s. If the person moves to the edge of the platform, which has a radius of 5 m, what is their new tangential velocity?",
    answerOptions: [
      { answer: "10 m/s", isCorrect: true },
      { answer: "5 m/s", isCorrect: false },
      { answer: "2.5 m/s", isCorrect: false },
      { answer: "1.25 m/s", isCorrect: false },
    ],
  },
  {
    question:
      "A 10 kg object is moving in a circular path with a radius of 3 m. If its angular velocity is increasing at a rate of 2 rad/s², what is the magnitude of the net torque acting on the object?",
    answerOptions: [
      { answer: "60 Nm", isCorrect: true },
      { answer: "30 Nm", isCorrect: false },
      { answer: "15 Nm", isCorrect: false },
      { answer: "7.5 Nm", isCorrect: false },
    ],
  },
  {
    question:
      "A car is traveling up a hill with an incline of 20°. If the car's velocity is 25 m/s, what is the force of gravity acting on it?",
    answerOptions: [
      { answer: "1500 N", isCorrect: true },
      { answer: "750 N", isCorrect: false },
      { answer: "375 N", isCorrect: false },
      { answer: "187.5 N", isCorrect: false },
    ],
  },
  {
    question:
      "A 5 kg block is attached to a vertical, massless spring with a force constant of 50 N/m. The block is displaced by 0.1 m from its equilibrium position and released. What is the block's potential energy when it passes through the equilibrium position?",
    answerOptions: [
      { answer: "25 J", isCorrect: true },
      { answer: "12.5 J", isCorrect: false },
      { answer: "6.25 J", isCorrect: false },
      { answer: "3.125 J", isCorrect: false },
    ],
  },
  {
    question:
      "A 20 kg object is moving in a circular path with a radius of 4 m. If its angular velocity is decreasing at a rate of 1 rad/s², what is the magnitude of the net torque acting on the object?",
    answerOptions: [
      { answer: "80 Nm", isCorrect: true },
      { answer: "40 Nm", isCorrect: false },
      { answer: "20 Nm", isCorrect: false },
      { answer: "10 Nm", isCorrect: false },
    ],
  },
  {
    question:
      "A car accelerates from 0 to 30 m/s in 10 seconds. If the car's mass is 1500 kg, what is its average power output?",
    answerOptions: [
      { answer: "11250 W", isCorrect: true },
      { answer: "5625 W", isCorrect: false },
      { answer: "2812.5 W", isCorrect: false },
      { answer: "1406.25 W", isCorrect: false },
    ],
  },
  {
    question:
      "A 10 kg object is moving in a circular path with a radius of 2 m. If its angular velocity is increasing at a rate of 3 rad/s², what is the magnitude of the net torque acting on the object?",
    answerOptions: [
      { answer: "60 Nm", isCorrect: true },
      { answer: "30 Nm", isCorrect: false },
      { answer: "15 Nm", isCorrect: false },
      { answer: "7.5 Nm", isCorrect: false },
    ],
  },
  {
    question:
      "A 20 kg block is attached to a horizontal, massless spring with a force constant of 200 N/m. The block is displaced by 0.4 m from its equilibrium position and released. What is the block's kinetic energy when it passes through the equilibrium position?",
    answerOptions: [
      { answer: "160 J", isCorrect: true },
      { answer: "80 J", isCorrect: false },
      { answer: "40 J", isCorrect: false },
      { answer: "20 J", isCorrect: false },
    ],
  },
  {
    question:
      "A 10 kg object is moving in a circular path with a radius of 6 m. If its angular velocity is decreasing at a rate of 2 rad/s², what is the magnitude of the net torque acting on the object?",
    answerOptions: [
      { answer: "120 Nm", isCorrect: true },
      { answer: "60 Nm", isCorrect: false },
      { answer: "30 Nm", isCorrect: false },
      { answer: "15 Nm", isCorrect: false },
    ],
  },
  {
    question:
      "A car is traveling up a hill with an incline of 25°. If the car's velocity is 20 m/s, what is the force of gravity acting on it?",
    answerOptions: [
      { answer: "1200 N", isCorrect: true },
      { answer: "600 N", isCorrect: false },
      { answer: "300 N", isCorrect: false },
      { answer: "150 N", isCorrect: false },
    ],
  },
  {
    question:
      "A 5 kg block is attached to a vertical, massless spring with a force constant of 100 N/m. The block is displaced by 0.2 m from its equilibrium position and released. What is the block's potential energy when it passes through the equilibrium position?",
    answerOptions: [
      { answer: "50 J", isCorrect: true },
      { answer: "25 J", isCorrect: false },
      { answer: "12.5 J", isCorrect: false },
      { answer: "6.25 J", isCorrect: false },
    ],
  },
  {
    question:
      "A 20 kg object is moving in a circular path with a radius of 8 m. If its angular velocity is increasing at a rate of 1 rad/s², what is the magnitude of the net torque acting on the object?",
    answerOptions: [
      { answer: "160 Nm", isCorrect: true },
      { answer: "80 Nm", isCorrect: false },
      { answer: "40 Nm", isCorrect: false },
      { answer: "20 Nm", isCorrect: false },
    ],
  },
  {
    question:
      "A car accelerates from 0 to 40 m/s in 15 seconds. If the car's mass is 2000 kg, what is its average power output?",
    answerOptions: [
      { answer: "21333 W", isCorrect: true },
      { answer: "10666 W", isCorrect: false },
      { answer: "5333 W", isCorrect: false },
      { answer: "2666 W", isCorrect: false },
    ],
  },
  {
    question:
      "A 10 kg object is moving in a circular path with a radius of 4 m. If its angular velocity is decreasing at a rate of 3 rad/s², what is the magnitude of the net torque acting on the object?",
    answerOptions: [
      { answer: "120 Nm", isCorrect: true },
      { answer: "60 Nm", isCorrect: false },
      { answer: "30 Nm", isCorrect: false },
      { answer: "15 Nm", isCorrect: false },
    ],
  },
  {
    question:
      "A 50 kg person is standing on a platform that is rotating with an angular velocity of 2 rad/s. If the person moves to the edge of the platform, which has a radius of 6 m, what is their new tangential velocity?",
    answerOptions: [
      { answer: "12 m/s", isCorrect: true },
      { answer: "6 m/s", isCorrect: false },
      { answer: "3 m/s", isCorrect: false },
      { answer: "1.5 m/s", isCorrect: false },
    ],
  },
  {
    question:
      "A 2000 kg truck accelerates from 0 to 20 m/s in 10 seconds. What is the average power output of the truck's engine?",
    answerOptions: [
      { answer: "8000 W", isCorrect: true },
      { answer: "4000 W", isCorrect: false },
      { answer: "2000 W", isCorrect: false },
      { answer: "1000 W", isCorrect: false },
    ],
  },
  {
    question:
      "A 50 kg person climbs a staircase with a height of 15 m in 3 minutes. What is their average power output?",
    answerOptions: [
      { answer: "125 W", isCorrect: true },
      { answer: "62.5 W", isCorrect: false },
      { answer: "31.25 W", isCorrect: false },
      { answer: "15.625 W", isCorrect: false },
    ],
  },
  {
    question:
      "A 1000 kg car travels up a hill with an incline of 10°. If the car's velocity is 25 m/s, what is the force of gravity acting on it?",
    answerOptions: [
      { answer: "2000 N", isCorrect: true },
      { answer: "1000 N", isCorrect: false },
      { answer: "500 N", isCorrect: false },
      { answer: "250 N", isCorrect: false },
    ],
  },
  {
    question:
      "A 500 kg elevator accelerates upward at 1 m/s². What is the tension in the elevator cable?",
    answerOptions: [
      { answer: "1500 N", isCorrect: true },
      { answer: "1000 N", isCorrect: false },
      { answer: "500 N", isCorrect: false },
      { answer: "250 N", isCorrect: false },
    ],
  },
  {
    question:
      "A 200 kg object is moving in a circular path with a radius of 8 m. If its angular velocity is decreasing at a rate of 2 rad/s², what is the magnitude of the net torque acting on the object?",
    answerOptions: [
      { answer: "3200 Nm", isCorrect: true },
      { answer: "1600 Nm", isCorrect: false },
      { answer: "800 Nm", isCorrect: false },
      { answer: "400 Nm", isCorrect: false },
    ],
  },
  {
    question:
      "A 1000 kg car travels down a hill with an incline of 15°. If the car's velocity is 30 m/s, what is the force of gravity acting on it?",
    answerOptions: [
      { answer: "3000 N", isCorrect: true },
      { answer: "1500 N", isCorrect: false },
      { answer: "750 N", isCorrect: false },
      { answer: "375 N", isCorrect: false },
    ],
  },
  {
    question:
      "A 50 kg person does 2000 J of work in 5 seconds. What is their average power output?",
    answerOptions: [
      { answer: "400 W", isCorrect: true },
      { answer: "200 W", isCorrect: false },
      { answer: "100 W", isCorrect: false },
      { answer: "50 W", isCorrect: false },
    ],
  },
  {
    question:
      "A 200 kg object is moving in a circular path with a radius of 10 m. If its angular velocity is increasing at a rate of 1 rad/s², what is the magnitude of the net torque acting on the object?",
    answerOptions: [
      { answer: "2000 Nm", isCorrect: true },
      { answer: "1000 Nm", isCorrect: false },
      { answer: "500 Nm", isCorrect: false },
      { answer: "250 Nm", isCorrect: false },
    ],
  },
  {
    question:
      "A 1500 kg car accelerates from 0 to 30 m/s in 12 seconds. What is the average power output of the car's engine?",
    answerOptions: [
      { answer: "11250 W", isCorrect: true },
      { answer: "5625 W", isCorrect: false },
      { answer: "2812.5 W", isCorrect: false },
      { answer: "1406.25 W", isCorrect: false },
    ],
  },
];

export default mechaniesquestions;
