const statequestions = [
    {
        question:" Vander's equation explain the behaviour of :",
        answerOptions: [
            {answer: 'ideal gas', isCorrect: false},
            {answer: 'real gas', isCorrect: true},
            {answer: 'non-ideal gas', isCorrect: false},
            {answer: 'non real gas', isCorrect: false},
        ],
    },  {
        question:"Viscosity of a liquid increase with increse of :",
        answerOptions: [
            {answer: 'atomic mass', isCorrect: true},
            {answer: 'atomic number', isCorrect: false},
            {answer: 'atomic unit', isCorrect: false},
            {answer: 'can not be said', isCorrect: false},
        ],
    },  {
        question:" The temperature at which the second virial coefficient of a gas is 0 is called :",
        answerOptions: [
            {answer: 'critical temparature', isCorrect: false},
            {answer: 'eutacit point', isCorrect: false},
            {answer: 'boiling point', isCorrect: false},
            {answer: 'Boyle temperature', isCorrect: true},
        ],
    }, {
        question:" Aveage velocity is equal to :",
        answerOptions: [
            {answer: '0.9213xRMS', isCorrect: true},
            {answer: '10.9213xRMS', isCorrect: false},
            {answer: '9213xRMS', isCorrect: false},
            {answer: 'Data insufficent', isCorrect: false},
        ],
    }, {
        question:"The rise of a liquid in capillary tube id due to :",
        answerOptions: [
            {answer: 'viscosity', isCorrect: false},
            {answer: 'osmosis', isCorrect: false},
            {answer: 'diffusion', isCorrect: false},
            {answer: 'surface tension', isCorrect: true},
        ],
    }, {
        question:"For an ideal gas, Cp - Cv equal to :",
        answerOptions: [
            {answer: '0', isCorrect: false},
            {answer: '1', isCorrect: false},
            {answer: 'R', isCorrect: true},
            {answer: 'can ne said', isCorrect: false},
        ],
    }, {
        question:"The viscosity of which liquid is heghest :",
        answerOptions: [
            {answer: 'water', isCorrect: false},
            {answer: 'glycol', isCorrect: true},
            {answer: 'acetone', isCorrect: false},
            {answer: 'ethanol', isCorrect: false},
        ],
    }, {
        question:"With increase in temperature, the fluidity of liquids :",
        answerOptions: [
            {answer: 'increase', isCorrect: true},
            {answer: 'decrease', isCorrect: false},
            {answer: 'remain constant', isCorrect: false},
            {answer: 'continious increase', isCorrect: false},
        ],
    }, {
        question:"Critical temperature mean above this temperature at any pressure, any gas can be :",
        answerOptions: [
            {answer: 'liquefied', isCorrect: false},
            {answer: 'not liquefied', isCorrect: true},
            {answer: 'both', isCorrect: false},
            {answer: 'only solified', isCorrect: false},
        ],
    }, {
        question:" The ratio of radius of cation and anion is called :",
        answerOptions: [
            {answer: 'atomic ratio', isCorrect: false},
            {answer: 'radius ratio', isCorrect: true},
            {answer: 'equal ratio', isCorrect: false},
            {answer: 'can be said', isCorrect: false},
        ],
    },{
        question:"In a unit cell fo NaCl lattice there are :",
        answerOptions: [
            {answer: '4 NaCl units', isCorrect: true},
            {answer: '3 NaCl units', isCorrect: false},
            {answer: '6 Na+ units', isCorrect: false},
            {answer: '6 Cl- units', isCorrect: false},
        ],
    },{
        question:"The gas which can be liquefied under high pressure and 40°C is :",
        answerOptions: [
            {answer: 'nitrogen', isCorrect: false},
            {answer: 'hydrogen', isCorrect: false},
            {answer: 'fluorine', isCorrect: false},
            {answer: 'ammonia', isCorrect: true},
        ],
    }, {
        question:"The expression for Bragg's equation is :",
        answerOptions: [
            {answer: '2 d sin θ = n λ', isCorrect: true},
            {answer: 'd sin θ = n λ', isCorrect: false},
            {answer: 'sin θ = n/2 λ', isCorrect: false},
            {answer: '2d sin θ = λ', isCorrect: false},
        ],
    }, {
        question:"Unit of surface tension is :",
        answerOptions: [
            {answer: 'dyne cm', isCorrect: false},
            {answer: 'dyne /cm', isCorrect: true},
            {answer: 'dyne /cm²', isCorrect: false},
            {answer: 'dyne /cm^-2', isCorrect: false},
        ],
    }, {
        question:"Value of Cp/Cv for inert gases is :",
        answerOptions: [
            {answer: '1.33', isCorrect: false},
            {answer: '1.66', isCorrect: true},
            {answer: '2.13', isCorrect: false},
            {answer: '1.99', isCorrect: false},
        ],
    }, {
        question:"Association of molecules in water is due to :",
        answerOptions: [
            {answer: 'covalent bonding', isCorrect: false},
            {answer: 'hydrogen bonding', isCorrect: true},
            {answer: 'ionic bonding', isCorrect: false},
            {answer: 'van der waals force', isCorrect: false},
        ],
    }, {
        question:"Which crystal has highest boiling point :",
        answerOptions: [
            {answer: 'ionic', isCorrect: true},
            {answer: 'metallic', isCorrect: false},
            {answer: 'crystall', isCorrect: false},
            {answer: 'molucular', isCorrect: false},
        ],
    },{
        question:" Heat capicity of a diatomic gas is greater than that of:",
        answerOptions: [
            {answer: 'diatomic gas', isCorrect: false},
            {answer: 'monoatomic gas', isCorrect: true},
            {answer: 'real gas', isCorrect: false},
            {answer: 'ideal gas', isCorrect: false},
        ],
    }, {
        question:"Crystals can be classified into :",
        answerOptions: [
            {answer: '7 systems', isCorrect: true},
            {answer: '9 systems', isCorrect: false},
            {answer: '10 systems', isCorrect: false},
            {answer: '12 systems', isCorrect: false},
        ],
    }, {
        question:"Which if the following is emulsifier :",
        answerOptions: [
            {answer: 'soaps', isCorrect: false},
            {answer: 'detergen', isCorrect: false},
            {answer: 'both', isCorrect: true},
            {answer: 'none', isCorrect: false},
        ],
    }, {
        question:"For a liquid, the number of drops formed per unit volume depends on :",
        answerOptions: [
            {answer: 'viscosity', isCorrect: false},
            {answer: 'surface tension', isCorrect: true},
            {answer: 'pressure', isCorrect: false},
            {answer: 'temperature', isCorrect: false},
        ],
    },{
        question:"Which of the following is a colligative property :",
        answerOptions: [
            {answer: 'molecular weight', isCorrect: false},
            {answer: 'osmotic pressure', isCorrect: true},
            {answer: 'parachor', isCorrect: false},
            {answer: 'surface tension', isCorrect: false},
        ],
    }, {
        question:"If in a crystal intercepts are 1, ∞ and ∞, the Millar indices are :",
        answerOptions: [
            {answer: '(100)', isCorrect: true},
            {answer: '(101)', isCorrect: false},
            {answer: '(110)', isCorrect: false},
            {answer: '(000)', isCorrect: false},
        ],
    },{
        question:"Tyndall effects is shown by :",
        answerOptions: [
            {answer: 'dilute solutions', isCorrect: false},
            {answer: 'collide solutions', isCorrect: true},
            {answer: 'suspension', isCorrect: false},
            {answer: 'true solution', isCorrect: false},
        ],
    },{
        question:"The temperature at which real gases behave like ideal gases is called :",
        answerOptions: [
            {answer: 'keliven temperature', isCorrect: false},
            {answer: 'critical temperature', isCorrect: false},
            {answer: 'boyle temperature', isCorrect: true},
            {answer: 'can not be said', isCorrect: false},
        ],
    },{
        question:"A real gas will approach the behaviour of ideal gas at :",
        answerOptions: [
            {answer: 'low temp and low pressure', isCorrect: false},
            {answer: 'low temp and high pressure', isCorrect: true},
            {answer: 'high temp and high pressure', isCorrect: false},
            {answer: 'high temp and low temp', isCorrect: false},
        ],
    },{
        question:"Which method is used to destroy a solitions :",
        answerOptions: [
            {answer: 'condensation', isCorrect: false},
            {answer: 'dialysis', isCorrect: false},
            {answer: 'diffusion', isCorrect: false},
            {answer: 'additive of electrolyte', isCorrect: true},
        ],
    }, {
        question:"The molecular viscosity of a liquid is given by :",
        answerOptions: [
            {answer: 'viscosity x mol. wt.', isCorrect: false},
            {answer: 'viscosity x molar vol.', isCorrect: false},
            {answer: 'viscosity x molar sur.', isCorrect: true},
            {answer: 'viscosity x mol. sur.', isCorrect: false},
        ],
    }, {
        question:" Degree of freedom in a water molecule is :",
        answerOptions: [
            {answer: '1', isCorrect: false},
            {answer: '2', isCorrect: false},
            {answer: '3', isCorrect: true},
            {answer: '4', isCorrect: false},
        ],
    }, {
        question:" Which crystal has the highest mwlting point :",
        answerOptions: [
            {answer: 'ionic ', isCorrect: false},
            {answer: 'metallic', isCorrect: true},
            {answer: 'covalent', isCorrect: false},
            {answer: 'molecular', isCorrect: false},
        ],
    }, {
        question:"Which of the following gases has maximum value of root mean square velocity :",
        answerOptions: [
            {answer: 'CH4', isCorrect: false},
            {answer: 'CO2', isCorrect: false},
            {answer: 'H2', isCorrect: true},
            {answer: 'CO', isCorrect: false},
        ],
    }, {
        question:"The minimum concentration of an electrolpte required to cause coagulation of a solution is called :",
        answerOptions: [
            {answer: 'flocculation', isCorrect: true},
            {answer: 'gold number', isCorrect: false},
            {answer: 'coagulation number', isCorrect: false},
            {answer: 'none', isCorrect: false},
        ],
    }, {
        question:"If a plane id parallel on an axis of crystal its miller index wil be :",
        answerOptions: [
            {answer: '0', isCorrect: true},
            {answer: '∞', isCorrect: false},
            {answer: '1', isCorrect: false},
            {answer: '2', isCorrect: false},
        ],
    }, {
        question:"Expansion of a degree of freedom inversion temperature creates : (Xnetous chellange)",
        answerOptions: [
            {answer: 'cooling', isCorrect: false},
            {answer: 'heating', isCorrect: true},
            {answer: 'boiling', isCorrect: false},
            {answer: 'can not be said', isCorrect: true},
        ],
    }, {
        question:"Surface tension decrease with increase of :",
        answerOptions: [
            {answer: 'pressure', isCorrect: false},
            {answer: 'temperature', isCorrect: true},
            {answer: 'heating', isCorrect: false},
            {answer: 'cooling', isCorrect: false},
        ],
    }, {
        question:"The geometrical form consisting only of a regular array of points in space is called :",
        answerOptions: [
            {answer: 'unit cell', isCorrect: false},
            {answer: 'crystal', isCorrect: false},
            {answer: 'lattice', isCorrect: true},
            {answer: 'amorphous', isCorrect: false},
        ],
    }, {
        question:"Which one of the following is positive solution :",
        answerOptions: [
            {answer: 'smoke', isCorrect: false},
            {answer: 'blood', isCorrect: false},
            {answer: 'clay soil', isCorrect: true},
            {answer: 'acid', isCorrect: false},
        ],
    },  {
        question:"Axis of symmetry in crystals is denoted by the formula :",
        answerOptions: [
            {answer: '360°', isCorrect: true},
            {answer: 'n x 360°', isCorrect: false},
            {answer: 'n x 180°', isCorrect: false},
            {answer: 'n/ 90°', isCorrect: false},
        ],
    },  {
        question:"The emulsifying agent in milk is :",
        answerOptions: [
            {answer: 'lactic acid', isCorrect: false},
            {answer: 'fat', isCorrect: false},
            {answer: 'lactose', isCorrect: false},
            {answer: 'casein', isCorrect: true},
        ],
    },  {
        question:"Coordination number for an atom in a primitive cubic cell is :",
        answerOptions: [
            {answer: '6', isCorrect: true},
            {answer: '8', isCorrect: false},
            {answer: '10', isCorrect: false},
            {answer: '12', isCorrect: false},
        ],
    }, 
]

export default statequestions;