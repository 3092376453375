const carboncompoundquestions = [
    {
        question:" Impure glycerine is purified by  :",
        answerOptions: [
            {answer: 'vacuum distillation', isCorrect: true},
            {answer: 'simple distillation', isCorrect: false},
            {answer: 'steam distillation ', isCorrect: false},
            {answer: 'none of the above', isCorrect: false},
        ],
    }, {
        question:"In Kjeldhal's method, nitrogen present is estimated as :",
        answerOptions: [
            {answer: 'NO', isCorrect: false},
            {answer: 'NH3', isCorrect: true},
            {answer: 'NO2', isCorrect: false},
            {answer: 'N2', isCorrect: false},
        ],
    },  {
        question:"Carbon and hydrogen are estimated by :",
        answerOptions: [
            {answer: ' Leibig method', isCorrect: true},
            {answer: ' Duma’s method ', isCorrect: false},
            {answer: ' Carius method', isCorrect: true},
            {answer: ' Kjeldahl’s method ', isCorrect: false},
        ],
    },  {
        question:"Which of the following has molecular weight of 92 :",
        answerOptions: [
            {answer: 'benzene', isCorrect: false},
            {answer: 'propene', isCorrect: false},
            {answer: 'toluene', isCorrect: true},
            {answer: 'methylene', isCorrect: false},
        ],
    },  {
        question:"The equivalent mass of an acid is equal to  :",
        answerOptions: [
            {answer: 'molecular mass x acidity', isCorrect: false},
            {answer: 'molecular mass x basicity ', isCorrect: false},
            {answer: 'molecular mass / acidity ', isCorrect: false},
            {answer: 'molecular mass / basicity ', isCorrect: true},
        ],
    },  {
        question:"In a hydrocarbon, mass ratio of hydrogen and carbon is 1 : 3, the empirical formula of hydrocarbon is   :",
        answerOptions: [
            {answer: 'CH2', isCorrect: false},
            {answer: 'CH4', isCorrect: true},
            {answer: 'CH3', isCorrect: false},
            {answer: 'Ch', isCorrect: false},
           
        ],
    }, {
        question:"The Beilstein test for organic compounds is used to detect :",
        answerOptions: [
            {answer: 'nitrogen', isCorrect: false},
            {answer: 'sulpher', isCorrect: false},
            {answer: 'carbon', isCorrect: false},
            {answer: 'halogen', isCorrect: true},
        ],
    },  {
        question:"26 Absolute alcohol is prepared by   :",
        answerOptions: [
            {answer: 'fractional distillation ', isCorrect: false},
            {answer: 'Kolbes method', isCorrect: false},
            {answer: 'vacuum distillation', isCorrect: false},
            {answer: 'azeotropic distillation', isCorrect: true},
        ],
    },  {
        question:"An alkane has a C/H ratio of 5.1428 by mass. Its molecular formula is :",
        answerOptions: [
            {answer: 'C5H12', isCorrect: false},
            {answer: 'C6H14', isCorrect: true},
            {answer: 'C8H18', isCorrect: false},
            {answer: 'C7H10', isCorrect: false},
        ],
    },  {
        question:" When petroleum is heated gradually, first batch of vapours evolved will be rich in  :",
        answerOptions: [
            {answer: 'kerosen', isCorrect: false},
            {answer: 'producer gas', isCorrect: true},
            {answer: 'diesal', isCorrect: false},
            {answer: 'nlubricating oil', isCorrect: false},
        ],
    },  {
        question:"A mixture of benzoic acid and naphthalene can be separated by crystallization from :",
        answerOptions: [
            {answer: 'coil water', isCorrect: false},
            {answer: 'ether', isCorrect: false},
            {answer: 'hot water', isCorrect: true},
            {answer: 'benzene', isCorrect: false},
        ],
    },  {
        question:"H2 + CuO -----> Cu + H2O, which catylist will be used  :",
        answerOptions: [
            {answer: 'O2', isCorrect: false},
            {answer: 'ether', isCorrect: false},
            {answer: 'heat', isCorrect: true},
            {answer: 'MnO4', isCorrect: false},
        ],
    },  {
        question:"Molecular weight of acid is define by :",
        answerOptions: [
            {answer: 'E/n', isCorrect: false},
            {answer: 'Exn', isCorrect: true},
            {answer: 'Exn²', isCorrect: false},
            {answer: 'E/n²', isCorrect: false},
        ],
    },  {
        question:"12/44 x Wt. of CO2/Wt of org. compound :",
        answerOptions: [
            {answer: 'C%', isCorrect: true},
            {answer: 'H%', isCorrect: false},
            {answer: 'N%', isCorrect: false},
            {answer: 'S%', isCorrect: false},
        ],
    },  {
        question:"The principle involved in paper chromatography is :",
        answerOptions: [
            {answer: 'adsorpation', isCorrect: false},
            {answer: 'partition', isCorrect: false},
            {answer: 'solubility', isCorrect: true},
            {answer: 'volatility', isCorrect: false},
        ],
    },   
]

export default carboncompoundquestions;