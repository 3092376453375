const alkalimetalquestions = [
    {
        question:"Sodium is heated with air or O2. The product is  :",
        answerOptions: [
            {answer: 'Na2O', isCorrect: false},
            {answer: 'Na2O2', isCorrect: true},
            {answer: 'Na2O', isCorrect: false},
            {answer: 'NaO2', isCorrect: false},
        ],
    }, {
        question:"The correct order of reducing power of alkali metals is :",
        answerOptions: [
            {answer: 'Li > Na > K > Cs', isCorrect: false},
            {answer: 'Cs > Li > Na > K', isCorrect: false},
            {answer: 'Li > Cs > K > Na', isCorrect: true},
            {answer: 'Na > K > Li > Cs', isCorrect: false},
        ],
    },  {
        question:"Thermal stability of MNO3 (M = alkali metals) :",
        answerOptions: [
            {answer: 'decreases down the group', isCorrect: false},
            {answer: 'inecreases down the group', isCorrect: true},
            {answer: 'does not follow regular order', isCorrect: false},
            {answer: 'remains almost similar', isCorrect: false},
        ],
    },  {
        question:"Which of the following is not found in solid state :",
        answerOptions: [
            {answer: 'NaHCO3', isCorrect: false},
            {answer: 'LiHCO3', isCorrect: true},
            {answer: 'NH4HCO3', isCorrect: false},
            {answer: 'KHCO3', isCorrect: false},
        ],
    },  {
        question:"Alkaline metals are Li, Na, K, Rb, Ss and  :",
        answerOptions: [
            {answer: 'O', isCorrect: false},
            {answer: 'Mg', isCorrect: false},
            {answer: 'Co', isCorrect: false},
            {answer: 'Fr', isCorrect: true},
        ],
    },  {
        question:"The formula of calcium cyanamide is  :",
        answerOptions: [
            {answer: 'Ca(N3)2', isCorrect: false},
            {answer: 'CaCN2', isCorrect: true},
            {answer: 'CaC2N', isCorrect: false},
            {answer: 'CaCHNH2', isCorrect: false},
           
        ],
    }, {
        question:"Atomic number of lithum is   :",
        answerOptions: [
            {answer: '3', isCorrect: true},
            {answer: '2', isCorrect: false},
            {answer: '4', isCorrect: false},
            {answer: '6', isCorrect: false},
        ],
    },  {
        question:" The metal ion essential for the metabolism of glucose inside the cell is :",
        answerOptions: [
            {answer: 'Na+', isCorrect: false},
            {answer: 'K+', isCorrect: true},
            {answer: 'Mg+', isCorrect: false},
            {answer: 'Cu+', isCorrect: false},
        ],
    },  {
        question:"Which of the following has highest melting point in alkali group :",
        answerOptions: [
            {answer: 'Li', isCorrect: true},
            {answer: 'Mg', isCorrect: false},
            {answer: 'K', isCorrect: false},
            {answer: 'Rb', isCorrect: false},
        ],
    },  {
        question:"Brine (saturated aquous solution of NaCl) on electrolysis will not give :",
        answerOptions: [
            {answer: 'O2', isCorrect: true},
            {answer: 'NaOH', isCorrect: false},
            {answer: 'Cl2', isCorrect: false},
            {answer: 'H2', isCorrect: false},
        ],
    },  {
        question:"The compound insoluble in acetic acid is :",
        answerOptions: [
            {answer: 'CaO', isCorrect: false},
            {answer: 'CaCO3', isCorrect: false},
            {answer: 'CaC2O4', isCorrect: true},
            {answer: 'Ca(OH)2', isCorrect: false},
        ],
    },  {
        question:"The set which represents correct order of first ionization energy is :",
        answerOptions: [
            {answer: 'B > C > N', isCorrect: false},
            {answer: 'K > Na > Li', isCorrect: false},
            {answer: 'Be > Mg > Ca', isCorrect: true},
            {answer: 'Ge > Si > C', isCorrect: false},
        ],
    },  {
        question:"Which of the following has lowest boiling point in alkali group  :",
        answerOptions: [
            {answer: 'Li', isCorrect: false},
            {answer: 'Na', isCorrect: true},
            {answer: 'K', isCorrect: false},
            {answer: 'Cs', isCorrect: false},
        ],
    },  {
        question:"In aminal cells, the concentration of which of the following metal ion is highest  :",
        answerOptions: [
            {answer: 'K+', isCorrect: true},
            {answer: 'Na+', isCorrect: false},
            {answer: 'Cs²+', isCorrect: false},
            {answer: 'Cu+', isCorrect: false},
        ],
    },  {
        question:"The paramagnetic species is :",
        answerOptions: [
            {answer: 'SiO2', isCorrect: false},
            {answer: 'TiO2', isCorrect: false},
            {answer: 'RbO2', isCorrect: true},
            {answer: 'RbCl', isCorrect: false},
        ],
    },  {
        question:" Which of the sulphates is used in the diagnostic investigation of stomach ulcers  :",
        answerOptions: [
            {answer: 'SeSO4', isCorrect: false},
            {answer: 'CaSO4', isCorrect: false},
            {answer: 'BeSO4', isCorrect: false},
            {answer: 'BaSO4', isCorrect: true},
        ],
    },  {
        question:"Which of the following has highest effective nuclear charge in alkali group :",
        answerOptions: [
            {answer: 'Li', isCorrect: true},
            {answer: 'Na', isCorrect: false},
            {answer: 'Rb', isCorrect: false},
            {answer: 'Cs', isCorrect: false},
        ],
    },  {
        question:"The formula of calcium cyanamide is :",
        answerOptions: [
            {answer: 'Ca(N3)2', isCorrect: false},
            {answer: 'CaCN2', isCorrect: true},
            {answer: 'CaC2N', isCorrect: false},
            {answer: 'CaChNH2', isCorrect: false},
        ],
    },  {
        question:" All alkaline earth metals are :",
        answerOptions: [
            {answer: 'liquid', isCorrect: false},
            {answer: 'gases', isCorrect: false},
            {answer: 'solid and gases', isCorrect: false},
            {answer: 'solid', isCorrect: true},
        ],
    },  {
        question:" Ammonia is dried over :",
        answerOptions: [
            {answer: 'Slacked lime', isCorrect: false},
            {answer: 'Quick lime', isCorrect: true},
            {answer: 'CaCl2', isCorrect: false},
            {answer: 'all of these', isCorrect: false},
        ],
    }, {
        question:"Which of the following is not found in solid state :",
        answerOptions: [
            {answer: 'NaHCO3', isCorrect: false},
            {answer: 'LIHCO3', isCorrect: true},
            {answer: 'NH4HCO3', isCorrect: false},
            {answer: 'KHCO3', isCorrect: false},
        ],
    },{
        question:" The metal that does not produce H2 with cold water is :",
        answerOptions: [
            {answer: 'Ba', isCorrect: false},
            {answer: 'Mg', isCorrect: false},
            {answer: 'Ca', isCorrect: false},
            {answer: 'Sr', isCorrect: true},
        ],
    },{
        question:"The metal which is used in photocells is :",
        answerOptions: [
            {answer: 'Ba', isCorrect: false},
            {answer: 'Cs', isCorrect: true},
            {answer: 'Li', isCorrect: false},
            {answer: 'Ti', isCorrect: false},
        ],
    },{
        question:"The metal ion essential for the metabolism of glucose inside the cell is :",
        answerOptions: [
            {answer: 'Na+', isCorrect: false},
            {answer: 'K+', isCorrect: true},
            {answer: 'Mg+', isCorrect: false},
            {answer: 'Cu+', isCorrect: false},
        ],
    },{
        question:"In body fluids, the concentration of which of the following is larges :",
        answerOptions: [
            {answer: 'K+', isCorrect: false},
            {answer: 'Cs+', isCorrect: false},
            {answer: 'Rb+', isCorrect: false},
            {answer: 'Na+', isCorrect: true},
        ],
    },{
        question:" Heat of formation is due to :",
        answerOptions: [
            {answer: 'alkalis', isCorrect: false},
            {answer: 'nitrate', isCorrect: false},
            {answer: 'alkali earth metals', isCorrect: false},
            {answer: 'halides', isCorrect: true},
        ],
    },{
        question:"Sodium is heated with air or O2. The product is :",
        answerOptions: [
            {answer: 'Na2O', isCorrect: false},
            {answer: 'BaO2', isCorrect: true},
            {answer: 'KO2', isCorrect: false},
            {answer: 'PbO2', isCorrect: false},
        ],
    },{
        question:"The metal(s) which fail(s) flame test is (are) :",
        answerOptions: [
            {answer: 'Cu', isCorrect: false},
            {answer: 'Sr', isCorrect: true},
            {answer: 'Mg', isCorrect: false},
            {answer: 'K', isCorrect: false},
        ],
    },{
        question:"Which of the following is not an ore of magnesium :",
        answerOptions: [
            {answer: 'magnesite', isCorrect: false},
            {answer: 'dolomite', isCorrect: false},
            {answer: 'magnetite', isCorrect: true},
            {answer: 'carnalite', isCorrect: false},
        ],
    },{
        question:"The pair of metals which possess chemical similarity is :",
        answerOptions: [
            {answer: 'Li, Ca', isCorrect: false},
            {answer: 'Li, Mg', isCorrect: true},
            {answer: 'Li, Al', isCorrect: false},
            {answer: 'Li, Ti', isCorrect: false},
        ],
    }, 
]

export default alkalimetalquestions;