const modernphyquestions = [
  {
    question:
      " What is energy (in eV) of photon of wavelength 12400 Angstrom :",
    answerOptions: [
      { answer: "-1 eV", isCorrect: false },
      { answer: "1 eV", isCorrect: true },
      { answer: "10 eV", isCorrect: false },
      { answer: "12400 eV", isCorrect: false },
    ],
  },
  {
    question:
      "The minimum orbital angular momentum of electon in a hydrogen atom is :",
    answerOptions: [
      { answer: "h", isCorrect: false },
      { answer: "h/2", isCorrect: false },
      { answer: "h/2 π", isCorrect: true },
      { answer: "h/λ", isCorrect: false },
    ],
  },
  {
    question:
      "The force acting on a electron in a hydrogen atom depends on principal quantum number as :",
    answerOptions: [
      { answer: "F is propotional to n²", isCorrect: false },
      { answer: "F is inverse to  1/n²", isCorrect: false },
      { answer: "F is propotional to n^4", isCorrect: false },
      { answer: "F is inverse to 1/n^4", isCorrect: true },
    ],
  },
  {
    question:
      "The work function of metel is W and λ is the wavelength of the incident radiation. There is no emission of photoelectrons when :",
    answerOptions: [
      { answer: "λ > hc/W", isCorrect: true },
      { answer: "λ < hc/W", isCorrect: false },
      { answer: "λ = hc/W", isCorrect: false },
      { answer: "λ > h/W", isCorrect: false },
    ],
  },
  {
    question:
      "The angular momentum of an electron in a hydrogen atom is propotional to :",
    answerOptions: [
      { answer: "1/√(r)", isCorrect: false },
      { answer: "1/r", isCorrect: false },
      { answer: "√(r)", isCorrect: true },
      { answer: "r²", isCorrect: false },
    ],
  },
  {
    question:
      "If stopping potentials corresponding wavelengths 4000 Angstrom and 5000 Angestrom are 1.3 V and 0.9 V, respectively, then the work function of the metel is :",
    answerOptions: [
      { answer: "0.3 eV", isCorrect: false },
      { answer: "1.3 eV", isCorrect: false },
      { answer: "2.3 eV", isCorrect: true },
      { answer: "5 eV", isCorrect: false },
    ],
  },
  {
    question: "photon density in the light beam can be given as  :",
    answerOptions: [
      { answer: "photons m^-2", isCorrect: true },
      { answer: "photons m²", isCorrect: false },
      { answer: "photons", isCorrect: false },
      { answer: "photons / m²", isCorrect: false },
    ],
  },
  {
    question:
      " Magnatic moments due to the motions of electron in nth energy of hygrogen atom is propotional to :",
    answerOptions: [
      { answer: "n", isCorrect: true },
      { answer: "n^0", isCorrect: false },
      { answer: "n^5", isCorrect: false },
      { answer: "n^3", isCorrect: false },
    ],
  },
  {
    question:
      "Two electrons are revolving around a nucleus at distances r and 4r. The ratio of their periods is  :",
    answerOptions: [
      { answer: "1:4", isCorrect: false },
      { answer: "4:1", isCorrect: false },
      { answer: "8:1", isCorrect: false },
      { answer: "1:8", isCorrect: true },
    ],
  },
  {
    question:
      "In fission, the percentage of mass converted into energy is about :",
    answerOptions: [
      { answer: "10%", isCorrect: false },
      { answer: "1%", isCorrect: false },
      { answer: "0.1%", isCorrect: true },
      { answer: "0.01%", isCorrect: false },
    ],
  },
  {
    question: "What is energy of photon possessing wavelength 0.4 Armstrom :",
    answerOptions: [
      { answer: "0.51 eV", isCorrect: true },
      { answer: "1.51 eV", isCorrect: false },
      { answer: "10.51 eV", isCorrect: false },
      { answer: "100.51 eV", isCorrect: false },
    ],
  },
  {
    question: "When an atom undergoes beta decay, :",
    answerOptions: [
      { answer: "a neutron changes into a proton", isCorrect: false },
      { answer: "a proton changes into a neutron", isCorrect: true },
      { answer: "a neutron changes into a antiproton", isCorrect: false },
      { answer: "a proton changes into a antineutron", isCorrect: false },
    ],
  },
  {
    question:
      "A particles of mass M at rest decays into two masses m1 and m2 with non-zero velocitys. The ratio λ1/λ2 of de Broglie wavelength of the particles is :",
    answerOptions: [
      { answer: "m2/m1", isCorrect: false },
      { answer: "m1/m2", isCorrect: false },
      { answer: "√(m1)/√(m2)", isCorrect: false },
      { answer: "1:1", isCorrect: true },
    ],
  },
  {
    question:
      "The half-life of a radioactive decay is x times its mean life. The value of x is :",
    answerOptions: [
      { answer: "0.3010", isCorrect: false },
      { answer: "0.6930", isCorrect: true },
      { answer: "0.6020", isCorrect: false },
      { answer: "1/0.6930", isCorrect: false },
    ],
  },
  {
    question:
      "The ratio of minimum to maximum wavelength in Balmer series is :",
    answerOptions: [
      { answer: "5:9", isCorrect: true },
      { answer: "5:36", isCorrect: false },
      { answer: "1:4", isCorrect: false },
      { answer: "3:4", isCorrect: false },
    ],
  },
  {
    question:
      "The maximum velocity of electrons emitted from a metel surface is v. What would be the maximum velocity if the frequency of incident light is increased by a factor of 4 :",
    answerOptions: [
      { answer: "2v", isCorrect: false },
      { answer: ">2v", isCorrect: true },
      { answer: "<2v", isCorrect: false },
      { answer: "between 2v and 4v", isCorrect: false },
    ],
  },
  {
    question:
      "A perfectly inelastic collision between held neutron and H atom, which one of the correct :",
    answerOptions: [
      { answer: "1/2 E", isCorrect: true },
      { answer: "E", isCorrect: false },
      { answer: "2 E", isCorrect: false },
      { answer: "Total energy lost", isCorrect: false },
    ],
  },
  {
    question:
      "If the radius of an orbital is r and velocity of an electron in is v, then the frequency of electron in the orbit will be :",
    answerOptions: [
      { answer: "2 πrv", isCorrect: false },
      { answer: "2 π/rv", isCorrect: false },
      { answer: "rv/2π", isCorrect: false },
      { answer: "v/2π", isCorrect: true },
    ],
  },
  {
    question:
      "A particle of mass m is projected from ground with velocity making angle x with vertical. The de Broglie wavelength of the particle at the highest point is :",
    answerOptions: [
      { answer: "∞", isCorrect: false },
      { answer: "h/mu sin x", isCorrect: true },
      { answer: "h/mu cos x", isCorrect: false },
      { answer: "h/mu", isCorrect: false },
    ],
  },
  {
    question:
      "As the quantum number increases, the difference of energy between conservative level will be :",
    answerOptions: [
      { answer: "decrease", isCorrect: true },
      { answer: "increase", isCorrect: false },
      { answer: "decrease then increase", isCorrect: false },
      { answer: "remains same", isCorrect: false },
    ],
  },
  {
    question:
      "The resolving power of an electron microscope operated at 16 kV is R. The rosolving power of the electron microscope when operated at 4 kV is :",
    answerOptions: [
      { answer: "R/4", isCorrect: false },
      { answer: "R/2", isCorrect: true },
      { answer: "4R", isCorrect: false },
      { answer: "2R", isCorrect: false },
    ],
  },
  {
    question:
      "The speed of electrons in the second orbital of Be^3+ ion will be :",
    answerOptions: [
      { answer: "c/137", isCorrect: false },
      { answer: "2c/137", isCorrect: false },
      { answer: "3c/137", isCorrect: false },
      { answer: "4c/137", isCorrect: true },
    ],
  },
  {
    question: "In nuclear binding energy reaction :",
    answerOptions: [
      { answer: "E released", isCorrect: true },
      { answer: "E observed", isCorrect: false },
      { answer: "E lost", isCorrect: false },
      { answer: "E conserved", isCorrect: false },
    ],
  },
  {
    question:
      "N1 atoms of a radioactive element emit N2 beta particles per second. They decay constant of the element is (in/s) :",
    answerOptions: [
      { answer: "N1/N2", isCorrect: false },
      { answer: "N2/N1", isCorrect: true },
      { answer: "N1 ln(2)", isCorrect: false },
      { answer: "N2 ln(2)", isCorrect: false },
    ],
  },
  {
    question:
      "The potential energy of an electron in the fifth orbit of hydrogen atom is :",
    answerOptions: [
      { answer: "0.54 eV", isCorrect: false },
      { answer: "-0.54 eV", isCorrect: false },
      { answer: "1.08 eV", isCorrect: false },
      { answer: "-1.08 eV", isCorrect: true },
    ],
  },
  {
    question: "For uranium nuclenus, how does its mass vary with volume :",
    answerOptions: [
      { answer: "m is propotional to V", isCorrect: true },
      { answer: "m is inverse to V", isCorrect: false },
      { answer: "m is propotional to √(V)", isCorrect: false },
      { answer: "m is propotional to V²", isCorrect: false },
    ],
  },
  {
    question:
      "The fraction of a radioactive material which remains active after time t is 9/16. The fraction which remains active after time t/2 will be :",
    answerOptions: [
      { answer: "4/5", isCorrect: false },
      { answer: "7/9", isCorrect: false },
      { answer: "3/5", isCorrect: false },
      { answer: "3/4", isCorrect: true },
    ],
  },
  {
    question:
      "The energy change is greatest for a hydrogen atom when its state changes from :",
    answerOptions: [
      { answer: "n = 2 to n= 1", isCorrect: true },
      { answer: "n = 3 to n= 2", isCorrect: false },
      { answer: "n = 4 to n= 3", isCorrect: false },
      { answer: "n = 5 to n= 4", isCorrect: false },
    ],
  },
  {
    question:
      " The mean life time of a radionuclide, if its activity decreases by 4% for every 1h, would be (product is non-radioactive, i.e, stable) :",
    answerOptions: [
      { answer: "25 h", isCorrect: true },
      { answer: "1.042 h", isCorrect: false },
      { answer: "2 h", isCorrect: false },
      { answer: "30 h", isCorrect: false },
    ],
  },
  {
    question:
      "if R is Rydberg constant for hydrogen, then the wave number of the first line in the Lyman series is :",
    answerOptions: [
      { answer: "R/2", isCorrect: false },
      { answer: "2R", isCorrect: false },
      { answer: "3R/4", isCorrect: true },
      { answer: "R/4", isCorrect: false },
    ],
  },
  {
    question: "What is the principle behind the photoelectric effect?",
    answerOptions: [
      {
        answer: "Electrons are emitted when light is absorbed.",
        isCorrect: true,
      },
      { answer: "Light is absorbed as heat.", isCorrect: false },
      {
        answer: "Electrons are emitted when light is scattered.",
        isCorrect: false,
      },
      { answer: "Light causes the emission of protons.", isCorrect: false },
    ],
  },
  {
    question:
      "What is the energy of a photon with a frequency of 5 × 10¹⁴ Hz? (Planck's constant h = 6.626 × 10⁻³⁴ Js)",
    answerOptions: [
      { answer: "3.313 × 10⁻¹⁹ J", isCorrect: true },
      { answer: "2.665 × 10⁻¹⁹ J", isCorrect: false },
      { answer: "3.313 × 10⁻¹⁸ J", isCorrect: false },
      { answer: "2.665 × 10⁻¹⁸ J", isCorrect: false },
    ],
  },
  {
    question:
      "Which equation represents the De Broglie wavelength of a particle?",
    answerOptions: [
      { answer: "λ = h/mv", isCorrect: true },
      { answer: "λ = mv/h", isCorrect: false },
      { answer: "λ = e/h", isCorrect: false },
      { answer: "λ = h/e", isCorrect: false },
    ],
  },
  {
    question: "What does Heisenberg's uncertainty principle state?",
    answerOptions: [
      {
        answer:
          "The more precisely the position of a particle is known, the less precisely its momentum is known.",
        isCorrect: true,
      },
      {
        answer:
          "The more precisely the velocity of a particle is known, the more precisely its energy is known.",
        isCorrect: false,
      },
      {
        answer:
          "The more precisely the charge of a particle is known, the more precisely its energy is known.",
        isCorrect: false,
      },
      {
        answer:
          "The more precisely the energy of a particle is known, the more precisely its mass is known.",
        isCorrect: false,
      },
    ],
  },
  {
    question:
      "In quantum mechanics, what does the wavefunction of a particle describe?",
    answerOptions: [
      {
        answer: "The probability of finding the particle at a given point.",
        isCorrect: true,
      },
      { answer: "The speed of the particle.", isCorrect: false },
      { answer: "The charge of the particle.", isCorrect: false },
      { answer: "The momentum of the particle.", isCorrect: false },
    ],
  },
  {
    question:
      "What is the rest mass energy of an electron? (Mass of an electron = 9.11 × 10⁻³¹ kg, c = 3 × 10⁸ m/s)",
    answerOptions: [
      { answer: "8.2 × 10⁻¹⁴ J", isCorrect: true },
      { answer: "7.6 × 10⁻¹⁴ J", isCorrect: false },
      { answer: "9.1 × 10⁻¹⁴ J", isCorrect: false },
      { answer: "6.5 × 10⁻¹⁴ J", isCorrect: false },
    ],
  },
  {
    question:
      "What is the energy of an electron in the first excited state of a hydrogen atom? (Ground state energy = -13.6 eV)",
    answerOptions: [
      { answer: "-3.4 eV", isCorrect: true },
      { answer: "-6.8 eV", isCorrect: false },
      { answer: "-1.7 eV", isCorrect: false },
      { answer: "-13.6 eV", isCorrect: false },
    ],
  },
  {
    question: "What is the main postulate of Einstein's theory of relativity?",
    answerOptions: [
      {
        answer: "The speed of light is constant for all observers.",
        isCorrect: true,
      },
      { answer: "Energy and mass are equivalent.", isCorrect: false },
      { answer: "Time is absolute.", isCorrect: false },
      {
        answer: "Space and time are independent of each other.",
        isCorrect: false,
      },
    ],
  },
  {
    question: "What is the formula for the energy-mass equivalence?",
    answerOptions: [
      { answer: "E = mc²", isCorrect: true },
      { answer: "E = mv²", isCorrect: false },
      { answer: "E = mc", isCorrect: false },
      { answer: "E = mv", isCorrect: false },
    ],
  },
  {
    question: "Which particle is its own antiparticle?",
    answerOptions: [
      { answer: "Photon", isCorrect: true },
      { answer: "Electron", isCorrect: false },
      { answer: "Neutron", isCorrect: false },
      { answer: "Proton", isCorrect: false },
    ],
  },
  {
    question: "What is the spin quantum number for an electron?",
    answerOptions: [
      { answer: "±½", isCorrect: true },
      { answer: "±1", isCorrect: false },
      { answer: "±2", isCorrect: false },
      { answer: "0", isCorrect: false },
    ],
  },
  {
    question: "Which experiment demonstrated the wave nature of electrons?",
    answerOptions: [
      { answer: "Double-slit experiment", isCorrect: true },
      { answer: "Photoelectric effect", isCorrect: false },
      { answer: "Rutherford gold foil experiment", isCorrect: false },
      { answer: "Millikan oil drop experiment", isCorrect: false },
    ],
  },
  {
    question: "What is the charge of a positron?",
    answerOptions: [
      { answer: "+1e", isCorrect: true },
      { answer: "-1e", isCorrect: false },
      { answer: "0", isCorrect: false },
      { answer: "+2e", isCorrect: false },
    ],
  },
  {
    question:
      "What is the approximate age of the universe according to modern cosmology?",
    answerOptions: [
      { answer: "13.8 billion years", isCorrect: true },
      { answer: "10.5 billion years", isCorrect: false },
      { answer: "4.6 billion years", isCorrect: false },
      { answer: "15.3 billion years", isCorrect: false },
    ],
  },
  {
    question: "What is the Planck length?",
    answerOptions: [
      { answer: "1.6 × 10⁻³⁵ meters", isCorrect: true },
      { answer: "3.2 × 10⁻³⁵ meters", isCorrect: false },
      { answer: "1.6 × 10⁻³² meters", isCorrect: false },
      { answer: "3.2 × 10⁻³² meters", isCorrect: false },
    ],
  },
  {
    question: "What is the primary component of dark matter?",
    answerOptions: [
      {
        answer: "WIMPs (Weakly Interacting Massive Particles)",
        isCorrect: true,
      },
      { answer: "Photons", isCorrect: false },
      { answer: "Neutrinos", isCorrect: false },
      { answer: "Baryons", isCorrect: false },
    ],
  },
  {
    question: "What is Hawking radiation?",
    answerOptions: [
      {
        answer: "Radiation emitted by black holes due to quantum effects",
        isCorrect: true,
      },
      {
        answer: "Radiation emitted by stars during a supernova",
        isCorrect: false,
      },
      { answer: "Radiation emitted by atoms during decay", isCorrect: false },
      {
        answer: "Radiation emitted by the sun due to nuclear fusion",
        isCorrect: false,
      },
    ],
  },
  {
    question:
      "What is the ground state energy of a particle in an infinite potential well?",
    answerOptions: [
      { answer: "E = (h²/8mL²)", isCorrect: true },
      { answer: "E = (h²/4mL²)", isCorrect: false },
      { answer: "E = (h/8mL²)", isCorrect: false },
      { answer: "E = (h²/2mL²)", isCorrect: false },
    ],
  },
  {
    question:
      "What is the significance of Schrödinger's cat thought experiment?",
    answerOptions: [
      {
        answer: "It illustrates the concept of quantum superposition.",
        isCorrect: true,
      },
      {
        answer: "It demonstrates the uncertainty principle.",
        isCorrect: false,
      },
      {
        answer: "It proves the existence of parallel universes.",
        isCorrect: false,
      },
      {
        answer: "It shows that quantum mechanics is deterministic.",
        isCorrect: false,
      },
    ],
  },
  {
    question:
      "What is the rest energy of a proton? (Mass of a proton = 1.67 × 10⁻²⁷ kg, c = 3 × 10⁸ m/s)",
    answerOptions: [
      { answer: "1.5 × 10⁻¹⁰ J", isCorrect: true },
      { answer: "1.2 × 10⁻¹⁰ J", isCorrect: false },
      { answer: "1.8 × 10⁻¹⁰ J", isCorrect: false },
      { answer: "2.0 × 10⁻¹⁰ J", isCorrect: false },
    ],
  },
  {
    question:
      "What is the phenomenon where certain materials exhibit zero electrical resistance?",
    answerOptions: [
      { answer: "Superconductivity", isCorrect: true },
      { answer: "Ferromagnetism", isCorrect: false },
      { answer: "Electromagnetism", isCorrect: false },
      { answer: "Paramagnetism", isCorrect: false },
    ],
  },
  {
    question:
      "What is the temperature at which a substance becomes a superconductor called?",
    answerOptions: [
      { answer: "Critical temperature", isCorrect: true },
      { answer: "Transition temperature", isCorrect: false },
      { answer: "Curie temperature", isCorrect: false },
      { answer: "Melting point", isCorrect: false },
    ],
  },
  {
    question: "What is the principle of complementarity in quantum mechanics?",
    answerOptions: [
      {
        answer:
          "It states that objects have both particle and wave properties.",
        isCorrect: true,
      },
      {
        answer: "It states that energy and mass are equivalent.",
        isCorrect: false,
      },
      {
        answer:
          "It states that the position and momentum of a particle are complementary.",
        isCorrect: false,
      },
      { answer: "It states that particles have spin.", isCorrect: false },
    ],
  },
  {
    question:
      "What is the name of the phenomenon where light bends around an obstacle?",
    answerOptions: [
      { answer: "Diffraction", isCorrect: true },
      { answer: "Refraction", isCorrect: false },
      { answer: "Reflection", isCorrect: false },
      { answer: "Dispersion", isCorrect: false },
    ],
  },
  {
    question: "What is the effect of gravitational time dilation?",
    answerOptions: [
      {
        answer: "Time runs slower in stronger gravitational fields.",
        isCorrect: true,
      },
      {
        answer: "Time runs faster in stronger gravitational fields.",
        isCorrect: false,
      },
      { answer: "Time is unaffected by gravity.", isCorrect: false },
      {
        answer: "Time runs slower in weaker gravitational fields.",
        isCorrect: false,
      },
    ],
  },
  {
    question:
      "Which element was first discovered using the principles of quantum mechanics?",
    answerOptions: [
      { answer: "Helium", isCorrect: true },
      { answer: "Hydrogen", isCorrect: false },
      { answer: "Lithium", isCorrect: false },
      { answer: "Oxygen", isCorrect: false },
    ],
  },
  {
    question:
      "What is the name of the theoretical particles that are thought to mediate the force of gravity?",
    answerOptions: [
      { answer: "Gravitons", isCorrect: true },
      { answer: "Photons", isCorrect: false },
      { answer: "Gluons", isCorrect: false },
      { answer: "Neutrinos", isCorrect: false },
    ],
  },
  {
    question: "What is the significance of the Higgs boson in modern physics?",
    answerOptions: [
      {
        answer: "It explains the origin of mass in elementary particles.",
        isCorrect: true,
      },
      {
        answer: "It is responsible for electromagnetic force.",
        isCorrect: false,
      },
      { answer: "It explains the weak nuclear force.", isCorrect: false },
      {
        answer: "It is responsible for the strong nuclear force.",
        isCorrect: false,
      },
    ],
  },
  {
    question: "What is the primary difference between a fermion and a boson?",
    answerOptions: [
      {
        answer:
          "Fermions obey the Pauli exclusion principle, while bosons do not.",
        isCorrect: true,
      },
      { answer: "Bosons have mass, while fermions do not.", isCorrect: false },
      {
        answer: "Fermions have charge, while bosons do not.",
        isCorrect: false,
      },
      {
        answer: "Bosons have spin 1/2, while fermions have spin 1.",
        isCorrect: false,
      },
    ],
  },
  {
    question: "What is the main observation in the Compton effect?",
    answerOptions: [
      {
        answer: "X-rays scatter off electrons, changing wavelength.",
        isCorrect: true,
      },
      { answer: "Light diffracts around edges.", isCorrect: false },
      {
        answer: "Electrons emit radiation when accelerated.",
        isCorrect: false,
      },
      { answer: "Protons absorb and re-emit light.", isCorrect: false },
    ],
  },
  {
    question:
      "A photon with a wavelength of 400 nm is incident on a metal surface. What is the energy of the photon? (Planck's constant h = 6.63 × 10⁻³⁴ Js, speed of light c = 3 × 10⁸ m/s)",
    answerOptions: [
      { answer: "4.97 × 10⁻¹⁹ J", isCorrect: true },
      { answer: "3.10 × 10⁻¹⁹ J", isCorrect: false },
      { answer: "5.12 × 10⁻¹⁹ J", isCorrect: false },
      { answer: "6.21 × 10⁻¹⁹ J", isCorrect: false },
    ],
  },
  {
    question:
      "In a photoelectric experiment, light of frequency 6 × 10¹⁴ Hz is incident on a metal surface. If the work function of the metal is 2 eV, what is the maximum kinetic energy of the emitted electrons? (Planck's constant h = 6.63 × 10⁻³⁴ Js, 1 eV = 1.6 × 10⁻¹⁹ J)",
    answerOptions: [
      { answer: "0.85 eV", isCorrect: true },
      { answer: "1.2 eV", isCorrect: false },
      { answer: "1.6 eV", isCorrect: false },
      { answer: "2.4 eV", isCorrect: false },
    ],
  },
  {
    question:
      "A radioactive substance has a half-life of 5 years. If you start with 100 g of the substance, how much will remain after 15 years?",
    answerOptions: [
      { answer: "12.5 g", isCorrect: true },
      { answer: "25 g", isCorrect: false },
      { answer: "50 g", isCorrect: false },
      { answer: "6.25 g", isCorrect: false },
    ],
  },
  {
    question:
      "A hydrogen atom makes a transition from the n=3 level to the n=2 level. What is the wavelength of the emitted photon? (Rydberg constant R = 1.097 × 10⁷ m⁻¹)",
    answerOptions: [
      { answer: "656 nm", isCorrect: true },
      { answer: "486 nm", isCorrect: false },
      { answer: "121 nm", isCorrect: false },
      { answer: "434 nm", isCorrect: false },
    ],
  },
  {
    question:
      "In an electron microscope, electrons are accelerated through a potential difference of 50 kV. What is the de Broglie wavelength of the electrons? (Planck's constant h = 6.63 × 10⁻³⁴ Js, mass of electron m = 9.11 × 10⁻³¹ kg)",
    answerOptions: [
      { answer: "5.5 × 10⁻¹² m", isCorrect: true },
      { answer: "2.4 × 10⁻¹² m", isCorrect: false },
      { answer: "1.7 × 10⁻¹² m", isCorrect: false },
      { answer: "3.3 × 10⁻¹² m", isCorrect: false },
    ],
  },
  {
    question:
      "A positron and an electron annihilate each other. What is the total energy released in this process? (Rest mass of electron/positron = 9.11 × 10⁻³¹ kg, c = 3 × 10⁸ m/s)",
    answerOptions: [
      { answer: "1.64 × 10⁻¹³ J", isCorrect: true },
      { answer: "2.5 × 10⁻¹³ J", isCorrect: false },
      { answer: "1.2 × 10⁻¹³ J", isCorrect: false },
      { answer: "3.6 × 10⁻¹³ J", isCorrect: false },
    ],
  },
  {
    question:
      "A 2.5 MeV alpha particle is moving in a magnetic field of 0.2 T. What is the radius of the path of the alpha particle? (Charge of alpha particle = 3.2 × 10⁻¹⁹ C, mass = 6.64 × 10⁻²⁷ kg)",
    answerOptions: [
      { answer: "0.035 m", isCorrect: true },
      { answer: "0.025 m", isCorrect: false },
      { answer: "0.045 m", isCorrect: false },
      { answer: "0.015 m", isCorrect: false },
    ],
  },
  {
    question:
      "A sample contains a radioactive isotope with a decay constant of 0.693 yr⁻¹. What is the half-life of the isotope?",
    answerOptions: [
      { answer: "1 year", isCorrect: true },
      { answer: "0.5 years", isCorrect: false },
      { answer: "2 years", isCorrect: false },
      { answer: "4 years", isCorrect: false },
    ],
  },
  {
    question:
      "A particle is moving at 0.8c relative to an observer. What is the relativistic mass of the particle if its rest mass is 1 kg? (c = speed of light)",
    answerOptions: [
      { answer: "1.67 kg", isCorrect: true },
      { answer: "1.25 kg", isCorrect: false },
      { answer: "1.75 kg", isCorrect: false },
      { answer: "2.0 kg", isCorrect: false },
    ],
  },
  {
    question:
      "The binding energy per nucleon for a certain nucleus is 7.5 MeV. If the nucleus contains 10 nucleons, what is the total binding energy of the nucleus?",
    answerOptions: [
      { answer: "75 MeV", isCorrect: true },
      { answer: "150 MeV", isCorrect: false },
      { answer: "50 MeV", isCorrect: false },
      { answer: "25 MeV", isCorrect: false },
    ],
  },

  {
    question:
      "An electron in a hydrogen atom transitions from the n=4 level to the n=2 level. What is the wavelength of the emitted photon? (Rydberg constant R = 1.097 × 10⁷ m⁻¹)",
    answerOptions: [
      { answer: "486 nm", isCorrect: true },
      { answer: "656 nm", isCorrect: false },
      { answer: "434 nm", isCorrect: false },
      { answer: "410 nm", isCorrect: false },
    ],
  },
  {
    question:
      "A certain metal has a work function of 2.5 eV. What is the threshold frequency of light required to emit electrons from the metal surface? (Planck's constant h = 6.63 × 10⁻³⁴ Js, 1 eV = 1.6 × 10⁻¹⁹ J)",
    answerOptions: [
      { answer: "6.05 × 10¹⁴ Hz", isCorrect: true },
      { answer: "4.57 × 10¹⁴ Hz", isCorrect: false },
      { answer: "7.25 × 10¹⁴ Hz", isCorrect: false },
      { answer: "3.29 × 10¹⁴ Hz", isCorrect: false },
    ],
  },
  {
    question:
      "A positron and an electron collide and annihilate each other. What is the wavelength of the photons produced? (Rest mass of electron/positron = 9.11 × 10⁻³¹ kg, c = 3 × 10⁸ m/s, Planck's constant h = 6.63 × 10⁻³⁴ Js)",
    answerOptions: [
      { answer: "2.43 × 10⁻¹² m", isCorrect: true },
      { answer: "1.21 × 10⁻¹² m", isCorrect: false },
      { answer: "3.63 × 10⁻¹² m", isCorrect: false },
      { answer: "4.86 × 10⁻¹² m", isCorrect: false },
    ],
  },
  {
    question:
      "A sample of radioactive material decays to 25% of its original activity in 10 years. What is the half-life of the material?",
    answerOptions: [
      { answer: "5 years", isCorrect: true },
      { answer: "10 years", isCorrect: false },
      { answer: "15 years", isCorrect: false },
      { answer: "20 years", isCorrect: false },
    ],
  },
  {
    question:
      "The stopping potential for electrons emitted from a metal surface is 3 V when light of wavelength 400 nm is incident on it. What is the work function of the metal? (Planck's constant h = 6.63 × 10⁻³⁴ Js, e = 1.6 × 10⁻¹⁹ C, speed of light c = 3 × 10⁸ m/s)",
    answerOptions: [
      { answer: "1.65 eV", isCorrect: true },
      { answer: "2.5 eV", isCorrect: false },
      { answer: "3.7 eV", isCorrect: false },
      { answer: "4.1 eV", isCorrect: false },
    ],
  },
  {
    question:
      "In a nuclear reactor, the binding energy per nucleon of a uranium-235 nucleus is 7.6 MeV. How much energy is released when one mole of uranium-235 undergoes fission? (Avogadro's number = 6.02 × 10²³)",
    answerOptions: [
      { answer: "4.57 × 10¹³ J", isCorrect: true },
      { answer: "3.02 × 10¹³ J", isCorrect: false },
      { answer: "5.89 × 10¹³ J", isCorrect: false },
      { answer: "2.45 × 10¹³ J", isCorrect: false },
    ],
  },
  {
    question:
      "A gamma-ray photon has an energy of 1.25 MeV. What is the frequency of the photon? (Planck's constant h = 6.63 × 10⁻³⁴ Js, 1 eV = 1.6 × 10⁻¹⁹ J)",
    answerOptions: [
      { answer: "3.02 × 10²⁰ Hz", isCorrect: true },
      { answer: "4.21 × 10²⁰ Hz", isCorrect: false },
      { answer: "2.18 × 10²⁰ Hz", isCorrect: false },
      { answer: "1.45 × 10²⁰ Hz", isCorrect: false },
    ],
  },
  {
    question:
      "A beam of neutrons is used in a diffraction experiment. If the de Broglie wavelength of the neutrons is 0.1 nm, what is the kinetic energy of the neutrons? (Planck's constant h = 6.63 × 10⁻³⁴ Js, mass of neutron m = 1.675 × 10⁻²⁷ kg)",
    answerOptions: [
      { answer: "8.6 × 10⁻²¹ J", isCorrect: true },
      { answer: "6.4 × 10⁻²¹ J", isCorrect: false },
      { answer: "9.2 × 10⁻²¹ J", isCorrect: false },
      { answer: "7.3 × 10⁻²¹ J", isCorrect: false },
    ],
  },
  {
    question:
      "In the Compton effect, a photon of initial wavelength 0.03 nm is scattered by an electron at an angle of 90°. What is the change in wavelength of the photon? (Planck's constant h = 6.63 × 10⁻³⁴ Js, mass of electron m = 9.11 × 10⁻³¹ kg, speed of light c = 3 × 10⁸ m/s)",
    answerOptions: [
      { answer: "2.43 × 10⁻¹² m", isCorrect: true },
      { answer: "1.21 × 10⁻¹² m", isCorrect: false },
      { answer: "3.63 × 10⁻¹² m", isCorrect: false },
      { answer: "4.86 × 10⁻¹² m", isCorrect: false },
    ],
  },
  {
    question:
      "A proton is accelerated through a potential difference of 1 MV. What is the relativistic kinetic energy of the proton? (Charge of proton e = 1.6 × 10⁻¹⁹ C, mass of proton m = 1.67 × 10⁻²⁷ kg, speed of light c = 3 × 10⁸ m/s)",
    answerOptions: [
      { answer: "1.6 × 10⁻¹³ J", isCorrect: true },
      { answer: "3.2 × 10⁻¹³ J", isCorrect: false },
      { answer: "4.8 × 10⁻¹³ J", isCorrect: false },
      { answer: "2.4 × 10⁻¹³ J", isCorrect: false },
    ],
  },
  {
    question:
      "The wavelength of light emitted by a certain star is shifted by 0.01 nm due to the Doppler effect. If the speed of light is 3 × 10⁸ m/s and the wavelength of light is 500 nm, what is the velocity of the star relative to Earth?",
    answerOptions: [
      { answer: "6,000 m/s", isCorrect: true },
      { answer: "3,000 m/s", isCorrect: false },
      { answer: "9,000 m/s", isCorrect: false },
      { answer: "12,000 m/s", isCorrect: false },
    ],
  },
  {
    question:
      "A nucleus with a mass number of 238 undergoes alpha decay. What is the mass number of the daughter nucleus?",
    answerOptions: [
      { answer: "234", isCorrect: true },
      { answer: "235", isCorrect: false },
      { answer: "236", isCorrect: false },
      { answer: "237", isCorrect: false },
    ],
  },
  {
    question:
      "In a double-slit experiment, electrons are used instead of light. If the slit separation is 1 mm and the electrons are accelerated through a potential difference of 1 kV, what is the distance between the first and second maxima on a screen 1 m away? (Planck's constant h = 6.63 × 10⁻³⁴ Js, mass of electron m = 9.11 × 10⁻³¹ kg, speed of light c = 3 × 10⁸ m/s)",
    answerOptions: [
      { answer: "1.22 × 10⁻³ m", isCorrect: true },
      { answer: "2.44 × 10⁻³ m", isCorrect: false },
      { answer: "3.66 × 10⁻³ m", isCorrect: false },
      { answer: "4.88 × 10⁻³ m", isCorrect: false },
    ],
  },
  {
    question:
      "A photon of energy 2 MeV is scattered by a stationary electron at an angle of 180°. What is the energy of the scattered photon? (Planck's constant h = 6.63 × 10⁻³⁴ Js, mass of electron m = 9.11 × 10⁻³¹ kg, speed of light c = 3 × 10⁸ m/s)",
    answerOptions: [
      { answer: "1.37 MeV", isCorrect: true },
      { answer: "1.8 MeV", isCorrect: false },
      { answer: "1.5 MeV", isCorrect: false },
      { answer: "1.2 MeV", isCorrect: false },
    ],
  },
  {
    question:
      "A particle moving at 0.6c has a relativistic momentum of 3.33 × 10⁻²² kg·m/s. What is the rest mass of the particle? (c = speed of light)",
    answerOptions: [
      { answer: "3.0 × 10⁻²⁷ kg", isCorrect: true },
      { answer: "2.5 × 10⁻²⁷ kg", isCorrect: false },
      { answer: "3.5 × 10⁻²⁷ kg", isCorrect: false },
      { answer: "4.0 × 10⁻²⁷ kg", isCorrect: false },
    ],
  },
  {
    question:
      "A certain nuclear reaction releases an energy of 17.6 MeV per reaction. How much energy is released if 1 gram of the substance undergoes the reaction? (Avogadro's number = 6.02 × 10²³, 1 amu = 1.66 × 10⁻²⁷ kg)",
    answerOptions: [
      { answer: "1.6 × 10¹¹ J", isCorrect: true },
      { answer: "3.2 × 10¹¹ J", isCorrect: false },
      { answer: "4.8 × 10¹¹ J", isCorrect: false },
      { answer: "6.4 × 10¹¹ J", isCorrect: false },
    ],
  },
  {
    question:
      "In the Davisson-Germer experiment, electrons are scattered by a crystal lattice. If the accelerating voltage is 54 V, what is the wavelength of the electrons? (Planck's constant h = 6.63 × 10⁻³⁴ Js, mass of electron m = 9.11 × 10⁻³¹ kg)",
    answerOptions: [
      { answer: "0.167 nm", isCorrect: true },
      { answer: "0.250 nm", isCorrect: false },
      { answer: "0.333 nm", isCorrect: false },
      { answer: "0.400 nm", isCorrect: false },
    ],
  },
  {
    question:
      "A particle is moving with a velocity of 0.9c. What is the Lorentz factor (γ) for this particle?",
    answerOptions: [
      { answer: "2.294", isCorrect: true },
      { answer: "1.294", isCorrect: false },
      { answer: "3.294", isCorrect: false },
      { answer: "4.294", isCorrect: false },
    ],
  },
  {
    question:
      "A sample of a radioactive substance has an activity of 200 Bq. If the half-life of the substance is 10 hours, what will the activity be after 30 hours?",
    answerOptions: [
      { answer: "25 Bq", isCorrect: true },
      { answer: "50 Bq", isCorrect: false },
      { answer: "75 Bq", isCorrect: false },
      { answer: "100 Bq", isCorrect: false },
    ],
  },
  {
    question:
      "An X-ray photon is scattered by an electron at an angle of 45°. If the initial wavelength of the photon is 0.01 nm, what is the final wavelength of the photon? (Planck's constant h = 6.63 × 10⁻³⁴ Js, mass of electron m = 9.11 × 10⁻³¹ kg, speed of light c = 3 × 10⁸ m/s)",
    answerOptions: [
      { answer: "0.0102 nm", isCorrect: true },
      { answer: "0.0110 nm", isCorrect: false },
      { answer: "0.0120 nm", isCorrect: false },
      { answer: "0.0130 nm", isCorrect: false },
    ],
  },
  {
    question:
      "A certain radioactive isotope has a decay constant of 0.01 s⁻¹. What is the probability that a single nucleus will decay within 1 second?",
    answerOptions: [
      { answer: "0.01", isCorrect: true },
      { answer: "0.1", isCorrect: false },
      { answer: "0.001", isCorrect: false },
      { answer: "0.02", isCorrect: false },
    ],
  },
  {
    question:
      "In a double-slit experiment, electrons are accelerated through a potential difference of 10 kV. If the slit separation is 0.1 mm and the distance between the screen and the slits is 2 m, what is the distance between the first and second maxima on the screen? (Planck's constant h = 6.63 × 10⁻³⁴ Js, mass of electron m = 9.11 × 10⁻³¹ kg, e = 1.6 × 10⁻¹⁹ C)",
    answerOptions: [
      { answer: "4.78 × 10⁻³ m", isCorrect: true },
      { answer: "2.39 × 10⁻³ m", isCorrect: false },
      { answer: "7.17 × 10⁻³ m", isCorrect: false },
      { answer: "9.56 × 10⁻³ m", isCorrect: false },
    ],
  },
  {
    question:
      "A particle with a rest mass of 2 × 10⁻²⁷ kg is moving at 0.8c. What is its total energy? (c = speed of light, Planck's constant h = 6.63 × 10⁻³⁴ Js)",
    answerOptions: [
      { answer: "1.5 × 10⁻¹⁰ J", isCorrect: true },
      { answer: "2.0 × 10⁻¹⁰ J", isCorrect: false },
      { answer: "1.0 × 10⁻¹⁰ J", isCorrect: false },
      { answer: "0.5 × 10⁻¹⁰ J", isCorrect: false },
    ],
  },
  {
    question:
      "A hydrogen atom in the n=3 state absorbs a photon and transitions to the n=5 state. What is the wavelength of the absorbed photon? (Rydberg constant R = 1.097 × 10⁷ m⁻¹)",
    answerOptions: [
      { answer: "1.28 × 10⁻⁶ m", isCorrect: true },
      { answer: "1.03 × 10⁻⁶ m", isCorrect: false },
      { answer: "2.05 × 10⁻⁶ m", isCorrect: false },
      { answer: "0.85 × 10⁻⁶ m", isCorrect: false },
    ],
  },
  {
    question:
      "An X-ray tube operates at a potential difference of 100 kV. What is the minimum wavelength of the X-rays produced? (Planck's constant h = 6.63 × 10⁻³⁴ Js, c = 3 × 10⁸ m/s, e = 1.6 × 10⁻¹⁹ C)",
    answerOptions: [
      { answer: "0.0124 nm", isCorrect: true },
      { answer: "0.0248 nm", isCorrect: false },
      { answer: "0.0062 nm", isCorrect: false },
      { answer: "0.0186 nm", isCorrect: false },
    ],
  },
  {
    question:
      "A radioactive substance has a half-life of 5 minutes. If initially, there are 1,000 nuclei, how many nuclei will remain after 20 minutes?",
    answerOptions: [
      { answer: "62", isCorrect: true },
      { answer: "125", isCorrect: false },
      { answer: "31", isCorrect: false },
      { answer: "250", isCorrect: false },
    ],
  },
  {
    question:
      "A certain isotope undergoes beta decay and releases an electron with a kinetic energy of 1 MeV. What is the momentum of the electron? (Rest mass of electron = 9.11 × 10⁻³¹ kg, speed of light c = 3 × 10⁸ m/s)",
    answerOptions: [
      { answer: "5.43 × 10⁻²² kg·m/s", isCorrect: true },
      { answer: "3.21 × 10⁻²² kg·m/s", isCorrect: false },
      { answer: "6.54 × 10⁻²² kg·m/s", isCorrect: false },
      { answer: "2.17 × 10⁻²² kg·m/s", isCorrect: false },
    ],
  },
  {
    question:
      "In a nuclear fusion reaction, two deuterium nuclei combine to form a helium-3 nucleus and a neutron. If the binding energy per nucleon of deuterium is 1.1 MeV and that of helium-3 is 2.8 MeV, what is the total energy released in the reaction?",
    answerOptions: [
      { answer: "18.6 MeV", isCorrect: true },
      { answer: "22.4 MeV", isCorrect: false },
      { answer: "15.3 MeV", isCorrect: false },
      { answer: "20.7 MeV", isCorrect: false },
    ],
  },
  {
    question:
      "In the photoelectric effect, light of frequency 8 × 10¹⁴ Hz is incident on a metal surface with a work function of 3 eV. What is the maximum kinetic energy of the emitted electrons? (Planck's constant h = 6.63 × 10⁻³⁴ Js, 1 eV = 1.6 × 10⁻¹⁹ J)",
    answerOptions: [
      { answer: "2.29 eV", isCorrect: true },
      { answer: "1.58 eV", isCorrect: false },
      { answer: "3.42 eV", isCorrect: false },
      { answer: "1.14 eV", isCorrect: false },
    ],
  },
  {
    question:
      "A particle with charge q and mass m is moving in a magnetic field B with velocity v perpendicular to the field. What is the radius of the circular path followed by the particle?",
    answerOptions: [
      { answer: "mv/qB", isCorrect: true },
      { answer: "qv/mB", isCorrect: false },
      { answer: "mvB/q", isCorrect: false },
      { answer: "qB/mv", isCorrect: false },
    ],
  },
  {
    question:
      "A nucleus undergoes gamma decay and emits a photon with an energy of 2 MeV. What is the wavelength of the emitted photon? (Planck's constant h = 6.63 × 10⁻³⁴ Js, c = 3 × 10⁸ m/s, 1 eV = 1.6 × 10⁻¹⁹ J)",
    answerOptions: [
      { answer: "6.2 × 10⁻¹³ m", isCorrect: true },
      { answer: "1.2 × 10⁻¹² m", isCorrect: false },
      { answer: "2.4 × 10⁻¹³ m", isCorrect: false },
      { answer: "4.8 × 10⁻¹³ m", isCorrect: false },
    ],
  },
  {
    question:
      "An electron and a positron annihilate each other, producing two photons. If the initial kinetic energy of the electron is 0.5 MeV and that of the positron is 0.7 MeV, what is the total energy of the two photons? (Rest mass energy of electron/positron = 0.511 MeV)",
    answerOptions: [
      { answer: "2.222 MeV", isCorrect: true },
      { answer: "1.722 MeV", isCorrect: false },
      { answer: "1.022 MeV", isCorrect: false },
      { answer: "0.511 MeV", isCorrect: false },
    ],
  },
  {
    question:
      "A nucleus with a mass number of 220 undergoes alpha decay followed by beta decay. What is the mass number of the final nucleus?",
    answerOptions: [
      { answer: "216", isCorrect: true },
      { answer: "218", isCorrect: false },
      { answer: "222", isCorrect: false },
      { answer: "220", isCorrect: false },
    ],
  },
  {
    question:
      "A particle with rest mass m is moving with a velocity of 0.9c. What is the relativistic momentum of the particle? (c = speed of light)",
    answerOptions: [
      { answer: "2.06mc", isCorrect: true },
      { answer: "1.44mc", isCorrect: false },
      { answer: "3.11mc", isCorrect: false },
      { answer: "0.89mc", isCorrect: false },
    ],
  },
  {
    question:
      "The half-life of a certain radioactive isotope is 30 minutes. If 1 gram of the substance is present initially, how much will remain after 2 hours?",
    answerOptions: [
      { answer: "0.125 g", isCorrect: true },
      { answer: "0.25 g", isCorrect: false },
      { answer: "0.5 g", isCorrect: false },
      { answer: "0.0625 g", isCorrect: false },
    ],
  },
  {
    question:
      "A photon with an energy of 1 GeV interacts with a proton at rest. If the collision produces a proton and a pion, what is the energy of the pion? (Mass of proton = 938 MeV/c², mass of pion = 140 MeV/c², c = speed of light)",
    answerOptions: [
      { answer: "60 MeV", isCorrect: true },
      { answer: "80 MeV", isCorrect: false },
      { answer: "100 MeV", isCorrect: false },
      { answer: "120 MeV", isCorrect: false },
    ],
  },
  {
    question:
      "In a Compton scattering experiment, X-rays with a wavelength of 0.05 nm are scattered at an angle of 120°. What is the wavelength of the scattered X-rays? (Planck's constant h = 6.63 × 10⁻³⁴ Js, mass of electron m = 9.11 × 10⁻³¹ kg, c = 3 × 10⁸ m/s)",
    answerOptions: [
      { answer: "0.053 nm", isCorrect: true },
      { answer: "0.057 nm", isCorrect: false },
      { answer: "0.061 nm", isCorrect: false },
      { answer: "0.065 nm", isCorrect: false },
    ],
  },
];

export default modernphyquestions;
