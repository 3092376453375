const megnaticquestions = [
  {
    question:
      " Which particle moves along a circle under the action of possible contant electric field and magnetic field. Which of the following is possible :",
    answerOptions: [
      { answer: "E = 0, B = 0", isCorrect: false },
      { answer: "E = 0, B not = 0", isCorrect: true },
      { answer: "E not = 0, B = 0", isCorrect: false },
      { answer: "E not = 0, B not = 0", isCorrect: false },
    ],
  },
  {
    question: "Megnatic field lines moves from :",
    answerOptions: [
      { answer: "north to south", isCorrect: true },
      { answer: "south to north", isCorrect: false },
      { answer: "east to west", isCorrect: false },
      { answer: "east to south", isCorrect: false },
    ],
  },
  {
    question:
      "A moving charge create a megnatic field in the surrounding space in addition to its :",
    answerOptions: [
      { answer: "free space", isCorrect: false },
      { answer: "electric field", isCorrect: true },
      { answer: "electric force", isCorrect: false },
      { answer: "electric intensity", isCorrect: false },
    ],
  },
  {
    question:
      "A loop of flexible conducting wire of length l lie in magnetic field B which is normal to the plane of loop. A current I is passed through the loop. The tension developed in the wire to open up is :",
    answerOptions: [
      { answer: "π/2 BIl", isCorrect: false },
      { answer: "BIl/2", isCorrect: false },
      { answer: "BIl/2 π", isCorrect: true },
      { answer: "BIl", isCorrect: false },
    ],
  },
  {
    question: "Magnetic flux lines are :",
    answerOptions: [
      { answer: "visible", isCorrect: false },
      { answer: "not visible", isCorrect: true },
      { answer: "depends upon strength of field", isCorrect: false },
      { answer: "data insufficient", isCorrect: false },
    ],
  },
  {
    question:
      "The electric force is always parallel or antiparallel to the direction of the :",
    answerOptions: [
      { answer: "electric intensity", isCorrect: false },
      { answer: "electric flux", isCorrect: false },
      { answer: "electric field", isCorrect: true },
      { answer: "electric force", isCorrect: false },
    ],
  },
  {
    question:
      "When the current I is pointing inwards (into the page), the magnetic field B ia a circle in the :",
    answerOptions: [
      { answer: "clockwise sense", isCorrect: true },
      { answer: "anti-clockwise sense", isCorrect: false },
      { answer: "inward", isCorrect: false },
      { answer: "outward", isCorrect: false },
    ],
  },
  {
    question:
      "A square conducting loop is side length L carries a current I. The magnetic field at the center of the loop is :",
    answerOptions: [
      { answer: "independence of L", isCorrect: false },
      { answer: "propportional to L", isCorrect: false },
      { answer: "inverse to L", isCorrect: true },
      { answer: "can not be said", isCorrect: false },
    ],
  },
  {
    question:
      "For a point lying on a plane perpendicular to vector v, the magnetic field is the greatest since :",
    answerOptions: [
      { answer: "θ = 180°", isCorrect: false },
      { answer: "θ = 90°", isCorrect: true },
      { answer: "θ = 45°", isCorrect: false },
      { answer: "θ = 30°", isCorrect: false },
    ],
  },
  {
    question: "Lorentz force is combination of electric force and :",
    answerOptions: [
      { answer: "electric field", isCorrect: false },
      { answer: "magnetic field", isCorrect: false },
      { answer: "magnetic force", isCorrect: true },
      { answer: "megnatic field", isCorrect: false },
    ],
  },
  {
    question:
      "For points lying very far from the particle, the magnetic field is :",
    answerOptions: [
      { answer: "zero", isCorrect: true },
      { answer: "∞", isCorrect: false },
      { answer: "unity", isCorrect: false },
      { answer: "can net be said", isCorrect: false },
    ],
  },
  {
    question: "The magnitude of B is proportional to:",
    answerOptions: [
      { answer: "q", isCorrect: true },
      { answer: "1/q", isCorrect: false },
      { answer: "1/q²", isCorrect: false },
      { answer: "1/q^3", isCorrect: false },
    ],
  },
  {
    question:
      "A long cylindrical wire of radius 'a' carries a current i distributed uniformly over its cross section. If the magnetic fields at distance r < a and R > a from the axis have equal magnitude, then:",
    answerOptions: [
      { answer: "a = R/2 + r/2", isCorrect: false },
      { answer: "a = √(Rr)", isCorrect: true },
      { answer: "a = Rr/R+r", isCorrect: false },
      { answer: "a = R²/r", isCorrect: false },
    ],
  },
  {
    question:
      "The product of pole strength and distance between the two pole is :",
    answerOptions: [
      { answer: "magnetic moment", isCorrect: true },
      { answer: "magnetic field", isCorrect: false },
      { answer: "magnetic intensity ", isCorrect: false },
      { answer: "megnetic pole", isCorrect: false },
    ],
  },
  {
    question:
      "A magnet of length of 2L and magnetic moment M is bent at its center through 60°. Now what will be its magnetic moment :",
    answerOptions: [
      { answer: "M", isCorrect: true },
      { answer: "M/2", isCorrect: false },
      { answer: "2M", isCorrect: false },
      { answer: "4M", isCorrect: false },
    ],
  },
  {
    question:
      "When θ = 0° work is strored in the magnet as its potential energy is :",
    answerOptions: [
      { answer: "W = 0", isCorrect: true },
      { answer: "W = π", isCorrect: false },
      { answer: "W = 1", isCorrect: false },
      { answer: "data insufficient", isCorrect: false },
    ],
  },
  {
    question: "S.I unit of intensity of magnetic field is :",
    answerOptions: [
      { answer: "amp²/m", isCorrect: false },
      { answer: "amp m", isCorrect: false },
      { answer: "amp m²", isCorrect: false },
      { answer: "amp/m", isCorrect: true },
    ],
  },
  {
    question:
      "The value of the electric field strength in vaccum if the energy density is same as that due to a magnetic field of induction 1 T in vaccum is :",
    answerOptions: [
      { answer: "3 x 10^8 N/C", isCorrect: true },
      { answer: "1.5 x 10^8 N/C", isCorrect: false },
      { answer: "2.0 x 10^8 N/C", isCorrect: false },
      { answer: "1.0 x 10^8 N/C", isCorrect: false },
    ],
  },
  {
    question:
      "The electromagnetic has 50 turns and a curent of 5 A flows through coil. If the length of the magnet wire is 200 mm, then magnetic field strength is :",
    answerOptions: [
      { answer: "2500 AT/m", isCorrect: true },
      { answer: "250 AT/m", isCorrect: false },
      { answer: "25 AT/m", isCorrect: false },
      { answer: "2.5 AT/m", isCorrect: false },
    ],
  },
  {
    question:
      "When a charge particle flows at the direction of right angle to the magnetic field, which one will be variable quantity :",
    answerOptions: [
      { answer: "speed", isCorrect: false },
      { answer: "velocity", isCorrect: false },
      { answer: "momentum", isCorrect: true },
      { answer: "gravity", isCorrect: false },
    ],
  },
  {
    question: "The effects of megnatic field outside of a solenoid is :",
    answerOptions: [
      { answer: "half", isCorrect: false },
      { answer: "double", isCorrect: false },
      { answer: "zero", isCorrect: true },
      { answer: "does not effect", isCorrect: false },
    ],
  },
  {
    question: "Meganatic flux strength is also known as :",
    answerOptions: [
      { answer: "megantic field", isCorrect: false },
      { answer: "megnatic strength", isCorrect: false },
      { answer: "megnatic intensity", isCorrect: false },
      { answer: "megnetic flux density", isCorrect: true },
    ],
  },
  {
    question:
      "When current is parallel to magnetic field, then which force is experenced by the current carrying conductor when we placed in uniform magnetic field is :",
    answerOptions: [
      { answer: "half at angle 60°", isCorrect: false },
      { answer: "double at angle 60°", isCorrect: true },
      { answer: "zero at angle 60°", isCorrect: false },
      { answer: "data insufficient", isCorrect: false },
    ],
  },
  {
    question: "Fleming's left hand rule is used for :",
    answerOptions: [
      { answer: "direction of magnetic field", isCorrect: false },
      { answer: "direction of force of current", isCorrect: false },
      { answer: "direction of flux", isCorrect: true },
      { answer: "direction of current", isCorrect: false },
    ],
  },
  {
    question:
      "A conductor of length L having current I passed through ot, when it is placed parallel to a magnetic field. The force on the conductor will be :",
    answerOptions: [
      { answer: "∞", isCorrect: false },
      { answer: "zero", isCorrect: true },
      { answer: "unity", isCorrect: false },
      { answer: "BLI", isCorrect: false },
    ],
  },
  {
    question:
      "A wire of length l is used to form a coil. The megnatic field at its center for a given current in it minimum if the coil has :",
    answerOptions: [
      { answer: "4 turns", isCorrect: false },
      { answer: "2 turns", isCorrect: false },
      { answer: "1 turn", isCorrect: true },
      { answer: "8 turns", isCorrect: false },
    ],
  },
  {
    question: "When θ = 90°, then :",
    answerOptions: [
      { answer: "B = 0", isCorrect: false },
      { answer: "B = 1", isCorrect: true },
      { answer: "B = π", isCorrect: false },
      { answer: "B = π/2", isCorrect: false },
    ],
  },
  {
    question:
      "Two megnets each of magnetis moment M are placed so as to from a cross at right angles to each other. Calculate the magnetic moment of the system :",
    answerOptions: [
      { answer: "M √(2)", isCorrect: true },
      { answer: "M/2 √(2)", isCorrect: false },
      { answer: "M/2", isCorrect: false },
      { answer: "M", isCorrect: false },
    ],
  },
  {
    question: "In the left hand rule, forefinger always represents :",
    answerOptions: [
      { answer: "electric field", isCorrect: false },
      { answer: "electric flux", isCorrect: false },
      { answer: "magnetic field", isCorrect: true },
      { answer: "magnetic flux", isCorrect: false },
    ],
  },
  {
    question:
      "A 300 mm long conductor is carrying a current of 50 A and is suited at right angle to a magnetic field having a megnatic flux density of 0.8 T, What will be force on a conductor will be :",
    answerOptions: [
      { answer: "240 N", isCorrect: false },
      { answer: "0.24 N", isCorrect: false },
      { answer: "24 N", isCorrect: true },
      { answer: "none of these", isCorrect: false },
    ],
  },
  {
    question:
      "A long straight wire carries a current of 5 A. What is the magnetic field strength at a distance of 10 cm from the wire?",
    answerOptions: [
      { answer: "1.0 × 10⁻⁴ T", isCorrect: true },
      { answer: "2.0 × 10⁻⁴ T", isCorrect: false },
      { answer: "5.0 × 10⁻⁴ T", isCorrect: false },
      { answer: "1.0 × 10⁻³ T", isCorrect: false },
    ],
  },
  {
    question:
      "A circular loop of radius 0.1 m carries a current of 2 A. What is the magnetic field at the center of the loop?",
    answerOptions: [
      { answer: "2.52 × 10⁻³ T", isCorrect: true },
      { answer: "1.57 × 10⁻³ T", isCorrect: false },
      { answer: "3.14 × 10⁻³ T", isCorrect: false },
      { answer: "4.00 × 10⁻³ T", isCorrect: false },
    ],
  },
  {
    question:
      "An electron travels perpendicular to a magnetic field of strength 0.2 T with a velocity of 3 × 10⁶ m/s. What is the magnitude of the magnetic force on the electron?",
    answerOptions: [
      { answer: "9.6 × 10⁻¹³ N", isCorrect: true },
      { answer: "1.6 × 10⁻¹² N", isCorrect: false },
      { answer: "3.2 × 10⁻¹² N", isCorrect: false },
      { answer: "4.8 × 10⁻¹² N", isCorrect: false },
    ],
  },
  {
    question:
      "A solenoid with 500 turns and a length of 0.5 m carries a current of 4 A. What is the magnetic field inside the solenoid?",
    answerOptions: [
      { answer: "4.0 × 10⁻² T", isCorrect: true },
      { answer: "5.0 × 10⁻² T", isCorrect: false },
      { answer: "2.0 × 10⁻² T", isCorrect: false },
      { answer: "6.0 × 10⁻² T", isCorrect: false },
    ],
  },
  {
    question:
      "A magnetic field of 0.3 T is directed into the plane of the paper. A proton moves perpendicular to the field with a velocity of 2 × 10⁶ m/s. What is the radius of the proton's circular path?",
    answerOptions: [
      { answer: "1.56 × 10⁻² m", isCorrect: true },
      { answer: "2.00 × 10⁻² m", isCorrect: false },
      { answer: "1.00 × 10⁻² m", isCorrect: false },
      { answer: "3.00 × 10⁻² m", isCorrect: false },
    ],
  },
  {
    question:
      "A magnetic field is created by a coil with a current of 3 A and 100 turns. If the coil has a radius of 0.2 m, what is the magnetic field at the center of the coil?",
    answerOptions: [
      { answer: "1.20 × 10⁻² T", isCorrect: true },
      { answer: "1.50 × 10⁻² T", isCorrect: false },
      { answer: "1.00 × 10⁻² T", isCorrect: false },
      { answer: "1.80 × 10⁻² T", isCorrect: false },
    ],
  },
  {
    question:
      "In a magnetic resonance imaging (MRI) machine, a magnetic field of 1.5 T is used. If the gyromagnetic ratio for a proton is 2.675 × 10⁸ rad/T·s, what is the Larmor frequency of the proton?",
    answerOptions: [
      { answer: "4.01 × 10⁷ Hz", isCorrect: true },
      { answer: "3.50 × 10⁷ Hz", isCorrect: false },
      { answer: "5.00 × 10⁷ Hz", isCorrect: false },
      { answer: "6.00 × 10⁷ Hz", isCorrect: false },
    ],
  },
  {
    question:
      "A transformer with 200 turns in the primary coil and 50 turns in the secondary coil is used to step down the voltage from 240 V to 60 V. What is the current in the secondary coil if the current in the primary coil is 1 A?",
    answerOptions: [
      { answer: "4 A", isCorrect: true },
      { answer: "3 A", isCorrect: false },
      { answer: "5 A", isCorrect: false },
      { answer: "2 A", isCorrect: false },
    ],
  },
  {
    question:
      "A particle with a charge of 1.6 × 10⁻¹⁹ C is moving with a velocity of 1 × 10⁵ m/s in a magnetic field of 0.4 T. If the velocity makes an angle of 30 degrees with the field direction, what is the magnitude of the magnetic force on the particle?",
    answerOptions: [
      { answer: "1.28 × 10⁻¹⁴ N", isCorrect: true },
      { answer: "1.60 × 10⁻¹⁴ N", isCorrect: false },
      { answer: "2.00 × 10⁻¹⁴ N", isCorrect: false },
      { answer: "1.00 × 10⁻¹⁴ N", isCorrect: false },
    ],
  },
  {
    question:
      "In an electric motor, a current-carrying coil is placed in a magnetic field of 0.5 T. If the coil has 10 turns, each with an area of 0.01 m², and the current through the coil is 2 A, what is the maximum torque experienced by the coil?",
    answerOptions: [
      { answer: "0.1 N·m", isCorrect: true },
      { answer: "0.2 N·m", isCorrect: false },
      { answer: "0.3 N·m", isCorrect: false },
      { answer: "0.4 N·m", isCorrect: false },
    ],
  },
  {
    question:
      "A magnetic field of 0.2 T is applied perpendicular to a current-carrying wire. If the wire has a length of 0.5 m and the current through it is 4 A, what is the force exerted on the wire?",
    answerOptions: [
      { answer: "0.4 N", isCorrect: true },
      { answer: "0.6 N", isCorrect: false },
      { answer: "0.2 N", isCorrect: false },
      { answer: "0.8 N", isCorrect: false },
    ],
  },
  {
    question:
      "A charged particle moves in a uniform magnetic field. If the magnetic field strength is increased, how does the radius of the particle's circular path change, assuming the velocity and charge remain constant?",
    answerOptions: [
      { answer: "Decreases", isCorrect: true },
      { answer: "Increases", isCorrect: false },
      { answer: "Remains the same", isCorrect: false },
      { answer: "First increases, then decreases", isCorrect: false },
    ],
  },
  {
    question:
      "In a cyclotron, particles are accelerated by a magnetic field and electric field. If the magnetic field strength is 1.2 T and the particle is a proton with a charge of 1.6 × 10⁻¹⁹ C, what is the cyclotron frequency of the proton?",
    answerOptions: [
      { answer: "2.82 × 10⁷ Hz", isCorrect: true },
      { answer: "3.20 × 10⁷ Hz", isCorrect: false },
      { answer: "2.00 × 10⁷ Hz", isCorrect: false },
      { answer: "2.50 × 10⁷ Hz", isCorrect: false },
    ],
  },
  {
    question:
      "A magnetic field is applied perpendicular to the plane of a current loop. If the magnetic field strength is doubled, what happens to the magnetic flux through the loop?",
    answerOptions: [
      { answer: "Doubles", isCorrect: true },
      { answer: "Halves", isCorrect: false },
      { answer: "Quadruples", isCorrect: false },
      { answer: "Remains the same", isCorrect: false },
    ],
  },
  {
    question:
      "A motor with a rotating coil has an angular velocity of 100 rad/s. If the magnetic field strength is 0.4 T and the area of the coil is 0.05 m², what is the maximum electromotive force (emf) induced in the coil?",
    answerOptions: [
      { answer: "0.8 V", isCorrect: true },
      { answer: "1.0 V", isCorrect: false },
      { answer: "0.6 V", isCorrect: false },
      { answer: "1.2 V", isCorrect: false },
    ],
  },
  {
    question:
      "A metal rod of length 0.2 m is moving with a velocity of 5 m/s perpendicular to a magnetic field of 0.3 T. What is the induced emf across the ends of the rod?",
    answerOptions: [
      { answer: "0.3 V", isCorrect: true },
      { answer: "0.5 V", isCorrect: false },
      { answer: "0.2 V", isCorrect: false },
      { answer: "0.6 V", isCorrect: false },
    ],
  },
  {
    question:
      "A transformer steps up the voltage from 120 V to 480 V. If the primary coil has 50 turns, how many turns does the secondary coil have?",
    answerOptions: [
      { answer: "200 turns", isCorrect: true },
      { answer: "150 turns", isCorrect: false },
      { answer: "100 turns", isCorrect: false },
      { answer: "250 turns", isCorrect: false },
    ],
  },
  {
    question:
      "A magnetic field of 0.1 T is applied parallel to the direction of a current-carrying wire. If the current is 3 A and the wire length is 0.4 m, what is the magnetic force on the wire?",
    answerOptions: [
      { answer: "0 N", isCorrect: true },
      { answer: "0.12 N", isCorrect: false },
      { answer: "0.08 N", isCorrect: false },
      { answer: "0.06 N", isCorrect: false },
    ],
  },
  {
    question:
      "In an induction heating system, a high-frequency current is used to produce eddy currents in a metal object. If the frequency of the current is 50 kHz, what is the period of the alternating current?",
    answerOptions: [
      { answer: "20 µs", isCorrect: true },
      { answer: "10 µs", isCorrect: false },
      { answer: "25 µs", isCorrect: false },
      { answer: "15 µs", isCorrect: false },
    ],
  },
  {
    question:
      "A magnet is placed inside a solenoid carrying a current. If the solenoid has 200 turns and a length of 0.4 m, and the magnetic field inside the solenoid is 0.5 T, what is the current in the solenoid if the permeability of free space is 4π × 10⁻⁷ H/m?",
    answerOptions: [
      { answer: "2.0 A", isCorrect: true },
      { answer: "1.5 A", isCorrect: false },
      { answer: "2.5 A", isCorrect: false },
      { answer: "3.0 A", isCorrect: false },
    ],
  },
  {
    question:
      "A charged particle moves in a circular path with a radius of 0.05 m in a magnetic field of 0.2 T. If the charge of the particle is 1.6 × 10⁻¹⁹ C and its velocity is 1 × 10⁵ m/s, what is the mass of the particle?",
    answerOptions: [
      { answer: "9.6 × 10⁻²² kg", isCorrect: true },
      { answer: "8.0 × 10⁻²² kg", isCorrect: false },
      { answer: "1.2 × 10⁻²² kg", isCorrect: false },
      { answer: "7.2 × 10⁻²² kg", isCorrect: false },
    ],
  },
  {
    question:
      "A moving coil meter has a coil with an area of 1 × 10⁻⁴ m², a magnetic field of 0.1 T, and a current of 2 A. What is the torque experienced by the coil?",
    answerOptions: [
      { answer: "2 × 10⁻³ N·m", isCorrect: true },
      { answer: "1.5 × 10⁻³ N·m", isCorrect: false },
      { answer: "2.5 × 10⁻³ N·m", isCorrect: false },
      { answer: "3 × 10⁻³ N·m", isCorrect: false },
    ],
  },
  {
    question:
      "A railgun accelerates a metal projectile using a magnetic field. If the magnetic field strength is 0.8 T and the length of the rail is 0.4 m, what is the maximum force on the projectile if the current is 10 A?",
    answerOptions: [
      { answer: "3.2 N", isCorrect: true },
      { answer: "4.0 N", isCorrect: false },
      { answer: "2.8 N", isCorrect: false },
      { answer: "3.5 N", isCorrect: false },
    ],
  },
  {
    question:
      "A DC motor has an armature winding of 100 turns and a magnetic field of 0.2 T. If the current through the armature is 4 A and the length of the armature is 0.1 m, what is the torque produced by the motor?",
    answerOptions: [
      { answer: "0.8 N·m", isCorrect: true },
      { answer: "1.0 N·m", isCorrect: false },
      { answer: "0.6 N·m", isCorrect: false },
      { answer: "0.5 N·m", isCorrect: false },
    ],
  },
  {
    question:
      "A magnetic field causes a charged particle to move in a spiral path. If the particle's charge is 1.6 × 10⁻¹⁹ C, its mass is 9.1 × 10⁻³¹ kg, and it has a velocity of 2 × 10⁵ m/s, what is the radius of the spiral path in a magnetic field of 0.3 T?",
    answerOptions: [
      { answer: "0.1 m", isCorrect: true },
      { answer: "0.2 m", isCorrect: false },
      { answer: "0.3 m", isCorrect: false },
      { answer: "0.4 m", isCorrect: false },
    ],
  },
  {
    question:
      "In a Hall effect experiment, a magnetic field of 0.4 T is applied perpendicular to the current in a conductor. If the Hall voltage measured is 0.2 V and the current is 5 A, what is the width of the conductor if the thickness is 0.01 m and the Hall coefficient is 1.6 × 10⁻⁸ m³/C?",
    answerOptions: [
      { answer: "0.025 m", isCorrect: true },
      { answer: "0.030 m", isCorrect: false },
      { answer: "0.035 m", isCorrect: false },
      { answer: "0.040 m", isCorrect: false },
    ],
  },
  {
    question:
      "A magnetic field is used to deflect electrons in a cathode ray tube. If the magnetic field strength is 0.1 T and the velocity of the electrons is 1 × 10⁶ m/s, what is the radius of the electron's path if the charge of the electron is 1.6 × 10⁻¹⁹ C and its mass is 9.1 × 10⁻³¹ kg?",
    answerOptions: [
      { answer: "0.1 m", isCorrect: true },
      { answer: "0.2 m", isCorrect: false },
      { answer: "0.05 m", isCorrect: false },
      { answer: "0.15 m", isCorrect: false },
    ],
  },
  {
    question:
      "An MRI machine generates a magnetic field of 3 T. If the gyromagnetic ratio of a hydrogen nucleus is 2.675 × 10⁸ rad/T·s, what is the resonance frequency of the hydrogen nucleus?",
    answerOptions: [
      { answer: "8.02 × 10⁷ Hz", isCorrect: true },
      { answer: "9.00 × 10⁷ Hz", isCorrect: false },
      { answer: "7.50 × 10⁷ Hz", isCorrect: false },
      { answer: "8.50 × 10⁷ Hz", isCorrect: false },
    ],
  },
  {
    question:
      "A wire loop with 50 turns and an area of 0.02 m² is rotated in a magnetic field of 0.3 T. If the loop completes one rotation every 0.5 seconds, what is the maximum emf induced in the loop?",
    answerOptions: [
      { answer: "1.2 V", isCorrect: true },
      { answer: "1.0 V", isCorrect: false },
      { answer: "1.5 V", isCorrect: false },
      { answer: "1.8 V", isCorrect: false },
    ],
  },
  {
    question:
      "A charged particle enters a magnetic field of 0.5 T with a velocity of 2 × 10⁵ m/s perpendicular to the field. If the charge of the particle is 3.2 × 10⁻¹⁹ C, what is the radius of the particle's circular motion?",
    answerOptions: [
      { answer: "0.32 m", isCorrect: true },
      { answer: "0.25 m", isCorrect: false },
      { answer: "0.40 m", isCorrect: false },
      { answer: "0.20 m", isCorrect: false },
    ],
  },
  {
    question:
      "A transformer with an efficiency of 90% steps down 240 V to 120 V. If the power supplied to the primary coil is 500 W, what is the power delivered to the secondary coil?",
    answerOptions: [
      { answer: "450 W", isCorrect: true },
      { answer: "400 W", isCorrect: false },
      { answer: "350 W", isCorrect: false },
      { answer: "500 W", isCorrect: false },
    ],
  },
  {
    question:
      "A magnetic field of 0.6 T is applied perpendicular to a wire of length 0.2 m carrying a current of 3 A. What is the force experienced by the wire?",
    answerOptions: [
      { answer: "0.36 N", isCorrect: true },
      { answer: "0.24 N", isCorrect: false },
      { answer: "0.48 N", isCorrect: false },
      { answer: "0.30 N", isCorrect: false },
    ],
  },
  {
    question:
      "A magnetic field of 0.4 T is applied to a coil with 200 turns and an area of 0.05 m². What is the total magnetic flux through the coil?",
    answerOptions: [
      { answer: "4.0 Wb", isCorrect: true },
      { answer: "2.0 Wb", isCorrect: false },
      { answer: "6.0 Wb", isCorrect: false },
      { answer: "5.0 Wb", isCorrect: false },
    ],
  },
  {
    question:
      "In a mass spectrometer, ions are accelerated by an electric field and then enter a magnetic field. If the velocity of an ion is 3 × 10⁶ m/s and the magnetic field strength is 0.5 T, what is the radius of the ion's circular path if the ion's mass is 1.6 × 10⁻²⁷ kg?",
    answerOptions: [
      { answer: "0.24 m", isCorrect: true },
      { answer: "0.30 m", isCorrect: false },
      { answer: "0.20 m", isCorrect: false },
      { answer: "0.18 m", isCorrect: false },
    ],
  },
  {
    question:
      "A Hall effect sensor measures a magnetic field of 0.2 T. If the sensor has a width of 0.01 m and the current through it is 2 A, what is the Hall voltage measured across the sensor if the Hall coefficient is 1.0 × 10⁻⁸ m³/C?",
    answerOptions: [
      { answer: "4.0 × 10⁻³ V", isCorrect: true },
      { answer: "3.0 × 10⁻³ V", isCorrect: false },
      { answer: "5.0 × 10⁻³ V", isCorrect: false },
      { answer: "6.0 × 10⁻³ V", isCorrect: false },
    ],
  },
  {
    question:
      "A magnetic field of 0.2 T is applied to a loop with an area of 0.1 m². If the loop is rotated to a position where the magnetic field is perpendicular to its plane, what is the maximum change in magnetic flux through the loop if the initial magnetic flux is 0.02 Wb?",
    answerOptions: [
      { answer: "0.08 Wb", isCorrect: true },
      { answer: "0.06 Wb", isCorrect: false },
      { answer: "0.10 Wb", isCorrect: false },
      { answer: "0.12 Wb", isCorrect: false },
    ],
  },
  {
    question:
      "A particle with a charge of 1.6 × 10⁻¹⁹ C is moving in a magnetic field of 0.3 T with a velocity of 1 × 10⁶ m/s. What is the magnitude of the magnetic force on the particle if the velocity is at an angle of 45 degrees with the field direction?",
    answerOptions: [
      { answer: "4.8 × 10⁻¹⁴ N", isCorrect: true },
      { answer: "3.2 × 10⁻¹⁴ N", isCorrect: false },
      { answer: "5.0 × 10⁻¹⁴ N", isCorrect: false },
      { answer: "2.4 × 10⁻¹⁴ N", isCorrect: false },
    ],
  },
  {
    question:
      "A magnetic field of 0.5 T is applied to a conducting loop with 100 turns. If the loop's area is 0.02 m² and the loop is rotated at 60 revolutions per minute, what is the peak emf induced in the loop?",
    answerOptions: [
      { answer: "1.0 V", isCorrect: true },
      { answer: "0.8 V", isCorrect: false },
      { answer: "1.2 V", isCorrect: false },
      { answer: "1.5 V", isCorrect: false },
    ],
  },
  {
    question:
      "A magnetic field of 0.3 T is applied perpendicular to a current-carrying wire. If the wire has a length of 0.4 m and the current through it is 2 A, what is the magnetic force on the wire?",
    answerOptions: [
      { answer: "0.24 N", isCorrect: true },
      { answer: "0.30 N", isCorrect: false },
      { answer: "0.20 N", isCorrect: false },
      { answer: "0.10 N", isCorrect: false },
    ],
  },
  {
    question:
      "A proton with a charge of 1.6 × 10⁻¹⁹ C is moving in a magnetic field of 0.4 T with a velocity of 2 × 10⁶ m/s. What is the radius of the proton's circular path?",
    answerOptions: [
      { answer: "0.08 m", isCorrect: true },
      { answer: "0.10 m", isCorrect: false },
      { answer: "0.12 m", isCorrect: false },
      { answer: "0.06 m", isCorrect: false },
    ],
  },
  {
    question:
      "A transformer with 200 turns in the primary coil and 50 turns in the secondary coil steps down the voltage from 240 V to 60 V. What is the turns ratio of the transformer?",
    answerOptions: [
      { answer: "4:1", isCorrect: true },
      { answer: "3:1", isCorrect: false },
      { answer: "2:1", isCorrect: false },
      { answer: "5:1", isCorrect: false },
    ],
  },
  {
    question:
      "A magnetic field of 0.2 T is applied perpendicular to a current-carrying wire with a length of 0.5 m. If the current through the wire is 5 A, what is the force on the wire?",
    answerOptions: [
      { answer: "0.5 N", isCorrect: true },
      { answer: "1.0 N", isCorrect: false },
      { answer: "0.4 N", isCorrect: false },
      { answer: "0.3 N", isCorrect: false },
    ],
  },
  {
    question:
      "A solenoid with a length of 0.6 m and 1000 turns carries a current of 3 A. What is the magnetic field inside the solenoid?",
    answerOptions: [
      { answer: "5.0 × 10⁻² T", isCorrect: true },
      { answer: "6.0 × 10⁻² T", isCorrect: false },
      { answer: "4.0 × 10⁻² T", isCorrect: false },
      { answer: "7.0 × 10⁻² T", isCorrect: false },
    ],
  },
  {
    question:
      "A charged particle with a mass of 9.1 × 10⁻³¹ kg and a charge of 1.6 × 10⁻¹⁹ C moves with a velocity of 3 × 10⁵ m/s in a magnetic field of 0.2 T. What is the radius of the particle's circular path?",
    answerOptions: [
      { answer: "0.075 m", isCorrect: true },
      { answer: "0.1 m", isCorrect: false },
      { answer: "0.05 m", isCorrect: false },
      { answer: "0.08 m", isCorrect: false },
    ],
  },
  {
    question:
      "In an electromagnet, if the number of turns of wire is doubled while keeping the current constant, how does the magnetic field strength change?",
    answerOptions: [
      { answer: "Doubles", isCorrect: true },
      { answer: "Halves", isCorrect: false },
      { answer: "Quadruples", isCorrect: false },
      { answer: "Remains the same", isCorrect: false },
    ],
  },
  {
    question:
      "A wire of length 0.1 m is placed in a magnetic field of 0.4 T with a current of 2 A. What is the force on the wire?",
    answerOptions: [
      { answer: "0.08 N", isCorrect: true },
      { answer: "0.1 N", isCorrect: false },
      { answer: "0.12 N", isCorrect: false },
      { answer: "0.14 N", isCorrect: false },
    ],
  },
  {
    question:
      "A magnetic field of 0.3 T is applied to a circular loop with a radius of 0.1 m. What is the magnetic flux through the loop?",
    answerOptions: [
      { answer: "2.8 × 10⁻² Wb", isCorrect: true },
      { answer: "3.0 × 10⁻² Wb", isCorrect: false },
      { answer: "2.5 × 10⁻² Wb", isCorrect: false },
      { answer: "3.5 × 10⁻² Wb", isCorrect: false },
    ],
  },
  {
    question:
      "A magnetic field of 0.2 T is applied to a wire of length 0.3 m carrying a current of 4 A. What is the force on the wire?",
    answerOptions: [
      { answer: "0.24 N", isCorrect: true },
      { answer: "0.30 N", isCorrect: false },
      { answer: "0.20 N", isCorrect: false },
      { answer: "0.12 N", isCorrect: false },
    ],
  },
  {
    question:
      "A charged particle with a charge of 3.2 × 10⁻¹⁹ C and a mass of 6.4 × 10⁻²⁶ kg moves in a magnetic field of 0.5 T with a velocity of 4 × 10⁵ m/s. What is the radius of the particle's circular path?",
    answerOptions: [
      { answer: "0.08 m", isCorrect: true },
      { answer: "0.10 m", isCorrect: false },
      { answer: "0.12 m", isCorrect: false },
      { answer: "0.06 m", isCorrect: false },
    ],
  },
  {
    question:
      "A magnetic field of 0.2 T is applied perpendicular to a coil with 50 turns and an area of 0.01 m². What is the total magnetic flux through the coil?",
    answerOptions: [
      { answer: "0.1 Wb", isCorrect: true },
      { answer: "0.05 Wb", isCorrect: false },
      { answer: "0.2 Wb", isCorrect: false },
      { answer: "0.15 Wb", isCorrect: false },
    ],
  },
  {
    question:
      "A transformer with a primary coil voltage of 240 V and a secondary coil voltage of 60 V has a primary coil with 100 turns. How many turns does the secondary coil have?",
    answerOptions: [
      { answer: "25 turns", isCorrect: true },
      { answer: "30 turns", isCorrect: false },
      { answer: "20 turns", isCorrect: false },
      { answer: "35 turns", isCorrect: false },
    ],
  },
  {
    question:
      "A magnetic field of 0.4 T is applied to a wire loop with an area of 0.03 m². If the magnetic field is perpendicular to the plane of the loop, what is the magnetic flux through the loop?",
    answerOptions: [
      { answer: "0.012 Wb", isCorrect: true },
      { answer: "0.015 Wb", isCorrect: false },
      { answer: "0.010 Wb", isCorrect: false },
      { answer: "0.020 Wb", isCorrect: false },
    ],
  },
  {
    question:
      "A charged particle with a charge of 1.6 × 10⁻¹⁹ C and a mass of 9.1 × 10⁻³¹ kg moves in a magnetic field of 0.5 T with a velocity of 4 × 10⁵ m/s. What is the radius of the particle's circular path?",
    answerOptions: [
      { answer: "0.12 m", isCorrect: true },
      { answer: "0.10 m", isCorrect: false },
      { answer: "0.14 m", isCorrect: false },
      { answer: "0.08 m", isCorrect: false },
    ],
  },
  {
    question:
      "A magnetic field of 0.6 T is applied to a coil with 200 turns and an area of 0.02 m². What is the total magnetic flux through the coil?",
    answerOptions: [
      { answer: "2.4 Wb", isCorrect: true },
      { answer: "2.0 Wb", isCorrect: false },
      { answer: "2.8 Wb", isCorrect: false },
      { answer: "3.0 Wb", isCorrect: false },
    ],
  },
  {
    question:
      "A charged particle with a mass of 1.67 × 10⁻²⁷ kg and a charge of 1.6 × 10⁻¹⁹ C moves in a magnetic field of 0.3 T with a velocity of 2 × 10⁶ m/s. What is the radius of the particle's circular path?",
    answerOptions: [
      { answer: "0.17 m", isCorrect: true },
      { answer: "0.15 m", isCorrect: false },
      { answer: "0.12 m", isCorrect: false },
      { answer: "0.20 m", isCorrect: false },
    ],
  },
  {
    question:
      "A transformer has a primary coil with 500 turns and a secondary coil with 200 turns. If the primary voltage is 240 V, what is the secondary voltage?",
    answerOptions: [
      { answer: "96 V", isCorrect: true },
      { answer: "100 V", isCorrect: false },
      { answer: "120 V", isCorrect: false },
      { answer: "80 V", isCorrect: false },
    ],
  },
  {
    question:
      "A wire with a length of 0.2 m carries a current of 4 A and is placed in a magnetic field of 0.3 T. What is the force on the wire?",
    answerOptions: [
      { answer: "0.24 N", isCorrect: true },
      { answer: "0.30 N", isCorrect: false },
      { answer: "0.20 N", isCorrect: false },
      { answer: "0.28 N", isCorrect: false },
    ],
  },
  {
    question:
      "A solenoid with 200 turns, a length of 0.5 m, and a current of 2 A generates a magnetic field. If the permeability of free space is 4π × 10⁻⁷ H/m, what is the magnetic field strength inside the solenoid?",
    answerOptions: [
      { answer: "0.16 T", isCorrect: true },
      { answer: "0.14 T", isCorrect: false },
      { answer: "0.18 T", isCorrect: false },
      { answer: "0.20 T", isCorrect: false },
    ],
  },
  {
    question:
      "A charged particle with a mass of 9.1 × 10⁻³¹ kg and a charge of 1.6 × 10⁻¹⁹ C moves with a velocity of 2 × 10⁵ m/s in a magnetic field of 0.4 T. What is the radius of the particle's circular path?",
    answerOptions: [
      { answer: "0.10 m", isCorrect: true },
      { answer: "0.08 m", isCorrect: false },
      { answer: "0.12 m", isCorrect: false },
      { answer: "0.15 m", isCorrect: false },
    ],
  },
  {
    question:
      "A wire of length 0.15 m and a current of 3 A is placed in a magnetic field of 0.2 T. What is the force on the wire?",
    answerOptions: [
      { answer: "0.09 N", isCorrect: true },
      { answer: "0.06 N", isCorrect: false },
      { answer: "0.10 N", isCorrect: false },
      { answer: "0.12 N", isCorrect: false },
    ],
  },
  {
    question:
      "A magnetic field of 0.4 T is applied to a coil with 80 turns and an area of 0.03 m². What is the total magnetic flux through the coil?",
    answerOptions: [
      { answer: "0.96 Wb", isCorrect: true },
      { answer: "0.84 Wb", isCorrect: false },
      { answer: "0.72 Wb", isCorrect: false },
      { answer: "1.08 Wb", isCorrect: false },
    ],
  },
  {
    question:
      "A transformer steps down the voltage from 480 V to 120 V. If the primary coil has 60 turns, how many turns does the secondary coil have?",
    answerOptions: [
      { answer: "15 turns", isCorrect: true },
      { answer: "20 turns", isCorrect: false },
      { answer: "10 turns", isCorrect: false },
      { answer: "25 turns", isCorrect: false },
    ],
  },
  {
    question:
      "A magnetic field of 0.5 T is applied to a coil with an area of 0.1 m². If the magnetic field is perpendicular to the plane of the coil, what is the magnetic flux through the coil?",
    answerOptions: [
      { answer: "0.05 Wb", isCorrect: true },
      { answer: "0.04 Wb", isCorrect: false },
      { answer: "0.06 Wb", isCorrect: false },
      { answer: "0.07 Wb", isCorrect: false },
    ],
  },
  {
    question:
      "A wire with a length of 0.25 m carries a current of 6 A in a magnetic field of 0.3 T. What is the force on the wire?",
    answerOptions: [
      { answer: "0.45 N", isCorrect: true },
      { answer: "0.50 N", isCorrect: false },
      { answer: "0.40 N", isCorrect: false },
      { answer: "0.30 N", isCorrect: false },
    ],
  },
  {
    question:
      "A magnetic field of 0.3 T is applied to a wire with a length of 0.2 m carrying a current of 5 A. What is the force on the wire?",
    answerOptions: [
      { answer: "0.3 N", isCorrect: true },
      { answer: "0.4 N", isCorrect: false },
      { answer: "0.5 N", isCorrect: false },
      { answer: "0.2 N", isCorrect: false },
    ],
  },
  {
    question:
      "A transformer with 80 turns in the primary coil and 20 turns in the secondary coil steps down the voltage from 240 V to 60 V. What is the turns ratio of the transformer?",
    answerOptions: [
      { answer: "4:1", isCorrect: true },
      { answer: "3:1", isCorrect: false },
      { answer: "2:1", isCorrect: false },
      { answer: "5:1", isCorrect: false },
    ],
  },
  {
    question:
      "A magnetic field of 0.5 T is applied to a wire loop with an area of 0.02 m². What is the magnetic flux through the loop?",
    answerOptions: [
      { answer: "0.01 Wb", isCorrect: true },
      { answer: "0.02 Wb", isCorrect: false },
      { answer: "0.03 Wb", isCorrect: false },
      { answer: "0.04 Wb", isCorrect: false },
    ],
  },
  {
    question:
      "A particle with a charge of 1.6 × 10⁻¹⁹ C and a mass of 9.1 × 10⁻³¹ kg moves with a velocity of 1 × 10⁶ m/s in a magnetic field of 0.6 T. What is the radius of the particle's circular path?",
    answerOptions: [
      { answer: "0.092 m", isCorrect: true },
      { answer: "0.10 m", isCorrect: false },
      { answer: "0.08 m", isCorrect: false },
      { answer: "0.12 m", isCorrect: false },
    ],
  },
  {
    question:
      "A magnetic field of 0.6 T is applied to a wire with a length of 0.2 m and a current of 4 A. What is the force on the wire?",
    answerOptions: [
      { answer: "0.48 N", isCorrect: true },
      { answer: "0.40 N", isCorrect: false },
      { answer: "0.50 N", isCorrect: false },
      { answer: "0.60 N", isCorrect: false },
    ],
  },
  {
    question:
      "A solenoid with 500 turns and a length of 0.4 m carries a current of 1 A. What is the magnetic field inside the solenoid if the permeability of free space is 4π × 10⁻⁷ H/m?",
    answerOptions: [
      { answer: "0.05 T", isCorrect: true },
      { answer: "0.10 T", isCorrect: false },
      { answer: "0.15 T", isCorrect: false },
      { answer: "0.20 T", isCorrect: false },
    ],
  },
  {
    question:
      "A magnetic field of 0.3 T is applied to a wire of length 0.25 m carrying a current of 3 A. What is the force on the wire?",
    answerOptions: [
      { answer: "0.225 N", isCorrect: true },
      { answer: "0.30 N", isCorrect: false },
      { answer: "0.15 N", isCorrect: false },
      { answer: "0.20 N", isCorrect: false },
    ],
  },
  {
    question:
      "A transformer with a primary coil with 400 turns and a secondary coil with 100 turns steps down the voltage from 240 V to 60 V. What is the turns ratio of the transformer?",
    answerOptions: [
      { answer: "4:1", isCorrect: true },
      { answer: "3:1", isCorrect: false },
      { answer: "2:1", isCorrect: false },
      { answer: "5:1", isCorrect: false },
    ],
  },
  {
    question:
      "A wire of length 0.3 m carries a current of 1 A in a magnetic field of 0.6 T. What is the force on the wire?",
    answerOptions: [
      { answer: "0.18 N", isCorrect: true },
      { answer: "0.12 N", isCorrect: false },
      { answer: "0.15 N", isCorrect: false },
      { answer: "0.20 N", isCorrect: false },
    ],
  },
  {
    question:
      "A magnetic field of 0.4 T is applied to a wire of length 0.5 m carrying a current of 5 A. What is the force on the wire?",
    answerOptions: [
      { answer: "1.0 N", isCorrect: true },
      { answer: "0.8 N", isCorrect: false },
      { answer: "1.2 N", isCorrect: false },
      { answer: "1.5 N", isCorrect: false },
    ],
  },
  {
    question:
      "A particle with a charge of 3.2 × 10⁻¹⁹ C and a mass of 6.4 × 10⁻²⁶ kg moves in a magnetic field of 0.3 T with a velocity of 3 × 10⁵ m/s. What is the radius of the particle's circular path?",
    answerOptions: [
      { answer: "0.13 m", isCorrect: true },
      { answer: "0.12 m", isCorrect: false },
      { answer: "0.15 m", isCorrect: false },
      { answer: "0.10 m", isCorrect: false },
    ],
  },
  {
    question:
      "A wire of length 0.4 m carries a current of 5 A in a magnetic field of 0.6 T. What is the force on the wire?",
    answerOptions: [
      { answer: "1.2 N", isCorrect: true },
      { answer: "1.0 N", isCorrect: false },
      { answer: "1.4 N", isCorrect: false },
      { answer: "1.5 N", isCorrect: false },
    ],
  },
  {
    question:
      "A transformer with 600 turns in the primary coil and 200 turns in the secondary coil steps down the voltage from 480 V to 160 V. What is the turns ratio of the transformer?",
    answerOptions: [
      { answer: "3:1", isCorrect: true },
      { answer: "4:1", isCorrect: false },
      { answer: "2:1", isCorrect: false },
      { answer: "5:1", isCorrect: false },
    ],
  },
  {
    question:
      "A wire of length 0.2 m carries a current of 6 A in a magnetic field of 0.4 T. What is the force on the wire?",
    answerOptions: [
      { answer: "0.48 N", isCorrect: true },
      { answer: "0.40 N", isCorrect: false },
      { answer: "0.50 N", isCorrect: false },
      { answer: "0.30 N", isCorrect: false },
    ],
  },
  {
    question:
      "A particle with a charge of 1.6 × 10⁻¹⁹ C and a mass of 9.1 × 10⁻³¹ kg moves in a magnetic field of 0.6 T with a velocity of 2 × 10⁶ m/s. What is the radius of the particle's circular path?",
    answerOptions: [
      { answer: "0.093 m", isCorrect: true },
      { answer: "0.10 m", isCorrect: false },
      { answer: "0.08 m", isCorrect: false },
      { answer: "0.12 m", isCorrect: false },
    ],
  },
  {
    question:
      "A wire with a length of 0.35 m carries a current of 3 A in a magnetic field of 0.5 T. What is the force on the wire?",
    answerOptions: [
      { answer: "0.525 N", isCorrect: true },
      { answer: "0.50 N", isCorrect: false },
      { answer: "0.45 N", isCorrect: false },
      { answer: "0.55 N", isCorrect: false },
    ],
  },
  {
    question:
      "A magnetic field of 0.2 T is applied to a wire of length 0.3 m carrying a current of 6 A. What is the force on the wire?",
    answerOptions: [
      { answer: "0.36 N", isCorrect: true },
      { answer: "0.30 N", isCorrect: false },
      { answer: "0.42 N", isCorrect: false },
      { answer: "0.48 N", isCorrect: false },
    ],
  },
  {
    question:
      "A transformer with a primary voltage of 240 V and a secondary voltage of 120 V has a primary coil with 200 turns. How many turns does the secondary coil have?",
    answerOptions: [
      { answer: "100 turns", isCorrect: true },
      { answer: "150 turns", isCorrect: false },
      { answer: "80 turns", isCorrect: false },
      { answer: "120 turns", isCorrect: false },
    ],
  },
  {
    question:
      "A magnetic field of 0.7 T is applied to a wire of length 0.2 m carrying a current of 5 A. What is the force on the wire?",
    answerOptions: [
      { answer: "0.70 N", isCorrect: true },
      { answer: "0.60 N", isCorrect: false },
      { answer: "0.80 N", isCorrect: false },
      { answer: "0.50 N", isCorrect: false },
    ],
  },
  {
    question:
      "A solenoid with 100 turns, a length of 0.2 m, and a current of 2 A generates a magnetic field. If the permeability of free space is 4π × 10⁻⁷ H/m, what is the magnetic field strength inside the solenoid?",
    answerOptions: [
      { answer: "0.01 T", isCorrect: true },
      { answer: "0.02 T", isCorrect: false },
      { answer: "0.03 T", isCorrect: false },
      { answer: "0.04 T", isCorrect: false },
    ],
  },
  {
    question:
      "A transformer with 50 turns in the primary coil and 10 turns in the secondary coil steps down the voltage from 120 V to 24 V. What is the turns ratio of the transformer?",
    answerOptions: [
      { answer: "5:1", isCorrect: true },
      { answer: "4:1", isCorrect: false },
      { answer: "3:1", isCorrect: false },
      { answer: "6:1", isCorrect: false },
    ],
  },
  {
    question:
      "A magnetic field of 0.3 T is applied to a wire with a length of 0.4 m carrying a current of 2 A. What is the force on the wire?",
    answerOptions: [
      { answer: "0.24 N", isCorrect: true },
      { answer: "0.30 N", isCorrect: false },
      { answer: "0.20 N", isCorrect: false },
      { answer: "0.40 N", isCorrect: false },
    ],
  },
  {
    question:
      "A particle with a charge of 3.2 × 10⁻¹⁹ C and a mass of 6.4 × 10⁻²⁶ kg moves in a magnetic field of 0.5 T with a velocity of 2 × 10⁵ m/s. What is the radius of the particle's circular path?",
    answerOptions: [
      { answer: "0.08 m", isCorrect: true },
      { answer: "0.10 m", isCorrect: false },
      { answer: "0.06 m", isCorrect: false },
      { answer: "0.12 m", isCorrect: false },
    ],
  },
];

export default megnaticquestions;
