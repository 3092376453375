import React from "react";
import '../../../quizzesStyle/quizanswer.css'
import nobelgasquestions from "../chemistryData/NobelGasData";


function NobleGasAnswer(props){

    return (
        <div className="score-section">
            <h2>Complited</h2>
            <p>Each questions carry 5 marks</p>
            <p className="total-score">Total Score : <span>{props.Score}</span> </p>
            <p className="total-answer">Your correct answers is <span>{props.CorrectAnswer}</span> out of <span>{nobelgasquestions.length}</span> questions</p> <br></br>
            
            <div>Answers</div>
            <div className="all-answer">
                 1 : monoatomic,  2 : water, 3 : atomic number, 4 : size, 5 : Two, 6 : fluorine,<br></br>
                 7 : Rn, 8 : sp^3d, 9 : very high, 10 : yes, 11 : semi conductor, 12 : yellow solid,<br></br>
                 13 : Ar, 14 : XeO3, 15 : Ar,  <br></br> </div>
            <p className="thanks">Thanks for playing</p>
            <button className="play-again" onClick={props.handlePlayAgain}>Play again</button>
        </div>
    )
}

export default NobleGasAnswer;