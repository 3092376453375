import { auth } from "../config/firebase";
import { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import "./LinkGeneration.css";
import Home from "../pages/Home";
import Navbar from "../container/Navbar";
import Signup from "../auth/Signup";
import LoginPage from "../auth/LoginPage";
import Dashboard from "../auth/Dashboard";
import SubscriptionPage from "../components/SubscriptionPage";
import UserRanking from "../components/UserRanking";
import UserProfile from "../components/UserProfile";
import Premium from "../components/Premium";
import DiscussionRooms from "../pages/DiscussionRooms";
import About from "../pages/About";
import TermAndCondition from "../pages/TermAndCondition";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import Disclaimer from "../pages/Disclaimer";
import ContactUs from "../pages/ContactUs";
import Support from "../pages/Support";
import AboutDeveloper from "../pages/AboutDeveloper";
import PageScroll from "../pages/PageScroll";
import EngagementTrends from "../pages/EngagementTrends";





import BasicPhy from "../quizzes/physics/physicsProblem/BacicPhy";
import Kenamatics from "../quizzes/physics/physicsProblem/Kenamatics";
import Mechanies from "../quizzes/physics/physicsProblem/Mechanies";
import Circular from "../quizzes/physics/physicsProblem/Circular";
import Wave from "../quizzes/physics/physicsProblem/Wave";
import Optics from "../quizzes/physics/physicsProblem/Optics";
import ModernPhy from "../quizzes/physics/physicsProblem/ModernPhy";
import Megnatic from "../quizzes/physics/physicsProblem/Megnatic";
import Induction from "../quizzes/physics/physicsProblem/Induction";
import Electrostatics from "../quizzes/physics/physicsProblem/Electrostatics";
import Elasticity from "../quizzes/physics/physicsProblem/Elasticity";
import Current from "../quizzes/physics/physicsProblem/Current";

import Algebra from "../quizzes/math/mathProblem/Algebra";
import Calculus from "../quizzes/math/mathProblem/Calculus";
import Coordinate from "../quizzes/math/mathProblem/Cordinate";
import Geometry from "../quizzes/math/mathProblem/Geometry";
import Statistics from "../quizzes/math/mathProblem/Statistics";
import Trigonometry from "../quizzes/math/mathProblem/Trigonometry";
import ThreeDim from "../quizzes/math/mathProblem/ThreeDim";
import AbundanceAndMetaAnswer from "../quizzes/chemistry/chemistryAnswer/AbundanceAndMataAnswer";
import AlkaliMetal from "../quizzes/chemistry/chemistryProblem/AlkaliMetal";
import CarbonCompound from "../quizzes/chemistry/chemistryProblem/CarbonCompound";
import ChemicalEquilibrium from "../quizzes/chemistry/chemistryProblem/ChemicalEquilibrium";
import Chemicalkenatic from "../quizzes/chemistry/chemistryProblem/ChemicalKenatic";
import ChemicalReaction from "../quizzes/chemistry/chemistryProblem/ChemicalReaction";
import CoordinateCompound from "../quizzes/chemistry/chemistryProblem/CordinateCompound";
import DiluteSolnAndCollagative from "../quizzes/chemistry/chemistryProblem/DiluteSolnAndCollagative";
import Electrochemistry from "../quizzes/chemistry/chemistryProblem/Electrochemistry";
import EnvironmentalChemistry from "../quizzes/chemistry/chemistryProblem/EnvironmentalChe";
import AcidBase from "../quizzes/chemistry/chemistryProblem/AcidBase";
import AnalyticalChe from "../quizzes/chemistry/chemistryProblem/AnalyticalChe";
import BasicCon from "../quizzes/chemistry/chemistryProblem/BasicCon";
import BondingAndMoleSt from "../quizzes/chemistry/chemistryProblem/BondingAndMoleSt";
import GroupEleven from "../quizzes/chemistry/chemistryProblem/GroupEleven";
import GroupFifteen from "../quizzes/chemistry/chemistryProblem/GroupFifteen";
import GroupForteen from "../quizzes/chemistry/chemistryProblem/GroupForteen";
import GroupSixteen from "../quizzes/chemistry/chemistryProblem/GroupSixteen";
import GroupThirteen from "../quizzes/chemistry/chemistryProblem/GroupThirteen";
import GroupTwelve from "../quizzes/chemistry/chemistryProblem/GroupTwelve";

import HydroCarbon from "../quizzes/chemistry/chemistryProblem/HydroCarbon";
import Hydrogen from "../quizzes/chemistry/chemistryProblem/Hydrogen";
import Isomerism from "../quizzes/chemistry/chemistryProblem/Isomerism";
import NobelGas from "../quizzes/chemistry/chemistryProblem/NobelGas";
import Nomenclature from "../quizzes/chemistry/chemistryProblem/Nomenclature";
import PeriodicTable from "../quizzes/chemistry/chemistryProblem/PerodicTabel";
import QuantumMechanies from "../quizzes/chemistry/chemistryProblem/QuantumMechanies";
import ReactionIntermediate from "../quizzes/chemistry/chemistryProblem/ReactionIntermediate";
import State from "../quizzes/chemistry/chemistryProblem/State";
import Thermodynamic from "../quizzes/chemistry/chemistryProblem/Thermodynamic";
import TransElements from "../quizzes/chemistry/chemistryProblem/TransElements";
import ReactionMechanism from "../quizzes/chemistry/chemistryProblem/ReactionMechanism";
import UserRecommendations from "../components/UserRecommendations";
import Halogen from "../quizzes/chemistry/chemistryProblem/Halogen";
import Main from "../pages/Main";
import VotingTrends from "../pages/VotingTrends";
import Trends from "../pages/Trends";
import AdminLogin from "../auth/AdminLogin";



function LinkGeneration() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      setUser(currentUser);
    });

    return () => unsubscribe();
  }, []);

  return (
    <div className="link-generation">
      <Router>
        <Navbar>
          <PageScroll/>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/dashboard/:uid" element={<Dashboard />} />
            <Route path="/dashboard/user/:uid" element={<Dashboard />} />
            <Route path="/dashboard" element={user ? <Dashboard /> : <Navigate to="/login" />} /> 
            <Route path="/subscription" element={<SubscriptionPage />} />
            <Route path="/premium" element={<Premium />} />
            <Route path="/trends" element={<Trends />} />
            <Route path="/engagement-trends" element={<EngagementTrends />} />
            <Route path="/voting-trends" element={<VotingTrends />} />
            <Route path="/recommendation" element={<UserRecommendations />} />
            <Route path="/ranking" element={<UserRanking />} />
            <Route path="/profile/:userId" element={<UserProfile />} />
            <Route path="/user/:userId" element={<UserProfile />} />
            <Route path="/user-profile/:followerId" element={<UserProfile />} />  
            <Route path="/discussion-rooms" element={<DiscussionRooms />} /> 
            <Route path="/about" element={<About />} /> 
            <Route path="/terms-and-conditions" element={<TermAndCondition />} /> 
            <Route path="/privacy-policy" element={<PrivacyPolicy />} /> 
            <Route path="/disclaimer" element={<Disclaimer />} /> 
            <Route path="/contact-us" element={<ContactUs />} /> 
            <Route path="/support" element={<Support />} />
            <Route path="/admin-login" element={<AdminLogin />} /> 
            <Route path="/about-xnetous-developer-aqueeqazam" element={<AboutDeveloper />} /> 

         

            <Route path="/physics/unit-dimension-and-vector/quiz-questions" element={<BasicPhy/>} />
            <Route path="/physics/motion-in-plane-and-projectile-motion/quiz-questions" element={<Kenamatics/>} />
            <Route path="/physics/law-of-motion-work-poer-energy-gravitional-force/quiz-questions" element={<Mechanies/>} />
            <Route path="/physics/circular-motion-center-of-mass-rotational-motion/quiz-questions" element={<Circular/>} />
            <Route path="/physics/mechanics-mechnical-property-of-solid-and-flude" element={<Elasticity/>} />
            <Route path="/physics/simple-harmonic-motion-and-wave/quiz-questions" element={<Wave/>} />
            <Route path="/physics/electrostatics/quiz-questions" element={<Electrostatics/>} />
            <Route path="/physics/current-electricity/quiz-questions" element={<Current/>} />
            <Route path="/physics/megnetic-field-overview/quiz-questions" element={<Megnatic/>} />
            <Route path="/physics/electromegantic-induction-and-A.C-current/quiz-questions" element={<Induction/>} />
            <Route path="/physics/optics/quiz-questions" element={<Optics/>} />
            <Route path="/physics/modern-physics/quiz-questions" element={<ModernPhy/>} />
           

            <Route path="/algebra/quiz-questions" element={<Algebra/>} />
            <Route path="calculus/quiz-questions" element={<Calculus/>} />
            <Route path="/coordinate-geometry/quiz-questions" element={<Coordinate/>} />
            <Route path="/geometry/quiz-questions" element={<Geometry/>} />
            <Route path="/statistics/quiz-questions" element={<Statistics/>} />
            <Route path="/trigonometry/quiz-questions" element={<Trigonometry/>} />
            <Route path="/three-dimentional-geometry/quiz-questions" element={<ThreeDim/>} />



            <Route path="/physical-chemistry/abundance-and-metallurgy/quiz-questions" element={<AbundanceAndMetaAnswer/>} />
            <Route path="/physical-chemistry/acid-base-and-salts/quiz-questions" element={<AcidBase/>} />
            <Route path="/inorganic-chemistry/alkali-metal/quiz-questions" element={<AlkaliMetal/>} />
            <Route path="/inorganic-chemistry/analytic-chemistry/quiz-questions" element={<AnalyticalChe/>} />
            <Route path="/physical-chemistry/basic-concept-of-chemistry/overview" element={<BasicCon/>} />
            <Route path="/physical-chemistry/chemical-bonding-and-molecular-structure/quiz-questions" element={<BondingAndMoleSt/>} />
            <Route path="/organic-chemistry/carbon-and-it's-compound/quiz-questions" element={<CarbonCompound/>} />
            <Route path="/physical-chemistry/chemical-equilibrium/quiz-questions" element={<ChemicalEquilibrium/>} />
            <Route path="/physical-chemistry/chemical-kinetics/quiz-questions" element={<Chemicalkenatic/>} />
            <Route path="/physical-chemistry/chemical-reaction/quiz-questions" element={<ChemicalReaction/>} />
            <Route path="/organic-chemistry/carbon-and-it's-compound/quiz-questions" element={<CoordinateCompound/>} />
            <Route path="/physical-chemistry/dilute-solution-and-collagitive-properties/quiz-questions" element={<DiluteSolnAndCollagative/>} />
            <Route path="/physical-chemistry/electrochemistry/quiz-questions" element={<Electrochemistry/>} />
            <Route path="/organic-chemistry/environmental-chemistry/quiz-questions" element={<EnvironmentalChemistry/>} />
            <Route path="/inorganic-chemistry/group-eleven-elements/quiz-questions" element={<GroupEleven/>} />
            <Route path="/inorganic-chemistry/group-fifteen-elements/quiz-questions" element={<GroupFifteen/>} />
            <Route path="/inorganic-chemistry/group-fourteen-elements/quiz-questions" element={<GroupForteen/>} />
            <Route path="/inorganic-chemistry/group-sixteen-elements/quiz-questions" element={<GroupSixteen/>} />
            <Route path="/inorganic-chemistry/group-thirteen-elements/quiz-questions" element={<GroupThirteen/>} />
            <Route path="/inorganic-chemistry/group-twelve-elements/quiz-questions" element={<GroupTwelve/>} />
            <Route path="/inorganic-chemistry/halogen/quiz-questions" element={<Halogen/>} />
            <Route path="/organic-chemistry/hydrocarbon/quiz-questions" element={<HydroCarbon/>} />
            <Route path="/organic-chemistry/hydrocarbon/quiz-questions" element={<Hydrogen/>} />
            <Route path="/organic-chemistry/isomers/quiz-questions" element={<Isomerism/>} />
            <Route path="/inorganic-chemistry/noble-gas/quiz-questions" element={<NobelGas/>} />
            <Route path="/organic-chemistry/nomenclature/quiz-questions" element={<Nomenclature/>} />
            <Route path="/inorganic-chemistry/perodic-table/quiz-questions" element={<PeriodicTable/>} />
            <Route path="/physical-chemistry/quantum-mechanies/quiz-questions" element={<QuantumMechanies/>} />
            <Route path="/organic-chemistry/reaction-intermediate/quiz-questions" element={<ReactionIntermediate/>} />
            <Route path="/organic-chemistry/reaction-mechanism/quiz-questions" element={<ReactionMechanism/>} />
            <Route path="/physical-chemistry/solid-liquid-and-gasous-state/overview" element={<State/>} />
            <Route path="/physical-chemistry/thermodynamic/quiz-questions" element={<Thermodynamic/>} />
            <Route path="/inorganic-chemistry/trans-elements/quiz-questions" element={<TransElements/>} />


         
           
          

           
          </Routes>
        </Navbar>
      </Router>
    </div>
  );
}

export default LinkGeneration;


 {/*<Route path="/trending" element={<Trending />} />
            <Route path="/recent-post" element={<RecentPosts />} />
            <Route path="/ranking" element={<UserRanking />} />
            <Route path="/profile/:userId" element={<UserProfile />} />
            <Route path="/subscription" element={<SubscriptionPage />} />
            <Route path="/user/:userId" element={<UserProfile />} />
            <Route path="/user-profile/:followerId" element={<UserProfile />} /> 
            <Route path="/signup" element={<Signup />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/dashboard/:uid" element={<Dashboard />} />
            <Route path="/dashboard/user/:uid" element={<Dashboard />} />
            <Route
              path="/dashboard"
              element={user ? <Dashboard /> : <Navigate to="/login" />}
            /> */}
