import React, { useState, useEffect } from "react";
import { collection, getDocs, query } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { fireDB } from "../config/firebase";
import { getAuth, onAuthStateChanged } from "firebase/auth";

const Ranking = () => {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentUserId, setCurrentUserId] = useState(null);
    const [currentUserRank, setCurrentUserRank] = useState(null);
    const [currentUserScore, setCurrentUserScore] = useState(0);

    const navigate = useNavigate();

    const calculateEngagementScore = (questions) => {
        return questions.reduce((totalScore, question) => {
            return (
                totalScore +
                (question.likes || 0) * 1.5 +
                (question.comments?.length || 0) * 1.2 +
                (question.shares || 0) * 1.3 +
                (question.retweetedBy?.length || 0) * 1.1
            );
        }, 0);
    };

    useEffect(() => {
        const fetchUsersAndScores = async () => {
            try {
                // Fetch users data
                const usersQuery = query(collection(fireDB, "users"));
                const userSnapshot = await getDocs(usersQuery);
                const usersList = userSnapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));

                // Fetch quiz questions and calculate engagement scores
                const questionsQuery = query(collection(fireDB, "quizQuestions"));
                const questionSnapshot = await getDocs(questionsQuery);
                const questionsList = questionSnapshot.docs.map((doc) => doc.data());

                // Calculate engagement scores for each user
                const usersWithScores = usersList.map((user) => {
                    const userQuestions = questionsList.filter((q) => q.userId === user.id);
                    const engagementScore = calculateEngagementScore(userQuestions);
                    return {
                        ...user,
                        engagementScore,
                    };
                });

                // Sort users based on their engagement score
                const sortedUsers = usersWithScores.sort((a, b) => b.engagementScore - a.engagementScore);

                setUsers(sortedUsers);

                // Find and set the current user's rank and score
                if (currentUserId) {
                    const currentUserIndex = sortedUsers.findIndex((user) => user.id === currentUserId);
                    if (currentUserIndex !== -1) {
                        setCurrentUserRank(currentUserIndex + 1);
                        setCurrentUserScore(sortedUsers[currentUserIndex].engagementScore.toFixed(2));
                    }
                }
            } catch (error) {
                console.error("Error fetching users or performance data:", error);
            } finally {
                setLoading(false);
            }
        };

        // Get the logged-in user's ID
        const auth = getAuth();
        onAuthStateChanged(auth, (user) => {
            if (user) {
                setCurrentUserId(user.uid);
                fetchUsersAndScores();
            }
        });
    }, [currentUserId]);


    return (
        <div className="user-ranking" style={{ maxWidth: "600px", margin: "0 auto", padding: "1rem", background: "#f9f9f9" }}>

          


                    {/* Display current user's rank and score */}
                    {currentUserRank !== null && (
                        <div style={{ marginTop: "1.5rem", padding: "1rem", backgroundColor: "#d1e7dd", borderRadius: "8px" }}>
                            <h3>Your Rank</h3>
                            <p>
                                <strong>Rank:</strong> {currentUserRank}
                                <br />
                                <strong>Score:</strong> {currentUserScore} points
                            </p>
                        </div>
            )}
        </div>
    );
};

export default Ranking;
