const hydrogenquestions = [
    {
        question:" The ionization energy of H is :",
        answerOptions: [
            {answer: '1.36 ev', isCorrect: false},
            {answer: '13.6 ev', isCorrect: true},
            {answer: '136 ev', isCorrect: false},
            {answer: '0.136 ev', isCorrect: false},
        ],
    }, {
        question:"D2O is used in nuclear reactors as :",
        answerOptions: [
            {answer: 'source of neutron', isCorrect: false},
            {answer: 'moderator', isCorrect: true},
            {answer: 'fuel', isCorrect: false},
            {answer: 'meutron obsorber', isCorrect: false},
        ],
    },  {
        question:"Tritium is radioactive and decays giving :",
        answerOptions: [
            {answer: 'gamma-rays', isCorrect: false},
            {answer: 'alpha-rays', isCorrect: false},
            {answer: 'beta-rays', isCorrect: true},
            {answer: 'none of these', isCorrect: false},
        ],
    },  {
        question:"Manufacture of H2 by Bosch's process uses the catalyst :",
        answerOptions: [
            {answer: 'Ni-Cr', isCorrect: false},
            {answer: 'Fe2O3-Cr2O3', isCorrect: true},
            {answer: 'CaO', isCorrect: false},
            {answer: 'FeO-MnO', isCorrect: false},
        ],
    },  {
        question:" The H - bond is strongest in :",
        answerOptions: [
            {answer: 'S - H - - - O', isCorrect: false},
            {answer: 'O - H - - - S', isCorrect: false},
            {answer: 'F - H - - - O', isCorrect: false},
            {answer: 'F - H - - - H', isCorrect: true},
        ],
    },  {
        question:" Which of the following diatomic will not show ortho – para forms  :",
        answerOptions: [
            {answer: 'D2', isCorrect: false},
            {answer: 'N2', isCorrect: false},
            {answer: 'O2', isCorrect: true},
            {answer: 'F2', isCorrect: false},
           
        ],
    }, {
        question:" When H2 and O2 combine to form H2O :",
        answerOptions: [
            {answer: 'H2 is oxidixed', isCorrect: false},
            {answer: 'H2 id reduced', isCorrect: false},
            {answer: 'H2 is reduced and O2 is oxidize', isCorrect: false},
            {answer: 'H2 is oxidized and O2 is reduced', isCorrect: true},
        ],
    },  {
        question:" Which of the following is a boarder line hydride  :",
        answerOptions: [
            {answer: 'NaH', isCorrect: false},
            {answer: 'CaH2', isCorrect: false},
            {answer: 'TIH', isCorrect: true},
            {answer: 'BeH2', isCorrect: false},
        ],
    },  {
        question:" Ortho and para hydrogen differ in :",
        answerOptions: [
            {answer: 'atomic mass', isCorrect: false},
            {answer: 'sp in of protons', isCorrect: false},
            {answer: 'atomic number', isCorrect: false},
            {answer: 'number of neutrons', isCorrect: true},
        ],
    },  {
        question:"Which of the following gaseous mixture is used in Bosch's process for the manufacture of dihydrogen :",
        answerOptions: [
            {answer: 'coal gas', isCorrect: false},
            {answer: 'producer gas', isCorrect: false},
            {answer: 'water gas', isCorrect: true},
            {answer: 'natural gas', isCorrect: false},
        ],
    },  {
        question:"One gram of which of the following hydrides will give maximum H2  :",
        answerOptions: [
            {answer: 'LiH', isCorrect: true},
            {answer: 'NaH', isCorrect: false},
            {answer: 'KH', isCorrect: false},
            {answer: 'MgH', isCorrect: false},
        ],
    },  {
        question:" The coloured oxide which gives H2O2 on reaction with dil. acid is :",
        answerOptions: [
            {answer: 'KO2', isCorrect: true},
            {answer: 'PbO2', isCorrect: false},
            {answer: 'TiO2', isCorrect: false},
            {answer: 'MnO4', isCorrect: false},
        ],
    },  {
        question:" Which of the following is a salt like hydride :",
        answerOptions: [
            {answer: 'PdH2', isCorrect: false},
            {answer: 'NH2', isCorrect: false},
            {answer: 'NaH', isCorrect: true},
            {answer: 'B2H6', isCorrect: false},
        ],
    },  {
        question:"Which is neither a Lewis acid nor a Lewis base  :",
        answerOptions: [
            {answer: 'C2H4', isCorrect: false},
            {answer: 'C3H4', isCorrect: false},
            {answer: 'C4H4', isCorrect: false},
            {answer: 'C2H6', isCorrect: true},
        ],
    },  {
        question:"The molarity of 30 volume H2O2 solution is :",
        answerOptions: [
            {answer: '3.6', isCorrect: false},
            {answer: '5.2', isCorrect: false},
            {answer: '2.7', isCorrect: true},
            {answer: '4.8', isCorrect: false},
        ],
    },  {
        question:" Heavy water is insecticide because :",
        answerOptions: [
            {answer: 'rate of reaction in D2O is fast', isCorrect: false},
            {answer: 'rate of reaction in D2O is slow', isCorrect: true},
            {answer: 'boiling point D2O is high', isCorrect: false},
            {answer: 'none of these', isCorrect: false},
        ],
    },  {
        question:"Which is neither a Lewis acid nor a Lewis base :",
        answerOptions: [
            {answer: 'C2H4', isCorrect: false},
            {answer: 'C3H4', isCorrect: false},
            {answer: 'C4H4', isCorrect: false},
            {answer: 'C2H6', isCorrect: true},
        ],
    },  {
        question:"Which is expected to have lowest conductivity :",
        answerOptions: [
            {answer: 'hard water', isCorrect: false},
            {answer: 'heavy water', isCorrect: false},
            {answer: 'demineralized water', isCorrect: true},
            {answer: 'none of these', isCorrect: false},
        ],
    },  {
        question:"The exhausted permutit is regenerated by percolating through it a solution of  :",
        answerOptions: [
            {answer: 'NaCl', isCorrect: true},
            {answer: 'AlCl', isCorrect: false},
            {answer: 'MgCl', isCorrect: false},
            {answer: 'CaCl', isCorrect: false },
        ],
    },  {
        question:"Manufacture of H2 by Bosch's process uses the catalyst :",
        answerOptions: [
            {answer: 'Ni - Cr', isCorrect: false},
            {answer: 'Fe2O3 - Cr2O3', isCorrect: true},
            {answer: 'CaO', isCorrect: false},
            {answer: 'all of these', isCorrect: false},
        ],
    },  
]

export default hydrogenquestions;