const inductionquestions = [
  {
    question:
      "The equivalent inductancce of two inductors is 2.4 H when connected in parallel and 10 H when connected in series. What is the value of inductance of the individual inductors :",
    answerOptions: [
      { answer: "L1 = 6 H and L2 = 4 H", isCorrect: true },
      { answer: "L1 = 4 H and L2 = 6 H", isCorrect: false },
      { answer: "L1 = 4 H and L2 = 4 H", isCorrect: false },
      { answer: "L1 = 6 H and L2 = 6 H", isCorrect: false },
    ],
  },
  {
    question:
      " A coil with 200 turns and an area of 0.02 square meters is exposed to a magnetic field that changes at a rate of 0.1 Tesla per second. Calculate the induced electromotive force (EMF) in the coil :",
    answerOptions: [
      { answer: "6 Volts", isCorrect: false },
      { answer: "4 Volts", isCorrect: true },
      { answer: "3 Volts", isCorrect: false },
      { answer: "can not be say", isCorrect: false },
    ],
  },
  {
    question:
      "What will happen to the inductance of a solenoid, when the number of turns and the length are doubled keep the area of cross section same :",
    answerOptions: [
      { answer: "L/2", isCorrect: false },
      { answer: "L", isCorrect: false },
      { answer: "2L", isCorrect: true },
      { answer: "L²", isCorrect: false },
    ],
  },
  {
    question:
      " An alternating current is given by the following equations I = 3 √2 sin(100 pi t + pi/4). Given the frequency and rms value of the current :",
    answerOptions: [
      { answer: "3 A", isCorrect: true },
      { answer: "5 A", isCorrect: false },
      { answer: "4 A", isCorrect: false },
      { answer: "2 A", isCorrect: false },
    ],
  },
  {
    question:
      "A magnetic field is increasing in strength at a rate of 0.02 Tesla per second in the positive direction. A wire loop with a resistance of 5 Ω is placed in this changing magnetic field. Calculate the current induced in the loop according to Lenz's law :",
    answerOptions: [
      { answer: "3 milliamperes", isCorrect: false },
      { answer: "4 milliamperes", isCorrect: true },
      { answer: "5 milliamperes", isCorrect: false },
      { answer: "6 milliamperes", isCorrect: false },
    ],
  },
  {
    question:
      "A small coil of radius r is placedat the center of a larger coil of radius R, where R>>r. The two coils are coplaner. The mutual inductance between the coils is proportional to :",
    answerOptions: [
      { answer: "r/R", isCorrect: false },
      { answer: "r²/R", isCorrect: false },
      { answer: "r²/R²", isCorrect: true },
      { answer: "r/R²", isCorrect: false },
    ],
  },
  {
    question:
      "Two coils are placed close to each other. Coil A has an inductance of 0.1 Henrys, and coil B has 500 turns. If the current in coil A changes at a rate of 2 Amperes per second, calculate the induced EMF in coil B (mutual inductance) :",
    answerOptions: [
      { answer: "0.1 Volts", isCorrect: false },
      { answer: "0.2 Volts", isCorrect: true },
      { answer: "0.3 Volts", isCorrect: false },
      { answer: "0.4 Volts", isCorrect: false },
    ],
  },
  {
    question:
      "A voltage, E = 60 sin (314 t), is applied across a resistor of 20 Ω. What will be the rading of I(rms) in an ac ammeter  :",
    answerOptions: [
      { answer: "42.2 A", isCorrect: false },
      { answer: "42.1 A", isCorrect: false },
      { answer: "42.4 A", isCorrect: true },
      { answer: "42.3 A", isCorrect: false },
    ],
  },
  {
    question:
      "A coil of inductance 0.20 H is connected in series with a switch and a cell of emf 1.6 V. The total resistance of the circuit is 4.0 Ω. What is the initial rate of growth of the current when the switch is closed :",
    answerOptions: [
      { answer: "0.050 A/s", isCorrect: false },
      { answer: "0.40 A/s", isCorrect: false },
      { answer: "0.13 A/s", isCorrect: false },
      { answer: "8.0 A/s", isCorrect: true },
    ],
  },
  {
    question:
      " The total heat produced in resistor R in an RL circult when the current in the inductor decreases from I to 0 is :",
    answerOptions: [
      { answer: "LI²", isCorrect: false },
      { answer: "1/2 LI²", isCorrect: true },
      { answer: "3/2 LI²", isCorrect: false },
      { answer: "1/3 LI²", isCorrect: false },
    ],
  },
  {
    question:
      " In LCR circult current resonant frequency is 600 Hz and half power pints are at 650 and 550 Hz. The quality factor is :",
    answerOptions: [
      { answer: "1/6", isCorrect: false },
      { answer: "1/3", isCorrect: false },
      { answer: "6", isCorrect: true },
      { answer: "3", isCorrect: false },
    ],
  },
  {
    question:
      "Current in a coil of  self inductance 2.0 H is increasing as i = 2 sin t². The amount of energy spent during the period when the current changes from 0 to 2 A is  :",
    answerOptions: [
      { answer: "1 J", isCorrect: false },
      { answer: "2 J", isCorrect: false },
      { answer: "3 J", isCorrect: false },
      { answer: "4 J", isCorrect: true },
    ],
  },
  {
    question:
      "An ac voltage is representage by E = 220 √(2) cos(50 pi)t. How many times will the current become zero in 1 s :",
    answerOptions: [
      { answer: "50 times", isCorrect: true },
      { answer: "100 times", isCorrect: false },
      { answer: "30 times", isCorrect: false },
      { answer: "25 times", isCorrect: false },
    ],
  },
  {
    question: "Power factor is one for :",
    answerOptions: [
      { answer: "pure resistor", isCorrect: true },
      { answer: "pure inductor", isCorrect: false },
      { answer: "pure capacitor", isCorrect: false },
      { answer: "All are false", isCorrect: false },
    ],
  },
  {
    question:
      "An alternating voltage E = 50 √(2) sin(100t) V is connected to a 1 meuF capacitor through an ac ammeter. What will be the reading of the ammeter  :",
    answerOptions: [
      { answer: "10 mA", isCorrect: false },
      { answer: "5 mA", isCorrect: true },
      { answer: "5 √(2) mA", isCorrect: false },
      { answer: "10 √(2) mA", isCorrect: false },
    ],
  },
  {
    question:
      "A 0.1 m long conductor carrying a current of 50 A is perpendicular to a megantic field of 1.21 mT. The mechanical power to move the conductor with a speed of 1 m/s is: ",
    answerOptions: [
      { answer: "0.25 mW", isCorrect: false },
      { answer: "6.25 mW", isCorrect: true },
      { answer: "0.625 mW", isCorrect: false },
      { answer: "1 W", isCorrect: false },
    ],
  },
  {
    question:
      "A circuit has a voltage source of 120 Vrms and a resistance of 10 Ω. Calculate the current (I) flowing through the circuit  :",
    answerOptions: [
      { answer: "12 A", isCorrect: true },
      { answer: "13 A", isCorrect: false },
      { answer: "14 A", isCorrect: false },
      { answer: "15 A", isCorrect: false },
    ],
  },
  {
    question:
      " Calculate the peak voltage (V_peak) of an AC circuit with a given rms voltage of 240 V :",
    answerOptions: [
      { answer: "339.40 volts", isCorrect: false },
      { answer: "339.41 volts", isCorrect: true },
      { answer: "339.42 volts", isCorrect: false },
      { answer: "339.43 volts", isCorrect: false },
    ],
  },
  {
    question:
      " A coil with an inductance of 0.5 H and a frequency of 60 Hz is connected to a 120 Vrms AC source. Calculate the reactance (X_L) of the coil :",
    answerOptions: [
      { answer: "approximately 188.5 Ω", isCorrect: true },
      { answer: "approximately 188.6 Ω", isCorrect: false },
      { answer: "approximately 188.7 Ω", isCorrect: false },
      { answer: "approximately 188.8 Ω", isCorrect: false },
    ],
  },
  {
    question:
      " Find the impedance (Z) of a series AC circuit consisting of a 15-ohm resistor, a 10 µF capacitor, and a 0.2 H inductor at a frequency of 100 Hz :",
    answerOptions: [
      { answer: "approximately 80.70 Ω", isCorrect: false },
      { answer: "approximately 80.72 Ω", isCorrect: false },
      { answer: "approximately 80.71 Ω", isCorrect: true },
      { answer: "approximately 80.73 Ω", isCorrect: false },
    ],
  },
  {
    question:
      "A magnet is moved in and out of a coil of wire. What phenomenon is induced in the coil?",
    answerOptions: [
      { answer: "Electromotive Force (EMF)", isCorrect: true },
      { answer: "Static Charge", isCorrect: false },
      { answer: "Thermal Energy", isCorrect: false },
      { answer: "Mechanical Force", isCorrect: false },
    ],
  },
  {
    question:
      "If the magnetic field through a coil increases, what happens to the induced EMF in the coil?",
    answerOptions: [
      { answer: "It increases", isCorrect: true },
      { answer: "It decreases", isCorrect: false },
      { answer: "It remains the same", isCorrect: false },
      { answer: "It becomes zero", isCorrect: false },
    ],
  },
  {
    question:
      "A coil with 100 turns of wire has a change in magnetic flux of 0.02 Wb over 0.1 seconds. What is the average induced EMF?",
    answerOptions: [
      { answer: "20 V", isCorrect: true },
      { answer: "2 V", isCorrect: false },
      { answer: "200 V", isCorrect: false },
      { answer: "0.2 V", isCorrect: false },
    ],
  },
  {
    question:
      "In Faraday's law of electromagnetic induction, the induced EMF is directly proportional to which of the following?",
    answerOptions: [
      { answer: "Rate of change of magnetic flux", isCorrect: true },
      { answer: "Current in the coil", isCorrect: false },
      { answer: "Resistance of the coil", isCorrect: false },
      { answer: "Voltage applied", isCorrect: false },
    ],
  },
  {
    question:
      "An iron core is inserted into a solenoid. What effect does this have on the induced EMF?",
    answerOptions: [
      { answer: "It increases the induced EMF", isCorrect: false },
      { answer: "It decreases the induced EMF", isCorrect: false },
      { answer: "It has no effect on the induced EMF", isCorrect: false },
      { answer: "It increases the magnetic flux", isCorrect: true },
    ],
  },
  {
    question:
      "A transformer has 100 turns on the primary coil and 200 turns on the secondary coil. If the primary voltage is 120 V, what is the secondary voltage?",
    answerOptions: [
      { answer: "240 V", isCorrect: true },
      { answer: "60 V", isCorrect: false },
      { answer: "120 V", isCorrect: false },
      { answer: "30 V", isCorrect: false },
    ],
  },
  {
    question:
      "If a loop of wire is moved into a magnetic field at a constant speed, what happens to the induced current as it enters the field?",
    answerOptions: [
      { answer: "It increases", isCorrect: true },
      { answer: "It decreases", isCorrect: false },
      { answer: "It remains the same", isCorrect: false },
      { answer: "It becomes zero", isCorrect: false },
    ],
  },
  {
    question:
      "What happens to the induced EMF if the area of the coil in a changing magnetic field is doubled while the rate of change of flux remains constant?",
    answerOptions: [
      { answer: "It doubles", isCorrect: true },
      { answer: "It remains the same", isCorrect: false },
      { answer: "It is halved", isCorrect: false },
      { answer: "It quadruples", isCorrect: false },
    ],
  },
  {
    question:
      "In Lenz's Law, the direction of the induced current is such that it opposes what?",
    answerOptions: [
      { answer: "The change in magnetic flux", isCorrect: true },
      { answer: "The direction of the magnetic field", isCorrect: false },
      { answer: "The resistance of the wire", isCorrect: false },
      { answer: "The applied voltage", isCorrect: false },
    ],
  },
  {
    question:
      "A generator rotates a coil in a magnetic field. If the rate of rotation is doubled, what happens to the induced EMF?",
    answerOptions: [
      { answer: "It doubles", isCorrect: true },
      { answer: "It remains the same", isCorrect: false },
      { answer: "It is halved", isCorrect: false },
      { answer: "It quadruples", isCorrect: false },
    ],
  },
  {
    question:
      "The efficiency of an electric transformer is defined as the ratio of what two quantities?",
    answerOptions: [
      { answer: "Output power to input power", isCorrect: true },
      { answer: "Secondary voltage to primary voltage", isCorrect: false },
      { answer: "Primary current to secondary current", isCorrect: false },
      { answer: "Magnetic flux to induced EMF", isCorrect: false },
    ],
  },
  {
    question:
      "A metal rod is moving through a magnetic field. If the magnetic field strength is increased, what happens to the induced EMF in the rod?",
    answerOptions: [
      { answer: "It increases", isCorrect: true },
      { answer: "It decreases", isCorrect: false },
      { answer: "It remains the same", isCorrect: false },
      { answer: "It becomes zero", isCorrect: false },
    ],
  },
  {
    question:
      "In a magnetic induction experiment, the rate of change of magnetic flux is reduced by a factor of 3. How does this affect the induced EMF?",
    answerOptions: [
      { answer: "It is reduced by a factor of 3", isCorrect: true },
      { answer: "It is increased by a factor of 3", isCorrect: false },
      { answer: "It remains the same", isCorrect: false },
      { answer: "It is reduced by a factor of 9", isCorrect: false },
    ],
  },
  {
    question:
      "If a coil of wire is connected to an AC power source, what type of current is induced in the coil?",
    answerOptions: [
      { answer: "Alternating Current (AC)", isCorrect: true },
      { answer: "Direct Current (DC)", isCorrect: false },
      { answer: "Pulsating Current", isCorrect: false },
      { answer: "Static Charge", isCorrect: false },
    ],
  },
  {
    question:
      "A coil with 50 turns is placed in a changing magnetic field. If the change in magnetic flux is 0.01 Wb over 0.5 seconds, what is the induced EMF in the coil?",
    answerOptions: [
      { answer: "1 V", isCorrect: true },
      { answer: "0.1 V", isCorrect: false },
      { answer: "5 V", isCorrect: false },
      { answer: "0.5 V", isCorrect: false },
    ],
  },
  {
    question:
      "In a rotating coil generator, if the number of turns in the coil is increased while keeping the magnetic field strength constant, what happens to the induced EMF?",
    answerOptions: [
      { answer: "It increases", isCorrect: true },
      { answer: "It decreases", isCorrect: false },
      { answer: "It remains the same", isCorrect: false },
      { answer: "It becomes zero", isCorrect: false },
    ],
  },
  {
    question:
      "A magnetic flux of 0.05 Wb is changing uniformly in 2 seconds. What is the rate of change of magnetic flux?",
    answerOptions: [
      { answer: "0.025 Wb/s", isCorrect: true },
      { answer: "0.1 Wb/s", isCorrect: false },
      { answer: "0.05 Wb/s", isCorrect: false },
      { answer: "0.02 Wb/s", isCorrect: false },
    ],
  },
  {
    question:
      "A cyclist rides through a magnetic field created by a generator in a bike-powered lighting system. How does the speed of the bike affect the brightness of the lights?",
    answerOptions: [
      {
        answer:
          "Increased speed increases brightness due to higher induced EMF",
        isCorrect: true,
      },
      {
        answer: "Increased speed decreases brightness due to lower induced EMF",
        isCorrect: false,
      },
      { answer: "Speed does not affect brightness", isCorrect: false },
      {
        answer: "Brightness remains constant regardless of speed",
        isCorrect: false,
      },
    ],
  },
  {
    question:
      "In a power plant, a generator produces electricity through rotating coils in a magnetic field. If the generator’s rotation speed is doubled, what happens to the voltage output of the generator?",
    answerOptions: [
      { answer: "The voltage output doubles", isCorrect: true },
      { answer: "The voltage output is halved", isCorrect: false },
      { answer: "The voltage output remains the same", isCorrect: false },
      { answer: "The voltage output quadruples", isCorrect: false },
    ],
  },
  {
    question:
      "A transformer is used to step up the voltage from a power station before transmission over long distances. What is the primary reason for stepping up the voltage in this manner?",
    answerOptions: [
      {
        answer:
          "To reduce energy loss due to resistance in the transmission lines",
        isCorrect: true,
      },
      {
        answer: "To increase the power output of the power station",
        isCorrect: false,
      },
      { answer: "To make the transformer more efficient", isCorrect: false },
      { answer: "To provide more power to the local grid", isCorrect: false },
    ],
  },
  {
    question:
      "In a wireless charging pad for electric vehicles, how does the distance between the charging pad and the vehicle affect the efficiency of power transfer?",
    answerOptions: [
      {
        answer:
          "Increased distance reduces the efficiency of power transfer due to weaker induced EMF",
        isCorrect: true,
      },
      {
        answer: "Increased distance improves the efficiency of power transfer",
        isCorrect: false,
      },
      {
        answer: "Distance has no effect on power transfer efficiency",
        isCorrect: false,
      },
      {
        answer: "Power transfer efficiency is constant regardless of distance",
        isCorrect: false,
      },
    ],
  },
  {
    question:
      "In an induction stove, how does the material of the cookware affect its heating efficiency?",
    answerOptions: [
      {
        answer:
          "Cookware must be ferromagnetic to induce currents effectively and heat up efficiently",
        isCorrect: true,
      },
      {
        answer: "Cookware material has no effect on heating efficiency",
        isCorrect: false,
      },
      {
        answer: "Non-ferromagnetic materials heat up more efficiently",
        isCorrect: false,
      },
      {
        answer:
          "Cookware material only affects the aesthetic quality of the stove",
        isCorrect: false,
      },
    ],
  },
  {
    question:
      "When a car’s alternator charges the battery, how does the speed of the engine affect the charging rate of the battery?",
    answerOptions: [
      {
        answer:
          "Increased engine speed increases the charging rate due to higher induced EMF",
        isCorrect: true,
      },
      {
        answer: "Increased engine speed decreases the charging rate",
        isCorrect: false,
      },
      {
        answer: "Engine speed does not affect the charging rate",
        isCorrect: false,
      },
      {
        answer: "Charging rate is constant regardless of engine speed",
        isCorrect: false,
      },
    ],
  },
];

export default inductionquestions;
