const chemicalreactionquestions= [
    {
        question:"The oxidation numbers of Cl atoms in CaOCl2 are :",
        answerOptions: [
            {answer: '(-1, -1)', isCorrect: false},
            {answer: '(+1, -1)', isCorrect: true},
            {answer: '(+1, +1)', isCorrect: false},
            {answer: '(0, -1)', isCorrect: false},
        ],
    }, {
        question:"The reduction of N in aqueous NO3 by Zn and NaOH involves :",
        answerOptions: [
            {answer: '2 electrons', isCorrect: false},
            {answer: '4 electrons', isCorrect: false},
            {answer: '6 electrons', isCorrect: true},
            {answer: '8 electrons', isCorrect: false},
        ],
    },  {
        question:"The standard reduction potential of three metals x, y, z are –3.03, +0.52 and –1.18V respectively, The order of their reducing power is :",
        answerOptions: [
            {answer: 'x > y > z', isCorrect: true},
            {answer: 'x > z > y', isCorrect: false},
            {answer: 'z > y > x', isCorrect: false},
            {answer: 'z > x > y', isCorrect: false},
        ],
    },  {
        question:" The strongest acid among the following in water, is:",
        answerOptions: [
            {answer: 'SO2(OH)2', isCorrect: false},
            {answer: 'ClO2(OH)', isCorrect: false},
            {answer: 'ClO3(OH)', isCorrect: true},
            {answer: 'H-bond', isCorrect: false},
        ],
    },  {
        question:"The ground state electron configuration of Cr(24) is: :",
        answerOptions: [
            {answer: '3d^4 4s²', isCorrect: true},
            {answer: '4s² 4p^4', isCorrect: false},
            {answer: '3d^5 4s^1', isCorrect: false},
            {answer: '3d^6', isCorrect: false},
        ],
    },  {
        question:" paramagnetic moment of √(8) BM is associated with which of the following ion : ",
        answerOptions: [
            {answer: 'Fe²+', isCorrect: false},
            {answer: 'V²+', isCorrect: false},
            {answer: 'Ni²+', isCorrect: true},
            {answer: 'Cr²+', isCorrect: false},
           
        ],
    }, {
        question:"Which of the following transition metals shows maximum number of oxidation states :",
        answerOptions: [
            {answer: 'V', isCorrect: false},
            {answer: 'Cr', isCorrect: false},
            {answer: 'Mn', isCorrect: true},
            {answer: 'Fe', isCorrect: false},
        ],
    },  {
        question:"Which of the following is not a transition metal ion :",
        answerOptions: [
            {answer: 'Ti^4+', isCorrect: true},
            {answer: 'V^4+', isCorrect: false},
            {answer: 'Mn^4+', isCorrect: false},
            {answer: 'Cr^4+', isCorrect: false},
        ],
    },  {
        question:"Which of the following is antiferromagnetic :",
        answerOptions: [
            {answer: 'TiO', isCorrect: false},
            {answer: 'VO', isCorrect: false},
            {answer: 'MnO', isCorrect: true},
            {answer: 'CuO', isCorrect: false},
        ],
    },  {
        question:" Which of the following has highest second ionisation energy :",
        answerOptions: [
            {answer: 'V', isCorrect: false},
            {answer: 'Cr', isCorrect: true},
            {answer: 'Mn', isCorrect: false},
            {answer: 'Fe', isCorrect: false},
        ],
    },  {
        question:" The pyknometric density of VO is less than its X-ray density. It is due to :",
        answerOptions: [
            {answer: 'Frenkel-defect', isCorrect: false},
            {answer: 'F-center', isCorrect: false},
            {answer: 'V-center', isCorrect: false},
            {answer: 'Schootty defect', isCorrect: true},
        ],
    },  {
        question:"For decolourisation of 1mol of KMnO4, the molecules of H2O2 needed is :",
        answerOptions: [
            {answer: '3/2', isCorrect: false},
            {answer: '5/2', isCorrect: false},
            {answer: '1/2', isCorrect: true},
            {answer: '7/2', isCorrect: false},  
        ],
    },  {
        question:"The electrical conductance of Cu, Ag and Au decreases as: :",
        answerOptions: [
            {answer: 'Cu > Ag > Au', isCorrect: false},
            {answer: 'Au > Ag > Cu', isCorrect: false},
            {answer: 'Ag > Cu > Au', isCorrect: true},
            {answer: 'Ag > Au > Cu', isCorrect: false},
        ],
    },  {
        question:"Cu does not react with dil. H2SO4, because :",
        answerOptions: [
            {answer: 'Cu is a transition metal', isCorrect: false},
            {answer: 'Cu is a coinage metal', isCorrect: false},
            {answer: 'Cu is a E positive', isCorrect: true},
            {answer: 'Cu is a E negative', isCorrect: false},
        ],
    }, {
        question:" The product of oxidation of I − by MnO4 − in alkaline medium is :",
        answerOptions: [
            {answer: 'IO2', isCorrect: false},
            {answer: 'I2', isCorrect: false},
            {answer: 'IO3', isCorrect: true},
            {answer: 'IO4', isCorrect: false},
        ],
    },  {
        question:" Which of the following have different oxidation number of oxygen :",
        answerOptions: [
            {answer: 'CrO5', isCorrect: false},
            {answer: 'H2S2O3', isCorrect: false},
            {answer: 'H2SO3', isCorrect: true},
            {answer: 'H2S2O4', isCorrect: false},
        ],
    }, {
        question:"The reaction Pb3O4 + HCl ---> PbCl2 + Cl2 + H2O is an expamle of  :",
        answerOptions: [
            {answer: 'acid-base reaction', isCorrect: false},
            {answer: 'disproprotionation reaction', isCorrect: true},
            {answer: 'redox reaction', isCorrect: false},
            {answer: 'not redox reaction', isCorrect: false},
        ],
    },  {
        question:"Reduction of manganese in aqueous MnO4 ion involves :",
        answerOptions: [
            {answer: '5 electrons in acis medium', isCorrect: false},
            {answer: '3 electrons in neutral medium', isCorrect: false},
            {answer: '5 electrons in neutral medium', isCorrect: false},
            {answer: '5 electrons in dil. alkali', isCorrect: true},
        ],
    }, {
        question:"Which of these will form coloured aquous solution :",
        answerOptions: [
            {answer: 'Ti^4+', isCorrect: false},
            {answer: 'Sc^3+', isCorrect: false},
            {answer: 'La^3+', isCorrect: false},
            {answer: 'Cr^3+', isCorrect: true},
        ],
    },  {
        question:"Oxidation is also defined as an increase in the  :",
        answerOptions: [
            {answer: 'atomic mass', isCorrect: false},
            {answer: 'atomic radius', isCorrect: false},
            {answer: 'oxidation state', isCorrect: false},
            {answer: 'oxidation number', isCorrect: true},
        ],
    }, {
        question:"Superoxides are strong :",
        answerOptions: [
            {answer: 'buffer solution', isCorrect: false},
            {answer: 'polyprotic', isCorrect: false},
            {answer: 'oxidizing agent', isCorrect: true},
            {answer: 'reducing agent', isCorrect: false},
        ],
    },  {
        question:"The common oxidation state of Ce(58) is :",
        answerOptions: [
            {answer: '+2 and +4', isCorrect: false},
            {answer: '+4 only', isCorrect: false},
            {answer: '+3 and +4', isCorrect: true},
            {answer: '+3 only', isCorrect: false},
        ],
    }, {
        question:"Bond pair electrons are equally divided between two like  :",
        answerOptions: [
            {answer: 'atoms', isCorrect: true},
            {answer: 'ions', isCorrect: false},
            {answer: 'molecules', isCorrect: false},
            {answer: 'none of these', isCorrect: false},
        ],
    },  {
        question:"The first ionization energy of Ni, Pd and Pt :",
        answerOptions: [
            {answer: 'Pt > Ni > Pd', isCorrect: false},
            {answer: 'Pt > Pd > Ni', isCorrect: true},
            {answer: 'Ni > Pd > Pt', isCorrect: false},
            {answer: 'Ni > Pt > Pd', isCorrect: false},
        ],
    }, {
        question:"For which of the lanthanides +2 oxidation state is expected most stable :",
        answerOptions: [
            {answer: 'Ce', isCorrect: false},
            {answer: 'Ga', isCorrect: false},
            {answer: 'Eu', isCorrect: true},
            {answer: 'Pm', isCorrect: false},
        ],
    },  {
        question:"The substance which does oxidation is :",
        answerOptions: [
            {answer: 'oxidation number', isCorrect: false},
            {answer: 'oxidation state', isCorrect: false},
            {answer: 'oxidation agent', isCorrect: true},
            {answer: 'data insuffucuent', isCorrect: false},
        ],
    }, {
        question:"Which of the following is not a transition metal ion :",
        answerOptions: [
            {answer: 'Ti^4+', isCorrect: true},
            {answer: 'V^4+', isCorrect: false},
            {answer: 'Mn^4+', isCorrect: false},
            {answer: 'Ct^4+', isCorrect: false},
        ],
    },  {
        question:"Tt can be used only for ionic reaction :",
        answerOptions: [
            {answer: 'Ion-electron method', isCorrect: true},
            {answer: 'Ion method', isCorrect: false},
            {answer: 'Oxidation state method', isCorrect: false},
            {answer: 'Oxidation number method', isCorrect: false},
        ],
    },  {
        question:"When a transition metal forms interstitial compound, it becomes :",
        answerOptions: [
            {answer: 'more ductile', isCorrect: false},
            {answer: 'more brittle', isCorrect: true},
            {answer: 'more soft', isCorrect: false},
            {answer: 'more soft', isorrect: false},
        ],
    },
]

export default chemicalreactionquestions;