import React, { useState } from "react";
import { toast } from "react-toastify";
import {
  collection,
  doc,
  setDoc,
  updateDoc,
  arrayUnion,
} from "firebase/firestore";
import { fireDB } from "../config/firebase";
import "../componentsStyle/postquiz.css";

function PostQuiz({ user, fetchPostedQuestions }) {
  const [question, setQuestion] = useState("");
  const [options, setOptions] = useState(["", "", "", ""]);
  const [correctAnswer, setCorrectAnswer] = useState("");
  const [category, setCategory] = useState("");
  const [loading, setLoading] = useState(false);

  const handlePostQuestion = async (e) => {
    e.preventDefault();

    if (
      !question ||
      options.some((opt) => !opt) ||
      !correctAnswer ||
      !category
    ) {
      toast.error("Please fill in all fields");
      return;
    }

    setLoading(true);

    try {
      // Create a new quiz question in Firestore
      const questionRef = doc(collection(fireDB, "quizQuestions"));
      await setDoc(questionRef, {
        question,
        options,
        correctAnswer,
        category,
        createdAt: new Date(),
        userId: user.uid, // Add user ID to the question document
      });

      // Update the user's postedQuestions array in Firestore
      const userRef = doc(fireDB, "users", user.uid);
      await updateDoc(userRef, {
        postedQuestions: arrayUnion(questionRef.id),
      });

      toast.success("Question posted successfully!");
      setQuestion(""); // Reset form fields
      setOptions(["", "", "", ""]);
      setCorrectAnswer("");
      setCategory("");
      if (fetchPostedQuestions) fetchPostedQuestions(); // Refetch posted questions for the user dashboard
    } catch (error) {
      console.error("Error posting question:", error);
      toast.error("Failed to post question. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleOptionChange = (index, value) => {
    setOptions((prevOptions) => {
      const updatedOptions = [...prevOptions];
      updatedOptions[index] = value;
      return updatedOptions;
    });
  };

  return (
    <div className="post-quiz-container">
      <h3>Post a New Question</h3>
      <form onSubmit={handlePostQuestion} className="post-quiz-form">
        <div className="form-group">
          <label htmlFor="question">Question</label>
          <input
            type="text"
            id="question"
            value={question}
            onChange={(e) => setQuestion(e.target.value)}
            placeholder="Enter your quiz question"
          />
        </div>

        <div className="form-group">
          <label>Options</label>
          {options.map((option, index) => (
            <input
              key={index}
              type="text"
              value={option}
              onChange={(e) => handleOptionChange(index, e.target.value)}
              placeholder={`Option ${index + 1}`}
            />
          ))}
        </div>

        <div className="form-group">
          <label htmlFor="correctAnswer">Correct Answer</label>
          <input
            type="text"
            id="correctAnswer"
            value={correctAnswer}
            onChange={(e) => setCorrectAnswer(e.target.value)}
            placeholder="Enter the correct answer"
          />
        </div>

        <div className="form-group">
          <label htmlFor="category">Category</label>
          <input
            type="text"
            id="category"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            placeholder="Enter category"
          />
        </div>

        <button
  type="submit"
  className="post-button"
  disabled={loading}
  style={{
    backgroundColor: '#1da1f2', // Twitter blue
    color: '#ffffff',
    border: 'none',
    padding: '12px 30px',
    borderRadius: '5px',
    cursor: 'pointer',
    fontSize: '16px',
    fontWeight: 'bold',
    transition: 'background-color 0.3s ease, transform 0.2s ease',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
    outline: 'none',
    whiteSpace: 'nowrap',
    opacity: loading ? 0.6 : 1, // Change opacity when disabled
  }}
  onMouseOver={(e) => {
    if (!loading) e.target.style.backgroundColor = '#1a91da';
  }}
  onMouseOut={(e) => {
    if (!loading) e.target.style.backgroundColor = '#1da1f2';
  }}
  onMouseDown={(e) => {
    if (!loading) {
      e.target.style.backgroundColor = '#1b88c4';
      e.target.style.transform = 'scale(0.98)';
    }
  }}
  onMouseUp={(e) => {
    if (!loading) {
      e.target.style.backgroundColor = '#1a91da';
      e.target.style.transform = 'scale(1)';
    }
  }}
>
  {loading ? "Posting..." : "Post Question"}
</button>

      </form>
    </div>
  );
}

export default PostQuiz;
