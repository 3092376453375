const circularquestions = [
  {
    question:
      "A simple pendulum of length l and maximum angular displacement θ. Then maximum kenatic energy of a bob of mass m is  :",
    answerOptions: [
      { answer: "1/2 mgl", isCorrect: false },
      { answer: "1/2 mgl cos θ", isCorrect: false },
      { answer: "1/2 mgl sin θ", isCorrect: false },
      { answer: "mgl (1-cos θ)", isCorrect: true },
    ],
  },
  {
    question:
      "A particles is moving in a circle with increasing its speed uniformly. Does tangential accelerarion will be constant :",
    answerOptions: [
      { answer: "yes", isCorrect: true },
      { answer: "no", isCorrect: false },
      { answer: "may be", isCorrect: false },
      { answer: "can not be said", isCorrect: false },
    ],
  },
  {
    question:
      "Two paticles A and B makes a head on elastic collision with each other. They fly apart in opposite direction with equal speeds. The mass ratio will be :",
    answerOptions: [
      { answer: "1/3", isCorrect: true },
      { answer: "1/2", isCorrect: false },
      { answer: "2/3", isCorrect: false },
      { answer: "1/4", isCorrect: false },
    ],
  },
  {
    question:
      "What is the acceleration of a particles in uniform circular motion constant, variable or uniform :",
    answerOptions: [
      { answer: "constant", isCorrect: false },
      { answer: "variable", isCorrect: true },
      { answer: "uniform", isCorrect: false },
      { answer: "data insufficient", isCorrect: false },
    ],
  },
  {
    question:
      "The average resisting force must act on a 5 kg mass to reduce its speed from 65 m/s in 2s :",
    answerOptions: [
      { answer: "12.5 N", isCorrect: false },
      { answer: "125 N", isCorrect: true },
      { answer: "1250 N", isCorrect: false },
      { answer: "can be said", isCorrect: false },
    ],
  },
  {
    question:
      "The motion of body along curve path is remain constant. Which of the following is true :",
    answerOptions: [
      { answer: "velocity", isCorrect: false },
      { answer: "speed", isCorrect: false },
      { answer: "acceleration", isCorrect: false },
      { answer: "second and third are correct", isCorrect: true },
    ],
  },
  {
    question:
      "The radius of gyration of a dics of radius 25 cm about a centroidal axis perpendicular to dics is :",
    answerOptions: [
      { answer: "18 cm", isCorrect: true },
      { answer: "12.5 cm", isCorrect: false },
      { answer: "36 cm", isCorrect: false },
      { answer: "50 cm", isCorrect: false },
    ],
  },
  {
    question: "The momentum of a system with respect to center of mass :",
    answerOptions: [
      { answer: "∞", isCorrect: false },
      { answer: "0", isCorrect: false },
      { answer: "always 0", isCorrect: true },
      { answer: "nothing can be said", isCorrect: false },
    ],
  },
  {
    question:
      "A bomb of mass 9 kg exploaded into two pieces of masses 3 kg and 6 kg. The velocity of mass 3 kg is 16 m/s. The kenatic energy of mass 6 kg is :",
    answerOptions: [
      { answer: "96 kg", isCorrect: false },
      { answer: "384 kg", isCorrect: false },
      { answer: "192 kg", isCorrect: true },
      { answer: "768 kg", isCorrect: false },
    ],
  },
  {
    question:
      "If R is the radius of gyration of a body of mass M and radius r, then the ratio of its rotational to trandlational kenatic energy in the rolling condition is :",
    answerOptions: [
      { answer: "R²/R²+r²", isCorrect: false },
      { answer: "R²/r²", isCorrect: true },
      { answer: "r²/R²", isCorrect: false },
      { answer: "1", isCorrect: false },
    ],
  },
  {
    question:
      "A car is moving on a circular level road of curvature 300 m. If the coefficient of friction is 0.3 and accelerating due to gravity is 10 m/s², the maximum speed of car can be :",
    answerOptions: [
      { answer: "90 km/hr", isCorrect: false },
      { answer: "81 km/hr", isCorrect: false },
      { answer: "108 km/hr", sCorrect: true },
      { answer: "162 km/hr", isCorrect: false },
    ],
  },
  {
    question:
      "A heavy ball moving with speed v with a tiny ball. The collision is elastic, then immediately after the impact, the second ball will moves  with a spped of approx is :",
    answerOptions: [
      { answer: "v", isCorrect: false },
      { answer: "2v", isCorrect: true },
      { answer: "v/2", isCorrect: false },
      { answer: "v/3", isCorrect: false },
    ],
  },
  {
    question:
      " Moment of inertia of a uniform rod of length L and mass M, about an axis passing through L/4 from one end and perpendicular to its length is :",
    answerOptions: [
      { answer: "7ML²/36", isCorrect: false },
      { answer: "7ML²/48", isCorrect: true },
      { answer: "11LM²/48", isCorrect: false },
      { answer: "ML²/12", isCorrect: false },
    ],
  },
  {
    question:
      "A gun is fires a bullets. The barrel of the gun is inclined at an angle 45° with horizontal. When the bullets leaves it will be travelling at an angle greater than 45° with the horizontal. Does this statement true or false :",
    answerOptions: [
      { answer: "false", isCorrect: false },
      { answer: "true", isCorrect: true },
      { answer: "data insufficient", isCorrect: false },
    ],
  },
  {
    question:
      "Let m1 be the mass of rocket at time t = 0. m its mass at any time t and v its velocity at that moment. What is correct equation of rocket propulsion at initial velocity u : (Xnetous chellange)",
    answerOptions: [
      { answer: "v = u - gt + vr ln (m1/m)", isCorrect: true },
      { answer: "v = u - gt + vr ln (m/m1)", isCorrect: false },
      { answer: "v = u + gt + vr ln (m1/m)", isCorrect: false },
      { answer: "u = v - gt + vr ln (m1/m)", isCorrect: false },
    ],
  },
  {
    question:
      "When a rigid body is rotating about a fixed axis and a force is applied on it is called :",
    answerOptions: [
      { answer: "kenatic energy", isCorrect: false },
      { answer: "moment of inertia", isCorrect: false },
      { answer: "torque", isCorrect: true },
      { answer: "radius of gyration", isCorrect: false },
    ],
  },
  {
    question:
      "A particle is given an initial speed u inside a smooth spherical shell of radius R so that it is just able to complete the circle. Acceleration of particles, when its velovity is vertical, is :",
    answerOptions: [
      { answer: "g √10", isCorrect: true },
      { answer: "g", isCorrect: false },
      { answer: "g √2", isCorrect: false },
      { answer: "g √6", isCorrect: false },
    ],
  },
  {
    question: "Angular momentum of a particle about at any point:",
    answerOptions: [
      { answer: "L = mvr sin θ", isCorrect: true },
      { answer: "L = mvr cos θ", isCorrect: false },
      { answer: "L = mvr tan θ", isCorrect: false },
      { answer: "L = mvr", isCorrect: false },
    ],
  },
  {
    question:
      "In the absence of any dissipative force, the mechanical energy of the system will also remain conserved when two block of masses m1 and m2 are moving with velocity v1 and v2 (where v1>v2) along same straight line in a smooth horizontal surface. A spring force is attached to the block of m2. Now let see what happens during the collision between two particles : (Xnetous chellange)",
    answerOptions: [
      { answer: "1/2(m1 + m2)v² + 1/2 kx² m", isCorrect: true },
      { answer: "1/2(m1 - m2)v² + 1/2 kx² m", isCorrect: false },
      { answer: "(m1 + m2)v² + 1/2 kx² m", isCorrect: false },
      { answer: "(m1 - m2)v² + 1/2 kx² m", isCorrect: false },
    ],
  },
  {
    question:
      "A particle rotating in a circular path of radius 54 m with varing speed v = 4t². The angle between velocity and acceleration at t = 3s is  :",
    answerOptions: [
      { answer: "30°", isCorrect: false },
      { answer: "45°", isCorrect: true },
      { answer: "60°", isCorrect: false },
      { answer: "90°", isCorrect: false },
    ],
  },
  {
    question:
      "Particles A makes a head in elastic collision with another stationary particles B. They fly apart in opposite directions with equal speeds. Then mass ratio will be :",
    answerOptions: [
      { answer: "1/3", isCorrect: true },
      { answer: "1/2", isCorrect: false },
      { answer: "1/4", isCorrect: false },
      { answer: "2/3", isCorrect: false },
    ],
  },
  {
    question:
      "The steel ball is droped from a hard surface of 1 m and rebounds to a height of 64 cm. the maximum height attended by the ball after nth bounce is (in m) :",
    answerOptions: [
      { answer: "(0.64)²n", isCorrect: false },
      { answer: "(0.8)²n", isCorrect: true },
      { answer: "(0.6)²2n", isCorrect: false },
      { answer: "(0.8)²n", isCorrect: false },
    ],
  },
  {
    question:
      "A particle is moving in a circle of radius R in a such a way that at any instant the normal and tengential components of its acceleration are equal. If the speed at t = 0 is v. The time taken to complite the first revolution is R/v ( 1 - e^2π ). Does this calculation is correct :",
    answerOptions: [
      { answer: "no", isCorrect: false },
      { answer: "yes", isCorrect: true },
      { answer: "may be", isCorrect: false },
      { answer: "can not be said", isCorrect: false },
    ],
  },
  {
    question: "Time interval is defined as an angular impulse is :",
    answerOptions: [
      { answer: "∫ under a certain limits of t1 and t2", isCorrect: true }, //
      { answer: "∫ under a specific limits of t1 and t2", isCorrect: false },
      { answer: "∫ under a improper limits", isCorrect: false },
      { answer: "data insufficient", isCorrect: false },
    ],
  },
  {
    question:
      "A ball falls vertically onto a floor with a momentum p and then bounces repeatedly. If the coefficient of restitution is e, then total momentum imparted by the ball to the floor is :",
    answerOptions: [
      { answer: "p(1+e)", isCorrect: false },
      { answer: "p/(1-e)", isCorrect: false },
      { answer: "p(1-e/1+e)", isCorrect: false },
      { answer: "P(1+e/1-e)", isCorrect: true },
    ],
  },
  {
    question:
      "A dics and a solid sphere of same mass and radius roll down an inclined plane. The ratio of the friction forace acting on the dics and sphere is :",
    answerOptions: [
      { answer: "7/6", isCorrect: true },
      { answer: "5/4", isCorrect: false },
      { answer: "3/2", isCorrect: false },
      { answer: "data insufficient", isCorrect: false },
    ],
  },
  {
    question: "Which is true for condition of oscillation :",
    answerOptions: [
      { answer: "0 > θ > 90°", isCorrect: false },
      { answer: "0 < θ < 90°", isCorrect: true },
      { answer: "0 < θ < 180°", isCorrect: false },
      { answer: "0 < 1 < 90°", isCorrect: false },
    ],
  },
  {
    question:
      "A train of mass M is moving on a circular track of radius R with constant speed v. The length of the train is half of hte perimeter of the track. The linear momentum of the train will be :",
    answerOptions: [
      { answer: "0", isCorrect: false },
      { answer: "2Mv/π", isCorrect: true },
      { answer: "MvR", isCorrect: false },
      { answer: "Mv", isCorrect: false },
    ],
  },
  {
    question:
      "A particles of mass m = 3 kg moves along a straight line 4y-3x = 2, where x and y are in meter with constant velocity v = 5m/s. The magnitude of angular momentum about the origin is :",
    answerOptions: [
      { answer: "12 kg m²/s", isCorrect: false },
      { answer: "6.0 kg m²/s", isCorrect: true },
      { answer: "4.5 kg m²/s", isCorrect: false },
      { answer: "8.0 kg m²/s", isCorrect: false },
    ],
  },
  {
    question:
      "A bullet of mass m penerates a thickness h of a fixed plate of mass M. If the plate was free to move, then the thickness penetrated will be :",
    answerOptions: [
      { answer: "Mh/M+m", isCorrect: true },
      { answer: "2Mh/M+m", isCorrect: false },
      { answer: "mh/2(M+m)", isCorrect: false },
      { answer: "Mh/2(M+m)", isCorrect: false },
    ],
  },
  {
    question:
      "A rocket of m1 has attended a speed equal to its exhaust speed and at that time the mass of the rocket is m. Then the ratio m1/m will be : (neglate gravity)",
    answerOptions: [
      { answer: "500 N", isCorrect: false },
      { answer: "1.0 N", isCorrect: false },
      { answer: "0.5 N", isCorrect: true },
      { answer: "1000 N", isCorrect: false },
    ],
  },
  {
    question:
      "A small particle of mass 0.36 g rests on a horizontal turnable at a distance 25 cm from the axis of spindle. The turnable is accelerated at a rate of alpha = 1/3 rad/s². The frictional force that the table exerts on hte particle 2s after the startup is :",
    answerOptions: [
      { answer: "40 μN", isCorrect: false },
      { answer: "30 μN", isCorrect: false },
      { answer: "60 μN", isCorrect: false },
      { answer: "50 μN", isCorrect: true },
    ],
  },
  {
    question:
      "A dics of radius R is rolling pure on a flat horizontal surface, with a constant angular velocity. The angle between the velocity and acceleration vectors of point P at end of horizontal radius of disk is : (Xnetous chellange)",
    answerOptions: [
      { answer: "45°", isCorrect: true },
      { answer: "0", isCorrect: false },
      { answer: "tan^-1 (2)", isCorrect: false },
      { answer: "tan^-1 (1/2)", isCorrect: false },
    ],
  },
  {
    question:
      "Which of the following options satisfy condition of leaving the circle :",
    answerOptions: [
      { answer: "0 < θ < 90°", isCorrect: false },
      { answer: "1 < θ < 90°", isCorrect: false },
      { answer: "90° < θ < 180°", isCorrect: true },
      { answer: "45° < θ < 90°", isCorrect: false },
    ],
  },
  {
    question:
      "The density of a thin rod of length l varies with the distance x from one end as ⍴ = ⍴'x²/l². Find the position of center of mass of rod :",
    answerOptions: [
      { answer: "l/3", isCorrect: false },
      { answer: "l", isCorrect: false },
      { answer: "2l/4", isCorrect: false },
      { answer: "3l/4", isCorrect: true },
    ],
  },
  {
    question:
      "The necessary centripetal force is provided by the resultant of tension T and mg cos θ wil be :",
    answerOptions: [
      { answer: "mv/R", isCorrect: false },
      { answer: "mv²/R", isCorrect: true },
      { answer: "mv²/r", isCorrect: false },
      { answer: "mv/(R-r)", isCorrect: false },
    ],
  },
  {
    question:
      " In case of conical pendulum the vertical conmonent of tension balances the weight while its horizontal components provides the necessary :",
    answerOptions: [
      { answer: "acceleration", isCorrect: false },
      { answer: "velocity", isCorrect: false },
      { answer: "speed", isCorrect: false },
      { answer: "centripetal force", isCorrect: true },
    ],
  },
  {
    question:
      "A solid uniform dics of mass m rolls without slipping down a fixed inclined plank with an acceleration a. The frictional force on the disc due to surface of the plane is :",
    answerOptions: [
      { answer: "1/4 ma", isCorrect: false },
      { answer: "3/2 ma", isCorrect: false },
      { answer: "ma", isCorrect: false },
      { answer: "1/2 ma", isCorrect: true },
    ],
  },
  {
    question:
      "A straight rod of lengh L has one of its end at the origin and the other at x = L. If the mass per unit lenght of the rod is give by Ax where A is a constant, where is its center os mass of rod :",
    answerOptions: [
      { answer: "2/3 L", isCorrect: true },
      { answer: "2 L", isCorrect: false },
      { answer: "at center", isCorrect: false },
      { answer: "can not be said", isCorrect: false },
    ],
  },
  {
    question: "Which equation obay the Newton's law of restitution :",
    answerOptions: [
      { answer: "seperated speed x approach speed = e", isCorrect: false },
      { answer: "seperated speed / approach speed = e", isCorrect: true },
      { answer: "seperated speed + approach speed = e", isCorrect: false },
      { answer: "approach speed / seperated speed = e", isCorrect: false },
    ],
  },
  {
    question:
      "A solid disk of radius R and mass M rotates about its central axis with angular velocity ω. What is the moment of inertia of the disk about its central axis?",
    answerOptions: [
      { answer: "1/2 MR^2", isCorrect: true },
      { answer: "2/3 MR^2", isCorrect: false },
      { answer: "1/4 MR^2", isCorrect: false },
      { answer: "MR^2", isCorrect: false },
    ],
  },
  {
    question:
      "A particle is moving in a circular path with radius r and speed v. What is the centripetal force acting on the particle?",
    answerOptions: [
      { answer: "mv^2/r", isCorrect: true },
      { answer: "mvr/2", isCorrect: false },
      { answer: "mv^2r/2", isCorrect: false },
      { answer: "m/v^2r", isCorrect: false },
    ],
  },
  {
    question:
      "A thin rod of length L and mass M rotates about an axis perpendicular to the rod and passing through one end. What is the moment of inertia of the rod about this axis?",
    answerOptions: [
      { answer: "1/3 ML^2", isCorrect: true },
      { answer: "1/2 ML^2", isCorrect: false },
      { answer: "1/4 ML^2", isCorrect: false },
      { answer: "ML^2", isCorrect: false },
    ],
  },
  {
    question:
      "A rotating object has a moment of inertia I and an angular velocity ω. What is its rotational kinetic energy?",
    answerOptions: [
      { answer: "1/2 I ω^2", isCorrect: true },
      { answer: "I ω^2", isCorrect: false },
      { answer: "1/4 I ω^2", isCorrect: false },
      { answer: "I/2 ω^2", isCorrect: false },
    ],
  },
  {
    question:
      "A straight rod of length L has one of its ends at the origin and the other at x = L. If the mass per unit length of the rod is given by Ax where A is a constant, where is its center of mass of the rod?",
    answerOptions: [
      { answer: "2/3 L", isCorrect: true },
      { answer: "2 L", isCorrect: false },
      { answer: "at center", isCorrect: false },
      { answer: "cannot be said", isCorrect: false },
    ],
  },
  {
    question:
      "A ring of radius R and mass M is rotating about its central axis. What is the moment of inertia of the ring about this axis?",
    answerOptions: [
      { answer: "MR^2", isCorrect: true },
      { answer: "1/2 MR^2", isCorrect: false },
      { answer: "1/3 MR^2", isCorrect: false },
      { answer: "2 MR^2", isCorrect: false },
    ],
  },
  {
    question:
      "A solid sphere of radius R and mass M is rotating about an axis through its center. What is its moment of inertia?",
    answerOptions: [
      { answer: "2/5 MR^2", isCorrect: true },
      { answer: "1/2 MR^2", isCorrect: false },
      { answer: "3/5 MR^2", isCorrect: false },
      { answer: "MR^2", isCorrect: false },
    ],
  },
  {
    question:
      "A hollow cylinder of mass M and radius R rotates about its central axis. What is its moment of inertia?",
    answerOptions: [
      { answer: "MR^2", isCorrect: true },
      { answer: "1/2 MR^2", isCorrect: false },
      { answer: "1/4 MR^2", isCorrect: false },
      { answer: "3/4 MR^2", isCorrect: false },
    ],
  },
  {
    question:
      "A disk of mass M and radius R is rotating about its central axis. If its rotational kinetic energy is E, what is its angular velocity?",
    answerOptions: [
      { answer: "sqrt(2E/MR^2)", isCorrect: true },
      { answer: "sqrt(E/MR^2)", isCorrect: false },
      { answer: "sqrt(2E/M)", isCorrect: false },
      { answer: "sqrt(E/M)", isCorrect: false },
    ],
  },
  {
    question:
      "A particle moving in a circular path with radius r has a centripetal acceleration a. What is its speed v?",
    answerOptions: [
      { answer: "sqrt(a r)", isCorrect: true },
      { answer: "a r", isCorrect: false },
      { answer: "a / r", isCorrect: false },
      { answer: "sqrt(a / r)", isCorrect: false },
    ],
  },
  {
    question:
      "A rod of length L and mass M is pivoted at one end and rotated about the pivot. What is the moment of inertia of the rod about the pivot?",
    answerOptions: [
      { answer: "1/3 ML^2", isCorrect: true },
      { answer: "1/2 ML^2", isCorrect: false },
      { answer: "1/4 ML^2", isCorrect: false },
      { answer: "ML^2", isCorrect: false },
    ],
  },
  {
    question:
      "An object of mass m is moving in a circular path of radius r with a constant speed v. What is the expression for the centripetal acceleration of the object?",
    answerOptions: [
      { answer: "v^2 / r", isCorrect: true },
      { answer: "m v^2 / r", isCorrect: false },
      { answer: "r / v^2", isCorrect: false },
      { answer: "m r / v^2", isCorrect: false },
    ],
  },
  {
    question:
      "A particle of mass m is rotating in a circular path with radius r and angular velocity ω. What is the centripetal force acting on the particle?",
    answerOptions: [
      { answer: "m r ω^2", isCorrect: true },
      { answer: "m ω^2 / r", isCorrect: false },
      { answer: "m r / ω^2", isCorrect: false },
      { answer: "m ω / r", isCorrect: false },
    ],
  },
  {
    question:
      "A wheel of mass M and radius R rotates about its central axis. What is its moment of inertia?",
    answerOptions: [
      { answer: "1/2 MR^2", isCorrect: true },
      { answer: "MR^2", isCorrect: false },
      { answer: "1/4 MR^2", isCorrect: false },
      { answer: "2 MR^2", isCorrect: false },
    ],
  },
  {
    question:
      "A solid cylinder of mass M and radius R rotates about its central axis. What is its moment of inertia?",
    answerOptions: [
      { answer: "1/2 MR^2", isCorrect: true },
      { answer: "MR^2", isCorrect: false },
      { answer: "1/4 MR^2", isCorrect: false },
      { answer: "2 MR^2", isCorrect: false },
    ],
  },
  {
    question:
      "A uniform rod of length L and mass M is rotating about its center. What is the moment of inertia of the rod?",
    answerOptions: [
      { answer: "1/12 ML^2", isCorrect: true },
      { answer: "1/6 ML^2", isCorrect: false },
      { answer: "1/4 ML^2", isCorrect: false },
      { answer: "1/2 ML^2", isCorrect: false },
    ],
  },
  {
    question:
      "An object of mass m is attached to a string and rotates in a horizontal circle of radius r with a constant speed v. What is the tension in the string?",
    answerOptions: [
      { answer: "m v^2 / r", isCorrect: true },
      { answer: "m r / v^2", isCorrect: false },
      { answer: "m r v^2", isCorrect: false },
      { answer: "m v / r", isCorrect: false },
    ],
  },
  {
    question:
      "A disk of radius R and mass M is rotating about its central axis. If the angular velocity is doubled, how does the rotational kinetic energy change?",
    answerOptions: [
      { answer: "It increases by a factor of 4", isCorrect: true },
      { answer: "It increases by a factor of 2", isCorrect: false },
      { answer: "It remains the same", isCorrect: false },
      { answer: "It decreases by a factor of 4", isCorrect: false },
    ],
  },
  {
    question:
      "A particle is rotating with an angular velocity ω and has a moment of inertia I. What is the torque required to increase the angular velocity to 2ω?",
    answerOptions: [
      { answer: "2 I ω", isCorrect: true },
      { answer: "I ω", isCorrect: false },
      { answer: "4 I ω", isCorrect: false },
      { answer: "I / ω", isCorrect: false },
    ],
  },
  {
    question:
      "A thin, uniform spherical shell of mass M and radius R is rotating about an axis through its center. What is its moment of inertia?",
    answerOptions: [
      { answer: "2/3 MR^2", isCorrect: true },
      { answer: "1/2 MR^2", isCorrect: false },
      { answer: "MR^2", isCorrect: false },
      { answer: "1/3 MR^2", isCorrect: false },
    ],
  },
  {
    question:
      "A solid sphere of mass M and radius R is rotating about an axis through its center. If its angular velocity is ω, what is its rotational kinetic energy?",
    answerOptions: [
      { answer: "2/5 M R^2 ω^2", isCorrect: true },
      { answer: "1/2 M R^2 ω^2", isCorrect: false },
      { answer: "3/5 M R^2 ω^2", isCorrect: false },
      { answer: "M R^2 ω^2", isCorrect: false },
    ],
  },
  {
    question:
      "A rod of length L and mass M is pivoted at one end and rotates about the pivot. What is the angular momentum of the rod if it rotates with angular velocity ω?",
    answerOptions: [
      { answer: "1/3 M L^2 ω", isCorrect: true },
      { answer: "1/2 M L^2 ω", isCorrect: false },
      { answer: "1/4 M L^2 ω", isCorrect: false },
      { answer: "M L^2 ω", isCorrect: false },
    ],
  },
  {
    question:
      "A particle is moving in a circle of radius r with an angular velocity ω. What is the tangential speed of the particle?",
    answerOptions: [
      { answer: "r ω", isCorrect: true },
      { answer: "ω / r", isCorrect: false },
      { answer: "r / ω", isCorrect: false },
      { answer: "r^2 ω", isCorrect: false },
    ],
  },
  {
    question:
      "A rotating disc has an angular acceleration α. If the moment of inertia of the disc is I, what is the torque applied to the disc?",
    answerOptions: [
      { answer: "I α", isCorrect: true },
      { answer: "α / I", isCorrect: false },
      { answer: "I / α", isCorrect: false },
      { answer: "I α^2", isCorrect: false },
    ],
  },
  {
    question:
      "A particle is moving in a circular path of radius r with constant speed v. What is the work done by the centripetal force over one complete revolution?",
    answerOptions: [
      { answer: "0", isCorrect: true },
      { answer: "mv^2", isCorrect: false },
      { answer: "mv^2 / r", isCorrect: false },
      { answer: "2πr mv^2", isCorrect: false },
    ],
  },
  {
    question:
      "A rotating object with moment of inertia I has an angular velocity ω. What is the rate of change of its rotational kinetic energy if its angular velocity increases at a constant rate?",
    answerOptions: [
      { answer: "I ω (dω/dt)", isCorrect: true },
      { answer: "I dω / dt", isCorrect: false },
      { answer: "I ω^2 dω / dt", isCorrect: false },
      { answer: "I dω^2 / dt", isCorrect: false },
    ],
  },
  {
    question:
      "A hollow sphere of mass M and radius R is rotating about an axis through its center. What is the moment of inertia of the sphere?",
    answerOptions: [
      { answer: "2/3 MR^2", isCorrect: true },
      { answer: "1/2 MR^2", isCorrect: false },
      { answer: "3/5 MR^2", isCorrect: false },
      { answer: "MR^2", isCorrect: false },
    ],
  },
  {
    question:
      "A particle with mass m is moving in a circular path of radius r with angular velocity ω. What is its centripetal acceleration?",
    answerOptions: [
      { answer: "r ω^2", isCorrect: true },
      { answer: "m ω^2 / r", isCorrect: false },
      { answer: "r / ω^2", isCorrect: false },
      { answer: "m r ω", isCorrect: false },
    ],
  },
  {
    question:
      "A rotating disk has a moment of inertia I and an angular velocity ω. What is the angular momentum of the disk?",
    answerOptions: [
      { answer: "I ω", isCorrect: true },
      { answer: "I / ω", isCorrect: false },
      { answer: "ω / I", isCorrect: false },
      { answer: "I^2 ω", isCorrect: false },
    ],
  },
  {
    question:
      "A cylindrical object is rotating about its central axis. What is the moment of inertia of the cylinder if its mass is M and radius is R?",
    answerOptions: [
      { answer: "1/2 MR^2", isCorrect: true },
      { answer: "MR^2", isCorrect: false },
      { answer: "1/4 MR^2", isCorrect: false },
      { answer: "2 MR^2", isCorrect: false },
    ],
  },
  {
    question:
      "A uniform ring of mass M and radius R is rotating about its central axis. What is the moment of inertia of the ring?",
    answerOptions: [
      { answer: "MR^2", isCorrect: true },
      { answer: "1/2 MR^2", isCorrect: false },
      { answer: "1/4 MR^2", isCorrect: false },
      { answer: "2 MR^2", isCorrect: false },
    ],
  },
  {
    question:
      "A pendulum consists of a mass m attached to a string of length L. What is the expression for the period of oscillation of the pendulum for small angles?",
    answerOptions: [
      { answer: "2π sqrt(L / g)", isCorrect: true },
      { answer: "2π sqrt(m / g)", isCorrect: false },
      { answer: "2π sqrt(L g)", isCorrect: false },
      { answer: "2π sqrt(g / L)", isCorrect: false },
    ],
  },
  {
    question:
      "A particle moves in a circular path of radius r with a constant tangential speed v. What is the time period of the particle?",
    answerOptions: [
      { answer: "2π r / v", isCorrect: true },
      { answer: "v / 2π r", isCorrect: false },
      { answer: "2π v / r", isCorrect: false },
      { answer: "r / v", isCorrect: false },
    ],
  },
  {
    question:
      "A wheel of radius R and mass M rotates with an angular velocity ω. What is its rotational kinetic energy?",
    answerOptions: [
      { answer: "1/2 I ω^2", isCorrect: true },
      { answer: "1/2 M R^2 ω^2", isCorrect: false },
      { answer: "M R^2 ω^2", isCorrect: false },
      { answer: "I / 2 ω^2", isCorrect: false },
    ],
  },
  {
    question:
      "A thin rod of length L and mass M is rotating about its end with angular velocity ω. What is the rotational kinetic energy of the rod?",
    answerOptions: [
      { answer: "1/3 ML^2 ω^2", isCorrect: true },
      { answer: "1/2 ML^2 ω^2", isCorrect: false },
      { answer: "ML^2 ω^2", isCorrect: false },
      { answer: "1/4 ML^2 ω^2", isCorrect: false },
    ],
  },
  {
    question:
      "A sphere of mass M and radius R rolls without slipping on a horizontal surface. What is the total kinetic energy of the sphere?",
    answerOptions: [
      { answer: "7/10 MR^2 ω^2", isCorrect: true },
      { answer: "5/10 MR^2 ω^2", isCorrect: false },
      { answer: "3/5 MR^2 ω^2", isCorrect: false },
      { answer: "2/5 MR^2 ω^2", isCorrect: false },
    ],
  },
  {
    question:
      "A mass m is attached to a spring with spring constant k and is rotating in a horizontal circle of radius r. What is the centripetal force on the mass?",
    answerOptions: [
      { answer: "m ω^2 r", isCorrect: true },
      { answer: "k r", isCorrect: false },
      { answer: "k ω^2 r", isCorrect: false },
      { answer: "m k / r", isCorrect: false },
    ],
  },
  {
    question:
      "A rotating object with moment of inertia I has an angular acceleration α. What is the torque applied to the object?",
    answerOptions: [
      { answer: "I α", isCorrect: true },
      { answer: "I / α", isCorrect: false },
      { answer: "α / I", isCorrect: false },
      { answer: "I α^2", isCorrect: false },
    ],
  },
  {
    question:
      "An object of mass m is moving in a circular path of radius r with a constant speed v. What is the expression for the centripetal acceleration of the object?",
    answerOptions: [
      { answer: "v^2 / r", isCorrect: true },
      { answer: "m v^2 / r", isCorrect: false },
      { answer: "r / v^2", isCorrect: false },
      { answer: "m r / v^2", isCorrect: false },
    ],
  },
  {
    question:
      "A particle of mass m is rotating in a circular path with radius r and angular velocity ω. What is the centripetal force acting on the particle?",
    answerOptions: [
      { answer: "m r ω^2", isCorrect: true },
      { answer: "m ω^2 / r", isCorrect: false },
      { answer: "m r / ω^2", isCorrect: false },
      { answer: "m ω / r", isCorrect: false },
    ],
  },
  {
    question:
      "A wheel of mass M and radius R rotates about its central axis. What is its moment of inertia?",
    answerOptions: [
      { answer: "1/2 MR^2", isCorrect: true },
      { answer: "MR^2", isCorrect: false },
      { answer: "1/4 MR^2", isCorrect: false },
      { answer: "2 MR^2", isCorrect: false },
    ],
  },
  {
    question:
      "A solid cylinder of mass M and radius R rotates about its central axis. What is its moment of inertia?",
    answerOptions: [
      { answer: "1/2 MR^2", isCorrect: true },
      { answer: "MR^2", isCorrect: false },
      { answer: "1/4 MR^2", isCorrect: false },
      { answer: "2 MR^2", isCorrect: false },
    ],
  },
  {
    question:
      "A uniform rod of length L and mass M is rotating about its center. What is the moment of inertia of the rod?",
    answerOptions: [
      { answer: "1/12 ML^2", isCorrect: true },
      { answer: "1/6 ML^2", isCorrect: false },
      { answer: "1/4 ML^2", isCorrect: false },
      { answer: "1/2 ML^2", isCorrect: false },
    ],
  },
  {
    question:
      "An object of mass m is attached to a string and rotates in a horizontal circle of radius r with a constant speed v. What is the tension in the string?",
    answerOptions: [
      { answer: "m v^2 / r", isCorrect: true },
      { answer: "m r / v^2", isCorrect: false },
      { answer: "m r v^2", isCorrect: false },
      { answer: "m v / r", isCorrect: false },
    ],
  },
  {
    question:
      "A disk of radius R and mass M is rotating about its central axis. If the angular velocity is doubled, how does the rotational kinetic energy change?",
    answerOptions: [
      { answer: "It increases by a factor of 4", isCorrect: true },
      { answer: "It increases by a factor of 2", isCorrect: false },
      { answer: "It remains the same", isCorrect: false },
      { answer: "It decreases by a factor of 4", isCorrect: false },
    ],
  },
  {
    question:
      "A particle is rotating with an angular velocity ω and has a moment of inertia I. What is the torque required to increase the angular velocity to 2ω?",
    answerOptions: [
      { answer: "2 I ω", isCorrect: true },
      { answer: "I ω", isCorrect: false },
      { answer: "4 I ω", isCorrect: false },
      { answer: "I / ω", isCorrect: false },
    ],
  },
  {
    question:
      "A thin, uniform spherical shell of mass M and radius R is rotating about an axis through its center. What is its moment of inertia?",
    answerOptions: [
      { answer: "2/3 MR^2", isCorrect: true },
      { answer: "1/2 MR^2", isCorrect: false },
      { answer: "MR^2", isCorrect: false },
      { answer: "1/3 MR^2", isCorrect: false },
    ],
  },
  {
    question:
      "A solid sphere of mass M and radius R is rotating about an axis through its center. If its angular velocity is ω, what is its rotational kinetic energy?",
    answerOptions: [
      { answer: "2/5 M R^2 ω^2", isCorrect: true },
      { answer: "1/2 M R^2 ω^2", isCorrect: false },
      { answer: "3/5 M R^2 ω^2", isCorrect: false },
      { answer: "M R^2 ω^2", isCorrect: false },
    ],
  },
  {
    question:
      "A rod of length L and mass M is pivoted at one end and rotates about the pivot. What is the angular momentum of the rod if it rotates with angular velocity ω?",
    answerOptions: [
      { answer: "1/3 M L^2 ω", isCorrect: true },
      { answer: "1/2 M L^2 ω", isCorrect: false },
      { answer: "1/4 M L^2 ω", isCorrect: false },
      { answer: "M L^2 ω", isCorrect: false },
    ],
  },
  {
    question:
      "A particle is moving in a circle of radius r with an angular velocity ω. What is the tangential speed of the particle?",
    answerOptions: [
      { answer: "r ω", isCorrect: true },
      { answer: "ω / r", isCorrect: false },
      { answer: "r / ω", isCorrect: false },
      { answer: "r^2 ω", isCorrect: false },
    ],
  },
  {
    question:
      "A rotating disc has an angular acceleration α. If the moment of inertia of the disc is I, what is the torque applied to the disc?",
    answerOptions: [
      { answer: "I α", isCorrect: true },
      { answer: "α / I", isCorrect: false },
      { answer: "I / α", isCorrect: false },
      { answer: "I α^2", isCorrect: false },
    ],
  },
  {
    question:
      "A particle is moving in a circular path of radius r with constant speed v. What is the work done by the centripetal force over one complete revolution?",
    answerOptions: [
      { answer: "0", isCorrect: true },
      { answer: "mv^2", isCorrect: false },
      { answer: "mv^2 / r", isCorrect: false },
      { answer: "2πr mv^2", isCorrect: false },
    ],
  },
  {
    question:
      "A rotating object with moment of inertia I has an angular velocity ω. What is the rate of change of its rotational kinetic energy if its angular velocity increases at a constant rate?",
    answerOptions: [
      { answer: "I ω (dω/dt)", isCorrect: true },
      { answer: "I dω / dt", isCorrect: false },
      { answer: "I ω^2 dω / dt", isCorrect: false },
      { answer: "I dω^2 / dt", isCorrect: false },
    ],
  },
  {
    question:
      "A hollow sphere of mass M and radius R is rotating about an axis through its center. What is the moment of inertia of the sphere?",
    answerOptions: [
      { answer: "2/3 MR^2", isCorrect: true },
      { answer: "1/2 MR^2", isCorrect: false },
      { answer: "3/5 MR^2", isCorrect: false },
      { answer: "MR^2", isCorrect: false },
    ],
  },
  {
    question:
      "A particle with mass m is moving in a circular path of radius r with angular velocity ω. What is its centripetal acceleration?",
    answerOptions: [
      { answer: "r ω^2", isCorrect: true },
      { answer: "m ω^2 / r", isCorrect: false },
      { answer: "r / ω^2", isCorrect: false },
      { answer: "m r ω", isCorrect: false },
    ],
  },
  {
    question:
      "A rotating disk has a moment of inertia I and an angular velocity ω. What is the angular momentum of the disk?",
    answerOptions: [
      { answer: "I ω", isCorrect: true },
      { answer: "I / ω", isCorrect: false },
      { answer: "ω / I", isCorrect: false },
      { answer: "I^2 ω", isCorrect: false },
    ],
  },
  {
    question:
      "A cylindrical object is rotating about its central axis. What is the moment of inertia of the cylinder if its mass is M and radius is R?",
    answerOptions: [
      { answer: "1/2 MR^2", isCorrect: true },
      { answer: "MR^2", isCorrect: false },
      { answer: "1/4 MR^2", isCorrect: false },
      { answer: "2 MR^2", isCorrect: false },
    ],
  },
  {
    question:
      "A uniform ring of mass M and radius R is rotating about its central axis. What is the moment of inertia of the ring?",
    answerOptions: [
      { answer: "MR^2", isCorrect: true },
      { answer: "1/2 MR^2", isCorrect: false },
      { answer: "1/4 MR^2", isCorrect: false },
      { answer: "2 MR^2", isCorrect: false },
    ],
  },
  {
    question:
      "A pendulum consists of a mass m attached to a string of length L. What is the expression for the period of oscillation of the pendulum for small angles?",
    answerOptions: [
      { answer: "2π sqrt(L / g)", isCorrect: true },
      { answer: "2π sqrt(m / g)", isCorrect: false },
      { answer: "2π sqrt(L g)", isCorrect: false },
      { answer: "2π sqrt(g / L)", isCorrect: false },
    ],
  },
  {
    question:
      "A particle moves in a circular path of radius r with a constant tangential speed v. What is the time period of the particle?",
    answerOptions: [
      { answer: "2π r / v", isCorrect: true },
      { answer: "v / 2π r", isCorrect: false },
      { answer: "2π v / r", isCorrect: false },
      { answer: "r / v", isCorrect: false },
    ],
  },
  {
    question:
      "A wheel of radius R and mass M rotates with an angular velocity ω. What is its rotational kinetic energy?",
    answerOptions: [
      { answer: "1/2 I ω^2", isCorrect: true },
      { answer: "1/2 M R^2 ω^2", isCorrect: false },
      { answer: "M R^2 ω^2", isCorrect: false },
      { answer: "I / 2 ω^2", isCorrect: false },
    ],
  },
  {
    question:
      "A thin rod of length L and mass M is rotating about its end with angular velocity ω. What is the rotational kinetic energy of the rod?",
    answerOptions: [
      { answer: "1/3 ML^2 ω^2", isCorrect: true },
      { answer: "1/2 ML^2 ω^2", isCorrect: false },
      { answer: "ML^2 ω^2", isCorrect: false },
      { answer: "1/4 ML^2 ω^2", isCorrect: false },
    ],
  },
  {
    question:
      "A sphere of mass M and radius R rolls without slipping on a horizontal surface. What is the total kinetic energy of the sphere?",
    answerOptions: [
      { answer: "7/10 MR^2 ω^2", isCorrect: true },
      { answer: "5/10 MR^2 ω^2", isCorrect: false },
      { answer: "3/5 MR^2 ω^2", isCorrect: false },
      { answer: "2/5 MR^2 ω^2", isCorrect: false },
    ],
  },
  {
    question:
      "A mass m is attached to a spring with spring constant k and is rotating in a horizontal circle of radius r. What is the centripetal force on the mass?",
    answerOptions: [
      { answer: "m ω^2 r", isCorrect: true },
      { answer: "k r", isCorrect: false },
      { answer: "k ω^2 r", isCorrect: false },
      { answer: "m k / r", isCorrect: false },
    ],
  },
  {
    question:
      "A rotating object with moment of inertia I has an angular acceleration α. What is the torque applied to the object?",
    answerOptions: [
      { answer: "I α", isCorrect: true },
      { answer: "I / α", isCorrect: false },
      { answer: "α / I", isCorrect: false },
      { answer: "I α^2", isCorrect: false },
    ],
  },
];

export default circularquestions;
