import React from "react";
import { doc, updateDoc } from "firebase/firestore";
import { fireDB, auth } from "../config/firebase"; // Import your Firestore and Auth instances
import { FaRetweet } from "react-icons/fa";

function Retweet({ questionId, userId, questions, setQuestions }) {
  const handleRetweet = async (questionId) => {
    try {
      const user = auth.currentUser;
      const currentUserId = user ? user.uid : null; // Get actual user ID

      if (!currentUserId) {
        alert("User not authenticated.");
        return;
      }

      const questionRef = doc(fireDB, "quizQuestions", questionId);
      const questionToUpdate = questions.find((q) => q.id === questionId);

      if (!questionToUpdate) {
        console.error("Question not found.");
        return;
      }

      // Default retweetCount to 0 if it's undefined
      const currentRetweetCount = questionToUpdate.retweetCount || 0;

      // Toggle retweet status and update the retweet count
      const updatedRetweets = questionToUpdate.retweetedByUser
        ? currentRetweetCount - 1 // Decrement if already retweeted
        : currentRetweetCount + 1; // Increment if not

      const updatedQuestions = questions.map((question) =>
        question.id === questionId
          ? {
              ...question,
              retweetedByUser: !question.retweetedByUser, // Toggle user retweet status
              retweetCount: updatedRetweets,
              retweetedBy: questionToUpdate.retweetedByUser
                ? questionToUpdate.retweetedBy.filter(id => id !== currentUserId) // Remove user ID if retweeting again
                : [...questionToUpdate.retweetedBy, currentUserId], // Add user ID to retweetedBy
            }
          : question
      );

      // Update the state in the parent component
      setQuestions(updatedQuestions);

      // Update Firestore with the new retweet count
      await updateDoc(questionRef, {
        retweetCount: updatedRetweets,
        retweetedBy: updatedQuestions.find(q => q.id === questionId).retweetedBy,
      });

      console.log("Retweet updated successfully.");
    } catch (error) {
      console.error("Error while retweeting:", error);
    }
  };

  // Get the current question from the questions array
  const currentQuestion = questions.find((q) => q.id === questionId);

  return (
    <div
      onClick={() => handleRetweet(questionId)}
      style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
    >
      <FaRetweet />
      <span style={{ marginLeft: "5px" }}>
        {currentQuestion ? currentQuestion.retweetCount || 0 : 0} {/* Display retweet count */}
      </span>
    </div>
  );
}

export default Retweet;
