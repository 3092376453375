import React, { useState, useEffect } from "react";
import { doc, getDoc } from "firebase/firestore";
import { fireDB, auth } from "../config/firebase";

function UserAccuracy() {
  const [accuracy, setAccuracy] = useState(null);
  const [loading, setLoading] = useState(true);
  const [totalAnswers, setTotalAnswers] = useState(0);
  const [totalCorrect, setTotalCorrect] = useState(0);

  useEffect(() => {
    const fetchUserStats = async () => {
      try {
        const user = auth.currentUser;
        if (!user) return;
        
        const userId = user.uid;
        const userRef = doc(fireDB, "users", userId);
        const userSnap = await getDoc(userRef);

        if (userSnap.exists()) {
          const userData = userSnap.data();
          const answers = userData.totalAnswers || 0;
          const correct = userData.totalCorrect || 0;
          
          setTotalAnswers(answers);
          setTotalCorrect(correct);

          if (answers > 0) {
            const calculatedAccuracy = ((correct / answers) * 100).toFixed(2);
            setAccuracy(calculatedAccuracy);
          } else {
            setAccuracy(0);
          }
        }
      } catch (error) {
        console.error("Error fetching user accuracy:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserStats();
  }, []);

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <div>
      <h2>Overall Accuracy</h2>
      <p>Total Answers: {totalAnswers}</p>
      <p>Correct Answers: {totalCorrect}</p>
      <p>Accuracy: {accuracy}%</p>
    </div>
  );
}

export default UserAccuracy;
